
/*
 * Account Retrievers - DATA ACCESS WITHOUT ANY STATE CHANGE
 *
 * This provides global access to the accounts.
 *
 * WARNING: These should NEVER be used for properties (MapToProps).  These are to retrieve information from
 * the REDUX Store and perform operations/searches on it, but this will not create any linkage for state change
 *
 */

import store from '@quicken-com/react.utils.redux-store';
import { accountsSelectors } from '@quicken-com/react.flux.accounts';

export function getAccountsById(s = store.getState()) {
  return accountsSelectors.getAccountsById(s);
}

export function getAccountsByIdIncludingGoals(s = store.getState()) {
  return accountsSelectors.getAccountsByIdIncludingGoals(s);
}
export function getAccountById(id, s = store.getState()) {
  const accounts = accountsSelectors.getAccountsByIdIncludingGoals(s);
  return accounts.get(id);
}

export function getAccountString(id, s = store.getState()) {
  const account = getAccountById(id, s);
  if (account) {
    return account.name;
  }
  return '[Account Not Synced]';
}
export function getCurrencyString(id, s = store.getState()) {
  const account = getAccountById(id, s);
  if (account) {
    return account.currency;
  }
  return 'N/A';
}

