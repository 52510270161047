// CORE
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import compose from 'utils/compose';
import moment from 'moment';
import { connect } from 'react-redux';
import VirtualList from 'react-tiny-virtual-list-oss';

// MUI
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import Checkbox from '@mui/material/Checkbox';
import CheckIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';

// LOCAL
import AmountField, { ShowSignEnum, ShowColorEnum } from 'components/QuickenControls/AmountField';
import CategoryField from 'components/QuickenControls/CategoryField';
import zeroStateIcon from 'assets/zero-state-images/safebox.svg';
import ZeroStateView from 'components/ZeroStateView';
import QButton from 'components/QButton';
import Dump from 'components/Dump';
import { isBankPending } from 'data/transactions/utils';
import { isAcme } from 'isAcme';

const styles = (theme) => ({
  VL: {
    padding: '10px 10px',
    '&::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      width: 4,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 2,
      backgroundColor: isAcme ? theme.palette.background.paper : 'rgba(0, 0, 0, 0.3)',
      WebkitBoxShadow: '0 0 1px rgba(245, 245, 245, 0.5)',
    },
  },
  txnLine: {
    height: 56,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 8,
    padding: 16,
    boxShadow: theme.shadows[2],
    '&:hover': {
      transform: 'translate(0px, 0px)',
      backgroundColor: theme.palette.greyScaleDeprecated[6],
      cursor: 'pointer',
    },
  },
  txnLineShaded: {
    extend: 'txnLine',
    backgroundColor: theme.palette.greyScaleDeprecated[5],
    '&:hover': {
      backgroundColor: theme.palette.greyScaleDeprecated[5],
    },
  },
  dater: { // 30
    width: 136,
    paddingRight: 16,
  },
  payer: { // 31
    width: '31%',
    minWidth: 90,
    paddingRight: 16,
  },
  typer: { // 28
    width: '28%',
    paddingRight: 16,
  },
  amter: { // 18
    width: '16%',
    textAlign: 'right',
    minWidth: 64,
  },
  checkWrap: {
    height: 22,
    width: 22,
    marginLeft: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  checkIcon: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50%',
    color: theme.palette.greyScaleDeprecated[7],
    width: 20,
    height: 20,
    padding: 2,
  },
  selectIcon: {
    borderRadius: '50%',
    border: '1px solid grey',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
  noCheck: {
    width: 20,
    height: 20,
    padding: 2,
    opacity: 0,
    backgroundColor: 'inherit',
  },
  zeroState: {
    textAlign: 'center',
    lineHeight: 2,
  },
  stateView: {
    height: 360,
    width: '100%',
  },
  zeroRoot: {
    height: '80%',
  },
  zeroReset: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    height: 32,
  },
  resetText: { // TODO Add this to replace #666 somehow...
    color: theme.palette.greyScaleDeprecated[2],
    lineHeight: '32px',
  },
});

class TransactionSelectList extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      displayNodes: [],
    };
  }

  checkHighlight(id) {
    const { highlight, multiSelect } = this.props;
    if (multiSelect) {
      return highlight?.contains(id) || false;
    }
    return highlight === id;
  }

  generateTxns() {
    const { classes, clickFunc, hideCategory, multiSelect } = this.props;
    let acctTxns = this.getAcctTxns();
    if (!isAcme) {
      acctTxns = acctTxns && acctTxns.filter((txn) => !isBankPending(txn));
    }
    this.state.displayNodes = [];
    let i = 0;
    acctTxns.map((txn) => {
      if (txn.payee) {
        this.state.displayNodes.push(
          <div
            role="presentation"
            key={i}
            name={`txn-item-${i}`}
            id={JSON.stringify(txn)}
            onClick={clickFunc}
            className={this.checkHighlight(txn.id) ? classes.txnLineShaded : classes.txnLine}
          >
            <Dump obj={txn} />
            {isAcme && multiSelect && (
              <Checkbox
                checked={this.checkHighlight(txn.id)}
                // no onChange because we want the parent div to fire the event to retain the txn in the id
              />
            )}
            <Typography variant="body2" noWrap className={classes.dater}>
              {moment(txn.postedOn).format('MMM Do, YYYY')}
            </Typography>
            <Typography variant="body2" noWrap className={classes.payer}>
              {txn.payee}
            </Typography>
            {!hideCategory && (
              <CategoryField
                variant="body2"
                classNameWhenNotEditable={classes.typer}
                disableTooltip
                value={txn.coa}
                longCats={false}
                autoFocus={false}
                noWrap
              />
            )}
            <AmountField
              showSign={ShowSignEnum.POSITIVE_ONLY}
              showColor={ShowColorEnum.POSITIVE_ONLY}
              color={txn.amount > 0 ? 'green' : undefined}
              value={txn.amount}
              variant="body2"
              className={classes.amter}
              noWrap
            />
            {!isAcme && (
              <div className={classes.checkWrap}>
                {this.checkHighlight(txn.id) && <CheckIcon className={classes.checkIcon} />}
                {!this.checkHighlight(txn.id) && <AddIcon className={classes.selectIcon} />}
              </div>
            )}
          </div>,
        );
        i += 1;
        return '';
      }
      return '';
    });
  }

  getAcctTxns() {
    const { acctTxns, filterByType } = this.props;
    return filterByType ? acctTxns.filter(filterByType) : acctTxns;
  }

  render() {
    const { classes, onReset } = this.props;
    const acctTxns = this.getAcctTxns();

    if (!acctTxns?.size) {
      return (
        <div className={classes.stateView}>
          <ZeroStateView
            icon={zeroStateIcon}
            primary="We couldn't find any transactions"
            secondary={
              <>
                You might have searched for something too specific.
                <br />
                Try resetting the search if so
              </>
            }
            classes={{ secondary: classes.zeroState, root: classes.zeroRoot }}
          />
          <div className={classes.zeroReset}>
            <QButton onClick={onReset} variant="text">
              Click Here
            </QButton>
            <Typography style={{ color: '#666', lineHeight: '32px' }}>
              to reset the search.
            </Typography>
          </div>
        </div>
      );
    }
    if (acctTxns && this.state.displayNodes) {
      this.generateTxns();
    }
    return (
      acctTxns.size && 
      (
        <VirtualList
          id={'transaction-choice-list'}
          height={360}
          itemCount={this.state.displayNodes.length}
          overscanCount={30}
          itemSize={60}
          renderItem={({ style, index }) => (this.state.displayNodes[index] ? 
            <div style={style} key={index}>
              {this.state.displayNodes[index]}
            </div>
            : ''
          )}
          className={this.props.classes.VL}
        />
      )
    );
  }
}

TransactionSelectList.defaultProps = {
  selectorProps: {},
};

TransactionSelectList.propTypes = {
  classes: PropTypes.object.isRequired,
  acctTxns: PropTypes.any,
  clickFunc: PropTypes.func,
  highlight: PropTypes.oneOfType([
    PropTypes.string, 
    PropTypes.object,
  ]),
  hideCategory: PropTypes.bool,
  multiSelect: PropTypes.bool,
  onReset: PropTypes.func,
  filterByType: PropTypes.func,
  /* eslint-disable react/no-unused-prop-types */
  selectorProps: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  return {
    acctTxns: ownProps.transactionListSelector(state, {
      ...ownProps.selectorProps,
      filter: ownProps.filter,
    }),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  withStyles(styles), 
  connect(mapStateToProps, mapDispatchToProps),
)(TransactionSelectList);
