import { createAction } from 'redux-actions';

export const getCategoryGroupsAction = createAction('GET_CATEGORY_GROUPS');
export const getCategoryGroupsSuccessAction = createAction('GET_CATEGORY_GROUPS_SUCCESS');
export const getCategoryGroupsFailureAction = createAction('GET_CATEGORY_GROUPS_FAILURE');

export const updateCategoryGroupAction = createAction('UPDATE_CATEGORY_GROUP',
  (payload) => payload, (payload, metadata) => metadata);
export const updateCategoryGroupSuccessAction = createAction('UPDATE_CATEGORY_GROUP_SUCCESS');
export const updateCategoryGroupFailureAction = createAction('UPDATE_CATEGORY_GROUP_FAILURE');
