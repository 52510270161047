

import React from 'react';
import { connect } from 'react-redux';
import compose from 'utils/compose';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Input from '@mui/material/Input';

export const styles = (theme) => ({
  input: {},
  root: {},
  cancelIcon: {
    color: theme.palette.removeButton,
  },
  searchIconButton: {
    margin: 5,
    color: theme.palette.greyScaleDeprecated[2],
    cursor: 'pointer',
  },
  searchIcon: {
    margin: 3,
  },
  iconHolder: {
    lineHeight: '5px',
    '&:hover': {
      transform: 'scale(1.1)',
      transition: 'all 0.15s ease-in-out',
    },
    '&:active': {
      transform: 'scale(1)',
      transition: 'all 0.15s ease-in-out',
    },
  },
  smartSearch: {
    width: 32,
    marginRight: 'auto',
    marginLeft: '0px',
    height: 32,
    minHeight: 32,
    padding: 0,
    border: 'none',
    borderWidth: 1,
    borderRadius: 0,
    marginTop: 1,
    marginBottom: 'auto',
    borderColor: theme.palette.greyScaleDeprecated[3],
    fontSize: theme.components.register.fontSize.default,
    color: theme.components.txns.smartSearch,
    '&.open': {
      width: 300,
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&:focus-within': {
      color: theme.palette.primary.main,
    },
    transition: 'width 0.5s ease',
  },
  smartSearchInput: {
    fontSize: theme.components.register.fontSize.default,
    color: theme.palette.text.primary,
    fontWeight: 'normal',
    padding: '0px 5px 0px 5px',
    height: 'inherit',
    '&::placeholder': {
      color: theme.palette.greyScaleDeprecated[2],
      opacity: 1,
      fontWeight: 'normal',
    },
    '&::-ms-clear ': {
      display: 'none',
    },
    '&.hide': {
      display: 'none',
    },
    '&.show': {
      display: 'block',
    },
  },
});

class SearchBox extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      smartSearchText: props.initialValue || '',
      inputRef: null,
      activeSearch: false,
      searchOpen: Boolean(props.initialValue) || !props.collapsable,
    };
  }

  componentDidMount() {
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.initialValue && !nextProps.initialValue) {
      this.setState({ smartSearchText: '' });
    }
    if (nextProps.initialValue && nextProps.initialValue !== this.state.smartSearchText) {
      const smartSearchText = nextProps.initialValue || '';
      this.setState({ searchOpen: (smartSearchText !== ''), activeSearch: (smartSearchText !== ''), smartSearchText });
    }
  }
  saveInputRef = (el) => {
    this.state.inputRef = el;
  };

  resetFocus = () => {
    if (this.state.inputRef) {
      this.state.inputRef.focus();
    }
  };

  iconDoSearch = () => {
    if (this.state.searchOpen) {
      this.doSearch();
    } else {
      this.setState({ searchOpen: true });
      setTimeout(this.resetFocus, 100);
      // this.resetFocus();
    }
  };

  doSearch = (string = null) => {
    const filter = string || this.state.smartSearchText.trim();
    if (filter.length > 0) {
      this.props.onSearch(string || this.state.smartSearchText.trim());
      this.resetFocus();
      this.setState({ activeSearch: true });
    } if (filter.length === 0) {
      this.clearSearch();
    }
  };

  clearSearch = () => {
    this.setState({ activeSearch: false, smartSearchText: '' });
    this.props.onSearch('');
    this.resetFocus();
    this.setState({ searchOpen: !this.props.collapsable });
    if (this.props.onHelp) this.props.onHelp(false);
  };

  maybeHideSearchField = () => {
    if (!this.state.smartSearchText) {
      this.setState({ searchOpen: !this.props.collapsable });
      if (this.props.onHelp) this.props.onHelp(false);
    }
  };

  keyDown = (e) => {
    if (e.key === 'Enter') {
      this.doSearch();
      this.resetFocus();
    }
    if (e.key === 'Escape') {
      this.clearSearch();
      this.resetFocus();
      this.setState({ searchOpen: !this.props.collapsable });
    }
  };

  handleChange = (e) => {
    this.setState({ smartSearchText: e.target.value });

    if (this.props.autoSearch) {
      if (e.target.value.length >= 2 || this.state.smartSearchText > 0) {
        this.doSearch(e.target.value.trim());
      } else {
        this.props.onSearch(null);
      }
    } else if (e.target.value.trim().length === 0 && this.state.activeSearch) {
      this.clearSearch();
    }
  };

  render() {

    const { classes, collapsable, placeholder, className, onHelp, showUnderline, autoFocus } = this.props;
    const showCancel = this.state.smartSearchText && this.state.smartSearchText.length > 0;

    let cancelAdorn = null;
    if (showCancel) {
      cancelAdorn =
        <div className={classes.iconHolder}>
          <CancelIcon
            style={{ fontSize: '20px' }}
            aria-label="Cancel Search"
            id="cancel-search"
            className={classNames(classes.cancelIcon, collapsable ? classes.searchIconButton : classes.searchIcon)}
            onClick={this.clearSearch}
            tabIndex="0"
          />
        </div>;
    } else if (onHelp && this.state.searchOpen) {
      cancelAdorn =
        <div
          className={classes.iconHolder}
        >
          <HelpOutlineIcon
            style={{ fontSize: '20px' }}
            aria-label="Cancel Search"
            id="help-search-adorn"
            className={collapsable ? classes.searchIconButton : classes.searchIcon}
            tabIndex="0"
            onClick={() => onHelp(true)}
          />
        </div>;
    }

    return (
      <Input
        autoFocus={autoFocus}
        autoComplete={'off'}
        inputProps={{ 'aria-label': 'Search Transactions' }}
        label="Search Transactions"
        id="search"
        sharedcomponentid={'SEARCH_BOX'}
        inputRef={this.saveInputRef}
        classes={{
          root: classNames(classes.smartSearch, this.state.searchOpen || !collapsable ? 'open' : 'show', classes.root),
          input: classNames(classes.smartSearchInput, classes.input, this.state.searchOpen || !collapsable ? 'show' : 'hide'),
        }}
        className={className}
        margin="dense"
        placeholder={placeholder || 'Search available transactions'}
        value={this.state.smartSearchText}
        disableUnderline={!showUnderline}
        onKeyDown={this.keyDown}
        onChange={this.handleChange}
        startAdornment={
          <div className={classes.iconHolder}>
            <SearchIcon
              aria-label="Search"
              id="register-search-icon"
              className={collapsable ? classes.searchIconButton : classes.searchIcon}
              onClick={() => this.iconDoSearch()}
              tabIndex="0"
            />
          </div>
        }
        endAdornment={cancelAdorn}
      />
    );
  }
}

function mapStateToProps() {
  return {
  };
}

function mapDispatchToProps() {
  return {
  };
}

SearchBox.defaultProps = {
};

SearchBox.propTypes = {
  // the 'classes' propType also allows for style overrides, much like MUI, for input, and input root
  // use this to customize the styles!
  classes: PropTypes.object,
  className: PropTypes.string,
  onSearch: PropTypes.func,
  autoSearch: PropTypes.bool,
  initialValue: PropTypes.string,
  collapsable: PropTypes.bool,
  placeholder: PropTypes.string,
  onHelp: PropTypes.func,
  showUnderline: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(SearchBox);
