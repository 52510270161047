
export const POINTER_SIZE = 14;

export const styles = (theme) => {

  const { qCards } = theme.components;
  const pointer = POINTER_SIZE * 1.5;

  return ({
    wrapperOff: {
      position: 'absolute',
    },
    qcardPositionBase: {
      content: "' '",
      width: 0,
      height: 0,
      border: `${POINTER_SIZE}px solid transparent`,
      position: 'absolute',
      left: '100%',
      top: '50%',
      marginRight: -POINTER_SIZE,
      marginTop: -POINTER_SIZE,
    },
    qcard: {
      willChange: 'top, left, width, height, opacity',
      transitionProperty: 'top, left, width, height',
      transitionDuration: '.4s',
      transitionTimingFunction: 'ease',
      position: 'fixed',
      top: 0,
      left: 0,
      width: 300,
      height: 100,
      background: qCards.backgroundColor,
      zIndex: 1202,
      borderRadius: 5,
      boxShadow: `${theme.palette.action.active} 5px 5px 10px`,
      '&.left': {
        '&:after': {
          extend: 'qcardPositionBase',
          borderLeft: `${POINTER_SIZE}px solid ${qCards.backgroundColor}`,
        },
      },
      '&.right': {
        '&:after': {
          extend: 'qcardPositionBase',
          borderRight: `${POINTER_SIZE}px solid ${qCards.backgroundColor}`,
          right: '100%',
          left: 'unset',
          marginLeft: -POINTER_SIZE,
          marginTop: -POINTER_SIZE,
          marginRight: 'unset',
        },
      },
      '&.righttop': {
        '&:after': {
          extend: 'qcardPositionBase',
          borderRight: `${POINTER_SIZE}px solid ${qCards.backgroundColor}`,
          right: '100%',
          left: 'unset',
          top: pointer,
          marginRight: 'unset',
        },
      },
      '&.rightbottom': {
        '&:after': {
          extend: 'qcardPositionBase',
          borderRight: `${POINTER_SIZE}px solid ${qCards.backgroundColor}`,
          right: '100%',
          left: 'unset',
          top: 'unset',
          bottom: pointer,
          marginRight: 'unset',
        },
      },
      '&.lefttop': {
        '&:after': {
          extend: 'qcardPositionBase',
          borderLeft: `${POINTER_SIZE}px solid ${qCards.backgroundColor}`,
          top: pointer,
        },
      },
      '&.leftbottom': {
        '&:after': {
          extend: 'qcardPositionBase',
          borderLeft: `${POINTER_SIZE}px solid ${qCards.backgroundColor}`,
          top: 'unset',
          bottom: pointer,
        },
      },
    },
    buttonArea: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      textAlign: 'center',
      padding: 5,
    },
    contentArea: {
      marginTop: 10,
      paddingLeft: 10,
      paddingRight: 10,
    },
    title: {
      color: qCards.titleColor,
      fontSize: 16,
      paddingRight: 20,
    },
    content: {
      marginTop: 10,
      color: qCards.contentColor,
      fontSize: theme.typography.fontSize,
    },
    closeBox: {
      position: 'absolute',
      right: 5,
      top: 5,
    },
    closeIcon: {
      color: qCards.closeColor,
    },
    buttonText: {
      color: qCards.buttonTextColor,
    },
    highlight: {
      boxShadow: `${qCards.highlightColor} 0px 0px 18px`,
    },
  });
};
