// @flow
import { combineActions, handleActions } from 'redux-actions';
import { difference } from 'lodash';

import { addInstitutionLoginAndAccountsResponse, deleteInstitutionLoginSuccess } from 'data/institutionLogins/actions';
import { resourceStoreTypes, resourceUpserters, resourceUndoUpserters } from '@quicken-com/react.flux.core';
import { accountsActions, accountsReducer, accountsTypes } from '@quicken-com/react.flux.accounts';
import { transactionsActions } from '@quicken-com/react.flux.transactions';

import * as actions from './actions';

const handleInvestmentAccountMerge = (state, accountsToUpsert) => {
  const [...accountIds] = state.resourcesById.filter((acct) => acct.type === accountsTypes.AccountTypes.INVESTMENT).keys();
  const accountIdsToUpsert = accountsToUpsert.map((acct) => acct.id);
  const newAccountIds = (accountIds.length === accountIdsToUpsert.length) ? difference(accountIdsToUpsert, accountIds) : [];

  // isNotSynced is not supported for q17/accounts, desktop clients delete||create investment accounts on un-sync & sync
  // delete local investment accounts on store, if there is any mismatch with q17/accounts response
  const updatedState = (newAccountIds.length > 0 || accountIds.length > accountIdsToUpsert.length) ?
    state.merge({ resourcesById: accountIds.reduce((resourcesById, id) => resourcesById.delete(id), state.resourcesById) }) : state;

  return resourceUpserters.upsertResources(updatedState, { resources: accountsToUpsert, doMerge: false })
    .set('loadPending', state.get('loadPending'))
    .set('isLoading', state.get('isLoading'));
};

const localReducer = handleActions({

  [actions.getInvestmentAccounts]:
    (state) => state.merge({ isLoading: true }),
  [actions.getInvestmentAccountsFailure]:
    (state, { payload: error }) => resourceUpserters.completeWithError(state, error),

  [actions.updateInvestmentAccountFailure]: // // optimistic update + undo
    (state, action) => resourceUndoUpserters.upsertResourceSetupUndo(state, action, accountsActions.updateAccount),

  [combineActions( // optimistic update
    actions.createInvestmentAccount,
    actions.deleteInvestmentAccount,
  )]:
    (state, { payload }) => resourceUpserters.upsertResource(state, payload),

  [actions.batchInvestmentAccounts]: // optimistic update
    (state, { payload }) => resourceUpserters.upsertResources(state, { resources: payload }),

  [combineActions(
    actions.createInvestmentAccountFailure,
    actions.updateInvestmentAccountFailure,
    actions.deleteInvestmentAccountFailure,
  )]: (state, { payload: error, asyncDispatch }) => {
    asyncDispatch(actions.getInvestmentAccounts());
    return resourceUpserters.resetResources(state, error);
  },

  [deleteInstitutionLoginSuccess]: (state, { asyncDispatch, payload: resource }) => {
    // find and delete all accounts (in REDUX only) that have this institutionLoginId
    let acctsToDelete = state.resourcesById.filter((acct) => acct.institutionLoginId === resource.id);
    if (resource.id && acctsToDelete && acctsToDelete.size > 0) {
      acctsToDelete = acctsToDelete.map((x) => x.set('isDeleted', true));
      acctsToDelete.forEach((acct) => asyncDispatch(accountsActions.deleteAccountSuccess(acct)));

      // asyncDispatch(actions.batchAccountsSuccess(acctsToDelete));
      // give QCS 3seconds to delete the transactions, then do a get
      // note that transactions reducer will not process this as a delete since it went
      // through UPSERT for bulk delete
      // TODO: Listen to batchAccountsSuccess in transactions reducer
      // This is to get impacted transfers from other accts, but does not return actual deleted transactions from account
      // todo: BUMP SERVER
      setTimeout(() => asyncDispatch(transactionsActions.getTransactions()), 3000);
    }
    return state;
  },

  [actions.batchInvestmentAccountsSuccess]: (state, { payload: resources }) => {
    // when ignoring accounts during an Add-FI we had to send to QCS but we not want to add to redux
    const resourcesExcludingIgnored = resources.filter((resource) => !resource.isIgnored);
    const payload = resourceStoreTypes.mkQcsSyncResourcesPayload({ resources: resourcesExcludingIgnored, doMerge: true });
    return resourceUpserters.upsertResources(state, payload).set('loadPending', false);
  },

  [combineActions(
    actions.createInvestmentAccountFailure,
    actions.updateInvestmentAccountFailure,
    actions.deleteInvestmentAccountFailure,
  )]: (state, { payload: error, asyncDispatch }) => {
    asyncDispatch(accountsActions.getAccounts());
    return resourceUpserters.resetResources(state, error);
  },

  [actions.getInvestmentAccountsSuccess]: (state, { payload }) => {
    const accountsToUpsertExcludingIgnored = payload.resources.filter((account) => !account.isIgnored);
    return handleInvestmentAccountMerge(state, accountsToUpsertExcludingIgnored);
  },

  [addInstitutionLoginAndAccountsResponse]: {
    next(state, { payload: { accounts }, asyncDispatch }) {
      asyncDispatch(accountsActions.applyAccountsChanges(accounts));
      return state;
    },
  },

}, resourceStoreTypes.mkQcsSyncResourceStore({ resourceName: 'ACCOUNTS' }));

// chain reducers
export const reducer = (state, action) => {
  const state1 = localReducer(state, action);
  const state2 = accountsReducer(state1, action);
  return state2;
};

export default reducer;
export const ACCOUNTS_REDUCER_KEY = 'accountsStore';
