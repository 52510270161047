import { useEffect } from 'react';
import store from 'store';
import { useSelector } from 'react-redux';

import { authSelectors } from '@quicken-com/react.flux.auth';
import { getLogger } from '@quicken-com/react.utils.core';

const logger = getLogger('useFetchOnce');

// resourceSelectors should contain following methods to be fully compatible with this hook
// getLoadPending
// getIsLoading
// getError

function fetchOnceLogic(fetchResourceActionCreator, resourceSelectors = {}, update = true) {
  const state = store.getState();
  if (!resourceSelectors.getIsLoading) {
    logger.warn(`getIsLoading selector is not defined for ${fetchResourceActionCreator}`);
  }
  const loading = resourceSelectors.getIsLoading ? resourceSelectors.getIsLoading(state) : false;
  if (!loading) {
    if (update
      || (resourceSelectors.getLoadPending ? resourceSelectors.getLoadPending(state) : true)
      || (resourceSelectors.getError ? resourceSelectors.getError(state) : undefined)
    ) {
      store.dispatch(fetchResourceActionCreator());
    }
  }
}

export function fetchOnce(fetchResourceActionCreator,
  resourceSelectors = {},
  update = true,
  ignoreDataset = false) {
  const authSession = authSelectors.getAuthSession(store.getState());
  const datasetId = authSession?.datasetId;
  const isLoggedIn = Boolean(authSession?.accessToken);

  if (isLoggedIn && (ignoreDataset || datasetId)) {
    fetchOnceLogic(fetchResourceActionCreator, resourceSelectors, update);
  }
}

export default function useFetchOnce(
  fetchResourceActionCreator,
  resourceSelectors = {},
  update = true,
  ignoreDataset = false
) {
  const authSession = useSelector(authSelectors.getAuthSession);
  const datasetId = authSession?.datasetId;
  const userId = authSession?.qcsId;
  const isLoggedIn = Boolean(authSession?.accessToken);

  useEffect(() => {
    if (isLoggedIn && (ignoreDataset || datasetId)) {
      fetchOnceLogic(fetchResourceActionCreator, resourceSelectors, update);
    }
  }, [fetchResourceActionCreator, resourceSelectors, update, datasetId, ignoreDataset, userId, isLoggedIn]);
}
