import { alertsSagas } from '@quicken-com/react.flux.alerts';
import { bumpSagas } from '@quicken-com/react.flux.bump';
import { categoriesSagas } from '@quicken-com/react.flux.categories';
import { datasetsSagas } from '@quicken-com/react.flux.datasets';
import { keyManagementSagas } from '@quicken-com/react.flux.key-management';
import { postponedActionsSagas } from '@quicken-com/react.flux.postponed-actions';
import { profileSagas } from '@quicken-com/react.flux.profile';
import { tagsSagas } from '@quicken-com/react.flux.tags';
import { userInfoSagas } from '@quicken-com/react.flux.user-info';
import { scheduledTransactionsSagas } from '@quicken-com/react.flux.scheduled-transactions';

import accountsSagas from 'data/accounts/sagas';  // wraps bit.dev
import authSagas from 'utils/auth/authSagas'; // wraps bit.dev
import accountDiscoverySagas from 'components/Dialogs/AccountDiscovery/sagas';
import budgetsSagas from 'data/budgets/budgetsSagas';
import budgetItemsSagas from 'data/budgetItems/budgetItemsSagas';
import categoryGroupsSagas from 'data/categoryGroups/categoryGroupsSagas';
import categoryGroupListsSagas from 'data/categoryGroupLists/categoryGroupListsSagas';
import documentSagas from 'data/documents/sagas';
import institutionSagas from 'data/institutions/sagas';
import institutionLoginsSagas from 'data/institutionLogins/sagas';
import investmentHoldingsSagas from 'data/investmentHoldings/sagas';
import persistSagas from 'data/persist/sagas';
import qcsApiSagas from 'data/apiUtil/apiCall';
import subscriptionSagas from 'data/subscriptions/subscriptionsSagas';
import transactionSagas from 'data/transactions/sagas';
import preferencesSagas from 'data/preferences/sagas';
import preferencesV2Sagas from 'data/preferencesV2/preferencesV2Sagas';
import rulesSagas from 'data/renameRules/sagas';
import memorizedRulesSagas from 'data/memorizedRules/sagas';
import jobStatus from 'data/jobStatus/sagas';
import npsSagas from 'data/nps/npsSagas';
import accountBalancesSagas from 'data/accountBalances/sagas';
import payeesSagas from 'data/payees/sagas';
import entitlementsSagas from 'data/entitlements/entitlementsSagas';
import mcRulesSagas from 'data/mcRules/mcRulesSagas';
import importSagas from 'data/import/importSagas';
import appSagas from 'data/app/sagas';
import alertRulesSagas from 'data/alertRules/sagas';
import ssoSagas from 'data/sso/ssoSagas';
import feedbackSagas from 'data/feedback/feedbackSagas';
import { accountsBalancesSagas } from '@quicken-com/react.flux.accounts-balances';
import { configFeatureFlagsSagas } from '@quicken-com/react.flux.config-feature-flags';

const createSagas = (store) => {

  const sagaTasks = [

    // TODO: MAKE SURE THERE ARE NO DUPLICATE ENTRIES
    //  ADD THINGS IN ALPHABETICAL ORDER PLEASE
    accountBalancesSagas,
    accountsSagas,
    accountDiscoverySagas,
    accountsBalancesSagas,
    alertRulesSagas,
    alertsSagas,
    appSagas,
    authSagas,
    budgetsSagas,
    budgetItemsSagas,
    bumpSagas,
    categoriesSagas,
    categoryGroupsSagas,
    categoryGroupListsSagas,
    configFeatureFlagsSagas,
    datasetsSagas,
    documentSagas,
    entitlementsSagas,
    feedbackSagas,
    importSagas,
    institutionSagas,
    institutionLoginsSagas,
    investmentHoldingsSagas,
    jobStatus,
    keyManagementSagas,
    mcRulesSagas,
    memorizedRulesSagas,
    npsSagas,
    payeesSagas,
    persistSagas,
    postponedActionsSagas,
    preferencesSagas,
    preferencesV2Sagas,
    profileSagas,
    qcsApiSagas,
    rulesSagas,
    scheduledTransactionsSagas,
    ssoSagas,
    subscriptionSagas,
    tagsSagas,
    transactionSagas,
    userInfoSagas,
    // TODO: MAKE SURE THERE ARE NO DUPLICATE ENTRIES
    //  ADD THINGS IN ALPHABETICAL ORDER PLEASE
  ].reduce((tasksAccumulator, sagas) => [...tasksAccumulator, ...sagas.map(store.runSaga)], []);

  return sagaTasks;
};

export default createSagas;
