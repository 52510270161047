// @flow
import * as types from './types';

export function transformRuleToRequestData(rule: types.Rule) {
  return {
    id: rule.id,
    clientId: rule.clientId,
    isDeleted: rule.isDeleted,
    renamePayeeFrom: rule.renamePayeeFrom,
    renamePayeeTo: rule.renamePayeeTo,
    isDoNotRename: rule.isDoNotRename,
    matchCriteria: rule.matchCriteria,
  };
}

export function transformRulesToRequestData(rules: Array<types.Rule>) {
  return {
    resources: rules.map(transformRuleToRequestData),
  };
}

export function transformResponseToRules(response: any) {
  return response.data.resources.map((data) => (
    types.mkRenameRule({
      id: data.id,
      clientId: data.clientId,
      isDeleted: data.isDeleted,
      renamePayeeFrom: data.renamePayeeFrom,
      renamePayeeTo: data.renamePayeeTo,
      isDoNotRename: data.isDoNotRename,
      matchCriteria: data.matchCriteria,
    })
  ));
}
