// ================================================================================================
// These should only be used by the store for syncing authenticated session to local storage
// ================================================================================================
import * as sessionStorageEx from 'utils/sessionStorageEx';
import { localPreferences } from '@quicken-com/react.utils.core';
import { authTypes } from '@quicken-com/react.flux.auth';

export const loadAuthSession = () => {
  const authSession = localPreferences.getAuthSession() || sessionStorageEx.getAuthSession();
  if (authSession) {
    authSession.isLoading = false;
    authSession.source = authTypes.AuthSessionSources.SESSION_STORAGE;
    return authTypes.mkAuthStore(authSession);
  }
  return authTypes.mkAuthStore();
};

export const saveAuthSession = (authSession) => {

  if (!authSession || authSession.accessToken === null) {
    clearAuthSession();
    return;
  }

  // note: convert to Record before calling toJSON in case we're passed a non-Record object
  const session = authTypes.mkAuthStore(authSession);

  sessionStorageEx.setAuthSession(session);
  if (localPreferences.getKeepLoggedIn()) {
    localPreferences.setAuthSession(session);
  } else {
    localPreferences.removeAuthSession();
  }
};

export const clearAuthSession = () => {
  sessionStorageEx.removeAuthSession();
  localPreferences.removeAuthSession();
};
