// @flow
import { delay, put, takeLatest, takeEvery, select } from 'redux-saga/effects';

import { getLogger } from '@quicken-com/react.utils.core';
import { resourceStoreTypes, resourceSagaUtils } from '@quicken-com/react.flux.core';
import { authSelectors } from '@quicken-com/react.flux.auth';

import * as actions from 'data/nps/npsActions';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import { DIALOG_TYPE as DIALOG_TYPE_NPS_SURVEY } from 'components/Dialogs/NPSSurveyDialog/types';

import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';

const log = getLogger('data/nps/npsSagas');

function* checkIfNPSSurveyRequested() {

  const featureFlags = yield select(featureFlagsSelectors.getFeatureFlags);
  const npsDelay : number = featureFlags.get('nps');
  if (npsDelay > 0) {
    log.log('checkIfNPSSurveyRequestedAction...');
    const authSession = yield select(authSelectors.getAuthSession);
    if (authSession.surveyRequested) {
      log.log(`Show NPS Survey...in ${npsDelay} msec`);
      yield delay(npsDelay);

      log.log('Show NPS Survey...');
      const dialogData = mkRootUiData({
        id: 'NPS',
        type: DIALOG_TYPE_NPS_SURVEY,
        allowNesting: true,
      });
      yield put(createDialog(dialogData));
    }
  }
}

function* checkIfNPSSurveyRequestedActionWatcher(): Generator<*, *, *> {
  yield takeLatest(actions.checkIfNPSSurveyRequestedAction, checkIfNPSSurveyRequested);
}

export function* postNPSSurveyResultActionWatcher(): Generator<*, *, *> {
  yield takeEvery(actions.postNPSSurveyResultAction,
    resourceSagaUtils.qcsSyncCreateResource,
    resourceStoreTypes.mkQcsSyncResourceConfig({
      resourceName: 'nps survey',
      resourceBaseUrl: '/users/survey',
      successAction: actions.postNPSSurveyResultSuccessAction,
      failureAction: actions.postNPSSurveyResultFailureAction,
    }));
}

export default [
  checkIfNPSSurveyRequestedActionWatcher,
  postNPSSurveyResultActionWatcher,
];
