import { createAction } from 'redux-actions';

export const appSetOnlineStatus = createAction('APP_SET_ONLINE_STATUS');

export const appSetServiceWorkerIsRegistered = createAction('APP_SET_SW_IS_REGISTERED');
export const appSetServiceWorkerIsRegistering = createAction('APP_SET_SW_IS_REGISTERING');
export const appSetServiceWorkerUpdateIsAvailable = createAction('APP_SET_SW_UPDATE_IS_AVAILABLE');

export const appSubmitPushNotificationToken = createAction('APP_SUBMIT_PUSH_NOTIFICATION_TOKEN');

export const appRefreshAll = createAction('APP_REFRESH_ALL', (payload) => payload, (payload, meta) => meta);
export const setAwaitingToAutoRefresh = createAction('APP_AWAITING_TO_AUTO_REFRESH');
export const setAppProps = createAction('APP_SET_PROPS');

