import { handleActions, combineActions } from 'redux-actions';

import { getLogger } from '@quicken-com/react.utils.core';
import { resourceUpserters, resourceUndoUpserters, resourceStoreTypes } from '@quicken-com/react.flux.core';

import * as actions from './budgetsActions';

const { upsertResources, completeWithError, upsertResource } = resourceUpserters;
const { upsertResourceSetupUndo, upsertResourcesSetupUndo } = resourceUndoUpserters;

const log = getLogger('data/budgets/budgetsReducer.js');

export const BUDGETS_REDUCER_KEY = 'budgetsStore';

const initialState = resourceStoreTypes.mkQcsSyncResourceStore();
export const budgetsReducer = handleActions({

  // GET BUDGETS ========================================
  [actions.getBudgetsAction]: (state) =>
    state.merge({ isLoading: true }),
  [actions.getBudgetsSuccessAction]: (state, { payload }) => {
    log.log('getBudgetsSuccessAction...\n', payload);
    return upsertResources(state, payload);
  },

  // RECALC BUDGETS ========================================
  [actions.recalcBudgetsAction]: (state) =>
    state.merge({ isLoading: true }),
  [actions.recalcBudgetsSuccessAction]: (state, { payload }) => {
    log.log('recalcBudgetsSuccessAction...\n', payload);
    return state.merge({ isLoading: false });
  },

  // CREATE BUDGETS ========================================
  [actions.createBudgetsAction]:
    (state) => state.merge({ isLoading: true }),
  [actions.createBudgetsSuccessAction]:
    (state, action) => {
      log.log('createBudgetsAction...', action);
      return upsertResourcesSetupUndo(state, action, actions.deleteBudgetsAction, true);
    },

  // UPDATE BUDGETS ========================================
  [actions.updateBudgetAction]:
    (state, action) => {
      log.log('updateBudgetsAction...', action);
      return upsertResourceSetupUndo(state, action, actions.updateBudgetAction);
    },
  [actions.updateBudgetSuccessAction]:
    (state, action) => {
      log.log('updateBudgetsSuccessAction...\n', action.payload);
      return upsertResource(state, action.payload);
    },

  // DELETE BUDGETS ======================================== to delete 1 budget, pass as an array
  [actions.deleteBudgetsAction]:
    (state, action) => upsertResourcesSetupUndo(state, action, actions.createBudgetsAction, false),
  [actions.deleteBudgetSuccessAction]:
    (state, { payload: budgets }) => upsertResources(state, budgets),

  [actions.callFullCalcBudgetSuccessAction]:
    (state) => {
      log.log('fullrecalcBudgetsSuccessAction...\n');
      return state.merge({ isLoading: false });
    },

  [combineActions(
    actions.createBudgetsFailureAction,
    actions.updateBudgetFailureAction,
    actions.getBudgetsFailureAction,
    actions.deleteBudgetFailureAction,
    actions.recalcBudgetsFailureAction,
    actions.callFullCalcBudgetFailureAction
  )]:
    (state, { payload: error }) => completeWithError(state, error),

}, initialState);

export default budgetsReducer;
