import { DateTime } from 'luxon';
import { List as ImmutableList } from 'immutable';

import { getLogger } from '@quicken-com/react.utils.core';
import { transactionsTypes } from '@quicken-com/react.flux.transactions';
import { chartOfAccountsSelectors } from '@quicken-com/react.flux.chart-of-accounts';

import { transactionsSortFunction } from 'data/transactions/utils';


const log = getLogger('data/transactionList/selectors');


function compareDate(a, b) {
  const aDate = DateTime.fromISO(a.postedOn);
  const bDate = DateTime.fromISO(b.postedOn);
  if (aDate < bDate) {
    return -1;
  }
  if (aDate > bDate) {
    return 1;
  }
  return 0;
}

export function sortTransactions(txns, optSortBy, optSortOrder, featureFlags) {
  const sortBy =
    optSortBy !== undefined ? optSortBy : 'date';
  const sortOrder =
    optSortOrder !== undefined ? optSortOrder : 'descending';

  log.log('In selector performing SORT', sortBy, sortOrder);
  const sortFactor = sortOrder === 'ascending' ? 1 : -1;

  const sortedTxns = ImmutableList(txns.toList()).sort((a, b) => {

    let cmpValue = 0;
    switch (sortBy) {
      case 'payee':
        cmpValue = 1;

        if (a.payee === b.payee) {
          cmpValue = compareDate(a, b);
          // sometimes payee can be null
        } else if ((a.payee || '').toLowerCase() < (b.payee || '').toLowerCase()) {
          cmpValue = -1;
        }
        break;
      case 'check': {
        cmpValue = 1;

        const aChecknum = (a.check && a.check.number) || '';
        const bChecknum = (b.check && b.check.number) || '';

        if (aChecknum === bChecknum) {
          cmpValue = compareDate(a, b);
        } else if (Number.isNaN(Number(aChecknum)) || Number.isNaN(Number(bChecknum))) {
          cmpValue = aChecknum.toLowerCase() < bChecknum.toLowerCase() ? -1 : 1;
        } else {
          cmpValue = Number(bChecknum) - Number(aChecknum);
          // cmpValue = -1;
        }
        break;
      }
      case 'amount':
        cmpValue = 1;

        if (Number(a.amount) === Number(b.amount)) {
          cmpValue = compareDate(a, b);
        } else if (Number(a.amount) < Number(b.amount)) {
          cmpValue = -1;
        }
        break;
      case 'category': {
        const strA = a.coa ? chartOfAccountsSelectors.getCoaStringSelector(undefined, a.coa, true) : '';
        const strB = b.coa ? chartOfAccountsSelectors.getCoaStringSelector(undefined, b.coa, true) : '';
        cmpValue = 1;

        if (strA === strB) {
          cmpValue = compareDate(a, b);
        } else if (strA.toLowerCase() < strB.toLowerCase()) {
          cmpValue = -1;
        }
        break;
      }

      case 'reviewed':
      case 'date':
        if (featureFlags && !featureFlags.get('labelPendingAsFuture')) {
          if (a.state === 'PENDING' || b.state === 'PENDING') {
            if (a.state === b.state) {
              cmpValue = 0;
            } else {
              cmpValue = a.state === 'PENDING' ? 1 : -1;
            }
          }
        }
        if (cmpValue === 0) {
          cmpValue = transactionsSortFunction(a, b);
        }
        break;
      case 'status':
        cmpValue =
          transactionsTypes.statusOrder.indexOf(a.state) - transactionsTypes.statusOrder.indexOf(b.state);
        if (cmpValue === 0) {
          // for cash-flow uses, secondary-sort needs to be on date
          cmpValue = compareDate(a, b);
        }
        break;
      default:
        break;
    }

    // if all else equal, sort by ID to guarantee repeatable sorting
    if (cmpValue === 0) {
      cmpValue = a.id - b.id;
    }

    return cmpValue * sortFactor;
  });

  return sortedTxns;
}

