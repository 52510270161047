
import { DateTime } from 'luxon';
import { isAcme } from 'isAcme';

import { chartOfAccountsSelectors } from '@quicken-com/react.flux.chart-of-accounts';


import { getTxnStateInfo } from 'data/transactions/utils';

//
// groupTxnListByDate
//
// used by TXList selector to create date groups for register display
//
export function groupTxnListByDate(txns, featureFlags, showPendingSection = true, sortOrder = 'descending') {

  const today = DateTime.local().startOf('day');
  let groupsOfTransactions = txns.groupBy((txn) => {

    const tDate = DateTime.fromISO(txn.postedOn).startOf('day');
    let ret;

    const isFuture = (tDate > today) && featureFlags.get('labelPendingAsFuture');
    const isPending = isFuture || (txn.state === 'PENDING' && featureFlags.get('manualArePending'));

    // Pending
    if (showPendingSection && isPending) {
      ret = featureFlags.get('labelPendingAsFuture') ? '9999-99:Future' : '9999-99:Pending';

      // within past 24 months
    } else if (tDate > today.minus({ months: 24 })) {
      ret = `${tDate.toFormat('yyyy-MM:MMMM yyyy')}`;
    } else {
      // group the rest by year
      ret = `${tDate.toFormat('yyyy-00:yyyy')}`;
    }

    return ret;
  });

  // If there is a PENDING section and this is Acme, we sort that section by status
  if (isAcme) {
    let pendingSection = groupsOfTransactions.get('9999-99:Pending');

    if (pendingSection) {
      pendingSection = pendingSection.sort(pendingSortFunction);
      if (sortOrder === 'ascending') {
        pendingSection = pendingSection.reverse();
      }
      groupsOfTransactions = groupsOfTransactions.set('9999-99:Pending', pendingSection);
    }

  }

  return groupsOfTransactions;
}

function pendingSortFunction(txnA, txnB) {

  const sortOrder = ['upcoming', 'pending', 'due', 'overdue'];

  const stateA = getTxnStateInfo(txnA);
  const stateB = getTxnStateInfo(txnB);

  return sortOrder.indexOf(stateA.status.toLowerCase()) - sortOrder.indexOf(stateB.status.toLowerCase());

}
//
// groupTxnListByAmount
//
// used by TXList selector to create amount groups for register display
//
const expenseSectionTitle = 'Money Out';
const incomeSectionTitle = 'Money In';

export function groupTxnListByAmount(txns) {
  const groupsOfTransactions = txns.groupBy((txn) => {

    const amount = Number(txn.amount);

    let ret;

    switch (true) {

      case (amount >= -20) && (amount < 0):
        ret = `00:${expenseSectionTitle} 0 - 20`;
        break;
      case (amount >= -50) && (amount < -20):
        ret = `00:${expenseSectionTitle} 20 - 50`;
        break;
      case (amount >= -100) && (amount < -50):
        ret = `00:${expenseSectionTitle} 50 - 100`;
        break;
      case (amount >= -300) && (amount < -100):
        ret = `00:${expenseSectionTitle} 100 - 300`;
        break;
      case (amount >= -500) && (amount < -300):
        ret = `00:${expenseSectionTitle} 300 - 500`;
        break;
      case (amount >= -1000) && (amount < -500):
        ret = `00:${expenseSectionTitle} 500 - 1000`;
        break;
      case (amount >= -3000) && (amount < -1000):
        ret = `00:${expenseSectionTitle} 1000 - 3000`;
        break;
      case (amount >= -10000) && (amount < -3000):
        ret = `00:${expenseSectionTitle} 3000 - 10000`;
        break;
      case amount < -10000:
        ret = `00:${expenseSectionTitle} over 10000`;
        break;
      case (amount >= 0) && (amount < 100):
        ret = `00:${incomeSectionTitle} 0 - 100`;
        break;
      case (amount >= 100) && (amount < 500):
        ret = `00:${incomeSectionTitle} 100 - 500`;
        break;
      case (amount >= 500) && (amount < 1000):
        ret = `00:${incomeSectionTitle} 500 - 1000`;
        break;
      case (amount >= 1000) && (amount < 5000):
        ret = `00:${incomeSectionTitle} 1000 - 5000`;
        break;
      case amount >= 5000:
        ret = `00:${incomeSectionTitle} over 5000`;
        break;

      default:
        ret = '14:Other';
        break;
    }

    return ret;
  });

  // groupsOfTransactions.keySeq().forEach((key) => {
  //   log.log(key);
  // });

  return groupsOfTransactions;
}

//
// groupTxnListByString
//
// used by TXList selector to create groups grouped alphabetically for register display
//
export function groupTxnListByString(txns, field) {
  const groupsOfTransactions = txns.groupBy((txn) => {

    let startsWith;
    let strValue;

    switch (field) {
      case 'check':
        strValue = txn.check ? txn.check.number : ' ';
        startsWith = strValue.charAt(0).toLowerCase();
        break;
      case 'category':
        strValue = txn.coa ? chartOfAccountsSelectors.getCoaStringSelector(undefined, txn.coa, true) : ' ';
        startsWith = strValue.charAt(0).toLowerCase();
        break;
      default:
        strValue = txn.get(field);
        if (strValue && strValue.length > 0) {
          startsWith = strValue.charAt(0).toLowerCase();
        } else {
          startsWith = ' ';
        }
    }

    let ret;

    strValue = strValue || ' ';

    switch (true) {

      case strValue.toLowerCase() === 'future':
        ret = '00:FUTURE';
        break;
      case strValue.toLowerCase() === 'pending':
        ret = isAcme ? '00:Pending' : '00:Uncleared';
        break;
      case strValue.toLowerCase() === 'cleared':
        ret = '00:Cleared';
        break;
      case strValue.toLowerCase() === 'reconciled':
        ret = '00:Reconciled';
        break;

      case startsWith >= 'a' && startsWith <= 'd':
        ret = '00:A - D';
        break;
      case startsWith >= 'e' && startsWith <= 'h':
        ret = '01:E - H';
        break;
      case startsWith >= 'i' && startsWith <= 'l':
        ret = '02:I - L';
        break;
      case startsWith >= 'm' && startsWith <= 'p':
        ret = '03:M - P';
        break;
      case startsWith >= 'q' && startsWith <= 't':
        ret = '04:Q - T';
        break;
      case startsWith >= 'u' && startsWith <= 'z':
        ret = '05:U - Z';
        break;
      case startsWith >= '0' && startsWith <= '9':
        ret = '06:Numbers';
        break;

      default:
        ret = '07:Other';
        break;
    }
    return ret;
  });

  // groupsOfTransactions.keySeq().forEach((key) => {
  //   log.log(key);
  // });

  return groupsOfTransactions;
}
