// BASE ============================================================================
import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { exists } from 'utils/utils';

// MUI IMPORTS  ============================================================================
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';

// STYLES IMPORT ============================================================================
import { styles } from './styles';
const useStyles = makeStyles(styles);

// returns via change function true, false, or undefined
const ThreeStateOption = (props) => {

  // PROPS ============================================================================
  const { value, onChange, onOffText = 'Default Resource', idPrefix } = props;

  // STATE ============================================================================
  const isEnabled = exists(value);

  // STYLES ============================================================================
  const classes = useStyles(props);

  // RENDER ============================================================================
  return (
    <div className={classes.root} sharedcomponentid={'THREE_STATE_OPTION'}>
      <div className={classes.radioGroup}>
        <div className={classes.radioItemContainer}>
          <Radio
            className={classes.radioItem}
            onClick={(() => onChange(isEnabled && value ? undefined : true))}
            checked={isEnabled && value}
            id={`advanced_filter_${idPrefix}_radio_on`}
          />
          <Typography variant="body2" className={classes.labelText}>
            {`${onOffText}`}
          </Typography>
        </div>
        <div className={classes.radioItemContainer}>
          <Radio
            className={classes.radioItem}
            onClick={(() => onChange(isEnabled && !value ? undefined : false))}
            checked={isEnabled && !value}
            id={`advanced_filter_${idPrefix}_radio_off`}
          />
          <Typography variant="body2" className={classes.labelText}>
            {`Not ${onOffText.toLowerCase()}`}
          </Typography>
        </div>
      </div>
    </div>
  );
};
ThreeStateOption.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  onOffText: PropTypes.string,
  idPrefix: PropTypes.string,
};

export default ThreeStateOption;
