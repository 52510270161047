import { createGlobalStyle } from 'styled-components';
/* eslint no-unused-expressions: 0 */
/* note, the _:-ms-lang.. blah blah is a way to identify a microsoft browser (bleccch) */
// https://stackoverflow.com/questions/32201586/how-to-identify-microsoft-edge-browser-via-css

const GlobalStyles = createGlobalStyle`
  html,
  body {
    background-color: ${(process.env.PROJECT === 'acme') ? 'white' : '#f5f5f5'};
    height: 100%;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body iframe {
    opacity: ${(process.env.PROJECT === 'acme') ? '1' : '0.5'};
  }
  body iframe:hover {
    opacity: 1;
  }
  _:-ms-lang(x), _:-webkit-full-screen, body iframe
  {
      opacity: 1;
  }

  body {
    font-family: Roboto, Helvetica, Arial, sans-serif;
  }

  body.fontLoaded {
    font-family: Roboto, Helvetica, Arial, sans-serif;
  }

  #app {
    background-color: ${(process.env.PROJECT === 'acme') ? 'white' : '#f5f5f5'};
    min-height: 100%;
    min-width: 100%;
  }

  input::-ms-clear {
    display: none;
  }

  #main {
    margin: 0 auto;
    min-height: 100%;
    max-width: 100%;
  }
`;

export default GlobalStyles;
