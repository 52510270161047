import { getLogger } from '@quicken-com/react.utils.core';
import * as types from './types';

const log = getLogger('data/investmentHoldings/transformers.js');

export function transformIHoldToRequestData() {
  // JLA TODO - May not need filling out
  return null;
}

export function transformResponseToIHolds(holdingList, securities) {
  return holdingList.map((holding) => (transformApiDataToIHold(holding, securities)));
}

// /////////////////////////////////////////////////////////////////////////////
// Transformer for mapping Real Time Quotes to securities
// /////////////////////////////////////////////////////////////////////////////
export function transformRealTimeQuotes(securities, quotes) {
  return securities.map((security) => transformQuotesToSecurities(security, quotes));
}

function transformQuotesToSecurities(security, quotes) {
  const quotesObj = quotes.find((quote) => quote.symbol === security.tickerSymbol);
  // Quotes response will not have 'id'. So merge it with security.
  // TODO: take only missing fields from security
  return quotesObj ? {
    ...security,
    companyName: quotesObj.name || security.companyName,
    tickerSymbol: quotesObj.symbol || security.tickerSymbol,
    quoteChange: quotesObj.amtChange ?? security.quoteChange,
    quoteChangePercentage: quotesObj.pctChange ?? security.quoteChangePercentage,
    yearHighPrice: quotesObj.fiftyTwoWeekHigh ?? security.yearHighPrice,
    yearLowPrice: quotesObj.fiftyTwoWeekLow ?? security.yearLowPrice,
    closePrice: quotesObj.previousClosePrice ?? security.closePrice,
    latestPrice: quotesObj.lastPrice ?? security.latestPrice,
    volume: quotesObj.volume ?? security.volume,
    highestPrice: quotesObj.dayHigh ?? security.highestPrice,
    lowestPrice: quotesObj.dayLow ?? security.lowestPrice,
    openPrice: quotesObj.openPrice ?? security.openPrice,
    peRatio: quotesObj.peRatio ?? security.peRatio,
    divYield: quotesObj.divYield ?? security.divYield,
    marketCap: quotesObj.mktCap ?? security.marketCap,
    averageVolume: quotesObj.avgVolume ?? security.averageVolume,
    timestamp: quotesObj.quoteTime ? Math.floor((new Date(quotesObj.quoteTime)).getTime() / 1000) : security.timeStamp,
  } : security;
}

// /////////////////////////////////////////////////////////////////////////////
// Transformers for Investment Holding API Responses to Investment Holdings
// /////////////////////////////////////////////////////////////////////////////

function transformApiDataToIHold(data, securities) {
  return types.mkInvestmentHolding(transformApiDataToIHoldMap(data, securities));
}

function transformHolding(data, security) {
  const { numberOfShares } = data;

  const closePrice = security.closePrice === undefined ? 0 : security.closePrice;
  const divYield = security.divYield === undefined ? 0 : security.divYield;
  const highestPrice = security.highestPrice === undefined ? 0 : security.highestPrice;
  const { averageVolume, latestPrice, lowestPrice, openPrice, peRatio, volume, yearHighPrice, yearLowPrice } = security;

  const dateOfQuote = new Date(security.timestamp * 1000).setHours(0, 0, 0, 0);
  const todaysDate = new Date().setHours(0, 0, 0, 0);

  const quoteIsToday = dateOfQuote === todaysDate;

  let quoteChange;
  let quoteChangePercentage;

  if (quoteIsToday) {
    quoteChange = security.quoteChange;
    quoteChangePercentage = security.quoteChangePercentage === undefined ? 0 : security.quoteChangePercentage;
  } else {
    quoteChange = 0;
    quoteChangePercentage = 0;
  }

  let valueChange = quoteChange * numberOfShares;

  let showInInvestmentPanel = true;

  let amount = numberOfShares * latestPrice;
  if (Number.isNaN(amount)) amount = 0;
  let amountDelta = quoteChange * numberOfShares;

  /*
  log.debug(`security.tickerSymbol = ${security.tickerSymbol}`);
  log.debug(`amountDelta = ${amountDelta}`);
  log.debug(`latestPrice = ${latestPrice}`);
  log.debug(`openPrice = ${openPrice}`);
  log.debug(`quoteChange = ${quoteChange}`);
  */

  if (Number.isNaN(amountDelta)) amountDelta = 'N/A';

  if (Number.isNaN(valueChange) || amountDelta === 'N/A') {
    valueChange = 0;
    showInInvestmentPanel = false;
  }

  if (amountDelta === 0) showInInvestmentPanel = false;

  return {
    id: data.id,
    clientId: data.clientId,
    createdAt: data.createdAt,
    modifiedAt: data.modifiedAt,
    numberOfShares,
    accountID: data.accountID,
    securityID: data.securityID,
    isDeleted: data.isDeleted ? data.isDeleted : false,
    deleted: data.deleted,
    coa: null,

    // Security fields

    amount,
    amountDelta,
    averageVolume,
    assetClassResourceType: security.assetClassResourceType,
    balance: numberOfShares * latestPrice,
    closePrice,
    companyName: security.companyName,
    divYield,
    highestPrice,
    latestPrice,
    lowestPrice,
    openPrice,
    peRatio,
    quoteChange,
    quoteChangePercentage,
    security,
    securityType: security.securityType,
    showInInvestmentPanel,
    tickerSymbol: security.tickerSymbol,
    valueChange,
    volume,
    yearHighPrice,
    yearLowPrice,
  };
}

function transformApiDataToIHoldMap(data, securities) {
  const security = securities.find((sec) => (sec.id === data.securityID));
  if (!security) {
    log.error(`CAN'T FIND SECURITY! FOR SECURITY ID = ${data.securityID}`);
    return {};
  }

  return transformHolding(data, security);
}

// /////////////////////////////////////////////////////////////////////////////
// Transformers for Investment Holding to Investment Holding API Request Data
// /////////////////////////////////////////////////////////////////////////////

// JLA TODO
