import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { isOldEdge } from 'utils/utils';
import StdDialog from 'components/Dialogs/StdDialog';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import { formatAmount } from 'components/QCurrency/core';
import { filterCalendarTxnBasedOnPreferences, filterDashboardCalendarTxnBasedOnPref } from 'data/transactions/selectors';
import TableHeader from './TableHeader';
import TableTxnRow from './TableTxnRow';
import { calendarTxnModalStyles } from '../../styles';

const isIe = require('is-iexplorer') || isOldEdge();  // eslint-disable-line

const useStyles = makeStyles(calendarTxnModalStyles);

const tableColumns = Object.freeze({
  reminderCol: {
    label: '',
  },
  payeeCol: {
    label: 'Payee',
  },
  accountCol: {
    label: 'Account',
  },
  categoryCol: {
    label: 'Category',
  },
  amountCol: {
    label: 'Amount',
  },
  optionsCol: {
    label: '',
  },
});

const CalTxnsModal = (props) => {
  const classes = useStyles();
  const { onClose, date, txns, onGoToRegister, onGoToBills, useDashboardPrefFilter } = props;

  const filterCalendarTxnList = useDashboardPrefFilter ? filterDashboardCalendarTxnBasedOnPref : filterCalendarTxnBasedOnPreferences;
  const filteredTxnList = useSelector((state) => filterCalendarTxnList(state, { txnList: txns }));
  const modalTitle = useMemo(() => <div className={classes.modalTitle}>{date}</div>, [date, classes]);
  const txnsTotal = useMemo(() => filteredTxnList &&
    filteredTxnList.reduce((acc, txn) => parseFloat(acc) + parseFloat(txn.amount ?? 0), 0), [filteredTxnList]);

  const onCloseHandler = useCallback((event) => {
    event.stopPropagation();
    onClose && onClose();
  }, [onClose]);

  const onHandleModalClick = useCallback((event) => event.stopPropagation(), []);

  const tableContent = useMemo(() => (
    filteredTxnList?.size ? (
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader padding="none" size="small">
          <TableHeader columns={tableColumns} classes={classes} />
          <TableBody>
            {filteredTxnList.map((txn) =>
              <TableTxnRow
                key={`row-${txn.id}`}
                columns={tableColumns}
                classes={classes}
                txn={txn}
                onGoToRegister={onGoToRegister}
                onGoToBills={onGoToBills}
              />)}
          </TableBody>
        </Table>
        <Table padding="none" size="small" className={classes.footerTable}>
          <TableFooter>
            <TableRow>
              <TableCell className={classes.reminderCol} />
              <TableCell className={classes.totalLabelCell}>
                <div className={classes.cellContainer}>Total</div>
              </TableCell>
              <TableCell className={classes.totalAmountCell}>
                <div className={classes.cellContainer}>{formatAmount(txnsTotal)}</div>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    ) : <div>No Transactions</div>
  ), [classes, filteredTxnList, txnsTotal, onGoToBills, onGoToRegister]);

  return (
    <StdDialog
      open
      classes={isIe ? { paper: classes.dialogPaper } : {}}
      onClose={onCloseHandler}
      title={modalTitle}
      maxWidth="xlg"
      onClick={onHandleModalClick}
    >
      <DialogContent>
        {tableContent}
      </DialogContent>
      <div className={classes.buttonArea}>
        <Button
          color="primary"
          onClick={onCloseHandler}
          id="txn-modal-done"
        >
          Done
        </Button>
      </div>

    </StdDialog>
  );
};

CalTxnsModal.propTypes = {
  onClose: PropTypes.func,
  date: PropTypes.string,
  txns: PropTypes.object,
  onGoToRegister: PropTypes.func,
  onGoToBills: PropTypes.func,
  useDashboardPrefFilter: PropTypes.bool,
};


CalTxnsModal.whyDidYouRender = true;
export default React.memo(CalTxnsModal);
