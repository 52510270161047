// @flow

// Register / UnRegister Service Worker

// This lets the app load faster on subsequent visits in production, and gives it offline capabilities (plus notifications).
// However, it also means that developers (and users) will only see deployed updates on subsequent visits to a page, after
// all the existing tabs open on the page have been closed, since previously cached resources are updated in the background.
import { Workbox, messageSW } from 'workbox-window';

import { appSetServiceWorkerIsRegistered, appSetServiceWorkerIsRegistering, appSetServiceWorkerUpdateIsAvailable } from 'data/app/actions';
import { getLogger, tracker } from '@quicken-com/react.utils.core';

import store from 'store';

const log = getLogger('swMain');

let wb = null;
let swRegistration = null;

export const getSwRegistration = () => swRegistration;
export const isRegistering = () => wb !== null && swRegistration === null;
export const isRegistered = () => swRegistration !== null;

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  window.location.hostname === 'machost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

export function register(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    log.debug('Calling registerServiceWorker');
    tracker.track(tracker.events.serviceWorker, { action: 'registered' });

    const swUrl = 'sw.js';

    if (isLocalhost) {
      // This is running on localhost. Let's check if a service worker still exists or not.
      registerServiceWorkerOnLocalhost(swUrl, config);

      // Add some additional logging to localhost, pointing developers to the service worker/PWA documentation.
      navigator?.serviceWorker?.ready.then(() =>
        log.warn('This web app is being served cache-first by a service worker'));
    } else {
      // Is not localhost. Just register service worker
      registerSW(swUrl);
    }
  }
}

export function unregister() {
  if ('serviceWorker' in navigator && swRegistration?.active) {
    log.log('Sending unregister message to ServiceWorker');
    messageSW(swRegistration.active, { type: 'UNREGISTER_SERVICE_WORKER' });

    wb = null;
    swRegistration = null;
  }
}

// This method can be called to activate a waiting service-worker. This will trigger a listener in the sw.js file
// that will initiate the process of the current service-worker shutting down and a new service-worker taking over.
//
export function activateUpdatedApp(method) {

  if (swRegistration?.waiting) {
    log.log('Sending activate message to ServiceWorker');
    messageSW(swRegistration.waiting, { type: 'SKIP_WAITING' });
    tracker.track(tracker.events.serviceWorker, { action: 'activating', method });
  } else {
    log.log('Received call to activate service worker, but no service worker is waiting', swRegistration);

    // notify app that an update is no longer available
    store.dispatch(appSetServiceWorkerUpdateIsAvailable(false));
  }

}

async function registerSW(swUrl) {
  if (wb !== null) {
    if (!swRegistration) {
      log.log('Trying to register a service worker while registration is already in progress', swUrl);
    } else {
      log.log('Trying to register a service worker which is already registered', swUrl);
    }
    return;
  }

  store.dispatch(appSetServiceWorkerIsRegistering(true));

  wb = new Workbox(swUrl);
  wb.addEventListener('installed', (event) => {
    if (!event.isUpdate) {
      log.log('New ServiceWorker is first installed', event);
      // All PreCached assets should now be available.
      //
      // Since this is the first install, the functionality of the app should meet the functionality of the
      // service worker! So send a message telling the service worker to claim the clients.
      wb.messageSW({ type: 'CLIENTS_CLAIM' });
    } else {
      log.log('New ServiceWorker is installed', event);
      // A new service worker is installed (different than the one currenctly serving our app)
      //
      // We do not call skipWaiting here cause we don't want the app to start using the new service
      // worker w/o re-loading and we don't want to force a re-load here in case user is busy doing
      // something (like entering data).
    }
  });

  wb.addEventListener('controlling', (event) => {
    log.log('New ServiceWorker has started controlling page', event);
    window.location.reload();
  });

  wb.addEventListener('waiting', (event) => {
    log.log('New ServiceWorker has installed but is stuck in waiting phase', event);
    store.dispatch(appSetServiceWorkerUpdateIsAvailable(true));
    tracker.track(tracker.events.serviceWorker, { action: 'waiting' });
  });

  wb.addEventListener('activated', (event) => {
    log.log('New ServiceWorker has finished activating', event);
    store.dispatch(appSetServiceWorkerUpdateIsAvailable(false));
  });

  wb.addEventListener('externalinstalling', (event) => {
    log.log('New external ServiceWorker is installing', event);
  });

  wb.addEventListener('externalwaiting', (event) => {
    log.log('New external ServiceWorker is waiting', event);
    store.dispatch(appSetServiceWorkerUpdateIsAvailable(true));
    tracker.track(tracker.events.serviceWorker, { action: 'externalwaiting' });
  });

  wb.addEventListener('externalactivated', (event) => {
    log.log('New external ServiceWorker has activated', event);
    window.location.reload();
  });

  // Register ServiceWorker and check for updates every minute
  try {
    swRegistration = await wb.register();
    store.dispatch(appSetServiceWorkerIsRegistered(true));
    setInterval(() => checkForUpdate(), 60 * 1000);
  } catch (e) {
    store.dispatch(appSetServiceWorkerIsRegistering(false));
    log.error('Failed to register ServiceWorker', e);
  }
}

async function checkForUpdate() {
  if (navigator.onLine) {
    // only call update if we are onLine
    try {
      log.log('Updating ServiceWorker');
      await swRegistration.update();
    } catch (e) {
      log.error('ServiceWorker could not be updated', e);
    }
  }
}

// Checks if a service worker can be found. If found, will register as normal; otherwise,
// will unregister any current service worker and reload page.
function registerServiceWorkerOnLocalhost(swUrl, config) {
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (response.status === 404 || (contentType != null && contentType.indexOf('javascript') === -1)) {
        // No service worker found. Reload the page.
        navigator?.serviceWorker?.ready.then((registration) => {
          registration.unregister().then(() => window.location.reload());
        });
      } else {
        // Service worker found. Proceed as normal.
        registerSW(swUrl, config);
      }
    })
    .catch(() => {
      log.info('No internet connection found. App is running in offline mode.');
    });
}
