import React from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { DateTime } from 'luxon';
import HeightIcon from '@mui/icons-material/Height';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import EditIcon from '@mui/icons-material/Edit';
// import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

import {
  BUDGET_ANNUAL_MONTHS,
  BUDGET_ANNUAL_SUBVIEWS,
  BUDGET_VIEWS,
  BUDGET_ANNUAL_COPY_OPTIONS,
} from './constants';

export const budgetOptions = ({
  isToDateColumnEnabled,
  handleShowToDateSetting,
}) => ({
  label: 'Budget Options',
  items: [
    {
      label: 'View Options',
      items: [
        {
          label: 'Show to-date column in annual view',
          leftIcon: isToDateColumnEnabled ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />,
          callback: () => handleShowToDateSetting(),
        },
      ],
    },
  ],
});

export const getProgressColor = (balanceAmount, redColor, greenColor) => balanceAmount < 0 ? redColor : greenColor;

export const getHighlightColor = (numMonth, currentMonth, currentYear, budgetEndYear, color, isToDate = false) =>
  !isToDate && currentMonth === numMonth && budgetEndYear >= currentYear && color;

export const getMonthlyCellSxProps = (
  startDate,
  currentMonth,
  currentYear,
  budgetEndYear,
  color,
  border,
  isToDate = false,
  isSelected = false,
  colorSelectedRow = '',
) => {
  const monthFromStartDate = DateTime.fromISO(startDate).month;
  return {
    border,
    p: 0,
    backgroundColor: isSelected
      ? colorSelectedRow
      : getHighlightColor(monthFromStartDate, currentMonth, currentYear, budgetEndYear, color, isToDate),
  };
};

const arrangeMonthlyCellsOrder = (arrMonths, currentMonth, budgetEndMonth, isToDateEnabled) => {
  const pushMonthCells = [];
  if (isToDateEnabled) {
    arrMonths.forEach((objMonth) => {
      if (objMonth.number === currentMonth && currentMonth !== budgetEndMonth) {
        pushMonthCells.push(objMonth, {
          number: -1,
          shortName: 'To Date',
          name: 'To Date',
        });
      } else {
        pushMonthCells.push(objMonth);
      }
    });
  } else {
    pushMonthCells.push(...arrMonths);
  }

  return pushMonthCells;
};

export const getIterableMonthCells = (
  budgetStartMonth,
  currentMonth,
  budgetEndMonth,
  budgetStartYear,
  budgetEndYear,
  isToDateEnabled = false
) => {
  // IF it's staring from Jan month, then it's normal process
  if (budgetStartMonth === 1) {
    return arrangeMonthlyCellsOrder(BUDGET_ANNUAL_MONTHS, currentMonth, budgetEndMonth, isToDateEnabled);
  }

  // Otherwise, special treatment
  const passedMonths = BUDGET_ANNUAL_MONTHS.slice(0, budgetStartMonth - 1)
    .map((objMonth) => ({ ...objMonth, year: budgetEndYear }));
  const futureMonths = BUDGET_ANNUAL_MONTHS.slice(budgetStartMonth - 1)
    .map((objMonth) => ({ ...objMonth, year: budgetStartYear }));
  return arrangeMonthlyCellsOrder([...futureMonths, ...passedMonths], currentMonth, budgetEndMonth, isToDateEnabled);
};

export const getIterableCells = (monthlyAmounts, budgetEndMonth, isDetailsView = false, isToDateEnabled = false) => {
  const arrCellsObjects = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const objMonth of monthlyAmounts) {
    const currentMonth = objMonth.startDate.month;
    if (hideToDateColumn(isToDateEnabled, objMonth.isToDate, currentMonth, budgetEndMonth)) {
      // eslint-disable-next-line no-continue
      continue;
    }
    if (!isDetailsView || objMonth.isToDate) {
      arrCellsObjects.push(objMonth);
    } else {
      arrCellsObjects.push(
        { ...objMonth, amount: objMonth.budgetAmount },
        { ...objMonth, amount: objMonth.actualAmount },
        { ...objMonth, amount: objMonth.balanceAmount, isBalanceAmount: true },
      );
    }
  }

  return arrCellsObjects;
};

export const getAmountColor = (
  viewType,
  amount,
  colorActual,
  colorBalance,
  isBalanceAmount = false,
  isToDate = false,
  isTotalColumn = false,
) => {
  let colorAmount = 'inherit';
  if (isToDate || isTotalColumn) {
    return amount < 0 ? colorBalance : colorAmount;
  }
  if (viewType === BUDGET_ANNUAL_SUBVIEWS.actual.key && !isTotalColumn) {
    colorAmount = colorActual;
  } else if ((isBalanceAmount || isToDate || viewType === BUDGET_ANNUAL_SUBVIEWS.balance.key) && amount < 0) {
    colorAmount = colorBalance;
  }

  return colorAmount;
};

export const getBudgetViewStateObject = (id, name, viewType, subViewType) => ({
  budgetOptions: {
    viewState: {
      [id]: {
        name,
        viewType,
        subViewType,
      },
    },
  },
});

export const hideToDateColumn = (isToDateColumnEnabled, isToDate, currentMonth, budgetEndMonth) =>
  isToDate && (!isToDateColumnEnabled || currentMonth === budgetEndMonth);

export const extractDetailsForTracker = (sharedPreferences) => {
  const trackingDetails = {};
  if (sharedPreferences && sharedPreferences.budgetOptions && sharedPreferences.budgetOptions.lastViewedBudgetId) {
    const { lastViewedBudgetId } = sharedPreferences.budgetOptions;
    if (sharedPreferences && sharedPreferences.webApp && sharedPreferences.webApp.budgetOptions && sharedPreferences.webApp.budgetOptions.viewState) {
      const { viewState } = sharedPreferences.webApp.budgetOptions;
      const lastViewedBudgetToTrack = viewState[lastViewedBudgetId];
      if (lastViewedBudgetToTrack) {
        const { viewType, subViewType } = lastViewedBudgetToTrack;
        trackingDetails.budgetView = viewType;
        if (viewType === BUDGET_VIEWS.annual.key) {
          trackingDetails.annualViewType = BUDGET_ANNUAL_SUBVIEWS[subViewType].label;
        }
      }
    }
  }
  return trackingDetails;
};

const getStringForOptions = (month, budgetStartYear, budgetEndYear) => ({
  monthShortName: BUDGET_ANNUAL_MONTHS[month - 1]?.shortName,
  suffixFY: budgetStartYear === budgetEndYear ? '' : '(FY)',
});

export const generateEditOptionsForAnnualTable = (
  monthSelected,
  budgetStartYear,
  budgetEndYear,
  handleApplyAction,
  handleEditYearlyAction,
  // handleCalculateAverageAction
) => {
  const { monthShortName, suffixFY } = getStringForOptions(monthSelected.month, budgetStartYear, budgetEndYear);

  return [
    {
      label: `Apply ${monthShortName} budget forward to the end of ${budgetEndYear} ${suffixFY}`,
      value: BUDGET_ANNUAL_COPY_OPTIONS.restOfYear,
      action: handleApplyAction,
      icon: <UpgradeIcon sx={{ transform: 'rotate(90deg)' }} />,
    },
    {
      label: `Apply ${monthShortName} budget to all of ${budgetEndYear} ${suffixFY}`,
      value: BUDGET_ANNUAL_COPY_OPTIONS.wholeYear,
      action: handleApplyAction,
      icon: <HeightIcon sx={{ transform: 'rotate(90deg)' }} />,
    },
    {
      label: 'Edit yearly budget',
      value: 'editYearlyBudget',
      action: handleEditYearlyAction,
      icon: <EditIcon fontSize="small" />,
    },
    // TODO hide calculate average
    // {
    //   label: 'Calculate average budget',
    //   value: 'calculateAverageBudget',
    //   action: handleCalculateAverageAction,
    //   icon: <SettingsSuggestIcon fontSize="small" />,
    // },
  ];
};

export const generateMonthCopyOptions = (
  monthClicked,
  budgetStartMonth,
  budgetEndMonth,
  budgetStartYear,
  budgetEndYear,
  handleMonthCopyAction,
) => {
  if (!monthClicked) { return []; }

  const { monthShortName, suffixFY } = getStringForOptions(monthClicked.month, budgetStartYear, budgetEndYear);
  return [
    {
      key: BUDGET_ANNUAL_COPY_OPTIONS.restOfYear,
      label: `Copy ${monthShortName} budgets to all future months in ${budgetEndYear} ${suffixFY}`,
      action: handleMonthCopyAction,
      icon: <UpgradeIcon sx={{ transform: 'rotate(90deg)' }} />,
      disabled: monthClicked.month === budgetEndMonth,
    },
    {
      key: BUDGET_ANNUAL_COPY_OPTIONS.pastMonths,
      label: `Copy ${monthShortName} budgets to all past months in ${budgetEndYear} ${suffixFY}`,
      action: handleMonthCopyAction,
      icon: <UpgradeIcon sx={{ transform: 'rotate(270deg)' }} />,
      disabled: monthClicked.month === budgetStartMonth,
    },
    {
      key: BUDGET_ANNUAL_COPY_OPTIONS.wholeYear,
      label: `Copy ${monthShortName} budgets to all months in ${budgetEndYear} ${suffixFY}`,
      action: handleMonthCopyAction,
      icon: <HeightIcon sx={{ transform: 'rotate(90deg)' }} />,
    },
  ];
};

export const validateAmount9Digits2Decimals = (value) => /^[+ -]?[0-9]{0,9}([.][0-9]{0,2})?$/.test(value);
