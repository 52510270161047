import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import compose from 'utils/compose';
import { List as ImmutableList } from 'immutable';

import { accountsSelectors } from '@quicken-com/react.flux.accounts';
import { categoriesSelectors } from '@quicken-com/react.flux.categories';
import { scheduledTransactionsUtils } from '@quicken-com/react.flux.scheduled-transactions';

import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import CheckCircle from '@mui/icons-material/CheckCircle';
import QButton from 'components/QButton';
import QTip from 'components/QuickenControls/QTip';
import AmountField, { ShowSignEnum, ShowColorEnum } from 'components/QuickenControls/AmountField';
import { userFriendlyTxnNames } from 'data/scheduledTransactions/scheduledTransactionsUtils';
import { ScheduleTransactionSelectorStyles as styles } from 'components/Dialogs/WfBillScout/styles';
import zeroStateIcon from 'assets/zero-state-images/safebox.svg';
import ZeroStateView from 'components/ZeroStateView';
import DialogHoc, { dialogActions } from 'containers/Settings/ChildPages/Accounts/DialogHoc';

export const INCOME_STEP = 'INCOME';
export const BILLS_STEP = 'EXPENSE';

const actions = {
  [`${BILLS_STEP}`]: 'Missing any other bills? find them from:',
  [`${INCOME_STEP}`]: 'Missing any other income?',
};

class ScheduleTransactionSelector extends React.PureComponent {

  static propTypes = {
    // from withStyles
    classes: PropTypes.object,

    scheduledTransactions: PropTypes.object,
    allUpcomingTxns: PropTypes.object,
    categoriesById: PropTypes.object,
    accountsById: PropTypes.object,

    onGoBack: PropTypes.func,
    setDialog: PropTypes.func,
    deleteScheduleTransaction: PropTypes.func,
    setEditScheduledTransaction: PropTypes.func,
    createEmptyScheduleTransaction: PropTypes.func,
    selected: PropTypes.object,
    setSelectedItems: PropTypes.func,
    openTransactionSelector: PropTypes.func,
    showFindingRecurring: PropTypes.bool,
    type: PropTypes.string,
    reviewCurrentStep: PropTypes.bool,
    hideAddManually: PropTypes.bool,
    baseId: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.setInitialSelection();
  }

  componentDidUpdate(prevProps) {
    const { scheduledTransactions: pScheduledTransactions, type: pType } = prevProps;
    const { scheduledTransactions, type } = this.props;

    if (!pScheduledTransactions.equals(scheduledTransactions) ||
      pScheduledTransactions.size !== scheduledTransactions.size ||
      pType !== type) {
      this.setInitialSelection();
    }
  }

  setInitialSelection() {
    const { type } = this.props;
    const newSelectedList = this.getSelectedItems().toSet().toList();
    this.props.setSelectedItems(type, newSelectedList);
  }

  CellTitle = (props) => (
    <QTip title={props.title} wrapOnly>
      <span id={props.id} className={this.props.classes.cellTitle}>{props.title}</span>
    </QTip>
  );

  CellCaption = (props) => (
    <span id={props.id} className={this.props.classes.cellCaption}>{props.caption}</span>
  );

  toggleTransactionSelection = (type, id) => () => {
    const isSelected = this.checkItemSelected(id);
    let selectedItems = this.getSelectedItems();

    if (isSelected) {
      selectedItems = selectedItems.filterNot((x) => x === id);
    } else {
      selectedItems = selectedItems.push(id);
    }

    this.props.setSelectedItems(type, selectedItems);
  };

  checkItemSelected(id) {
    const selectedItems = this.getSelectedItems();
    return selectedItems.findIndex((x) => x === id) >= 0;
  }

  getSelectedItems() {
    return this.props.selected || ImmutableList();
  }

  handleDelete = (scheduledTransaction) => () => {
    this.props.setDialog(
      'Bills and Income',
      'Do you want to delete this schedule transaction?',
      (dialog) => {
        if (['YES', dialogActions.ENTER].includes(dialog.action)) {
          this.props.deleteScheduleTransaction(scheduledTransaction);
        }
      },
      ['CANCEL', 'YES'],
    );
  };

  renderRow(scheduledTransaction, key, reviewCurrentStep) {
    const { CellTitle, CellCaption } = this;
    const { classes, categoriesById, accountsById, allUpcomingTxns, type, setEditScheduledTransaction } = this.props;
    const selectedRow = this.checkItemSelected(scheduledTransaction.id);
    const upTxn = allUpcomingTxns.get(scheduledTransaction && scheduledTransaction.id);
    const schTxn = userFriendlyTxnNames(scheduledTransaction, categoriesById, accountsById, (upTxn && upTxn.first()));
    const baseID = `scheduled-txn-${key}`;

    return (
      <TableRow
        key={scheduledTransaction.clientId || scheduledTransaction.id}
        id={baseID}
        hover
        className={classes.standardRow}
      >
        <TableCell className={classes.tableCellAvatar} classes={{ root: classes.tableCellRoot }}>
          <div className={classes.tableCellAvatarContent}>
            <Avatar className={classes.avatar}>
              {((scheduledTransaction.transaction || {}).payee || '')[0]}
            </Avatar>
          </div>
        </TableCell>

        <TableCell className={classes.tableCell} classes={{ root: classes.tableCellRoot }}>
          <div className={classes.tableCellPayeeContent}>
            <CellTitle id={`${baseID}-name`} title={schTxn.payeeName} />
            <CellCaption id={`${baseID}-type`} caption={scheduledTransactionsUtils.STTypeNamesEnum[schTxn.type]} />
          </div>
        </TableCell>

        <TableCell className={classes.tableCell} classes={{ root: classes.tableCellRoot }}>
          <div className={classes.tableCellAmountContent}>
            <AmountField
              showSign={ShowSignEnum.NEGATIVE_ONLY}
              showColor={ShowColorEnum.POSITIVE_ONLY}
              value={schTxn.amount}
              className={classes.cellTitle}
              id={`${baseID}-amount`}
            />
            <CellCaption id={`${baseID}-next-instance-date`} caption={schTxn.nextDateFormatted} />
          </div>
        </TableCell>

        <TableCell className={classes.tableCell} classes={{ root: classes.tableCellRoot }}>
          <div className={classes.tableCellAccountContent}>
            <CellTitle id={`${baseID}-source-account`} title={schTxn.accountName} />
            <CellCaption id={`${baseID}-frequency`} caption={schTxn.frequency} />
          </div>
        </TableCell>

        <TableCell className={classes.tableCellActions} classes={{ root: classes.tableCellRoot }}>
          <div className={classes.tableCellActionsContent}>
            <IconButton
              onClick={() => { setEditScheduledTransaction(scheduledTransaction); }}
              size="large"
            >
              <Edit />
            </IconButton>
            {reviewCurrentStep && (
              <IconButton onClick={this.handleDelete(scheduledTransaction)} size="large">
                <Delete />
              </IconButton>
            )}
            {!reviewCurrentStep && !scheduledTransaction.isUserVerified && (
              <IconButton
                onClick={this.toggleTransactionSelection(type, scheduledTransaction.id)}
                aria-label="select"
                size="large"
              >
                {selectedRow ? (
                  <CheckCircle className={classes.selectedIcon} />
                ) : (
                  <AddIcon className={classes.selectIcon} />
                )}
              </IconButton>
            )}
          </div>
        </TableCell>
      </TableRow>
    );
  }

  renderAddMissing(type, reviewCurrentStep) {
    const { classes, baseId, hideAddManually } = this.props;
    const actionTitle = actions[type];

    const buttons = [
      {
        id: baseId('action-back-button'),
        onClick: this.props.onGoBack,
        text: 'Suggestions',
        show: true,
      },
      {
        id: baseId('find-transactions-button'),
        onClick: this.props.openTransactionSelector,
        text: 'Transactions',
        show: true,
      },
      {
        id: baseId('add-manual-button'),
        onClick: this.props.createEmptyScheduleTransaction,
        text: 'Add Manually',
        show: !hideAddManually,
      },
    ].filter((x) => x.show);

    return (
      <Collapse in={reviewCurrentStep} classes={{ root: classes.collapseArea, wrapper: classes.collapseAreaWrapper }}>
        <Grid container className={classes.bottomActionsTitleContainer}>
          <Grid item className={classes.missingTypeTitleTop}>{actionTitle}</Grid>
          <Grid item className={classes.bottomActionsRoot}>
            {buttons && buttons.map((button, index) => (
              <React.Fragment key={button.id}>
                {buttons.length === index + 1 && (
                  <span className={classes.missingTypeTitleTop}>or</span>
                )}
                <QButton
                  className={classes.wideButton}
                  id={button.id}
                  variant="outlined"
                  onClick={button.onClick}
                >{button.text}
                </QButton>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </Collapse>
    );
  }

  renderZeroState(reviewCurrentStep) {
    const { classes, showFindingRecurring } = this.props;

    return !showFindingRecurring ? (
      <ZeroStateView
        icon={zeroStateIcon}
        primary={!reviewCurrentStep ? 'We couldn\'t find any suggestion' : 'You don\'t have any transactions to review'}
        classes={{ root: this.props.classes.zeroRoot }}
      />
    ) : (
      <div className={classes.waiterRoot}>
        <p className={classes.waiterTitle}>Hang Tight!</p>
        <p className={classes.waiterText}>We are finding your recurring bills and income.</p>
        <div className={classes.waiterCircularRoot}>
          <CircularProgress size={40} style={{ margin: 10 }} />
          <p className={classes.waiterText}>This should only take a few moments.</p>
        </div>
      </div>
    );
  }

  render() {
    const { classes, scheduledTransactions, type, reviewCurrentStep } = this.props;
    const currentSchTxn = scheduledTransactions.filter((x) => reviewCurrentStep ? x.isUserVerified : !x.isUserVerified);

    return (
      <Grid container className={classes.scheduleTxnSelectorRoot}>
        <Grid item className={classes.scheduleTxnSelectorWrapper}>
          {!currentSchTxn.size ?
            this.renderZeroState(reviewCurrentStep) :
            <Table>
              <TableBody>
                {
                  currentSchTxn && currentSchTxn.size > 0 &&
                  currentSchTxn.valueSeq().map((txn, key) => this.renderRow(txn, key, reviewCurrentStep))
                }
              </TableBody>
            </Table>}
        </Grid>
        <Grid item>
          {this.renderAddMissing(type, reviewCurrentStep)}
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state, _props) {
  return {
    categoriesById: categoriesSelectors.getCategoriesById(state),
    accountsById: accountsSelectors.getAccountsById(state),
  };
}

function mapDispatchToProps(_dispatch) {
  return {};
}

export default compose(
  withStyles(styles),
  DialogHoc(),
  connect(mapStateToProps, mapDispatchToProps),
)(ScheduleTransactionSelector);
