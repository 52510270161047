import { call, take } from 'redux-saga/effects';
import * as categoryGroupListsActions from 'data/categoryGroupLists/categoryGroupListsActions';
import { getLastSyncDate } from 'data/categoryGroupLists/categoryGroupListsSelectors';
import { resourceStoreTypes, resourceSagaUtils } from '@quicken-com/react.flux.core';

export function* getCategoryGroupListsActionWatcher() {
  while (true) {
    const action = yield take(categoryGroupListsActions.getCategoryGroupListsAction);

    const config = resourceStoreTypes.mkQcsSyncResourceConfig({
      resourceName: 'categoryGroupLists',
      resourceBaseUrl: '/category-group-lists',
      getLastSyncDate,
      // transformResponseToResources: ,
      // transformResourceToRequestData:,
      successAction: categoryGroupListsActions.getCategoryGroupListsSuccessAction,
      failureAction: categoryGroupListsActions.getCategoryGroupListsFailureAction,
    });
    yield call(resourceSagaUtils.qcsSyncGetResources, config, action);
  }
}

export default [getCategoryGroupListsActionWatcher];
