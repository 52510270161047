import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ResourceRow from 'components/ResourceRegister/ResourceRow';
import { treeNodeRowStyles as styles } from 'components/ResourceRegister/styles';


class TreeNodeRow extends React.PureComponent {
  state = {
    isExpanded: this.props.expandForSearch,
  };
  componentDidUpdate(prevProps, _prevState) {
    if (this.props.expandForSearch !== prevProps.expandForSearch) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isExpanded: this.props.expandForSearch });
    }
  }

  handleExpandCollapse = () => {
    this.setState((state) => ({ isExpanded: !state.isExpanded }));
  };

  render() {
    const {
      classes, item, dataDictionary, isChild, onSave, onDelete, onRemoveNewItem, getCategoryByID,
      setDialog, editingProps, rowIndex, onSelect, selectedItems, narrowMode,
    } = this.props;
    const { isExpanded } = this.state;
    const sharedProps = (data, isChildren, index) => ({
      item: data,
      isChild: isChildren,
      rowIndex: index,
      narrowMode,
      dataDictionary,
      onSave,
      onDelete,
      onSelect,
      selectedItems,
      onRemoveNewItem,
      getCategoryByID,
      setDialog,
      editingProps,
    });

    return (
      <List dense key={item.id} className={classes.listContainer}>
        <ResourceRow
          isExpanded={isExpanded}
          isParent={Boolean(item.children)}
          onCollapse={this.handleExpandCollapse}
          {...sharedProps(item, isChild, rowIndex)}
          disabled={this.props.disabled}
        />
        {isExpanded &&
          <Collapse in={isExpanded} className={classNames(classes.collapseContainer, narrowMode ? 'narrowMode' : '')}>
            { item.children ?
              item.children.map((child, childIndex) => (
                <TreeNodeRowChildren
                  key={child.id}
                  {...sharedProps(child, true, [...rowIndex, childIndex + 1])}
                  disabled={selectedItems?.includes(item.id) || this.props.disabled}
                />
              )) : null}
          </Collapse>}
      </List>
    );
  }
}

TreeNodeRow.defaultProps = {
  isChild: false,
  expandForSearch: false,
};

TreeNodeRow.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.object,
  rowIndex: PropTypes.array,
  isChild: PropTypes.bool,
  dataDictionary: PropTypes.object,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
  selectedItems: PropTypes.object,
  onRemoveNewItem: PropTypes.func,
  getCategoryByID: PropTypes.func,
  setDialog: PropTypes.func,
  editingProps: PropTypes.object,
  narrowMode: PropTypes.bool,
  disabled: PropTypes.bool,
  expandForSearch: PropTypes.bool,
};

const TreeNodeRowChildren = withStyles(styles)(TreeNodeRow);

export default TreeNodeRowChildren;
