import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'utils/compose';

// import { getLogger } from 'utils/logger';

import * as preferencesActions from 'data/preferences/actions';
import * as preferencesSelectors from 'data/preferences/selectors';


// const log = getLogger('components/QPreferences/index.js');

function pathIn(obj, path) {
  let tobj = obj;
  path.forEach((k) => {
    if (tobj[k]) {
      tobj = tobj[k];
    }
  });
  return tobj;
}

export default () => (WrappedComponent) => {

  class QPreferences extends Component {

    setDatasetPreference = (prefObj) => {
      this.props.setPreference({
        section: 'shared',
        group: 'dataset',
        preference: { webApp: prefObj },
      }, { context: 'QPreferences:setDatasetPreference' });
    };

    setUserPreference = (prefObj) => {
      this.props.setPreference({
        section: 'shared',
        group: 'user',
        preference: { webApp: prefObj },
      }, { context: 'SetupPageAcme:setUserPreference' });
    };


    getUserPreference = (prefPath) => pathIn(this.props.userPreferences, prefPath);
    render() {

      const qPrefProps = {
        setDatasetPreference: this.setDatasetPreference,
        setUserPreference: this.setUserPreference,
        datasetPreferences: this.props.datasetPreferences,
        userPreferences: this.props.userPreferences,
        sharedPreferences: this.props.sharedDatasetPreferences,
        sharedUserPreferences: this.props.sharedUserPreferences,
        accountPreferencesById: this.props.accountPreferencesById,
      };

      // wrapped component with its props, the state from HOC and uiStateProps
      return (
        <WrappedComponent
          {...{
            ...this.props,
            ...qPrefProps,
          }}
        />
      );
    }
  }

  function mapStateToProps(state) {
    return {
      datasetPreferences: preferencesSelectors.getSharedPreferencesByPath(state, { group: 'dataset', path: ['webApp'] }),
      userPreferences: preferencesSelectors.getSharedPreferencesByPath(state, { group: 'user', path: ['webApp'] }),
      sharedUserPreferences: preferencesSelectors.getSharedPreferencesByPath(state, { group: 'user' }),
      sharedDatasetPreferences: preferencesSelectors.getSharedPreferencesByPath(state, { group: 'dataset' }),
      accountPreferencesById: preferencesSelectors.accountPreferencesById(state),
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      // { section, group, preference }
      setPreference: (payload, meta) => dispatch(preferencesActions.setPreference(payload, meta)),
      getSharedPreferences: () => {
        dispatch(preferencesActions.getPreferences({ section: 'shared', group: 'user' }));
        dispatch(preferencesActions.getPreferences({ section: 'shared', group: 'dataset' }));
      },
    };
  }

  QPreferences.propTypes = {

    load: PropTypes.bool,

    setPreference: PropTypes.func,
    datasetPreferences: PropTypes.object,
    userPreferences: PropTypes.object,
    getSharedPreferences: PropTypes.func,
    sharedDatasetPreferences: PropTypes.object,
    sharedUserPreferences: PropTypes.object,
    accountPreferencesById: PropTypes.object,
  };

  // the HOC itself is wrapped in connect
  // return connect(mapStateToProps, mapDispatchToProps)(QData);

  return compose(
    connect(mapStateToProps, mapDispatchToProps),
  )(QPreferences);
};
