import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';

import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { getLogger } from '@quicken-com/react.utils.core';

import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import QTip from 'components/QuickenControls/QTip';

import cannotDisplayImg from 'assets/checknum_details.svg';

import { SubTitle, HiddenInput, ImageDiv, ThumbDiv, ViewPort, Thumbs } from './styledComponents';

const log = getLogger('components/QuickenControls/QImageGallery/index.js');

const styles = (theme) => ({
  button: {
    width: 50,
    height: 50,
    textAlign: 'center',
  },
  hide: {
    display: 'none',
  },
  show: {
    display: 'block',
  },
  thumb: {

  },
  mainImage: {
    margin: 'auto',
    textAlign: 'center',
  },
  mainImageText: {
    margin: 'auto',
    textAlign: 'center',
    marginTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
  loadingCircle: {
    display: 'block',
    margin: 'auto',
    position: 'relative',
    color: theme.palette.greyScaleDeprecated[4],
  },
  loadingCircle2: {
    position: 'relative',
    color: theme.palette.greyScaleDeprecated[4],
  },
  cloudIcon: {
    width: 44,
    height: 44,
    color: theme.palette.greyScaleDeprecated[2],
  },
});

/* -------------------------------------------------------
 QImageGallery

 Props:
 images = [{id: anyId, url: 'www.quicken.com/img1.jpg'}, ...]
 selectedImages()  - will get an array of images selected with action button
 action = string - text of the action button

 ESC will close the menu

 */
export class QImageGallery extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currIndex: 0,
      images: props.images.map((obj) => ({ ...obj, selected: 'off' })),
      validImages: [],
      errorImages: [],
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.images) {
      const images = newProps.images.map((obj) => ({ ...obj, selected: 'off' }));
      this.setState({ images, currIndex: Math.min(this.state.currIndex, images.length) });
    }
  }

  componentDidUpdate() { }

  getSelValue = (index) => this.state.images[index].selected;

  getField = () => {
    const { classes } = this.props;
    const imageDivs = [];
    const thumbs = [];

    if (this.state.images && this.state.images.length > 0) {
      this.state.images.forEach((i, index) => {

        const isValidImage = this.state.validImages.indexOf(i.url) >= 0;
        const isErrorImage = this.state.errorImages.indexOf(i.url) >= 0;
        const extension = i.name.slice(i.name.lastIndexOf('.'));
        const isUnavailable = (i.url === 'unavailable');

        const errText = isUnavailable ?
          'This document is no longer available' :
          `This document type (${extension}) cannot be displayed, click to download`;

        if (this.state.currIndex === index) {
          imageDivs.push(
            <div
              key={`img-${i.url}${i.id}`}
            >
              <ButtonBase
                onClick={() => this.clickPic(i.url)}
                style={{ display: 'block', margin: 'auto' }}
                disabled={isUnavailable}
              >
                <div>
                  {(isValidImage) &&
                    <ImageDiv
                      src={isValidImage ? i.url : cannotDisplayImg}
                      className="show"
                    >
                    </ImageDiv>}
                  {(isErrorImage && !isUnavailable) &&
                    <CloudDownloadIcon className={classes.cloudIcon} />}
                  {!(isValidImage || isErrorImage) &&
                    <CircularProgress size={25} className={classes.loadingCircle2} />}
                  <Typography
                    className={classNames(
                      isValidImage ? classes.hide : classes.show,
                      classes.mainImageText,
                    )}
                    variant="h5"
                  >
                    {isErrorImage || isUnavailable ? errText :
                      'Loading...'}

                  </Typography>
                </div>
              </ButtonBase>
            </div>
          );
        }
        thumbs.push(
          <ThumbDiv
            key={`img-thumb${i.url}`}
            className={classNames(
              this.state.currIndex === index ? 'selected' : '',
            )}
          >
            <QTip title={`${i.name}`}>
              <ButtonBase
                onClick={() => this.clickThumb(index)}
                className={classes.button}
              >
                <img
                  alt="select item"
                  onLoad={() => this.markValid(i.url)}
                  onError={() => this.markError(i.url)}
                  src={i.url}
                  className={isValidImage ? classes.show : classes.hide}
                />
                <Typography
                  className={classNames(
                    isValidImage ? classes.hide : classes.show,
                    classes.thumb
                  )}
                >
                  {extension}
                </Typography>
              </ButtonBase>
            </QTip>
          </ThumbDiv>
        );
      });
    } else {
      imageDivs.push(
        <div key="thumbkey" style={{ margin: 'auto', width: 'max-content' }}>
          <CircularProgress
            size={52}
            className={classes.loadingCircle}
          />
          <Typography>
            Retrieving attachments...
          </Typography>
        </div>
      );
    }

    return (
      <div>
        <div>
          {(this.state.images && this.state.images.length > 0) &&
            <SubTitle> Click image to open full screen in a separate tab </SubTitle>}
          <ViewPort>
            {imageDivs}
          </ViewPort>
          <Divider />
          <div style={{ display: 'flex' }}>
            <Thumbs>
              {thumbs}
            </Thumbs>
            { this.numSelected() > 0 ?
              <Button
                color="primary"
                onClick={this.processSelected}
              >
                { this.props.action || 'Action' }
              </Button> : ''}
          </div>

          <HiddenInput
            autoFocus
            onKeyDown={this.processKey}
          >
          </HiddenInput>
        </div>
      </div>
    );
  };

  markValid = (url) => {
    this.setState({ validImages: this.state.validImages.concat([url]) });
  };
  markError = (url) => {
    this.setState({ errorImages: this.state.errorImages.concat([url]) });
  };

  numSelected = () => {
    let num = 0;
    this.state.images.forEach((i) => {
      if (i.selected === 'on') {
        num += 1;
      }
    });
    return num;
  };

  processSelected = () => {
    if (this.props.selectedImages) {
      const ids = this.state.images.filter((i) => i.selected === 'on').map((i) => i.id);
      this.props.selectedImages(ids);
    }
  };

  handleCheckboxChange = (index) => {
    const images = this.state.images.slice();
    images[index].selected = images[index].selected === 'on' ? 'off' : 'on';
    this.setState({ images });
  };

  clickThumb = (index) => { this.setState({ currIndex: index }); };

  clickPic = (url) => {
    window.open(url, '_blank');
  };

  processKey = (e) => {
    log.log('KEY');
    switch (true) {
      case e.key === 'Escape':
        if (this.props.closeFn) {
          e.preventDefault();
          this.props.closeFn();
        }
        break;
      default:
        break;
    }
  };

  render() {
    return (this.getField());
  }
}

QImageGallery.propTypes = {
  images: PropTypes.array,

  action: PropTypes.string,
  closeFn: PropTypes.func,
  selectedImages: PropTypes.func,
  classes: PropTypes.object,
};

export default withStyles(styles)(QImageGallery);
