/**
 *
 * DialogHoc
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import isString from 'lodash/isString';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import QButton from 'components/QButton';
import withStyles from '@mui/styles/withStyles';

const styles = ({ spacing, typography, components, palette, applyOpacityToHex }) => ({
  dialogTitleRoot: {
    margin: 0,
    padding: `${spacing(3)} ${spacing(7.5)} ${spacing(3)} ${spacing(3)}`,
    backgroundColor: components.register.headerBackground,
  },
  dialogCloseButton: {
    position: 'absolute',
    right: spacing(1),
    top: spacing(2),
  },
  dialogContentRoot: {
    margin: 0,
    padding: spacing(3),
  },
  dialogContentText: {
    color: applyOpacityToHex(palette.greyScaleDeprecated[0], 0.6),
    '& strong': {
      fontWeight: typography.fontWeightMedium,
    },
  },
  dialogActionsRoot: {
    margin: 0,
    padding: spacing(3),
  },
  dialogActionsButton: {
    margin: `0 ${spacing(1)}`,
  },
});

export const dialogActions = {
  CLOSE: 'Close',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  KEYDOWN: 'KeyDown',
};

export default () => (WrappedComponent) => {
  class DialogHoc extends React.PureComponent {
    state = {
      dialog: null,
    };

    setDialog = (title, content, callback, buttons) => {
      const dialog = {
        title, content, callback, buttons,
      };
      this.setState({ dialog });
    };

    clearDialog = () => {
      this.setState({ dialog: null });
    };

    dispatchDialogAction = (action) => {
      const { dialog: { callback = null } } = this.state;
      let dispatchAction = action;

      return (event, reason) => {
        if (dispatchAction === dialogActions.KEYDOWN && event.key === 'Enter') {
          dispatchAction = dialogActions.ENTER;
        } else if (reason === 'escapeKeyDown') {
          dispatchAction = dialogActions.ESCAPE;
        }

        if (dispatchAction !== dialogActions.KEYDOWN) {
          callback && callback({ action: dispatchAction });
          this.clearDialog();
        }
      };
    };

    renderDialog(dialogData) {
      const DialogComponent = (props) => {
        const { classes, dialog, dispatchDialogAction } = props;
        return (
          <Dialog
            maxWidth="md"
            onClose={dispatchDialogAction(dialogActions.CLOSE)}
            onKeyPress={dispatchDialogAction(dialogActions.KEYDOWN)}
            aria-labelledby="dialogHoc-title"
            open
          >
            <DialogTitle id="dialogHoc-title" className={classes.dialogTitleRoot}>
              <Typography variant="h6">{dialog.title}</Typography>
              <IconButton
                aria-label="Close"
                className={classes.dialogCloseButton}
                onClick={dispatchDialogAction(dialogActions.CLOSE)}
                size="large"
              ><CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContentRoot}>
              <Typography variant="subtitle1" component="div" className={classes.dialogContentText}>{dialog.content}</Typography>
            </DialogContent>
            {
              dialog.buttons && (
                <DialogActions className={classes.dialogActionsRoot}>
                  {dialog.buttons.map((Btn, i) => {
                    if (isString(Btn)) {
                      const variant = i === dialog.buttons.length - 1 ? 'contained' : 'outlined';
                      return (<QButton id={`dialog_${Btn}`} key={Btn} className={classes.dialogActionsButton} variant={variant} onClick={dispatchDialogAction(Btn)}>{Btn}</QButton>);
                    }
                    return <Btn key={Btn} onClick={dispatchDialogAction(Btn)} />;
                  })}
                </DialogActions>
              )
            }
          </Dialog>
        );
      };
      const DialogComponentWithStyles = compose(withStyles(styles))(DialogComponent);
      return (
        <DialogComponentWithStyles
          dialog={dialogData}
          dispatchDialogAction={this.dispatchDialogAction}
        />
      );
    }

    render() {
      const dialogHocProps = {
        setDialog: this.setDialog,
      };

      return (
        <>
          <WrappedComponent
            {...{
              ...this.props,
              ...dialogHocProps,
            }}
          />
          {
            this.state.dialog && this.renderDialog(this.state.dialog)
          }
        </>
      );
    }
  }

  DialogHoc.propTypes = {
    classes: PropTypes.object,
    dialog: PropTypes.object,

    dispatchDialogAction: PropTypes.func,
  };

  const mapStateToProps = (_state, _props) => ({});

  const mapDispatchToProps = (_dispatch) => ({});

  const withConnect = connect(mapStateToProps, mapDispatchToProps);

  return compose(
    withConnect,
  )(DialogHoc);
};






