import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { accountsActions, accountsSelectors } from '@quicken-com/react.flux.accounts';

import DownshiftField from 'components/QuickenControls/DownshiftField';
import ReadOnlyField from 'components/Transactions/ReadOnlyField';

class AccountField extends PureComponent {

  constructor(props) {
    super(props);

    if (this.props.accountsLoadPending) {
      this.props.dispatchGetAccountsAction();
    }
  }

  render() {
    const { accounts, accountsLoadPending, initialAccount, initialAccountId, onSelected, dispatchGetAccountsAction, readOnly, className, doNotIncludeClosed, ...otherProps } = this.props;
    let accountsSorted = this.props.accounts && this.props.accounts.sort((item1, item2) => item1.name.localeCompare(
      item2.name,
      undefined,
      { numeric: true, sensitivity: 'base' },
    ));

    if (this.props.doNotIncludeClosed) {
      accountsSorted = accountsSorted.filter((account) => !account.isClosed);
    }

    let field = (<DownshiftField
      dataLoading={accountsLoadPending}
      items={[...(accountsSorted && accountsSorted.values())]}
      initialItemSelected={initialAccount}
      onSelected={onSelected}
      textFieldProps={otherProps}
      itemToString={(account) => account ? account.name : ''}
      itemKey={(account) => account ? account.id : ''}
      indexId
      className={className}
    />);

    if (readOnly) {
      field = (
        <ReadOnlyField
          {...otherProps}
          label="Account"
          value={initialAccount ? initialAccount.name : 'Unknown'}
          className={className}
          sharedcomponentid={'ACCOUNT_FIELD'}
        />
      );
    }

    return field;
  }

}

AccountField.propTypes = {
  accounts: PropTypes.object,
  initialAccount: PropTypes.object,
  initialAccountId: PropTypes.string,
  onSelected: PropTypes.func,
  dispatchGetAccountsAction: PropTypes.func,
  accountsLoadPending: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  doNotIncludeClosed: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  const accounts = ownProps.accounts || accountsSelectors.getTransactionAccounts(state);
  return {
    accounts,
    accountsLoadPending: accountsSelectors.getLoadPending(state),
    initialAccount: accounts && ownProps.initialAccountId ? accounts.get(ownProps.initialAccountId) : undefined,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetAccountsAction: () => dispatch(accountsActions.getAccounts()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountField);
