import { createSelector } from 'reselect';
import { List as ListImmutable, Set as SetImmutable } from 'immutable';
import createCachedSelector, { LruCacheObject } from 're-reselect';
import { DateTime } from 'luxon';
import { flatten } from 'lodash';

import { accountsSelectors } from '@quicken-com/react.flux.accounts';
import { categoriesSelectors, categoriesTypes } from '@quicken-com/react.flux.categories';
import { chartOfAccountsTypes } from '@quicken-com/react.flux.chart-of-accounts';

import { getCategoryGroupLists } from 'data/categoryGroupLists/categoryGroupListsSelectors';
import { getCategoryGroupsById, filterCategoriesByGroup, getCategoryGroupById } from 'data/categoryGroups/categoryGroupsSelectors';
import { getBudgetItemsFiltered, getStore as getBudgetItemsStore } from 'data/budgetItems/budgetItemsSelectors';
import { getTransactionsByFilter } from 'data/transactions/selectors';
import { createFlexEqualSelector } from 'utils/selectorsHelpers';

import { BUDGETS_REDUCER_KEY } from './budgetsReducer';
import { BudgetTreeNodeImmutable, BudgetNodeTypes, BudgetRollOverType } from './budgetsTypes';
import { updateBudgetItemSummary, getBudgetStartDate, updateNegateBasedOnBudgetItems } from './budgetUtils';

export const getStore = (state) => state[BUDGETS_REDUCER_KEY];

export const getLoadPending = (state) => getStore(state).loadPending;

export const getIsLoading = (state) => getStore(state).isLoading;

export const getLastSyncDate = (state) => getStore(state).lastSyncDate;

export const getBudgets = (state) => getStore(state).resourcesById;

export const getBudgetById = createSelector(
  getBudgets,
  (state, budgetId) => budgetId,
  (budgets, budgetId) => {
    const budget = budgets.get(budgetId);
    return budget;
  }
);

export function getNegate(categoryGroup, target, actual) {
  let negate = -1;

  const categoryGroupType = (categoryGroup && categoryGroup.type) || 'DYNAMIC';
  switch (categoryGroupType) {
    case 'INCOME':
      negate = 1;
      break;
    case 'EXPENSE':
      negate = -1;
      break;
    case 'DYNAMIC':
    default:
      if (target) {
        negate = target <= 0 ? -1 : +1;
      } else if (actual) {
        negate = actual <= 0 ? -1 : +1;
      }
  }

  return negate;
}

export function nodesForCategories(allCategories, categoryGroup, displayPath, categories, depth, budgetItems) {
  const results = { nodes: [], eeCOAs: [], coas: [] };

  categories.forEach((category) => {

    const categoryId = category.id;
    const coa = chartOfAccountsTypes.mkChartOfAccount({ type: 'CATEGORY', id: categoryId });

    const budgetItemsOtherFound = budgetItems.filter((budgetItem) =>
      budgetItem.type === 'CATEGORY_OTHER' && budgetItem.coa && budgetItem.coa.id === categoryId && budgetItem.coa.type === 'CATEGORY');
    const otherFound = budgetItemsOtherFound && budgetItemsOtherFound.size > 0;

    const budgetItemsEverythingElseFound = budgetItems.filter((budgetItem) =>
      budgetItem.coa && budgetItem.coa.id === categoryId && budgetItem.type === 'COA' && budgetItem.coa.type === 'CATEGORY');
    const eeFound = budgetItemsEverythingElseFound && budgetItemsEverythingElseFound.size > 0;

    const newDisplayPath = displayPath ? [...displayPath, category.name] : [category.name];
    const childDisplayPath = (otherFound || eeFound) ? null : newDisplayPath;

    const childCategories = allCategories.filter((child) => child.parentId === categoryId);

    const childResults = childCategories && childCategories.size > 0
      && nodesForCategories(allCategories, categoryGroup, childDisplayPath, childCategories, depth + 1, budgetItems);

    const childNodes = (childResults && childResults.nodes) || [];
    const hasChildren = childNodes && childNodes.length > 0;

    const childCOAs = (childResults && childResults.coas) || [];
    let childEECOAs = (childResults && childResults.eeCOAs) || [];

    const displayLabel = newDisplayPath.join(':');
    if (otherFound || eeFound) {

      const calculationsOther = budgetItemsOtherFound.reduce((accumulator, budgetItem) => ({
        targetAmount: accumulator.targetAmount + budgetItem.amount,
        actualAmount: accumulator.actualAmount + budgetItem.calculatedActualsAmount,
        rolloverAmount: accumulator.rolloverAmount + getRolloverAmountForBudgetItem(budgetItem),
      }), {
        targetAmount: 0,
        actualAmount: 0,
        rolloverAmount: 0,
      });

      const calculationsEE = budgetItemsEverythingElseFound.reduce((accumulator, budgetItem) => ({
        targetAmount: accumulator.targetAmount + budgetItem.amount,
        actualAmount: accumulator.actualAmount + budgetItem.calculatedActualsAmount,
        rolloverAmount: accumulator.rolloverAmount + getRolloverAmountForBudgetItem(budgetItem),
      }), {
        targetAmount: 0,
        actualAmount: 0,
        rolloverAmount: 0,
      });

      if (hasChildren || (otherFound && eeFound)) {

        if (otherFound) {

          childCOAs.push(coa);

          const childNode = new BudgetTreeNodeImmutable({
            key: `${BudgetNodeTypes.CATEGORY_OTHER}:${categoryId}`,
            type: BudgetNodeTypes.CATEGORY_OTHER,
            id: categoryId,
            coas: new SetImmutable([coa]),
            displayLabel,
            targetAmount: calculationsOther.targetAmount,
            actualAmount: calculationsOther.actualAmount,
            rolloverAmount: calculationsOther.rolloverAmount,
            negate: category.type === 'INCOME' ? +1 : -1,
            depth: depth + 1,
            // parent: ???
            budgetItems: budgetItemsOtherFound,
          });
          childNodes.unshift(childNode);
        } else {

          childEECOAs.push(coa);
        }

        if (eeFound) {

          const childNode = new BudgetTreeNodeImmutable({
            key: `${BudgetNodeTypes.CATEGORY_EE}:${categoryId}`,
            type: BudgetNodeTypes.CATEGORY_EE,
            id: categoryId,
            coas: childEECOAs ? new SetImmutable(childEECOAs) : null,
            displayLabel: 'Everything Else', // `${displayLabel} (everything else)`,
            isEverythingElse: true,
            targetAmount: calculationsEE.targetAmount,
            actualAmount: calculationsEE.actualAmount,
            rolloverAmount: calculationsEE.rolloverAmount,
            negate: category.type === 'INCOME' ? +1 : -1,
            depth: depth + 1,
            budgetItems: budgetItemsEverythingElseFound,
          });
          childNodes.push(childNode);

          if (childEECOAs && childEECOAs.length) {
            childCOAs.push(...childEECOAs);
            childEECOAs = null; // stop propagation
          }
        }

        const calculations = childNodes.reduce((accumulator, node) => ({
          targetAmount: accumulator.targetAmount + node.targetAmount,
          actualAmount: accumulator.actualAmount + node.actualAmount,
          rolloverAmount: accumulator.rolloverAmount + node.rolloverAmount,
        }), {
          targetAmount: 0,
          actualAmount: 0,
          rolloverAmount: 0,
        });

        const node = new BudgetTreeNodeImmutable({
          key: `${BudgetNodeTypes.CATEGORY_ROLL_UP}:${categoryId}`,
          type: BudgetNodeTypes.CATEGORY_ROLL_UP,
          id: categoryId,
          coas: childCOAs ? new SetImmutable(childCOAs) : null,
          displayLabel, // : `${displayLabel} (total)`,
          targetAmount: calculations.targetAmount,
          actualAmount: calculations.actualAmount,
          rolloverAmount: calculations.rolloverAmount,
          negate: category.type === 'INCOME' ? +1 : -1,
          depth,
          children: childNodes && childNodes.length > 0 ? new ListImmutable(childNodes) : null,
        });
        results.nodes.push(node);

      } else {

        const coas = [];
        if (categoryGroup && categoryGroup.coas
          && categoryGroup.coas.find((categoryGroupCOA) => categoryGroupCOA.type === coa.type && categoryGroupCOA.id === coa.id)) {
          coas.push(coa);
        }

        if (eeFound && childEECOAs && childEECOAs.length > 0) {
          coas.push(...childEECOAs);
          childEECOAs = null; // stop propagation
        }
        results.coas.push(...coas);

        const type = (otherFound && BudgetNodeTypes.CATEGORY_OTHER) || (eeFound && BudgetNodeTypes.CATEGORY_EE);
        const node = new BudgetTreeNodeImmutable({
          key: `${type}:${categoryId}`,
          type,
          id: categoryId,
          coas: new SetImmutable(coas),
          displayLabel, // : eeFound ? `${displayLabel} (total)` : displayLabel,
          targetAmount: (otherFound && calculationsOther.targetAmount) || (eeFound && calculationsEE.targetAmount),
          actualAmount: (otherFound && calculationsOther.actualAmount) || (eeFound && calculationsEE.actualAmount),
          rolloverAmount: (otherFound && calculationsOther.rolloverAmount) || (eeFound && calculationsEE.rolloverAmount),
          negate: category.type === 'INCOME' ? +1 : -1,
          depth,
          budgetItems: (otherFound && budgetItemsOtherFound) || (eeFound && budgetItemsEverythingElseFound),
        });
        results.nodes.push(node);
      }

    } else {

      if (categoryGroup && categoryGroup.coas
        && categoryGroup.coas.find((categoryGroupCOA) => categoryGroupCOA.type === coa.type && categoryGroupCOA.id === coa.id)) {
        results.eeCOAs.push(coa);
      }

      if (childNodes && childNodes.length) {
        results.nodes.push(...childNodes);
      }
    }

    if (childCOAs && childCOAs.length) {
      results.coas.push(...childCOAs);
    }

    if (childEECOAs && childEECOAs.length) {
      results.eeCOAs.push(...childEECOAs);
    }

  });

  results.nodes.sort((node1, node2) =>
    node1.displayLabel.localeCompare(
      node2.displayLabel,
      undefined,
      { numeric: true, sensitivity: 'base' },
    ));

  return results;
}

export function nodeForGroupEE(categoryGroup, budgetItems, coas) {
  let groupEENode;

  const budgetItemsFound = budgetItems.filter((budgetItem) => budgetItem.type === 'GROUP');
  if (budgetItemsFound && budgetItemsFound.size > 0) {

    const calculations = budgetItemsFound.reduce((accumulator, budgetItem) => ({
      targetAmount: accumulator.targetAmount + budgetItem.amount,
      actualAmount: accumulator.actualAmount + budgetItem.calculatedActualsAmount,
      rolloverAmount: accumulator.rolloverAmount + getRolloverAmountForBudgetItem(budgetItem),
    }), {
      targetAmount: 0,
      actualAmount: 0,
      rolloverAmount: 0,
    });
    const negate = getNegate(categoryGroup, calculations.targetAmount, calculations.actualAmount);

    groupEENode = new BudgetTreeNodeImmutable({
      key: `${BudgetNodeTypes.GROUP_EE}:${categoryGroup.id}`,
      type: BudgetNodeTypes.GROUP_EE,
      id: categoryGroup.id,
      coas,
      displayLabel: `Miscellaneous ${categoryGroup.name}`, // `${categoryGroup.name} (everything else)`,
      isEverythingElse: true,
      targetAmount: calculations.targetAmount,
      actualAmount: calculations.actualAmount,
      rolloverAmount: calculations.rolloverAmount,
      negate,
      depth: 1,
      budgetItems: budgetItemsFound,
    });
  }

  return groupEENode;
}

const getRolloverAmountForBudgetItem = (budgetItem) => {
  let rolloverAmount;
  if (budgetItem.rolloverOverrideAmount === undefined) {
    rolloverAmount = budgetItem.calculatedRolloverAmount;
  } else {
    rolloverAmount = budgetItem.isIncome ? -1 * budgetItem.rolloverOverrideAmount : budgetItem.rolloverOverrideAmount;
  }
  return rolloverAmount;
};

export function nodesForTransfers(budgetItems, categoryGroup, accountsById) {
  const results = { nodes: [], eeCOAs: [], coas: [] };

  const budgetItemsFound = budgetItems && budgetItems.filter((budgetItem) => budgetItem.type === 'COA' && budgetItem.coa && budgetItem.coa.type === 'ACCOUNT');
  const budgetItemsGrouped = budgetItemsFound && budgetItemsFound.groupBy((budgetItem) => budgetItem.coa.id);
  budgetItemsGrouped.forEach((nodeBudgetItems, accountId) => {
    const calculations = nodeBudgetItems.reduce((accumulator, budgetItem) => ({
      targetAmount: accumulator.targetAmount + budgetItem.amount,
      actualAmount: accumulator.actualAmount + budgetItem.calculatedActualsAmount,
      rolloverAmount: accumulator.rolloverAmount + getRolloverAmountForBudgetItem(budgetItem),
    }), {
      targetAmount: 0,
      actualAmount: 0,
      rolloverAmount: 0,
    });
    const coa = chartOfAccountsTypes.mkChartOfAccount({ type: 'ACCOUNT', id: accountId });
    const account = accountsById.get(accountId);
    const negate = getNegate(categoryGroup, calculations.targetAmount, calculations.actualAmount);
    const transferNode = new BudgetTreeNodeImmutable({
      key: `${BudgetNodeTypes.TRANSFER}:${categoryGroup.id}:${accountId}`,
      type: BudgetNodeTypes.TRANSFER,
      id: accountId,
      coas: new SetImmutable([coa]),
      displayLabel: `${negate > 0 ? 'FROM' : 'TO'} ${account ? account.name : '\'unknown account\''}`,
      targetAmount: calculations.targetAmount,
      actualAmount: calculations.actualAmount,
      rolloverAmount: calculations.rolloverAmount,
      negate,
      depth: 1,
      budgetItems: nodeBudgetItems,
    });
    results.nodes.push(transferNode);
    results.coas.push(coa);
  });

  results.eeCOAs = categoryGroup.coas.filter((categoryGroupCOA) => categoryGroupCOA.type === 'ACCOUNT' && !results.coas.find((coa) => coa.id === categoryGroupCOA.id));

  return results;
}

export function nodeForGroup(categoryGroup, childNodes) {

  const calculations = childNodes.reduce((accumulator, node) => ({
    targetAmount: accumulator.targetAmount + node.targetAmount,
    actualAmount: accumulator.actualAmount + node.actualAmount,
    rolloverAmount: accumulator.rolloverAmount + node.rolloverAmount,
    coas: new SetImmutable([...accumulator.coas, ...node.coas]),
  }), {
    targetAmount: 0,
    actualAmount: 0,
    rolloverAmount: 0,
    coas: [],
  });
  const negate = getNegate(categoryGroup, calculations.targetAmount, calculations.actualAmount);

  const groupNode = new BudgetTreeNodeImmutable({
    key: `${BudgetNodeTypes.GROUP}:${categoryGroup.id}`,
    type: BudgetNodeTypes.GROUP,
    id: categoryGroup.id,
    coas: calculations.coas ? new SetImmutable(calculations.coas) : null,
    displayLabel: categoryGroup.name,
    targetAmount: calculations.targetAmount,
    actualAmount: calculations.actualAmount,
    rolloverAmount: calculations.rolloverAmount,
    negate,
    depth: 0,
    children: new ListImmutable(childNodes),
  });

  return groupNode;
}

export const getBudgetTreeNodes = createCachedSelector(
  (state) => ({ obj: accountsSelectors.getAccountsById(state), deep: false }),
  (state, props) => ({
    obj: getBudgetItemsFiltered(state, {
      budget: props.budget,
      startDate: props.startDate,
      endDate: props.endDate,
    }),
    deep: false,
  }),
  (state) => ({ obj: getCategoryGroupLists(state), deep: false }),
  (state) => ({ obj: getCategoryGroupsById(state), deep: false }),
  (state) => ({ obj: categoriesSelectors.getCategoriesById(state), deep: false }),
  (state, props) => ({ obj: props.budget, deep: false }),
  (state, props) => ({ obj: props.startDate, deep: true }),
  (state, props) => ({ obj: props.endDate, deep: true }),
  (
    { obj: accountsById },
    { obj: budgetItemsById },
    { obj: categoryGroupListsById },
    { obj: categoryGroupsById },
    { obj: categoriesById },
    { obj: budget },
  ) => (new ListImmutable()).withMutations((nodes) => {
    if (budget && budget.catGroupListId) {

      const budgetCategoryGroupList = categoryGroupListsById.get(budget.catGroupListId);
      if (budgetCategoryGroupList && budgetCategoryGroupList.id) {
        let categoryGroups = categoryGroupsById.filter((categoryGroup) => categoryGroup.categoryGroupListId === budgetCategoryGroupList.id).toOrderedMap();
        categoryGroups = categoryGroups.sort((obj1, obj2) =>
          obj1.name.localeCompare(
            obj2.name,
            undefined,
            { numeric: true, sensitivity: 'base' },
          ));

        categoryGroups.forEach((categoryGroup) => {

          const categoryGroupId = categoryGroup.id;
          const budgetItems = budgetItemsById.filter((budgetItem) =>
            (budgetItem.groupId && budgetItem.groupId === categoryGroupId)
            || (categoryGroup.coas && budgetItem.coa && categoryGroup.coas.indexOf(budgetItem.coa) >= 0));

          if (budgetItems.size > 0 && categoryGroup && categoryGroup.coas) {
            // categories that belongs to the group
            const categoryLeaves = filterCategoriesByGroup(categoriesById, categoryGroup);
            // categories for the tree
            const categoryTree = categoriesSelectors.filterCategoryTreeByLeaves(categoriesById, categoryLeaves);
            // root categories
            const rootCategories = categoryTree && categoryTree.filter((category) => category.parentId === '0');
            // build category nodes tree
            const categoryResults = nodesForCategories(categoryTree, categoryGroup, null, rootCategories, 1, budgetItems);
            const categoryNodes = categoryResults.nodes;
            // add transfer nodes
            const transferResults = nodesForTransfers(budgetItems, categoryGroup, accountsById);
            const transferNodes = transferResults.nodes;
            // group level ee
            const eeCOAs = new SetImmutable([...categoryResults.eeCOAs, ...transferResults.eeCOAs]);
            const groupEENode = nodeForGroupEE(categoryGroup, budgetItems, eeCOAs);
            // add group node
            if ((categoryNodes && categoryNodes.length > 0)
              || (transferNodes && transferNodes.length > 0)
              || groupEENode
            ) {
              const groupChildNodes = [];
              if (categoryNodes && categoryNodes.length > 0) {
                groupChildNodes.push(...categoryNodes);
              }
              if (transferNodes && transferNodes.length > 0) {
                groupChildNodes.push(...transferNodes);
              }
              if (groupEENode) {
                groupChildNodes.push(groupEENode);
              }
              const groupNode = nodeForGroup(categoryGroup, groupChildNodes);
              if (groupNode) {
                nodes.push(groupNode);
              }
            }
          }
        });
      }
    }
  }),
)(
  (_state, props) => `${props.budget && props.budget.id}:${props.startDate}:${props.endDate}`,
  {
    cacheObject: new LruCacheObject({ cacheSize: 13 }),
    selectorCreator: createFlexEqualSelector,
  },
);

export function pathToNodeWithKeyRecursive(nodes, key) {
  let pathFound;

  if (nodes && nodes.size > 0) {
    nodes.find((node) => {
      if (node.key === key) {
        pathFound = [node];
      } else if (node.children && node.children.size > 0) {
        pathFound = pathToNodeWithKeyRecursive(node.children, key);
        pathFound = pathFound ? [node, ...pathFound] : undefined;
      }
      return pathFound;
    });
  }

  return pathFound;
}

export const pathToNodeWithKey = createSelector(
  (nodes, _key) => nodes,
  (_nodes, key) => key,
  (nodes, key) => pathToNodeWithKeyRecursive(nodes, key)
);

export const coasForNodes = createCachedSelector(
  (nodes) => nodes,
  (nodes) => new SetImmutable(nodes && nodes.reduce((coasArray, node) => node.coas ? [...coasArray, ...node.coas] : coasArray, []))
)(
  (nodes) => `${nodes && nodes.hashCode()}`,
  { cacheObject: new LruCacheObject({ cacheSize: 8 }) },
);

export const getCategoryBudgetTransactions = createCachedSelector(
  (state) => state,
  (state, budgetId) => budgetId,
  (state, budgetId, categoryId) => categoryId,
  (state, budgetId) => getBudgetById(state, budgetId),
  (state, budgetId, categoryId, showFutureTxns) => showFutureTxns || false,
  (state) => getBudgetItemsStore(state),
  (state, budgetId, categoryId, showFutureTxns, budgetItems, specificMonth) => specificMonth || null,
  (state, budgetId, categoryId, showFutureTxns, budgetItems, specificMonth, allCategoryIdsOfBudget) => allCategoryIdsOfBudget || [],
  (state, budgetId, categoryId, showFutureTxns, budgetItems, specificMonth, allCategoryIdsOfBudget, isEverythingElse) => isEverythingElse || false,
  (state, budgetId, categoryId, showFutureTxns, budgetItems, specificMonth, allCategoryIdsOfBudget, isEverythingElse, displayName) => displayName || '',
  (state, budgetId, categoryId, budgetDetails, showFutureTxns, budgetItems, specificMonth, allCategoryIdsOfBudget, isEverythingElse, displayName) => {
    if (!budgetDetails) {
      return {};
    }
    const budgetItemsFiltered = budgetItems.resourcesById.filter((budgetItem) => budgetItem.budgetId === budgetId);
    const actualStartDate = getBudgetStartDate(budgetDetails, budgetItemsFiltered);
    const budgetEndDate = DateTime.fromISO(actualStartDate).plus({ months: 11 }).endOf('month');
    const startDateForGraphData = DateTime.now().endOf('month');
    const monthsConsidered = 12;
    const previousMonthDate = startDateForGraphData.minus({ months: monthsConsidered }).startOf('month');

    const categories = getAnnualBudgetTreeNodes(state, budgetId)?.nodes?.filter((node) => node.id === categoryId);
    let subCategories = flatten(categories?.map((category) => category.coas));

    // seperate accounts which are not considered in budget.
    const budgetExcludedAccountsId = accountsSelectors.getExcludedBudgetAccounts(state)?.map(({ id }) => id);
    const allAccountsOfUser = accountsSelectors.getAccountsById(state);

    // filter budget excluded accounts from all accounts.
    const consideredAccountIds = allAccountsOfUser.filter(({ id }) => !budgetExcludedAccountsId.includes(id));

    // filter is added for removing the categoryIds which are available in budget only for everything else.
    if (isEverythingElse) {
      subCategories = subCategories.filter((category) => !allCategoryIdsOfBudget?.includes(category.id));
    } 
    // adding parent category id to the sub category array
    subCategories.unshift({ type: 'CATEGORY', id: categoryId });

    const txnFilters = {
      coas: new SetImmutable(subCategories),
      startDate: specificMonth || previousMonthDate,
      endDate: (specificMonth && specificMonth.endOf('month')) || budgetEndDate,
      accountIds: consideredAccountIds,
    };
    let categoryDetails = categoriesSelectors.getCategoryById(state, categoryId);
    if (!categoryDetails) {
      categoryDetails = getCategoryGroupById(state, categoryId);
    }
    const budgetCategoryDetails = {
      txns: [],
      txnReminders: [],
      graphData: [],
      total: 0,
      monthlyAvarage: 0,
      txnsType: categoryDetails?.type === 'EXPENSE' ? 'Expenses' : 'Income',
      categoryName: displayName,
      budgetStartDate: actualStartDate,
      budgetEndDate,
    };
    const budgetCategoryTxns = getTransactionsByFilter(state, txnFilters);
    const chartData = {};

    let iteratedMonths = 0;
    while (iteratedMonths <= monthsConsidered) {
      const ittratedMonths = previousMonthDate.plus({ months: iteratedMonths }).toFormat('yyyy-MM');
      chartData[ittratedMonths] = {
        datedOn: previousMonthDate.plus({ months: iteratedMonths }).toISODate(),
        amount: 0,
      };
      iteratedMonths += 1;
    }

    budgetCategoryTxns.forEach((txn) => {
      let txnAmount = txn.amount;
      if (DateTime.fromISO(txn.postedOn) >= actualStartDate) {
        if (txn.source === 'SCHEDULED_TRANSACTION_PENDING') {
          if (showFutureTxns) {
            budgetCategoryDetails.total += txnAmount;
            budgetCategoryDetails.txnReminders.push(txn);
          }
        } else {
          if (txn.split) {
            txnAmount = 0;
            const splitItems = txn.split.items;
            splitItems.forEach((split) => {
              if (subCategories.map((category) => category.id).includes(split.coa.id)) {
                txnAmount += split.amount;
              }
            });
          }
          const editedTxn = txn.set('amount', (categoryDetails?.type === categoriesTypes.CategoryTypeEnum.EXPENSE) ? txnAmount * -1 : txnAmount);
          budgetCategoryDetails.total += editedTxn.amount;
          budgetCategoryDetails.txns.push(editedTxn);
        }
      }
      if (DateTime.fromISO(txn.postedOn) <= startDateForGraphData && txn.source !== 'SCHEDULED_TRANSACTION_PENDING') {
        const txnDate = DateTime.fromISO(txn.postedOn).toFormat('yyyy-MM');
        chartData[txnDate] = {
          ...chartData[txnDate],
          amount: categoryDetails?.type === categoriesTypes.CategoryTypeEnum.EXPENSE ? 
            chartData[txnDate].amount - txnAmount || 0 : chartData[txnDate].amount + txnAmount || 0,
          postedOn: txn.postedOn,
          unix: DateTime.fromISO(txn.postedOn).valueOf(),
        };
      }
    });

    budgetCategoryDetails.graphData = Object.values(chartData).sort((firstMonthlyTxnAmount, secondMonthlyTxnAmount) => firstMonthlyTxnAmount.unix - secondMonthlyTxnAmount.unix);
    if (budgetCategoryDetails.graphData.length) {
      let monthsCountConsideredForAverage = 0;
      let isAverageCalculatedNeeded = false;
      const sumOfAmountsForMonthlyAverage = budgetCategoryDetails.graphData.reduce((accumulator, monthlyTxn, graphDataIndex) => {
        let accumulatorSum = accumulator;
        if (graphDataIndex !== budgetCategoryDetails.graphData.length - 1) {
          if (!isAverageCalculatedNeeded && monthlyTxn.amount !== 0) {
            monthsCountConsideredForAverage += 1;
            isAverageCalculatedNeeded = true;
            accumulatorSum += monthlyTxn.amount;
          } else if (isAverageCalculatedNeeded) {
            monthsCountConsideredForAverage += 1;
            accumulatorSum += monthlyTxn.amount;
          }
        }
        return accumulatorSum;
      }, 0);
      if (monthsCountConsideredForAverage > 1) {
        budgetCategoryDetails.monthlyAvarage = sumOfAmountsForMonthlyAverage / monthsCountConsideredForAverage;
      }
    }
    budgetCategoryDetails.txns = budgetCategoryDetails.txns.sort((firstTxn, secondTxn) => DateTime.fromISO(firstTxn.postedOn).valueOf() - DateTime.fromISO(secondTxn.postedOn).valueOf());
    budgetCategoryDetails.txnReminders = budgetCategoryDetails.txnReminders.sort((firstReminder, secondReminder) => DateTime.fromISO(firstReminder.postedOn).valueOf() - DateTime.fromISO(secondReminder.postedOn).valueOf());
    return budgetCategoryDetails;
  }
)(
  (state, budgetId, categoryId) => `${budgetId}:${categoryId}`,
  { cacheObject: new LruCacheObject({ cacheSize: 8 }) },
);

export const getAnnualSpendings = createCachedSelector(
  (state) => state,
  (state, budgetId) => budgetId,
  (state) => getBudgetItemsStore(state),
  (state, budgetId) => getBudgetById(state, budgetId),
  (state, budgetId, budgetItems, budgetDetails) => {
    if (!budgetDetails) {
      return {};
    }
    const budgetItemsFiltered = budgetItems.resourcesById.filter((budgetItem) => budgetItem.budgetId === budgetId);
    const startDate = getBudgetStartDate(budgetDetails, budgetItemsFiltered);
    const endDate = DateTime.fromISO(startDate).plus({ months: 11 }).endOf('month');
    const annulSpendingDetails = {
      budgetId,
      startDate,
      endDate,
      totalSpendings: 0,
      annualBudget: 0,
      totalSavings: 0,
      currency: budgetDetails.currency,
    };

    let annualTotalExpense = 0;
    let annualTotalIncome = 0;

    const coas = [];
    budgetItemsFiltered.filter((budgetItem) => DateTime.fromISO(budgetItem.startDate) >= startDate && DateTime.fromISO(budgetItem.startDate) <= endDate).forEach((budgetItem) => {
      coas.push(budgetItem.coa);
      const isIncome = categoriesSelectors.isIncomeCat(null, budgetItem?.coa?.id) || false;
      annualTotalExpense = isIncome ? annualTotalExpense : annualTotalExpense + (budgetItem.amount || 0);
      annulSpendingDetails.totalSpendings = isIncome ? annulSpendingDetails.totalSpendings : (budgetItem.calculatedActualsAmount || 0) + annulSpendingDetails.totalSpendings;
      if (isIncome && DateTime.fromISO(budgetItem.startDate) < DateTime.now().startOf('month')) {
        annualTotalIncome += budgetItem.calculatedActualsAmount || 0;
      } else {
        annualTotalIncome = isIncome ? (budgetItem.amount || 0) + annualTotalIncome : annualTotalIncome;
      }
    });
    // TODO: commented out snippet for calculating spending with reminders.
    // const reminderTxns = getTransactionsByFilter(state, { coas: new SetImmutable(coas), startDate, endDate, isScheduledPending: true });
    // reminderTxns.forEach((txn) => {
    //   const isIncome = categoriesSelectors.isIncomeCat(null, txn?.coa?.id) || false;
    //   annulSpendingDetails.totalSpendings = isIncome ? annulSpendingDetails.totalSpendings : (txn.amount || 0) + annulSpendingDetails.totalSpendings;
    // });
    annulSpendingDetails.annualBudget = Math.abs(annualTotalExpense);
    annulSpendingDetails.totalSavings = Math.abs(annualTotalIncome);
    annulSpendingDetails.totalSpendings = Math.abs(annulSpendingDetails.totalSpendings);
    if (annulSpendingDetails.totalSpendings > annulSpendingDetails.annualBudget) {
      annulSpendingDetails.totalSavings -= annulSpendingDetails.totalSpendings;
    } else {
      annulSpendingDetails.totalSavings -= annulSpendingDetails.annualBudget;
    }
    annulSpendingDetails.totalSavings = annulSpendingDetails.totalSavings > 0 ? annulSpendingDetails.totalSavings : 0;
    return annulSpendingDetails;
  }
)(
  (state, budgetId) => budgetId,
  { cacheObject: new LruCacheObject({ cacheSize: 8 }) },
);

const flattenBudgetTreeNodes = (flattenedBudgetNodes = [], budgetNode, budgetId, parentCategoryId = null, parentsCount = 0) => {
  const { children, ...otherProps } = budgetNode;
  flattenedBudgetNodes.push({ ...otherProps, hasChildren: children.size > 0, parentId: parentCategoryId, parentsCount });
  children.forEach((child) => {
    const { children: newChild, ...others } = child;
    if (newChild.length > 0 || newChild.size > 0) {
      flattenBudgetTreeNodes(flattenedBudgetNodes, child, budgetId, otherProps.id, parentsCount + 1);
    } else {
      flattenedBudgetNodes.push({ ...others, parentId: otherProps.id, hasChildren: newChild.size > 0, parentsCount: parentsCount + 1 });
    }
    return true;
  });
  return flattenedBudgetNodes;
};

const addChildBudgetItemsImmutable = (budgetNode) => {
  const budgetNodeMutable = budgetNode.toJS();
  const categoryChildren = budgetNode.children.map(addChildBudgetItemsImmutable) || [];
  const budgetItemsAndKeys = categoryChildren.reduce((accumulator, childCategoryNode) => ({
    budgetItems: [...accumulator.budgetItems, ...Object.values(childCategoryNode.budgetItems)],
    keys: [...accumulator.keys, childCategoryNode.key, ...childCategoryNode.childrenKeys],
  }), { budgetItems: [], keys: [] });
  const budgetItems = [...Object.values(budgetNodeMutable.budgetItems), ...budgetItemsAndKeys.budgetItems];
  const { keys } = budgetItemsAndKeys;

  return {
    ...budgetNodeMutable,
    budgetItems,
    children: categoryChildren,
    childrenKeys: keys,
    originalBudgetItems:
    budgetNodeMutable.budgetItems,
    isVisible: true,
    isSelfExpanded: true,
    uniqueTimestamp: DateTime.now().valueOf(),
  };
};

export const getAnnualBudgetTreeNodes = createCachedSelector(
  (state) => state,
  (state, budgetId) => getBudgetById(state, budgetId),
  (state, budgetId) => budgetId,
  (state, budgetId, showFutureTxns) => showFutureTxns || false, // TODO disabling reminders for now. have to use QWIN pref in phase 02
  (state, budgetId) => getBudgetItemsStore(state).resourcesById.filter((budgetItem) => budgetItem.budgetId === budgetId),
  (state, budget, budgetId, showFutureTxns, budgetItems) => {
    const budgetTree = [];
    let reminderTxns = [];
    let startDate;
    if (budget) {
      startDate = getBudgetStartDate(budget, budgetItems);
      const endDate = DateTime.fromISO(startDate).plus({ months: 11 }).endOf('month');
      const budgetTreeNodes = getBudgetTreeNodes(state, { budget, startDate, endDate });
      // fetch reminder txns
      if (showFutureTxns) {
        reminderTxns = getTransactionsByFilter(state, { startDate, endDate, isScheduledPending: true });
      }
      const parentArrayWithFinalBalance = budgetTreeNodes.map(addChildBudgetItemsImmutable);
      parentArrayWithFinalBalance.forEach((item) => {
        budgetTree.push(...flattenBudgetTreeNodes([], item, budgetId, null, 0));
      });
    }

    const parentBudget = {};
    budgetTree.forEach((node) => {
      parentBudget[node.key] = {};
      let twelveMonths = 0;
      while (twelveMonths < 12) {
        const ittratedMonths = startDate.plus({ months: twelveMonths }).toFormat('MM-yyyy');
        parentBudget[node.key][ittratedMonths] = {
          actualAmount: 0,
          budgetAmount: 0,
          balanceAmount: 0,
          rolloverAmount: 0,
          budgetItemId: null,
          startDate: startDate.plus({ months: twelveMonths }),
          isEditable: false,
          isActualBudgetItem: false,
          isToDate: false,
          rolloverType: BudgetRollOverType.NO_ROLLOVER,
        };
        twelveMonths += 1;
      }

      node.budgetItems.forEach((budgetItem) => {
        const monthVal = DateTime.fromISO(budgetItem.startDate).toFormat('MM-yyyy');
        const isIncome = categoriesSelectors.isIncomeCat(null, budgetItem?.coa?.id);
        // match reminder txn for summing up.
        const txnToAdd = reminderTxns.find((txn) =>
          txn?.coa?.id === budgetItem?.coa?.id && monthVal === DateTime.fromISO(txn.stDueOn).toFormat('MM-yyyy'));
        let budgetActual = budgetItem.calculatedActualsAmount || 0;
        let budgetTarget = budgetItem.amount || 0;
        if (!budgetItem.isIncome) {
          budgetActual = -1 * budgetItem.calculatedActualsAmount;
          budgetTarget = -1 * budgetItem.amount;
        }

        let rollOverValue;
        if (Math.abs(budgetItem.rolloverOverrideAmount) > 0) {
          rollOverValue = budgetItem.isIncome ? -1 * budgetItem.rolloverOverrideAmount : budgetItem.rolloverOverrideAmount;
        } else {
          rollOverValue = budgetItem.calculatedRolloverAmount;
        }
        const rollover = budgetItem.rolloverType !== BudgetRollOverType.NO_ROLLOVER ? (rollOverValue || 0) : 0;
        if (parentBudget[node.key][monthVal]?.isActualBudgetItem) {
          parentBudget[node.key][monthVal] = {
            ...parentBudget[node.key][monthVal],
            actualAmount: parentBudget[node.key][monthVal].actualAmount + budgetActual,
            budgetAmount: parentBudget[node.key][monthVal].budgetAmount + budgetTarget,
            budgetItemId: null,
            isEditable: false,
            rolloverAmount: parentBudget[node.key][monthVal].rolloverAmount + rollover,
            rolloverType: (node.type === BudgetNodeTypes.CATEGORY_EE || node.type === BudgetNodeTypes.CATEGORY_OTHER) ? budgetItem.rolloverType : '',
          };
        } else {
          parentBudget[node.key] = {
            ...parentBudget[node.key],
            [monthVal]: {
              actualAmount: budgetActual,
              budgetAmount: budgetTarget,
              balanceAmount: 0,
              rolloverAmount: rollover,
              rolloverType: (node.type === BudgetNodeTypes.CATEGORY_EE || node.type === BudgetNodeTypes.CATEGORY_OTHER) ? budgetItem.rolloverType : '',
              startDate: DateTime.fromISO(budgetItem.startDate),
              budgetItemId: budgetItem.id,
              isEditable: true,
              isActualBudgetItem: true,
              isToDate: false,
            },
          };
        }
        if (txnToAdd) {
          parentBudget[node.key][monthVal].actualAmount += Math.abs(txnToAdd.amount);
        }
        const { actualAmount, budgetAmount, rolloverAmount } = parentBudget[node.key][monthVal];
        const balance = isIncome ? actualAmount - budgetAmount : budgetAmount - actualAmount;
        parentBudget[node.key][monthVal].balanceAmount = balance - rolloverAmount;
      });
    });

    // calculate and update summary from child to parent order
    updateBudgetItemSummary(budgetTree, parentBudget, BudgetNodeTypes.CATEGORY_EE);
    updateBudgetItemSummary(budgetTree, parentBudget, BudgetNodeTypes.TRANSFER);
    updateBudgetItemSummary(budgetTree, parentBudget, BudgetNodeTypes.GROUP_EE, []);
    updateBudgetItemSummary(budgetTree, parentBudget, BudgetNodeTypes.CATEGORY_OTHER, []);
    updateBudgetItemSummary(budgetTree, parentBudget, BudgetNodeTypes.CATEGORY_ROLL_UP, []);
    updateBudgetItemSummary(budgetTree, parentBudget, BudgetNodeTypes.GROUP, [BudgetNodeTypes.CATEGORY_ROLL_UP]);

    // updating negate value for type gr based on budgetItems
    updateNegateBasedOnBudgetItems(budgetTree);

    return {
      nodes: budgetTree.sort((firstCategory, secondCategory) => secondCategory.negate - firstCategory.negate),
      budgetStartDate: startDate,
    };
  }
)((state, budgetId, showFutureTxns) => budgetId + showFutureTxns,
  {
    cacheObject: new LruCacheObject({ cacheSize: 13 }),
  });

export const getTotalValuesForAnnualBudget = (annualBudget) => {
  let monthlyWiseTotal = {};
  const annualBudgetTotals = {
    monthlyTotal: [],
    summary: {
      actualAmount: 0,
      balanceAmount: 0,
      budgetAmount: 0,
    },
  };
  annualBudget.filter((budget) => budget.depth === 0).forEach((item) => {
    const isIncome = item.negate > 0;
    item.monthlyAmounts.forEach((bud) => {
      const monthVal = bud.isToDate ? 'toDate' : DateTime.fromISO(bud.startDate).toFormat('MM-yyyy');
      if (!bud.summaryBalance) {
        if (monthlyWiseTotal[monthVal]) {
          monthlyWiseTotal[monthVal] = {
            ...monthlyWiseTotal[monthVal],
            actualAmount: isIncome ? (monthlyWiseTotal[monthVal].actualAmount + bud.actualAmount) : (monthlyWiseTotal[monthVal].actualAmount - bud.actualAmount),
            budgetAmount: isIncome ? (monthlyWiseTotal[monthVal].budgetAmount + bud.budgetAmount) : (monthlyWiseTotal[monthVal].budgetAmount - bud.budgetAmount),
            balanceAmount: monthlyWiseTotal[monthVal].balanceAmount + bud.balanceAmount,
          };
        } else {
          monthlyWiseTotal = {
            ...monthlyWiseTotal,
            [monthVal]: {
              actualAmount: bud.actualAmount,
              budgetAmount: bud.budgetAmount,
              balanceAmount: bud.balanceAmount,
              startDate: DateTime.fromISO(bud.startDate),
              isToDate: bud.isToDate,
            },
          };
        }
      }
    });
    annualBudgetTotals.monthlyTotal = Object.values(monthlyWiseTotal).sort((firstIndividualMonth, secondIndividualMonth) => firstIndividualMonth.startDate - secondIndividualMonth.startDate);

    annualBudgetTotals.summary = annualBudgetTotals.monthlyTotal.reduce((accumulator, individualMonth) => ({
      actualAmount: accumulator.actualAmount + (individualMonth.isToDate ? 0 : individualMonth.actualAmount),
      budgetAmount: accumulator.budgetAmount + (individualMonth.isToDate ? 0 : individualMonth.budgetAmount),
    }), {
      actualAmount: 0,
      budgetAmount: 0,
    });

    // calculate balance from budget items type 'gr' balances
    annualBudgetTotals.summary.balanceAmount = annualBudget.filter((budget) => budget.depth === 0)
      .reduce((balanceAmount, budget) => balanceAmount + (budget.summary?.balanceAmount || 0), 0);
  });
  return annualBudgetTotals;
};
