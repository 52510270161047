import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { makeStyles } from '@mui/styles';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';

import { getPayeesNameList } from 'data/payees/selectors';

import useQPreferences from 'components/QPreferences/useQPreferences';
import HighlightText from 'components/HighlightText';

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    minWidth: 120,
    paddingLeft: theme.spacing(1),
    fontSize: theme.components.register.fontSize.default,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  popper: {
    minWidth: 250,
  },
  option: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  },
  groupLabel: {
    ...theme.typography.caption,
    paddingBottom: theme.spacing(0.5),
  },
  allowCreateContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(1)} ${theme.spacing(0.5)}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: 'pointer',
    position: 'sticky',
    top: 0,
    left: 0,
  },
}));

const BoldHighlight = styled.b`
  color: ${({ theme }) => theme.palette.text.primary} !important;
`;


// static functions for rendering options/groups
const groupByKey = (op) => {
  switch (op.groupKey) {
    case 'EXISTING_PAYEE': return 'Choose from existing';
    case 'NEW_PAYEE': return 'Create new payee';
    default: return null;
  }
};
const getOptionLabel = (op) => typeof op === 'string' ? op : op.label;
const filterOptions = createFilterOptions({ stringify: (op) => typeof op === 'string' ? op : op.name, ignoreCase: true, trim: true, ignoreAccents: true });
const renderOption = (props, option, state) => (option.isNewPayee ?
  <li {...props} style={{ marginBottom: 8 }}>{option.label}</li>
  :
  <li {...props}>
    <HighlightText highlightComponent={BoldHighlight} text={option.label} searchKeys={[state.inputValue]} />
  </li>);

const getOptionSelected = (op, value) => op.label === value && op.groupKey !== 'NEW_PAYEE';

export default function SPayeeField(props) {
  const { onChange, allowCreateRule, textFieldProps, defaultValue, inRegister, ...otherProps } = props;

  const classes = useStyles();
  const { datasetPreferences, setDatasetPreference } = useQPreferences();
  const { defaultCreateRuleToChecked = true } = datasetPreferences; // default to true

  const [localValue, setLocalValue] = useState(defaultValue || '');
  const [open, setOpen] = useState(false);
  const [shouldCreateRenameRule, setShouldCreateRenameRule] = useState(defaultCreateRuleToChecked);
  const eatClickAndToggleShouldCreateRenameRule = (e) => {
    e?.preventDefault();
    e?.stopPropagation();

    setDatasetPreference({ defaultCreateRuleToChecked: !shouldCreateRenameRule });
    setShouldCreateRenameRule(!shouldCreateRenameRule);
  };

  useEffect(() => setLocalValue(defaultValue || ''), [defaultValue]); // update value if default changes

  // Building options array to pass to Autocomplete
  const options = useSelector(getPayeesNameList)?.toArray().map((payee, index) => ({ groupKey: 'EXISTING_PAYEE', name: payee.name, label: payee.name, index }));
  if (localValue.length > 0 && !options.find((item) => item.name === localValue)) {  // if we want to show the user they can create a new payee
    options.unshift({ groupKey: 'NEW_PAYEE', name: localValue, label: localValue, isNewPayee: true });
  }

  const handleChange = (event, updatedValue, reason) => {
    if (reason === 'selectOption' || reason === 'blur') {
      if (event.key === 'Enter') {  // maintain focus
        event.preventDefault();
        event.stopPropagation();
      }

      // use the current field value rather than the highlighted value on blur
      let currentVal = reason === 'blur' ? localValue : updatedValue || localValue;
      currentVal = ((typeof currentVal === 'string') ? currentVal : currentVal?.label) || '';
      if (currentVal !== defaultValue) {
        // TODO: maybe deprecate 'e' pass after register gets updated
        onChange(event, currentVal);
      }
      // close paper on select or blur
      setOpen(false);
    }
  };

  const handleInputChange = (_e, value, reason) => {
    setLocalValue(value || '');
    if (reason !== 'reset') {
      setOpen(true);
    }
  };

  const handleBlur = () => setOpen(false);

  const handleKeyDown = (e) => {
    // need to force change because register doesn't blur on tab
    if (inRegister && (e.key === 'Tab' && localValue.length > 0)) {
      handleChange(e, localValue, 'blur');
    }
  };

  useEffect(() => { setShouldCreateRenameRule(defaultCreateRuleToChecked); }, [defaultCreateRuleToChecked]);

  const optionalClasses = inRegister ? {
    input: null,
    inputRoot: classes.inputRoot,
    groupLabel: classes.groupLabel,
    option: classes.option,
  } : {};

  return (
    <Autocomplete
      value={defaultValue || ''}
      inputValue={localValue}
      open={open}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onBlur={handleBlur}
      groupBy={groupByKey}
      getOptionLabel={getOptionLabel}
      options={options}
      renderTags={() => null}
      filterOptions={filterOptions}
      renderOption={renderOption}
      isOptionEqualToValue={getOptionSelected}
      selectOnFocus
      autoComplete
      autoSelect
      autoHighlight
      freeSolo
      disableClearable
      PaperComponent={allowCreateRule ? ({ children, ...paperProps }) => ( // by intercepting the PaperComponent we can inject options to keep at the top
        <Paper elevation={12} {...paperProps}>
          <ListItem onMouseDown={eatClickAndToggleShouldCreateRenameRule} className={classes.allowCreateContainer}>
            <Checkbox checked={shouldCreateRenameRule} />
            <Typography variant={'caption'}>Create a rule to also rename this payee for future transactions</Typography>
          </ListItem>
          {children}
        </Paper>
      )
        :
        (
          Paper
        )}
      renderInput={({ InputProps, ...otherParams }) => (
        <TextField
          {...otherParams}
          onKeyDown={handleKeyDown}
          autoFocus
          placeholder={'Select payee'}
          label={'Payee'}
          variant="outlined"
          margin={inRegister ? 'none' : 'normal'}
          {...textFieldProps}
          InputProps={{
            ...InputProps,
            ...(textFieldProps?.InputProps || {}),
          }}
        />
      )}
      {...otherProps}
      classes={{
        ...optionalClasses,
        popper: classes.popper,
        ...(otherProps.classes || {}),
      }}
    />
  );
}
SPayeeField.defaultProps = {
  textFieldProps: {},
  allowCreateRule: false,
  onChange: PropTypes.func,
};
SPayeeField.propTypes = {
  textFieldProps: PropTypes.object,
  allowCreateRule: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  inRegister: PropTypes.bool,
};
