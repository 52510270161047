import React, { PureComponent } from 'react';
import compose from 'utils/compose';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

import { getFieldString } from 'data/transactions/searchFilter';
import { getAccountString } from 'data/accounts/retrievers';

const styles = (theme) => ({
  root: {
    maxWidth: 250,
    minHeight: 60,
    padding: 6,
    boxShadow: `0px 0px 7px ${theme.palette.greyScaleDeprecated[4]}`,
    display: 'flex',
    flex: '2 1 0%',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  textStyle: {
    color: 'black',
  },
  txnLine: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
  },
  textStyleLeft: {
    extend: 'textStyle',
    flex: 2,
  },
  textStyleRight: {
    extend: 'textStyle',
    flex: 1,
    flexShrink: 0,
    textAlign: 'right',
  },
  bottomRow: {
    fontSize: '12px',
    color: theme.palette.greyScaleDeprecated[2],
  },
});

class MiniTxnCard extends PureComponent {
  render() {
    const { classes, txn, ...props } = this.props;

    return (
      <div {...props}>
        <div
          className={classes.root}
        >
          <div className={classes.txnLine}>
            <Typography className={classes.textStyleLeft} noWrap> {txn.payee} </Typography>
            <Typography
              className={classes.textStyleRight}
            >
              {getFieldString('amount', txn)}
            </Typography>
          </div>
          <div className={classes.txnLine}>
            <Typography className={classNames(classes.textStyleLeft, classes.bottomRow)} noWrap>
              {getAccountString(txn.accountId)}
            </Typography>
            <Typography
              className={classNames(classes.textStyleRight, classes.bottomRow)}
            >
              {getFieldString('date', txn)}
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

MiniTxnCard.propTypes = {
  classes: PropTypes.object.isRequired,
  txn: PropTypes.object,
};

export default compose(
  withStyles(styles)
)(MiniTxnCard);


