import { takeEvery } from 'redux-saga/effects';

import { resourceStoreTypes, resourceSagaUtils } from '@quicken-com/react.flux.core';

import * as actions from './ssoActions';

export function* getSSOTokenActionWatcher() {
  const config = resourceStoreTypes.mkQcsSyncResourceConfig({
    resourceName: 'unregisterMeForBeta',
    resourceBaseUrl: '/jwt-sso/token-redirect',
    successAction: actions.getSSOTokenSuccess,
    failureAction: actions.getSSOTokenFailure,
  });
  yield takeEvery(actions.getSSOToken, resourceSagaUtils.qcsSyncGetResource, config);
}

export default [
  getSSOTokenActionWatcher,
];
