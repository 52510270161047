import * as reduxPersist from 'redux-persist';
import localForage from 'localforage';
import { encryptTransform } from 'redux-persist-transform-encrypt';

import CryptoJS from 'crypto-js';
import { DateTime } from 'luxon';

import { coreActions } from '@quicken-com/react.flux.core';
import { crashReporterInterface, getLogger, tracker, localPreferences } from '@quicken-com/react.utils.core';
import { POSTPONED_ACTIONS_REDUCER_KEY } from '@quicken-com/react.flux.postponed-actions';
import { ALERTS_REDUCER_KEY } from '@quicken-com/react.flux.alerts';
import { DATASETS_REDUCER_KEY } from '@quicken-com/react.flux.datasets';
import { authTypes, AUTH_REDUCER_KEY, authSelectors } from '@quicken-com/react.flux.auth';
import { PROFILE_REDUCER_KEY } from '@quicken-com/react.flux.profile';
import { FEATURE_FLAGS_REDUCER_KEY } from '@quicken-com/react.flux.feature-flags';
import { ACCOUNTS_REDUCER_KEY } from '@quicken-com/react.flux.accounts';
import { TAGS_REDUCER_KEY } from '@quicken-com/react.flux.tags';
import { CATEGORIES_REDUCER_KEY } from '@quicken-com/react.flux.categories';
import { TRANSACTIONS_REDUCER_KEY } from '@quicken-com/react.flux.transactions';
import { SCHEDULED_TRANSACTIONS_REDUCER_KEY } from '@quicken-com/react.flux.scheduled-transactions';

import { convertToJSRecursive, convertToImmutableRecursive } from 'utils/immutableSerialize';
import store from 'store';
import createReducer from 'reducers';
import * as sessionStorageEx from 'utils/sessionStorageEx';

import { REDUCER_KEY as APP_REDUCER_KEY } from 'data/app/reducer';
import { REDUCER_KEY as DOCUMENTS_REDUCER_KEY } from 'data/documents/reducer';
import { REDUCER_KEY as INSTITUTIONS_REDUCER_KEY } from 'data/institutions/reducer';
import { REDUCER_KEY as INSTITUTION_LOGINS_REDUCER_KEY } from 'data/institutionLogins/reducer';
import { REDUCER_KEY as MEMORIZED_RULES_REDUCER_KEY } from 'data/memorizedRules/reducer';
import { REDUCER_KEY as RULES_REDUCER_KEY } from 'data/renameRules/reducer';
import { REDUCER_KEY as PAYEES_REDUCER_KEY } from 'data/payees/reducer';
import { ENTITLEMENTS_REDUCER_KEY } from 'data/entitlements/entitlementsTypes';
import { ALERT_RULES_REDUCER_KEY } from 'data/alertRules/reducer';
import { BUDGETS_REDUCER_KEY } from 'data/budgets/budgetsReducer';
import { BUDGET_ITEMS_REDUCER_KEY } from 'data/budgetItems/budgetItemsReducer';
import { CATEGORY_GROUP_LISTS_REDUCER_KEY } from 'data/categoryGroupLists/categoryGroupListsReducer';
import { CATEGORY_GROUPS_REDUCER_KEY } from 'data/categoryGroups/categoryGroupsReducer';
import { INVESTMENT_HOLDINGS_REDUCER_KEY } from 'data/investmentHoldings/reducer';
import { INVESTMENT_PERFORMANCE_V2_REDUCER_KEY } from 'data/investmentPerformanceV2/investmentPerformanceV2Reducer';
import { REDUCER_KEY as PREFERENCES_REDUCER_KEY } from 'data/preferences/reducer';
import { PREFERENCES_V2_REDUCER_KEY } from 'data/preferencesV2/preferencesV2Reducer';
import { ACCOUNTS_BALANCES_REDUCER_KEY } from '@quicken-com/react.flux.accounts-balances';
import { SUBSCRIPTIONS_REDUCER_KEY } from 'data/subscriptions/subscriptionsReducer';
import { CONFIG_FF_REDUCER_KEY } from '@quicken-com/react.flux.config-feature-flags';

const log = getLogger('persistor.js');

const debug = localPreferences.getDebug();

let persistor;

const persistConfig = {
  key: 'root', // key is overriden in the code below
  storage: localForage,
  version: 20, // increment it if existing data model has breaking changes
  transforms: [
    reduxPersist.createTransform( // reset temporary data
      (inboundState, _reducerKey, _fullState) => inboundState, // save to persist transform
      (outboundState, reducerKey, _fullState) => { // load from persist transform
        let newReducerState = outboundState;
        if (newReducerState.isLoading && newReducerState.set) { // reset isLoading
          newReducerState = newReducerState.set('isLoading', false);
        }
        if (newReducerState.resourcesById && newReducerState.resourcesById.set && newReducerState.resourcesById.filter) { // remove optimistic/ghost records
          newReducerState = newReducerState.set('resourcesById', newReducerState.resourcesById.filter((resource, key) => resource.id === key));
        }
        const currentState = store.getState()[reducerKey];
        switch (reducerKey) {
          case APP_REDUCER_KEY: {
            const { storeCreatedAt } = newReducerState;
            newReducerState = newReducerState.clear().merge({
              storeCreatedAt,
              sessionStartedAt: DateTime.utc().toISO(),
            });
          }
            break;
          case AUTH_REDUCER_KEY: {
            verify(outboundState.datasetId, 'transform: datasetId not found in DB #bug-trap');
            const accessTokenExpiredNew = DateTime.fromISO(outboundState.accessTokenExpired);
            const refreshTokenExpiredNew = DateTime.fromISO(outboundState.refreshTokenExpired);
            newReducerState = newReducerState.merge({
              ...((!accessTokenExpiredNew.isValid || DateTime.fromISO(currentState.accessTokenExpired) > accessTokenExpiredNew) && {
                accessTokenExpired: currentState.accessTokenExpired,
                accessToken: currentState.accessToken,
              }),
              ...((!refreshTokenExpiredNew.isValid || DateTime.fromISO(currentState.refreshTokenExpired) > refreshTokenExpiredNew) && {
                refreshTokenExpired: currentState.refreshTokenExpired,
                refreshToken: currentState.refreshToken,
              }),
              source: authTypes.AuthSessionSources.REDUX_PERSIST,
              datasetId: outboundState.datasetId || currentState.datasetId,
            });
          }
            break;
          default:
        }
        return newReducerState;
      },
    ),
    reduxPersist.createTransform(
      (inboundState) => {
        try {
          return convertToJSRecursive(inboundState);
        } catch (e) {
          crashReporterInterface.reportError(e);
          throw e;
        }
      },
      (outboundState) => {
        try {
          return convertToImmutableRecursive(outboundState);
        } catch (e) {
          crashReporterInterface.reportError(e);
          throw e;
        }
      }
    ),
  ],
  whitelist: [
    APP_REDUCER_KEY,
    FEATURE_FLAGS_REDUCER_KEY,
    AUTH_REDUCER_KEY,
    ENTITLEMENTS_REDUCER_KEY,
    ACCOUNTS_REDUCER_KEY,
    CATEGORIES_REDUCER_KEY,
    DATASETS_REDUCER_KEY,
    DOCUMENTS_REDUCER_KEY,
    INSTITUTIONS_REDUCER_KEY,
    INSTITUTION_LOGINS_REDUCER_KEY,
    MEMORIZED_RULES_REDUCER_KEY,
    PROFILE_REDUCER_KEY,
    RULES_REDUCER_KEY,
    TAGS_REDUCER_KEY,
    TRANSACTIONS_REDUCER_KEY,
    PAYEES_REDUCER_KEY,
    SCHEDULED_TRANSACTIONS_REDUCER_KEY,
    ALERT_RULES_REDUCER_KEY,
    ALERTS_REDUCER_KEY,
    POSTPONED_ACTIONS_REDUCER_KEY,
    BUDGETS_REDUCER_KEY,
    BUDGET_ITEMS_REDUCER_KEY,
    CATEGORY_GROUP_LISTS_REDUCER_KEY,
    CATEGORY_GROUPS_REDUCER_KEY,
    INVESTMENT_HOLDINGS_REDUCER_KEY,
    PREFERENCES_REDUCER_KEY,
    PREFERENCES_V2_REDUCER_KEY,
    INVESTMENT_PERFORMANCE_V2_REDUCER_KEY,
    ACCOUNTS_BALANCES_REDUCER_KEY,
    SUBSCRIPTIONS_REDUCER_KEY,
    CONFIG_FF_REDUCER_KEY,
  ],
  migrate: (restoredState, toVersion) => {
    const fromVersion = restoredState?._persist?.version; // eslint-disable-line no-underscore-dangle
    if (fromVersion !== toVersion) {
      const description = `version mismatch - reset to defaults (${fromVersion} -> ${toVersion})`;
      log.warn(description);
      persistor?.purge?.();
      tracker.track(tracker.events.persistPurge, { description });
      return Promise.resolve({});
    }
    if (DateTime.fromISO(restoredState[APP_REDUCER_KEY]?.storeCreatedAt)?.plus({ days: 30 }) < DateTime.utc()) {
      const description = `DB is expired - reset (${restoredState[APP_REDUCER_KEY].storeCreatedAt} vs ${DateTime.utc().toISO()})`;
      log.warn(description);
      persistor?.purge?.();
      tracker.track(tracker.events.persistPurge, { description });
      return Promise.resolve({});
    }
    return Promise.resolve(restoredState);
  },
  throttle: 50,
  debug,
  serialize: (data) => {
    log.debug(`save to persist (${typeof data})...`);
    return JSON.stringify(data);
  },
  deserialize: (serial) => {
    log.debug(`load from persist (${typeof serial})...`);
    return JSON.parse(serial);
  },
};

export const persistStore = (inStore = undefined, inBaseReducer = undefined, inPersistKey = undefined) => {
  persistor = undefined;
  const persist = localPreferences.getPersist();
  if (persist) {
    const lastSession = localPreferences.getAuthSession() || sessionStorageEx.getAuthSession();
    const theStore = inStore || store;
    const datasetId = authSelectors.getDatasetId(theStore.getState()) || lastSession?.datasetId;
    const baseReducer = inBaseReducer || createReducer();
    let key = inPersistKey;
    if (!key && datasetId) {
      key = CryptoJS.SHA256(datasetId);
    }
    if (key && datasetId) {
      const encryptor = encryptTransform({
        secretKey: datasetId,
        onError: (error) => {
          const description = `encryptor error - reset to defaults: ${error}`;
          log.warn(description);
          assert(false, `encryptor error: ${error}`);
          persistor?.purge?.();
          tracker.track(tracker.events.persistPurge, { description });
        },
      });
      if (encryptor) {
        const configUpdated = {
          ...persistConfig,
          key,
          transforms: [
            ...(persistConfig.transforms || []),
            encryptor,
          ],
        };
        const persistReducer = reduxPersist.persistReducer(configUpdated, baseReducer);
        theStore.replaceReducer(persistReducer);

        tracker.track(tracker.events.persistLoadStart);
        tracker.timeEvent(tracker.events.persistLoadComplete);
        const start = new Date();

        persistor = reduxPersist.persistStore(
          theStore,
          null,
          () => {
            const complete = new Date();
            log.log(`load from persist complete in ${complete - start}ms...`, theStore.getState());
            tracker.track(tracker.events.persistLoadComplete);

            const state = theStore.getState();
            assert(state.authStore.datasetId, 'persist load complete: no dataset id found in store');

            theStore.dispatch(coreActions.persistDataLoadComplete());
          },
        );
      }
    }
  }
  return persistor;
};

export const getPersistor = () => persistor;
