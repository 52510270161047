
// CORE
import React, { useState, useRef, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import _ from 'lodash';

import { getLogger, tracker } from '@quicken-com/react.utils.core';
import { scheduledTransactionsActions, scheduledTransactionsTypes } from '@quicken-com/react.flux.scheduled-transactions';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LockIcon from '@mui/icons-material/LockRounded';
import CheckIcon from '@mui/icons-material/DoneRounded';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

// DATA
import { useDispatch } from 'react-redux';
import { discoverBiller } from 'data/billerLogins/actions';
import { mkBillerLogin, RefreshStatusEnum } from 'data/billerLogins/types';

// CUSTOM COMPONENTS
import QButton from 'components/QButton';
import FormikSelect from 'components/Formik/Select';
import FormikTextField from 'components/Formik/TextField';
import { bindPromiseAction } from 'utils/actionHelpers';

const log = getLogger('BillPresentment/LoginPage');


const useStyles = makeStyles((theme) => ({
  // form styles
  form: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    maxHeight: 'calc(100% - 32px)',
    overflow: 'auto',
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  credentials: {
    paddingRight: 20,
    marginTop: 24,
    marginBottom: 0,
    zIndex: 1,
  },
  fieldSpace: {
    marginTop: 0,
    marginBottom: 24,
    width: 460,
  },
  field: {
    display: 'flex',
    padding: '10px 0',
  },
  lock: {
    color: theme.palette.greyScaleDeprecated[4],
  },
  formLabel: {
    maxWidth: 414,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  shrunkLabel: {
    maxWidth: 560,
  },

  checkBox: {
    marginTop: '-8px',
    paddingLeft: '3px',
    float: 'right',
  },
  checkLabel: {
    color: theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.6),
    size: '12px',
  },

  // action/footer styles
  formActions: {
    position: 'absolute',
    bottom: 24,
    right: 24,
    width: 'calc(100% - 48px)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
  },
  rightLine: {
    height: 26,
    width: 1,
    backgroundColor: theme.palette.greyScaleDeprecated[4],
    marginRight: 8,
  },
  subLock: {
    height: 24,
    width: 24,
    marginRight: 8,
  },

  loadingFade: {
    position: 'absolute',
    top: 94,
    left: 0,
    width: '100%',
    height: 'calc(100% - 154px)', // full height - header and footer size
    backgroundColor: theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[7], 1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 40,
    alignContent: 'center',
    color: theme.palette.text.secondary,
  },
  check: {
    color: theme.palette.link.main,
    paddingRight: 10,
    paddingBottom: 5,
    transform: 'scale(1.5)',
  },

  notice: {
    margin: '6px 20px 6px 0',
    padding: '4px 8px',
    borderRadius: 4,
    border: `1px solid ${theme.palette.number.negative}`,
    color: theme.palette.number.negative,
    backgroundColor: theme.applyOpacityToHex(theme.palette.color4.opacity30, 0.3),
  },
  lockMessage: {
    height: 1,
    width: 'calc(100% - 4px)',
    backgroundColor: theme.applyOpacityToHex(theme.palette.number.negative, 0.3),
    margin: '2px 0',
  },
}));

// const credentialsHardcode = [
//   {
//     type: 'USERNAME',
//     display: 'Username and other really long things to ellipse',
//     description: 'Username and other really long things to ellipse? Make no mistake. Im serius',
//     choices: [],
//     status: 'UNVERIFIED',
//     examples: [],
//   },
//   {
//     type: 'PASSWORD',
//     display: '',
//     description: 'Password',
//     choices: [],
//     status: 'UNVERIFIED',
//     examples: [],
//   },
//   // {
//   //   type: "CHOICE",
//   //   description: "Region",
//   //   status: "UNKNOWN",
//   //   examples: [],
//   //   choices: [
//   //     "NY: Long Island (Gas)",
//   //     "NY: Metro (Gas)",
//   //     "NY: Upstate (Electric/Gas)",
//   //     "MA (Electric)",
//   //     "MA (Gas)",
//   //     "NH (Electric)",
//   //     "RI (Electric/Gas)",
//   //   ],
//   // },
//   {
//     type: 'QUESTION_ANSWER_CHOICE',
//     display: 'Your id code will be sent as a text to your phone. Please select which phone number',
//     description: 'Your id code will be sent as a text to your phone Please select which phone number',
//     choices: ["XXX-XXX-1805", "XXX-XXX-0000"],
//     status: 'MISSING',
//     examples: [],
//   },
//   {
//     type: 'QUESTION_ANSWER_CHOICE',
//     description: 'Send code to',
//     choices: ["Mobile ***-***-2674", "Home ***-***-9613"],
//     status: 'MISSING',
//     examples: [],
//   },
//   {
//     type: 'QUESTION_ANSWER_CHOICE',
//     description: 'Delivery Method',
//     choices: ["Text message", "Phone call"],
//     status: 'MISSING',
//     examples: [],
//   },
// ];


// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
// ### - - -                  Main Component                     - - - ###
// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
function LoginPage(props) {

  const classes = useStyles();
  const dispatch = useDispatch();

  const formRef = useRef();
  const isMounted = useRef();

  const { biller, onComplete, connectedLogin, showSnackbar, hideSnackbar, series, mixpanelProps, currentLogin, setCurrentLogin } = props;
  const reconnect = Boolean(connectedLogin);

  const [showPassword, setShowPassword] = useState(false);
  const [verifiedCredentials, setVerifiedCredentials] = useState([]);
  const [MFA, setMFA] = useState(false);

  const credentials = currentLogin?.credentials || biller.credentials;
  // const credentials = credentialsHardcode;

  // track mounted state because of async promise
  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);

  // # - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // #      Promise resolution functions
  // #      Need make sure component exists to protect from memory leak
  // # - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  const resetForm = (oldLogin, newLogin) => {
    if (isMounted?.current) {
      formRef.current?.setSubmitting?.(false);

      if ((newLogin?.refreshStatus === RefreshStatusEnum.CREDENTIALS_MISSING) && !MFA) {
        setMFA(true);
      }

      // store verified values
      const oldCredentials = oldLogin?.credentials;
      const newCredentials = newLogin?.credentials;
      if ((oldCredentials !== newCredentials) && (oldCredentials?.length < newCredentials?.length)) {
        log.info('New credentials - caching previously verified credentials');
        setVerifiedCredentials(oldLogin?.credentialAnswers);
      }

      if (newLogin?.refreshStatus === RefreshStatusEnum.REFRESHED) {
        // here is when it completes
        onComplete(reconnect || newLogin);
        log.info('Refresh complete - getting accounts');
        if (reconnect) {
          tracker.track(tracker.events.fixBillerComplete, mixpanelProps);
        }
      }
      setCurrentLogin(newLogin);
      hideSnackbar();
    }
  };
  const errorReset = (error, oldLogin, newLogin) => {
    if (isMounted?.current) {
      formRef.current?.setSubmitting?.(false);
      log.error(error);
      hideSnackbar();

      // TODO: Could we update login with better notice?
      const errorLogin = (newLogin || oldLogin)?.set('notice', 'We ran into an error when trying to link your biller. Try again.');
      setCurrentLogin(errorLogin);
    }
  };

  // # - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // #      New Async Request
  // # - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  const handleSubmit = (values) => {
    log.info('submitting credentials');

    // save model to pending state
    if (!reconnect) {
      dispatch(scheduledTransactionsActions.updateScheduledTransaction(scheduledTransactionsTypes.mkScheduledTransaction({
        id: series.id,
        billPresentmentAccountId: '0',
        providerBillerId: biller?.id,
      })));
    }

    // generate new credentialed login and send
    const newLogin = mkBillerLogin({
      ...(currentLogin?.toJS?.() || {}),
      providerBillerID: biller.id,
      credentials,
      credentialAnswers: credentials.map(
        (field, index) => verifiedCredentials[index] || values[field.description]
      ),
      // refreshStatus: RefreshStatusEnum.REFRESHING,
    });

    // console.log('values', values);
    // console.log('credentials', credentials);
    // console.log('verifiedCredentials', verifiedCredentials);
    // console.log('newLogin answers', newLogin?.credentialAnswers);

    const closureResolve = (returnedLogin) => resetForm(newLogin, returnedLogin);
    const closureError = (error, returnedLogin) => errorReset(error, newLogin, returnedLogin);

    setCurrentLogin(newLogin);
    showSnackbar();
    const billerDiscoveryPromise = bindPromiseAction(dispatch, discoverBiller);
    billerDiscoveryPromise({ newLogin, reconnect, mixpanelProps, MFA }).then(closureResolve, closureError).catch(errorReset);
  };

  const handleShowClick = () => setShowPassword(!showPassword);

  // ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
  // ### - - -                  Render Sections                    - - - ###
  // ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###

  const renderField = (field, index, formikBag) => {
    const { touched, errors, values } = formikBag;

    const helperText = _.get(touched, field.description) && _.get(errors, field.description);
    const inError = Boolean(helperText);
    const value = _.get(values, field.description);
    let newValue;
    const label = field.display || field.description;
    const largeLabel = label?.length > 45;

    const isPasswordField = field.type === 'PASSWORD';
    const question = field.type === 'QUESTION_ANSWER_CHOICE' || field.type === 'CHOICE';

    let ret;
    if (question) {
      if (!value) {
        newValue = field?.choices?.[0];
        if (newValue) {
          formRef?.current?.setFieldValue(field.description, newValue);
        }
      }
      ret = (
        <FormControl key={field.description} variant="outlined" margin="normal" className={classes.fieldSpace} error={inError}>
          {!largeLabel && <InputLabel id={`label-${index}`}>{label}</InputLabel>}
          <Field
            component={FormikSelect}
            name={field.description}
            validate={(val) => !(val && field.choices?.includes?.(val)) && 'invalid'}
            variant="outlined"
            fullWidth
            label={!largeLabel && label}
            id={`field#${index}`}
            type={!isPasswordField || showPassword ? 'text' : 'password'}
            autoComplete={isPasswordField ? 'new-password' : null}
            inputProps={{
              value: value || newValue,
              maxLength: 214, // max that RSA 2048 encryption could do
            }}
          >
            {field.choices.map((choice) => (
              <MenuItem key={choice} value={choice}>
                {choice}
              </MenuItem>
            ))}
          </Field>

          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      );
    } else {
      ret = (
        <Field
          component={FormikTextField}
          key={field.description}
          name={field.description}
          validate={(val) => !(val && val !== '') && 'required'}
          variant="outlined"
          margin="normal"
          fullWidth
          InputProps={{
            id: `field#${index}`,
            autoFocus: index === 0,
            endAdornment: (
              <InputAdornment position="end">
                <LockIcon className={classes.lock} />
              </InputAdornment>
            ),
            inputProps: {
              maxLength: 214, // max that RSA 2048 encryption could do
            },
          }}
          label={!largeLabel && label}
          type={!isPasswordField || showPassword ? 'text' : 'password'}
          autoComplete={isPasswordField ? 'new-password' : null}
          InputLabelProps={{
            classes: {
              root: classes.formLabel,
              shrink: classes.shrunkLabel,
            },
          }}
          classes={{
            root: classes.fieldSpace,
          }}
        />
      );
    }

    return (
      <Fragment key={field.description}>
        {largeLabel &&
          <Typography variant="body2">
            {label}
          </Typography>}
        {ret}
      </Fragment>
    );
  };

  const currentVals = formRef.current?.values;
  const initialValues = credentials?.reduce((result, field) => {
    const value = currentVals && _.get(currentVals, field.description);
    return ({
      ...result,
      ...({ [field.description]: value || (field.type === 'QUESTION_ANSWER_CHOICE' ? field.choices[0] : '') }),
    });
  }, {});

  let credentialsToRender = credentials;
  if (verifiedCredentials.length) {
    credentialsToRender = credentials.slice(verifiedCredentials?.length, credentials.length);
  }

  return (
    <Formik
      key={credentials.length}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {(formikBag) => {
        formRef.current = formikBag;
        const { handleSubmit: formikHandleSubmit, isSubmitting, errors, values } = formikBag;
        const inValidated = Boolean(Object.keys(errors).length);

        let emptyForm = false;
        credentials.forEach((field, index) => {
          if (!(verifiedCredentials[index] || values[field.description])) {
            emptyForm = true;
          }
        });
        return (
          <>
            {isSubmitting &&
              <div className={classes.loadingFade}>
                <CircularProgress size={64} />
                <div className={classes.row}>
                  <CheckIcon className={classes.check} />
                  <Typography variant="body2">
                    Connecting to Biller
                  </Typography>
                </div>
              </div>}
            <Form className={classes.form} onSubmit={formikHandleSubmit}>
              <div className={classes.formContent}>
                {currentLogin?.notice &&
                <Typography variant="caption" className={classes.notice}>
                  {currentLogin.notice}
                  {currentLogin.refreshStatus === RefreshStatusEnum.LOCKED &&
                    <>
                      <br />
                      <div className={classes.lockMessage} />
                      This login is locked. You will need to resolve this with your biller before connecting to Simplifi
                    </>}
                </Typography>}

                <div className={classes.credentials}>
                  {credentialsToRender?.map((field, index) => renderField(field, index, formikBag, classes))}
                </div>
                {credentialsToRender.filter((field) => field.type === 'PASSWORD').length > 0 && //  If any of the fields are a PASSWORD field
                  <FormControlLabel
                    label="Show Password"
                    classes={{ label: classes.checkLabel, root: classes.checkBox }}
                    control={
                      <Checkbox
                        checked={showPassword}
                        onChange={handleShowClick}
                        value="Show Password"
                      />
                    }
                  />}
              </div>

              <div className={classes.formActions}>
                <Typography variant="body2" className={classes.footer} component="div">
                  <div className={classes.rightLine} />
                  <LockIcon className={classes.subLock} />
                  Secured, encrypted connections
                </Typography>

                <QButton
                  id="submitButton"
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting || inValidated || emptyForm}
                  width={96}
                >
                  {verifiedCredentials.length > 0 ? 'CONTINUE' : 'CONNECT'}
                </QButton>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
}

LoginPage.defaultProps = {
  biller: {},
};

LoginPage.propTypes = {
  biller: PropTypes.object,
  series: PropTypes.object,
  onComplete: PropTypes.func,
  connectedLogin: PropTypes.object,
  showSnackbar: PropTypes.func,
  hideSnackbar: PropTypes.func,
  mixpanelProps: PropTypes.object,
  currentLogin: PropTypes.object,
  setCurrentLogin: PropTypes.func,
};

export default LoginPage;
