/* eslint react/prop-types: 0 */

import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import compose from 'utils/compose';

import ButtonBase from '@mui/material/ButtonBase';
import Menu from '@mui/material/Menu';
import withStyles from '@mui/styles/withStyles';
import MoreVertIcon from '@mui/icons-material/MoreVertRounded';

import { getLogger } from '@quicken-com/react.utils.core';

import QIconButton from 'components/QIconButton';
import QTip from 'components/QuickenControls/QTip';

import QMenuList from 'components/QMenu/QMenuList';

const log = getLogger('components/QuickenControls/QMenu/index.js');

function pointInRect(x, y, domRect) {

  const fitX = (x >= domRect.x && x <= (domRect.x + domRect.width));
  const fitY = (y >= domRect.y && y <= (domRect.y + domRect.height));

  return (fitX && fitY);
}

const styles = (theme) => ({
  iconColor: {
    color: theme.palette.text.primary, // theme.palette.greyScaleDeprecated[2],
  },

  standardItem: {
    paddingLeft: 16,
  },
  standardItemHoverContainer: {
    borderRadius: theme.shape.borderRadius * 1,
  },
  menuSubHead: {
    paddingBottom: 5,
    color: theme.palette.text.secondary,
  },
  listIcon: {
    marginLeft: 0,
    marginRight: 5,
    minWidth: 0,
  },
  listText: {
    whiteSpace: 'noWrap',
    fontSize: theme.typography.body2.fontSize,
  },
  listTextRoot: {
    lineHeight: 'normal',
  },
  popOver: {
    padding: 20,
  },
  iconFiller: {
    width: 24,
    marginRight: 5,
  },
  parentList: {
    paddingBottom: 0,
    '&:focus': {
      outline: 'none',
    },
  },
  divider: {
    marginBottom: 8,
  },
  subIndent: {
    marginLeft: 5,
  },
});


/*
 *
 * QMENU
 *
 * Properties:
 *
 * Property               Default
 *
 * onChange               null
 * options:               null
 * selected               null        value of selected item (only works with label/value items)
 * menuIcon               'vertIcon' || 'arrowDown' || ??
 * viewBox                null        alternate viewBox for the menu icon
 * [
 *   {
 *     divider: false       // if true, a divider is displayed, all other fields ignored
 *     isSubheader: false   // if true, label is used as subhead text, all other fields ignored
 *     customRender: <node> // if set, used instead of label
 *     label: <string>,
 *     value: <string>,
 *   },
 *   ...
 * ]
 *
 */

let idCounter = 1;

class QMenu extends PureComponent {

  constructor(props) {
    super(props);

    /* eslint-disable react/no-unused-state */
    this.state = {
      isSelectBox: Boolean(props.selected),
      selected: props.selected,
      anchorEl: null,
      value: null,
      extraId: null,
      lastHover: null,
      anchorHover: null,
      myId: idCounter,
      openSub: new Set([]),
    };
    idCounter += 1;

    this.menuRef = null;
    this.menuRect = null;
    this.mouseInMenu = false;
    this.subMenuActive = false;
    this.enteredOnce = false;

  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // this.myLog('new props');
    if (newProps.selected !== this.state.selected) {
      this.setState({ selected: newProps.selected });
    }
  }

  onChange = (value, extraId, noClose) => {

    if (noClose) {
      this.props.onChange(value, extraId, noClose);
    } else {
      this.state.value = value;
      this.state.extraId = extraId;
      if (!noClose) {
        this.handleRequestClose();
      }
    }
  };

  onEntered = () => {
    const e = document.getElementById(`long-menu-wrapper_${this.state.myId}`);
    this.menuRef = e;
    this.menuRef.focus();
    this.myLog('MENU ENTERED', this.state.myId);
    /* NOP
    this.mouseInMenu = true;
    if (e) {
      this.myLog(this.menuRef.getBoundingClientRect());
      this.menuRect = this.menuRef.getBoundingClientRect();
    }
    if (this.props.subMouseEntered) {
      this.props.subMouseEntered();
    }
    document.addEventListener('mousemove', this.mouseMove, true);
    */
  };

  onExiting = () => {
    this.menuRef = null;
    /* NOP
    this.myLog('Exiting...');
    document.removeEventListener('mousemove', this.mouseMove, true);
    this.menuRect = null;
    this.enteredOnce = false;
    this.mouseInMenu = false;
    */
  };

  onExited = () => {
    this.myLog('EXITED ', this.props.name, this.state.value);

    if (this.props.onChange && this.state.value !== null) {
      this.props.onChange(this.state.value, this.state.extraId);
    }
    this.setState({ value: null, extraId: null, lastHover: null, anchorHover: null, anchorEl: null });

    if (this.props.subMouseLeft) {
      this.props.subMouseLeft();
    }
  };

  myLog = (...args) => {
    log.debug('QMenu', this.props.name, args);
  };

  // when mouse leaves this menu
  mouseLeave = () => {

    this.myLog('MouseLeave');
    this.mouseInMenu = false;

    if (this.props.subMouseLeft) {
      this.props.subMouseLeft();
    }
    setTimeout(() => {
      if (!this.subMenuActive) {
        this.myLog('SUB MENU NOT ACTIVE, CLOSING', this.props.name);
        this.handleRequestClose();
      }
    }, 5);
  };

  // this is called back from a subMenu node on it's own mouse events
  subMouseLeft = () => {
    /* making this a NOP, can be on a feature flag later
    this.myLog('SUB MOUSE LEFT', this.props.name);
    this.subMenuActive = false;
    */
  };
  // this is called back from a subMenu node on it's own mouse events
  subMouseEntered = () => {
    /* making this a NOP, can be on a feature flag later
    this.subMenuActive = true;
    this.myLog('SUB MOUSE ENTERED', this.props.name, this.subMenuActive);
    */
  };

  mouseMove = (e) => {

    this.state.anchorHover = null;

    if (this.menuRef && this.menuRect) {
      this.mouseInMenu = pointInRect(e.clientX, e.clientY, this.menuRect);
    }

    this.enteredOnce = this.enteredOnce || this.mouseInMenu;

    // if left the menu, close it
    /*
    if (this.enteredOnce && !this.mouseInMenu && !this.subMenuActive && !this.mouseLeftTimer) {
      this.mouseLeftTimer = setTimeout(() => {
        this.mouseLeftTimer = null;
        if (!this.mouseInMenu) {
          this.mouseLeave();
        }
      }, 500);
      // this.mouseLeave();
    }
    */
  };

  // mouse left the icon/trigger for opening a submenu
  mouseLeftSubMenuTrigger = () => {

    this.myLog('MOUSE LEFT SUB MENU', this.mouseInMenu);
    setTimeout(() => {
      if (!this.mouseInMenu) {
        this.setState({ anchorEl: null });
      }
    }, 500);
  };

  // request to close this menu
  handleRequestClose = () => {
    log.log('REQUEST CLOSE', this.props.name);
    this.setState({ anchorEl: null });
    if (this.props.onClose) {
      this.props.onClose();
    }
    document.removeEventListener('mousemove', this.mouseMove, true);
  };

  // item from this menu was clicked on
  handleClick = (value, extraId, noClose) => {

    if (noClose && this.props.onChange) {
      this.props.onChange(value, extraId, noClose);
      return;
    }
    this.state.value = value;
    this.state.extraId = extraId;
    this.handleRequestClose(true);
  };

  // show this menu (the trigger was clicked or hovered)
  initMenu = (event, delay = false) => {

    const target = event.currentTarget;
    event.stopPropagation();
    if (delay) {
      setTimeout(() => this.setState({ anchorEl: target }), 500);
    } else {
      this.setState({ anchorEl: target });
    }
  };

  keyDown = (e) => {
    log.log('QMENU KEYDOWN', e.key);
    if (e.key === 'Enter' ||
      e.key === 'Escape' ||
      e.key === 'ArrowUp' ||
      e.key === 'ArrowDown' ||
      e.key === 'Tab') {

      if (!this.subMouseLeft()) {
        e.stopPropagation();
      }
    }
    if (e.key === 'Escape') {
      this.handleRequestClose(true);
    }
    if (e.key === 'ArrowRight') {
      // get selected item, if it is a sub menu,open it

    }
  };

  buttonRef = (x) => {
    if (this.props.open) {
      this.setState({ anchorEl: x });
    }
    if (this.props.buttonRef) {
      this.props.buttonRef(x);
    }
  };

  hover = (e, field) => {
    const currTarget = e.currentTarget;

    if (this.hoverTimeout) {
      clearTimeout(this.hoverTimeout);
      this.hoverTimeout = null;
    }
    this.hoverTimeout = setTimeout(() =>
      this.setState({ anchorHover: currTarget, lastHover: field }), 5);
  };

  render() {
    const open = Boolean(this.state.anchorEl) || Boolean(this.props.open);

    const { customTrigger, menuStyle, classes, colorWhite, menuIcon, menuCloseIcon, menuIconButtonSize,
      viewBox, openOnHover, subMouseEntered, subMouseLeft, anchorEl, options, buttonRef, title, customTriggerClass, ...other } = this.props;

    const MenuIcon = !open ? (menuIcon || MoreVertIcon) : (menuCloseIcon || menuIcon || MoreVertIcon);

    const subMenuStyle = openOnHover ? { textAlign: 'right', width: 50 } : null;

    const listItemsData = [];
    options.forEach((item) => {
      if (!item.nop) {
        listItemsData.push(
          <QMenuList
            key={`qmenulist_item_${JSON.stringify(item.value)}_${item.label}`}
            item={item}
            subMenuType={QMenu}
            hover={this.hover}
            keyDown={this.keyDown}
            subMouseEntered={this.subMouseEntered}
            subMouseLeft={this.subMouseLeft}
            onChange={this.onChange}
            classes={this.props.classes}
            anchorHover={this.state.anchorHover}
            name={this.props.name}
            handleClick={this.handleClick}
            theme={this.props.theme}
          />
        );
      }
    });

    return (
      <>
        {customTrigger &&

          <QTip title={title}>
            <ButtonBase
              onClick={this.initMenu}
              ref={(x) => this.buttonRef(x)}
              id={`qmenu_open_${this.props.name}`}
              autoFocus={this.props.autoFocus}
              focusRipple
              className={customTriggerClass || ''}
            >
              {customTrigger}
            </ButtonBase>
          </QTip>}
        {!customTrigger &&
          <div
            onMouseEnter={openOnHover ? (e) => this.initMenu(e, true) : null}
            onMouseLeave={openOnHover ? this.mouseLeftSubMenuTrigger : null}
            style={{ ...subMenuStyle, ...menuStyle }}
          >
            <QTip title={title}>
              <QIconButton
                id={`qmenu_open_${this.props.name}`}
                aria-label="More"
                aria-owns={open ? `long-menu_${this.state.myId}` : null}
                aria-haspopup="true"
                onClick={this.initMenu}
                component="div"
                size={menuIconButtonSize || null}
                autoFocus={this.props.autoFocus}
              >
                <MenuIcon
                  className={classes.iconColor}
                />
              </QIconButton>
            </QTip>
          </div>}

        <div
          onMouseEnter={subMouseEntered}
        >
          <Menu
            style={{ zIndex: 2000, height: 'auto', width: 'auto', padding: this.props.theme.shape.padding[2] }}
            {...other}
            id={`long-menu_${this.state.myId}`}
            open={open}
            anchorEl={anchorEl || this.state.anchorEl}
            anchorOrigin={other.anchorOrigin || { horizontal: 'right', vertical: 'bottom' }}
            transformOrigin={other.transformOrigin || { horizontal: 'left', vertical: 'top' }}
            getContentAnchorEl={null}
            onKeyDown={(e) => this.keyDown(e, anchorEl || this.state.anchorEl)}
            PaperProps={{
              id: `long-menu-wrapper_${this.state.myId}`,
              style: {
                whiteSpace: 'nowrap',
                background: this.props.theme.palette.greyScaleDeprecated[7],
                borderRadius: this.props.theme.shape.borderRadius * 2,
              },
            }}
            onBackdropClick={(e) => {
              e.stopPropagation();
              this.handleRequestClose();
              e.preventDefault();
            }}
            TransitionProps={{
              onExited: this.onExited,
              onExiting: this.onExiting,
              onEntered: this.onEntered,
            }}
          >
            {listItemsData}
          </Menu>
        </div>
      </>
    );
  }
}

QMenu.defaultProps = {
  name: 'root',
};

QMenu.propTypes = {
  selected: PropTypes.string,
  viewBox: PropTypes.string,
  onChange: PropTypes.func,             // your onChange function, sends the new selection
  options: PropTypes.array,
  menuIcon: PropTypes.object,
  menuCloseIcon: PropTypes.func,
  menuStyle: PropTypes.object,
  colorBlack: PropTypes.bool,
  colorWhite: PropTypes.bool,
  classes: PropTypes.object,
  notifyAfterClose: PropTypes.oneOf([PropTypes.string, PropTypes.object]),
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
  subMouseLeft: PropTypes.func,
  subMouseEntered: PropTypes.func,
  openOnHover: PropTypes.bool,
  name: PropTypes.string,
  title: PropTypes.string,
  menuIconButtonSize: PropTypes.string,
  buttonRef: PropTypes.func,
  theme: PropTypes.object,
  customTriggerClass: PropTypes.string,
};

export default compose(
  withStyles(styles, { withTheme: true }),
)(QMenu);
