import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { categoriesSelectors } from '@quicken-com/react.flux.categories';
import { transactionsTypes } from '@quicken-com/react.flux.transactions';

import makeStyles from '@mui/styles/makeStyles';
import AlarmOn from '@mui/icons-material/AlarmOn';
import { formatNumber } from 'components/QuickenControls/AmountField';
import { getCalendarTxnType } from 'data/transactions/utils';
import { transactionLabelStyles } from '../../styles';

const useStyles = makeStyles(transactionLabelStyles);

function getTxnTypeClasses(type, classes) {
  switch (type) {
    case transactionsTypes.calTxnLabelTypes.more:
      return classes.moreType;
    case transactionsTypes.calTxnLabelTypes.income:
    case transactionsTypes.calTxnLabelTypes.expense:
      return classes.dueType;
    case transactionsTypes.calTxnLabelTypes.overdue:
      return classes.overdueType;
    case transactionsTypes.calTxnLabelTypes.normal:
    default:
      return null; // classes.normalType
  }
}

const TransactionLabel = (props) => {
  const classes = useStyles();
  const { txn, showMoreLabel, extraTxns } = props;

  const incomeCOAIds = useSelector((state) => !showMoreLabel && categoriesSelectors.getIncomeCOAIds(state));

  const type = useMemo(() => !showMoreLabel ? getCalendarTxnType(txn, incomeCOAIds) : transactionsTypes.calTxnLabelTypes.more, [showMoreLabel, txn, incomeCOAIds]);

  const typeClasses = useMemo(() => getTxnTypeClasses(type, classes), [type, classes]);
  const isIncome = useMemo(() => !showMoreLabel && parseFloat(txn.amount) > 0.0, [txn, showMoreLabel]);
  const payeeLabel = useMemo(() => !showMoreLabel ? txn.payee : `${extraTxns} more...`, [showMoreLabel, txn, extraTxns]);
  const amountLabel = useMemo(() => !showMoreLabel && formatNumber(txn.amount, 'USD', '0,00.00'), [showMoreLabel, txn]);

  return (
    <div className={classNames(classes.txnLabel, { [typeClasses]: Boolean(typeClasses) }, isIncome ? classes.incomeType : classes.expenseType)}>
      <div className={classes.payeeLabel}>
        <AlarmOn className={classes.reminderIcon} />
        <span>{payeeLabel}</span>
      </div>
      {amountLabel && <div className={classes.amountLabel}>{amountLabel}</div>}
    </div>
  );
};

TransactionLabel.propTypes = {
  txn: PropTypes.object,
  showMoreLabel: PropTypes.bool,
  extraTxns: PropTypes.number,
};

export default React.memo(TransactionLabel);
