/* eslint-disable react/no-danger */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useInterval, useUpdate } from 'react-use';
import { Set as ImmutableSet, Map as ImmutableMap } from 'immutable';
import { push } from 'connected-react-router';

import NotificationsIcon from 'assets/nav-menu/notifications.inline.svg';

import Badge from '@mui/material/Badge';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

import { alertsTypes, alertsSelectors, alertsActions } from '@quicken-com/react.flux.alerts';
import zeroStateIcon from 'assets/zero-state-images/empty-box.svg';
import useFetchOnce from 'hooks/useFetchOnce';
import { getSmartTimeLabel } from 'utils/date/utils';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';

import { DIALOG_TYPE as DIALOG_CONFIRMATION, mkConfirmationDialogProps } from 'components/Dialogs/ConfirmationDialog';
import QPopper from 'components/QPopper';
import Dump from 'components/Dump';
import ZeroStateView from 'components/ZeroStateView';

// const parseMetaData = (jsonMetaData) => {
//   let metaData;
//   if (jsonMetaData) {
//     try {
//       metaData = JSON.parse(jsonMetaData);
//     } catch (e) {
//       assert(false, e);
//     }
//   }
//   return metaData;
// }

const pathForAlert = (alert) => {
  let path = alert.deepLinkUri;

  switch (alert.deepLinkComponent) {
    case 'transactions':
      if (alert.deepLinkEntityId) {
        const tmpURL = new URL(window.location.origin);
        tmpURL.pathname = '/transactions';
        tmpURL.searchParams.append('txnId', alert.deepLinkEntityId);
        path = tmpURL.pathname + tmpURL.search;
      }
      break;
    default:
  }

  switch (alert.type) {
    case 'UNCATEGORIZED_TXNS':
      path = '/reports/spending/by-category/total?selectCategory=0';
      break;
    case 'REFUND_TRACKER':
      path = '/reports/refund-tracker';
      break;
    case 'MONTHLY_SPENDING_COMPARISON':
      path = '/reports/spending/by-none/over-time';
      break;
    default:
  }

  return path;
};

const useStyles = makeStyles((_theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  popper: {
    paddingBottom: 0,
  },
  list: {
    maxWidth: 350,
    maxHeight: 420,
    overflow: 'auto',
  },
  listItem: {
    '&:hover': {
      '& $dismissButton': {
        opacity: 1,
      },
    },
  },
  dismissButton: {
    opacity: 0,
  },
  unread: {
    margin: 9,
  },
  alertIcon: {
    padding: 6,
    filter: 'brightness(0) invert(1)', // make it white
  },
}));

const NotificationsNavIcon = React.forwardRef((props, ref) => {
  const { onClose, open } = props;
  const classes = useStyles();
  const theme = useTheme();

  const palette = useMemo(() =>
    Object.fromEntries(Object.entries(alertsTypes.IconBgColors).map(([_key, value], index) => (
      [value, theme.palette.data.sequence1[index % theme.palette.data.sequence1.length]]
    ))), [theme.palette.data.sequence1]);

  const dispatch = useDispatch();
  useFetchOnce(alertsActions.getAlerts, alertsSelectors);
  const [popperAnchorEl, setPopperAnchorEl] = useState();
  const [showPopper, setShowPopper] = useState();
  const [disableClickAway, setDisableClickAway] = useState(false);
  const alertsUnread = useSelector(alertsSelectors.getAlertsUnread);
  const alerts = useSelector(alertsSelectors.getAlerts);

  const update = useUpdate();
  useInterval(update, props.open ? 60000 : null);

  const updatePopperAnchorEl = useCallback((anchorElRef) => {
    setPopperAnchorEl(anchorElRef);
  }, [setPopperAnchorEl]);

  const notificationsIconOnClick = useCallback((event) => {
    if (typeof props.open !== 'boolean') {
      setShowPopper(!showPopper);
      event.stopPropagation();
      if (showPopper) {
        updatePopperAnchorEl(event.target);
      }
    }
  }, [props.open, setShowPopper, showPopper, updatePopperAnchorEl]);

  const onPopperClose = useCallback((event) => {
    if (onClose) {
      onClose(event);
    } else {
      setShowPopper(false);
    }
  }, [onClose, setShowPopper]);

  const onClickAway = useCallback(() => {
    if (!disableClickAway) {
      onClose?.();
    }
  }, [disableClickAway, onClose]);

  const onPopperKeyDown = useCallback((event) => {
    if (event.key === 'Escape') {
      onPopperClose(event);
    }
  }, [onPopperClose]);

  // mark items as read when visible
  const [alertsRead, setAlertsRead] = useState(ImmutableSet());
  const intersectionObserverHandler = useCallback((entries) => entries
    .filter((entry) => entry.isIntersecting)
    .forEach((entry) => setAlertsRead((set) => set.add(entry.target.dataset.alertid))), []);
  const [intersectionObserver, setIntersectionObserver] = useState();
  useEffect(() => {
    let observer;
    if (open) {
      setTimeout(() => {
        observer = new IntersectionObserver(intersectionObserverHandler, {
          root: null,
          rootMargin: '0px',
          threshold: 0.9,
        });
        setIntersectionObserver(observer);
      }, 3000);
    }
    return () => {
      observer?.disconnect();
      setIntersectionObserver(null);
    };
  }, [open, intersectionObserverHandler]);

  const dispatchAlertsRead = useCallback(() => {
    if (alertsRead?.size) {
      const alertsUpdates = alertsRead
        .filter((id) => alerts.get(id) && !alerts.get(id)?.isRead && !alerts.get(id)?.isDismissed)
        .toArray()
        .map((id) => ({ id, isRead: true }));
      if (alertsUpdates.length) {
        dispatch(alertsActions.batchAlerts(alertsUpdates));
      }
    }
  }, [alertsRead, alerts, dispatch]);

  useEffect(() => {
    if (!open) {
      dispatchAlertsRead();
    }
  }, [open, dispatchAlertsRead]);

  return (
    <>
      <Badge
        ref={ref}
        max={99}
        badgeContent={alertsUnread.size}
        color="primary"
      >
        <NotificationsIcon
          // ref={updatePopperAnchorEl}
          onClick={notificationsIconOnClick}
        />
      </Badge>

      <QPopper
        anchorEl={props.popperAnchorEl ?? popperAnchorEl}
        open={Boolean(props.open ?? showPopper)}
        placement="right-end"
        onClickAway={onClickAway}
        onKeyDown={onPopperKeyDown}
        className={classes.popper}
        modifiers={[{
          name: 'offset',
          options: {
            offset: [20, 20],
          },
        }]}
        arrowPadding={40}
      >
        {!alerts.size &&
        <ZeroStateView
          icon={zeroStateIcon}
          iconSize={{ height: 168, width: 168 }}
          size="small"
          primary="You don't have any notifications."
          onClick={(event) => event.stopPropagation()}
          tabIndex={0}
        >
          <IconButton
            size="small"
            aria-label="keyboard hook"
            autoFocus
            style={{ width: 0, height: 0, padding: 0 }}
          />
        </ZeroStateView>}

        {Boolean(alerts.size) &&
        <>
          <div className={classes.header}>
            <Typography variant="h5">
              Notifications
            </Typography>

            {Boolean(alerts.size) &&
            <Link
              id="clear-all"
              href="#"
              underline="none"
              onClick={(event) => {
                const alertsUpdates = alerts.map((alert) => ({
                  id: alert.id,
                  isDismissed: true,
                  isDeleted: true,
                }));
                if (alertsUpdates.size) {
                  setDisableClickAway(true);
                  dispatch(createDialog(mkRootUiData({
                    id: 'clear-notifications-confirmation',
                    type: DIALOG_CONFIRMATION,
                    allowNesting: true,
                    props: ImmutableMap(mkConfirmationDialogProps({
                      title: 'Clear Notifications',
                      content: 'Are you sure you want to clear all notifications?',
                      confirmLabel: 'Yes',
                      confirmAction: (reason, closeEvent) => {
                        dispatch(alertsActions.batchAlerts(alertsUpdates, { undo: { userMessage: `${alerts.size} notifications dismissed.`, disableUndoAction: true } }));
                        closeEvent?.stopPropagation();
                        setTimeout(() => setDisableClickAway(false), 500);
                      },
                      denyLabel: 'Cancel',
                      denyAction: (reason, closeEvent) => {
                        closeEvent?.stopPropagation();
                        setTimeout(() => setDisableClickAway(false), 500);
                      },
                    })),
                  })));
                }
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              Clear All
            </Link>}
          </div>

          <List
            dense
            className={classes.list}
          >
            {alerts.valueSeq().map((alert, index) => (
              <React.Fragment key={alert.id}>
                {Boolean(index) &&
                <Divider
                  key={`${alert.id}-divider`}
                  variant="inset"
                  component="li"
                />}

                <ListItem
                  data-alertid={alert.id}
                  id={`${alert.type}:${index}`}
                  key={`${alert.id}-item`}
                  button
                  autoFocus={!index}
                  alignItems="flex-start"
                  onClick={(event) => {
                    event.stopPropagation();
                    if (alert.id && !alert.isRead) {
                      dispatch(alertsActions.updateAlert({ id: alert.id, isRead: true }));
                    }
                    if (alert.deepLinkUri?.length) {
                      dispatch(push(pathForAlert(alert)));
                    }
                    onPopperClose(event);
                  }}
                  className={classes.listItem}
                  ref={(itemRef) => {
                    if (itemRef) {
                      intersectionObserver?.observe(itemRef);
                    }
                  }}
                >
                  <Dump obj={alert} />
                  <ListItemAvatar>
                    <Avatar
                      variant="rounded"
                      alt={alert.title}
                      src={alert.icon}
                      // src={'https://s3.us-west-2.amazonaws.com/images.services.quicken.com/logos/categories/default.svg'}
                      classes={{ img: classes.alertIcon }}
                      style={{
                        backgroundColor: palette[alert.iconBgColor]
                          || theme.palette.data.sequence1[(alert.title?.charCodeAt(0) ?? 0) % theme.palette.data.sequence1.length],
                      }}
                    />
                  </ListItemAvatar>

                  <ListItemText
                    primary={alert.title}
                    secondary={<span dangerouslySetInnerHTML={{ __html: alert.message }} />}
                  />
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-end"
                    minWidth={72}
                  >
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                      <Badge
                        color="primary"
                        variant="dot"
                        invisible={alert.isRead}
                        className={classes.unread}
                      />

                      <IconButton
                        className={classes.dismissButton}
                        size="small"
                        aria-label="delete"
                        onClick={(event) => {
                          event.stopPropagation();
                          // if (isQuicken) {
                          //   dispatch(alertsActions.updateAlert({
                          //     id: alert.id,
                          //     isDismissed: true,
                          //   }, { undo: { userMessage: 'Notification is dismissed.' } }));
                          // }
                          dispatch(alertsActions.deleteAlert({ id: alert.id, isDeleted: true }));
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Box>

                    <Typography variant="body2">
                      {getSmartTimeLabel(alert.createdAt)}
                    </Typography>
                  </Box>
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </>}
      </QPopper>
    </>
  );
});

NotificationsNavIcon.propTypes = {
  popperAnchorEl: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

NotificationsNavIcon.whyDidYouRender = { customName: 'NotificationsNavIcon' };

export default NotificationsNavIcon;
