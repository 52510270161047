// @flow

import { List, OrderedMap, Record } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';

import { AccountNode } from 'data/accountNodes/types';

type DisplayOptionsProps = {
  expand: boolean,
  selected: boolean,

  path: ?List<string>,
  children: ?OrderedMap<string, AccountNode>,
};
export type DisplayOptions = RecordOf<DisplayOptionsProps>;
export const makeDisplayOptions: RecordFactory<DisplayOptionsProps> =
  Record({
    expand: true,
    selected: false,

    path: null,
    children: null,
  });

