
export const styles = ({ palette, _components }) => ({
  inputField: {

  },
  adornment: {
    marginRight: 3,
  },
  reduceLeftMargin: {
    extend: 'adornment',
    paddingBottom: 2,
    marginLeft: 3,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  positive: {
    color: palette.number.positive,
  },
  negative: {
    color: palette.number.negative,
  },
});

