import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import CheckIcon from '@mui/icons-material/CheckRounded';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import classNames from 'classnames';

export default class QMenuList extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      isSelectBox: Boolean(props.selected),
      selected: props.selected,
      mySubMenu: null,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // this.myLog('new props');
    if (newProps.selected !== this.state.selected) {
      this.setState({ selected: newProps.selected });
    }
  }

  dividerStyle = (item) =>
    item.divider ? {
      borderBottom: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: this.props.theme.palette.grey.level2,
      paddingBottom: this.props.theme.shape.padding[2],
      marginBottom: this.props.theme.shape.padding[2],
    } : null;

  subMenuRef = (x) => {
    this.state.mySubMenu = x;
  };

  makeCheckListItem = (item, selected, groupId) => {

    const { classes } = this.props;

    const id = item.value;

    return (
      <ListItem
        style={this.dividerStyle(item)}
        button
        onClick={(e) => {
          e.stopPropagation();
          this.props.handleClick(id, groupId, item.noCloseOnClick);
        }}
        key={`qmenu_li_${JSON.stringify(item.value)}`}
        id={`qmenu_id_${item.label}`}
        value={selected ? 'on' : 'off'}
        className={classes.standardItemHoverContainer}
      >
        {selected &&
        <ListItemIcon className={classes.listIcon} style={{ minWidth: 0 }}>
          <CheckIcon />
        </ListItemIcon>}
        {!selected &&
        <div className={classes.iconFiller} />}

        <Typography variant="body2">
          {item.label}
        </Typography>
      </ListItem>
    );
  };

  render() {

    const { theme, classes, hover, keyDown, handleClick, anchorHover, onChange,
      subMouseLeft, subMouseEntered, item } = this.props;

    const data = [];


    // NOP
    if (item.nop) {
      /* SUBHEADER OR GROUP LIST */
    } else if (item.isSubheader || item.isGroupList) {
      if (item.label) {
        data.push(
          <ListSubheader
            key={`qmenu_sub_${item.label}`}
            className={classes.parentList}
            disableSticky
          >
            <ListItemText
              className={classes.menuSubHead}
              secondary={item.label}
            />
          </ListSubheader>
        );
      }

      if (item.isGroupList) {
        item.groupList.forEach((x) => {
          if (!x.nop) {
            data.push(this.makeCheckListItem(x, (item.selected === x.value || x.selected), item.groupId));
          }
        });
      }

      /* CUSTOM RENDER */

    } else if (item.customRender) {
      data.push(
        <div
          key={`qmenu_custom_${item.value}`}
          onMouseEnter={(e) => hover(e, JSON.stringify(item.value))}
        >
          <MenuItem
            style={this.dividerStyle(item)}
            id={`qmenu_id_${item.label}`}
            button
            onKeyDown={keyDown}
            onClick={(e) => {
              e.stopPropagation();
              handleClick(item.value);
            }}
          >
            {item.customRender}
          </MenuItem>
        </div>
      );

      /* SELECT BOX */

    } else if (this.state.isSelectBox) {
      data.push(this.makeCheckListItem(item, this.state.selected === item.value));

      // SUB MENU

    } else if (item.subMenu) {
      const SubMenu = this.props.subMenuType;

      data.push(
        <ListItem
          dense
          key={`qmenu_sub_${item.label}`}
          className={classes.standardItemHoverContainer}
          onMouseEnter={(e) => hover(e, JSON.stringify(item.value))}
          button
          onKeyDown={(e) => {
            if (e.key === 'ArrowRight' && this.state.mySubMenu) {
              this.state.mySubMenu.initMenu(e);
            }
            return true;
          }}
          onClick={(e) => {
            if (item.itemOpensMenu) {
              if (this.state.mySubMenu) {
                this.state.mySubMenu.initMenu(e);
              }
              return true;
            }
            return (item.value ? handleClick(item.value, null, item.noCloseOnClick) : null);
          }}
        >
          <ListItemText
            id={`qmenu_sub_${item.label}`}
            primary={item.label}
            style={this.dividerStyle(item)}
            classes={{ root: classes.listTextRoot, primary: classes.listText }}
          />
          <SubMenu
            ref={this.subMenuRef}
            anchorEl={anchorHover}
            menuIcon={KeyboardArrowRightIcon}
            menuIconButtonSize="small-no-padding"
            menuStyle={{ lineHeight: '0px' }}
            viewBox="-3 0 27 27"
            onChange={onChange}
            options={item.subMenu}
            theme={theme}
            classes={classes}
            subMouseLeft={subMouseLeft}
            subMouseEntered={subMouseEntered}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            name={`${this.props.name}:sub:${item.label}`}
          >
          </SubMenu>
        </ListItem>
      );

      // STANDARD MENU ITEM

    } else {
      data.push(
        <ListItem
          dense
          style={this.dividerStyle(item)}
          className={classNames(classes.standardItem, classes.standardItemHoverContainer)}
          id={`qmenu_id_${item.label}`}
          key={`qmenu_li_${JSON.stringify(item.value)}`}
          button
          onClick={(e) => {
            e.stopPropagation();
            handleClick(item.value);
          }}
          selected={this.props.selected === item.label}
        >
          <div
            onMouseEnter={(e) => hover(e, JSON.stringify(item.value))}
            className={item.subIndent ? classes.subIndent : ''}
          >
            <ListItemText
              primary={item.label}
              classes={{ root: classes.listTextRoot, primary: classes.listText }}
            />
          </div>
        </ListItem>
      );
    }

    return data;
  }

}

QMenuList.propTypes = {
  item: PropTypes.object,
  selected: PropTypes.string,
  onChange: PropTypes.func,             // your onChange function, sends the new selection
  classes: PropTypes.object,
  anchorHover: PropTypes.object,
  subMouseLeft: PropTypes.func,
  subMouseEntered: PropTypes.func,
  name: PropTypes.string,
  hover: PropTypes.func,
  keyDown: PropTypes.func,
  handleClick: PropTypes.func,
  subMenuType: PropTypes.func,

  theme: PropTypes.object,

};
