import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';

import { getBuildConfig, getLogger } from '@quicken-com/react.utils.core';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';

import Box from '@mui/material/Box';

import {
  ConnectCancelEvent,
  ConnectDoneEvent,
  ConnectErrorEvent,
  ConnectRouteEvent,
  FinicityConnect,
} from '@finicity/connect-web-sdk';

import type { Institution } from 'data/institutions/types';
import { bindPromiseAction } from 'utils/actionHelpers';

import * as actions from '../../actions';
import * as selectors from '../../selectors';

const log = getLogger('FinicityConnect/index.js');

type Props = {
  scope: string,

  institution: Institution,
  onClosed: (any) => void,
  onSuccess: (any) => void,
  // trackingProperties: any,
};

const getChannelFromFinicityEnv = (env) => {
  switch (env) {
    case 'production':
      return 'FINICITY';
    case 'development':
      return 'FINICITY';
    case 'sandbox':
      return 'FINICITY';
    default:
      // TODO: Should throw error here!
      return null;
  }
};

const FinicityConnectWrapper = (props: Props) => {
  const { institution, onClosed, onSuccess, scope } = props;
  const dispatch = useDispatch();

  const [aggregationInfo, setAggregationInfo] = useState(null);

  const institutionLogin = useSelector((state) => selectors.getInstitutionLogin(state, { scope }));
  const finicityEnvOverride = useSelector((state) => featureFlagsSelectors.getFeatureFlag(state, 'finicityEnv'));

  const getFinicityEnv = finicityEnvOverride || getBuildConfig().finicity_env;
  const channel = getChannelFromFinicityEnv(getFinicityEnv);

  const getAggregationInfo = () => {
    const getAggregationInfoPromise = bindPromiseAction(dispatch, actions.getAggregationInfo);
    getAggregationInfoPromise(
      { institution,
        channel,
        institutionLogin,
      },
      { scope }
    ).then(
      (localAggregationInfo) => {
        log.info('Aggregation Info', localAggregationInfo);
        setAggregationInfo(localAggregationInfo);
      },
      () => {
        log.error('Retrieving Aggregation Info Failed');
        // TODO: What to do in case of failure?
      }
    );
  };

  useMount(() => getAggregationInfo());

  const handleCancel = useCallback((event: ConnectCancelEvent) => {
    const { code, reason } = event;
    log.info('User cancelled connect', code, reason);
    onClosed({
      trackingProperties: {
        agg_status_code: code,
        agg_status_detail: reason,
      },
    });

  }, [onClosed]);

  const handleDone = useCallback((event: ConnectDoneEvent) => {
    log.info('User completed connect', event, aggregationInfo);
    onSuccess({ channel, pollingRef: aggregationInfo.pollingRef });
  }, [aggregationInfo, channel, onSuccess]);

  const handleError = useCallback((event: ConnectErrorEvent) => {
    const { code, reason } = event;
    const errorTrackingProperties = {
      agg_status_code: code,
      agg_status_detail: reason,
    };
    switch (code) {
      case 500:
        errorTrackingProperties.error_code = 'unknown';
        log.error('Unexpected error when connecting');
        break;
      case 1440:
        errorTrackingProperties.error_code = 'timeout';
        log.error('Timeout when connecting');
        break;
      default:
        errorTrackingProperties.error_code = 'unknown';
        log.error('Unhandled error when connecting', code, reason);
        break;
    }
    onClosed({
      trackingProperties: {
        ...errorTrackingProperties,
      },
    });
  }, [onClosed]);

  const handleRoute = useCallback((event: ConnectRouteEvent) => {
    const { screen, params } = event;
    log.info('User navigated in connect', screen, params);
    switch (screen) {
      case 'login': {
        // const { institutionId } = params;
        break;
      }
      case 'mfa': {
        // const { institutionId } = params;
        break;
      }
      case 'done':
        break;
      default:
        break;
    }
  }, []);

  const handleUser = useCallback((event: any) => {
    const { action } = event;
    log.info('User event in connect', action, event);
    switch (action) {
      case 'DisplayAlert': {
        // const { errorCode, message, data } = event;
        break;
      }
      case 'End':
        break;
      case 'FixMfaAttempt':
        break;
      case 'FixSignInAttempt':
        break;
      case 'Initialize':
        break;
      case 'LaunchOAuth':
        break;
      case 'MfaAttempt':
        break;
      case 'OAuthAddAccounts':
        break;
      case 'OAuthSignInAttempt':
        break;
      case 'RemoveAccounts':
        // TODO: Says this is supported in connect lite, but how if accounts are not selectable?
        break;
      case 'SignInAttempt':
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (aggregationInfo) {
      FinicityConnect.launch(
        aggregationInfo.connectUrl,
        {
          onCancel: handleCancel,
          onDone: handleDone,
          onError: handleError,
          onLoad: () => {
            log.info('Connect IFrame is loaded!');
          },
          onRoute: handleRoute,
          onUser: handleUser,
        },
        {
          overlay: 'rgba(199,201,199, 0.5)',
          selector: '#add-fi-contents',
        }
      );
    }
  }, [aggregationInfo, handleCancel, handleDone, handleError, handleRoute, handleUser]);

  return (
    <>
      <Box id="add-fi-contents" display="flex" flexDirection="column" m={3} />
    </>
  );
};

export default FinicityConnectWrapper;
