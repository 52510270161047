
import { List } from 'immutable';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import { categoriesSelectors } from '@quicken-com/react.flux.categories';
import { chartOfAccountsTypes, chartOfAccountsSelectors } from '@quicken-com/react.flux.chart-of-accounts';

import { mkBudgetItem } from 'data/budgetItems/budgetItemsTypes';

export const createNewBudgetItems = (budget, groupId, dateRange, itemInfo) => {

  let itemsToAdd = List();
  let startingDate = moment(moment(dateRange.startDate).format('YYYY-MM-01'));
  const isIncome = itemInfo.coa && categoriesSelectors.isIncomeCat(null, itemInfo.coa.id);

  do {
    const newBudgetItem = mkBudgetItem({
      clientId: uuidv4().toUpperCase(),
      budgetId: budget.id,
      startDate: moment(startingDate).format('YYYY-MM-DD'),
      amount: (isIncome ? Math.abs(itemInfo.amount) : -Math.abs(itemInfo.amount)),
      coa: itemInfo.coa && chartOfAccountsTypes.mkChartOfAccount(itemInfo.coa),
      groupId,
      name: itemInfo.name || chartOfAccountsSelectors.getCoaStringSelector(undefined, itemInfo.coa),
      type: itemInfo.budgetItemType || 'COA',
    });
    itemsToAdd = itemsToAdd.push(newBudgetItem);
    startingDate = startingDate.add(1, 'month');
  } while (startingDate.month() !== 0);

  return itemsToAdd;
};
export const setAmountSign = (amt, coa) => {

  // for the budget item, we flip the sign for expense cats when this amount was provided
  // from the category review card
  if (coa) {
    return categoriesSelectors.isIncomeCat(null, coa.id) ? amt : -amt;
  }
  return amt;
};
