/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { PersistGate } from 'redux-persist/integration/react';

import StylesProvider from '@mui/styles/StylesProvider';
import jssPreset from '@mui/styles/jssPreset';
import { create as jssCreate } from 'jss';
import jssCamelCase from 'jss-plugin-camel-case';
import jssDefaultUnit from 'jss-plugin-default-unit';
import jssGlobal from 'jss-plugin-global';
import jssExtend from 'jss-plugin-extend';
import jssNested from 'jss-plugin-nested';
import jssPluginTemplate from 'jss-plugin-template';
import jssPropsSort from 'jss-plugin-props-sort';
import jssRuleValueFunction from 'jss-plugin-rule-value-function';
import jssVendorPrefixer from 'jss-plugin-vendor-prefixer';
import 'sanitize.css/sanitize.css';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import * as am4core from '@amcharts/amcharts4/core';

// make sure to initialize react core before importing any of our components (including other react components)
import 'utils/interfaces/tracker';
import 'utils/interfaces/assert';
import 'utils/core';
import 'utils/interfaces/crahReporter';
import 'utils/interfaces/httpClient';
import 'utils/interfaces/bump';
import { history, getLogger, tracker } from '@quicken-com/react.utils.core';

import 'utils/tabAuthExchange'; // exchange auth session with other browser tabs
import { getPersistor, persistStore } from 'persistor';
import 'utils/wdyr'; // instruments/wraps react code, so should always be the first import before render started

import App from 'containers/App';
import CombinedProviders from 'containers/App/CombinedProviders';
import { appSetOnlineStatus } from 'data/app/actions';

import { initializeFeatureFlags } from 'utils/featureFlags/initialize';
import { initializeClientConfigFlags } from 'utils/clientConfigFlags';
import { dispatchEscapeKeyEvent } from 'utils/utils';
import PersistLoading from 'components/PersistLoading';
import consoleMirror from 'utils/consoleMirror';
import { captureNetworkLogs } from 'utils/networkLogs';
import { authSelectors } from '@quicken-com/react.flux.auth';
import ErrorBoundary from 'components/ErrorBoundary';

// Load the favicon and the .htaccess file
/////////////////////////////////////
///////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
/////////
////////////////////////////////////////
import '!file-loader?name=favicon.[ext]!./images/favicon-quicken.ico';
import '!file-loader?name=apple-touch-icon.[ext]!./images/apple-touch-icon-quicken.png';
/////////
import 'file-loader?name=[name].[ext]!./.htaccess'; // eslint-disable-line import/extensions

import GlobalStyles from './global-styles';
// TODO: Import root routes
import createSagas from './sagas';
import store from './store';

// TODO: Import selector for `syncHistoryWithStore`
// import { makeSelectLocationState } from 'containers/App/selectors';
/* eslint-enable import/no-webpack-loader-syntax */

const qcsId = authSelectors.getQCSId(store.getState());
tracker.identify(qcsId); // identify tracker ASAP

consoleMirror.attach();

const log = getLogger('app.js');
log.log(`app version: ${process.env.APP_VERSION} (${process.env.BUILD_NUMBER})`);

captureNetworkLogs(XMLHttpRequest.prototype);

tracker.track(tracker.events.init);

persistStore(store);

const isIe = require('is-iexplorer');

function nodeListPolyfill() {
  if (typeof NodeList.prototype.forEach === 'function') return false;
  NodeList.prototype.forEach = Array.prototype.forEach;
  return true;
}

nodeListPolyfill();

// ===============================================================================================
// Setup JSS Plugins (@mui/material requires certain JSS Plugins and we want to add to those)
// ===============================================================================================

// Note: This needs to include the plugins required by @mui/material. @mui/material provides a
// jssPreset().plugins array to use. THe implied usage would be:
//   const jss = jssCreate({ plugins: [jssExtend(), ...jssPreset().plugins] })
// However, this doesn't work correctly because the order of plugins matter. Therefore, will
// manually maintain the list of plugins required (including those required by @mui/material. This
// may cause issues in the future if the list required by @mui/material changes.

if (jssPreset().plugins.length !== 7) {
  log.warn('Check JSS Plugins, @mui/material Dependencies Changed');
}

// preset-defaults +compose, +expand, +rule-value-observable, +template. -extend

// Note: Be careful, order does matter!!
//
const jss = jssCreate({
  plugins: [
    jssRuleValueFunction(),
    jssPluginTemplate(),
    jssGlobal(),
    jssExtend(),
    jssNested(),
    jssCamelCase(),
    jssDefaultUnit(),
    jssVendorPrefixer(),
    jssPropsSort(),
  ],
});

// ===============================================================================================
// Initialize Launch Darkly (feature-flags)
// ===============================================================================================
initializeFeatureFlags();

// ===============================================================================================
// Initialize Client Config (feature-flags)
// ===============================================================================================
initializeClientConfigFlags();

// ===============================================================================================
// React APP Render
// ===============================================================================================
const isLoginMode = window !== window.top; // app is inside auth widget iframe
const MOUNT_NODE = document.getElementById('app');

const render = () => {
  const persisitor = !isLoginMode && getPersistor();
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <StylesProvider jss={jss}>
          <CombinedProviders>
            <ErrorBoundary>
              {persisitor ? (
                <PersistGate loading={<PersistLoading />} persistor={persisitor}>
                  <App />
                </PersistGate>
              ) : (
                <App />
              )}
            </ErrorBoundary>
          </CombinedProviders>
        </StylesProvider>
        <GlobalStyles />
      </ConnectedRouter>
    </Provider>,
    MOUNT_NODE
  );
};

// add global saga's
export const sagaTasks = createSagas(store);

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}

render();

// eslint-disable-next-line no-constant-condition
// if (false && process.env.NODE_ENV !== 'production') {
//   const { whyDidYouUpdate } = require('why-did-you-update'); // eslint-disable-line
//   whyDidYouUpdate(React);
// }

window.onclick = () => {
  tracker.trackActive();
};

//----------------------------------------------------------------------------------------------------------------------
// on resize, trigger an ESC keyup event to get MUI to close things it should before resize
//----------------------------------------------------------------------------------------------------------------------
function handleResize() {
  if (!isIe) dispatchEscapeKeyEvent(); // closes any open MUI things like Popovers
}

function resizeThrottler() {
  if (this.resizeTimeout) {
    clearTimeout(this.resizeTimeout);
  }
  this.resizeTimeout = setTimeout(handleResize, 66);
}

window.addEventListener('resize', resizeThrottler);

// enable amcharts license
am4core.options.commercialLicense = true;

// var installPromptEvent;
// window.addEventListener('beforeinstallprompt', function(event) {
//   event.preventDefault();
//   event.prompt();
//   installPromptEvent = event;
//   console.log('Got BeforeInstallPrompt event');
// });

window.addEventListener('load', () => {
  function handleNetworkChange(_event) {
    if (navigator.onLine) {
      log.debug('Application is online');
      store.dispatch(appSetOnlineStatus(true));
    } else {
      log.debug('Application is offline');
      store.dispatch(appSetOnlineStatus(false));
    }
  }

  window.addEventListener('online', handleNetworkChange);
  window.addEventListener('offline', handleNetworkChange);
});

if (!navigator.onLine) {
  log.debug('Application is offline 1', navigator.onLine, navigator);
  store.dispatch(appSetOnlineStatus(false));
}
