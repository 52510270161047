import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import compose from 'utils/compose';
import { List as ImmutableList, Map as ImmutableMap } from 'immutable';
import { Field, Form, Formik } from 'formik';
import _ from 'lodash';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';

import { scheduledTransactionsUtils, scheduledTransactionsActions, scheduledTransactionsTypes } from '@quicken-com/react.flux.scheduled-transactions';
import { categoriesSelectors } from '@quicken-com/react.flux.categories';
import { chartOfAccountsTypes } from '@quicken-com/react.flux.chart-of-accounts';
import { transactionsTypes, transactionsTransformers, transactionsUtils } from '@quicken-com/react.flux.transactions';
import { authSelectors } from '@quicken-com/react.flux.auth';

import { isAcme } from 'isAcme';
import QPureComponent from 'QPureComponent';

import { updateNewTags } from 'data/transactions/utils';
import AccountField from 'components/QuickenControls/AccountField';
import AmountField, { ShowSignEnum } from 'components/QuickenControls/AmountField';
import QButton from 'components/QButton';
import DownshiftField from 'components/QuickenControls/DownshiftField';
import CategoryField from 'components/QuickenControls/CategoryField';
import TagsField from 'components/QuickenControls/TagsField';
import QCheckbox from 'components/QCheckbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import MUISplitIcon from '@mui/icons-material/CallSplit';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import InputAdornment from '@mui/material/InputAdornment';
import deleteIcon from 'assets/icon-delete-black.svg';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import SplitsAndDetailsPanel from 'components/SplitsAndDetailsPanel';
import { configFeatureFlagsSelectors } from '@quicken-com/react.flux.config-feature-flags';
import { ClientConfigFlags } from 'utils/clientConfigFlags';
import { DIALOG_TYPE as DIALOG_CONFIRMATION, mkConfirmationDialogProps } from '../Dialogs/ConfirmationDialog';

const styles = (theme) => ({
  headerSpace: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  headerSection: {
    backgroundColor: theme.palette.greyScaleDeprecated[7],
  },
  labelSpace: {
    fontWeight: 500,
    letterSpacing: 2,
  },
  amtTxt: {
    textAlign: 'left',
  },
  datePickerWrapper: {
    '& > div:first-child > div:first-child': {
      display: 'block',
      '& > div:first-child': {
        display: 'block',
      },
    },
  },
  categories: {
    inputField: {
      '& input': {
        '&::-ms-clear ': {
          display: 'none',
        },
      },
    },
  },
  tags: {
    paddingBottom: 4,
  },
  buttonsSection: {
    background: theme.palette.greyScaleDeprecated[7],
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    bottom: 0,
    padding: theme.spacing(2, 0),
    margin: theme.spacing(0, 2.5),
    position: 'sticky',
  },
  formSection: {
    padding: 20,
  },
  fatButton: {
    width: 160,
    height: 36,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.greyScaleDeprecated[7],
    borderRadius: 20,
    marginRight: 10,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  dialogFatButton: {
    width: 116,
    height: 36,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.greyScaleDeprecated[7],
    borderRadius: 20,
    marginRight: 10,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  disabledSave: {
    color: `${theme.palette.greyScaleDeprecated[7]} !important`,
    opacity: 0.5,
  },
  cancelButton: {
    marginLeft: 10,
  },
  underlineOverride: {
    '&:before': {
      borderBottom: `1px solid ${theme.palette.greyScaleDeprecated[5]}`,
    },
    '&&&&:hover:before': {
      borderBottomWidth: '1px',
    },
  },
  inputRight: {
    padding: '0 0 0 12px',
  },
  inputLeft: {
    padding: '0 12px 0 0',
  },
  checkboxIgnore: {
    margin: '10px 0 0',

    '& >div': {
      paddingLeft: 0,
    },
  },
  payeeInput: {
    fontSize: 20,
    fontWeight: 500,
  },
  ellipser: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  defaultOverride: {
    color: theme.palette.greyScaleDeprecated[0],
    cursor: 'default',
    borderBottom: `0.5px solid ${theme.palette.greyScaleDeprecated[5]}`,
  },
  mainSplitTxnsContainer: {
    backgroundColor: theme.palette.greyScaleDeprecated[6],
    padding: 10,
  },
  discardText: {
    color: theme.palette.greyScaleDeprecated[2],
  },
});

const getConsecutiveMap = (max, label) =>
  ImmutableMap([...Array(max).keys()].map((x) => {
    const index = x + 1;
    return [`${index}`, `${index} ${index > 1 ? label[1] || '' : label[0] || ''}`];
  }));

export const weeksIntervalMap = getConsecutiveMap(10, ['week', 'weeks']); // 1 week to 10 weeks
export const monthsIntervalMap = getConsecutiveMap(12, ['month', 'months']);// 1 month to 12 month
export const yearsIntervalMap = getConsecutiveMap(15, ['year', 'years']);// 1 year to 15 years

// Week Days -- Monday, Tuesday...
export const byDayMap = (
  (shortDays, longDays) => ImmutableMap(shortDays.map((short, index) => [short, longDays[index]]))
)(scheduledTransactionsUtils.weekDays, ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']);

// Month days 1 to max
export const getByMonthDaysMap = (max) =>
  ImmutableMap([...Array(max).keys()].map((i) => {
    const n = i + 1;
    return [`${n}`, `${n}${['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th'}`];
  }));

export const frequenciesEditableInterval = [
  scheduledTransactionsUtils.frequencyAliasTypes.dayQuicken,
  scheduledTransactionsUtils.frequencyAliasTypes.weeklyQuicken,
  scheduledTransactionsUtils.frequencyAliasTypes.monthlyQuicken,
  scheduledTransactionsUtils.frequencyAliasTypes.twiceAMonthQuicken,
  scheduledTransactionsUtils.frequencyAliasTypes.yearlyQuicken,
  scheduledTransactionsUtils.frequencyAliasTypes.twiceAYearQuicken,
];

export const hasByMonthDayOrLastDay = (virtualRecurrence) =>
  Object.keys(virtualRecurrence).includes('byMonthDayOrLastDay') && virtualRecurrence.byMonthDayOrLastDay !== null;

class ScheduledTransactionFormQuicken extends QPureComponent {

  constructor(props) {
    super(props);

    const initialScheduledTransaction = this.initialScheduledTransaction(props.scheduledTransaction);
    this.type = initialScheduledTransaction.type;
    this.state = {
      scheduledTransaction: initialScheduledTransaction,
      splitViewOpen: Boolean(!isAcme && initialScheduledTransaction.transaction.split &&
        initialScheduledTransaction.transaction.split.items &&
        initialScheduledTransaction.transaction.split.items.length !== 0),
    };
    this.frequencyAliasItems = (props?.showScheduledTxnDayFrequency && props?.isQWinDataset) 
      ? scheduledTransactionsUtils.frequencyAliases
      : scheduledTransactionsUtils.frequencyAliases.filter(
        (freqAlias) => freqAlias !== scheduledTransactionsUtils.frequencyAliasTypes.dayQuicken
      );
    this.isTypeGrossPaycheck = initialScheduledTransaction?.type === 'GROSS_PAYCHECK';
  }

  initialScheduledTransaction(scheduledTransaction) {
    const schTxn = scheduledTransaction.transaction;
    const frequencyAlias = scheduledTransactionsUtils.frequencyAliasFromRecurrence(scheduledTransaction.recurrence);
    const dueOnDate = DateTime.fromISO(scheduledTransaction.dueOn);
    const endOnDate = scheduledTransaction.recurrence && DateTime.fromISO(scheduledTransaction.recurrence.endOn);
    const virtualEndOn = endOnDate && endOnDate.isValid ? endOnDate.toLocaleString(DateTime.DATE_SHORT) : 'Never';
    const negator = scheduledTransactionsUtils.negatorFromType(scheduledTransaction.type);
    const virtualShowSign = Math.sign(schTxn?.amount || -1) !== negator ? ShowSignEnum.ALWAYS : ShowSignEnum.NEVER;

    const amount = schTxn && !Number.isNaN(schTxn.amount) ? String(negator < 0 ? Math.abs(schTxn.amount) : schTxn.amount) : undefined;
    const immutableTags = schTxn && schTxn.tags ? ImmutableList(schTxn.tags) : undefined;
    const items = schTxn && schTxn.split && schTxn.split.items && schTxn.split.items.map((x) => ({
      ...x,
      amount: String(x.amount),
      tags: ImmutableList(x.tags),
    }));

    

    return {
      type: '',
      ...scheduledTransaction,
      dueOn: dueOnDate && dueOnDate.isValid ? dueOnDate.toLocaleString(DateTime.DATE_SHORT) : '',
      transaction: {
        payee: '',
        accountId: undefined,
        coa: undefined,
        ...schTxn,
        split: {
          items,
        },
        tags: immutableTags,
        amount,
      },
      ...(!isAcme && {
        virtualRecurrence: {
          ...scheduledTransaction.recurrence,
          endOn: virtualEndOn,
        },
      }),
      virtualFrequency: frequencyAlias,
      virtualShowSign,
      virtualEndOn,
      virtualAmount: this.setVirtualAmount(scheduledTransaction, amount),
    };
  }

  setVirtualAmount = (schTxn, amount) => {
    const negator = scheduledTransactionsUtils.negatorFromType(schTxn.type);
    return Math.abs(amount) * negator;
  }

  filterFn = (coa) => {
    const { type } = this;
    if (!type) {
      return true;
    }
    if (type === scheduledTransactionsTypes.STTypeEnum.BILL || type === scheduledTransactionsTypes.STTypeEnum.EXPENSE) {
      return categoriesSelectors.isExpenseCat(null, coa.id) || coa.type === 'BALANCE_ADJUSTMENT' || coa.type === 'UNCATEGORIZED';
    }
    if (type === scheduledTransactionsTypes.STTypeEnum.SUBSCRIPTION) {
      return categoriesSelectors.isExpenseCat(null, coa.id) || coa.type === 'UNCATEGORIZED';
    }
    if (type === scheduledTransactionsTypes.STTypeEnum.INCOME) {
      return categoriesSelectors.isIncomeCat(null, coa.id) || coa.type === 'UNCATEGORIZED';
    }
    if (type === scheduledTransactionsTypes.STTypeEnum.TRANSFER) {
      return coa.type === 'ACCOUNT' || coa.type === 'BALANCE_ADJUSTMENT';
    }
    return true;
  };

  deleteSchTxn = (clientId, id, payee) => () => {
    this.props.makeDialog({
      id: 'delete-schedule-transaction-confirmation-details',
      type: DIALOG_CONFIRMATION,
      props: ImmutableMap(mkConfirmationDialogProps({
        title: 'Delete Schedule Transaction',
        content: `Are you sure you want to delete the schedule transaction for "${payee || ''}"?`,
        confirmLabel: 'Delete Schedule Transaction',
        confirmAction: (_reason, _closeEvent) => {
          this.props.dispatchDeleteScheduledTransactionAction(scheduledTransactionsTypes.mkScheduledTransaction({
            clientId,
            id,
            isDeleted: true,
          }));

          if (this.props.onClose) {
            this.props.onClose();
          }
        },
        denyLabel: 'Go Back',
      })),
    });
  };

  renderVirtualRecurrenceFields(virtualFrequency, virtualRecurrence, dueOnValue, formikProps, setNewVirtualRecurrence) {
    const { classes } = this.props;
    const dueOnMoment = (dueOnValue && moment(dueOnValue)) || moment();
    const daysInMonth = dueOnMoment && dueOnMoment.isValid() ? dueOnMoment.daysInMonth() : 31;
    const sortItems = (a, b) => (parseInt(a, 10) - parseInt(b, 10));
    const onSelected = (name, value) => formikProps.setFieldValue(name, value);
    const parseDate = (dateStr, year) => (dateStr && dateStr.length === 5 ? `${dateStr}/${year}` : dateStr);

    const onWeeklyDateChange = (name, value) => {
      // check newDate is not in the past
      let newDueOnDate = moment(dueOnMoment).day(value);
      newDueOnDate = newDueOnDate.isSameOrAfter(dueOnMoment) ? newDueOnDate : newDueOnDate.day(7);
      onSelected(name, value);
      onSelected('dueOn', newDueOnDate.format('l'));
    };

    const onMonthlyDateChange = (name, value) => {
      onSelected(name, value);
      onSelected('dueOn', dueOnMoment.date(parseInt(value, 10)).format('l'));
    };

    const onTwiceAMonthDateChange = (changeIndex) => (name, value) => {
      const hasByMonthDay = !hasByMonthDayOrLastDay(formikProps.values.virtualRecurrence);
      const byMonthValue = !hasByMonthDay ? virtualRecurrence.byMonthDayOrLastDay[0] : virtualRecurrence.byMonthDay[0];
      const firstDay = changeIndex === 0 ? value : byMonthValue;
      const firstDate = moment(dueOnMoment).date(firstDay);
      let newValue = value;

      if (hasByMonthDay && value >= 28) {
        const endOfMonth = moment(formikProps.values.dueOn).endOf('month').date();
        newValue = -(endOfMonth - newValue);
      }

      onSelected(name, newValue);
      onSelected('dueOn', firstDate.format('l'));
    };

    const onTwiceAYearDateChange = (changeIndex) => (name, date) => {
      const dates = [...virtualRecurrence.byDates];
      if (changeIndex === 1) {
        dates[0] = dueOnMoment;
        dates[1] = date;

        if (dates[0].isAfter(dates[1], 'day') && dates[0].year() === dates[1].year()) {
          dates.reverse();
        }

        onSelected('dueOn', dates[0].format('l'));
        onSelected('virtualRecurrence.byDates[0]', dates[0].format('MM/DD'));
        onSelected('virtualRecurrence.byDates[1]', dates[1].format('MM/DD'));
      } else if (date.format('MM/DD') !== dates[0]) {
        setNewVirtualRecurrence({ dueOnValue: date, overwriteIntervalFlag: true });
        onSelected(name, date.format('MM/DD'));
      }
    };

    const renderInterval = (itemMap, id, label = 'Every') => this.renderRecurrenceDownshiftField({
      sortItems,
      itemMap,
      id,
      label,
      name: 'virtualRecurrence.interval',
      onSelected: (name, interval) => onSelected(name, parseInt(interval, 10)),
      isTypeGrossPaycheck: this.isTypeGrossPaycheck,
    });

    let singleColumn = null;
    let leftColumn = null;
    let rightColumn = null;

    switch (virtualFrequency) {
      case scheduledTransactionsUtils.frequencyAliasTypes.dayQuicken:
        leftColumn = (
          <Field
            name="virtualRecurrence.interval"
            margin="dense"
            validate={(value) => {
              if (!value || value?.length <= 0) return 'required';
              const onlyNums = +value;
              if (Number.isNaN(onlyNums) || onlyNums < 1 || onlyNums > 365) {
                return 'Please enter numeric value between 1 and 365';
              }
              return null;
            }}
            render={({ field: fieldData, form: { _values, touched, errors } }) => {
              const field = { ...fieldData };
              return <TextField
                {...field}
                id="intervalDay"
                label="Every"
                InputProps={{
                  disabled: this.isTypeGrossPaycheck,
                  classes: {
                    underline: classes.underlineOverride,
                  },
                  autoComplete: 'off',
                  endAdornment: (
                    <InputAdornment position="end">
                      day{field?.value > 1 && 's'}
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  formikProps.setFieldValue(field.name, e.target.value);
                }}
                error={Boolean(_.get(touched, field.name) && _.get(errors, field.name))}
                helperText={_.get(touched, field.name) && _.get(errors, field.name)}
              />;
            }}
          />
        );
        break;
      case scheduledTransactionsUtils.frequencyAliasTypes.weeklyQuicken:
        leftColumn = renderInterval(weeksIntervalMap, 'interval-weekly');
        rightColumn = this.renderRecurrenceDownshiftField({
          name: 'virtualRecurrence.byDay[0]',
          onSelected: onWeeklyDateChange,
          itemMap: byDayMap,
          id: 'byDay-weekly',
          label: 'Day',
          isTypeGrossPaycheck: this.isTypeGrossPaycheck,
        });
        break;
      case scheduledTransactionsUtils.frequencyAliasTypes.every2WeeksQuicken:
        singleColumn = this.renderRecurrenceDownshiftField({
          name: 'virtualRecurrence.byDay[0]',
          onSelected: onWeeklyDateChange,
          itemMap: byDayMap,
          id: 'byDay-biWeekly',
          label: 'Every 2 weeks on',
          isTypeGrossPaycheck: this.isTypeGrossPaycheck,
        });
        break;
      case scheduledTransactionsUtils.frequencyAliasTypes.twiceAMonthQuicken: {
        const fixValue = (value, virtualRecurrenceObj) => {
          if (value <= 0 && !hasByMonthDayOrLastDay(virtualRecurrenceObj)) {
            const endOfMonth = moment(formikProps.values.dueOn).endOf('month').date();
            return value + endOfMonth;
          }
          return value;
        };
        singleColumn = renderInterval(monthsIntervalMap, 'interval-twice-month');
        leftColumn = this.renderRecurrenceDownshiftField({
          name: hasByMonthDayOrLastDay(formikProps.values.virtualRecurrence) ? 'virtualRecurrence.byMonthDayOrLastDay[0]' : 'virtualRecurrence.byMonthDay[0]',
          onSelected: onTwiceAMonthDateChange(0),
          sortItems,
          itemMap: getByMonthDaysMap(daysInMonth),
          id: 'byMonthDay-twice-month-first',
          fixValue,
          label: 'First on',
          isTypeGrossPaycheck: this.isTypeGrossPaycheck,
        });
        rightColumn = this.renderRecurrenceDownshiftField({
          name: hasByMonthDayOrLastDay(formikProps.values.virtualRecurrence) ? 'virtualRecurrence.byMonthDayOrLastDay[1]' : 'virtualRecurrence.byMonthDay[1]',
          onSelected: onTwiceAMonthDateChange(1),
          sortItems,
          itemMap: getByMonthDaysMap(daysInMonth),
          id: 'byMonthDay-twice-month-second',
          fixValue,
          label: 'Second on',
          isTypeGrossPaycheck: this.isTypeGrossPaycheck,
        });
      }
        break;
      case scheduledTransactionsUtils.frequencyAliasTypes.everyFourWeeksQuicken:
        singleColumn = this.renderRecurrenceDownshiftField({
          name: 'virtualRecurrence.byDay[0]',
          onSelected: onWeeklyDateChange,
          itemMap: byDayMap,
          id: 'byDay-four-Weekly',
          label: 'Every 4 weeks on',
          isTypeGrossPaycheck: this.isTypeGrossPaycheck,
        });
        break;
      case scheduledTransactionsUtils.frequencyAliasTypes.monthlyQuicken:
        leftColumn = renderInterval(monthsIntervalMap, 'interval-monthly');
        rightColumn = this.renderRecurrenceDownshiftField({
          name: hasByMonthDayOrLastDay(formikProps.values.virtualRecurrence) ? 'virtualRecurrence.byMonthDayOrLastDay[0]' : 'virtualRecurrence.byMonthDay[0]',
          onSelected: onMonthlyDateChange,
          sortItems,
          itemMap: getByMonthDaysMap(daysInMonth),
          id: 'byMonthDay-monthly',
          label: 'Day',
          isTypeGrossPaycheck: this.isTypeGrossPaycheck,
        });
        break;
      case scheduledTransactionsUtils.frequencyAliasTypes.quarterlyQuicken:
        singleColumn = this.renderRecurrenceTextField({
          name: 'virtualOnDates',
          id: 'onDates-quarterly',
          label: 'On Dates',
          readOnly: true,
          fixValue: () => virtualRecurrence && virtualRecurrence.byDates && virtualRecurrence.byDates.join(', '),
          isTypeGrossPaycheck: this.isTypeGrossPaycheck,
        });
        break;
      case scheduledTransactionsUtils.frequencyAliasTypes.twiceAYearQuicken: {
        const transactionYear = (dueOnMoment && dueOnMoment.year()) || moment().year();

        const fixValue = (position) => (value) => {
          const dates = [...virtualRecurrence.byDates].map((date) => moment(date, 'MM/DD').month() + 1);
          const increaseYear = position === 1 && (dates[0] >= 6) && (dates[1] <= 6); // When is second date, and first date is in second half of the year
          return value && parseDate(value, increaseYear ? transactionYear + 1 : transactionYear);
        };

        singleColumn = renderInterval(yearsIntervalMap, 'interval-twice-year');
        leftColumn = this.renderRecuerrenceDateField({
          name: 'virtualRecurrence.byDates[0]',
          onChange: onTwiceAYearDateChange(0),
          id: 'byDates-twice-year-first',
          label: 'First on',
          fixValue: fixValue(0),
          minDate: moment().toDate(),
          isTypeGrossPaycheck: this.isTypeGrossPaycheck,
        });
        rightColumn = this.renderRecuerrenceDateField({
          name: 'virtualRecurrence.byDates[1]',
          onChange: onTwiceAYearDateChange(1),
          id: 'byDates-twice-year-second',
          label: 'Second on',
          fixValue: fixValue(1),
          minDate: moment().toDate(),
          isTypeGrossPaycheck: this.isTypeGrossPaycheck,
        });
      }
        break;
      case scheduledTransactionsUtils.frequencyAliasTypes.toPayEstimatedTaxesQuicken:
        singleColumn = this.renderRecurrenceTextField({
          name: 'virtualOnDates',
          id: 'onDates-tax-pay',
          label: 'On Dates',
          readOnly: true,
          fixValue: () => virtualRecurrence && virtualRecurrence.byDates && virtualRecurrence.byDates.join(', '),
          isTypeGrossPaycheck: this.isTypeGrossPaycheck,
        });
        break;
      case scheduledTransactionsUtils.frequencyAliasTypes.yearlyQuicken:
        singleColumn = renderInterval(yearsIntervalMap, 'interval-yearly');
        break;
      default: break;
    }

    const leftColumnClass = classNames({ [classes.inputLeft]: Boolean(rightColumn) });

    return (
      <>
        {singleColumn && (
          <Grid container>
            {singleColumn && <Grid item xs>{singleColumn}</Grid>}
          </Grid>
        )}
        {(leftColumn || rightColumn) && (
          <Grid container>
            {leftColumn && <Grid item xs={6} md className={leftColumnClass}>{leftColumn}</Grid>}
            {(leftColumn && rightColumn) && <Grid item xs={6} md lg className={classes.inputRight}>{rightColumn}</Grid>}
          </Grid>
        )}
      </>
    );
  }

  renderRecurrenceDownshiftField(args) {
    const { classes } = this.props;
    let items = args.itemMap;
    if (args.sortItems) {
      items = items.sort(args.sortItems);
    }
    return (
      <Field
        name={args.name}
        validate={(value) => `${value}` && `${value}`.length > 0 ? undefined : 'required'}
        render={({ field: fieldData, form: { values, touched, errors } }) => {
          const field = { ...fieldData };

          if (args.fixValue) {
            field.value = args.fixValue(field.value, values.virtualRecurrence);
          }

          return <DownshiftField
            key={`${args.id}-${_.get(values, field.name)}`}
            initialItemSelected={`${field.value}`}
            onSelected={(day) => args.onSelected(field.name, day)}
            items={[...items.keys()]}
            itemToString={(item) => items.get(item)}
            textFieldProps={{
              InputProps: {
                ...field,
                classes: { underline: classes.underlineOverride },
                disabled: args?.isTypeGrossPaycheck,
              },
              id: args.id,
              label: args.label,
              margin: 'normal',
              fullWidth: true,
              autoComplete: 'off',
              error: Boolean(_.get(touched, field.name) && _.get(errors, field.name)),
              helperText: _.get(touched, field.name) && _.get(errors, field.name),
            }}
            lightWeight
          />;
        }}
      />
    );
  }

  renderRecuerrenceDateField(args) {
    const { classes } = this.props;
    return (
      <Field
        name={args.name}
        validate={(value) => {
          let errorMessage;
          if (!value || !value.length) {
            errorMessage = 'required';
          } else if (!moment(value, 'MM/DD').isValid()) {
            errorMessage = 'invalid';
          }
          return errorMessage;
        }}
        render={({ field: fieldData, form: { _values, touched, errors } }) => {
          const field = { ...fieldData };
          if (args.fixValue) {
            field.value = args.fixValue(field.value);
          }
          const date = moment(field.value);
          const selected = date.isValid() ? date : moment();
          return (
            <div className={classes.datePickerWrapper}>
              <ReactDatePicker
                disabled={args?.isTypeGrossPaycheck}
                id={args.id}
                selected={selected.toDate()}
                onChange={(jsDate, e) => (args.onChange(field.name, (e.key ? date : moment(jsDate))))}
                onClickOutside={() => (args.onChange(field.name, date))}
                onKeyDown={(e) => {
                  if (e.key === 'Escape') {
                    e.stopPropagation();
                  }
                }}
                minDate={args.minDate}
                maxDate={args.maxDate}
                popperPlacement={
                  args.id === 'byDates-twice-year-first' ? 'bottom-start' : 'bottom-end'
                }
                allowSameDay
                disabledKeyboardNavigation
                customInputRef="inputRef"
                customInput={
                  <TextField
                    InputProps={{
                      ...field,
                      classes: { underline: classes.underlineOverride },
                      autoComplete: 'off',
                    }}
                    InputLabelProps={{
                      classes: { root: classes.ellipser },
                    }}
                    label={args.label}
                    margin="dense"
                    fullWidth
                    error={Boolean(_.get(touched, field.name) && _.get(errors, field.name))}
                    helperText={_.get(touched, field.name) && _.get(errors, field.name)}
                  />
                }
              />
            </div>
          );
        }}
      />
    );
  }

  renderRecurrenceTextField(args) {
    const { classes } = this.props;

    return (
      <Field
        name={args.name}
        render={({ field: fieldData }) => {
          const field = { ...fieldData };
          if (args.fixValue) {
            field.value = args.fixValue(field.value);
          }
          return <TextField
            {...field}
            id={args.id}
            label={args.label}
            InputProps={{
              classes: {
                underline: classes.underlineOverride,
              },
              readOnly: args.readOnly,
              autoComplete: 'off',
            }}
            margin="dense"
            fullWidth
            disabled={args.isTypeGrossPaycheck}
          />;
        }}
      />
    );
  }

  renderAmountField(name, formikProps) {
    const { classes } = this.props;
    const isSplit = name.includes('split');

    return <Field
      name={name}
      validate={(value) => value === undefined || value === null || value === '' ? 'required' : undefined}
      render={({ field, form: { touched, errors, values } }) => (
        <AmountField
          {...field}
          onChange={(e) => {
            formikProps.setFieldValue('virtualAmount', this.setVirtualAmount(formikProps.values, e.target.value));
            field.onChange(e);
          }}
          showSign={values.virtualShowSign}
          showAmountAdornment
          editable
          omitCents={false}
          currencySymbol="USD"
          amountType="amount"
          id="schedtxn-amount"
          label={isSplit ? 'Amount' : 'Recurring Amount'}
          marginProp="normal"
          fullWidth
          autoComplete="off"
          error={Boolean(_.get(touched, field.name) && _.get(errors, field.name))}
          helperText={_.get(touched, field.name) && _.get(errors, field.name)}
          classes={{ inputField: classNames(classes.amtTxt) }}
          InputProps={{
            classes: { underline: classes.underlineOverride },
          }}
          disabled={this.isTypeGrossPaycheck}
        />
      )}
    />;
  }

  renderCatField(name, formikProps) {
    const { classes } = this.props;

    return <Field
      name={name}
      validate={(coa) => {
        let error;
        switch (formikProps.values.type) {
          case scheduledTransactionsTypes.STTypeEnum.INCOME:
            if (!coa || (coa.type !== chartOfAccountsTypes.CoaTypeEnum.BALANCE_ADJUSTMENT && coa.type !== chartOfAccountsTypes.CoaTypeEnum.CATEGORY) ||
          (coa.type === chartOfAccountsTypes.CoaTypeEnum.CATEGORY && !categoriesSelectors.isIncomeCat(null, coa.id))) {
              error = 'valid income category required';
            }
            break;
          case scheduledTransactionsTypes.STTypeEnum.TRANSFER:
            if (!coa || (coa.type !== chartOfAccountsTypes.CoaTypeEnum.ACCOUNT && coa.type !== chartOfAccountsTypes.CoaTypeEnum.BALANCE_ADJUSTMENT)) {
              error = 'valid account required';
            }
            break;
          case scheduledTransactionsTypes.STTypeEnum.BILL:
          case scheduledTransactionsTypes.STTypeEnum.EXPENSE:
            if (!coa || (coa.type !== chartOfAccountsTypes.CoaTypeEnum.CATEGORY && coa.type !== chartOfAccountsTypes.CoaTypeEnum.BALANCE_ADJUSTMENT && coa.type !== chartOfAccountsTypes.CoaTypeEnum.UNCATEGORIZED)) {
              error = 'valid bill category required';
            }
            break;
          case scheduledTransactionsTypes.STTypeEnum.SUBSCRIPTION:
            if (!categoriesSelectors.isExpenseCat(null, coa.id) && coa.type !== chartOfAccountsTypes.CoaTypeEnum.UNCATEGORIZED) {
              error = 'valid subscription category required';
            }
            break;
          default:
        }
        return error;
      }}
      render={({ field, form: { touched, errors } }) =>
        <CategoryField
          {...field}
          onChange={(coa) => formikProps.setFieldValue(field.name, coa)}
          error={Boolean(_.get(touched, field.name) && _.get(errors, field.name))}
          label={formikProps.values.type === scheduledTransactionsTypes.STTypeEnum.TRANSFER ? 'Account' : 'Category'}
          editable
          createEnabled
          longCats={false}
          margin="dense"
          autoFocus={false}
          fontSize="16px"
          inputStyle={classes.categories}
          filterFn={this.filterFn}
          InputProps={{
            classes: { underline: classes.underlineOverride },
            id: 'transaction-input',
          }}
          textFieldProps={{
            helperText: _.get(touched, field.name) && _.get(errors, field.name),
          }}
          transferOnly={formikProps.values.type === scheduledTransactionsTypes.STTypeEnum.TRANSFER}
          stType={formikProps.values.type}
        />}
    />;
  }

  renderTagField(name, formikProps) {
    const { classes } = this.props;

    return <Field
      name={name}
      render={({ field, form: { touched, errors } }) =>
        <TagsField
          {...field}
          onChange={(tags) => formikProps.setFieldValue(field.name, tags)}
          error={Boolean(_.get(touched, field.name) && _.get(errors, field.name))}
          helperText={_.get(touched, field.name) && _.get(errors, field.name)}
          label="Tags"
          placeholder=" "
          margin="dense"
          id="transaction-tags"
          fullWidth
          InputProps={{
            classes: {
              underline: classes.underlineOverride,
              input: classes.tags,
            },
          }}
        />}
    />;
  }

  render() {
    super.render();
    const { classes, label, isConfirmationDialogOpen, isFormDirty, setDirtyForm, discardCb, onSubmit,
      dispatchCreateScheduledTransactionAction, dispatchUpdateScheduledTransactionAction,
      onClose, showConfirmationDialog, showCloseButton, changeDetailsMode, deleteUserVerificationFromPayload, hideDeleteIcon } = this.props;

    const { scheduledTransaction, splitViewOpen } = this.state;

    return (
      <>
        <Formik
          initialValues={scheduledTransaction}
          validateOnChange
          onSubmit={(values, actions) => {
            const dueOnMoment = values.dueOn && moment(values.dueOn);
            const dueOn = dueOnMoment && dueOnMoment.isValid() ? dueOnMoment.format('YYYY-MM-DD') : undefined;
            const endOnMoment = values.virtualEndOn && moment(values.virtualEndOn);
            const endOn = endOnMoment && endOnMoment.isValid() ? endOnMoment.format('YYYY-MM-DD') : '';
            const negator = scheduledTransactionsUtils.negatorFromType(values.type);
            const amount = (values.transaction.amount.includes('+') || values.transaction.amount.includes('-')) ? Number(values.transaction.amount) : Number(values.transaction.amount) * negator;
            const recurrence = values.virtualFrequency === scheduledTransactionsUtils.frequencyAliasTypes.custom ?
              { ...scheduledTransaction.recurrence, endOn } :
              { ...values.virtualRecurrence, endOn };

            // QWIN needs clientId for split Items
            if (transactionsUtils.isSplitTxn(values.transaction)) {
              // eslint-disable-next-line no-param-reassign
              values.transaction.split.items = values.transaction.split?.items?.filter((splitItem) => 
                splitItem.coa.id !== '0' && Number(splitItem.amount) !== 0).map((spliItem) => ({
                ...spliItem,
                ...(!spliItem.id && !spliItem.clientId ? { clientId: uuidv4().toUpperCase() } : {}),
              }));
            }

            const split = (values.transaction.split && values.transaction.split.items) ? transactionsTransformers.mkSplit(values.transaction.split) : ImmutableMap({ items: [] });
            const txns = new transactionsTypes.CashFlowTransaction({ ...values.transaction, split, amount });

            const data = scheduledTransactionsTypes.mkScheduledTransaction({
              ...(deleteUserVerificationFromPayload && values.id ?
                _.omit(values, ['isUserVerified']) : { ...values, isUserVerified: true }
              ),
              dueOn,
              ...(!isAcme && { overrideNextDueOn: dueOn, overrideNextAmount: amount }),
              recurrence: scheduledTransactionsTypes.mkRecurrence(recurrence),
              transaction: updateNewTags(txns, true),
            });
            if (values.id) {
              dispatchUpdateScheduledTransactionAction(data);
            } else {
              dispatchCreateScheduledTransactionAction(data);
            }

            actions.setSubmitting(false);
            if (changeDetailsMode) {
              changeDetailsMode();
            } else {
              onSubmit && onSubmit(data);
              onClose();
            }
            setDirtyForm && setDirtyForm(false);
          }}
          render={(formikProps) => {
            setDirtyForm && setDirtyForm(formikProps.dirty);
            const { values: { dueOn, virtualFrequency, virtualRecurrence } } = formikProps;

            const setNewVirtualRecurrence = ({ dueOnValue, overrideVirtualFreq, overwriteIntervalFlag = false }) => {
              if (isAcme) {
                return;
              }

              const newValues = overrideVirtualFreq || { virtualFrequency };
              const { virtualRecurrence: { interval: initialInterval } } = formikProps.initialValues;
              let overwriteInterval = (overwriteIntervalFlag && initialInterval !== virtualRecurrence.interval
                && frequenciesEditableInterval.includes(newValues?.virtualFrequency)) ? virtualRecurrence.interval : undefined;
              if (initialInterval && !overwriteInterval) {
                overwriteInterval = initialInterval;
              }
              let newRecurrence = scheduledTransactionsUtils.recurrenceFromFrequencyAlias(newValues.virtualFrequency, dueOnValue.format('YYYY-MM-DD'), overwriteInterval);

              if (newValues.virtualFrequency === scheduledTransactionsUtils.frequencyAliasTypes.twiceAYearQuicken && (dueOnValue.month() + 1) >= 6) {
                newRecurrence = {
                  ...newRecurrence,
                  byDates: newRecurrence.byDates.reverse(),
                };
              }

              newValues.virtualRecurrence = {
                ...newRecurrence,
              };

              formikProps.setValues({
                ...formikProps.values,
                ...newValues,
                virtualEndOn: newRecurrence?.endOn ?? formikProps.values.virtualEndOn,
                dueOn: dueOnValue.format('l'),
              });
            };

            return (
              <Form
                onKeyDown={(e) => {
                  if (e.key === 'Escape') {
                    if (showConfirmationDialog) {
                      showConfirmationDialog(false);
                      if (onClose) {
                        onClose();
                      }
                    }
                    if (formikProps.dirty) {
                      showConfirmationDialog(true);
                    } else if (onClose) {
                      onClose();
                    }
                  }
                }}
              >
                <section className={classNames(classes.formSection, classes.headerSection)}>
                  <div className={classes.headerSpace}>
                    {label && <Typography variant="overline" className={classes.labelSpace}>{label}</Typography>}
                    {showCloseButton &&
                      <IconButton
                        display="inline"
                        aria-label="close series properties"
                        onClick={() => {
                          if (formikProps.dirty) {
                            showConfirmationDialog(true);
                          } else if (onClose) {
                            onClose();
                          }
                        }}
                        style={{ padding: 0 }}
                        size="large"
                      >
                        <CloseIcon />
                      </IconButton>}
                  </div>

                  <Field
                    name="transaction.payee"
                    validate={(value) => value && value.length > 0 ? undefined : 'required'}
                    render={({ field, form: { touched, errors } }) =>
                      <TextField
                        {...field}
                        id="payee"
                        label="Name"
                        placeholder="Name"
                        error={Boolean(_.get(touched, field.name) && _.get(errors, field.name))}
                        helperText={_.get(touched, field.name) && _.get(errors, field.name)}
                        InputProps={{
                          classes: {
                            root: classes.payeeInput,
                            underline: classes.underlineOverride,
                          },
                          autoComplete: 'off',
                        }}
                        margin="dense"
                        fullWidth
                      />}
                  />

                  <Grid container>
                    <Grid item xs={6} md lg className={classes.inputLeft}>
                      {this.renderAmountField('transaction.amount', formikProps)}
                    </Grid>

                    <Grid item xs={6} md lg className={classes.inputRight}>
                      <Field
                        name="virtualFrequency"
                        validate={(value) => value && value.length > 0 ? undefined : 'required'}
                        render={({ field: { onChange, ...field }, form: { values, touched, errors } }) =>
                          <DownshiftField
                            key={_.get(values, field.name)}
                            initialItemSelected={_.get(values, field.name)}
                            onSelected={(frequencyAlias) => {
                              let dueOnValue = values.dueOn && moment(values.dueOn);
                              dueOnValue = dueOnValue && dueOnValue.isValid() ? dueOnValue : moment();

                              formikProps.setFieldValue(field.name, frequencyAlias);
                              setNewVirtualRecurrence({ dueOnValue, overrideVirtualFreq: { [field.name]: frequencyAlias } });

                              if (frequencyAlias === scheduledTransactionsUtils.frequencyAliasTypes.custom) {
                                // TODO: impelment custom
                              }
                            }}
                            items={this.frequencyAliasItems}
                            textFieldProps={{
                              InputProps: {
                                ...field,
                                classes: { underline: classes.underlineOverride },
                                disabled: this.isTypeGrossPaycheck,
                              },
                              id: 'frequency',
                              label: 'Frequency',
                              margin: 'normal',
                              fullWidth: true,
                              autoComplete: 'off',
                              error: Boolean(_.get(touched, field.name) && _.get(errors, field.name)),
                              helperText: _.get(touched, field.name) && _.get(errors, field.name),
                            }}
                            lightWeight
                          />}
                      />
                    </Grid>
                  </Grid>

                  {!isAcme && virtualRecurrence &&
                    this.renderVirtualRecurrenceFields(virtualFrequency, virtualRecurrence, dueOn, formikProps, setNewVirtualRecurrence)}

                </section>

                <section className={classes.formSection}>
                  <Field
                    name="transaction.accountId"
                    validate={(value) => value && value.length > 0 ? undefined : 'required'}
                    render={({ field: { onChange, ...field }, form: { values, touched, errors } }) =>
                      <AccountField
                        key={_.get(values, field.name)}
                        onSelected={(account) => formikProps.setFieldValue(field.name, account ? account.id : undefined)}
                        initialAccountId={_.get(values, field.name)}
                        id="account"
                        label={formikProps.values.type === scheduledTransactionsTypes.STTypeEnum.INCOME
                          ? 'Deposit to'
                          : 'Pay from'}
                        margin="normal"
                        autoComplete="off"
                        fullWidth
                        error={Boolean(_.get(touched, field.name) && _.get(errors, field.name))}
                        helperText={_.get(touched, field.name) && _.get(errors, field.name)}
                        InputProps={{
                          ...field,
                          classes: { underline: classes.underlineOverride },
                          disabled: this.isTypeGrossPaycheck,
                        }}
                      />}
                  />

                  <Field
                    name="type"
                    validate={(value) => value && value.length > 0 ? undefined : 'required'}
                    render={({ field: { onChange, ...field }, form: { values, touched, errors } }) =>
                      <DownshiftField
                        key={_.get(values, field.name)}
                        initialItemSelected={field.value}
                        onSelected={(type) => {
                          formikProps.setFieldValue(field.name, type);
                          this.type = type;
                          if (touched && touched.transaction && !touched.transaction.isExcludedFromReports) {
                            formikProps.setFieldValue('transaction.isExcludedFromReports', type === scheduledTransactionsTypes.STTypeEnum.TRANSFER);
                          }

                          switch (type) {
                            case scheduledTransactionsTypes.STTypeEnum.INCOME:
                              if (this.props.personalIncomeCategory) {
                                formikProps.setFieldValue('transaction.coa', { id: this.props.personalIncomeCategory.id, type: chartOfAccountsTypes.CoaTypeEnum.CATEGORY }); // personal income
                              } else {
                                formikProps.setFieldValue('transaction.coa', { id: '0', type: chartOfAccountsTypes.CoaTypeEnum.UNCATEGORIZED }); // Uncategorized
                              }
                              break;
                            case scheduledTransactionsTypes.STTypeEnum.TRANSFER:
                              formikProps.setFieldValue('transaction.coa', { id: '2', type: chartOfAccountsTypes.CoaTypeEnum.BALANCE_ADJUSTMENT }); // Transfer
                              break;
                            case scheduledTransactionsTypes.STTypeEnum.BILL:
                            case scheduledTransactionsTypes.STTypeEnum.SUBSCRIPTION:
                            case scheduledTransactionsTypes.STTypeEnum.EXPENSE:
                            default:
                              formikProps.setFieldValue('transaction.coa', { id: '0', type: chartOfAccountsTypes.CoaTypeEnum.UNCATEGORIZED }); // Uncategorized
                              break;
                          }
                        }}
                        items={scheduledTransactionsUtils.stTypes}
                        itemToString={(item) => scheduledTransactionsUtils.STTypeNamesEnum[item] || item}
                        textFieldProps={{
                          InputProps: {
                            ...field,
                            classes: { underline: classes.underlineOverride },
                            disabled: this.isTypeGrossPaycheck,
                          },
                          id: 'type',
                          label: 'Type',
                          margin: 'normal',
                          autoComplete: 'off',
                          fullWidth: true,
                          error: Boolean(_.get(touched, field.name) && _.get(errors, field.name)),
                          helperText: _.get(touched, field.name) && _.get(errors, field.name),
                        }}
                        lightWeight
                      />}
                  />

                  <Grid
                    container
                    alignItems="center"
                  >
                    <Grid item xs={6} md lg className={classNames({ [classes.inputLeft]: !isAcme && !splitViewOpen })}>
                      {(splitViewOpen || (formikProps.values.transaction.split.items &&
                        formikProps.values.transaction.split.items.length !== 0)) ? (
                          <TextField
                            label={formikProps.values.type === scheduledTransactionsTypes.STTypeEnum.TRANSFER ? 'Account' : 'Category'}
                            value="--- Split ---"
                            margin="dense"
                            fullWidth
                            InputProps={{
                              readOnly: true,
                              disabled: true,
                              classes: {
                                underline: classes.underlineOverride,
                                disabled: classes.defaultOverride,
                              },
                            }}
                          />
                        ) : this.renderCatField('transaction.coa', formikProps)}
                    </Grid>
                    {!isAcme && !splitViewOpen &&
                      <Grid item md className={classes.inputRight}>
                        <div>
                          <FormControlLabel
                            label={<><MUISplitIcon />Split</>}
                            labelPlacement="start"
                            control={<Switch
                              color="primary"
                              checked={splitViewOpen}
                              value={splitViewOpen}
                              id="split-switch"
                              onChange={() => {
                                formikProps.setFieldValue('transaction.split.items', []);
                                this.setState({ splitViewOpen: !splitViewOpen });
                              }}
                            />}
                          />
                        </div>
                      </Grid>}
                  </Grid>

                  {!isAcme && splitViewOpen &&
                    <div className={classes.mainSplitTxnsContainer}>
                      <SplitsAndDetailsPanel
                        txn={new transactionsTypes.CashFlowTransaction({ ...formikProps.values.transaction, amount: formikProps.values.virtualAmount })}
                        closeFn={() => this.setState({ splitViewOpen: !splitViewOpen })}
                        splitSwitch={() => this.setState({ splitViewOpen: !splitViewOpen })}
                        onAmountChange={(amount) => {
                          formikProps.setFieldValue('transaction.amount', Math.abs(amount));
                          formikProps.setFieldValue('virtualAmount', this.setVirtualAmount(formikProps.values, amount));
                        }}
                        saveFn={(txnform) => {
                          formikProps.setFieldValue('transaction.split.items', txnform.split.items);
                          formikProps.setFieldValue('transaction.coa', txnform.coa);
                        }}
                        onChangeTxnState={({ split }) => formikProps.setFieldValue('transaction.split.items', split.items)}
                        hideFooter
                        showCurrencySymbol
                        showDetails={false}
                        showSplits={splitViewOpen}
                        forceAutoSave
                        editable={!this.isTypeGrossPaycheck}
                      />
                    </div>}
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      md
                      lg
                      className={classNames({
                        [classes.inputLeft]: virtualFrequency !== scheduledTransactionsUtils.frequencyAliasTypes.onlyOnce,
                      })}
                    >
                      <Field
                        name="dueOn"
                        validate={(value) => {
                          let errorMessage;
                          if (!value || !value.length) {
                            errorMessage = 'required';
                          } else if (!moment(value).isValid()) {
                            errorMessage = 'invalid';
                          }
                          return errorMessage;
                        }}
                        render={({ field, form: { values, touched, errors } }) => {
                          const date = moment(field.value);
                          const selected = date.isValid() ? date : moment();
                          return (
                            <div className={classes.datePickerWrapper}>
                              <ReactDatePicker
                                id="next-date"
                                selected={selected.toDate()}
                                popperPlacement={!changeDetailsMode ? 'top-start' : 'bottom'}
                                onChange={(jsDate, e) => {
                                  const dueOnValue = (e.key ? date : moment(jsDate));

                                  setNewVirtualRecurrence({ dueOnValue, overwriteIntervalFlag: true });
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Escape') {
                                    e.stopPropagation();
                                  }
                                }}
                                onClickOutside={() => {
                                  const dueOnValue = moment(dueOn, 'l');

                                  setNewVirtualRecurrence({ dueOnValue, overwriteIntervalFlag: true });
                                }}
                                minDate={moment().toDate()}
                                maxDate={moment(values.virtualEndOn).toDate()}
                                // dateFormat="MM/DD/YYYY"
                                allowSameDay
                                disabledKeyboardNavigation
                                customInputRef="inputRef"
                                customInput={
                                  <TextField
                                    InputProps={{
                                      ...field,
                                      classes: { underline: classes.underlineOverride },
                                      autoComplete: 'off',
                                    }}
                                    InputLabelProps={{
                                      classes: { root: classes.ellipser },
                                    }}
                                    label="Recurrence Date"
                                    margin="dense"
                                    fullWidth
                                    error={Boolean(_.get(touched, field.name) && _.get(errors, field.name))}
                                    helperText={_.get(touched, field.name) && _.get(errors, field.name)}
                                  />
                                }
                              />
                            </div>
                          );
                        }}
                      />
                    </Grid>
                    {virtualFrequency !== scheduledTransactionsUtils.frequencyAliasTypes.onlyOnce &&
                      <Grid item xs={6} md lg className={classes.inputRight}>
                        <Field
                          name="virtualEndOn"
                          validate={(value) => value === 'Never' || value === '' || moment(value).isValid() ? undefined : 'invalid'}
                          render={({ field, form: { values, touched, errors } }) => {
                            const date = moment(field.value);
                            const selected = date.isValid() ? date : moment();
                            return (
                              <div className={classes.datePickerWrapper}>
                                <ReactDatePicker
                                  id="end-date"
                                  selected={selected.toDate()}
                                  popperPlacement={!changeDetailsMode ? 'top-end' : 'bottom'}
                                  onChange={(jsDate, e) =>
                                    formikProps.setFieldValue(field.name, (e.key ? date : moment(jsDate)).format('l'))}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Escape') {
                                      e.stopPropagation();
                                    }
                                  }}
                                  minDate={moment(values.dueOn).toDate()}
                                  allowSameDay
                                  customInputRef="inputRef"
                                  customInput={
                                    <TextField
                                      InputProps={{
                                        ...field,
                                        onBlur: (e) => {
                                          if (!field.value) {
                                            formikProps.setFieldValue(field.name, 'Never');
                                          }
                                          field.onBlur(e);
                                        },
                                        classes: { underline: classes.underlineOverride },
                                        autoComplete: 'off',
                                      }}
                                      label="End Date"
                                      margin="dense"
                                      fullWidth
                                      error={Boolean(_.get(touched, field.name) && _.get(errors, field.name))}
                                      helperText={_.get(touched, field.name) && _.get(errors, field.name)}
                                    />
                                  }
                                />
                              </div>
                            );
                          }}
                        />
                      </Grid>}
                  </Grid>

                  {(splitViewOpen || (formikProps.values.transaction.split.items &&
                    formikProps.values.transaction.split.items.length !== 0)) ? (
                      <TextField
                        label="Tags"
                        value="--- Split ---"
                        margin="dense"
                        fullWidth
                        InputProps={{
                          readOnly: true,
                          disabled: true,
                          classes: {
                            underline: classes.underlineOverride,
                            disabled: classes.defaultOverride,
                          },
                        }}
                      />
                    ) : this.renderTagField('transaction.tags', formikProps)}

                  {isAcme && <Field
                    name="transaction.isExcludedFromReports"
                    render={({ field }) =>
                      <FormControlLabel
                        control={
                          <QCheckbox
                            {...field}
                            size={this.props.theme.components.register.checkboxSize}
                            id="ignore-checkbox"
                            checked={field.value}
                            value={field.value ? field.value.toString() : ''}
                          />
                        }
                        label="Ignore from Reports"
                        className={classes.checkboxIgnore}
                      />}
                  />}
                </section>

                <section className={classes.buttonsSection}>
                  <Grid
                    container
                    direction="row"
                    justifyContent={hideDeleteIcon ? 'flex-end' : 'space-between'}
                    alignItems="center"
                    spacing={0}
                  >
                    {!hideDeleteIcon && (
                      <Grid item>
                        <QButton
                          disabled={!formikProps.values.id}
                          id="delete-transaction"
                          aria-label="delete"
                          variant="outlined"
                          style={{ width: 36, minWidth: 36, marginRight: 8, backgroundColor: 'transparent' }}
                          onClick={this.deleteSchTxn(formikProps.values.clientId, formikProps.values.id, formikProps.values.transaction.payee)}
                        >
                          <img src={deleteIcon} alt="delete" />
                        </QButton>
                      </Grid>
                    )}
                    <Grid item>
                      <QButton
                        id="cancel-transaction"
                        aria-label="cancel"
                        variant="outlined"
                        style={{ marginRight: 15 }}
                        onClick={() => {
                          if (formikProps.dirty) {
                            showConfirmationDialog(true);
                          } else if (changeDetailsMode && formikProps.values.id) {
                            changeDetailsMode();
                          } else {
                            onClose();
                          }
                        }}
                      >
                        Cancel
                      </QButton>
                      <QButton
                        type="submit"
                        id="submit-transaction"
                        disabled={formikProps.isSubmitting || (formikProps.values.id && !formikProps.dirty)}
                        onClick={(e) => {
                          e.preventDefault();
                          formikProps.submitForm();
                        }}
                        variant="contained"
                        width={140}
                      >
                        {scheduledTransaction.id ? 'Update' : 'Create'}
                      </QButton>
                    </Grid>
                  </Grid>

                </section>

                <Dialog
                  open={isConfirmationDialogOpen && (isFormDirty || formikProps.dirty)}
                  keepMounted
                  onClose={() => {
                    setDirtyForm(formikProps.dirty);
                    showConfirmationDialog(false);
                  }}
                >
                  <DialogContent>
                    <DialogContentText className={classes.discardText}>
                      Are you sure you want to discard all changes?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setDirtyForm(formikProps.dirty);
                        showConfirmationDialog(false);
                      }}
                      color="primary"
                      style={{ marginRight: 14 }}
                    >
                      Go Back
                    </Button>
                    <QButton
                      onClick={() => {
                        showConfirmationDialog(false);
                        if (discardCb) {
                          discardCb();
                        } else if (onClose) {
                          onClose();
                        }
                      }}
                      variant="contained"
                    >
                      Discard
                    </QButton>
                  </DialogActions>
                </Dialog>
              </Form>
            );
          }}
        />
      </>
    );
  }
}

ScheduledTransactionFormQuicken.propTypes = {
  theme: PropTypes.object,
  classes: PropTypes.object.isRequired,
  changeDetailsMode: PropTypes.func,
  dispatchDeleteScheduledTransactionAction: PropTypes.func,
  dispatchCreateScheduledTransactionAction: PropTypes.func,
  dispatchUpdateScheduledTransactionAction: PropTypes.func,
  hideDeleteIcon: PropTypes.bool,
  isConfirmationDialogOpen: PropTypes.bool,
  isFormDirty: PropTypes.bool,
  label: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  deleteUserVerificationFromPayload: PropTypes.bool,
  scheduledTransaction: PropTypes.object,
  showCloseButton: PropTypes.bool,
  setDirtyForm: PropTypes.func,
  showConfirmationDialog: PropTypes.func,
  discardCb: PropTypes.func,
  showScheduledTxnDayFrequency: PropTypes.bool,
  isQWinDataset: PropTypes.bool,
};


const mapDispatchToProps = (dispatch) => ({
  dispatchCreateScheduledTransactionAction: (data) => dispatch(scheduledTransactionsActions.createScheduledTransaction(data, { undo: { userMessage: 'Recurring transaction created.' } })),
  dispatchUpdateScheduledTransactionAction: (data) => dispatch(scheduledTransactionsActions.updateScheduledTransaction(data, { undo: { userMessage: 'Recurring transaction updated.' } })),
  dispatchDeleteScheduledTransactionAction: (data) => dispatch(scheduledTransactionsActions.deleteScheduledTransaction(data, { undo: { userMessage: 'Recurring transaction deleted.' } })),
  makeDialog: (data) => dispatch(createDialog(mkRootUiData(data))),
});

const mapStateToProps = (state) => ({
  personalIncomeCategory: categoriesSelectors.getPersonalIncomeCategory(state),
  showScheduledTxnDayFrequency: configFeatureFlagsSelectors.getFeatureFlagForUserOrDataset(
    state,
    ClientConfigFlags.showScheduledTxnDayFrequency
  ),
  isQWinDataset: authSelectors.getIsWinDataset(state),
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(ScheduledTransactionFormQuicken);
