import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import { connect } from 'react-redux';
import compose from 'utils/compose';
import { withRouter } from 'react-router-dom';

import { getPayeesForAccountsAsList } from 'data/payees/selectors';
import QTip from 'components/QuickenControls/QTip';
import QTypography from 'components/MUIWrappers/QTypography';

// import { getLogger } from 'utils/logger';
import FilteredInput from './filteredInput';

// const log = getLogger('components/QuickenControls/PayeeFieldEditable/index.js');


const emptyList = List();

class PayeeFieldEditable extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
    this.inputRef = null;
  }

  componentDidMount() {
    if (this.props.initialFocus && this.inputRef) {
      this.inputRef.focus();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    if (nextProps.value.payee !== this.props.value.payee) {

      this.setState({ value: nextProps.value });

      if (!this.props.value || (this.inputRef)) {
        if (this.inputRef) {
          // this.inputRef.focus();
          // this.inputRef.select();
        }
      }
    }
  }

  handleFocus = (e) => {
    e.target.select();
  };

  menuOnChange = (item) => {
    this.props.onChange({ target: { value: item } });
  };

  onChangeInput = (event) => {
    this.props.onChange({ target: { value: { name: event.target.value } } });
  };


  render() {

    const { editable, clickable, classNameWhenNotEditable,
      disableUnderline, autoFocus, fontSize, onBlur, textFieldVariant, marginProp } = this.props;


    return (
      <>
        {editable &&

          <div
            style={{ display: 'flex', width: this.props.width }}
            ref={(x) => { this.fieldRef = x; }}
          >
            <FilteredInput
              placeholder={this.props.placeholder}
              label={this.props.label}
              data={this.props.payeeList || emptyList}
              value={this.state.value.payee}
              onChange={this.menuOnChange}
              onChangeInput={this.onChangeInput}
              onBlur={onBlur}
              name={this.props.name || 'payeelist'}
              disableUnderline={disableUnderline}
              autoFocus={autoFocus}
              menuPosition={this.props.menuPosition}
              textFieldProps={this.props.textFieldProps}
              textFieldVariant={textFieldVariant}
              marginProp={marginProp}
              inputRef={(x) => {
                if (this.props.inputRef) {
                  this.props.inputRef(x);
                }
                this.inputRef = x;
              }}
              fontSize={fontSize}
              menuShowFields={this.props.menuShowFields}
              width={this.props.width}
            />
          </div>}
        {!editable &&
          <QTip
            wrapOnly
            title={this.props.tooltip ? this.state.value.payee : null}
          >
            <QTypography
              type="body1"
              clickable={clickable}
              thinFont
              className={classNameWhenNotEditable}
            >
              {this.state.value.payee}
            </QTypography>
          </QTip>}
      </>
    );
  }
}

PayeeFieldEditable.defaultProps = {
  menuShowFields: 'payee',
  width: 'auto',
};

PayeeFieldEditable.propTypes = {
  // props from parent
  /* eslint-disable react/no-unused-prop-types */
  accountIds: PropTypes.object,  // required for payeeList Selector, if not specfied, uses "all account"
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.object,
  initialFocus: PropTypes.bool,
  clickable: PropTypes.bool,
  textFieldProps: PropTypes.object,
  classNameWhenNotEditable: PropTypes.string,
  tooltip: PropTypes.bool, // show a tooltip on static value?
  disableUnderline: PropTypes.bool,
  autoFocus: PropTypes.bool,
  menuPosition: PropTypes.string,
  fontSize: PropTypes.string,
  inputRef: PropTypes.func,
  payeeList: PropTypes.object,
  name: PropTypes.string,
  menuShowFields: PropTypes.string,  // comma separated string, valid values are 'category', 'amount', 'payee', 'tags', 'memo'
  placeholder: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.string,
  textFieldVariant: PropTypes.string,
  marginProp: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  return {
    // selector uses prop 'accountIds', if not specified, it uses 'all accounts'
    payeeList: getPayeesForAccountsAsList(state, { ...ownProps, accountIds: ownProps.useAllAccounts ? null : ownProps.accountIds }),
  };
}

// note: order-matters - props flow from top to bottom
export default compose(
)(withRouter(connect(mapStateToProps, null)(PayeeFieldEditable)));

