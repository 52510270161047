
import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { categoriesSelectors } from '@quicken-com/react.flux.categories';
import { transactionsTypes, transactionsUtils } from '@quicken-com/react.flux.transactions';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import TrashIcon from '@mui/icons-material/DeleteOutlineRounded';
import SplitIcon from '@mui/icons-material/CallSplit';
import AddIcon from '@mui/icons-material/AddRounded';

// DATA
import { useSelector } from 'react-redux';
import { balanceSplitTxn, adjustSplitLine, removeSplitLine } from 'data/transactions/utils';

// CUSTOM
import QButton from 'components/QButton';
import CategoryField from 'components/QuickenControls/CategoryField';
import QAmountField from 'components/QAmountField';
import TagsField from 'components/QuickenControls/TagsField';
import CategoryHeaderAcme from 'components/QuickenControls/CategoryField/categoryHeaderAcme';


const useStyles = makeStyles((_theme) => ({
  catRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: 0,
  },

  alignedField: {
    width: 270,
    paddingRight: 24,
  },
  leftField: {
    extend: 'alignedField',
    minWidth: 180,
  },
  rightField: {
    flexBasis: 180,
    flexShrink: 0,
  },
  spacer: {
    extend: 'rightField',
    opacity: 0,
  },

  splitCat: {
    width: 236,
    paddingRight: 16,
    minWidth: 172,
  },
  splitTag: {
    width: 236,
    paddingRight: 16,
  },
  splitAmount: {
    width: 140,
    minWidth: 140,
    flexShrink: 0,
  },
  splitRemove: {
    margin: '26px -16px 18px 0',
  },
  trashIcon: {
    marginRight: 4,
  },

  addRow: {
    width: 124,
    marginBottom: 12,
  },
  addIcon: {
    marginRight: 4,
  },

  splitLine: {
    paddingBottom: 12,
  },
  splitIcon: {
    marginRight: 8,
  },
}));


function CategorySection(props) {
  const classes = useStyles();
  const { coa, setCoa, tags, setTags, split, setSplit, amount, focusSplits, validPayee, setCoaInternal } = props;

  const isSplit = transactionsUtils.isSplitTxn({ split });

  const incomeCOAs = useSelector(categoriesSelectors.getIncomeCOAIds);

  useEffect(() => {
    if (isSplit) {
      const newTxn = balanceSplitTxn({ amount, split });
      setSplit(newTxn.split);
    }
  }, [amount, isSplit]); // eslint-disable-line react-hooks/exhaustive-deps

  // +++                  Logic Functions                    +++
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initSplit = () => {
    const splitItems = [];
    // push current amount and cat+tags
    splitItems.push({
      coa: coa || { type: 'UNCATEGORIZED', id: '0' },
      tags,
      amount: Number(amount),
    });

    // push secondary uncategorized line
    splitItems.push({
      coa: { type: 'UNCATEGORIZED', id: '0' },
      amount: 0,
    });

    setSplit(transactionsTypes.mkSplit({ items: splitItems }));
  };

  const addSplitLine = () => {
    const newSplit = split.set('items', split.items.push(transactionsTypes.mkSplitItem({
      amount: 0,
      coa: { type: 'UNCATEGORIZED', id: '0' },
    })));
    setSplit(newSplit);
  };

  const removeSplit = (index) => () => {
    // if two or less lines, reset
    const remainingSplit = index === 1 ? split.items?.first() : split.items?.last();
    if (split.items?.size < 3) {
      if (remainingSplit?.coa) {
        setCoaInternal(remainingSplit?.coa);
      }
      if (remainingSplit?.tags) {
        setTags(remainingSplit?.tags);
      }
      setSplit(null);
    } else {
      setSplit(removeSplitLine({ amount, split }, index));
    }
  };

  const updateSplitCat = (index) => (newCat) => {
    const newSplit = split.setIn(['items', index, 'coa'], newCat);
    setSplit(newSplit);
  };

  const updateSplitTag = (index) => (newTags) => {
    const newSplit = split.setIn(['items', index, 'tags'], newTags);
    setSplit(newSplit);
  };

  const updateSplitAmount = (index) => (e) => {
    const newAmount = Number(e.target.value);
    const newSplit = split.setIn(['items', index, 'amount'], newAmount);

    setSplit(adjustSplitLine({ amount, split: newSplit }, index));
  };


  // +++                 Render Functions                    +++
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  const renderSplitRow = (splitItem, index) => (
    <div
      className={classes.catRow}
      key={`${splitItem.coa.type}+${splitItem.coa.id}+${index}`}
    >
      <CategoryField
        margin="normal"
        textFieldVariant="outlined"
        variant="body1"
        label="Category"
        editable
        createEnabled
        name="txn-details"
        id={`split-cat-${index}`}
        value={splitItem.coa}
        onChange={updateSplitCat(index)}
        classes={{ root: classes.splitCat }}
        width={236}
      />

      {/* Tags Field */}
      <TagsField
        label="Tags"
        margin="normal"
        textFieldVariant="outlined"
        placeholder={null}
        id={`split-tags-${index}`}
        value={splitItem.tags}
        onChange={updateSplitTag(index)}
        className={classes.splitTag}
      />

      {/* Amount Field */}
      <QAmountField
        label="Amount"
        margin="normal"
        textFieldVariant="outlined"
        editable
        submitOnBlur
        value={splitItem.amount}
        onSubmit={updateSplitAmount(index)}
        className={classes.splitAmount}
        id={`split-amount-${index}`}
        expectedSign={incomeCOAs?.has(splitItem?.coa?.id) ? 'positive' : 'negative'}
        autoFocus={focusSplits && (index === 0)}
      />

      <QButton
        onClick={removeSplit(index)}
        id={`split-remove-${index}`}
        className={classes.splitRemove}
      >
        <TrashIcon className={classes.trashIcon} /> Remove
      </QButton>
    </div>
  );

  // # ===================================================================================== #
  // # -----                               Main Render                                 ----- #
  // # ===================================================================================== #

  return (isSplit ?
    <>
      {/* SPLITS */}
      {split?.items.map(renderSplitRow)}
      <QButton
        onClick={addSplitLine}
        id="add-split-button"
        className={classes.addRow}
      >
        <AddIcon className={classes.addIcon} /> ADD ROW
      </QButton>
    </>
    :
    <>
      {/* NO SPLITS */}
      <div className={classes.catRow}>
        <CategoryField
          margin="normal"
          textFieldVariant="outlined"
          variant="body1"
          label="Category"
          editable
          name="txn-details"
          id="cat-field"
          value={coa}
          onChange={setCoa}
          classes={{ root: classes.leftField }}
          width={270}
          createEnabled
          header={validPayee ? <CategoryHeaderAcme /> : undefined}
        />

        {/* Tags Field */}
        <TagsField
          label="Tags"
          margin="normal"
          textFieldVariant="outlined"
          placeholder={null}
          id="tags-field"
          value={tags}
          onChange={setTags}
          className={classes.alignedField}
        />

        {/* Spacer */}
        <div className={classes.spacer} />
      </div>

      <Typography variant="caption" className={classes.splitLine}>
        <QButton
          onClick={initSplit}
          id="split-button"
        >
          <SplitIcon className={classes.splitIcon} /> SPLIT TRANSACTION
        </QButton>
        Use to assign multiple tags or categories
      </Typography>
    </>
  );
}

CategorySection.propTypes = {
  coa: PropTypes.object,
  setCoa: PropTypes.func,
  tags: PropTypes.object,
  setTags: PropTypes.func,
  split: PropTypes.object, // immutable record
  setSplit: PropTypes.func,
  amount: PropTypes.number,
  focusSplits: PropTypes.bool,
  validPayee: PropTypes.bool,
  setCoaInternal: PropTypes.func,
};

export default memo(CategorySection);
