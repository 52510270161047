import { call, put, takeEvery, race, take } from 'redux-saga/effects';

import { getEnvironmentConfig, getLogger } from '@quicken-com/react.utils.core';
import { resourceStoreTypes, resourceSagaUtils } from '@quicken-com/react.flux.core';

import { isQuicken } from 'isAcme';
import AxiosFactory from 'utils/axiosFactory';

import { getPreferencesV2, getPreferencesV2Failure, getPreferencesV2Success } from 'data/preferencesV2/preferencesV2Actions';

import * as actions from './actions';
import { transformResponseToInstitutions, transformResponseToInstitution, transformSearchResponseToInstitutions } from './transformers';

const qcsAxios = AxiosFactory.get('qcs');
const log = getLogger('data/institutions/sagas');

const mkResourceConfig = (props) => resourceStoreTypes.mkQcsSyncResourceConfig({
  resourceName: 'institutions',
  resourceBaseUrl: '/institutions',
  getLastSyncDate: null,
  transformResponseToResource: transformResponseToInstitution,
  ...props,
});

// =================================================================================================
// searchForInstitutions
// =================================================================================================

function* searchForInstitutions({ payload, meta }: {payload: any, meta: any}): Generator<*, *, *> {
  log.log('searchForInstitutions called...', payload);
  const { aggregator, searchString } = payload;
  const { resolve, reject } = meta;

  const requestUrl = `${getEnvironmentConfig().services_url}/institutions/search`;
  const params = {
    aggregator,
    name: searchString,
  };

  log.log('searchForInstitutions requestUrl...', requestUrl, params);
  try {
    const response = yield call(qcsAxios.get, requestUrl, { params });
    log.log('searchForInstitutions response...', response);

    const institutions = transformSearchResponseToInstitutions(response);
    log.info('searchForInstitutions response after transform=', institutions);

    yield put(actions.searchForInstitutionsSuccess(institutions));
    yield call(resolve, institutions);
  } catch (error) {
    // TODO: explicitly check for error type
    log.error('error');
    yield put(actions.searchForInstitutionsFailure(error));
    yield call(reject, error);
  }
}

function* getInstitution(action) : Generator<*, *, *> {
  yield put(getPreferencesV2());
  yield race([
    take(getPreferencesV2Success),
    take(getPreferencesV2Failure),
  ]);

  const resourceConfig = mkResourceConfig({
    resourceBaseUrl: '/institutions/%s',
    successAction: actions.getInstitutionResponse,
    failureAction: actions.getInstitutionResponse,
  });
  yield call(resourceSagaUtils.qcsSyncGetResource, resourceConfig, action);
}

// =============================================================================
// Watchers
// =============================================================================

function* getInstitutionActionWatcher() : Generator<*, *, *> {
  yield takeEvery(actions.getInstitution, getInstitution);
}

function* getPopularInstitutionsActionWatcher() : Generator<*, *, *> {
  const resourceConfig = resourceStoreTypes.mkQcsSyncResourceConfig({
    resourceName: 'popular-institutions',
    resourceBaseUrl: '/institutions/suggested',
    axiosConfig: {
      params: {
        limit: isQuicken ? 10 : 15,
      },
    },
    getLastSyncDate: null,
    transformResponseToResources: transformResponseToInstitutions,
    successAction: actions.getPopularInstitutionsSuccess,
    failureAction: actions.getPopularInstitutionsFailure,
  });
  yield takeEvery(actions.getPopularInstitutions, resourceSagaUtils.qcsSyncGetResources, resourceConfig);
}

function* searchForInstitutionsActionWatcher() : Generator<*, *, *> {
  yield takeEvery(actions.searchForInstitutions, searchForInstitutions);
}

// =============================================================================
// Exports
// =============================================================================

export default [
  getInstitutionActionWatcher,
  getPopularInstitutionsActionWatcher,
  searchForInstitutionsActionWatcher,
];

