import React, { useEffect } from 'react';

import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import PaperAirplane from 'assets/zero-state-images/paperAirplaneCrash.svg';
import QButton from 'components/QButton';
import ReportProblemLink from 'components/ReportProblemLink';
import { dismissErrorPage } from 'utils/core/bugsnag';

import styles from './styles';

const useStyles = makeStyles(styles);

const FallbackComponent = (_props) => {
  const classes = useStyles();
  useEffect(() => dismissErrorPage(), []);

  return (
    <div className={classes.parentContainer}>
      <img
        alt="paper-airplane"
        src={PaperAirplane}
        className={classes.paperAirplane}
      />

      <Typography
        variant="h5"
        className={classes.textItem}
      >
        {"Something isn't right.."}.
      </Typography>

      <QButton
        variant="outlined"
        onClick={() => window.location.reload()}
        className={classes.tryAgainButton}
      >
        Try Again
      </QButton>

      <ReportProblemLink
        feedbackSubject="report react crash"
        className={classes.reportProblem}
      />
    </div>
  );
};

FallbackComponent.whyDidYouRender = false;

export default FallbackComponent;
