import React from 'react';
import compose from 'utils/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';

import QPreferences from 'components/QPreferences';
import QMenu from 'components/QMenu';
import { tracker } from '@quicken-com/react.utils.core';

import { isAcme } from 'isAcme';

import { visibleBalances } from 'data/accountBalances/types';

const styles = () => ({

  balanceMenu: {
  },
  iconColor: {
  },
});

class BalanceSelectMenu extends React.Component {

  handleAccountBarBalanceMenu = (e, groupId) => {

    if (groupId === 'balDisplay') {
      this.props.setDatasetPreference({ accountBarBalanceType: e });

      // Tracking what got changed
      tracker.track(tracker.events.accountBalanceChange, {
        type: 'accountList',
        changedTo: e,
      });

    } else if (groupId === 'showCents') {
      this.props.setDatasetPreference({ accountBarShowCents: !this.props.datasetPreferences.accountBarShowCents });
    }

  };

  render() {

    const { classes, menuIconButtonSize } = this.props;
    const { accountBarBalanceType, accountBarShowCents } = this.props.datasetPreferences;

    const menuOptions = isAcme ? [] : visibleBalances.map((x) => ({ label: x.label, value: x.value }));

    if (!isAcme) menuOptions[menuOptions.length - 1].divider = true;

    return (
      <div
        className={classes.balanceMenu}
      >
        <QMenu
          name="balmenu"
          title="Select balance type to view"
          options={[
            { label: 'Balance Display',
              groupId: 'balDisplay',
              isGroupList: true,
              selected: accountBarBalanceType,
              groupList: menuOptions,
            },
            { label: null,
              groupId: 'showCents',
              isGroupList: true,
              selected: accountBarShowCents ? 'showCents' : null,
              groupList: [
                { label: 'Show Cents', value: 'showCents' },
              ],
            },
          ]}
          onChange={this.handleAccountBarBalanceMenu}
          classes={{ iconColor: classes.iconColor }}
          menuIconButtonSize={menuIconButtonSize || 'smallNoPadding'}
        />
      </div>
    );
  }
}

BalanceSelectMenu.propTypes = {

  menuIconButtonSize: PropTypes.string,

  classes: PropTypes.object,
  datasetPreferences: PropTypes.object,
  setDatasetPreference: PropTypes.func,
};

function mapStateToProps() {
  return {
  };
}

function mapDispatchToProps() {
  return {
  };
}

export default compose(
  withStyles(styles),
  QPreferences(),
)(withRouter(connect(mapStateToProps, mapDispatchToProps)(BalanceSelectMenu)));
