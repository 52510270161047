import { BudgetItemType } from './budgetItemsTypes';


export const transformBudgetItemsRequestData = (budgetItems: Array<BudgetItemType>) => ({
  resources: budgetItems.map(transformBudgetItemToApiRequestData),
});

export const transformBudgetItemToApiRequestData = (budgetItem: BudgetItemType) => {
  const requestBudgetItem = budgetItem.toJS ? budgetItem.toJS() : budgetItem;
  return ({
    ...requestBudgetItem,
    coa: requestBudgetItem.type === 'GROUP' ? null : requestBudgetItem.coa,
  });
};
