import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { OrderedMap } from 'immutable';
import { v4 as uuidv4 } from 'uuid';

import { localPreferences } from '@quicken-com/react.utils.core';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

import { addNotifications, removeNotifications } from 'data/notifications/notificationsActions';
import { mkNotification } from 'data/notifications/notificationsTypes';
import ReportProblemLink from 'components/ReportProblemLink';

const localStorageOK = localPreferences.checkLocalStorageAccess();

const StorageAccessChecker = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!localStorageOK) {
      const id = uuidv4();
      dispatch(addNotifications(OrderedMap({
        [id]: mkNotification({
          id,
          autoHideDuration: 20000, // ms
          snackbarContentProps: {
            message: (
              <Box display="flex">
                For the best experience, please enable cookies in your browser settings.&nbsp;&nbsp;
                <ReportProblemLink feedbackSubject="local storage access" />
              </Box>
            ),
            action: (
              <IconButton
                size="small"
                aria-label="dismiss"
                id="outdated-browser-dismiss"
                color="inherit"
                onClick={() => dispatch(removeNotifications([id]))}
              >
                <CloseIcon />
              </IconButton>
            ),
          },
        }),
      })));
    }
  }, [dispatch]);

  return null;
};

export default StorageAccessChecker;


