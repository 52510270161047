import { connect } from 'react-redux';
import QDialogs from 'components/QDialogs';
import queryString from 'query-string';
import compose from 'utils/compose';
import { List as ListImmutable, Record } from 'immutable';
import memoizeOne from 'memoize-one';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';

import { authSelectors } from '@quicken-com/react.flux.auth';
import { accountsSelectors } from '@quicken-com/react.flux.accounts';
import { categoriesActions, categoriesSelectors } from '@quicken-com/react.flux.categories';
import { transactionsActions } from '@quicken-com/react.flux.transactions';

import UIState from 'components/UIState';
import { getBudgetsAction, createBudgetsAction, deleteBudgetsAction, callFullCalcBudgetAction } from 'data/budgets/budgetsActions';
import { getCategoryGroupListsAction } from 'data/categoryGroupLists/categoryGroupListsActions';
import { getCategoryGroupsAction } from 'data/categoryGroups/categoryGroupsActions';
import { getPreferences as getPreferencesAction, setPreference as setPreferenceAction } from 'data/preferences/actions';
import * as budgetsSelectors from 'data/budgets/budgetsSelectors';
import * as budgetItemsSelectors from 'data/budgetItems/budgetItemsSelectors';
import * as budgetItemsActions from 'data/budgetItems/budgetItemsActions';
import * as categoryGroupListsSelectors from 'data/categoryGroupLists/categoryGroupListsSelectors';
import * as categoryGroupsSelectors from 'data/categoryGroups/categoryGroupsSelectors';
import * as preferencesSelectors from 'data/preferences/selectors';
import { getTransactionsByFilterFn } from 'data/transactions/selectors';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';
import { isBankOwnedPending } from 'data/transactions/utils';
import styles from './styles';
import BudgetsV2 from './BudgetsV2';

const transactionFilterFnGUID = uuidv4();
const transactionFilterFn = (transaction, props) => {
  let keepTransaction = !(transaction.source === 'SCHEDULED_TRANSACTION_PENDING');
  keepTransaction = (keepTransaction && props?.excludedAccounts?.size > 0) ? !props.excludedAccounts.includes(transaction.accountId) : keepTransaction;
  keepTransaction = (keepTransaction && props.accountIds && props.accountIds.size > 0) ? props.accountIds.has(transaction.accountId) : keepTransaction;
  const postedOnDate = DateTime.fromISO(transaction.postedOn).toJSDate();
  keepTransaction = (keepTransaction && props.startDate) ? postedOnDate >= props.startDate : keepTransaction;
  keepTransaction = (keepTransaction && props.endDate) ? postedOnDate <= props.endDate : keepTransaction;
  keepTransaction = (keepTransaction && props.coas && props.coas.size > 0) ? Boolean(
    (transaction.coa && props.coas.find((coa) => coa.type === transaction.coa.type && coa.id === transaction.coa.id
      && (transaction.coa.type !== 'ACCOUNT' || !props.negator || Math.sign(transaction.amount) === props.negator))) // special casing for transfers - drop one side of a transfer
    ||
    (transaction.split && transaction.split.items &&
      transaction.split.items.find((splitItem) => props.coas.find((coa) => coa.type === splitItem.coa.type && coa.id === splitItem.coa.id
        && (splitItem.coa.type !== 'ACCOUNT' || !props.negator || Math.sign(splitItem.amount) === props.negator))) // special casing for transfers - drop one side of a transfer
    )
  ) : false; // keepTransaction;

  return keepTransaction;
};
const transactionFilterPropsCached = memoizeOne((startDate, endDate, coas, accountIds, negator, excludedAccounts) => ({
  startDate,
  endDate,
  coas,
  accountIds,
  negator,
  filterFn: transactionFilterFn,
  filterFnGUID: transactionFilterFnGUID,
  excludedAccounts,
}));

function mapStateToProps(state, props) {
  const query = props.location && props.location.search && queryString.parse(props.location.search);
  const queryBudgetId = query?.displayBudget ? query.displayBudget : null;
  const queryBudgetNodeKey = query?.displayNode ? query.displayNode : null;
  const queryDashboardRef = query?.ref ? query.ref : null;
  const preferences = preferencesSelectors.getPreferences(state);
  const sharedDatasetPreferences = preferences && preferences.shared.dataset;
  const lastViewedBudgetId = sharedDatasetPreferences && sharedDatasetPreferences.budgetOptions && sharedDatasetPreferences.budgetOptions.lastViewedBudgetId;
  const budgets = budgetsSelectors.getBudgets(state);

  let budget = queryBudgetId && budgetsSelectors.getBudgetById(state, queryBudgetId);
  budget = budget || budgetsSelectors.getBudgetById(state, lastViewedBudgetId);
  budget = budget || (budgets && budgets.first());

  const budgetTreeNodes = budget && props.uiState && props.uiState.startDate && props.uiState.endDate &&
    budgetsSelectors.getBudgetTreeNodes(state, {
      budget,
      startDate: props.uiState.startDate,
      endDate: props.uiState.endDate,
    });
  const navigationPath = budgetTreeNodes && queryBudgetNodeKey && budgetsSelectors.pathToNodeWithKey(budgetTreeNodes, queryBudgetNodeKey);
  const budgetTreeNode = navigationPath && navigationPath.length > 0 && navigationPath[navigationPath.length - 1];

  const coas = budgetTreeNode ? budgetTreeNode.coas : budgetTreeNodes && budgetsSelectors.coasForNodes(budgetTreeNodes);
  const accountIds = budget && budget.filterAccountIds && budget.filterAccountIds.map((account) => account.id);
  const allAccounts = accountIds && accountsSelectors.getAccountsById(state);
  const excludedAccounts = accountIds && accountsSelectors.getExcludedBudgetAccounts(state).map((account) => account.id);
  const budgetAccounts = accountIds && allAccounts && allAccounts.filter((account) => accountIds.includes(account.id) && account.currency === budget.currency);
  const budgetAccountIds = (budgetAccounts && budgetAccounts.map((account) => account.id)) || new ListImmutable([]);
  const negator = (budgetTreeNode && budgetTreeNode.negate) || undefined;

  let transactions = props.uiState && props.uiState.startDate && props.uiState.endDate && coas && budgetAccountIds &&
    getTransactionsByFilterFn(state, transactionFilterPropsCached(props.uiState.startDate, props.uiState.endDate, coas, budgetAccountIds, negator, excludedAccounts));
  transactions = transactions && transactions.filter((txn) => !isBankOwnedPending(txn));

  return {
    budgetAccountIds,
    budgets,
    budget,
    budgetItems: budgetItemsSelectors.getBudgetItems(state),
    navigationPath,
    budgetTreeNode,
    budgetTreeNodes,
    transactions,
    preferences: preferencesSelectors.getPreferences(state),
    loadPending: Boolean(
      budgetsSelectors.getLoadPending(state)
      || budgetItemsSelectors.getLoadPending(state)
      || categoryGroupsSelectors.getLoadPending(state)
      || categoryGroupListsSelectors.getLoadPending(state)
      || categoriesSelectors.getLoadPending(state)
    ),
    isLoading: Boolean(
      budgetsSelectors.getIsLoading(state)
      || budgetItemsSelectors.getIsLoading(state)
      || categoryGroupsSelectors.getIsLoading(state)
      || categoryGroupListsSelectors.getIsLoading(state)
      || categoriesSelectors.getIsLoading(state)
    ),
    categoryGroupLists: categoryGroupListsSelectors.getCategoryGroupLists(state),
    categoryGroups: categoryGroupsSelectors.getCategoryGroupsById(state),
    hasReportableAccounts: accountsSelectors.getHasReportableAccounts(state, props),
    budgetsEnabled: featureFlagsSelectors.getFeatureFlags(state).get('budgets'),
    budgetsCreation: featureFlagsSelectors.getFeatureFlags(state).get('budgetsCreation'),
    isWebFirstDataset: authSelectors.getIsWebfirstDataset(state),
    dashboardRef: queryDashboardRef,
  };
}

const BudgetsUIState = Record({
  startDate: null,
  endDate: null,
  selectedAnnualBudgetItem: null,
  selectedMonthDate: null,
});

const uiStateConfig = {
  name: 'Budgets',
  state: () => new BudgetsUIState(),
  persist: false,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetUserPreferencesAction: () => dispatch(getPreferencesAction({ section: 'shared', group: 'user' })),
    dispatchSetUserPreferenceAction: (preference) =>
      dispatch(setPreferenceAction({ section: 'shared', group: 'user', preference })),
    dispatchGetBudgetsAction: () => dispatch(getBudgetsAction()),
    dispatchCreateBudgetAction: (data) => dispatch(createBudgetsAction([data], { undo: { userMessage: 'New budget created.' } })),
    dispatchDeleteBudgetAction: (data) => dispatch(deleteBudgetsAction([data])),
    dispatchGetBudgetItemsAction: () => dispatch(budgetItemsActions.getBudgetItemsAction()),
    dispatchGetCategoryGroupListsAction: () => dispatch(getCategoryGroupListsAction()),
    dispatchGetCategoryGroupsAction: () => dispatch(getCategoryGroupsAction()),
    dispatchGetCategoriesAction: () => dispatch(categoriesActions.getCategories()),
    dispatchGetPreferencesAction: () =>
      dispatch(getPreferencesAction({ section: 'shared', group: 'dataset' })),
    dispatchGetTransactionsAction: () =>
      dispatch(transactionsActions.getTransactions(undefined, { context: 'budgets' })),
    dispatchSetSharedDatasetPreferenceAction: (preference) => dispatch(setPreferenceAction({ section: 'shared', group: 'dataset', preference })),
    dispatchSetSharedWebAppDatasetPreferenceAction: (objPreference) => dispatch(setPreferenceAction({
      section: 'shared',
      group: 'dataset',
      preference: { webApp: objPreference },
    }, { context: 'QPreferences:setDatasetPreference' })),
    dispatchCreateBudgetItemsAction: (data) => dispatch(budgetItemsActions.createBudgetItemsAction(data, { undo: { userMessage: 'Budget items created' } })),
    dispatchBudgetFullCalcCall: (budgetId) => dispatch(callFullCalcBudgetAction(budgetId)),
  };
}

export default compose(
  UIState(uiStateConfig),
  QDialogs(),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, { withTheme: true })
)(withRouter(BudgetsV2));
