// @flow
import { combineActions, handleActions } from 'redux-actions';
import { hash } from 'immutable';

import { resourceUpserters, coreActions } from '@quicken-com/react.flux.core';

import * as actions from './actions';
import { mkDocumentsStore } from './types';

const reducer = handleActions({

  [coreActions.sanitize]: (state) =>
    state.set('resourcesById', state.resourcesById.map((resource) => resource.merge({
      name: hash(resource.name).toString(),
    }))),

  // Called when triggering a CRUD operation on transactions. Sagas are also
  // expected to handle the action by initiating the CRUD operation with QCS.
  //
  [combineActions(
    actions.getDocuments,
    actions.createDocument,
    actions.deleteDocument
  )]: (state) => state,

  // Called when a CRUD operation is successful on transactions. The success
  // action is triggered by the Sagas.
  [combineActions(
    actions.getDocumentsSuccess
  )]:
    (state, { payload: data }) => resourceUpserters.upsertResources(state, data),

  // Called when a CRUD operation is successful on transactions. The success
  // action is triggered by the Sagas.
  [combineActions(
    actions.createDocumentSuccess,
    actions.deleteDocumentSuccess
  )]:
    (state, { payload: documentToUpsert }) =>
      resourceUpserters.upsertResource(state, documentToUpsert),

  // Called when a CRUD operation fails on transactions. The failure action
  // is triggered by Sagas.
  [combineActions(
    actions.getDocumentsFailure,
    actions.createDocumentFailure,
    actions.deleteDocumentFailure
  )]:
    (state, { payload: _error }) =>
      // todo: what else should we do with the failure?
      state,

}, mkDocumentsStore());

export const REDUCER_KEY = 'documentStore';
export default reducer;
