import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { isAcme } from 'isAcme';
import { useSelector } from 'react-redux';

import { accountsSelectors } from '@quicken-com/react.flux.accounts';
import { categoriesSelectors } from '@quicken-com/react.flux.categories';
import { transactionsTypes } from '@quicken-com/react.flux.transactions';
import { chartOfAccountsSelectors } from '@quicken-com/react.flux.chart-of-accounts';
import { scheduledTransactionsSelectors } from '@quicken-com/react.flux.scheduled-transactions';

import { getTransactionsByAccountId } from 'data/transactions/selectors';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import AlarmOn from '@mui/icons-material/AlarmOn';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formatAmount } from 'components/QCurrency/core';
import QTip from 'components/QuickenControls/QTip';
import QMenu from 'components/QMenu';
import useQData from 'components/QData/useQData';
import {
  getCalendarTxnType, getTxnStateInfo, isUnacceptedScheduledTxn,
  nextScheduledInstanceAfterDate, transactionEditsNotAllowed, isNextScheduledInstance,
} from 'data/transactions/utils';


const defaultMenuOption = [{ label: 'Go to Register', value: 'REGISTER' }];

const schTxnsIsNextMenuOption = [
  { label: 'Enter this reminder', value: 'ACCEPT' },
  { label: 'Ignore this reminder', value: 'SKIP' },
  { label: 'Go to Bills', value: 'BILLS' },
];

const schTxnsMenuOption = [
  { label: 'Enter this and all previous overdue reminders for this Payee as Paid', value: 'ACCEPT' },
  { label: 'Ignore this and all previous overdue reminders for this Payee', value: 'SKIP' },
  { label: 'Go to Bills', value: 'BILLS' },
];

const schTxnsGrossPaycheckMenuOption = [
  { label: 'Go to Bills', value: 'BILLS' },
];

const TableTxnRow = (props) => {
  const { columns, classes, txn, onGoToRegister, onGoToBills } = props;

  const incomeCOAIds = useSelector(categoriesSelectors.getIncomeCOAIds);
  const accountsById = useSelector(accountsSelectors.getAccountsById);
  const accountTransactions = useSelector(getTransactionsByAccountId)?.get(txn.accountId);
  const scheduledTransactions = useSelector(scheduledTransactionsSelectors.getScheduledTransactions);
  const category = useSelector((state) =>
    txn.coa && chartOfAccountsSelectors.getCoaStringSelector(state, txn.coa, true));

  const { qDataPerformTransactionAction } = useQData('calendarTxnModal_TxPage');

  const type = useMemo(() => getCalendarTxnType(txn, incomeCOAIds), [txn, incomeCOAIds]);
  const recurringStatus = useMemo(() => getTxnStateInfo(txn), [txn]);
  const isUnacceptedSchTxn = useMemo(() => isUnacceptedScheduledTxn(txn) && !isAcme, [txn]);
  const nextInstance = useMemo(() => accountTransactions && txn.stModelId &&
    nextScheduledInstanceAfterDate(moment(), accountTransactions, txn.stModelId),
  [accountTransactions, txn]);
  const isNext = useMemo(() => nextInstance && nextInstance.id === txn.id, [nextInstance, txn]);
  const isUpcoming = isNextScheduledInstance(txn, scheduledTransactions);

  let menuOptions = null;
  let reminderIconClass = null;
  let qtipTitle = null;

  const scheduledTransactionsById = useSelector(scheduledTransactionsSelectors.getScheduledTransactions);
  const stRecord = scheduledTransactionsById.find(
    (item) => item.id === txn.stModelId
  );
  const isTypeGrossPaycheck = Boolean(stRecord?.type === 'GROSS_PAYCHECK');

  if (isTypeGrossPaycheck) {
    menuOptions = schTxnsGrossPaycheckMenuOption;
  } else if (isUnacceptedSchTxn) {
    // if this scheduled instance contains transfers to unsynced accounts, then do not allow the user to act
    menuOptions = !transactionEditsNotAllowed(txn) && isUpcoming ? schTxnsIsNextMenuOption : schTxnsMenuOption;
  }

  switch (type) {
    case transactionsTypes.calTxnLabelTypes.income:
    case transactionsTypes.calTxnLabelTypes.expense:
      if (recurringStatus.status === 'DUE') {
        qtipTitle = 'This reminder is due';
        reminderIconClass = classes.dueType;
      } else {
        if (isNext) {
          qtipTitle = 'This will be the next instance that becomes due';
        } else {
          qtipTitle = 'This reminder is not yet due';
          menuOptions = null;
        }
        reminderIconClass = classes.notDue;
      }
      break;
    case transactionsTypes.calTxnLabelTypes.overdue:
      qtipTitle = 'This reminder is overdue';
      reminderIconClass = classes.overdueType;
      break;
    case transactionsTypes.calTxnLabelTypes.normal:
    default:
      reminderIconClass = null;
  }

  if (reminderIconClass && category === '[Unsynced Account]') {
    menuOptions = schTxnsGrossPaycheckMenuOption;
  }

  const onMenuChange = useCallback((action) => {
    if (action === 'BILLS') {
      onGoToBills(txn.stModelId);
      return;
    }
    if (action === 'REGISTER') {
      onGoToRegister(txn);
      return;
    }

    const actionObj = {
      id: txn.id,
      action,
      overdueAction: action,
      instanceDate: txn.stDueOn,
    };
    qDataPerformTransactionAction(actionObj);
  }, [onGoToBills, onGoToRegister, txn, qDataPerformTransactionAction]);

  return (
    <TableRow className={classes.tableBodyRow}>
      {Object.keys(columns).map((key) => {
        let cell = null;

        switch (key) {
          case 'reminderCol':
            cell = (
              <div className={classes.reminderColContainer}>
                {reminderIconClass && (
                  <QTip
                    title={qtipTitle}
                    wrapId={`qtip-${txn.id}-recurring`}
                  >
                    <AlarmOn className={classNames(classes.reminderIcon, reminderIconClass)} />
                  </QTip>
                )}
              </div>
            );
            break;
          case 'payeeCol':
            cell = <div className={classes.payeeColContainer}>{txn.payee}</div>;
            break;
          case 'accountCol':
            cell = <div className={classes.accountColContainer}>{accountsById?.get(txn.accountId)?.name}</div>;
            break;
          case 'categoryCol':
            cell = <div className={classes.categoryColContainer}>{category}</div>;
            break;
          case 'amountCol':
            cell = <div className={classes.amountColContainer}>{formatAmount(txn.amount)}</div>;
            break;
          case 'optionsCol': {
            const customTrigger = (
              <ExpandMoreIcon className={classes.expandIcon} />
            );
            cell = (
              <div className={classes.optionsColContainer}>
                <QMenu
                  customTrigger={customTrigger}
                  name={`qmenu-${txn.id}-recurring`}
                  title={qtipTitle}
                  menuIconButtonSize="small"
                  options={menuOptions || defaultMenuOption}
                  onChange={onMenuChange}
                  customTriggerClass={classes.expandIconRoot}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                />
              </div>
            );
          }
            break;
          default: break;
        }

        return (
          <TableCell key={`cell-${txn.id}-${key}`} className={classNames(classes.tableBodyCell, classes?.[key])}>
            {cell}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
TableTxnRow.whyDidYouRender = true;

TableTxnRow.propTypes = {
  columns: PropTypes.object,
  classes: PropTypes.object,
  txn: PropTypes.object,
  onGoToRegister: PropTypes.func,
  onGoToBills: PropTypes.func,
};

export default React.memo(TableTxnRow);
