import React from 'react';
import { Map as ImmutableMap } from 'immutable';
import { mkRootUiData } from 'data/rootUi/types';
import { dangerouslyGetTheme } from 'themes/themesSelectors';

import DeleteIcon from '@mui/icons-material/DeleteRounded';
import WarningIcon from '@mui/icons-material/WarningRounded';
import MatchIcon from '@mui/icons-material/CompareArrowsRounded';
import InfoIcon from '@mui/icons-material/InfoRounded';
import HelpIcon from '@mui/icons-material/HelpRounded';
import ErrorIcon from '@mui/icons-material/ErrorRounded';
import OkIcon from '@mui/icons-material/CheckCircleRounded';

import { getLogger } from '@quicken-com/react.utils.core';

import { DIALOG_TYPE as ALERT_DIALOG_TYPE, mkAlertDialogProps } from 'components/Dialogs/AlertDialog/types';
import { DIALOG_TYPE as FORM_DIALOG_TYPE, mkFormDialogProps } from 'components/Dialogs/FormDialog/types';

const log = getLogger('components/QDialogs/index.js');

// Config is passed in by using component when composing wrapper
//

const getDialogIcon = (titleIcon) => {

  const theme = dangerouslyGetTheme();
  const iconStyle = {
    marginTop: -3,
    marginRight: 10,
    color: theme.palette.greyScaleDeprecated[2],
  };

  switch (titleIcon) {

    case 'delete':
      return <DeleteIcon style={iconStyle} />;
    case 'warning':
      return <WarningIcon style={iconStyle} />;
    case 'match':
      return <MatchIcon style={iconStyle} />;
    case 'info':
      return <InfoIcon style={iconStyle} />;
    case 'ok':
      return <OkIcon style={iconStyle} />;
    case 'help':
      return <HelpIcon style={iconStyle} />;
    case 'error':
      return <ErrorIcon style={iconStyle} />;

    default:
      return null;
  }
};

export const dialogAlertCustom = ({ title, subtitle, titleIcon, content, onClose, buttons = ['No', 'Yes'], paperStyle, rootStyle, iconsOnDialogAlerts }) => {

  const dialogData = mkRootUiData({
    type: ALERT_DIALOG_TYPE,
    allowNesting: true,
    id: title,
    props: ImmutableMap(mkAlertDialogProps({
      title,
      subtitle,
      content,
      onClose: onClose || (() => {}),
      buttons: onClose ? buttons : ['Ok'],
      paperStyle,
      titleIcon: iconsOnDialogAlerts ? getDialogIcon(titleIcon) : null,
      rootStyle,
    })),
  });
  log.log('Creating Dialog...', dialogData.toJS());
  return dialogData;
};

export const dialogAlert = (
  title,
  content,
  onClose,
  buttons = ['No', 'Yes'],
  titleIcon = 'info',
  showCloseButton,
  iconsOnDialogAlerts,
  tertiaryButton
) => {

  const dialogData = mkRootUiData({
    type: ALERT_DIALOG_TYPE,
    allowNesting: true,
    id: title,
    props: ImmutableMap(mkAlertDialogProps({
      title,
      titleIcon: iconsOnDialogAlerts ? getDialogIcon(titleIcon) : null,
      content,
      onClose: onClose || (() => {}),
      buttons: onClose ? buttons : ['Ok'],
      showCloseButton,
      tertiaryButton,
    })),
  });
  log.log('Creating Dialog...', dialogData.toJS());
  return dialogData;
};

export const dialogPassword = (title, content, label, onClose, buttons = ['Submit'], titleIcon, iconsOnDialogAlerts) => {
  const dialogData = mkRootUiData({
    type: FORM_DIALOG_TYPE,
    id: title,
    props: ImmutableMap(mkFormDialogProps({
      title,
      content,
      label,
      password: true,
      buttons,
      onClose,
      type: 'formDialog',
      titleIcon: iconsOnDialogAlerts ? getDialogIcon(titleIcon) : null,
    })),
  });
  log.log('Creating Dialog...', dialogData.toJS());
  return dialogData;
};

export const dialogForm = (title, content, label, onClose, buttons = ['No', 'Yes'], titleIcon, iconsOnDialogAlerts) => {
  const dialogData = mkRootUiData({
    type: FORM_DIALOG_TYPE,
    id: title,
    props: ImmutableMap(mkFormDialogProps({
      title,
      content,
      label,
      buttons,
      onClose,
      type: 'formDialog',
      titleIcon: iconsOnDialogAlerts ? getDialogIcon(titleIcon) : null,
    })),
  });
  log.log('Creating Dialog...', dialogData.toJS());
  return dialogData;
};

