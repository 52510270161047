// @flow
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { List } from 'immutable';
import forge from 'node-forge';
import _ from 'lodash';

import { getEnvironmentConfig, crashReporterInterface, getLogger } from '@quicken-com/react.utils.core';
import type { Account } from '@quicken-com/react.flux.accounts';
import { accountsSelectors } from '@quicken-com/react.flux.accounts';

import * as institutionLoginsActions from 'data/institutionLogins/actions';
import { getInstitutionLoginForId } from 'data/institutionLogins/selectors';
import { transformMfaResponseToMfaChallenge, transformResponseToRefreshStatus } from 'data/institutionLogins/transformers';
import type { InstitutionLogin } from 'data/institutionLogins/types';

import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';
import * as institutionsActions from 'data/institutions/actions';
import { getAggregatorFromChannel } from 'data/institutions/utils';

import { cpMetaForResponseAction, cpMetaForEmbeddedAction } from 'utils/actionHelpers';
import AxiosFactory from 'utils/axiosFactory';

import * as actions from './actions';
import * as selectors from './selectors';
import * as transformers from './transformers';
import * as types from './types';

const log = getLogger('components/Dialogs/AccountDiscovery/sagas.js');
const qcsAxios = AxiosFactory.get('qcs');

// poll every 3 seconds up to a total of 3 minutes and 30 seconds (seconds are buffer over QCS timeouts)
const REFRESH_POLL_INTERVAL = 3;
const REFRESH_POLL_ATTEMPTS = 210 / REFRESH_POLL_INTERVAL;

export function* accountDiscovery(action): Generator<*, *, *> {
  const { payload: { institutionLogin }, meta } = action;
  const { resolve, reject, scope } = meta;

  let resolvedInstitutionLogin = institutionLogin;
  let institutionLoginId = institutionLogin.id !== '0' ? institutionLogin.id : null;

  let requestUrl = `${getEnvironmentConfig().services_url}/institution-logins`;
  if (institutionLoginId) {
    requestUrl += `/${institutionLoginId}`;
  }
  let requestBody = transformers.transformInstitutionLoginToReqestData(institutionLogin);

  let isSecure = true;
  const fiCredentialEncryption = yield select(featureFlagsSelectors.getFeatureFlag, 'fiCredentialEncryption');
  if (fiCredentialEncryption) {
    const institution = yield select(selectors.getInstitution, { scope });
    const channelData = yield select(selectors.getInstitutionChannelData, { scope });
    if (channelData.authType === 'CREDENTIAL_AUTH') {
      try { // get public key: dig -t TXT services-bld.quickencs.com +short
        assert(getEnvironmentConfig().signature_public_key, 'public key for signature not found #encrypt');
        const signaturePublicKey = forge.pki.publicKeyFromPem(`-----BEGIN PUBLIC KEY-----${getEnvironmentConfig().signature_public_key}-----END PUBLIC KEY-----`);
        // verify form signature 'displayOrder:cpId:encrypt;displayOrder:cpId:encrypt'
        if (assert(channelData, 'can not verify form signature: aggregator not found #encrypt')
          && assert(channelData.formSignatureAlgorithm === 'SHA256withRSA', 'unexpected form signature algorithm #encrypt')
          && assert(signaturePublicKey, 'public key for form signature not found #encrypt')
        ) {
          const formData = channelData.formFields.map((formField) => `${formField.displayOrder}:${formField.key}:${formField.encrypt}`).join(';');
          const dataToVerify = forge.md.sha256.create().update(formData, 'utf8');
          const verify = signaturePublicKey.verify(
            dataToVerify.digest().bytes(),
            forge.util.decode64(channelData.formSignature),
          );
          assert(verify, 'form signature verification failed #encrypt');
          isSecure &= verify;
        } else {
          isSecure = false;
        }

        // verify encryption key signature
        const { formEncryption } = channelData;
        if (assert(formEncryption.signatureAlgorithm === 'SHA256withRSA', 'unexpected encryption signature algorithm #encrypt')
          && assert(signaturePublicKey, 'public key for signature not found #encrypt')
        ) {
          const dataToVerify = forge.md.sha256.create().update(formEncryption.publicKey, 'utf8');
          const verify = signaturePublicKey.verify(
            dataToVerify.digest().bytes(),
            forge.util.decode64(formEncryption.signature),
          );
          assert(verify, 'encryption key signature verification failed #encrypt');
          isSecure &= verify;
        } else {
          isSecure = false;
        }

        if (assert(formEncryption?.algorithm === 'RSA', `unexpected encryption algorithm "${formEncryption?.algorithm}" #encrypt`)
          && assert(formEncryption?.publicKey, 'encryption key not found #encrypt')
        ) {
          requestBody.credentials = requestBody.credentials?.map((field) => {
            const institutionFormField = channelData.formFields.find((formField) => formField.key === field.key);
            if (institutionFormField?.encrypt) {
              const pem = `-----BEGIN PUBLIC KEY-----${formEncryption.publicKey}-----END PUBLIC KEY-----`;
              const publicKey = forge.pki.publicKeyFromPem(pem);
              const encryptedValue = publicKey.encrypt(forge.util.encodeUtf8(field.value));
              const value = btoa(encryptedValue);
              return {
                ...field,
                encrypted: true,
                encryptionKeyId: formEncryption.keyId,
                value,
              };
            }
            return field;
          });
        } else {
          isSecure = false;
        }
      } catch (error) {
        assert(false, `${String(error)} #encrypt`);
        crashReporterInterface.reportError(error, (event) => event.addMetadata('custom', {
          institutionId: institutionLogin.institutionId,
          institution,
          aggregator: institution.aggregator,
        }));
        isSecure = false;
      }
    }
    if (!isSecure) {
      crashReporterInterface.reportError(Error('encryption failed (details)'), (event) => event.addMetadata('custom', {
        institutionId: institutionLogin.institutionId,
        institution,
        aggregator: institution.aggregator,
      }));

      const error = new types.AccountDiscoveryFailure('Unable to encrypt credentials', 'AD_UNABLE_TO_ENCRYPT_CREDENTIALS', 'vs.100');
      yield put(actions.accountDiscoveryError({ error, institutionLogin: resolvedInstitutionLogin }, cpMetaForResponseAction(meta)));
      yield call(reject, { error });
      const aggregator = getAggregatorFromChannel(institution.channel);
      yield put(institutionsActions.getInstitution(
        { id: institutionLogin.institutionId },
        cpMetaForEmbeddedAction(_.merge(meta, { axiosConfig: { params: { aggregator } } }))
      ));
      return;
    }
  }

  try {
    const httpMethod = institutionLoginId ? qcsAxios.put : qcsAxios.post;
    const response = yield call(httpMethod, requestUrl, requestBody);
    log.log('Upserted institition-login, response=', response.data);

    if (!institutionLoginId) {
      institutionLoginId = response.data.id;
      resolvedInstitutionLogin = resolvedInstitutionLogin.set('id', institutionLoginId);
    }
  } catch (exceptionError) {
    log.log('Received error', exceptionError, exceptionError.response);
    let error = exceptionError;
    if (error.response) {
      if (!institutionLoginId && error.response.status === 409 && error.response.data.errors[0].extData.attributeName === 'Credentials') {
        log.debug('Received 409 response, turning request into update');

        // Server detected that we are creating a login in which we already have one for the same username; so,
        // until server does it, we will redo requesting after changing to an update instead of create
        try {
          institutionLoginId = error.response.data.errors[0].extData.conflictId;
          const conflictingInstitutionLogin = yield select(getInstitutionLoginForId, institutionLoginId);

          // here we are swapping out institutionLogin to the conflicting institutionLogin
          //
          resolvedInstitutionLogin = conflictingInstitutionLogin.merge({
            credentials: institutionLogin.credentials,
          });

          requestUrl = `${getEnvironmentConfig().services_url}/institution-logins`;
          requestUrl += `/${institutionLoginId}`;
          requestBody = transformers.transformInstitutionLoginToReqestData(resolvedInstitutionLogin);

          const response = yield call(qcsAxios.put, requestUrl, requestBody);
          log.log('Upserted conflicting institution-login, response=', response.data);

          // clear error since put was sucessful
          error = null;
        } catch (exceptionError2) {
          error = exceptionError2;
        }
      }
    }

    if (error) {
      log.log('Error upserting institution-login, error=', error);

      yield put(actions.accountDiscoveryCredentialsFormSubmitResponse(error, cpMetaForResponseAction(meta)));

      yield put(actions.accountDiscoveryError({ error, institutionLogin: resolvedInstitutionLogin }, cpMetaForResponseAction(meta)));
      yield call(reject, { institutionLogin: resolvedInstitutionLogin, error });

      // if in error, don't continue
      return;
    }
  }

  yield put(actions.accountDiscoveryCredentialsFormSubmitResponse({ institutionLogin: resolvedInstitutionLogin }, cpMetaForResponseAction(meta)));
  yield call(resolve, resolvedInstitutionLogin);
  yield put(institutionLoginsActions.getInstitutionLogins());

  // make flow happy
  institutionLoginId = institutionLoginId || '0';

  // poll until institution-login is ready or cancel action
  requestUrl = `${getEnvironmentConfig().services_url}/institution-logins/${institutionLoginId}/poll`;
  yield* poll(requestUrl, institutionLoginId, meta);
}

export function* submitMfaResponses({ payload: { mfaResponse }, meta }: {payload: any, meta: any}) : Generator<*, *, *> {
  log.log('Submitting MFA Response for Account Discovery', mfaResponse);
  const { resolve, reject } = meta;

  let requestUrl = `${getEnvironmentConfig().services_url}${mfaResponse.submitUrl}`;
  const requestBody = {
    institutionLoginId: mfaResponse.institutionLoginId,
    mfaResponses: mfaResponse.answers.map((answer) => ({
      key: answer.id,
      response: answer.value,
    })),
  };

  try {
    yield call(qcsAxios.post, requestUrl, requestBody);

    yield put(actions.accountDiscoveryMfaFormSubmitResponse({}, cpMetaForResponseAction(meta)));
    yield call(resolve, mfaResponse.institutionLoginId);

  } catch (error) {
    log.error('Received error', error);

    yield put(actions.accountDiscoveryMfaFormSubmitResponse(error, cpMetaForResponseAction(meta)));
    yield call(reject, { error });

    // if in error, don't continue
    return;
  }

  // poll until institution-login is ready or cancel action
  requestUrl = `${getEnvironmentConfig().services_url}/institution-logins/${mfaResponse.institutionLoginId}/poll`;
  yield* poll(requestUrl, mfaResponse.institutionLoginId, meta);
}

function* poll(url, institutionLoginId, meta) {
  let response;
  let timeout = REFRESH_POLL_ATTEMPTS;
  while (timeout > 0) {
    try {
      log.log(`Number of times left to poll is ${timeout} #account-discovery`);

      response = yield call(qcsAxios.get, url);
      log.debug('Polling upserted institition-logins, response=', response.data);

      const adStatus = transformers.transformResponseToAccountDiscoveryStatus(response);
      yield put(actions.accountDiscoveryStatus({ status: adStatus }, cpMetaForResponseAction(meta)));

      const { data } = response;
      if (data.status === 'ACTION_REQUIRED') {
        const { aggregators } = response.data;
        if (aggregators.length !== 1) {
          throw new Error('Unsupported number of aggregators when extracting MFA Challenge');
        }
        const aggregator = aggregators[0];
        if (aggregator.aggStatus !== 'MFA_CHALLENGE_READY_FOR_USER') {
          throw new Error('Trying to transform response to MFA Challenge when aggStatus != MFA_CHALLENGE_READY_FOR_USER');
        }

        // todo: this should really come from QCS
        const submitUrl = `/institution-logins/${data.institutionLoginId}/mfa-response`;

        const mfaChallenge = transformMfaResponseToMfaChallenge(
          data.institutionLoginId, aggregator.mfaChallenges, submitUrl
        );
        yield put(actions.accountDiscoveryMfaRequired({ mfaChallenge }, cpMetaForResponseAction(meta)));
      }

      if (!data.isProcessing) {

        // if QCS detects we are attempting to modify existing login, switch it to the one QCS detected
        if (data.existingInstitutionLoginId) {
          // eslint-disable-next-line no-param-reassign
          institutionLoginId = data.existingInstitutionLoginId;
        }

        const existingInstitutionLogin = yield select(getInstitutionLoginForId, institutionLoginId);
        assert(existingInstitutionLogin, `institution login with id = ${institutionLoginId} not found`);
        if (data.status === 'OK') {
          if (data.mode === 'UPDATE_CREDENTIALS') {
            yield put(actions.accountDiscoverySuccess({ institutionLogin: existingInstitutionLogin }, cpMetaForResponseAction(meta)));
          } else {
            const allAccountsById = yield select(accountsSelectors.getAccountsById);
            const accountDiscoveryData = transformers.transformResponseToAccountDiscoveryData(data, allAccountsById);
            yield put(actions.accountDiscoverySuccessWithAccounts({ institutionLogin: existingInstitutionLogin, accountDiscoveryData }, cpMetaForResponseAction(meta)));
          }
        } else if (data.status !== 'ACTION_REQUIRED') {
          log.log('Status is in another terminated state', data.status);
          yield put(actions.accountDiscoveryFailure({ adStatus, institutionLogin: existingInstitutionLogin }, cpMetaForResponseAction(meta)));
        } else {
          yield put(actions.accountDiscoveryStopped({ adStatus, institutionLogin: existingInstitutionLogin }, cpMetaForResponseAction(meta)));
        }

        // server is done processing so stop polling
        return;
      }

      timeout -= 1;
      yield delay(REFRESH_POLL_INTERVAL * 1000);

    } catch (error) {
      assert(false, error);
      // TODO: I believe this may cause an exception, institionLogin likely doesn't exist in our Redux store for new Add-FI
      const institutionLogin = yield select(getInstitutionLoginForId, institutionLoginId);
      yield put(actions.accountDiscoveryError({ error, institutionLogin }, cpMetaForResponseAction(meta)));
      return;
    }
  }

  // time-out
  log.log('Time-out #account-discovery');
  const institutionLogin = yield select(getInstitutionLoginForId, institutionLoginId);
  const error = new types.AccountDiscoveryFailure('Account Discovery Timeout', 'AD_TIMEOUT', 'to.100');

  yield put(actions.accountDiscoveryError({ error, institutionLogin }, cpMetaForResponseAction(meta)));
}

// =================================================================================================
// getPartnerAuthUri
// =================================================================================================

export function* getPartnerAuthUris({ payload, meta }): Generator<*, *, *> {
  log.debug('getPartnerAuthUris called...', payload);

  const { partnerAuth, redirectUri } = payload;
  const { intuitProperty, partnerUid } = partnerAuth;

  const requestUrl =
    `${getEnvironmentConfig().services_url}/intuit-partnerauth?intuit_property=${intuitProperty}&partner_uid=${partnerUid}&offering_redirect_uri=${redirectUri}`;

  try {
    const response = yield call(qcsAxios.get, requestUrl);
    log.debug('Retrieved partnerAuthUris, response=', response);

    const partnerAuthUris = transformers.transformResponseToPartnerAuthUris(response);
    log.debug('getPartnerAuthUris response after transform=', partnerAuthUris);

    yield put(actions.getPartnerAuthUrisSuccess({ partnerAuthUris }, cpMetaForResponseAction(meta)));
  } catch (error) {
    log.debug('Error retrieving partnerAuthUris', error);
    yield put(actions.getPartnerAuthUrisFailure({ error }, cpMetaForResponseAction(meta)));
  }
}

function transformAddInstitutionLoginAndAccountsPayloadWithResponse(
  institutionLogin: InstitutionLogin,
  accounts: List<Account>,
  response: any,
) {
  const updatedInstitutionLogin = institutionLogin.set('id', response.data.id);

  const { correlationIds } = response.data;
  const updatedAccounts = accounts.map((account) => {
    const accountId = correlationIds[account.clientId];
    return account.merge({
      id: accountId || account.id,
      institutionLoginId: updatedInstitutionLogin.id,
      isDeleted: account.isDeleted || account.isIgnored,
    });
  });
  return [updatedInstitutionLogin, updatedAccounts];
}

// =================================================================================================
// addInstitutionLoginAndAccounts
// =================================================================================================
export function* addInstitutionLoginAndAccounts({ payload: { institutionLogin, accounts }, meta }: { payload: any, meta: any }): Generator<*, *, *> {
  log.log('Creating InstitutionLogin with accounts', institutionLogin, accounts);

  const requestUrl = `${getEnvironmentConfig().services_url}/institution-logins`;
  const requestBody = transformers.transformInstitutionLoginToReqestData(institutionLogin, accounts);

  let institutionLoginId = null;
  let pollingUrl = null;

  try {
    const response = yield call(qcsAxios.post, requestUrl, requestBody);
    log.debug('Created institition-login and added accounts, response=', response.data);

    const [updatedInstitutionLogin, updatedAccounts] =
      transformAddInstitutionLoginAndAccountsPayloadWithResponse(institutionLogin, accounts, response);
    log.debug('Transformed values', updatedInstitutionLogin, updatedAccounts);

    institutionLoginId = updatedInstitutionLogin.id;
    pollingUrl = `${getEnvironmentConfig().services_url}${response.data.pollingReference}`;
    yield put(institutionLoginsActions.addInstitutionLoginAndAccountsResponse({ institutionLogin: updatedInstitutionLogin, accounts: updatedAccounts }, cpMetaForResponseAction(meta)));
  } catch (error) {
    log.log('Error adding institution-login and accounts, error=', error);
    yield put(institutionLoginsActions.addInstitutionLoginAndAccountsResponse(error, cpMetaForResponseAction(meta)));

    // if in error, don't continue
    return;
  }

  yield* doAddInstitutionLoginAndAccountsPolling(pollingUrl, institutionLoginId, meta);
}

function* doAddInstitutionLoginAndAccountsPolling(url, institutionLoginId, meta): Generator<*, *, *> {
  let numTimesLeftToPoll = REFRESH_POLL_ATTEMPTS;
  while (numTimesLeftToPoll > 0) {
    try {
      const response = yield call(qcsAxios.get, url);
      log.log('Polling for refresh status', response.data);

      const refreshStatus = transformResponseToRefreshStatus(response.data);
      log.log('Status after transforming...', refreshStatus);

      // send refresh status
      yield put(institutionLoginsActions.refreshAccountsStatuses(refreshStatus));

      if (!response.data.isProcessing) {
        yield put(institutionLoginsActions.refreshAccountsCompleted({ institutionLoginId, status: 'DONE_PROCESSING' }));

        // since we are done processing, stop polling
        break;
      }

      numTimesLeftToPoll -= 1;
      log.log(`Number of times left to poll is ${numTimesLeftToPoll} #add-account`);
      yield delay(REFRESH_POLL_INTERVAL * 1000);
    } catch (error) {
      log.log('How did we get here...', error);
      yield put(institutionLoginsActions.refreshAccountsCompleted({ institutionLoginId, status: 'FAILED_WHEN_POLLING' }, cpMetaForResponseAction(meta)));
      break;
    }
  }

  if (numTimesLeftToPoll === 0) {
    yield put(institutionLoginsActions.refreshAccountsCompleted({ institutionLoginId, status: 'TIMEOUT_WHILE_PROCESSING' }, cpMetaForResponseAction(meta)));
  }
}

// =================================================================================================
// getAggregationInfo
//
// Retrieves aggregation-info required for some aggregators. For example,
//   - Finicity
//     - Requires Connect URI
//     - Requires Polling Reference Used to Retrieve Discovered Accounts
//   - Plaid
//     - Requires Plaid Link Token
// =================================================================================================
export function* getAggregationInfo({ payload: { institution, channel, institutionLogin }, meta }): Generator<*, *, *> {
  log.debug('getAggregationInfo called...', institution, channel, institutionLogin);
  const { resolve, reject } = meta;

  let requestUrlParams = institutionLogin ? `/${institutionLogin.id}?channel=${channel}` : `?channel=${channel}`;
  if (institution) {
    requestUrlParams += `&institutionId=${institution.id}`;
  }
  const requestUrl = `${getEnvironmentConfig().services_url}/institutions/aggregation-info${requestUrlParams}`;

  try {
    const response = yield call(qcsAxios.get, requestUrl);
    const transformedResponse = transformers.transformResponseToAggregationInfo(response);
    log.debug('Retrieved aggregationInfo', transformedResponse, response);

    yield call(resolve, transformedResponse);
  } catch (error) {
    log.debug('Error retrieving aggregation info', error);
    yield call(reject, error);
  }
}

// =================================================================================================
// discoverInstitutionLoginWithAccounts
//
// This is used to build a discover response from an institution login and accounts.
// =================================================================================================
export function* discoverInstitutionLoginWithAccounts({ payload: { institutionLogin, accounts }, meta }: { payload: any, meta: any }): Generator<*, *, *> {
  log.log('Discovering institition-login with accounts', institutionLogin, accounts);
  const { resolve, reject } = meta;

  const requestUrl = `${getEnvironmentConfig().services_url}/institution-logins/discover`;
  const requestBody = transformers.transformInstitutionLoginToReqestData(institutionLogin, accounts);

  try {
    const response = yield call(qcsAxios.post, requestUrl, requestBody);
    const institutionLoginToUpsert = response.data.existingInstitutionLoginId && response.data.existingInstitutionLoginId !== institutionLogin.id
      ? yield select(getInstitutionLoginForId, response.data.existingInstitutionLoginId)
      : institutionLogin;

    const allAccountsById = yield select(accountsSelectors.getAccountsById);
    const accountDiscoveryData = transformers.transformResponseToAccountDiscoveryData(response.data, allAccountsById);

    log.debug('Transformed values', institutionLoginToUpsert, accountDiscoveryData);

    yield call(resolve, { institutionLogin: institutionLoginToUpsert, accountDiscoveryData });
  } catch (error) {
    log.debug('Error discovering institution-login with accounts, error=', error);
    yield call(reject, error);
  }
}

// =================================================================================================
// discoverInstitutionLoginWithAccountsByPolling
//
// This is used to build a discover response from an institution login and accounts.
// =================================================================================================
export function* discoverInstitutionLoginWithAccountsByPolling({ payload: { institutionLogin, pollingRef }, meta }: { payload: any, meta: any }): Generator<*, *, *> {
  log.log('Discovering institition-login with accounts by polling', institutionLogin, pollingRef);
  const { resolve, reject } = meta;

  const url = `${getEnvironmentConfig().services_url}/${pollingRef}`;

  let timeout = REFRESH_POLL_ATTEMPTS;
  while (timeout > 0) {
    try {
      const response = yield call(qcsAxios.get, url);
      log.log('Polling for discovered accounts', response.data);

      const { data } = response;
      if (!data.isProcessing) {
        const institutionLoginToUpsert = response.data.existingInstitutionLoginId && response.data.existingInstitutionLoginId !== institutionLogin.id
          ? yield select(getInstitutionLoginForId, response.data.existingInstitutionLoginId)
          : transformers.mergeDiscoveryAggregatorsWithInstitutionLogin(response.data.aggregators, institutionLogin);

        const allAccountsById = yield select(accountsSelectors.getAccountsById);
        const accountDiscoveryData = transformers.transformResponseToAccountDiscoveryData(response.data, allAccountsById);

        log.debug('Transformed values', institutionLoginToUpsert, accountDiscoveryData);
        yield call(resolve, { institutionLogin: institutionLoginToUpsert, accountDiscoveryData });

        // server is done processing so stop polling
        return;
      }

      timeout -= 1;
      yield delay(REFRESH_POLL_INTERVAL * 1000);
    } catch (error) {
      log.debug('Error discovering institution-login with accounts, error=', error);
      yield call(reject, error);
      return;
    }
  }
}


// =================================================================================================
// logPlaidItemEvent
// =================================================================================================
export function* logPlaidItemEvent({ payload: { institutionLogin, event }, meta }: { payload: any, meta: any }): Generator<*, *, *> {
  log.info('Logging Plaid event', institutionLogin, event);

  const requestUrl = `${getEnvironmentConfig().services_url}/institution-logins/log-user-activity`;
  const requestBody = transformers.transformPlaidItemActivityToReqestData(institutionLogin, event);

  try {
    yield call(qcsAxios.post, requestUrl, requestBody);
    yield put(actions.logPlaidItemEventResponse(null, cpMetaForResponseAction(meta)));
  } catch (error) {
    log.error('Error logging Plaid activity, error=', error);
    yield put(actions.logPlaidItemEventResponse(error, cpMetaForResponseAction(meta)));
  }
}

export function* accountDiscoveryCredentialsFormSubmitWatcher(): Generator<*, *, *> {
  yield takeLatest(actions.accountDiscoveryCredentialsFormSubmit, accountDiscovery);
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* accountDiscoveryMfaFormWatcher(): Generator<*, *, *> {
  yield takeLatest(actions.accountDiscoveryMfaFormSubmit, submitMfaResponses);
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* getPartnerAuthUrisWatcher(): Generator<*, *, *> {
  yield takeLatest(actions.getPartnerAuthUris, getPartnerAuthUris);
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* addInstitutionLoginsAndAccountsWatcher(): Generator<*, *, *> {
  yield takeLatest(institutionLoginsActions.addInstitutionLoginAndAccounts, addInstitutionLoginAndAccounts);
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* getAggregationInfoWatcher(): Generator<*, *, *> {
  yield takeLatest(actions.getAggregationInfo, getAggregationInfo);
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* discoverInstitutionLoginWithAccountsWatcher(): Generator<*, *, *> {
  yield takeLatest(actions.discoverInstitutionLoginWithAccounts, discoverInstitutionLoginWithAccounts);
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* discoverInstitutionLoginWithAccountsByPollingWatcher(): Generator<*, *, *> {
  yield takeLatest(actions.discoverInstitutionLoginWithAccountsByPolling, discoverInstitutionLoginWithAccountsByPolling);
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* logPlaidItemActivityWatcher(): Generator<*, *, *> {
  yield takeLatest(actions.logPlaidItemEvent, logPlaidItemEvent);
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

// Load all watcher sagas
export default [
  accountDiscoveryCredentialsFormSubmitWatcher,
  accountDiscoveryMfaFormWatcher,
  getPartnerAuthUrisWatcher,
  addInstitutionLoginsAndAccountsWatcher,
  getAggregationInfoWatcher,
  discoverInstitutionLoginWithAccountsWatcher,
  discoverInstitutionLoginWithAccountsByPollingWatcher,
  logPlaidItemActivityWatcher,
];
