import React, { PureComponent } from 'react';
import ReactDatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DateTime } from 'luxon';

import { isAcme } from 'isAcme';

import withStyles from '@mui/styles/withStyles';
import Input from '@mui/material/Input';
import ButtonBase from '@mui/material/ButtonBase';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { copyObject } from 'utils/utils';
import 'react-datepicker/dist/react-datepicker.css';
import QTypography from 'components/MUIWrappers/QTypography';
import { DatePickerHolder } from './styledComponents';
import QIconButton from '../../QIconButton';


const styles = ({ palette, shape, shadows }) => ({
  textField: {
    marginLeft: 0,
    marginRight: 0,
    width: 'inherit',
    '& input': {
      fontSize: 14,
      width: 100,
    },
  },
  regInput: {
    color: 'black',
    paddingTop: 0,
    marginTop: '0px',
    width: '100%',
    height: '20px',
    '& input': {
      padding: '0px',
      paddingBottom: '2px',
      fontSize: '13px',
      '&::-ms-clear ': {
        display: 'none',
      },
    },
  },
  dateButtonStyle: {
    padding: 0,
    height: 25,
    textTransform: 'unset',
  },
  dateButtonLabelStyle: {
    color: isAcme ? palette.text.primary : palette.common.black,
    fontWeight: 400,
  },
  datePickerContainer: {
    borderRadius: shape.padding[1],
    border: 'none',
    boxShadow: shadows[4],
    '& .react-datepicker__header,.react-datepicker__triangle': {
      backgroundColor: `${palette.grey.level1} !important`,
      border: 'none',
    },
    '& .react-datepicker__current-month': {
      fontSize: '18px !important',
      color: `${palette.text.primary} !important`,
      fontWeight: 400,
      padding: '5px 0px 10px 0px',
    },
    '& .react-datepicker__navigation': {
      top: 20,
    },
    '& .react-datepicker__day:hover': {
      backgroundColor: `${palette.background.primary} !important`,
    },
    '& .react-datepicker__day,.react-datepicker__day-name': {
      margin: 5,
    },
    '& .react-datepicker__day--outside-month': {
      color: palette.text.secondary,
    },
  },
});

export class DateField extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      date: props.value ? DateTime.fromISO(props.value).toJSDate() : null,
    };

    this.handleTxDateChange = this.handleTxDateChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  onDateOnKeyDownChange = (e) => {
    if (this.props.changeDateOnKeyDown && e.target.value && e.target.value.length >= 6) { // At least `M/D/YY` (length 6)
      const newDate = DateTime.fromFormat(e.target.value, 'D');
      if (newDate.isValid) {
        this.handleTxDateChange(newDate.toJSDate());
      }
    }
  };

  onKeyDown = (e) => {

    const nodes = document.getElementsByClassName('QdatePopper');
    if (['Enter', 'Tab'].includes(e.key) && nodes.length > 0) {
      // Accepts valid dates on Enter/Tab key down when changeDateOnKeyDown is ON
      this.onDateOnKeyDownChange(e);
      e.stopPropagation();
    }

    if (this.props.onKeyDown) {
      this.props.onKeyDown(e, this.props.fieldId);
    }
  };

  setInputRef = (el) => {
    this.textInput = el;
    if (this.props.inputRef) {
      this.props.inputRef(el);
    }
  };

  //------------------------------------------------------
  // handleTxDateChange
  // specific handler for ReactDatePicker
  // converts ReactDatePicker return of a date value into a target.value
  // for standard handling
  // this is needed because the ReactDatePicker component does NOT
  // preserve properties, and returns just the date value to onChange

  handleTxDateChange = (e) => {
    if (this.props.todaysDateIfWrong && !e) {
      return;
    }

    this.setState({ date: e });

    if (e) {
      e.target = this.createTargetFromProps();
      e.target.value = DateTime.fromJSDate(e).toISODate();
      if (this.props.onChange) {
        this.props.onChange(e, this.props.fieldId);
      }
    }
  };

  handleFocus = (e) => {
    if (!this.props.disableKeyboardInput) e.target.select();
  };

  //--------------------------------------------------------
  // createTargetFromProps
  //
  // Creates a "data" property if there is one or more
  // data-* properties in this.props (user set data)
  //

  createTargetFromProps = () => {
    const target = copyObject(this.props);
    target.dataset = {};

    Object.keys(this.props).forEach((key) => {
      const p1 = key.slice(0, 5);
      const p2 = key.slice(5);
      if (p1 === 'data-') {
        target.dataset[p2] = this.props[key];
      }
    });
    return target;
  };

  onBlur = (e) => {
    if (this.props.todaysDateIfWrong && !DateTime.fromFormat(e.target.value, 'D').isValid) {
      this.handleTxDateChange(new Date());
    }

    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  renderCustomHeader = ({ decreaseMonth, increaseMonth, prevMonthDisabled, nextMonthDisabled, date }) => (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
      <QIconButton onClick={decreaseMonth} disabled={prevMonthDisabled}>
        <ArrowLeftIcon />
      </QIconButton>
      <QTypography>{DateTime.fromJSDate(date).toFormat('MMMM yyyy')}</QTypography>
      <QIconButton onClick={increaseMonth} disabled={nextMonthDisabled}>
        <ArrowRightIcon />
      </QIconButton>
    </div>);

  render() {
    const { classes, editable, value, clickable, className,
      dateFormat, id, disableKeyboardInput } = this.props;

    const customInput = !disableKeyboardInput ?
      (
        <Input
          disableUnderline={this.props.disableUnderline}
          className={classes.regInput}
          inputProps={{ style: this.props.customStyle }}
          inputRef={this.setInputRef}
        />
      ) : (
        <ButtonBase sx={{ fontSize: 14 }}>
          {DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED)}
        </ButtonBase>
      );

    if (editable) {
      return (
        <DatePickerHolder>
          <ReactDatePicker
            id={id}
            key={this.state.date}
            onChange={this.handleTxDateChange}
            selected={this.state.date}
            onFocus={this.handleFocus}
            autoFocus={this.props.autoFocus}
            className={classNames(disableKeyboardInput ? '' : classes.regInput, className)}
            onBlur={this.onBlur}
            popperPlacement="right-start"
            popperClassName="QdatePopper"
            onKeyDown={this.onKeyDown}
            popperModifiers={{
              preventOverflow: {
                enabled: true,
                escapeWithReference: true, // force popper to stay in viewport (even when input is scrolled out of view)
                boundariesElement: 'scrollParent',
                priority: ['bottom', 'top', 'left', 'right'],
              },
            }}
            dateFormat={dateFormat}
            customInput={customInput}
            calendarClassName={classes.datePickerContainer}
            renderCustomHeader={this.renderCustomHeader}
          />
        </DatePickerHolder>
      );
    }

    return disableKeyboardInput ? customInput : (
      <QTypography
        className={this.props.className}
        style={this.props.customStyle}
        thinFont
        clickable={clickable}
      >
        {DateTime.fromISO(value).toFormat(dateFormat || 'MM/dd/yyyy')}
      </QTypography>);
  }
}

DateField.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  fieldId: PropTypes.string,
  editable: PropTypes.bool,
  autoFocus: PropTypes.bool,
  clickable: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  inputRef: PropTypes.func,
  onKeyDown: PropTypes.func,
  classes: PropTypes.object,
  customStyle: PropTypes.object,
  disableUnderline: PropTypes.bool,
  dateFormat: PropTypes.string,
  disableKeyboardInput: PropTypes.bool,
  onBlur: PropTypes.func,
  todaysDateIfWrong: PropTypes.bool,
  changeDateOnKeyDown: PropTypes.bool,
};

export default withStyles(styles)(DateField);
