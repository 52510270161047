import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { OrderedMap } from 'immutable';
import { v4 as uuidv4 } from 'uuid';

import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';

import SnackbarContent from '@mui/material/SnackbarContent';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { platform, tracker } from '@quicken-com/react.utils.core';
import { addNotifications, removeNotifications } from 'data/notifications/notificationsActions';
import { mkNotification } from 'data/notifications/notificationsTypes';

const browserName = platform.browser?.name;
const browserVersionString = platform.browser?.version;
// const browserVersionString = '80.1.2.3'; // simulate outdated browser
const browserVersion = browserVersionString?.split?.('.');

const BrowserMinVersion = () => {
  const dispatch = useDispatch();
  const browsersMinVersion = useSelector((state) => featureFlagsSelectors.getFeatureFlag(state, 'browserMinVersion'))?.get(browserName);
  const updateRequired = useMemo(() => browsersMinVersion
    ?.split?.('.')
    ?.some((minVersion, index) => browserVersion[index] < minVersion), [browsersMinVersion]);
  useEffect(() => {
    if (updateRequired) {
      const id = uuidv4();
      tracker.track(tracker.events.browserUpdate, {
        browser_name: browserName,
        browser_version: browserVersionString,
        browsers_min_version: browsersMinVersion,
      });
      dispatch(addNotifications(OrderedMap({
        [id]: mkNotification({
          id,
          autoHideDuration: 20000, // ms
          snackbarContent: SnackbarContent,
          snackbarContentProps: {
            message: `Your web browser (${browserName} ${browserVersion}) is out of date - update your browser for the best experience.`,
            action: (
              <IconButton
                size="small"
                aria-label="dismiss"
                id="outdated-browser-dismiss"
                color="inherit"
                onClick={() => dispatch(removeNotifications([id]))}
              >
                <CloseIcon />
              </IconButton>
            ),
          },
        }),
      })));
    }
  }, [updateRequired, dispatch, browsersMinVersion]);

  return null;
};

export default BrowserMinVersion;


