//
// SELECTORS, these are functions designed to work directly on and with the
// redux data store
//

import createCachedSelector, { LruCacheObject } from 're-reselect';
import { List as ImmutableList } from 'immutable';

import { getLogger } from '@quicken-com/react.utils.core';
import { getHoldingListForAccountIds } from 'data/investmentHoldingList/selectors';


const log = getLogger('data/investmentHoldingList/selectors.js');

log.log('loaded');

export const getSecurityListForAccountIds = createCachedSelector(
  getHoldingListForAccountIds,
  (acctHoldings) => {
    let securityList = ImmutableList();
    acctHoldings.forEach((holding) => {
      securityList = securityList.concat(holding.security);
    });
    log.log(`Securities Count: ${securityList.size}`);
    return securityList;
  }

)(
  () => 'allSecurities', { cacheObject: new LruCacheObject({ cacheSize: 10 }) }
);
