/**
 *
 * QFilterSelect
 *
 */

// BASE

import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { accountsSelectors } from '@quicken-com/react.flux.accounts';
import { tagsSelectors } from '@quicken-com/react.flux.tags';
import { categoriesSelectors } from '@quicken-com/react.flux.categories';

// CUSTOM COMPONENTS
import QResourcePicker from 'components/QResourcePicker';
import QButton from 'components/QButton';
import ThreeStateOption from 'components/ThreeStateOption';
import QTextButton from 'components/QTextButton';
import QIconButton from 'components/QIconButton';

// SELECTORS, ACTIONS, UTILS
import { getPayeesNameList } from 'data/payees/selectors';
import { isAcme } from 'isAcme';

// MUI COMPONENTS
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ClearIcon from '@mui/icons-material/Clear';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';

// PATH RELATIVE IMPORTS
import { itemMenuConfig, countFilterItems, getNameFromKeyFactory } from './utils';
import { FilterObject } from './types';

// STYLES HOOK
import { styles } from './styles';
const useStyles = makeStyles(styles);

/*
 * QFilterSelect Component ***********************************
 */


// PROP TYPES
const propTypes = {
  onApply: PropTypes.func,
  initialFilter: PropTypes.object,
  // these used in popover mode
  popover: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
  popoverProps: PropTypes.object,
  openPopoverProps: PropTypes.object,
  allowFilterOut: PropTypes.bool,
  excludedResourceTypes: PropTypes.array,
  resourceFilterFn: PropTypes.func,
};

const SHOW_BILLS_SUBSCRIPTION_FILTER = false;

const QFilterSelect = (props) => {

  // PROPS ============================================================================
  const { onApply, initialFilter, popover, anchorEl, onClose, popoverProps,
    allowFilterOut, openPopoverProps, excludedResourceTypes, resourceFilterFn } = props;

  // STATE
  const [selectedItems, setSelectedItems] = useState(initialFilter || new FilterObject());
  const [currentFilterItem, setCurrentFilterItem] = useState(itemMenuConfig[0].resourceType);
  const [redrawId] = useState('id-qfilter-root');
  const numFilterItems = useMemo(() => countFilterItems(selectedItems) + selectedItems.get('payees').size, [selectedItems]);

  const categoriesById = useSelector(categoriesSelectors.getCategoriesById);
  const tagsById = useSelector(tagsSelectors.getTags);
  const accountsById = useSelector(accountsSelectors.getAccountsById);

  const payeesNameList = useSelector((state) => getPayeesNameList(state), shallowEqual);

  // EFFECTS ============================================================================
  useEffect(() => {
    if (!excludedResourceTypes) return;
    if (excludedResourceTypes.includes(currentFilterItem)) {
      const availableCurrent = itemMenuConfig.filter((item) => !excludedResourceTypes?.includes(item.resourceType));
      if (availableCurrent.length > 0) {
        setCurrentFilterItem(availableCurrent[0].resourceType);
      }
    }
  }, [excludedResourceTypes, currentFilterItem]);

  // STYLES ============================================================================
  const classes = useStyles(props);

  // INTERNAL FUNCTIONS ============================================================================

  const clearSelections = useCallback(() => {
    setSelectedItems(new FilterObject());
  }, []);


  const applyFilter = useCallback(() => {

    // add payee names from the returned ID's
    const payeeNames = selectedItems.payees
      .toList()
      .map((id) => payeesNameList.find((item) => item.id === id)?.name?.toLowerCase())
      .filter((payeeName) => assert(payeeName, 'payee not found'));
    let newItems = selectedItems.set('payeeNames', payeeNames);
    newItems = newItems.set('numFilterItems', countFilterItems(newItems) + newItems.get('payees').size);

    if (onApply) onApply(newItems);
  }, [onApply, selectedItems, payeesNameList]);

  // const filterSize = (item) => { // saving in case for future use
  //   const filter = selectedItems?.get(item.resourceType);
  //   if (filter && item.resourceType === 'advanced') {
  //     return ImmutableMap(filter.toObject()).reduce((v, x) => x === undefined ? v : v + 1, 0);
  //   }
  //   return filter?.size;
  // };

  const renderItemMenuListItems = () => {
    const nodes = [];
    itemMenuConfig.filter((item) => !excludedResourceTypes?.includes(item.resourceType)).forEach((item) => {
      const selClass = item.resourceType === currentFilterItem ? 'selected' : '';
      nodes.push(
        <ListItem
          key={`qfilter:${item.resourceType}`}
          button
          className={classNames(classes.itemMenu, selClass)}
          onClick={() => setCurrentFilterItem(item.resourceType)}
          id={item.id}
        >
          <ListItemText
            primary={item.title}
            primaryTypographyProps={{
              fontWeight: '500',
            }}
          />
        </ListItem>
      );
    });
    return nodes;
  };

  /*
   * Handle Select
   */
  const handleSelect = useCallback((items) => {
    setSelectedItems(selectedItems.set(currentFilterItem, items));
  }, [selectedItems, currentFilterItem]);

  const handleCheckSelect = useCallback(() => {
    setSelectedItems(selectedItems.set('filterOut', !selectedItems.filterOut));
  }, [selectedItems]);

  const openPopProps = currentFilterItem ? openPopoverProps : {};
  let wrapProps;
  let WrapComponent;
  if (popover) {
    WrapComponent = Popover;
    wrapProps = {
      classes: { root: classes.openPop, paper: classes.pop },
      id: redrawId,
      anchorEl,
      open: true,
      onClose,
      marginThreshold: 24,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      ...popoverProps,
      ...openPopProps,
    };
  } else {
    WrapComponent = React.Fragment;
    wrapProps = {
    };
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getNameFromItem = useCallback(getNameFromKeyFactory(categoriesById, payeesNameList, tagsById, accountsById),
    [categoriesById, payeesNameList, tagsById, accountsById]);

  const handleRemoveItem = useCallback((itemKey, item) => {
    let updatedObject;
    switch (itemKey) {
      case 'categories':
      case 'accounts':
      case 'tags':
      case 'payees':
        updatedObject = selectedItems.set(itemKey, selectedItems.get(itemKey).delete(item));
        if (itemKey === 'categories') {
          const childIds = categoriesById?.filter((cat) => cat?.parentId === item)?.keySeq()?.toArray() || [];
          if (childIds.length > 0) updatedObject = updatedObject.set('categories', updatedObject.get('categories').subtract(childIds));
        }
        break;
      case 'advanced':
        updatedObject = selectedItems.set('advanced', selectedItems.get('advanced').delete(item.key));
        break;
      default:
        updatedObject = selectedItems;
    }
    updatedObject = updatedObject.set('numFilterItems', countFilterItems(updatedObject) + updatedObject.get('payees').size);
    setSelectedItems(updatedObject);
  }, [selectedItems, categoriesById]);

  const advancedFilterItems = useMemo(() => {
    if (!selectedItems) return [];
    return Object.entries(selectedItems.advanced.toJS()) // break down the advanced object to entries
      .map(([key, val]) => val === undefined ? null : ({ key, val })) // convert it into list of objects with "key" and "value" props
      .filter((n) => n !== null); // filter out null values
  }, [selectedItems]);

  const openFilter = (currentFilterItem !== 'advanced') ? 'open' : 'advancedOpen';
  return (
    <WrapComponent
      {...wrapProps}
    >
      <div
        sharedcomponentid={'Q_FILTER_SELECT'}
        className={classes.root}
      >
        <div className={classes.content}>
          <div className={classes.panelLeft}>

            <div className={classes.header}>
              <Typography className={classes.title} variant="h6">
                Filters
              </Typography>
            </div>

            <List component="nav" aria-label="filter items">
              {renderItemMenuListItems()}
            </List>
            <div className={classes.filterOutContainer}>
              {allowFilterOut &&
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedItems.filterOut}
                      onChange={handleCheckSelect}
                      name="filterOutCheckBox"
                      color="primary"
                    />
                  }
                  label="Filter selected items out"
                />}
            </div>
          </div>
          <div className={classNames(classes.panelCenter, currentFilterItem ? openFilter : '')}>
            {currentFilterItem &&
              <div id="filter-select-slider" className={classNames(classes.slider, classes.slideAction)}>
                {(currentFilterItem !== 'advanced') ?
                  <QResourcePicker
                    autoFocus
                    noHeader
                    selectedItems={selectedItems.get(currentFilterItem)}
                    onSelect={handleSelect}
                    resourceType={currentFilterItem}
                    pageSize={12}
                    filterFn={resourceFilterFn}
                  />
                  :
                  <AdvancedPane
                    advancedItems={selectedItems.get(currentFilterItem)}
                    onChange={handleSelect}
                    classes={classes}
                  />}
              </div>}
          </div>
          <div className={classes.panelRight}>
            <Typography className={classes.panelRightHeader} variant={'caption'}>
              {`${numFilterItems > 0 ? numFilterItems : 'No'} filters selected`}
            </Typography>
            <FilterItemList
              title={'Categories'}
              getNameFromItem={getNameFromItem}
              items={selectedItems.get('categories')}
              itemKey={'categories'}
              onRemoveItem={handleRemoveItem}
            />
            <FilterItemList
              title={'Payees'}
              getNameFromItem={getNameFromItem}
              items={selectedItems.get('payees')}
              itemKey={'payees'}
              onRemoveItem={handleRemoveItem}
            />
            <FilterItemList
              title={'Tags'}
              getNameFromItem={getNameFromItem}
              items={selectedItems.get('tags')}
              itemKey={'tags'}
              onRemoveItem={handleRemoveItem}
            />
            <FilterItemList
              title={'Accounts'}
              getNameFromItem={getNameFromItem}
              items={selectedItems.get('accounts')}
              itemKey={'accounts'}
              onRemoveItem={handleRemoveItem}
            />
            <FilterItemList
              title={'Show only'}
              getNameFromItem={getNameFromItem}
              items={advancedFilterItems}
              itemKey={'advanced'}
              onRemoveItem={handleRemoveItem}
            />
          </div>
        </div>
        <div className={classes.footer}>
          <QTextButton
            title="Reset Filter"
            onClick={clearSelections}
            classes={{ root: classes.actionText }}
            id="reset_filter_button"
          />
          <div className={classes.flexRow}>
            <QButton
              variant="outlined"
              className={classes.button}
              onClick={onClose}
              id="cancel_filter_button"
            >
              Cancel
            </QButton>
            <div style={{ width: 20 }} />
            <QButton
              variant="contained"
              className={classes.button}
              onClick={applyFilter}
              id="apply_filter_button"
            >
              Apply
            </QButton>
          </div>
        </div>
      </div>
    </WrapComponent>
  );
};

QFilterSelect.propTypes = propTypes;

export default QFilterSelect;

const AdvancedPane = (props) => {

  const { advancedItems, onChange, classes } = props;

  const handleChange = (key, value) => {
    onChange(advancedItems.set(key, value));
  };

  const handleClearBillsAndSubscriptions = useCallback(() => {
    onChange(advancedItems.set('isBillOrSubscription', undefined));
  }, [onChange, advancedItems]);

  const handleClearTransactions = useCallback(() => {
    let updatedItems = advancedItems.set('isExcludedFromReports', undefined);
    updatedItems = updatedItems.set('isExcludedFromF2S', undefined);
    updatedItems = updatedItems.set('isReviewed', undefined);
    onChange(updatedItems);
  }, [advancedItems, onChange]);

  const showBillsClear = useMemo(() => advancedItems.get('isBillOrSubscription') !== undefined, [advancedItems]);
  const showTransactionsClear = useMemo(() => advancedItems.get('isExcludedFromReports') !== undefined ||
    advancedItems.get('isExcludedFromF2S') !== undefined || advancedItems.get('isReviewed') !== undefined, [advancedItems]);

  return (
    <div className={classes.advancedContainer}>
      <Typography className={classes.advancedHeader} variant={'caption'}>Show only</Typography>
      {SHOW_BILLS_SUBSCRIPTION_FILTER && 
      <div>
        <div className={classes.advancedSubtitle}>
          <Typography variant={'body1'}>Bills & Subscriptions</Typography>
          {showBillsClear &&
            <QTextButton
              className={classes.advancedClearButton}
              onClick={handleClearBillsAndSubscriptions}
              title={'CLEAR'}
            />}
        </div>
        <ThreeStateOption
          onChange={(val) => handleChange('isBillOrSubscription', val)}
          value={advancedItems.isBillOrSubscription}
          onOffText="Bills"
          idPrefix={'bills_and_subscriptions'}
        />
      </div>}
      <div style={{ height: 15 }} />
      <div className={classes.advancedSubtitle}>
        <Typography variant={'body1'}>Transactions</Typography>
        {showTransactionsClear &&
          <QTextButton
            className={classes.advancedClearButton}
            onClick={handleClearTransactions}
            title={'CLEAR'}
          />}
      </div>
      {isAcme &&
        <>
          <ThreeStateOption
            onChange={(val) => handleChange('isExcludedFromReports', val)}
            value={advancedItems.isExcludedFromReports}
            onOffText="Ignored in reports"
            idPrefix={'is_excluded_from_reports'}
          />
          <Divider />
          <ThreeStateOption
            onChange={(val) => handleChange('isExcludedFromF2S', val)}
            value={advancedItems.isExcludedFromF2S}
            onOffText="Ignored in spending plan"
            idPrefix={'is_excluded_from_free_to_spend'}
          />
          <Divider />
        </ >}
      <div className={classes.advancedGroup}>
        <ThreeStateOption
          onChange={(val) => handleChange('isReviewed', val)}
          value={advancedItems.isReviewed}
          onOffText="Reviewed"
          idPrefix={'reviewed'}
        />
      </div>
    </div>
  );
};
AdvancedPane.propTypes = {
  advancedItems: PropTypes.object,
  onChange: PropTypes.func,
  classes: PropTypes.object,
};


const FilterItemList = (props) => {
  const { title, items, getNameFromItem, onRemoveItem, itemKey } = props;
  const classes = useStyles();

  const categoriesById = useSelector(categoriesSelectors.getCategoriesById);
  const filteredItems = useMemo(() => {
    if (itemKey !== 'categories') return items;

    return items.filter((catId) => !categoriesById.get(catId)?.parentId || !items.has(categoriesById.get(catId)?.parentId));
  }, [itemKey, items, categoriesById]);

  const handleRemove = (item) => (_e) => {
    onRemoveItem(itemKey, item);
  };

  if (filteredItems.size === 0 || filteredItems.length === 0) return null;
  return (
    <div>
      <Typography variant={'subtitle2'} className={classes.fliHeader}>{title}</Typography>
      {filteredItems.map((item) => (
        <div key={`filter-item-list-${title}-${item}`} className={classes.fliItem}>
          <Typography
            className={classes.fliLabel}
            variant={'caption'}
            id={`filter-item-list-${title}-${getNameFromItem(itemKey, item)}`}
          >
            {getNameFromItem(itemKey, item)}
          </Typography>
          <QIconButton
            size={'small'}
            className={classes.fliRemove}
            id={`filter-item-list-${title}-${getNameFromItem(itemKey, item)}-remove`}
            onClick={handleRemove(item)}
          >
            <ClearIcon />
          </QIconButton>
        </div>
      ))}
    </div>
  );
};
FilterItemList.propTypes = {
  title: PropTypes.string,
  items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  getNameFromItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
  itemKey: PropTypes.string,
};
