
// BASE
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import classNames from 'classnames';


// CUSTOM COMPONENTS

// SELECTORS, ACTIONS, UTILS

// MUI COMPONENTS
import ButtonBase from '@mui/material/ButtonBase';

// PATH RELATIVE IMPORTS
import { styles } from './styles';

const propTypes = {
  onClick: PropTypes.func,
  tabs: PropTypes.array,
  selected: PropTypes.string,
};

// STYLES HOOK CREATION
const useStyles = makeStyles(styles);

const QTabsHorizontal = (props) => {

  // PROPS ============================================================================
  const { tabs, onClick, selected } = props;

  // STATE ============================================================================
  // SELECTORS ==========================================================================
  // EFFECTS ============================================================================
  // STYLES ============================================================================
  const classes = useStyles(props);

  // INTERNAL FUNCTIONS ================================================================
  const renderTabs = () => {
    const nodes = [];
    tabs.forEach((tab) => {
      nodes.push(
        <div key={`tabLabel:${tab}`} className={classes.tab}>
          <ButtonBase
            focusRipple
            onClick={(e) => { e.stopPropagation(); e.preventDefault(); onClick(tab); }}
          >
            <p className={classNames(classes.innerTab, selected.toLowerCase() === tab.toLowerCase() ? 'selected' : '')}>
              {tab}
            </p>
          </ButtonBase>
        </div>
      );
    });
    return nodes;
  };

  // RENDER ============================================================================
  return (
    <div className={classes.root}>
      {renderTabs()}
    </div>
  );

};

QTabsHorizontal.propTypes = propTypes;
export default (QTabsHorizontal);

