import { createAction } from 'redux-actions';

export const getBudgetsAction = createAction('GET_BUDGETS');
export const getBudgetsSuccessAction = createAction('GET_BUDGETS_SUCCESS');
export const getBudgetsFailureAction = createAction('GET_BUDGETS_FAILURE');

export const createBudgetsAction = createAction('CREATE_BUDGETS', (payload) => payload, (payload, meta) => meta);
export const createBudgetsSuccessAction = createAction('CREATE_BUDGETS_SUCCESS', (payload) => payload, (payload, meta) => meta);
export const createBudgetsFailureAction = createAction('CREATE_BUDGETS_FAILURE');

export const updateBudgetAction = createAction('UPDATE_BUDGET', (payload) => payload, (payload, meta) => meta);
export const updateBudgetSuccessAction = createAction('UPDATE_BUDGET_SUCCESS', (payload) => payload, (payload, meta) => meta);
export const updateBudgetFailureAction = createAction('UPDATE_BUDGET_FAILURE');

export const deleteBudgetAction = createAction('DELETE_BUDGETS', (payload) => [payload], (payload, meta) => meta);
export const deleteBudgetSuccessAction = createAction('DELETE_BUDGETS_SUCCESS');
export const deleteBudgetFailureAction = createAction('DELETE_BUDGETS_FAILURE');

export const deleteBudgetsAction = createAction('DELETE_BUDGETS', (payload) => payload, (payload, meta) => meta);
export const deleteBudgetsSuccessAction = createAction('DELETE_BUDGETS_SUCCESS');
export const deleteBudgetsFailureAction = createAction('DELETE_BUDGETS_FAILURE');

export const recalcBudgetsAction = createAction('RECALC_BUDGETS');
export const recalcBudgetsSuccessAction = createAction('RECALC_BUDGETS_SUCCESS');
export const recalcBudgetsFailureAction = createAction('RECALC_BUDGETS_FAILURE');

export const callFullCalcBudgetAction = createAction('FULL_CALC_BUDGETS', (payload) => payload, (payload, meta) => meta);
export const callFullCalcBudgetSuccessAction = createAction('FULL_CALC_BUDGETS_SUCCESS');
export const callFullCalcBudgetFailureAction = createAction('FULL_CALC_BUDGETS_FAILURE');
