// BASE
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

// CUSTOM COMPONENTS
import QButton from 'components/QButton';
import CategoryField from 'components/QuickenControls/CategoryField';
import QAmountField from 'components/QAmountField';

// SELECTORS, ACTIONS, UTILS

// MUI COMPONENTS
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';

// PATH RELATIVE IMPORTS
import { styles } from './styles';

// STYLES HOOK
const useStyles = makeStyles(styles);

/*
 *************** SUB COMPONENTS ******************
 */

/*
 * Title Header sub component
 */
export const TitleHeader = (props) => {

  const { classes } = props;
  return (
    <div
      className={classes.header}
    >
      <Typography
        variant="h5"
        className={classes.title}
      >
        Add Budget Category
      </Typography>
      <Typography
        variant="body1"
      >
        Select a category to add to your budget.  An average amount from your previous transactions
        will be entered on your behalf.  You may choose to update the amount.
      </Typography>
    </div>
  );
};
TitleHeader.propTypes = {
  classes: PropTypes.object,
};

/*
 * Button Area sub component
 */
export const ButtonArea = (props) => {

  const { onAdd, onClose, disableSubmit } = props;
  return (
    <DialogActions>
      <QButton onClick={onClose} id="add-budget-cancel-button">
        Cancel
      </QButton>
      <QButton disabled={disableSubmit} onClick={onAdd} id="add-to-budget-button">
        Add To Budget
      </QButton>
    </DialogActions>
  );
};
ButtonArea.propTypes = {
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  disableSubmit: PropTypes.bool,
};

/*
 * WrappedField sub component
 */
export const WrappedField = (props) => {

  const { children, caption, ...other } = props;

  // STYLES
  const classes = useStyles(props);

  return (
    <div
      {...other}
    >
      <Typography
        variant="caption"
      >
        {caption}
      </Typography>
      <div className={classes.fieldWrapper}>
        {children}
      </div>
    </div>
  );
};
WrappedField.propTypes = {
  children: PropTypes.any,
  caption: PropTypes.string,
};

/*
 * CategoryWrappedField sub component
 */
export const CategoryWrappedField = (props) => {

  const { catFieldProps, ...other } = props;

  // STYLES

  return (
    <WrappedField
      caption="Select a Category"
      style={{ flex: 1, marginRight: 40 }}
      {...other}
    >
      <CategoryField
        name="budget-add-cat-field-category"
        id="budget-add-cat-field-category"
        fontSize="22px"
        editable
        allowBlank
        createEnabled
        disableUnderline
        initialFocus
        {...catFieldProps}
      />
    </WrappedField>
  );
};
CategoryWrappedField.propTypes = {
  selectedCategory: PropTypes.object,
  onChange: PropTypes.func,
  catFieldProps: PropTypes.object,
};

/*
 * AmountWrappedField sub component
 */
export const AmountWrappedField = (props) => {

  const { currentAmount, onBlur, classes, onSubmit, currency, ...other } = props;

  // STYLES
  return (
    <WrappedField
      caption="Amount"
      style={{ flex: 0, minWidth: 150 }}
      {...other}
    >
      <QAmountField
        name="budgetAddAmountField"
        id="budget-category-amount-field"
        editable
        value={currentAmount}
        currency={currency}
        classes={classes}
        onSubmit={onSubmit}
        onBlur={onBlur}
        disableUnderline
        decimals={0}
      />
    </WrappedField>
  );
};
AmountWrappedField.propTypes = {
  currentAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBlur: PropTypes.func,
  onSubmit: PropTypes.func,
  classes: PropTypes.object,
  currency: PropTypes.string,
};
