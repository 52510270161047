import { handleActions } from 'redux-actions';
import { resourceUpserters } from '@quicken-com/react.flux.core';
import { InvestmentHoldingsStore } from './types';
import * as actions from './actions';

import { upsertIHolds } from './reducerHelpers';

// const log = getLogger('data/investmentHoldings/reducer.js');
export const INVESTMENT_HOLDINGS_REDUCER_KEY = 'investmentHoldingsStore';

const reducer = handleActions({

  // Called when triggering a CRUD operation on transactions. Sagas are also
  // expected to handle the action by initiating the CRUD operation with QCS.
  [actions.getInvestmentHoldings]: (state) => state.merge({ isLoading: true }),
  [actions.getInvestmentHoldingsSuccess]: (state, { payload: data }) => upsertIHolds(state, data),
  [actions.getInvestmentHoldingsFailure]: (state, { payload: error }) => resourceUpserters.completeWithError(state, error),
  [actions.getInvestmentQuotes]: (state) => state.merge({ isQuotesLoading: true }),
  [actions.getInvestmentQuotesSuccess]: (state, { payload: data }) => upsertIHolds(state, data).merge({ isQuotesLoading: false }),
  [actions.getInvestmentQuotesFailure]: (state, { payload: error }) => resourceUpserters.completeWithError(state, error).merge({ isQuotesLoading: false }),
}, new InvestmentHoldingsStore());

export default reducer;
