import { takeEvery } from 'redux-saga/effects';
import * as actions from 'data/subscriptions/subscriptionsActions';

import { resourceStoreTypes, resourceSagaUtils } from '@quicken-com/react.flux.core';

import { mkSubscription } from 'data/subscriptions/subscriptionsTypes';

const mkResourceConfig = (props) => resourceStoreTypes.mkQcsSyncResourceConfig({
  resourceName: 'subscriptions',
  resourceBaseUrl: '/subscriptions',
  axiosConfig: { headers: { 'qcs-dataset-id': null } },
  transformResponseToResources: (response: any) => response.data.map((subscription) => mkSubscription(subscription)),
  ...props,
});

export function* getSubscriptionActionWatcher() {
  const resourceConfig = mkResourceConfig({
    successAction: actions.getSubscriptionsSuccess,
    failureAction: actions.getSubscriptionsFailure,
  });
  yield takeEvery(actions.getSubscriptions, resourceSagaUtils.qcsSyncGetResources, resourceConfig);
}

export function* updateSubscriptionRenewalFrequencyActionWatcher() {
  const resourceConfig = resourceStoreTypes.mkQcsSyncResourceConfig({
    resourceName: 'subscriptionsActions',
    resourceBaseUrl: '/subscriptions/renewalFrequency',
    axiosConfig: { headers: { 'qcs-dataset-id': null } },
    successAction: actions.updateSubscriptionRenewalFrequencySuccess,
    failureAction: actions.updateSubscriptionRenewalFrequencyFailure,
  });
  yield takeEvery(actions.updateSubscriptionRenewalFrequency, resourceSagaUtils.qcsSyncCreateResource, resourceConfig);
}

export default [
  getSubscriptionActionWatcher,
  updateSubscriptionRenewalFrequencyActionWatcher,
];
