
import { List as ImmutableList } from 'immutable';

import { accountsSelectors } from '@quicken-com/react.flux.accounts';
import { tagsSelectors } from '@quicken-com/react.flux.tags';
import { categoriesSelectors } from '@quicken-com/react.flux.categories';
import { chartOfAccountsTypes, chartOfAccountsSelectors } from '@quicken-com/react.flux.chart-of-accounts';

import { getPayeesNameList } from 'data/payees/selectors';

export const dataDictionary = ImmutableList([
  { id: 'actionsSelect',
    type: 'actionsSelect',
    label: ' ',
    flex: 1,
    hide: false,
  },
  { id: 'name',          // the name/index of the field in the data record
    type: 'string',      // 'string' or 'select'
    label: 'Category Name',       // Data label for this field
    flex: 3,             // in list display, flex distribution
    editable: false,      // in category row, is the field editable?
    uniqueWith: ['parent'],  // this field, and all fields in the array must match to be considered a duplicate
    placeholder: 'Tag',
    hide: false,
    selectable: true,
  },
]);

//
// Configuration for resources supported
//
export const resourceConfig = {
  categories: {
    key: 'categoriesById',
    selector: categoriesSelectors.getCategoriesById,
    allowsChildren: true,
  },
  accounts: {
    key: 'accountsById',
    selector: accountsSelectors.getReportableAccountsById,
    allowsChildren: false,
  },
  payees: {
    key: 'payeesNameList',
    selector: getPayeesNameList,
    allowsChildren: false,
  },
  tags: {
    key: 'tagsById',
    selector: tagsSelectors.getTags,
    allowsChildren: false,
  },
};


// ========== CACHE FOR SPEED ===========
let PARENT_CACHE = {};

function resetParentCache() {
  PARENT_CACHE = {};
}
function addToParentCache(key, value) {
  PARENT_CACHE[key] = value;
}
function parentCacheValue(key) {
  return PARENT_CACHE[key];
}
// ========== CACHE FOR SPEED ===========

function crawlList(coaNode, categories, filter, level = 1) {

  const category = coaNode; // categories.get(coaNode.id);

  if (category && !category.isExcludedFromCategoryList && category.inList && (category.id !== 'NONE')) {
    let childData = null;
    if (coaNode.children) {
      childData = [];
      coaNode.children.forEach((node) => {
        childData = childData.concat(crawlList(node, categories, filter, level + 1));
      });
    }

    childData = childData?.filter((x) => x.id !== 0);

    if (filter && category.fullPathName.toLowerCase().indexOf(filter.toLowerCase()) === -1 && !childData?.length) {
      return [{ id: 0 }];
    }

    const parent = Number(category.parentId) ?
      parentCacheValue(category.parentId) || chartOfAccountsSelectors.getCoaStringSelector(undefined, { type: 'CATEGORY', id: category.parentId }, true) : '';

    addToParentCache(category.parentId, parent);

    const parentCatType = ['', '0'].includes(category.parentId) ? chartOfAccountsTypes.CoaTypeEnum.NONE : chartOfAccountsTypes.CoaTypeEnum.CATEGORY;

    return ([
      {
        id: category.id,
        name: category.name,
        description: category.description,
        categoryType: String(category.type),
        isExcludedFromBudgets: category.isExcludedFromBudgets ? 'No' : 'Yes',
        isDeleted: category.isDeleted,
        parent,
        // if an object 'values' is included in the data record, the value specified for the given id
        // is used for the inputComponent instead of the string/number provided in the base object
        // (e.g. for 'parent', instead of the string above, we will use the coa record below)
        values: {
          parent: { type: parentCatType, id: category.parentId },
        },
        // if the array property children is specified, then the objects in the array represent a hierarchical
        // section (child objects) of the parent
        children: childData,
        level,
      },
    ]);
  }
  return [{ id: 0 }];
}

export function makeLocalCategoriesData({ categories, chartOfAccounts, filter, filterFn }) {
  if (!categories) {
    return [];
  }

  resetParentCache();

  let data;
  if (chartOfAccounts) {
    let data2 = [];
    chartOfAccounts.forEach((coaNode) => {
      const ret = crawlList(coaNode, categories, filter);
      data2 = data2.concat(ret);
    });
    data = (ImmutableList(data2)).filter((x) => Number(x.id) !== 0);
  }

  return filterFn ? data.filter(filterFn) : data;
}

export function getChildIds(catItem) {
  return catItem.children?.map((x) => x.id);
}
