import moment from 'moment';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import { List as ImmutableList } from 'immutable';

import { getLogger, tracker } from '@quicken-com/react.utils.core';
import { accountsUtils } from '@quicken-com/react.flux.accounts';
import { categoriesSelectors } from '@quicken-com/react.flux.categories';
import { chartOfAccountsTypes, chartOfAccountsUtils } from '@quicken-com/react.flux.chart-of-accounts';
import { transactionsActions, transactionsTypes, transactionsUtils } from '@quicken-com/react.flux.transactions';

import { ensureISODate, isBrokenAccountId } from 'utils/utils';

import { isAcme } from 'isAcme';

import { getAccountString } from '../accounts/retrievers';

import { splitTxnRemainder, addRemainderToSplit } from './splitsHelpers';

import {
  setReportsFlags,
  isMultiCurrencyTransferTxn,
  isUnsyncedTransferTxn,
  isSupportedTransferTxnWithFlags,
  isLoanAcctTransferTxn,
  isInvestmentAcctTransferTxn,
  isUnacceptedScheduledTxn,
  isPendingTxn,
  isBankDownloadedTxn,
  addPossibleMatch,
  findTransaction,
  findPossibleTransferCandidate,
  possiblyMatchTransfer,
  getTxnDifferences,
  fieldChanged,
  areTagsEqual,
  transactionEditsNotAllowed,
  nextScheduledInstanceAfterTransaction,
  txnHasUnsupportedTransfer,
  updateNewTags,
  isExistingTxn,
  replaceInArray,
} from './utils';

const log = getLogger('data/transactions/preProcess.js');


export function txnCategorySign(txn) {

  if (transactionsUtils.isSplitTxn(txn)) {
    return 1;
  }
  const isIncomeCat = txn.coa ? categoriesSelectors.isIncomeCat(null, txn.coa.id) : false;

  return isIncomeCat ? 1 : -1;
}

function getMemoForTransferTxn(txn, overrides) {
  if (!txn.id) {
    return overrides ? overrides.memo : txn.memo;
  }
  return undefined;
}

function makeTransferTxn(txn, guid, overrides) {
  return new transactionsTypes.CashFlowTransaction({
    accountId: overrides?.accountId || txn.coa.id,
    coa: {
      type: 'ACCOUNT',
      id: txn.accountId,
    },
    amount: overrides?.amount || String(Number(txn.amount) * -1),
    split: null,
    cpData: null,
    matchState: 'NOT_MATCHED',
    transfer: {
      id: txn.id ? txn.id : undefined,
      clientId: txn.clientId, // assumes clientId already set
    },
    clientId: guid,  // new GUID for transfer transaction
    tags: overrides?.tags || txn.tags,
    isExcludedFromReports: true,  // by default both sides of a transfer are excluded from reports
    isExcludedFromF2S: true,  // by default both sides of a transfer are excluded from reports
    check: null,

    payee: txn.payee,
    postedOn: txn.postedOn,
    postedOnAsUnix: txn.postedOnAsUnix,
    state: txn.state,
    stModelId: txn.stModelId,
    stDueOn: txn.stDueOn,
    isBill: txn.isBill,
    isSubscription: txn.isSubscription,
    memo: getMemoForTransferTxn(txn, overrides),
  });
}

function shouldUpdateTransfer(oldTransfer, newTransfer) {
  return Boolean((Number(oldTransfer.amount) !== Number(newTransfer.amount)) ||
    !areTagsEqual(oldTransfer.tags, newTransfer.tags) ||
    !DateTime.fromISO(oldTransfer.postedOn).hasSame(DateTime.fromISO(newTransfer.postedOn), 'day') ||
    (oldTransfer.isExcludedFromReports !== newTransfer.isExcludedFromReports) ||
    (oldTransfer.isExcludedFromF2S !== newTransfer.isExcludedFromF2S));
}

const getParentSplitTxn = (txn, txnsByAccountId) => {
  if (txn?.coa?.type === 'ACCOUNT') {
    const txnsFromTransferAccount = txnsByAccountId.get(txn.coa.id) || ImmutableList([]);
    const transferTxns = txnsFromTransferAccount.filter((tx) => transactionsUtils.isTransferTxn(tx));
    const parentTxns = transactionsUtils.findMatchingTransferTx(txn, transferTxns);
    return parentTxns?.txn || undefined;
  }
  return undefined;
};

// ====================================================
// createRequiredTransferTxns
//
// If this is a transfer transaction (or has split children which are transfers),
// we will make the other side(s) of it.
//
export function createRequiredTransferTxns(txnToProcess, txnsByAccountId, featureFlags, state, previousTxn) {

  const allTxnsToCreate = [];
  const allTxnsToUpdate = [];
  const warnings = [];
  const allMatches = [];
  const extraActions = [];
  const transfersToPersist = [];

  createOrUpdate(txnToProcess, allTxnsToCreate, allTxnsToUpdate); // push the txn before applying match logic

  let baseTxn = possiblyMatchTransfer(txnToProcess, txnsByAccountId);
  const forceWarnOnMatch = (baseTxn !== txnToProcess); // if found a potential match,

  if (transactionsUtils.isSplitTxn(baseTxn)) {

    // process splits
    // baseTxn represents the txn we are processing, as a split, we will need to alter any
    // transfer split items to contain the GUID connectors

    baseTxn.split.items.forEach((item, index) => {
      if (isSupportedTransferTxnWithFlags(item, featureFlags, state) && item.coa.id !== baseTxn.accountId) {

        let alreadyConnected = false;
        let oldTxn; // the previous 'other side' of the transfer

        // check if we are editing an existing transfer
        if (previousTxn && transactionsUtils.isTransferTxn(previousTxn)) {
          // transfer txns to consider
          const transferTxns = txnsByAccountId.get(item.coa.id) || ImmutableList([])
            .filter((tx) => transactionsUtils.isTransferTxn(tx));

          if (transactionsUtils.isSplitTxn(previousTxn)) {
            const oldSplitItem = previousTxn.split?.items?.find((oldItem) => oldItem.coa?.id === item.coa?.id);
            if (oldSplitItem) {
              // find the existing 'otherSide'
              oldTxn = transactionsUtils.findMatchingTransferTx({ ...oldSplitItem.toJS(), postedOn: previousTxn?.postedOn, accountId: previousTxn?.accountId }, transferTxns)?.txn;
            }
          } else if (item.coa.id === previousTxn.coa.id) { // if new split, make sure the split contains the previous transfer coa
            oldTxn = transactionsUtils.findMatchingTransferTx(previousTxn, transferTxns)?.txn;
          }
        }

        // found existing match
        if (oldTxn && (item.coa?.id === oldTxn.accountId) && (oldTxn.coa?.id === baseTxn.accountId)) { // still connected
          alreadyConnected = true;
          // update fields
          if (shouldUpdateTransfer(oldTxn, { ...baseTxn.toJS?.(), ...item })) { // update fields
            const updatedTransfer = oldTxn.merge({
              amount: Number(item.amount || 0) * -1,
              tags: item.tags,
              postedOn: baseTxn.postedOn,
              isExcludedFromReports: baseTxn.isExcludedFromReports,
              isExcludedFromF2S: baseTxn.isExcludedFromF2S,
            });
            log.log('updating other side of transfer', updatedTransfer);
            allTxnsToUpdate.push(updatedTransfer);
          } else {
            transfersToPersist.push(oldTxn);
          }
        }

        // only match/create new transfers if we didnt find it already connected above
        if (!alreadyConnected) {

          const newGuid = uuidv4().toUpperCase(); // new GUID for transfer transaction
          // check for a matching transaction, if found save with GUID for later syncing
          const possibleMatch = findPossibleTransferCandidate(item.coa.id, // TODO: Is this returning already matched txns???
            baseTxn.postedOn,
            String(-1 * Number(item.amount)),
            txnsByAccountId);

          const transferTxn = makeTransferTxn(baseTxn, newGuid, {
            accountId: item.coa.id,
            amount: item.amount * -1,
            tags: item.tags,
            memo: item.memo,
          });
          // by default both sides of a transfer are excluded from reports
          baseTxn = setReportsFlags(baseTxn, true);

          // set the guid on both the baseTxn, and the split item
          baseTxn = baseTxn.setIn(['split', 'items', index, 'transfer'], { clientId: newGuid });

          // If we have a possible match, save it and save the matching transfer "to create" for processing
          if (possibleMatch) {
            log.log('found possible match', possibleMatch.toJS?.());
            addPossibleMatch(possibleMatch, baseTxn, transferTxn.set('accountId', baseTxn.accountId), allMatches, forceWarnOnMatch);
          } else {
            // no possible match, balance with a manual txn
            log.log('no possible match', '\ncreating txn:', transferTxn);
            allTxnsToCreate.push(transferTxn);
            // apply transfer updates to already staged txn
            if (!(replaceInArray(allTxnsToUpdate, baseTxn) || replaceInArray(allTxnsToCreate, baseTxn))) {
              // base not already in update/create array
              log.warn('Did not find baseTxn already staged for update/create.',
                '\nConsider looking into transfer balancing logic');
              createOrUpdate(baseTxn, allTxnsToCreate, allTxnsToUpdate);
            }
            if (isAcme) { // warn before doing
              warnings.push(`We did not find a matching transaction in the account ${getAccountString(transferTxn.accountId)}.` +
                '  Click continue to create a manual transaction, or Cancel to abort');
            }
          }
        }
      }
    });

  } else if (isSupportedTransferTxnWithFlags(baseTxn, featureFlags, state) && baseTxn.coa.id !== baseTxn.accountId) {

    let alreadyConnected = false;

    // check if existing transfer is already connected to a txn
    if (previousTxn && (baseTxn.coa?.id === previousTxn.coa?.id)) { // did not change transfer account
      const transferTxns = txnsByAccountId.get(baseTxn.coa.id) || ImmutableList([])
        .filter((tx) => transactionsUtils.isTransferTxn(tx));
      const oldTxn = transactionsUtils.findMatchingTransferTx(previousTxn, transferTxns)?.txn;
      // found existing match
      if (oldTxn && (baseTxn.coa?.id === oldTxn.accountId) && (oldTxn.coa?.id === baseTxn.accountId)) { // still connected
        alreadyConnected = true;
        // update fields
        if (shouldUpdateTransfer(oldTxn, baseTxn)) { // update fields
          const updatedTransfer = oldTxn.merge({
            amount: Number(baseTxn.amount || 0) * -1,
            tags: baseTxn.tags,
            postedOn: baseTxn.postedOn,
            isExcludedFromReports: baseTxn.isExcludedFromReports,
            isExcludedFromF2S: baseTxn.isExcludedFromF2S,
          });
          log.log('updating other side of transfer', updatedTransfer);
          allTxnsToUpdate.push(updatedTransfer);
        } else {
          transfersToPersist.push(oldTxn);
        }
      }
    }

    // only match/create new transfers if we didnt find it already connected above
    if (!alreadyConnected) {

      // check to see if there is a transaction already in the transfer account that might be
      // a target to match up with this transfer.
      const possibleMatch = findPossibleTransferCandidate(baseTxn.coa.id,
        baseTxn.postedOn,
        -1 * baseTxn.amount,
        txnsByAccountId);

      const newGuid = uuidv4().toUpperCase(); // New GUID for new transfer, save with match if any for later sync

      const transferTxn = makeTransferTxn(baseTxn, newGuid);

      baseTxn = setReportsFlags(baseTxn, true);
      baseTxn = baseTxn.set('transfer', { clientId: newGuid });

      // If we have a possible match, save it for approval
      if (possibleMatch) {
        log.log('found possible match', possibleMatch.toJS?.());
        addPossibleMatch(possibleMatch, baseTxn, transferTxn.set('accountId', baseTxn.accountId), allMatches, forceWarnOnMatch);
      } else {
        log.log('no possible match', '\ncreating manual txn:', transferTxn);
        // no possible match, balance with a manual txn
        // extra check for validating whether the transaction belongs to split txn or not
        if (!getParentSplitTxn(baseTxn, txnsByAccountId)) {
          allTxnsToCreate.push(transferTxn);
        }
        // apply transfer updates to already staged txn
        if (!(replaceInArray(allTxnsToUpdate, baseTxn) || replaceInArray(allTxnsToCreate, baseTxn))) {
          // base not already in update/create array
          log.warn('Did not find baseTxn already staged for update/create.',
            '\nConsider looking into transfer balancing logic');
          createOrUpdate(baseTxn, allTxnsToCreate, allTxnsToUpdate);
        }
        if (isAcme) { // warn before doing
          warnings.push(`We did not find a matching transaction in the account ${getAccountString(transferTxn.accountId)}.` +
            '  Click continue to create a manual transaction, or Cancel to abort');
        }
      }
    }
  }
  return { allTxnsToUpdate, allTxnsToCreate, allMatches, warnings, extraActions, transfersToPersist };
}

// ----------------------------------------------------
// CreateOrUpdate (txn, createArray, updateArray)
function createOrUpdate(txn, ca, ua) {
  if (!isExistingTxn(txn)) {
    ca.push(txn);
  } else {
    ua.push(txn);
  }
}

// ----------------------------------------------------
// removeTransfers
//
function mergeTxnPack(txnPack, packObj) {

  const { allMatches, allTxnsToCreate, allTxnsToUpdate, allTxnsToDelete, extraActions, warnings, errors } = packObj;

  if (txnPack.allTxnsToCreate) {
    txnPack.allTxnsToCreate.forEach((x) => {
      allTxnsToCreate.push(x);
    });
  }
  if (txnPack.allTxnsToUpdate) {
    // for updates, we will not allow you to update the same transaction twice.  No way to know which
    // should win, so we only accept the first
    txnPack.allTxnsToUpdate.forEach((x) => {
      if (!allTxnsToUpdate.find((txn) => txn.id === x.id)) {
        allTxnsToUpdate.push(x);
      }
    });
  }
  if (txnPack.allTxnsToDelete) {
    txnPack.allTxnsToDelete.forEach((x) => {
      allTxnsToDelete.push(x);
    });
  }
  if (txnPack.warnings) {
    txnPack.warnings.forEach((x) => {
      warnings.push(x);
    });
  }
  if (txnPack.errors) {
    txnPack.errors.forEach((x) => {
      errors.push(x);
    });
  }
  if (txnPack.allMatches) {
    txnPack.allMatches.forEach((x) => {
      allMatches.push(x);
    });
  }
  if (txnPack.extraActions) {
    txnPack.extraActions.forEach((x) => {
      extraActions.push(x);
    });
  }

}

// TODO for all of these we need to handle MATHED/UNMATCHED and DOWNLOADED cases and do the right thing
// whatever that is
//
// ====================================================
// preProcessDelete
//
export function preProcessDelete(options) {

  const { txns, txnsByAccountId, isUpdate = false, skipWarnings, state } = options;

  const allTxnsToDelete = [];
  const allTxnsToUpdate = [];
  const warnings = [];
  const errors = [];
  const extraActions = [];

  txns.forEach((txn) => {

    // we can use this to create deletion side effects without deleting the original transaction
    if (!isUpdate) {
      const txnPrev = findTransaction(txnsByAccountId, txn.id, txn.accountId);
      if (!txnPrev) {
        return true;
      }
      allTxnsToDelete.push(txn.set('isDeleted', true));
      if (txn.cpData) { // TODO need to check preference for 'editAmounts', maybe bypass warning?
        // warnings.push(`The transaction "${txn.payee}" was downloaded from your financial institution.` +
        //  ' You will not be able to download it again if you continue this delete operation.  ');
      }
    }
    if (isInvestmentAcctTransferTxn(txn, state)) {
      // if this transaction is a transfer to an investment account, do not allow deletion
      errors.push('This transaction contains a transfer to an investment account, You may only delete ' +
        'this transaction from the desktop.');
    }
    if (isUnsyncedTransferTxn(txn, state)) {
      // if this transaction is a transfer to an unsynced account, warn on deletion
      warnings.push('This transaction contains a transfer to an unsynced account, deleting it may ' +
        'have adverse effects when you sync back to the desktop.  Recommend aborting this action.');
    }
    // TODO Investment and Loan transfer transactions, allow delete?
    //
    if (transactionsUtils.isTransferTxn(txn)) {

      if (transactionsUtils.isSplitTxn(txn)) {
        // find the split item whose amount matches the amount
        txn.split.items.forEach((item) => {
          // if split item is a valid transfer, and not a self-transfer
          if (item.coa && item.coa.type === 'ACCOUNT' && item.coa.id !== txn.accountId) {
            const transferTxns = getTransferTxns(txnsByAccountId, item.coa.id); // txnsByAccountId.get(item.coa.id).filter((x) => isTransferTxn(x));
            removeMatchingTransfer({
              txn,
              transferTxns,
              updateArray: allTxnsToUpdate,
              deleteArray: allTxnsToDelete,
              warnings,
              errors,
              altAmount: item.amount,
              skipWarnings,
              state,
            });
          }
        });
      } else {

        // not a split transaction, so simply delete the other side of this transaction
        const transferTxns = getTransferTxns(txnsByAccountId, txn.coa.id); // txnsByAccountId.get(txn.coa.id).filter((x) => isTransferTxn(x));
        removeMatchingTransfer({
          txn,
          transferTxns,
          updateArray: allTxnsToUpdate,
          deleteArray: allTxnsToDelete,
          warnings,
          errors,
          altAmount: null,
          skipWarnings,
          state,
        });
      }
    }
    return true;

  });

  return ({ allTxnsToDelete, allTxnsToUpdate, warnings, errors, extraActions });
}

function getTransferTxns(txnsByAccountId, id) {

  const acctTxns = txnsByAccountId.get(id) || ImmutableList([]);
  const ret = acctTxns.filter((x) => transactionsUtils.isTransferTxn(x));
  return ret;
}

//-------------------------------------------------------------------------------
// When the other side of a transfer is in a split, we have to update that one split
// row (we make the category 'UNCATEGORIZED')  We don't bother to consolidate the
// uncategorized rows here, although that is a TODO - consolidate uncategorized items
//
// removeMatchingTransfer
//
function removeMatchingTransfer({
  txn,
  transferTxns,
  updateArray,
  deleteArray,
  warnings,
  errors: _errors,
  altAmount = null,
  skipWarnings,
  state,
}) {

  const bestMatchTxn = transactionsUtils.findMatchingTransferTx(txn, transferTxns, altAmount);

  if (bestMatchTxn?.txn) {
    if (bestMatchTxn.splitItem !== null) {  // transfer is in a split

      // update in the immutable the coa id and the coa type
      const t1 = bestMatchTxn.txn.setIn(['split', 'items', bestMatchTxn.splitItem, 'coa'], { id: '0', type: 'UNCATEGORIZED' });
      const t2 = t1.setIn(['split', 'items', bestMatchTxn.splitItem, 'transfer'], null);
      updateArray.push(t2);
      const acctName = getAccountString(t2.accountId, state);
      if (!skipWarnings) {
        warnings.push(`You are deleting the transfer transaction "${txn.payee}".  The other side of this transaction` +
          ` in account [${acctName}] is in a split transaction and its line item will be set to "Uncategorized"`);
      }
    } else if (bestMatchTxn.txn.cpData) {
      //
      // if the matched transaction is a downloaded and/or matched transaction, do not delete it,
      // but un-categorize it
      // Also unset the exclusion from reports
      //
      const t1 = setReportsFlags(bestMatchTxn.txn.merge({
        coa: { id: 0, type: 'UNCATEGORIZED' },
        transfer: null,
      }), false);
      updateArray.push(t1);
      const acctName = getAccountString(t1.accountId, state);

      if (!skipWarnings) {
        warnings.push(`You are deleting the transfer transaction "${txn.payee}".  The other side of this transfer ` +
          ` in account [${acctName}] is a bank downloaded transaction and will be set to "Uncategorized", but will not be deleted`);
      }
    } else {
      const acctName = getAccountString(bestMatchTxn.txn.accountId, state);

      if (!skipWarnings) {
        warnings.push(`You are deleting the transfer transaction "${txn.payee}".  This will also delete the other side` +
          ` of the transfer in account [${acctName}] `);
      }
      deleteArray.push(bestMatchTxn.txn.set('isDeleted', true));
    }
  }
}

// ====================================================
// preProcessUpdate
//
// Here are the rules, subject to change
//
// Premise: Transaction has a transfer category OR has a split with transfer category(ies)
// Problem: Determine updates that need to happen to the other side of the transfer
// Note: The other side of the transfer may be a transaction coa, or within a split
// More Notes: transaction can go from split to not split, or the other way around
//
// COMPLEX!!!!!!!
//
// RULES:
//
// NORMAL TRANSFER: If this is a generic transfer (was not split) tightly bound
//  - if the other side of this transfer is within a split, NO CHANGES are allowed (Mac rules).  Transaction is locked
//  - change to amount will change both sides of the transfer
//  - change to the date will change both sides of the transfer (date/amount coupling is strongest) -with warning
//  - change to payee will change both sides of the transfer
//  - change to the category (break the transfer) will delete the other side of the transfer - with warning
//  - transaction becomes split - then previous transfer is deleted, and new transfers are created if applicable
//  - all other changes will not impact the other side of the transfer
//
// SPLIT TRANSFER: This transaction had one or more transfers in it's split items
// - change to the date will change both sides of the transfer(s) - with warning
// - change to the payee will change all payee names linked to split transfer rows
// - change to a split row amount that is a transfer will impact the amount of the other side of the transfer
// - change to a split row category that will break the existing transfer will delete the other side
// - all other changes will not impact the other side of any split row transfers
// - if the transaction became NON SPLIT (was split, now it is not) all prior transfers are deleted and then
//   if applicable, new transfer transaction created (if non split version is a transfer) - with warning
//

const msgReconcileChanging = 'You are changing a previously reconciled transaction to be not reconciled. ' +
'Are you sure you want to do this?';

export function preProcessUpdate(options) {  // state is for unit testing

  const { txns, txnsByAccountId, state, featureFlags } = options;

  const allTxnsToDelete = []; // transfer transactions could be eliminated
  const allTxnsToUpdate = []; // all transactions might change
  const allTxnsToCreate = []; // new transfer transactions may be created
  const allMatches = [];
  const warnings = [];
  const errors = [];
  const extraActions = [];

  function localMergeTxnPack(packItems) {
    mergeTxnPack(packItems, {
      allTxnsToUpdate,
      allTxnsToDelete,
      allTxnsToCreate,
      allMatches,
      warnings,
      errors,
      extraActions,
    });
  }

  // Process every transaction in the update
  txns.forEach((x) => {

    // general formatting

    let txn = x;
    let txnPrev = null;
    let isNewTxn = !isExistingTxn(txn);

    let errorThrown = false; // if an error is thrown, we do not add any entries to the update/create/delete

    // make sure there is a legit amount
    txn = txn.set('amount', Number(txn.amount || 0));

    // convert date to an ISO String
    txn = txn.set('postedOn', ensureISODate(txn.postedOn));

    // We add a unix timestamp for faster sorting to every inserted transaction to redux
    txn = txn.set('postedOnAsUnix', DateTime.fromISO(txn.postedOn).toMillis());

    // if this is a CREATE (new) Transaction, add a clientId if there is not one already
    if (isNewTxn && !txn.clientId) {
      txn = txn.set('clientId', uuidv4().toUpperCase());
    }

    //    Split logic
    // - - - - - - - - - - - - - - - - - - -- - - - - - - - -
    if (transactionsUtils.isSplitTxn(txn)) {
      assert(!txn.coa, 'You are saving a transaction with a COA and a SPLIT - Please tell CHRIS or TIM what you did');
      // clear coa and tags if there are any
      txn = txn.merge({ tags: null, coa: null });

      // remove any zero items
      txn = txn.setIn(['split', 'items'], txn.split.items.filter((item) => Number(item.amount) !== 0));
      //
      // ensure the transaction balances
      //
      const remainder = splitTxnRemainder(txn);
      if (remainder !== 0.00) {
        txn = addRemainderToSplit(txn, remainder);
      }

      // if only one split item left, convert it to a normal category
      const items = txn.split?.items;
      const itemsAreImmutable = items?.size !== undefined;
      if (itemsAreImmutable ? (items?.size === 1) : (items?.length === 1)) { // only one split item
        const currentItem = itemsAreImmutable ? items.first() : items[0];
        txn = txn.merge({
          coa: currentItem.coa,
          tags: currentItem.tags,
          split: null,
          memo: currentItem.memo,
        });
      } else { // genuine split with multiple items

        // make sure amounts are numbers
        // also assign clientId's to any split items that do not have any
        txn = txn.setIn(['split', 'items'], txn.split.items.map((item) => {
          const newItem = item?.set('amount', Number(item.amount));
          if (item?.id) {
            return newItem;
          }
          if (item?.set) {
            return newItem?.set('clientId', uuidv4().toUpperCase());
          }
          return item;
        }));

      }
    }

    // If this transaction has tags, check for any tags with id 0, and either find an actual tag
    // with that 'name' and use it's ID, or remove it
    //

    txn = updateNewTags(txn, true, state);

    let dateChanged = false;
    let accountChanged = false;
    let coaChanged = false;
    let tagsChanged = false;
    let payeeChanged = false;

    const hideLoanTransactions = featureFlags.get('hideLoanTransactions');
    const hideConnectedLoanTransactions = featureFlags.get('hideConnectedLoanTransactions');

    if (accountsUtils.transactionsNotSupportedForAccountId(txn.accountId, hideLoanTransactions, hideConnectedLoanTransactions, state)) {
      errors.push('This account does not support creating or modifying transactions.');
      errorThrown = true;
    }

    // for Acme, do not allow transfers to any account that does not support them
    // for Quicken, we only run the test here for Loan accounts because we do allow minor editing of
    // synced transfers to investment accounts
    if ((isLoanAcctTransferTxn(txn, state) || isAcme) && txnHasUnsupportedTransfer(txn, featureFlags, state)) {
      errors.push('This transaction contains a transfer to an account that does not support transactions.');
      errorThrown = true;
    }

    // get the current stored transaction by id to identify changes in the update
    if (!isNewTxn) {
      txnPrev = findTransaction(txnsByAccountId, txn.id, txn.accountId);
      if (!txnPrev) {
        log.log('Attempting an update after txn was deleted, treat this as a create for a newTxn');
        isNewTxn = true;
        txn = txn.set('id', null).set('clientId', uuidv4().toUpperCase());
      } else {

        // Txn update (field change) logic

        dateChanged = fieldChanged(txn, txnPrev, 'postedOn');
        accountChanged = txn.accountId !== txnPrev.accountId;
        coaChanged = fieldChanged(txn, txnPrev, 'coa');
        tagsChanged = fieldChanged(txn, txnPrev, 'tags');
        payeeChanged = fieldChanged(txn, txnPrev, 'payee');

        if (txnPrev.split && !txn.split && txn.coa) {
          txn = txn.set('split', transactionsTypes.mkSplit({ items: [] }));
        }

        if (txnPrev.memo && (txnPrev.memo.length > 0) && !txn.memo) {
          txn = txn.set('memo', '');
        }

        // if we are unreconciling a transaction, give a warning
        if (txnPrev.state === 'RECONCILED' 
            && txn.state !== 'RECONCILED' 
            && !warnings.includes(msgReconcileChanging)) {
          warnings.push(msgReconcileChanging);
        }

        if (featureFlags.get('hideLoanTransactions') && isLoanAcctTransferTxn(txnPrev, state)) {
          errors.push('This transaction contains a transfer to a loan account.  ' +
            'You can only add, modify, or delete loan account transactions from desktop Quicken');
          errorThrown = true;
        }

        if (isUnacceptedScheduledTxn(txn)) {
          // do not allow changing the date to before today or after the next scheduled instance
          // companion only (for now)
          if (!isAcme) {
            if (dateChanged && moment(txn.postedOn).endOf('day').isBefore(moment())) {
              errors.push('You cannot change the date of a scheduled transaction to the past.');
              errorThrown = true;
            } else if (dateChanged) {
              // find the next instance based on the current saved date for this transaction
              const nextInstance = nextScheduledInstanceAfterTransaction(txnPrev, txnsByAccountId.get(txn.accountId));
              // now test if the new date is within the range
              if (nextInstance && moment(nextInstance.postedOn).isSameOrBefore(moment(txn.postedOn), 'day')) {
                errors.push('You cannot change the date of this scheduled instance to be after the next one in the series');
              }
            }
          }
        }
        // in acme do not allow them to change a cleared/reconciled bank downloaded transaction to pending
        if (isAcme && !isPendingTxn(txnPrev) && isPendingTxn(txn) && isBankDownloadedTxn(txn)) {
          errors.push("Sorry, but you cannot change a cleared bank downloaded transaction to 'Pending'");
          errorThrown = true;
        }
        // in quicken, do not allow editing of sched tx instances with transfers to unsynced accounts
        // except we will allow chaning the CLR status
        // TODO: needs unit test
        if (transactionEditsNotAllowed(txnPrev)) {
          const diffs = getTxnDifferences(txn, txnPrev);
          const onlyStateChanged = diffs.length === 1 && diffs[0] === 'state';
          if (!onlyStateChanged) {
            errors.push('This is a billpay transaction, or a scheduled transaction instance to an unsynced account, you can only edit this from desktop Quicken');
          }
        }
      }
    }

    // should not be possible, but do not allow creation of transactions to unsynced accounts
    if (isNewTxn && isUnsyncedTransferTxn(txn, state) && errors.length === 0) {
      errors.push('You cannot save a transaction with a transfer to an account which has not been synced');
      errorThrown = true;
    }

    // If this is a transfer transaction, and the currency types of the accounts are different, do not
    // allow to create or edit
    //
    if (isMultiCurrencyTransferTxn(txn, state)) {
      errors.push('Multi currency transfers: ' +
        'We are sorry, but creating or editing multi currency transfers is not supported.');
      errorThrown = true;
    }

    // After amount is finally determined, check if it changed for an existing transaction
    const amountChanged = isNewTxn ? false : Number(txn.amount) !== Number(txnPrev.amount);

    // do not allow editing or creation of transfers to investment accounts
    // EXCEPT when benign fields are edited, however we don't deal with splits... UNLESS only the state field changed
    // THis is ONLY for QUICKEN, it is OPEN SEASON for ACME
    //
    if (!isAcme) {
      if ((!isNewTxn &&
        ((isUnsyncedTransferTxn(txnPrev, state) || isInvestmentAcctTransferTxn(txnPrev, state)) && errors.length === 0)) ||
        ((isUnsyncedTransferTxn(txn, state) || isInvestmentAcctTransferTxn(txn, state)) && errors.length === 0)
      ) {

        if (isNewTxn) {
          errors.push('We are sorry, but creation of transfers to investment accounts or unsynced accounts is not yet supported, please use the desktop for this');
          errorThrown = true;
        } else {
          const diffs = getTxnDifferences(txn, txnPrev);
          const onlyStateChanged = diffs.length === 1 && diffs[0] === 'state';
          if (dateChanged || amountChanged || accountChanged || coaChanged || (!onlyStateChanged && (transactionsUtils.isSplitTxn(txn) || transactionsUtils.isSplitTxn(txnPrev)))) {
            errors.push('We are sorry, but you can only edit the clr status for transfers to investment or unsynced accounts on the web, please use the desktop for this');
            errorThrown = true;
          }
        }
      }
      // QUICKEN ONLY (not Acme)
      // if editing an existing scheduled transaction, use the action API to change the date/amount
      // should not allow editing anything else but the date and amount
      // we should check for 'isNextScheduledInstance' here, but that requires the sched txn state, and not worth it
      //
      if (!isAcme && isUnacceptedScheduledTxn(txn) && (dateChanged || amountChanged)) {
        const scheduledTxnUpdateAction = transactionsActions.performTransactionAction(
          {
            action: 'override',
            id: txn.id,
            overrideNextDueOn: moment(txn.postedOn).format('YYYY-MM-DD'),
            overrideNextAmount: parseFloat(txn.amount),
          }
        );
        extraActions.push({ action: scheduledTxnUpdateAction, delay: 1000 });
      }

    }

    if (isNewTxn && featureFlags.get('warnOnUncategorized') && txn.coa && txn.coa.type === 'UNCATEGORIZED') {
      warnings.push('You are saving the transaction without a category. ' +
       `${isAcme ? 'Simplifi' : 'Quicken'} works best for you when you use categories.`);
    }


    /* *******************************************************************************************
     * Begin Processing the transaction, below are various cases for processing the update/create
     * Start by ensuring there are not validation errors with the fields
     * If an error was thrown above, we just bail out and do not do the subsequent processing/checks
     */

    // convert the amount(s) to numbers
    txn = txn.set('amount', Number(txn.amount || 0));
    if (transactionsUtils.isSplitTxn(txn)) {
      const newItems = txn.split.items.map((item) => item?.set('amount', Number(item.amount)));

      txn.set('split', txn.split.set('items'), newItems);
    }

    // if there is no split, and no coa, make it uncategorized
    if (!transactionsUtils.isSplitTxn(txn) && (!txn.coa || !txn.coa.type)) {
      txn = txn.set('coa', chartOfAccountsTypes.mkChartOfAccount({ type: 'UNCATEGORIZED', id: '0' }));
    }

    const validateError = validateTxn(txn);

    if ((!txn.isDeleted && validateError) || errorThrown) {
      if (!errorThrown) {
        errors.push(`Cannot ${isNewTxn ? 'create' : 'update'} transaction ${txn.payee || ''}: ${validateError}`);
        errorThrown = true;
      }

    /*
     * Do not allow changing the account of a downloaded transaction
     */
    } else if ((txn.cpData || transactionsUtils.isTransferTxn(txn)) && accountChanged) {
      errors.push(`You cannot change the account of a ${txn.cpData ? 'downloaded' : 'transfer'} transaction`);
      errorThrown = true;

    /*
     * NO TRANSFERS in the previous transaction, proceed with update/create
     * Note, the new transaction may have transfers, so we create those
     * THIS IS THE MOST COMMON CASE, previous transaction is being updated and had no transfers
     * OR IF txnPrev is null, there is no previous transaction (this is a create)
     */
    } else if (!txnPrev || !transactionsUtils.isTransferTxn(txnPrev)) {

      const setFlag = !txnPrev || coaChanged;
      // if the category for this transaction is the L1 category for transfers, we set ignore from reports to true

      if (setFlag) {
        if (chartOfAccountsUtils.coaIsBalanceAdjustmentType(txn.coa) || transactionsUtils.isTransferTxn(txn)) {
          // is transfer or adjustment now
          txn = setReportsFlags(txn, true);

        } else if (txnPrev && chartOfAccountsUtils.coaIsBalanceAdjustmentType(txnPrev.coa)) {
          // isn't transfer or adjustment, but used to be, so clear the flag
          txn = setReportsFlags(txn, false);
        }
      }

      const txnPack = createRequiredTransferTxns(txn, txnsByAccountId, featureFlags, state, null);
      localMergeTxnPack(txnPack);

    /*
     * REMAINING CASE ARE FOR PROCESSING THE PRIOR TRANSFER(S) IN THE PREVIOUS TRANSACTION
     */
    } else {

      // retrieve txn candidates for matching transfer items in the split
      const oldDestinationTransfers = getTransferCandidates(txnPrev, txnsByAccountId);

      // if any of the oldDestinationTransfers are also in the transactions to be updated, and we have changed
      // the coa of this transaction, don't allow it
      // TODO Unit Tests for this
      if (coaChanged && oldDestinationTransfers.find(({ txn: destTxn }) =>
        allTxnsToUpdate.find((updateTxn) => updateTxn.id === destTxn.id))) {
        errors.push('Really sorry, but you cannot change the category of both sides of a transfer at the same time');
        errorThrown = true;
      }

      // if the current transaction is no longer a transfer, unset the report flags as well
      if (!transactionsUtils.isTransferTxn(txn) && !chartOfAccountsUtils.coaIsBalanceAdjustmentType(txn.coa)) {
        txn = setReportsFlags(txn, false);
      }

      // create or update other sides of the transfer txn
      const txnPack = createRequiredTransferTxns(txn, txnsByAccountId, featureFlags, state, txnPrev);

      const transfersToDelete = [];
      const transfersToUpdate = txnPack.allTxnsToUpdate;

      const parentTxn = getParentSplitTxn(txn, txnsByAccountId);

      // for each old transfer endpoint, we see if it exists in txnsToUpdate
      // or transfersToPersist. If not, we delete it
      oldDestinationTransfers.forEach((oldTransferPoint) => {

        /*
         * if the destination endpoint is in a split, we cannot edit shared fields
         */
        if (oldTransferPoint.splitItem !== null && (amountChanged || dateChanged || coaChanged || tagsChanged || payeeChanged)) {
          errors.push(`Cannot update transaction "${txn.payee}", the other side of the transfer is in a split.` +
            ' To update it, edit the transfer transaction in the transfer account');
          errorThrown = true;

        /*
         * Destination endpoint is not in a split, so we can make adjustments to transfers
         */
        } else {

          const oldTransfer = oldTransferPoint.txn;

          if (txnPack.allTxnsToUpdate.find((tx) => tx.id === oldTransfer.id) // updating txn
            || txnPack.transfersToPersist.find((tx) => tx.id === oldTransfer.id)) { // keeping txn
            // keep endpoints
          } else if (oldTransfer.cpData) {
            // if the endpoint is a bank downloaded transaction, un-categorize it, and unset reports exclusion
            log.log('Removed one side of transfer', '\nUncategorizing other side');
            transfersToUpdate.push(
              setReportsFlags(oldTransfer.merge({
                coa: { type: 'UNCATEGORIZED', id: '0' },
                transfer: null,
              }), false)
            );
          } else if (!parentTxn || (parentTxn?.id !== oldTransfer.id)) { // endpoint is manual txn
            // we delete the old endpoint (no longer a reference to it in the base txn)
            transfersToDelete.push(oldTransfer.set('isDeleted', true));
          }
        }
      });

      if (!errorThrown) {
        localMergeTxnPack({
          ...txnPack,
          allTxnsToUpdate: transfersToUpdate,
          allTxnsToDelete: transfersToDelete,
        });
      }
    }
  }); // for each txn

  //
  // in some cases during a multi-transaction update, a transfer transaction might be getting updated
  // along with one of it's children.  This can result in a new child transaction being created, and the
  // previous transaction being deleted, but also the previous transaction may attempt to update itself
  // effectively undeleting itself.  We check for any updates conflicting with deletes, and we remove
  // the updates if there is a conflict (delete wins)
  //

  const txnPack = { allMatches, allTxnsToDelete, allTxnsToUpdate, allTxnsToCreate, warnings, errors, extraActions };
  return removeTxnWithInvalidAccountIds(removeConflicts(txnPack), 'preProcessUpdate');

}

export function removeTxnWithInvalidAccountIds(txnPack, funcName) {
  const { allTxnsToCreate, allTxnsToUpdate } = txnPack;

  const newCreate = allTxnsToCreate.filter((txn) => !isBrokenAccountId(txn.accountId));
  const newUpdate = allTxnsToUpdate.filter((txn) => !isBrokenAccountId(txn.accountId));

  const invalidCreate = allTxnsToCreate.filter((txn) => isBrokenAccountId(txn.accountId));
  const invalidUpdate = allTxnsToUpdate.filter((txn) => isBrokenAccountId(txn.accountId));

  const listOfWrongErrorCodeForCreate = [];
  const listOfWrongErrorCodeForUpdate = [];
  invalidCreate.forEach((item) => listOfWrongErrorCodeForCreate.push(item.accountId));
  invalidUpdate.forEach((item) => listOfWrongErrorCodeForUpdate.push(item.accountId));


  if (listOfWrongErrorCodeForCreate.length > 0 || listOfWrongErrorCodeForUpdate.length > 0) {

    log.warn(`Transaction invalid accountId is getting created/updated. Creation count:${listOfWrongErrorCodeForCreate.length} and updation count:${listOfWrongErrorCodeForUpdate.length}`);

    tracker.track(tracker.events.transferTxnCreationWithoutValidAcctId,
      {
        createCount: listOfWrongErrorCodeForCreate.length,
        cUpdateCount: listOfWrongErrorCodeForUpdate.length,
        calledFrom: funcName,
        createAcctIds: JSON.stringify(listOfWrongErrorCodeForCreate),
        UpdateAcctIds: JSON.stringify(listOfWrongErrorCodeForUpdate),
      });
  }

  return ({ ...txnPack, allTxnsToCreate: newCreate, allTxnsToUpdate: newUpdate });
}

function removeConflicts(txnPack) {
  const { allTxnsToDelete, allTxnsToUpdate } = txnPack;

  const newUpdate = allTxnsToUpdate.filter((txn) => !(allTxnsToDelete.find((x) => x.id === txn.id)));

  return ({ ...txnPack, allTxnsToUpdate: newUpdate });
}

function getTransferCandidates(txnPrev, txnsByAccountId) {
  const oldDestinationTransfers = [];
  if (transactionsUtils.isSplitTxn(txnPrev)) {
    txnPrev.split.items.forEach((item) => {
      if (item.coa.type === 'ACCOUNT') {
        const txnsFromTransferAccount = txnsByAccountId.get(item.coa.id) || ImmutableList([]);
        const transferTxns = txnsFromTransferAccount.filter((tx) => transactionsUtils.isTransferTxn(tx));
        const oldTxn = transactionsUtils.findMatchingTransferTx(txnPrev, transferTxns, item.amount);
        oldTxn && oldDestinationTransfers.push(oldTxn);
      }
    });
  } else if (txnPrev.coa.type === 'ACCOUNT') {
    const txnsFromTransferAccount = txnsByAccountId.get(txnPrev.coa.id) || ImmutableList([]);
    const transferTxns = txnsFromTransferAccount.filter((tx) => transactionsUtils.isTransferTxn(tx));
    const oldTxn = transactionsUtils.findMatchingTransferTx(txnPrev, transferTxns);
    oldTxn && oldDestinationTransfers.push(oldTxn);
  }
  return oldDestinationTransfers;
}

//-------------------------------------------------------
// validateTxn
//
function validateTxn(txn) {

  if (!txn.postedOn) {
    return 'Must have a valid date';
  }

  if (Math.abs(txn.amount) >= 1000000000) {
    return "Whoops! Really sorry, but that's a bigger number than we support for a single transaction. Please use values under a billion.";
  }
  if (!transactionsUtils.isSplitTxn(txn) && (!txn.coa || !txn.coa.type)) {
    return 'Please enter a Category/Transfer for this transaction';
  }

  // if (txn.amount === null || String(txn.amount).trim() === '') {
  //   return 'Please specify an amount';
  // }
  return null;
}


