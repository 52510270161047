
import React from 'react';
import PropTypes from 'prop-types';
import { List as ImmutableList } from 'immutable';
import { isAcme } from 'isAcme';

import withStyles from '@mui/styles/withStyles';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// Custom
import StdDialog from 'components/Dialogs/StdDialog';
import SearchBox from 'components/SearchBox';
import TransactionSelectList from 'components/TransactionSelectList';
import QButton from 'components/QButton';
import QPureComponent from 'QPureComponent';
import { getLesserBilledTransactions } from 'data/transactionList/selectors';

const styles = (theme) => ({
  dialogRoot: {
    overflow: 'auto',
  },
  headerSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 24px',
    height: 'fit-content',
    backgroundColor: isAcme ? theme.palette.background.default : 'unset',
  },
  subtitle: {
    color: theme.palette.text.secondary,
    flexShrink: 2,
  },
  searchBar: {
    height: 40,
    paddingLeft: 4,
    borderRadius: 12,
    backgroundColor: theme.palette.greyScaleDeprecated[7],
    border: `1px solid ${theme.palette.greyScaleDeprecated[4]}`,
    margin: 'auto 0 0 4px',
    '&.open': {
      width: 220,
    },
    '&:hover': {
      borderColor: theme.palette.greyScaleDeprecated[0],
    },
    '&:focus-within': {
      borderColor: theme.palette.primary.main,
    },
  },
  searchInput: {
    fontSize: '14px',
    lineHeight: 1.43,
    fontWeight: 'normal',
    color: isAcme ? theme.palette.searchInput : '#000000',
    '&:-webkit-autofill': { // disable autofill color because it makes it ugly
      '-webkit-background-clip': 'text',
    },
  },
  greyGrid: {
    padding: '0px 14px',
    position: 'relative',
  },
  buttonBottoms: {
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 68,
    paddingBottom: 14,
    paddingLeft: 24,
    paddingRight: 24,
    backgroundColor: isAcme ? theme.palette.background.default : 'unset',
  },
  manualLine: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: '#666666',
  },
  manualButton: {
    color: theme.palette.secondary.main,
    marginLeft: 6,
  },
  disabledSave: {
    color: `${theme.palette.greyScaleDeprecated[7]} !important`,
    opacity: 0.5,
  },
  processing: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[7], 0.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

class SelectTransactionDialog extends QPureComponent {

  constructor(props) {
    super(props);
    this.state = {
      selectedTxn: props.multiSelect ? ImmutableList() : null,
      processing: false,
    };
    this.autoTimeout = null;
  }

  componentWillUnmount() {
    if (this.autoTimeout) {
      clearTimeout(this.autoTimeout);
    }
  }

  // This is the function by which a transaction selected from the virtualList in ScheduleList component is stored in
  // this component's state. Couldn't think of a cleaner way to back-pass updates to state. The nested ID checks are
  // used to grab parent ID values, as each transaction row has many child components, but the ID we need is in the
  // parent row.
  selectTxn = (event) => {
    const selectTxn = JSON.parse(event.currentTarget.id);
    let currentTxn = this.state.selectedTxn;
    if (this.props.multiSelect) {
      currentTxn = currentTxn || [];
      if (currentTxn?.size > 0 && currentTxn.find((t) => t.id === selectTxn.id)) {
        this.setState({ selectedTxn: currentTxn.filter((t) => t.id !== selectTxn.id) });
      } else {
        this.setState({ selectedTxn: ImmutableList([...currentTxn, selectTxn]) });
      }
      return;
    }
    if (currentTxn && (currentTxn.id === selectTxn.id)) {
      this.setState({ selectedTxn: null });
    } else {
      this.setState({ selectedTxn: selectTxn });
    }
  };

  handleComplete = () => {
    const { onTransactionSelected } = this.props;
    onTransactionSelected && onTransactionSelected(this.state.selectedTxn);
    this.setState({ processing: true });
    this.autoTimeout = setTimeout(() => this.setState({ processing: false }), 5000);
  };

  onClose = () => {
    this.props.onClose?.();
    this.setState({
      selectedTxn: null,
      search: null,
      processing: false,
    });
  };

  render() {
    const { classes, selectorProps, title, buttonText, disableAdd, subtitle, onTransactionSelected, open,
      transactionListSelector, filterByType, multiSelect, ...otherProps } = this.props;

    const { selectedTxn, processing } = this.state;

    // For backwards compatibility, the default selector is for scheduled transactions
    const derivedSelector = transactionListSelector || getLesserBilledTransactions;
    return (
      <StdDialog
        title={title || 'Select Transaction'}
        open={Boolean(open)}
        fullWidth
        maxWidth="md"
        className={classes.dialogRoot}
        onClose={this.onClose}
        sharedcomponentid={'SELECT_TRANSACTION_DIALOG'}
        {...otherProps}
      >
        <div className={classes.headerSection}>
          <Typography className={classes.subtitle}>
            {subtitle || 'Select or search for a transaction'}
          </Typography>

          <SearchBox
            onSearch={(search) => {
              this.setState({ search });
            }}
            autoSearch
            autoFocus
            collapsable={false}
            initialValue={this.state.search}
            placeholder="Search Transactions"
            classes={{
              root: classes.searchBar,
              input: classes.searchInput,
            }}
          />
        </div>
        <DialogContent className={classes.greyGrid}>
          <TransactionSelectList
            clickFunc={this.selectTxn}
            highlight={selectedTxn?.map?.((t) => t.id) || selectedTxn?.id}
            filter={this.state.search}
            onReset={() => this.setState({ search: null })}
            transactionListSelector={derivedSelector}
            selectorProps={selectorProps}
            filterByType={filterByType}
            multiSelect={multiSelect}
          />

          {processing &&
            <div className={classes.processing}>
              <CircularProgress size={64} className={classes.spinner} />
            </div>}
        </DialogContent>

        <div className={classes.buttonBottoms}>
          <QButton
            type="submit"
            id="create-transaction"
            variant="contained"
            disabled={!selectedTxn || processing}
            onClick={this.handleComplete}
            style={{ marginRight: 10 }}
          >
            { buttonText || 'CREATE' }
          </QButton>

          {!disableAdd &&
            <Typography variant="body2" className={classes.manualLine}>
              {"Couldn't Find?"}
              <Button
                className={classes.manualButton}
                id="add-manually-transaction"
                onClick={() => onTransactionSelected && onTransactionSelected()}
              >
                ADD MANUALLY
              </Button>
            </Typography>}
        </div>
      </StdDialog>
    );
  }
}

SelectTransactionDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onTransactionSelected: PropTypes.func,
  transactionListSelector: PropTypes.func,
  selectorProps: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  disableAdd: PropTypes.bool,
  filterByType: PropTypes.func,
  multiSelect: PropTypes.bool,
};

export default withStyles(styles)(SelectTransactionDialog);
