
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { exists } from 'utils/utils';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import compose from 'utils/compose';
import withStyles from '@mui/styles/withStyles';
import MaterialCombobox from './material-combobox';

const isIe = require('is-iexplorer');

const displayTemplate = (componentProps, item, props, inputValue, theme) => {
  // let stateColor = theme.applyOpacityToHex(theme.palette.common.black, 0);

  const { dataHighlighted, dataSelected, ...other } = props;
  const { menuShowFields } = componentProps;

  const showCat = (menuShowFields || '').includes('category');
  const showAmount = (menuShowFields || '').includes('amount');
  const showPayee = (menuShowFields || 'payee').includes('payee');
  const showTags = (menuShowFields || '').includes('tags');
  const showMemo = (menuShowFields || '').includes('memo');

  // if (dataHighlighted) {
  //   stateColor = theme.palette.divider;
  // }

  if (dataSelected) {
    // stateColor = 'rgba(0,0,0,0.46)';
  }

  // highlight the inputValue
  let str = item.name;
  let strJsx = null;

  if (str) {
    const start = str.indexOf(inputValue);
    if (start >= 0) {
      const strTip = str.slice(0, start);
      const strMid = str.substr(start, inputValue.length);
      const strEnd = str.slice(start + inputValue.length);
      strJsx = (
        <div>
          {strTip}<b>{strMid}</b>{strEnd}
        </div>
      );
    } else {
      strJsx = (
        <div>
          {str}
        </div>
      );
    }

    const miniStyle = { fontSize: '11px', lineHeight: '11px', color: theme.components.payeeField.displayTemplate };
    str = (
      <>
        {showPayee ? strJsx : null}
        {showAmount &&
          <div style={miniStyle}>
            {`Amt: ${item.amountString}`}
          </div>}
        {showCat &&
          <div style={miniStyle}>
            {`Cat: ${item.catString}`}
          </div>}
        {showMemo && item.txn &&
          <div style={miniStyle}>
            {`Memo: ${item.txn.memo}`}
          </div>}
        {showTags && item.tagString && item.tagString.length > 0 &&
          <div style={miniStyle}>
            {`Tags: ${item.tagString}`}
          </div>}
      </>
    );
  }

  return (
    <ListItem
      button
      {...other}
      style={{
        // gets rid of highlight in text box
        // backgroundColor: stateColor,
        textAlign: 'left',
      }}
      key={`autocomplete-item-${item.id}`}
      dense
    >
      <ListItemText
        style={{ fontWeight: dataSelected ? 600 : 'inherit' }}
        primary={str}
      />
    </ListItem>
  );
};

displayTemplate.propTypes = {
  dataHighlighted: PropTypes.bool,
  dataSelected: PropTypes.bool,
};

class FilteredInput extends Component {
  state = {
    isOpen: false,
    currSel: null,
    listItems: null,
  };

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    if (this.props.value !== prevProps.value) {
      this.currentValue = this.props.value;
    }
  }

  onListChange = (items) => {
    this.state.listItems = items;
  };

  keydown = (event) => {
    switch (event.key) {

      // Enter key is for the list to drive a selection of a highlighted item, however, if there is no highlighted
      // item, then Enter should accept the current input (do not stop propogation)
      case 'Enter':
        if (this.state.isOpen && exists(this.state.currSel) && this.state.listItems) {
          event.stopPropagation();
        }
        break;

      case 'Escape':
        if (this.state.isOpen) {
          event.stopPropagation();
        }
        break;

      case 'Tab':
        if (this.state.isOpen) {
          if (this.props.onChange && exists(this.state.currSel) && this.state.listItems) {
            this.props.onChange(this.state.listItems.get(this.state.currSel));
          }
        } else if (this.currentValue) {
          this.props.onChange({ action: 'Tab', name: this.currentValue });
        }
        break;

      default:
        break;
    }
  };

  render() {
    const { data, name, disableUnderline, menuPosition, fontSize, onChangeInput, onBlur, placeholder, label, theme,
      textFieldProps, textFieldVariant, marginProp } = this.props;

    return (
      /* eslint-disable jsx-a11y/no-static-element-interactions */
      <div
        style={{ flex: 2, overflow: 'hidden' }}
        onKeyDown={this.keydown}
      >
        <div>
          <MaterialCombobox
            style={isIe ? { height: 27 } : {}}
            // this is used for setting the name property of the input field
            name={name}
            // This is the list of data that is databound to the combobox
            items={data}
            inputRef={this.props.inputRef}
            value={this.props.value}
            disableUnderline={disableUnderline}
            menuPosition={menuPosition}
            textFieldProps={textFieldProps}
            // This is a template that can be overridden for each list item
            displayTemplate={(item, props, inputValue) => displayTemplate(this.props, item, props, inputValue, theme)}
            // This is placeholder text when the input has focus
            placeholder={placeholder}
            label={label}
            fontSize={fontSize}
            textFieldVariant={textFieldVariant}
            marginProp={marginProp}
            // This affects the search behavior
            // Maybe what you search is different from just
            // one single prop.
            // so this is how one might search across many properties
            searchTemplate={(item) => `${item.name}`}
            // Currently a noop, but you can fire an event
            // as a side effect each time the input changes
            onChangeInput={(e) => {
              this.currentValue = e.target.value;
              onChangeInput(e);
            }}
            onBlur={onBlur}
            // This controls what you see in the actual input
            // when an item is selected
            inputDisplayTemplate={(selectedItem) => `${selectedItem ? selectedItem.name : ''}`}
            onListChange={this.onListChange}
            initialHighlightedIndex={0}
            // This event fires every time there are state changes inside the Combobox
            onStateChange={(
              {
                highlightedIndex,
                // inputValue,
                isOpen,
                // selectedItem,
              }
            ) => {
              // NOTE, material combobox does not accurately tell us when the list
              // closes if it closes due to there being no matches.  So we proxy that here
              // by setting isOpen to reflect whether the current list being rendered has any
              // items at all (in the cases where isOpen is undefined
              //
              if (isOpen !== undefined) {
                this.setState({ isOpen: Boolean(isOpen) });
              } else {
                this.setState({ isOpen: this.state.listItems && this.state.listItems.size > 0 });
              }
              this.setState({ currSel: highlightedIndex });

            }}
            // This (very important) event fires everytime a new item in the combobox
            // Is selected
            onChange={(item) => {
              if (item && this.props.onChange) {
                this.props.onChange(item);
              }
            }}
            width={this.props.width}
          />
        </div>
      </div>
    );
  }
}

FilteredInput.defaultProps = {
  placeholder: 'Payee Name',
};

FilteredInput.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  disableUnderline: PropTypes.bool,
  menuPosition: PropTypes.string,
  inputRef: PropTypes.func,
  fontSize: PropTypes.string,
  onChangeInput: PropTypes.func,
  /* eslint-disable react/no-unused-prop-types */
  menuShowFields: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  theme: PropTypes.object,
  width: PropTypes.string,
  textFieldProps: PropTypes.object,
  textFieldVariant: PropTypes.string,
  marginProp: PropTypes.string,
};


export default compose(
  withStyles({}, { withTheme: true }),
)(FilteredInput);
