import { handleActions } from 'redux-actions';

import { getLogger } from '@quicken-com/react.utils.core';
import { resourceStoreTypes, resourceUpserters } from '@quicken-com/react.flux.core';

import * as actions from './categoryGroupListsActions';

const log = getLogger('data/categoryGroupLists/categoryGroupListsReducer.js');

export const CATEGORY_GROUP_LISTS_REDUCER_KEY = 'categoryGroupListsStore';

const initialState = resourceStoreTypes.mkQcsSyncResourceStore();
const categoryGroupListsReducer = handleActions({
  [actions.getCategoryGroupListsAction]: (state) => {
    log.log('getCategoryGroupListsAction...');
    return state.merge({ isLoading: true });
  },
  [actions.getCategoryGroupListsSuccessAction]: (state, { payload }) => {
    log.log('getCategoryGroupListsSuccessAction...\n', payload);
    return resourceUpserters.upsertResources(state, payload);
  },
  [actions.getCategoryGroupListsFailureAction]:
    (state, { payload: error }) => resourceUpserters.completeWithError(state, error),

}, initialState);

export default categoryGroupListsReducer;
