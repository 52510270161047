import { ConfigTypes, setConfig } from '@quicken-com/react.utils.core';

import { Config, PROTOCOL, PORT, HOSTNAME, ENVIRONMENT, BUILD_TYPE } from './config';

const config = ConfigTypes.mkConfig({
  builds: Object.keys(Config.builds).reduce((result, key) => {
    result[key] = ConfigTypes.mkBuild(Config.builds[key]); // eslint-disable-line no-param-reassign
    return result;
  }, {}),
  client: ConfigTypes.mkClient({
    product: process.env.PROJECT === 'acme' ? 'ACME' : 'QUICKEN',
    product_name: process.env.PROJECT === 'acme' ? 'Simplifi Web App' : 'Quicken Web App',
    app_version: String(process.env.APP_VERSION),
    protocol: PROTOCOL,
    hostname: HOSTNAME,
    port: PORT,
    environment: ENVIRONMENT,
    build_type: BUILD_TYPE,
  }),
  common: ConfigTypes.mkCommon(Config.common),
  environments: Object.keys(Config.environments).reduce((result, key) => {
    result[key] = ConfigTypes.mkEnvironment(Config.environments[key]); // eslint-disable-line no-param-reassign
    return result;
  }, {}),
  hosts: Object.keys(Config.hosts).reduce((result, key) => {
    result[key] = ConfigTypes.mkHost(Config.hosts[key]); // eslint-disable-line no-param-reassign
    return result;
  }, {}),
});

setConfig(config);
