import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import ScheduledTransactionFormQuicken from 'components/ScheduledTransactions/ScheduledTransactionFormQuicken';

const ScheduledTransactionFormContainer = (props) => {

  const [isConfirmationDialogOpen, setConfirmationDialog] = useState(false);
  const [isFormDirty, setDirtyForm] = useState(false);

  return (
    <Dialog
      open={Boolean(props.scheduledTransaction)}
      onClose={() => props.onClose && props.onClose(isFormDirty)}
      aria-labelledby="edit-recurrence-dialog"
    >
      <ScheduledTransactionFormQuicken
        {...props}
        label={(props.scheduledTransaction || {}).id ? 'Edit Series' : 'Add New Series'}
        showCloseButton
        isConfirmationDialogOpen={isConfirmationDialogOpen}
        showConfirmationDialog={setConfirmationDialog}
        setDirtyForm={setDirtyForm}
        isFormDirty={isFormDirty}
      />
    </Dialog>
  );
};

ScheduledTransactionFormContainer.propTypes = {
  classes: PropTypes.object,
  scheduledTransaction: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ScheduledTransactionFormContainer;
