import createCachedSelector, { LruCacheObject } from 're-reselect';
import { createFlexEqualSelector } from 'utils/selectorsHelpers';
import moment from 'moment';

import { categoriesSelectors } from '@quicken-com/react.flux.categories';
import { chartOfAccountsSelectors } from '@quicken-com/react.flux.chart-of-accounts';


import { getCategoryGroupsById } from 'data/categoryGroups/categoryGroupsSelectors';
import { BUDGET_ITEMS_REDUCER_KEY } from './budgetItemsReducer';
import { mkBudgetItem } from './budgetItemsTypes';

export const getStore = (state) => state[BUDGET_ITEMS_REDUCER_KEY];

export const getLoadPending = (state) => getStore(state).loadPending;

export const getIsLoading = (state) => getStore(state).isLoading;

export const getLastSyncDate = (state) => getStore(state).lastSyncDate;

export const getBudgetItems = (state) => getStore(state).resourcesById;

export const getBudgetItemsFiltered = createCachedSelector(
  (state) => ({ obj: getBudgetItems(state), deep: false }),
  (state) => ({ obj: getCategoryGroupsById(state), deep: false }),
  (state) => ({ obj: categoriesSelectors.getCategoriesById(state), deep: false }),
  (state, filter) => ({ obj: filter.budget, deep: false }),
  (state, filter) => ({ obj: filter.startDate, deep: true }),
  (state, filter) => ({ obj: filter.endDate, deep: true }),
  (
    { obj: budgetItemsById },
    { obj: categoryGroupsById },
    { obj: categoriesById },
    { obj: budget },
    { obj: startDate },
    { obj: endDate },
  ) => {
    const items = budgetItemsById.filter((budgetItem) => {
      let include = true;
      include = include && (!budget || budget.id === budgetItem.budgetId);
      const date = include && (startDate || endDate) && moment(budgetItem.startDate).toDate();
      include = include && (!startDate || date >= startDate);
      include = include && (!endDate || date <= endDate);
      return include;
    });

    // We augment the budget items for internal use with the textual NAME of the
    // item, and a flag 'isIncome' to determine if it is income or expense
    let extras = {};
    return items.map((item) => {
      if (item.type === 'GROUP') {
        const groupObj = categoryGroupsById.get(item.groupId);
        extras = { name: 'Everything Else', isIncome: groupObj && groupObj.type === 'INCOME' };
      } else {
        let isIncome;
        if (item.coa.type === 'CATEGORY') {
          const catRec = categoriesById.get(item.coa.id);
          isIncome = catRec && categoriesById.get(item.coa.id).type === 'INCOME';
        } else {
          isIncome = item.amount > 0;
        }
        extras = { name: chartOfAccountsSelectors.getCoaStringSelector(undefined, item.coa, false), isIncome };
      }
      return mkBudgetItem({ ...item.toJS(), ...extras });
    });
  },
)(
  (state, filter) => `${filter.budget ? filter.budget.id : 'all'}:${filter.startDate ? filter.startDate : 'any'}` +
    `:${filter.endDate ? filter.endDate : 'any'}`,
  {
    cacheObject: new LruCacheObject({ cacheSize: 24 }),
    selectorCreator: createFlexEqualSelector,
  },
);
