import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import getSymbolFromCurrency from 'currency-symbol-map';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import EditIcon from '@mui/icons-material/EditRounded';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import { DateTime } from 'luxon';

import { tracker } from '@quicken-com/react.utils.core';
import QMonthNavigator from 'components/QMonthNavigator';
import QButton from 'components/QButton';
import { BUDGET_VIEWS, BUDGET_ANNUAL_SUBVIEWS } from 'containers/BudgetsV2/constants';

import { budgetOptions } from '../../utils';
import { useBudgetOptions } from '../../hooks';
import NestedDropdown from '../NestedDropDown';

const BudgetHeader = ({
  theme,
  classes,
  isLoading,
  budget,
  budgets,
  budgetsCreation,
  goNextMonth,
  goPrevMonth,
  goThisMonth,
  handleBudgetChange,
  startDate,
  onDelete,
  onEdit,
  handleViewChange,
  handleAnnualSubViewChange,
  dashboardRef,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [budgetMonthEditable, setBudgetMonthEditable] = useState(true);
  const { id, name } = budget;
  const { isToDateColumnEnabled, budgetView, annualSubView, setDatasetPreference } = useBudgetOptions(id, dashboardRef);

  const handleShowToDateSetting = () => {
    setDatasetPreference({ budgetOptions: { showToDateColumn: !isToDateColumnEnabled } });
    tracker.track(tracker.events.viewBudgetOptions, { showToDateColumn: isToDateColumnEnabled ? 'Unchecked' : 'Checked' });
  };

  useEffect(() => {
    let budgetsBarObserver = null;
    const budgetsBarSentinel = document.querySelector('#budgetsBarSentinel');
    if (budgetsBarSentinel && typeof IntersectionObserver !== 'undefined') {
      budgetsBarObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // setBudgetMonthEditable(false);
          } else {
            // setBudgetMonthEditable(true);
          }
        });
      }, {
        root: null,
        rootMargin: `-${theme.defaults.headers.headerHeight}px 0px 0px 0px`,
        threshold: 0,
      });
      budgetsBarObserver.observe(budgetsBarSentinel);
    }

    return () => {
      budgetsBarObserver && budgetsBarObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !isLoading && (
    <AppBar
      position="sticky"
      elevation={0}
      className={classes.header}
    >
      <Toolbar className={classes.headerContent}>
        <Box sx={{ flexGrow: 1 }}>
          {budgets && budgets?.size > 1 &&
            <FormControl id="budgets-page-dropdown">
              <Select
                id="budgets-page-select"
                value={budget ? `${id}` : ''}
                onChange={(evt) => handleBudgetChange(evt)}
                variant="outlined"
                size="small"
              >
                {budgets && budgets.valueSeq().map((theBudget, index) => (
                  <MenuItem
                    id={`budgets-select-${index}`}
                    value={theBudget.id}
                    key={theBudget.id}
                  >
                    {theBudget.name}
                    {budgets
                      && theBudget.currency
                      && budgets.reduce((set, aBudget) => { set.add(aBudget.currency); return set; }, new Set()).size > 1
                      && ` (${getSymbolFromCurrency(theBudget.currency)})`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>}
          <FormControl id="budgets-view-dropdown" sx={{ ml: 2 }}>
            <Select
              id="budgets-view-select"
              value={budgetView}
              variant="outlined"
              size="small"
              onChange={(evt) => handleViewChange(budget, annualSubView, evt)}
            >
              {Object.values(BUDGET_VIEWS).map(({ key, label }) =>
                <MenuItem value={key} key={key}>{label}</MenuItem>)}
            </Select>
          </FormControl>
          {budgetView === BUDGET_VIEWS.annual.key && (
            <FormControl id="budgets-annual-subview-dropdown" sx={{ ml: 2 }}>
              <Select
                id="budgets-annual-subview-select"
                value={annualSubView}
                variant="outlined"
                size="small"
                onChange={(evt) => handleAnnualSubViewChange(budget, budgetView, evt)}
              >
                {Object.values(BUDGET_ANNUAL_SUBVIEWS).map(({ key, label }) =>
                  <MenuItem value={key} key={key}>{label}</MenuItem>)}
              </Select>
            </FormControl>
          )}
          {budgetView === BUDGET_VIEWS.monthly.key && (
            <FormControl id="budgets-date-navigator" sx={{ ml: 2 }}>
              <QMonthNavigator
                date={DateTime.fromJSDate(startDate)}
                onPreviousClick={goPrevMonth}
                onCurrentClick={goThisMonth}
                onNextClick={goNextMonth}
              />
            </FormControl>
          )}
        </Box>

        {budgetsCreation && budgetView !== BUDGET_VIEWS.annual.key &&
          <>
            <QButton onClick={onDelete}>
              <DeleteIcon style={{ marginRight: 7 }} />
              {`Delete ${name}`}
            </QButton>

            {budgetMonthEditable &&
              <QButton onClick={onEdit} color="secondary">
                <EditIcon style={{ marginRight: 7 }} />
                {`Edit ${name} `}
              </QButton>}
          </>}
        {budgetView === BUDGET_VIEWS.annual.key && (
          <NestedDropdown
            menuItemsData={budgetOptions({
              isToDateColumnEnabled,
              handleShowToDateSetting,
            })}
            MenuProps={{ elevation: 3 }}
            ButtonProps={{ variant: 'outlined' }}
          />
        )}
      </Toolbar>
    </AppBar>
  );
};

BudgetHeader.propTypes = {
  theme: PropTypes.object,
  classes: PropTypes.object,
  budgets: PropTypes.object,
  budgetsCreation: PropTypes.bool,
  budget: PropTypes.object,
  goNextMonth: PropTypes.func,
  goPrevMonth: PropTypes.func,
  goThisMonth: PropTypes.func,
  handleBudgetChange: PropTypes.func,
  isLoading: PropTypes.bool,
  startDate: PropTypes.any,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  handleViewChange: PropTypes.func,
  handleAnnualSubViewChange: PropTypes.func,
  dashboardRef: PropTypes.string,
};

export default React.memo(BudgetHeader);
