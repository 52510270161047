
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const useStyles = makeStyles((theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 262,
  },

  noOffset: {
    marginLeft: 0,
  },

  greyText: {
    color: theme.palette.text.secondary,
  },
}));


function VisibilitySection(props) {
  const classes = useStyles();
  const { excludeReports, excludeF2S, setExcludeReports, setExcludeF2S, className, leftAlign } = props;

  const onReportsClick = () => {
    setExcludeReports(!excludeReports);
  };
  const onF2SClick = () => {
    setExcludeF2S(!excludeF2S);
  };

  const noOffset = useMemo(() => ({ root: classes.noOffset }), [classes.noOffset]);

  return (
    <div className={classNames(classes.column, className)}>
      <Typography variant="body2" className={classes.greyText}>
        Visibility
      </Typography>

      <FormControlLabel
        control={<Checkbox />}
        label="Ignore from Reports"
        labelPlacement="end"
        onChange={onReportsClick}
        checked={excludeReports}
        classes={leftAlign ? undefined : noOffset}
        id="ignore-reports-toggle"
      />
      <FormControlLabel
        control={<Checkbox />}
        label="Ignore from Spending Plan"
        labelPlacement="end"
        checked={excludeF2S}
        onChange={onF2SClick}
        classes={leftAlign ? undefined : noOffset}
        id="ignore-spending-plan-toggle"
      />
    </div>
  );
}

VisibilitySection.defaultProps = {
  leftAlign: false,
};

VisibilitySection.propTypes = {
  excludeReports: PropTypes.bool,
  excludeF2S: PropTypes.bool,
  setExcludeReports: PropTypes.func,
  setExcludeF2S: PropTypes.func,
  className: PropTypes.string,
  leftAlign: PropTypes.bool,
};

export default memo(VisibilitySection);
