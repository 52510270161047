// @flow
import React from 'react';
import { useSelector } from 'react-redux';

import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

import { getCurrentTheme } from 'themes/themesSelectors';

type Props = {
  children: React.Node,
}

const CombinedProviders = ({ children } : Props) => {
  const theme = useSelector(getCurrentTheme);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <StyledComponentsThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            {children}
          </LocalizationProvider>
        </StyledComponentsThemeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );

};

export default CombinedProviders;
