import React from 'react';
import PropTypes from 'prop-types';
import compose from 'utils/compose';
import { connect } from 'react-redux';
import withTheme from '@mui/styles/withTheme';

import { getLogger } from '@quicken-com/react.utils.core';

import { createDialog } from 'data/rootUi/actions';

import * as ddata from './core';

const log = getLogger('components/QDialogs/index.js');

// Config is passed in by using component when composing wrapper
//
export default () => (WrappedComponent) => {

  class QDialogs extends React.PureComponent {

    dialogAlertCustom = (props) => {

      // only this one takes on an object (props), all others take individual args   (argggh!)
      const dialogData = ddata.dialogAlertCustom({ ...props, iconsOnDialogAlerts: this.props.iconsOnDialogAlerts });
      log.log('Creating Dialog...', dialogData.toJS());
      this.props.createDialog(dialogData);
    };

    dialogAlert = (...args) => {
      const dialogData = ddata.dialogAlert(...args, this.props.iconsOnDialogAlerts);
      log.log('Creating Dialog...', dialogData.toJS());
      this.props.createDialog(dialogData);
    };

    dialogPassword = (...args) => {
      const dialogData = ddata.dialogPassword(...args, this.props.iconsOnDialogAlerts);
      this.props.createDialog(dialogData);
    };

    dialogForm = (...args) => {
      const dialogData = ddata.dialogForm(...args, this.props.iconsOnDialogAlerts);
      log.log('Creating Dialog...', dialogData.toJS());
      this.props.createDialog(dialogData);
    };

    render() {

      const qDialogProps = {
        dialogAlert: this.dialogAlert,
        dialogForm: this.dialogForm,
        dialogPassword: this.dialogPassword,
        dialogAlertCustom: this.dialogAlertCustom,
      };

      // wrapped component with its props, the state from HOC and uiStateProps
      return (
        <WrappedComponent
          {...{
            ...this.props,
            ...qDialogProps,
          }}
        />
      );
    }
  }

  function mapStateToProps() {
    return ({
      iconsOnDialogAlerts: true, // featureFlagsSelectors.getFeatureFlags(state).get('iconsOnDialogAlerts'),
    });
  }

  function mapDispatchToProps(dispatch) {
    return {
      createDialog: (data) => dispatch(createDialog(data)),
    };
  }


  QDialogs.propTypes = {
    createDialog: PropTypes.func,
    iconsOnDialogAlerts: PropTypes.bool,
    theme: PropTypes.object,
  };

  // the HOC itself is wrapped in connect
  return compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTheme,
  )(QDialogs);
};
