import {
  requiredColumns as requiredColumnsQUICKEN,
  availableColumns as availableColumnsQUICKEN,
  fieldData as fieldDataQUICKEN,
  columnDefaults as columnDefaultsQUICKEN,
  clrFieldOptions as clrFieldOptionsQUICKEN,
  recurringEditable as recurringEditableQUICKEN,
} from './transactionsConfigQUICKEN';

export function getColumnDefaults(type = 'default') {
  const defaults = columnDefaultsQUICKEN;
  const key = type && type.toLowerCase();
  return defaults[key] || defaults.default;
}

export function getAvailableColumns(type = 'default') {
  const defaults = availableColumnsQUICKEN;
  const key = type && type.toLowerCase();
  return defaults[key] || defaults.default;
}

export const requiredColumns = requiredColumnsQUICKEN;
export const availableColumns = availableColumnsQUICKEN;
export const fieldData = fieldDataQUICKEN;
export const clrFieldOptions = clrFieldOptionsQUICKEN;
export const recurringEditable = recurringEditableQUICKEN;
export const flagFieldOptions = null;

