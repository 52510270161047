// @flow
import { Record } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';

type AlertPrefsProps = {
  isEnabled: ?boolean,
  isSmsOk: ?boolean,
  isSmsSupported: ?boolean,
  isEmailOk: ?boolean,
  isEmailSupported: ?boolean,
  isPushOk: ?boolean,
  isPushSupported: ?boolean,
}
export type AlertPrefs = RecordOf<AlertPrefsProps>;
export const mkAlertPrefs: RecordFactory<AlertPrefsProps> =
  Record({
    isEnabled: undefined,
    isSmsOk: undefined,
    isSmsSupported: undefined,
    isEmailOk: undefined,
    isEmailSupported: undefined,
    isPushOk: undefined,
    isPushSupported: undefined,
  });

type AlertRuleProps = {
  id: string,
  product: ?string,
  group: ?string,
  type: ?string,
  title: ?string,
  description: ?string,
  inputVariables: ?Object,
  inputValues: ?Object,
  preferences: ?AlertPrefs,
  isHidden: ?boolean,
}
export type AlertRule = RecordOf<AlertRuleProps>;

export const mkAlertRuleDefaults: RecordFactory<AlertRuleProps> =
  Record({
    id: undefined,
    product: undefined,
    group: undefined,
    type: undefined,
    title: undefined,
    description: undefined,
    inputVariables: undefined,
    inputValues: undefined,
    preferences: undefined,
    isHidden: undefined,
  });

export const mkAlertRule = (jsObj: Object = {}): AlertRule => mkAlertRuleDefaults({
  ...jsObj,
  preferences: jsObj.preferences && mkAlertPrefs(jsObj.preferences),
});
