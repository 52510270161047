
/*
 * PREFERENCES REDUCER
 *
 * Actions flow through here, and here we can respond when actions start, and are completed
 *
 */
import { handleActions } from 'redux-actions';

import { getLogger } from '@quicken-com/react.utils.core';

import { PreferencesStore } from './types';
import * as actions from './actions';
import { removePreference, createReplacePreferenceList, setPreference } from './reducers';
const log = getLogger('data/preferences/reducer.js');

const initialState = new PreferencesStore();

export const reducer = handleActions({

  [actions.initAccountPreferences]: (state) => {
    log.log('accountsPrefs reducer: account Prefs, maybe initialize defaults');
    return state;
  },

  //-----------------------------------------
  // GET PREFERENCES
  // called when the getPreferences action is triggered.  Sagas will also respond to this
  // by actually getting the preferences
  //
  [actions.getPreferences]: (state) => {
    log.log('reducer: get Preferences');
    return state.merge({ isLoading: true });
  },
  //-----------------------------------------
  // GET PREFERENCES SUCCESS
  // called after the Sagas completes retrieval of the preferences list
  //
  // at this time, assuming we are getting all preferences (not paged data)
  [actions.getPreferencesSuccess]: (state, { payload: data }) => {
    const newState = createReplacePreferenceList(state, data);
    return newState.merge({ isLoading: false });
  },
  //-----------------------------------------
  // GET PREFERENCES FAILURE
  // called after the Sagas completes retrieval of the preferences list
  //
  [actions.getPreferencesFailure]: (state, { payload: error }) => state.merge({ isLoading: false, error }),

  //-----------------------------------------
  // SET PREFERENCE
  // action called when a new preference is about to be created - optimistic update
  //
  [actions.setPreference]: (state, { payload }) =>
    setPreference(state, { data: payload, response: { data: payload.preference } }),

  //-----------------------------------------
  // SET PREFERENCE FAILURE
  // called after the Sagas failed
  //
  [actions.setPreferenceFailure]: (state, { asyncDispatch }) => {
    asyncDispatch(actions.getPreferences({ section: 'shared', group: 'dataset' }));
    asyncDispatch(actions.getPreferences({ section: 'shared', group: 'user' }));
    return state;
  },

  //-----------------------------------------
  // DELETE PREFERENCE
  //
  [actions.deletePreference]: (state) => {
    log.log('reducer: delete Preference');
    return state;
  },
  //-----------------------------------------
  // DELETE PREFERENCE SUCCESS
  //
  [actions.deletePreferenceSuccess]: (state, { payload: preference }) => removePreference(state, preference),
  //-----------------------------------------
  // DELETE PREFERENCE FAILURE
  //
  [actions.deletePreferenceFailure]: (state) => state,

}, initialState);

export const REDUCER_KEY = 'preferencesStore';
export default reducer;
