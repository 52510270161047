
import { handleActions } from 'redux-actions';
import { List } from 'immutable';

import { resourceUpserters } from '@quicken-com/react.flux.core';
import type { QcsSyncResourcesPayload } from '@quicken-com/react.flux.core/resourceStoreTypes';

import * as actions from './actions';
import { Biller, BillerStore, mkBillerStore } from './types';

const { upsertResource, upsertResources, completeWithError } = resourceUpserters;

const reducer = handleActions({

  [actions.getBillerSuccess]: {
    next(state: BillerStore, { payload: institutionToUpsert, asyncDispatch }: { payload: Biller }) {
      // Use current biller logo if it exists to reduce logo loads
      const billerLogo = state.resourcesById.get(institutionToUpsert.id)?.logo;
      let institutionWithLogo = institutionToUpsert;
      if (billerLogo) {
        institutionWithLogo = institutionToUpsert.merge({ logo: billerLogo });
      } else {
        asyncDispatch(actions.getBillerLogo(institutionToUpsert.id));
      }
      return upsertResource(state, institutionWithLogo, false);
    },
  },
  [actions.getBillerFailure]: (state: BillerStore, { payload: error }) => completeWithError(state, error),

  [actions.getBillerLogoResponse]: (state: BillerStore, { payload: { id, logo } }: { payload: Biller }) => { // eslint-disable-line
    // const base64 = 'data:image/png;base64,' +encodeUnicode(logo);
    // const billerWithLogo = state.resourcesById.get(id).set('logo', base64);
    // return upsertResource(state.set('isLoading', false), billerWithLogo);
    const billerWithLogo = state.resourcesById.get(id)?.merge({ logo: `data:image/jpeg;base64,${logo}` });
    if (billerWithLogo) {
      return upsertResource(state.set('isLoading', false), billerWithLogo);
    }
    return state.set('isLoading', false);
  },

  [actions.getBillerLogoError]: (state: BillerStore, { payload: error }) => completeWithError(state, error),

  [actions.getPopularBillersSuccess]:
    (state: BillerStore, { payload }: { payload: QcsSyncResourcesPayload }) => {
      let newState: BillerStore = upsertResources(state, payload);
      newState = newState.set('popularIds', List(payload.resources.slice(0, 15).map((resource) => resource.id)));
      return newState;
    },

}, mkBillerStore());

export const REDUCER_KEY = 'billerStore';
export default reducer;
