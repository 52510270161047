import { createAction } from 'redux-actions';

export const getRenameRules = createAction('GET_RENAME_RULES');
export const getRenameRulesSuccess = createAction('GET_RENAME_RULES_SUCCESS');
export const getRenameRulesFailure = createAction('GET_RENAME_RULES_FAILURE');

export const createRenameRule = createAction('CREATE_RENAME_RULE', (payload) => payload, (payload, meta) => meta);
export const createRenameRuleSuccess = createAction('CREATE_RENAME_RULE_SUCCESS', (payload) => payload, (payload, meta) => meta);
export const createRenameRuleFailure = createAction('CREATE_RENAME_RULE_FAILURE');

export const updateRenameRule = createAction('UPDATE_RENAME_RULE', (payload) => payload, (payload, meta) => meta);
export const updateRenameRuleSuccess = createAction('UPDATE_RENAME_RULE_SUCCESS');
export const updateRenameRuleFailure = createAction('UPDATE_RENAME_RULE_FAILURE');

export const updateRenameRuleBatch = createAction('UPDATE_RENAME_RULE_BATCH', (payload) => payload, (payload, meta) => meta);
export const updateRenameRuleBatchSuccess = createAction('UPDATE_RENAME_RULE_BATCH_SUCCESS');
export const updateRenameRuleBatchFailure = createAction('UPDATE_RENAME_RULE_BATCH_FAILURE');

export const deleteRenameRule = createAction('DELETE_RENAME_RULE', (payload) => payload, (payload, meta) => meta);
export const deleteRenameRuleSuccess = createAction('DELETE_RENAME_RULE_SUCCESS');
export const deleteRenameRuleFailure = createAction('DELETE_RENAME_RULE_FAILURE');
