import firebase from 'firebase/app';
import 'firebase/messaging';
import 'utils/core/configInit'; // make sure to initialize config before using it
import { getEnvironmentConfig } from '@quicken-com/react.utils.core';

let fbMessaging = null;

/////////////////////////////////////
///////////////////
////////////////////////////////////////////////
//////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
//

//////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////

export const messaging = fbMessaging;
