import { getEnvironmentConfig, httpClientInterface } from '@quicken-com/react.utils.core';
import AxiosFactory from 'utils/axiosFactory';

const qcsAxios = AxiosFactory.get('qcs');

// implement shared interfaces
httpClientInterface.httpClientInterfaceInit({
  getServiceUrl: () => getEnvironmentConfig()?.services_url,
  send: (method, url, data, headers, params) => {
    let promise;
    switch (method) {
      case 'GET':
        promise = qcsAxios.get(url, { headers, params });
        break;
      case 'POST':
        promise = qcsAxios.post(url, data, { headers, params });
        break;
      case 'PUT':
        promise = qcsAxios.put(url, data, { headers, params });
        break;
      case 'DELETE':
        promise = qcsAxios.delete(url, { headers, params });
        break;
      default:
      // TODO: assert
    }
    return promise;
  },
});
