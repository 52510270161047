//
// index
// Quicken
//
// @flow
// Created by Ramesh Anandhan on 7/13/22.
// Copyright © 2022 Quicken Inc. All rights reserved.
//
import store from '@quicken-com/react.utils.redux-store';
import { configFeatureFlagsActions } from '@quicken-com/react.flux.config-feature-flags';

import * as defaultFlags from './clientConfigFlags.json';

const CONFIG_FF_CLIENT_TYPE = 'WEB';
const CONFIG_FF_APP_GROUP = 'Companion';
const CONFIG_FF_PLATFORM = 'Web';

export function initializeClientConfigFlags() {
  store.dispatch(configFeatureFlagsActions.setDefaultConfigFlags(defaultFlags.default));

  getClientConfigFlags();
}

export function getClientConfigFlags() {
  store.dispatch(configFeatureFlagsActions.getConfigFlagsAction({
    clientType: CONFIG_FF_CLIENT_TYPE,
    appGroup: CONFIG_FF_APP_GROUP,
    platform: CONFIG_FF_PLATFORM,
  }));
}

// Should be used to declare Config feature flags and their names.
// { flagName: 'flagName' }
export const ClientConfigFlags = {
  feedbackFeatureList: 'feedbackFeatureList',
  showScheduledTxnDayFrequency: 'showScheduledTransactionDayFrequency',
};
