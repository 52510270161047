import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import compose from 'utils/compose';
import PropTypes from 'prop-types';

import FileDownload from '@mui/icons-material/GetApp';

import QIconButton from 'components/QIconButton';
import QCurrency from 'components/QCurrency';

import Popover from '@mui/material/Popover';
import ButtonBase from '@mui/material/ButtonBase';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import FastCatIcon from '@mui/icons-material/FlashOn';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FilterListIcon from '@mui/icons-material/FilterListRounded';

import SearchBox from 'components/SearchBox';
import QDialogs from 'components/QDialogs';
import FileImportDialog from 'components/Dialogs/FileImportDialog';
import QFilterSelect from 'components/QFilterSelect';
import { showTxnDetailsDialog } from 'components/Transactions/DetailsDialog/actions';

import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';

import { isMobile, isTablet } from 'react-device-detect';
import { FilterObject } from 'components/QFilterSelect/types';
import { countFilterItemsForDisplay } from 'components/QFilterSelect/utils';
import { isAcme } from 'isAcme';

import ScheduleViewMenu from './scheduleViewMenu';

import { styles } from './styles';
import QTip from '../../QuickenControls/QTip';

// ==============================================================
//
// The control bar at the top of the Transaction list (register)
//
// props:
//
// onAdd   - called when add/new transaction pushed
//
//
export class RegControlBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeSearch: props.filter && (props.filter.trim().length > 0),
      showHelp: false,
      showFileImportDialog: false,
      filterAnchorEl: null,
      cancellingFilter: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.filter !== this.props.filter) {
      this.setState({ activeSearch: nextProps.filter && (nextProps.filter.trim().length > 0) });
    }
  }

  onSearch = (string) => {
    if (string !== null) {
      this.setState({ activeSearch: string.trim().length > 0 });
      this.props.onSearch(string.trim());
    }
  };

  downloadFile = () => {
    if (this.props.downloadFile) {
      this.props.downloadFile();
    }
  };

  applyFilter = (filters) => {
    this.setState({ filterAnchorEl: null });
    if (this.props.onApplyFilter) this.props.onApplyFilter(filters);
  };

  cancelFilters = (e) => {
    e.stopPropagation();
    this.setState({ cancellingFilter: true }, () => setTimeout(() => {
      if (this.props.onApplyFilter) this.props.onApplyFilter(new FilterObject());
      this.setState({ cancellingFilter: false });
    }, 10));
  };

  render() {

    const { classes, onFastCategorize, scheduledTransactionsInRegister, accountsFilter,
      transactionFilters, filterObject, theme, csvImport } = this.props;
    const { filterAnchorEl, cancellingFilter } = this.state;

    // If searching
    const { numTxns, sumTxns } = this.props.transactionListMetrics;
    const currSymbol = this.props.getSharedCurrency(this.props.acctTxns);
    const totalText = this.props.formatAmount(sumTxns, currSymbol);
    const selText = ` (${numTxns} Transaction${numTxns > 1 ? 's' : ''} Found: ${totalText})`;

    // have to break into the object and count the size of each Set
    const numFilterItems = countFilterItemsForDisplay(filterObject) + (filterObject?.get('payees').size || 0);

    return (
      <>
        {this.state.showHelp && this.searchRef &&
          <SearchHelpBox
            classes={classes}
            anchorEl={this.searchRef}
            onClose={() => this.setState({ showHelp: false })}
          />}

        {this.state.filterAnchorEl &&
        <QFilterSelect
          onApply={this.applyFilter}
          initialFilter={this.props.filterObject}
          popover
          openPopoverProps={{
            transformOrigin: {
              horizontal: 'left',
              vertical: 'top',
            },
          }}
          anchorEl={this.state.filterAnchorEl}
          onClose={() => this.setState({ filterAnchorEl: null })}
        />}

        <div className={classes.container}>
          <Typography className={classes.title} variant="h6" noWrap>
            Transaction Activity
          </Typography>

          { (this.state.activeSearch || numFilterItems > 0) &&
            <Typography variant="body2" id="reg-header-search-total" className={classes.searchMetrics}>
              {selText}
            </Typography>}


          <div className={classes.searchContainer} ref={(x) => { this.searchRef = x; }}>

            <div className={classes.rightControls}>
              <div
                className={classes.searchBox}
                style={{ borderColor: this.state.filterAnchorEl != null ? theme.palette.primary.main : '' }}
              >
                <SearchBox
                  onSearch={this.onSearch}
                  initialValue={this.props.filter}
                  autoSearch={false}
                  autoFocus
                  onHelp={isAcme ? null : (show) => this.setState({ showHelp: show })}
                />
              </div>
              {transactionFilters &&
              <>
                <div className={classes.filterBox} style={{ borderColor: this.state.filterAnchorEl != null ? theme.palette.primary.main : '' }}>
                  <QIconButton
                    style={{ color: this.state.filterAnchorEl != null ? theme.palette.primary.main : '' }}
                    aria-label="Transaction Filters"
                    onClick={(e) => this.setState(filterAnchorEl ? null : { filterAnchorEl: e.currentTarget })}
                    id="reg-add-filter"
                    IconComponent={FilterListIcon}
                    IconProps={{ className: classes.filterIconButton }}
                    tooltip="Filter List"
                    size="small-target"
                  />
                  <Typography variant="body2" className={classes.filterNum} id="reg-add-filter" onClick={(e) => this.setState(filterAnchorEl ? null : { filterAnchorEl: e.currentTarget })}>
                    {numFilterItems}
                  </Typography>
                  {numFilterItems > 0 &&
                    <ButtonBase
                      className={classes.filterNumClose}
                      onClick={this.cancelFilters}
                    >
                      {cancellingFilter ? '' : <Typography className={classes.filterNumCloseText} variant="body2"> X </Typography>}
                    </ButtonBase>}
                </div>
              </>}
            </div>
            <div className={classes.iconDivider} style={{ minWidth: !(isMobile || isTablet) && csvImport ? 66 : 34 }}>
              {csvImport &&
              <>
                <QIconButton
                  aria-label="Import transactions"
                  id="import-transactions-button"
                  IconComponent={CloudUploadIcon}
                  IconProps={{ className: classes.iconButton }}
                  onClick={(_e) => {
                    this.setState({ showFileImportDialog: true });
                  }}
                  tooltip="Import transactions"
                  size="medium-no-padding"
                />
                <FileImportDialog
                  account={accountsFilter.first()}
                  open={Boolean(this.state.showFileImportDialog)}
                  onHandleClose={() => this.setState({ showFileImportDialog: false })}
                />
              </>}

              {!(isMobile || isTablet) &&
              <QIconButton
                aria-label="Download transactions"
                id="reg-fast-download-csv"
                IconComponent={FileDownload}
                IconProps={{ className: classes.iconButton }}
                onClick={this.downloadFile}
                tooltip="Download transactions"
                size="small-target"
              />}
            </div>

            {scheduledTransactionsInRegister &&
            <div className={classes.scheduleMenuHolder}>
              {this.props.onReminderChange &&
              <ScheduleViewMenu
                reminderSetting={this.props.reminderSetting}
                onChange={this.props.onReminderChange}
                isWindows={this.props.isWindowsDataset}
              />}
            </div>}

            {onFastCategorize &&
            <QIconButton
              aria-label="Categorize transactions"
              id="reg-fast-categorize-all"
              IconComponent={FastCatIcon}
              IconProps={{ className: classes.iconButton }}
              onClick={onFastCategorize}
              tooltip="Automatically categorize uncategorized transactions when a suggestion is available"
              size="small-target"
            />}

            {!this.props.noNew &&
            <QTip
              title="Add transaction"
            >
              <Fab
                variant="circular"
                size="small"
                color="primary"
                id="txn-register-add-txn-button"
                className={classes.addTxnButton}
                aria-label="Add"
                onClick={(e) => {
                  e.stopPropagation();
                  if (this.props.showDebtErrorMessage) {
                    this.props.dialogAlert(
                      'Unsupported Feature',
                      'You may only add, modify or delete loan account transactions on the desktop app',
                      undefined,
                      ['OK'],
                    );
                    return;
                  }
                  if (this.props.txnDetailsModal) {
                    // const account = accountsSelectors.getAccountById(undefined, this.props.defaultAccountId);
                    this.props.dispatchCashFlowTransactionDialog();
                  } else {
                    this.props.showNewTxn();
                  }
                }}
              >
                <AddIcon />
              </Fab>
            </QTip>}
          </div>
        </div>
      </>);
  }
}

RegControlBar.propTypes = {
  onFastCategorize: PropTypes.func,
  onSearch: PropTypes.func,
  downloadFile: PropTypes.func,
  filter: PropTypes.string,
  accountsFilter: PropTypes.object,
  acctTxns: PropTypes.object,
  transactionListMetrics: PropTypes.object,
  scheduledTransactionsInRegister: PropTypes.bool,
  reminderSetting: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onReminderChange: PropTypes.func,
  noNew: PropTypes.bool,
  showNewTxn: PropTypes.func.isRequired,
  isWindowsDataset: PropTypes.bool,
  onApplyFilter: PropTypes.func,
  filterObject: PropTypes.object,
  transactionFilters: PropTypes.bool,
  txnDetailsModal: PropTypes.bool,
  dispatchCashFlowTransactionDialog: PropTypes.func,
  theme: PropTypes.object,
  classes: PropTypes.object,
  csvImport: PropTypes.bool,
  /* eslint-disable react/no-unused-prop-types */
  defaultAccountId: PropTypes.string,

  // QCurrency
  getSharedCurrency: PropTypes.func,
  formatAmount: PropTypes.func,

  // DEBT NOT SUPPORTED IN QUICKEN WEB
  showDebtErrorMessage: PropTypes.bool,

  // QDialogs
  dialogAlert: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    csvImport: featureFlagsSelectors.getFeatureFlag(state, 'csvImport'),
    transactionFilters: featureFlagsSelectors.getFeatureFlag(state, 'transactionFilters'),
    txnDetailsModal: featureFlagsSelectors.getFeatureFlag(state, 'txnDetailsModal'),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    dispatchCashFlowTransactionDialog: () => dispatch(showTxnDetailsDialog({ defaultAccountId: ownProps.defaultAccountId })),
  };
}

export default compose(
  withStyles(styles),
  QCurrency(),
  QDialogs(),
)(connect(mapStateToProps, mapDispatchToProps)(RegControlBar));

/*
 * Title Header sub component
 */
export const SearchHelpBox = (props) => {

  const { classes, anchorEl, onClose } = props;

  return (
    <Popover
      id="search-help-popover"
      open
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className={classes.searchHelpContainer}>
        <Typography className={classes.searchHelpTitle} variant="h6"> Search Tips </Typography>
        <Typography className={classes.searchHelpSubtitle} variant="subtitle1"> Searching for Amounts </Typography>
        <Typography className={classes.helpText} variant="body2"> {`Amount searches that start with '-' or '+' will restrict to expenses or income`}</Typography> {/* eslint-disable-line */}
        <Typography className={classes.helpText} variant="body2"> {'"23" will search for 23.00 or -23.00'} </Typography>
        <Typography className={classes.helpText} variant="body2"> {'"-23.50" will search for -23.50'} </Typography>
        <Typography className={classes.helpText} variant="body2"> {'"-23ish" will search for values roughly around -23.00'}  </Typography>
        <Typography className={classes.helpText} variant="body2"> {'"~-23" will also search for values roughly around -23.00'} </Typography>
        <Typography className={classes.helpText} variant="body2"> {'"+>1000" will search for income transactions greater than 1000.00'} </Typography>
        <Typography className={classes.helpText} variant="body2"> {'"+<1000" will search for income transactions less than 1000.00'} </Typography>
        <Typography className={classes.helpText} variant="body2"> {'"-&50 .to. 100" will search for expense transactions between 50.00 and 100.00'} </Typography>
        <Typography className={classes.searchHelpSubtitle} variant="subtitle1"> Searching for Dates </Typography>
        <Typography className={classes.helpText} variant="body2"> {'"02/03/2020" will search for transactions dated 02/03/2020'}  </Typography>
        <Typography className={classes.helpText} variant="body2"> {'"02/03/2020ish" will search for transactions dated around 02/03/2020'}  </Typography>
        <Typography className={classes.helpText} variant="body2"> {'">02/03/2020" will search for transactions on or past 02/03/2020'}  </Typography>
        <Typography className={classes.helpText} variant="body2"> {'"<02/03/2020" will search for transactions on or before 02/03/2020'}  </Typography>
        <Typography className={classes.helpText} variant="body2"> {'"&02/03/2020 .to. 03/10/2020" will search for transactions between 02/03 and 03/10/2020'}  </Typography>

      </div>
    </Popover>
  );
};
SearchHelpBox.propTypes = {
  classes: PropTypes.object,
  onClose: PropTypes.func,
  anchorEl: PropTypes.object,
};
