import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ButtonBase from '@mui/material/ButtonBase';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import Delete from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import arrowIconRight from 'assets/icon-arrow-right.svg';
import arrowIconDown from 'assets/icons-arrow-down.svg';
import { ResourceRowStyles as styles } from 'components/ResourceRegister/styles';
import CategoryField from 'components/QuickenControls/CategoryField';

import { chartOfAccountsTypes } from '@quicken-com/react.flux.chart-of-accounts';
import { isBrowser } from 'utils/utils';
import isAcme, { isQuicken } from 'isAcme';
import Dump from 'components/Dump';

class ResourceRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      item: { ...props.item },
      isEditing: false,
      editingProp: null,
      selectOpen: false,
    };
  }

  componentDidMount() {
    this.trigerInitialEditing();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.item) !== JSON.stringify(this.props.item)) {
      this.setState({
        item: { ...nextProps.item },
        isEditing: false,
        editingProp: null,
        selectOpen: false,
      });
    }

    if (this.props.editingProps.editingId === this.state.item.id &&
      nextProps.editingProps.editingId !== this.state.item.id &&
      nextProps.editingProps.cancelEditingId === this.state.item.id
    ) {
      this.onCancel(true);
    }
  }

  onBlur = (editProp) => (e) => {
    if (editProp !== 'parent' || (editProp === 'parent' && !(e && e.relatedTarget) && !isBrowser('safari') && !isBrowser('firefox'))) {
      this.changeEditingProp(null);
    }
  };

  onChange = (editProp) => (e) => {
    const getCategory = () => {
      if (this.props.getCategoryByID(e.id)) return this.props.getCategoryByID(e.id).name;
      return '';
    };
    const { item } = this.state;
    const newItem = editProp === 'parent' ? {
      ...item,
      parent: (e && e.id && e.id !== '0') ? (getCategory()) : '',
      values: {
        ...item.values,
        parent: e,
      },
    } : {
      ...item,
      [editProp]: e.target.value,
    };

    this.setState({ item: newItem });
  };

  onCancel = (flagShowName = false) => {
    const { item: { name } } = this.state;

    if (this.hasChanges()) {
      this.props.setDialog(
        'Discard Changes',
        <>Are you sure you want to cancel changes to {flagShowName ? <strong>{name}</strong> : 'this'} category?</>,
        (dialog) => {
          if (['YES', 'Enter'].includes(dialog.action)) {
            this.resetChanges();
          }
          if (['CANCEL'].includes(dialog.action)) {
            this.cleanEditingData(true);
          }
        },
        ['CANCEL', 'YES']
      );
      return;
    }
    this.resetChanges();
  };

  onCollapse = (e) => {
    e.stopPropagation();
    this.props.onCollapse(e);
  };

  onDelete = () => {
    this.props.onDelete(this.state.item);
  };

  onEditMode = (editingProp) => {
    this.requestEditing();
    this.changeEditingProp(editingProp);
  };

  onKeyPress = (nextRow) => (event) => {
    // If select is open all keyPress events must be handled by the selectComponent
    if (this.state.selectOpen) { return; }

    if (event.key === 'Enter') {
      this.onSave();
    } else if (event.key === 'Escape') {
      this.onCancel();
    } else if (event.key === 'Tab' && nextRow && nextRow.id && nextRow.editable) {
      setTimeout(() => this.onEditMode(nextRow.id), 0);
    }
  };

  onSave = () => {
    if (this.hasChanges()) {
      this.props.onSave(this.state.item);
      this.cleanEditingData();
    } else {
      this.onCancel();
    }
  };

  onSelectToggle = () => {
    this.setState({ selectOpen: !this.state.selectOpen });
  };

  changeEditingProp(editingProp) {
    const isEditing = Boolean(editingProp);

    if (!isEditing && !this.hasChanges()) {
      this.cleanEditingData();
    }

    this.setState(() => ({ isEditing, editingProp }));
  }

  getArgs() {
    const { classes, dataDictionary, isChild, isExpanded, isParent, editingProps, rowIndex, onSelect, selectedItems } = this.props;
    const { item, isEditing, editingProp, selectOpen } = this.state;
    const { isNewCategory = false } = item;
    const showEditing = isEditing || this.hasChanges() || isNewCategory;
    const index = rowIndex.length < 2 ? [...rowIndex, 0] : rowIndex;
    const baseId = `category-${index.join('-')}`;

    return {
      classes,
      dataDictionary,
      isChild,
      isExpanded,
      isParent,
      editingProps,
      item,
      isEditing,
      editingProp,
      showEditing,
      isNewCategory,
      baseId,
      selectOpen,
      onSelect,
      selectedItems,
    };
  }

  hasChanges() {
    return JSON.stringify(this.props.item) !== JSON.stringify(this.state.item);
  }

  resetChanges() {
    const { item: { isNewCategory = false } } = this.state;

    this.setState({
      item: { ...this.props.item },
      isEditing: false,
      editingProp: null,
      selectOpen: false,
    });

    if (isNewCategory) {
      this.props.onRemoveNewItem();
    }

    this.cleanEditingData();
  }

  requestEditing() {
    this.props.editingProps.requestEditing(this.state.item.id);
  }

  cleanEditingData(flagSendId = false) {
    this.props.editingProps.cleanEditingData(flagSendId ? this.state.item.id : null);
  }

  trigerInitialEditing() {
    const { editingProp, item: { isNewCategory = false } } = this.state;

    if (isNewCategory && !this.hasChanges() && editingProp === null) {
      this.onEditMode('name');
    }

  }

  renderCollapseIcon(args) {
    const { narrowMode } = this.props;
    const { classes, baseId, isExpanded, isParent } = args;
    return isParent ? (
      <ButtonBase
        id={`${baseId}-collapse-button`}
        className={classNames(classes.collapseIcon, narrowMode ? 'narrowMode' : '')}
        onClick={this.onCollapse}
      >
        <img
          alt={isExpanded ? 'Expand List' : 'Collapse List'}
          src={isExpanded ? arrowIconDown : arrowIconRight}
        />
      </ButtonBase>
    ) : <div className={classNames(classes.collapseIcon, narrowMode ? 'narrowMode' : '')} />;
  }

  renderActionIcons(args) {
    const { classes, baseId, showEditing, isNewCategory, row, onSelect, item, selectedItems } = args;

    if (row.type === 'actionsSelect') {
      const selected = selectedItems?.includes(item.id);
      return (
        <div key={row.id} className={classes.actionsSelectContainer} style={{ flex: row.flex }}>
          <Checkbox
            disabled={this.props.disabled}
            onClick={!this.props.disabled ? () => onSelect(item) : null}
            checked={selected}
            id={`${baseId}-select-box`}
            className={classes.actionIcon}
            aria-label="Select"
            classes={{ root: classes.checkboxScale }}
          />
        </div>
      );
    }

    return (
      <div key={row.id} className={classes.actionsIconsContainer} style={{ flex: row.flex }}>
        {!isNewCategory &&
          <IconButton
            id={`${baseId}-delete-button`}
            className={classNames(classes.hoverIcon, classes.actionIcon)}
            aria-label="Delete"
            onClick={this.onDelete}
            size="large"
          >
            <Delete className={classes.deleteIcon} />
          </IconButton>}
        {showEditing && (
          <div className={classes.editButtonsContainer}>
            <IconButton
              id={`${baseId}-cancel-button`}
              className={classes.actionIcon}
              aria-label="Cancel"
              onClick={() => this.onCancel()}
              size="large"
            >
              <Cancel className={classes.cancelIcon} />
            </IconButton>
            <IconButton
              id={`${baseId}-save-button`}
              className={classes.actionIcon}
              aria-label="Save"
              onClick={this.onSave}
              size="large"
            >
              <CheckCircle className={classes.saveIcon} />
            </IconButton>
          </div>
        )}
      </div>
    );
  }

  renderItemContent(args) {
    const { classes, narrowMode } = this.props;
    const { dataDictionary, isParent, isChild, isEditing, editingProp, item } = args;

    return (
      <div className={classNames(classes.itemContentContainer, narrowMode ? 'narrowMode' : '')}>
        <Dump obj={item} />
        {dataDictionary.map(
          (row, index) => {

            const showIndent = isQuicken ? isChild : isChild && !isParent;
            let root;
            if (showIndent) {
              root = classes?.[`itemIdent-L${item.level}`]; // ACME just have L2 || Quicken has L2 to L16
            } else {
              root = classes.firstItem; // L1
            }

            const nextRow = (index + 1) < dataDictionary.size ? dataDictionary.get(index + 1) : undefined;

            if (row.type === 'actions' || row.type === 'actionsSelect') {
              return this.renderActionIcons({ ...args, row, index });
            }

            if (isEditing && editingProp === row.id && row.editable) {
              return this.renderFields({ ...args, row, index, root, nextRow });
            }

            return this.renderTextLabel({ ...args, row, index, primary: root });
          }
        )}
      </div>
    );
  }

  renderTextLabel(args) {
    const { classes, row, item, index, primary, isChild, baseId } = args;
    const listClasses = index === 0 ? { primary } : {};
    let canGotoEditMode = true;
    let listClassname = classNames(classes.listItemText, row.editable ? classes.labelHover : '');
    let text = item[row.id] ? item[row.id] : row.defaultValue || row.placeholder;

    if (row.type === 'select') {
      text = (row.choices.find((x) => x.value === item[row.id]) || {}).title;
      canGotoEditMode = !isChild;
      listClassname = classNames(listClassname, { [classes.noCursorPointer]: !canGotoEditMode });
    }

    return (<ListItemText
      id={`${baseId}-${row.id}-text`}
      key={row.id}
      primary={text}
      style={{ flex: row.flex }}
      className={listClassname}
      classes={listClasses}
      onClick={row.editable ? (() => canGotoEditMode && this.onEditMode(row.id)) : null}
    />);
  }

  renderFields(args) {
    const { classes, row, item, index, root, baseId, selectOpen, nextRow } = args;
    const sharedProps = {
      id: `${baseId}-${row.id}-field`,
      key: row.id,
      autoFocus: true,
      onBlur: this.onBlur(row.id),
      onChange: this.onChange(row.id),
      onKeyPress: this.onKeyPress(nextRow),
      onKeyDown: this.onKeyPress(nextRow),
      placeholder: row.placeholder,
      value: item[row.id] ? item[row.id] : '',
    };
    let fieldProps = {};

    switch (row.type) {
      case 'string': {
        const inputDefaultClasses = { input: classes.textField };
        fieldProps = {
          InputProps: {
            ...(isQuicken ? { disableUnderline: true } : {}),
            classes: index === 0 ? { root, ...inputDefaultClasses } : inputDefaultClasses,
          },
        };
        break;
      }
      case 'select': {
        fieldProps = {
          select: true,
          classes: {
            root: classes.textField,
          },
          variant: 'standard',
          SelectProps: {
            disableUnderline: true,
            open: selectOpen,
            onOpen: this.onSelectToggle,
            onClose: this.onSelectToggle,
            classes: { select: classes.selectField },
          },
          children: this.renderSelectItems(row.choices),
        };
        break;
      }
      case 'CategoryField': {
        fieldProps = {
          value: '',
          InputProps: {
            classes: { root: classes.textField },
            disableUnderline: true,
            'aria-label': 'Subcategory',
            inputComponent: CategoryField,
            inputProps: {
              blurOnMenuClose: true,
              disableUnderline: true,
              editable: true,
              allowNone: true,
              onlyL1: isAcme,
              filterFn: (cat) => cat.type === chartOfAccountsTypes.CoaTypeEnum.CATEGORY || cat.type === chartOfAccountsTypes.CoaTypeEnum.NONE,
              menuPosition: 'absolute',
              'aria-label': 'Subcategory',
              value: item.values.parent || '',
            },
          },
        };
        break;
      }

      default:
        return null;
    }

    return <div key={sharedProps.key} style={{ flex: row.flex }} className={classes.listItemText}><TextField {...sharedProps} {...fieldProps} /></div>;
  }

  renderSelectItems(choices) {
    return choices.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.title}
      </MenuItem>
    ));
  }

  render() {
    const args = this.getArgs();
    const { classes, showEditing, baseId } = args;
    const itemClasses = classNames(classes.itemContainer, { [classes.itemContainerIsEditing]: showEditing });
    const { onSelect } = this.props;

    return (
      <List id={`${baseId}-row-container`} dense className={classes.listContainer}>
        <ListItem
          id={`${baseId}-row-item`}
          className={itemClasses}
          onClick={onSelect && !this.props.disabled ? () => onSelect(args.item) : null}
        >
          {this.renderCollapseIcon(args)}
          {this.renderItemContent(args)}
        </ListItem>
      </List>
    );
  }
}

ResourceRow.defaultProps = {
  isChild: false,
};

ResourceRow.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.object,
  narrowMode: PropTypes.bool,
  rowIndex: PropTypes.array,
  isChild: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isParent: PropTypes.bool,
  dataDictionary: PropTypes.object,
  onCollapse: PropTypes.func,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
  selectedItems: PropTypes.object,
  onRemoveNewItem: PropTypes.func,
  getCategoryByID: PropTypes.func,
  setDialog: PropTypes.func,
  editingProps: PropTypes.object,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(ResourceRow);
