import { createAction } from 'redux-actions';

export const getPreferences = createAction('GET_PREFERENCES');
export const getPreferencesUser = createAction('GET_PREFERENCES_USER');
export const getPreferencesSuccess = createAction('GET_PREFERENCES_SUCCESS');
export const getPreferencesFailure = createAction('GET_PREFERENCES_FAILURE');

export const setPreference = createAction('SET_PREFERENCE', (payload) => payload, (payload, meta) => meta);
export const setPreferenceSuccess = createAction('SET_PREFERENCE_SUCCESS');
export const setPreferenceFailure = createAction('SET_PREFERENCE_FAILURE', (payload) => payload, (payload, meta) => meta);

export const deletePreference = createAction('DELETE_PREFERENCE');
export const deletePreferenceSuccess = createAction('DELETE_PREFERENCE_SUCCESS');
export const deletePreferenceFailure = createAction('DELETE_PREFERENCE_FAILURE');

export const initAccountPreferences = createAction('INIT_ACCOUNT_PREFERENCES');
export const resetAllPreferences = createAction('RESET_ALL_PREFERENCES');
