// @flow
import { combineActions, handleActions } from 'redux-actions';

import { resourceUpserters, resourceStoreTypes, resourceUndoUpserters } from '@quicken-com/react.flux.core';

import * as actions from './actions';

const alertRulesReducer = handleActions({
  [actions.getAlertRulesSuccess]:
    (state, { payload: data }) => resourceUpserters.upsertResources(state, data),

  [actions.updateAlertRule]:
    (state, action) => resourceUndoUpserters.upsertResourceSetupUndo(state, action, actions.updateAlertRule),
  [actions.updateAlertRuleSuccess]:
    (state, { payload }) => resourceUpserters.upsertResource(state, payload),

  [combineActions(
    actions.getAlertRulesFailure,
  )]:
    (state, { payload: error }) => resourceUpserters.completeWithError(state, error),

  [actions.updateAlertRuleFailure]:
    (state, { payload: error, asyncDispatch }) => {
      asyncDispatch(actions.getAlertRules());
      return resourceUpserters.resetResources(state, error);
    },
}, resourceStoreTypes.mkQcsSyncResourceStore());

export const ALERT_RULES_REDUCER_KEY = 'alertRulesStore';
export default alertRulesReducer;
