import { trackerInterface } from '@quicken-com/react.utils.core';
import mixpanel, { dynamicDefaultPropertiesCreator } from 'utils/core/mixpanel';

trackerInterface.trackerInterfaceInit({
  track: (event, props) => mixpanel.track(event, props),
  identify: (userId) => mixpanel.identify(userId),
  getIdentity: () => mixpanel.get_distinct_id(),
  timeEvent: (event) => mixpanel.time_event(event),
  dynamicDefaultProperties: dynamicDefaultPropertiesCreator,
  updateProfile: (props) => mixpanel.people.set(props),
});

