import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import ArrowRight from '@mui/icons-material/ArrowRight';
import classNames from 'classnames';
import { useStyles } from './styles';

const BulletListItem = (props) => {
  const { classes } = props;
  return (
    <div className={classNames(classes.bulletListItem, props.className)}>
      <ArrowRight className={props.classes.bullet} />
      <Typography className={props.classes.bodyText}>
        {props.title}
      </Typography>
    </div>
  );
};

BulletListItem.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
};

const GettingStartedModule = (props) => {
  const { config } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography className={classes.title}>
          {config.title}
        </Typography>
        {config.bullets.map((item) => (
          <BulletListItem
            key={`BLI:${item}`}
            title={item}
            classes={classes}
            className={classes.bulletItem}
          />))}
      </div>
      <div className={classes.imageRoot}>
        <img
          alt="getting started intro screen"
          src={config.img}
          className={classes.image}
        />
      </div>
    </div>
  );
};

GettingStartedModule.propTypes = {
  config: PropTypes.object,
};

export default GettingStartedModule;
