import React from 'react';
import LoadingView from 'components/LoadingView';

const PersistLoading = () => (
  <LoadingView
    in
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }}
    title="loading..."
  />
);
export default PersistLoading;
