import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import compose from 'utils/compose';
import withStyles from '@mui/styles/withStyles';

import { getLogger } from '@quicken-com/react.utils.core';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';

import AccountList from 'components/AccountList';
import QPreferences from 'components/QPreferences';
import { doAccountDiscovery } from 'components/Dialogs/AccountDiscovery/actions';
import { getSharedPreferencesByPath } from 'data/preferences/selectors';
import { ShowBalance } from 'data/preferences/types';
import { authSelectors } from '@quicken-com/react.flux.auth';

import { isAcme } from 'isAcme';

import { AccountDrawerToggle, AccountListHeader } from './components';
import { styles } from './styles';

const log = getLogger('component/AccountDrawer/index.js');

export class AccountDrawer extends React.PureComponent {

  constructor(props) {
    super(props);

    log.log('Constructor');

    this.state = {
      drawerOpen: props.open,
      selectedAccountNodeId: props.selectedNode,
      currentRoute: null,
    };
  }

  componentDidMount() {
    if (this.props.drawerChange) window.addEventListener('resize', this.resizeThrottler);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedNode !== this.props.selectedNode) {
      this.setState({ selectedAccountNodeId: nextProps.selectedNode });
    }
    if (nextProps.open !== this.props.open) {
      this.setState({ drawerOpen: nextProps.open });
    }
    // Sync the value of the preference if it is different from current state
    if (nextProps.accountDrawerDefaultOpen !== this.props.accountDrawerDefaultOpen && nextProps.accountDrawerDefaultOpen !== this.state.drawerOpen) {
      this.props.drawerChange && this.props.drawerChange(this.state.drawerOpen);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeThrottler);
  }

  handleResize = () => {
    if (this.props.drawerChange) {
      const isWidthChanged = window.innerWidth !== this.widthBeforeResize;
      this.widthBeforeResize = null;
      if (!isWidthChanged) {
        return;
      }
      this.setState({ drawerOpen: window.innerWidth >= (this.props.theme.components.accountDrawer.collapsePoint) });
      this.forceUpdate();
    }
  };

  resizeThrottler = () => {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(this.handleResize, 66);

    if (this.widthBeforeResize === null) {
      this.widthBeforeResize = window.innerWidth;
    }
  };

  handleDrawerToggle = (e) => {
    if (this.props.drawerChange) {
      e.stopPropagation();
      this.setState({ drawerOpen: !this.state.drawerOpen });
      this.props.drawerChange(!this.state.drawerOpen);
    }
  };

  handleAccountNodeSelect = (currentNode) => {
    let node = currentNode;
    // For Separate accounts the route can be transactions and have investment accounts inside
    if (node.routeTo === '/transactions' && (node.account?.type === 'INVESTMENT' || node.id === 'BROKERAGE')) {
      node = node.set('routeTo', '/investments');
    }
    // if the user specified a handler, call the handler.
    // if it returns 'true' also execute the default action
    let doDefault = true;
    if (this.props.nodeSelect) {
      doDefault = this.props.nodeSelect(node);
    }
    if (doDefault && node.routeTo) {
      if (typeof node === 'string') {
        this.setState({ selectedAccountNodeId: node });
        //
        // if switching to a new page, do a 'push', otherwise switches on the same page do a 'replace'
      } else if (!this.state.selectedAccountNodeId ||
        (this.state.currentRoute !== node.routeTo)) {
        this.props.history.push(`${node.routeTo}?displayNode=${node.id}`);
        this.setState({ currentRoute: node.routeTo });
      } else {
        this.props.history.replace(`${node.routeTo}?displayNode=${node.id}`);
        this.setState({ currentRoute: node.routeTo });
      }
    }
  };

  handleAddInstitutionLogin = (initiator, directToManualAccounts = false) => (event) => {
    if (this.props.addFiEnabled && this.props.isWebFirstDataset) {
      event?.stopPropagation();
      this.props.doAccountDiscovery?.(initiator, directToManualAccounts);
    }
  };

  renderDrawer = (open) => {

    const { classes, dimAccountList, drawerChange, prefix, showBalance } = this.props;
    const { transactionRegister: regPrefs } = this.props.datasetPreferences;
    const { accountBarBalanceType, accountBarShowCents } = this.props.datasetPreferences;

    // hardcode to 'available balance', or 'today'
    const balanceType = isAcme ? showBalance : accountBarBalanceType;

    const showColors = regPrefs && regPrefs.showAccountColors && !this.props.suppressColorBars; // regPrefs.regFieldsSort.cashFlow.indexOf('accountColor') >= 0;

    return (
      <>
        {drawerChange &&
          <div
            className={open ? classes.overlay : classes.overlayClosed}
            onClick={this.handleDrawerToggle}
            role="presentation"
          />}
        <div
          ref={(x) => { this.balRef = x; }}
          className={classes.container}
        >
          <div
            className={open ? classes.drawerPaper : classes.drawerPaperClosed}
          >
            {/* eslint-disable jsx-a11y/no-static-element-interactions */}
            <div
              id={`${prefix}qCard-add-account`}
              className={classes.drawerHeader}
              style={{ backgroundColor: !open && 'inherit' }}
              onClick={() => !isAcme && this.props.history.replace('/transactions?displayNode=all')}
              onKeyDown={() => {}}
            >
              <AccountListHeader
                classes={classes}
                handleAddInstitutionLogin={this.handleAddInstitutionLogin}
                addFiEnabled={this.props.addFiEnabled && this.props.isWebFirstDataset}
              />
              {drawerChange &&
                <AccountDrawerToggle
                  open={open}
                  classes={classes}
                  handleDrawerToggle={this.handleDrawerToggle}
                  anchorEl={this.balRef}
                />}
            </div>

            {/* Accounts List */}
            <div
              className={classNames(classes.accountDrawerContent, dimAccountList ? 'dim' : '')}
            >
              {open &&
                <AccountList
                  dimAccountList={dimAccountList}
                  onSelect={this.handleAccountNodeSelect}
                  selectedNodeId={this.state.selectedAccountNodeId}
                  handleAddInstitutionLogin={this.handleAddInstitutionLogin}
                  prefix={prefix}
                  showColors={showColors}
                  balanceType={balanceType}
                  omitCents={isAcme ? false : !accountBarShowCents}
                />}
            </div>
          </div>
        </div>
      </>
    );
  };


  render() {

    return this.renderDrawer(this.state.drawerOpen);

  }
}

function mapStateToProps(state) {
  return {
    addFiEnabled: featureFlagsSelectors.getFeatureFlags(state)?.get('addFi'),
    isWebFirstDataset: authSelectors.getIsWebfirstDataset(state),
    showBalance: featureFlagsSelectors.getFeatureFlag(state, 'balanceToggle')
      ? getSharedPreferencesByPath(state, { group: 'dataset', path: ['shared', 'showBalance'] }) || ShowBalance.INCLUDE_PENDING
      : 'today',
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const initiatorSuffixAcme = ownProps.prefix === 'dashboard' ? ' dashboard' : ' txns';
  const defaultInitiator = `account list${isAcme ? initiatorSuffixAcme : ' app'}`;
  return {
    doAccountDiscovery: (initiator, directToManualAccounts = false) => dispatch(doAccountDiscovery({ initiator: initiator || defaultInitiator, mode: directToManualAccounts ? 'ADD-FI-MANUAL' : 'ADD-FI' })),
  };
}

AccountDrawer.propTypes = {
  dimAccountList: PropTypes.bool,
  history: PropTypes.object,
  nodeSelect: PropTypes.func,
  selectedNode: PropTypes.string,
  drawerChange: PropTypes.func,
  open: PropTypes.bool,
  suppressColorBars: PropTypes.bool,
  accountDrawerDefaultOpen: PropTypes.bool,
  prefix: PropTypes.string,

  /* Generated */
  classes: PropTypes.object,
  datasetPreferences: PropTypes.object,
  doAccountDiscovery: PropTypes.func,
  theme: PropTypes.object,
  addFiEnabled: PropTypes.bool,
  showBalance: PropTypes.string,
  isWebFirstDataset: PropTypes.bool,
};

export default compose(
  withStyles(styles, { withTheme: true }),
  QPreferences(),
  connect(mapStateToProps, mapDispatchToProps),
)(AccountDrawer);

