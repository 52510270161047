import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const MainNavItem = React.forwardRef((props, ref) => {

  const { nav, classes, selected, displayNode, iconSize, closeHeader, iconRef, canOpenSubMenu, handleTracking } = props;
  const IconComponent = nav.img;
  const aId = nav.id || `nav-menu-${(nav.title || 'default')}`.toLowerCase().replace(' ', '-').replace('/', '');
  let { path } = nav;
  const cutSelected = (selected) ? `/${selected.split('/')[1]}` : '';
  const hasSubMenu = nav?.subMenuItems?.length >= 1;

  if (nav.useSelectedAccount) {
    path = `${path}?displayNode=${displayNode}`;
  }

  const subMenuShown = React.useRef(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleParentHover = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const onMenuItemClick = () => {
    handleTracking(path);
    setTimeout(() => {
      subMenuShown.current = false;
      setAnchorEl(null);
      closeHeader();
    }, 500);
  };

  const onLeaveParentMenu = () => {
    setTimeout(() => {
      if (!subMenuShown.current) {
        setAnchorEl(null);
      }
    }, 100);
  };

  const onSubMenuMouseOver = () => {
    subMenuShown.current = true;
  };

  return (
    <>
      <ButtonBase
        onClick={onMenuItemClick}
        className={classNames(classes.navMenuItem, cutSelected === nav.path && classes.selectedItem)}// classes.navButton}
        to={path}
        component={NavLink}
        id={aId}
        ref={ref}
        onMouseEnter={!hasSubMenu ? null : handleParentHover}
        onMouseLeave={!hasSubMenu ? null : onLeaveParentMenu}
      >
        {/* Below will keep the full nav item w/ darker background color when selected */}
        <span ref={iconRef}>
          <IconComponent
            title={nav.alt}
            fill="currentColor"
            height={iconSize}
            width={iconSize}
          />
        </span>
        <Typography
          style={{ marginLeft: 24, textAlign: 'left' }}
          className={cutSelected === nav.path ? classes.selectedNavText : classes.navText}
        >
          {nav.title}
          {hasSubMenu && <ChevronRightIcon className={classes.mainNavChevronRightIcon} />}
        </Typography>
      </ButtonBase>
      {hasSubMenu && canOpenSubMenu && (
        <div onMouseEnter={onSubMenuMouseOver}>
          <Menu
            id="main-nav-sub-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onMenuItemClick}
            MenuListProps={{ onMouseLeave: onMenuItemClick }}
            autoFocus={false}
            className={classes.mainNavSubMenu}
          >
            {nav.subMenuItems.map((subMenuItem) => (
              <MenuItem key={subMenuItem.title} onClick={onMenuItemClick} to={subMenuItem.path} component={NavLink}>
                {subMenuItem.title}
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}
    </>
  );
});

MainNavItem.propTypes = {
  nav: PropTypes.object,
  classes: PropTypes.object,
  iconSize: PropTypes.number,
  selected: PropTypes.string,
  displayNode: PropTypes.string,
  closeHeader: PropTypes.func,
  iconRef: PropTypes.object,
  canOpenSubMenu: PropTypes.bool,
  handleTracking: PropTypes.func,
};

