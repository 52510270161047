import { isAcme } from 'isAcme';
import { isMobile } from 'react-device-detect';

export const styles = (theme) => {

  const accountDrawerOverlapBreakpoint = '@media (max-width:960px)';

  const { accountDrawer } = theme.components;

  return ({
    overlay: {
      display: 'none',
      width: '100%',
      height: '100vh',
      backgroundColor: 'rgba(0,0,0,.35)',
      cursor: 'pointer',
      [accountDrawerOverlapBreakpoint]: {
        position: 'absolute',
        display: 'block',
        zIndex: theme.zIndex.drawer,
      },
    },
    overlayClosed: {
      display: 'none',
      width: '0',
      height: '0',
    },
    container: {
      overflow: 'visible',
      height: '100%',
      display: 'flex',
      [accountDrawerOverlapBreakpoint]: {
        position: 'relative',
      },
    },
    drawerPaper: {
      marginTop: 0,
      position: 'relative',
      minWidth: accountDrawer.width,
      width: accountDrawer.width,
      background: accountDrawer.background,
      minHeight: '100vh',
      fontSize: 14,
      fontFamily: 'Helvetica Neue',
      zIndex: theme.zIndex.drawer,
      '&::-webkit-scrollbar': { display: 'none' }, // Safari and Chrome
      'scrollbar-width': 'none', // Firefox
      '-ms-overflow-style': 'none', // Internet Explorer 10+
      transition: accountDrawer.accountDrawerAnimation,
      marginLeft: 0,
      overflow: 'hidden',
      height: '100%',
      [accountDrawerOverlapBreakpoint]: {
        position: 'absolute',
        left: 0,
      },
    },
    drawerPaperClosed: {
      extend: 'drawerPaper',
      minWidth: accountDrawer.collapsedWidth,
      width: accountDrawer.collapsedWidth,
    },
    accountDrawerContent: {
      backgroundColor: isAcme ? theme.components.overviewPage.dashBackground : theme.palette.primary,
      position: 'relative',
      minWidth: accountDrawer.width,
      [accountDrawerOverlapBreakpoint]: {
        minWidth: `calc(100vw - ${theme.components.navigation.barWidth}px - ${theme.spacing(7)}) + 1px`,
      },
      overflowX: 'hidden',
      transition: 'opacity .25s ease',
      '&.dim': {
        opacity: '0.3',
        transitionDelay: '300ms',
      },
      marginTop: isAcme ? theme.defaults.headers.subHeaderHeight : theme.defaults.headers.subHeaderHeightTall,
      height: `calc(100% - ${isAcme ? theme.defaults.headers.subHeaderHeight : theme.defaults.headers.subHeaderHeightTall}px)`,
      overflowY: isMobile ? 'auto' : 'hidden',
      '&:hover': {
        overflowY: 'auto',
      },
    },
    drawerHeader: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 'inherit',
      minWidth: theme.components.accountDrawer.width,
      cursor: !isAcme ? 'pointer' : null,
      overflow: 'hidden',
      height: isAcme ? theme.defaults.headers.subHeaderHeight : theme.defaults.headers.subHeaderHeightTall,
      background: accountDrawer.headerBackground,
      padding: isAcme ? `32px ${accountDrawer.listPaddingRight}px 8px 0` : '4px 10px 0px 0px',
      paddingLeft: accountDrawer.basePadding,
    },
    drawerSubTitle: {
      fontSize: '12px',
      color: theme.palette.secondary.main,
      flex: 2,
      paddingLeft: 14,
    },
    drawerHeaderIcons: { //
      color: accountDrawer.actionColor,
      backgroundColor: accountDrawer.actionBackground,
      opacity: 1,
    },
    roundedButton: {
      height: 32,
      width: 32,
      padding: 6,
      borderRadius: '50%',
    },
    drawerOpenControl: {
      position: 'fixed',
      left: accountDrawer.collapsedWidth + theme.components.navigation.barWidth - 13,
      zIndex: 1200,
      top: isAcme ? (theme.defaults.headers.subHeaderHeightTall - 12) : (theme.defaults.headers.subHeaderHeightTall - 24) / 2,
      borderRadius: '50%',
      border: `1px solid ${accountDrawer.divider}`,
      background: accountDrawer.openControl,
      height: 24,
      width: 24,
      margin: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&.open': {
        left: accountDrawer.width + theme.components.navigation.barWidth - 13,
      },
      '& > div': {
        height: 26,
      },
      transition: accountDrawer.accountDrawerAnimation,
    },
    hide: {
      display: 'none',
    },
    divider: {
      background: accountDrawer.divider,
    },
    drawerIcons: {
      color: accountDrawer.icon,
    },
    buttonShift: {
      marginRight: -22,
    },
    borderCols: {
      '&:focus-within': {
        outlineWidth: '0px',
      },
    },
    drawerListTitle: {
      color: accountDrawer.headerListText,
      fontWeight: accountDrawer.titleWeight,
      flexGrow: 1,
      fontSize: accountDrawer.drawerListTitleFontSize,
      textTransform: accountDrawer.titleTransform,
      textAlign: 'left',
    },
  });
};


