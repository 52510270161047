import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from 'immutable';

import { getLogger } from '@quicken-com/react.utils.core';
import { accountsTypes } from '@quicken-com/react.flux.accounts';

import type { Institution } from 'data/institutions/types';
import { getInstitutionLoginsById } from 'data/institutionLogins/selectors';
import { mkCredential, mkInstitutionLogin } from 'data/institutionLogins/types';

import { bindPromiseAction } from 'utils/actionHelpers';

import NewOrDiscoverForm from '../NewOrRediscoverPicker';
import * as selectors from '../selectors';
import * as actions from '../actions';

import FinicityConnect from './FinicityConnect';
import PlaidLink from './PlaidLink';

const log = getLogger('WidgetAuthenticator/index.js');

const mkNewInstitutionLogin = (institution, cpSuccessData, overrides = {}, _forceNew = false) => (
  mkInstitutionLogin({
    cpSetupMode: 'DISCOVER_AND_ADD_ACCOUNTS',
    institutionId: institution.id,
    channel: cpSuccessData?.channel,
    name: institution.name,
    credentials: cpSuccessData?.token
      ? List([mkCredential({ key: 'public-token', value: cpSuccessData.token })])
      : List([mkCredential({ key: 'DUMMY', value: 'DUMMY' })]),
    aggregators: cpSuccessData?.channel ? List([accountsTypes.mkAccountAggregator({ channel: cpSuccessData.channel })]) : undefined,
    ...overrides,
  })
);

type Props = {
  scope: string,

  aggregator: 'FINICITY' | 'PLAID',
  fiBrandingIndent: number,
  institution: Institution,
  trackingProperties: any,
  updateMode: boolean,

  onBack: (any) => void,
}

const WidgetAuthenticator = forwardRef((props: Props, ref) => {
  const { aggregator, fiBrandingIndent, institution, onBack, scope, trackingProperties, updateMode } = props;
  const dispatch = useDispatch();

  const pageRef = useRef(null);

  const [page, setPage] = useState('WIDGET');
  const [cpSuccessData, setCpSuccessData] = useState();

  const [userSelectedInstitutionLogin, setUserSelectedInstitutionLogin] = useState();

  const institutionLoginToUpdate = useSelector((state) => selectors.getInstitutionLogin(state, { scope }));
  const institutionLoginsById = useSelector(getInstitutionLoginsById);

  useImperativeHandle(ref, () => ({
    handleBack: () => {
      if (pageRef.current?.handleBack) {
        pageRef.current?.handleBack();
      } else {
        onBack();
      }
    },
  }));

  // discover may come back with an existing institution login
  //   case 1) exact match so we just update
  //   case 2) no match but we have existing institutions
  //     - user select institutions, do re-discover with selected institution


  const doDiscoverWithPolling = useCallback(async (institutionLogin, pollingRef) => {
    const discoverInstitutionLoginWithAccountsByPolling = bindPromiseAction(dispatch, actions.discoverInstitutionLoginWithAccountsByPolling);
    return discoverInstitutionLoginWithAccountsByPolling({ institutionLogin, pollingRef }, { scope });
  }, [dispatch, scope]);

  const doDiscover = useCallback(async (institutionLogin, accounts) => {
    const discoverInstitutionLoginWithAccounts = bindPromiseAction(dispatch, actions.discoverInstitutionLoginWithAccounts);
    return discoverInstitutionLoginWithAccounts({ institutionLogin, accounts }, { scope });
  }, [dispatch, scope]);

  const discover = useCallback(async () => {
    const { accounts, existingLoginsAtInstitution, pollingRef } = cpSuccessData;
    if (existingLoginsAtInstitution.length === 0) {
      // if we do not have any other logins to same institution, then we can get and set discover response
      const response = pollingRef
        ? await doDiscoverWithPolling(mkNewInstitutionLogin(institution), pollingRef)
        : await doDiscover(mkNewInstitutionLogin(institution, cpSuccessData), accounts);
      const updateStrategy = response.institutionLogin.id ? 'UPDATE' : 'REPLACE';
      dispatch(actions.discoverInstitutionLoginWithAccountsResponse({ ...response, updateStrategy }, { scope }));
    } else if (userSelectedInstitutionLogin) {
      // if we have user specified institution (could be new), then we rediscover and set discover response
      const response = await doDiscover(userSelectedInstitutionLogin, accounts);
      dispatch(actions.discoverInstitutionLoginWithAccountsResponse({ ...response, updateStrategy: 'REPLACE' }, { scope }));
    } else {
      // if we have potentially ambiguous login (unclear whether user is attempting to add new login or update existing one
      const response = pollingRef
        ? await doDiscoverWithPolling(mkNewInstitutionLogin(institution), pollingRef)
        : await doDiscover(mkNewInstitutionLogin(institution, cpSuccessData), accounts);
      if (response instanceof Error) {
        dispatch(actions.discoverInstitutionLoginWithAccountsResponse(response, { scope }));
        return;
      }
      const { institutionLogin } = response;
      if (institutionLogin?.id) {
        // QCS determined that we should update existing login
        // TODO: How to handle case where login is invalidated if another item is created (e.g. Chase OAuth with Plaid)
        dispatch(actions.discoverInstitutionLoginWithAccountsResponse({ ...response, updateStrategy: 'UPDATE' }, { scope }));
      } else {
        // first time we receive accounts, store them for rediscovery
        if (pollingRef) {
          const { accountDiscoveryData } = response;
          const accountsFromPolling = accountDiscoveryData.newAccounts.toArray()
            .concat(accountDiscoveryData.activeAccounts.toArray(), accountDiscoveryData.deadAccounts.toArray());
          setCpSuccessData({ ...cpSuccessData, accounts: accountsFromPolling });
        }
        setPage('NEW_OR_REDISCOVER_PICKER');
        dispatch(actions.discoverInstitutionLoginWithAccountsDiscard(null, { scope }));
      }
    }
  }, [cpSuccessData, dispatch, doDiscover, doDiscoverWithPolling, institution, scope, userSelectedInstitutionLogin]);

  useEffect(() => {
    if (!cpSuccessData) {
      return;
    }
    try {
      discover();
    } catch (error) {
      log.error('Error in discovery', error);
      dispatch(actions.discoverInstitutionLoginWithAccountsResponse(error, { scope }));
    }
  }, [discover, dispatch, cpSuccessData, scope]);

  const handleWidgetSuccess = (data) => {
    if (institutionLoginToUpdate) {
      // TODO: This handles credential update but wouldn't handle if new accounts showed up!
      dispatch(actions.accountDiscoveryWidgetUpdateSuccess({ institutionLoginToUpdate, accountDiscoveryData: null }, { scope }));
      return;
    }

    const existingLoginsAtInstitution =
      institutionLoginsById.reduce((list, login) => {
        if (login.institutionId === institution.id) {
          list.push(login);
        }
        return list;
      }, []);

    setCpSuccessData({ ...data, existingLoginsAtInstitution });
  };

  const handleAdd = () => {
    // TODO: This forces a call to discover again. However, we really don't need to do another discovery if we
    //       save the previous discovery response. Instead, should we just trigger a successful discovery?
    setUserSelectedInstitutionLogin(mkNewInstitutionLogin(institution, cpSuccessData, {}, true));
  };

  const handleRediscover = (institutionLogin) => {
    const { token } = cpSuccessData;
    setUserSelectedInstitutionLogin(institutionLogin.merge({
      cpSetupMode: 'DISCOVER_AND_REPLACE_ACCOUNTS',
      credentials: token ? List([mkCredential({ key: 'public-token', value: token })]) : null,
    }));
  };

  return (
    <>
      { page === 'WIDGET' && aggregator === 'FINICITY' && !cpSuccessData &&
        <FinicityConnect
          institution={institution}
          onClosed={onBack}
          onSuccess={handleWidgetSuccess}
          scope={scope}
          trackingProperties={trackingProperties}
        />}
      { page === 'WIDGET' && aggregator === 'PLAID' && !cpSuccessData &&
        <PlaidLink
          institution={institution}
          onClosed={onBack}
          onSuccess={handleWidgetSuccess}
          scope={scope}
          trackingProperties={trackingProperties}
          updateMode={updateMode}
        />}
      { page === 'NEW_OR_REDISCOVER_PICKER' &&
        <NewOrDiscoverForm
          existingLoginsAtInstitution={cpSuccessData.existingLoginsAtInstitution}
          fiBrandingIndent={fiBrandingIndent}
          onAdd={handleAdd}
          onBack={onBack}
          onRediscover={handleRediscover}
          ref={pageRef}
        />}
    </>
  );
});

export default WidgetAuthenticator;
