import { call, put, take, select } from 'redux-saga/effects';
import store from '@quicken-com/react.utils.redux-store';

import { getEnvironmentConfig, getLogger } from '@quicken-com/react.utils.core';

import AxiosFactory from 'utils/axiosFactory';

import { isRealTimeQuotesEnabled } from 'data/preferences/selectors';
import { getSecurityListForAccountIds } from 'data/investmentSecurityList/selectors';
import { getHoldingListForAccountIds } from 'data/investmentHoldingList/selectors';
import { transformRealTimeQuotes, transformResponseToIHolds } from './transformers';
import * as actions from './actions';

const log = getLogger('data/investmentHoldings/sagas');

const qcsAxios = AxiosFactory.get('qcs');

// ===============================================================================================
// SAGAS functions for investmentHoldings.  These are the functions responsible for communicating directly
// with external endpoints asynchronously.  Data is returned via ACTIONS, however, these can be
// split out (and should be) to be fully functional and JS shareable API callers via Axios and
// simply return an object in a provided callback, which can then choose to call the actions
//

// ====================================================
// GET IHolds
//
export function* getIHolds() {

  try {
    let requestUrl;
    let securities = [];
    try {
      requestUrl = `${getEnvironmentConfig().services_url}/investments/securities?limit=1000`;
      const response = yield call(qcsAxios.get, requestUrl);
      securities = response.data.resources;
    } catch (error) {
      log.error('Error retrieving Investment Securities', error);
    }

    const isV2QuotesEnabled = yield select(isRealTimeQuotesEnabled);
    if (isV2QuotesEnabled) {
      try {
        const symbols = securities?.map((security) => security.tickerSymbol)?.join(',');
        const quotesRequestUrl = new URL('/v2/investments/quotes', getEnvironmentConfig().services_url);
        quotesRequestUrl.search = new URLSearchParams({
          limit: 1000,
          realTime: true,
          includeFallback: false,
          symbols,
        });
        const response = yield call(qcsAxios.get, quotesRequestUrl);
        securities = transformRealTimeQuotes(securities, response.data.resources);
      } catch (error) {
        log.error('Error retrieving Investment Quotes', error);
      }
    }

    let lastSyncDate = null; // yield select(getLastSyncDate);  // Get all holdings

    const queryParams = { ...(lastSyncDate && { modifiedAfter: lastSyncDate }) };

    const queryStr = Object.keys(queryParams).map((key) => (
      `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
    )).join('&');

    requestUrl = `${getEnvironmentConfig().services_url}/investments/holdings`;
    requestUrl += queryStr ? `?${queryStr}` : '';

    while (requestUrl) {
      const response = yield call(qcsAxios.get, requestUrl);

      // If this is the first page of data, update lastSyncDate with the asOf
      // time from the server so we can send it back the next time we retrieve
      // we retrieve categories.
      //
      if (response.data.metaData.currentPage === 1) lastSyncDate = response.data.metaData.asOf;

      const nextPage = response.data.metaData.nextLink;
      requestUrl = nextPage ? `${getEnvironmentConfig().services_url}${nextPage}` : null;

      const iHolds = transformResponseToIHolds(response.data.resources, securities);

      // Notes:
      //   - We replace existing investment holdings with any read investment holdings.
      //   - We only send the lastSyncDate if there are no more pages. We do
      //     this in case of failure on a subsequent page. In that case, we
      //     would read the entire set of investment holdings again from the previous
      //     lastSyncDate the next time getIHolds was called.
      //
      const actionPayload = {
        iHolds,
        replace: true,
      };
      yield put(actions.getInvestmentHoldingsSuccess(actionPayload));
    }
  } catch (error) {
    log.error('Error retrieving Investment Holdings', error);
    yield put(actions.getInvestmentHoldingsFailure(error));
  }
}

export function* getIQuotes() {
  try {
    let securities = [];
    const isV2QuotesEnabled = yield select(isRealTimeQuotesEnabled);
    if (isV2QuotesEnabled) {
      try {
        securities = getSecurityListForAccountIds(store.getState(), { accountIds: null });
        const symbols = securities?.map((security) => security.tickerSymbol)?.join(',');
        const quotesRequestUrl = new URL('/v2/investments/quotes', getEnvironmentConfig().services_url);
        quotesRequestUrl.search = new URLSearchParams({
          limit: 1000,
          realTime: true,
          includeFallback: false,
          symbols,
        });
        const response = yield call(qcsAxios.get, quotesRequestUrl);
        securities = transformRealTimeQuotes(securities, response.data.resources);
      } catch (error) {
        log.error('Error retrieving Investment Quotes', error);
      }

      const holdingList = getHoldingListForAccountIds(store.getState(), { accountIds: null });
      const iHolds = transformResponseToIHolds(holdingList, securities);
      const actionPayload = {
        iHolds,
        replace: true,
      };
      yield put(actions.getInvestmentQuotesSuccess(actionPayload));
    }
  } catch (error) {
    log.error('Error retrieving Investment Quotes', error);
    yield put(actions.getInvestmentQuotesFailure(error));
  }
}

// ====================================================
// ACTION WATCHERS to trigger SAGAS calls

export function* getIHoldsActionWatcher() {
  // using a vanilla saga pattern so we only process a single get request 
  //   - takeEvery would process every request
  //   - takeLatest would cancel any current request and start request again
  while (true) {
    const action = yield take(actions.getInvestmentHoldings);
    yield call(getIHolds, action);
  }
}

export function* getIQuoteActionWatcher() {
  // using a vanilla saga pattern so we only process a single get request
  //   - takeEvery would process every request
  //   - takeLatest would cancel any current request and start request again
  while (true) {
    const action = yield take(actions.getInvestmentQuotes);
    yield call(getIQuotes, action);
  }
}





// ====================================================
// EXPORTS

export default [
  getIHoldsActionWatcher,
  getIQuoteActionWatcher,
];
