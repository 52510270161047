
// CORE
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';

// MUI
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';

// DATA
import { useDispatch, useSelector } from 'react-redux';
import { getBillerAccountForId } from 'data/billerAccounts/selectors';
import { getBillerLoginForId } from 'data/billerLogins/selectors';
import { getBiller } from 'data/billers/selectors';
import { getBiller as getBillerAction } from 'data/billers/actions';
import { isIncomeTxn } from 'data/transactions/selectors';
import { isAcme } from 'isAcme';


import ConnectedIcon from './ConnectedIcon';



const useStyles = makeStyles((theme) => ({
  avatar: ({ size, avatarColor, textColor }) => ({
    width: size,
    height: size,
    backgroundColor: avatarColor || theme.palette.greyScaleDeprecated[3],
    color: isAcme ? theme.palette.common.white : (textColor || theme.palette.greyScaleDeprecated[7]),
    borderRadius: size / 4,
    position: 'relative',
    overflow: 'visible',
    flexGrow: 0,

    fontSize: 24 + Math.round((size - 48) / (size > 48 ? 4 : 2.5)),
    fontWeight: (4 + Math.round(size / 20)) * 100,
  }),

  hide: ({ size }) => ({
    height: size * 0.8,
    backgroundColor: theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[7], 0),
    color: theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[7], 0),
  }),

  img: ({ size }) => ({
    width: size,
    height: (size * 64) / 102,
  }),
}));


// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
// ### - - -                  Main Component                     - - - ###
// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
function ConnectedAvatar(props) {

  const { model, size, txn, iconSize, color, className, styles, textColor } = props;

  const dispatch = useDispatch();
  const theme = useTheme();

  const presentBills = useSelector(featureFlagsSelectors.getFeatureFlags).get('billPresentment');
  const showIcon = presentBills && model?.billPresentmentAccountId;
  const connected = showIcon && model?.billPresentmentAccountId !== '0';
  const billerAccount = useSelector((state) => connected && getBillerAccountForId(state, model?.billPresentmentAccountId));
  const billerLogin = useSelector((state) => connected && getBillerLoginForId(state, billerAccount?.billerLoginID));
  const billerId = billerLogin?.providerBillerID;
  const biller = useSelector((state) => getBiller(state, billerId));
  const logo = Boolean(biller?.logo);

  const displayTxn = txn || model?.transaction || {};
  const isIncome = useSelector((state) => isIncomeTxn(state, displayTxn));

  let avatarColor = color || (isIncome ? theme.palette.number.positive : theme.palette.primary.main);
  if (model?.isCompleted) avatarColor = theme.palette.avatarGrey;


  useEffect(() => {
    if (billerId && !biller) {
      dispatch(getBillerAction(billerId));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles({ size, avatarColor, textColor });

  return (
    <Avatar className={classNames(classes.avatar, className, logo && classes.hide)} styles={styles}>
      {logo && <img alt={biller.name} src={biller.logo} className={classes.img} />}

      {!logo && (displayTxn.payee || '?').toUpperCase?.()[0]}

      {showIcon &&
        <ConnectedIcon model={model} size={iconSize} txn={txn} />}
    </Avatar>
  );
}

ConnectedAvatar.defaultProps = {
  size: 48,
  iconSize: 20,
};

ConnectedAvatar.propTypes = {
  model: PropTypes.object,
  txn: PropTypes.object,
  size: PropTypes.number,
  iconSize: PropTypes.number,
  color: PropTypes.string,
  textColor: PropTypes.string,
  className: PropTypes.string,
  styles: PropTypes.object,
};

export default ConnectedAvatar;
