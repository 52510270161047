/**
 *
 * QResourcePicker
 *
 */

// BASE

import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { List as ImmutableList, Set as ImmutableSet } from 'immutable';

import { categoriesSelectors } from '@quicken-com/react.flux.categories';
import { chartOfAccountsSelectors } from '@quicken-com/react.flux.chart-of-accounts';

// CUSTOM COMPONENTS
import ResourceRegister from 'components/ResourceRegister';
import QButton from 'components/QButton';
import QTextButton from 'components/QTextButton';

// MUI COMPONENTS
import Typography from '@mui/material/Typography';

import { getLogger } from '@quicken-com/react.utils.core';

// PATH RELATIVE IMPORTS
import { dataDictionary, resourceConfig, makeLocalCategoriesData, getChildIds } from './utils';

// STYLES HOOK
import { styles } from './styles';
const useStyles = makeStyles(styles);

const logger = getLogger('QResourcePicker.js');

/*
 * QResourcePicker Component ***********************************
 */

// PROP TYPES
const propTypes = {
  resourceType: PropTypes.string,
  onSelect: PropTypes.func,
  pageSize: PropTypes.number,
  selectedItems: PropTypes.object,
  noHeader: PropTypes.bool,
  noSearch: PropTypes.bool,
  autoFocus: PropTypes.bool,
  filterFn: PropTypes.func,
  placeholder: PropTypes.string,
  children: PropTypes.object,
  noBorder: PropTypes.bool,
  noGoals: PropTypes.bool,
};

const QResourcePicker = (props) => {

  // PROPS ============================================================================
  const { resourceType, onSelect, pageSize, selectedItems, noHeader, noSearch, autoFocus, filterFn, placeholder, noBorder, noGoals } = props;

  const resourceSelector = resourceConfig[resourceType]?.selector;

  // STATE
  const resourcesById = useSelector((state) => resourceSelector(state), shallowEqual);
  const chartOfAccounts = useSelector((state) => chartOfAccountsSelectors.getChartOfAccountsTree(state), shallowEqual);
  const categoriesById = useSelector(categoriesSelectors.getCategoriesById);

  const [data, setData] = useState(ImmutableList());
  const [searchFilter, setSearchFilter] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [showMore, setShowMore] = useState(true);
  const [searchChildren, setSearchChildren] = useState(false);

  const resourceTitle = resourceType.charAt(0).toUpperCase() + resourceType.slice(1);

  // EFFECTS ============================================================================

  /*
   * Make Data
   */
  useEffect(() => {

    logger.log('qresource **QResourcePicker new data', resourceType, resourcesById);

    let newList;
    if (resourceType === 'categories') {
      newList = makeLocalCategoriesData({
        categories: resourcesById,
        chartOfAccounts,
        filter: searchFilter || null,
        filterFn: filterFn || null,
      });
    } else {
      newList = ImmutableList(resourcesById.toList().toJS());
      if (searchFilter) {
        newList = newList.filter((item) =>
          item.name.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1);
      }
      newList = filterFn ? newList.filter(filterFn) : newList;
    }

    if (pageSize) newList = newList.slice(0, pageSize * pageNum);

    /*  Filter out GOALS tag types from Data! */
    if (resourceType === 'tags' && noGoals) {
      newList = newList.filter((item) => item?.type ? (item.type !== 'GOAL') : true);
    }

    setData(newList);
    setShowMore(newList.size >= (pageSize * pageNum));

  }, [resourceType, pageNum, resourcesById, chartOfAccounts, searchFilter, pageSize, filterFn, noGoals]);


  // STYLES ============================================================================
  const classes = useStyles(props);

  // INTERNAL FUNCTIONS ============================================================================

  /*
   * Handle Select
   */
  const handleSelect = useCallback((item) => {

    let newSelectedItems = selectedItems;
    const childIds = resourceType === 'categories' ?
      categoriesById.filter((cat) => cat.parentId === item.id).keySeq()
      :
      getChildIds(item);

    if (newSelectedItems.includes(item.id)) {
      newSelectedItems = newSelectedItems.delete(item.id);
      if (childIds) newSelectedItems = newSelectedItems.subtract(childIds);
    } else {
      newSelectedItems = newSelectedItems.add(item.id);
      if (resourceType === 'categories') {
        if (childIds) {
          newSelectedItems = newSelectedItems.union(childIds);
        }
      }
    }
    // setSelectedItems(newSelectedItems);
    if (onSelect) onSelect(newSelectedItems);
  }, [selectedItems, resourceType, onSelect, categoriesById]);

  /*
   * Handle Search Change
   */
  const handleSearchChange = useCallback((filter) => {
    setSearchFilter(filter);
    if (!searchChildren && filter) {
      setSearchChildren(true);
    } else if (filter === null && searchChildren) {
      setSearchChildren(false);
    }
  }, [searchChildren]);

  const clearSelections = useCallback(() => {
    onSelect(ImmutableSet());
  }, [onSelect]);
  return (
    <>
      <div
        className={classes.resourcesContainer}
        sharedcomponentid={'Q_RESOURCE_PICKER'}
      >
        {!noHeader &&
          <div className={classes.header}>
            <Typography className={classes.title} variant="subtitle1">
              {`Pick ${resourceTitle}`}
            </Typography>
            {(selectedItems.size > 0) &&
              <QTextButton
                title="Clear Selected"
                onClick={clearSelections}
                classes={{ root: classes.actionText }}
              />}
          </div>}
        <div className={classes.pickerContainer}>
          <ResourceRegister
            autoFocus={autoFocus}
            classes={{ rootContainer: classes.listRoot }}
            dataDictionary={dataDictionary.filterNot((x) => x.hide)}
            data={data}
            onSelect={handleSelect}
            selectedItems={selectedItems}
            noHeader
            onSearchChange={noSearch ? null : handleSearchChange}
            resourceType={resourceType}
            narrowMode
            placeholder={placeholder}
            noBorder={noBorder}
            searchChildren={searchChildren}
          >
            {props.children}
          </ResourceRegister>
          {showMore &&
            <QButton
              onClick={() => setPageNum(pageNum + 1)}
            >
              {`Show More ${resourceTitle}`}
            </QButton>}
        </div>
      </div>
    </>

  );
};

QResourcePicker.propTypes = propTypes;

export default QResourcePicker;
