import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'utils/compose';

import QTip from 'components/QuickenControls/QTip';
import IconButton from '@mui/material/IconButton';
import withStyles from '@mui/styles/withStyles';
import QCardIconOn from '@mui/icons-material/Chat';
import QCardIconOff from '@mui/icons-material/ChatBubbleOutline';

const styles = (_theme) => ({
  buttonRoot: {
    outline: 'none !important',
    fontSize: 'unset',
  },
});

const QCardToggleButton = (props) => {
  const { classes, size, qCardsOn } = props;

  const QCardIcon = qCardsOn ? QCardIconOn : QCardIconOff;

  return (
    <QTip
      placement="right"
      title={`QCards are currently ${qCardsOn ? 'on' : 'off'}`}
    >
      <IconButton
        id={qCardsOn ? 'qCardsTrue' : 'qCardsFalse'}
        aria-label="QCard Toggle"
        classes={{
          root: classes.buttonRoot,
        }}
        onClick={(e) => {
          props.closeHeader();
          props.onClick(!qCardsOn);
          e.stopPropagation();
        }}
        size="large"
      >
        <QCardIcon style={{ fontSize: size }} />
      </IconButton>
    </QTip>
  );
};

QCardToggleButton.propTypes = {
  classes: PropTypes.object,
  size: PropTypes.number,
  qCardsOn: PropTypes.bool,
  onClick: PropTypes.func,
  closeHeader: PropTypes.func,
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

export default compose(
  withStyles(styles, { name: 'QCardToggleButton' }),
  connect(mapStateToProps, mapDispatchToProps)
)(QCardToggleButton);
