import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DateTime, Info } from 'luxon';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { List } from 'immutable';
import { accountsSelectors } from '@quicken-com/react.flux.accounts';
import makeStyles from '@mui/styles/makeStyles';
import ButtonBase from '@mui/material/ButtonBase';
import PrevIcon from '@mui/icons-material/ChevronLeftRounded';
import NextIcon from '@mui/icons-material/ChevronRightRounded';
import TodayIcon from '@mui/icons-material/Stop'; // Do not use rounded version for this one
import SettingsIcon from '@mui/icons-material/SettingsRounded';
import Button from '@mui/material/Button';
import DownshiftField from 'components/QuickenControls/DownshiftField';
import QMenu from 'components/QMenu';
import useQPreferences from 'components/QPreferences/useQPreferences';
import { calTopHeaderStyles } from '../../styles';


const useStyles = makeStyles(calTopHeaderStyles);

const monthsData = Info.months().map((month, index) => ({
  key: month, label: month, index: index + 1,
}));

const prevYears = 10;
const nextYears = 5;
const prevYearsArray = [...Array(prevYears).keys()].map((i) => DateTime.local().year - (i + 1)).reverse();
const nextYearsArray = [...Array(nextYears).keys()].map((i) => DateTime.local().year + (i + 1));
const yearsData = [...prevYearsArray, DateTime.local().year, ...nextYearsArray]
  .map((year) => ({ key: `${year}`, label: `${year}`, value: year }));

const qMenuAnchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

const qMenuTransformOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const CalTopHeader = (props) => {
  const classes = useStyles();
  const { updateDateState, onPrefsOpen, onAccountsPrefsModalOpen, curMonth: { date = null } } = props;

  const location = useLocation();
  const showAllAccounts = location.search?.includes('displayNode=all');

  const { datasetPreferences, setDatasetPreference } = useQPreferences();

  const accountsByIdData = useSelector(accountsSelectors.getAccountsById);

  const originalAccountsToShow = useMemo(() => datasetPreferences.calendarTransaction.accountsToShow, [datasetPreferences]) || List();
  const accountsById = useMemo(() => accountsByIdData && accountsByIdData.filterNot((account) => (account.type === 'INVESTMENT')), [accountsByIdData]);
  const qMenuOptions = useMemo(() => [{ label: 'Preferences', value: 'preferences' }, ...showAllAccounts ? [{ label: 'Calendar Accounts', value: 'accounts' }] : []], [showAllAccounts]);

  const showAccountPrefReset = useMemo(() => {
    if (originalAccountsToShow.size !== accountsById.size) return true;
    if (originalAccountsToShow.size && originalAccountsToShow.every((acc) => !accountsById.has(acc))) return true;
    return false;
  }, [originalAccountsToShow, accountsById]);

  const onNextMonth = useCallback(() => {
    updateDateState(date.plus({ months: 1 }), 'next-month');
  }, [updateDateState, date]);

  const onPrevMonth = useCallback(() => {
    updateDateState(date.minus({ months: 1 }), 'previous-month');
  }, [updateDateState, date]);

  const onCurrentMonth = useCallback(() => {
    updateDateState(DateTime.local(), 'current-month');
  }, [updateDateState]);

  const onCustomMonth = useCallback((monthData) => {
    updateDateState(DateTime.local(date.year, monthData.index), 'month-picker');
  }, [updateDateState, date]);

  const onCustomYear = useCallback((yearData) => {
    updateDateState(DateTime.local(yearData.value, date.month), 'year-picker');
  }, [updateDateState, date]);


  const labelFromItem = useCallback((item) => item?.label, []);
  const keyFromItem = useCallback((item) => item?.key, []);
  const onQMenuChange = useCallback((value) => {
    if (value === 'preferences') {
      onPrefsOpen();
    } else if (value === 'accounts') {
      onAccountsPrefsModalOpen();
    }
  }, [onPrefsOpen, onAccountsPrefsModalOpen]);

  const monthSelectedIndex = date?.month - 1;
  const yearSelectedIndex = yearsData.findIndex((y) => y.value === date?.year);

  const textFieldProps = useMemo(() => ({
    InputProps: {
      disableUnderline: true,
      classes: {
        input: classes.inputRoot,
      },
    },
    fullWidth: true,
  }), [classes]);

  const disablePrevBtn = useMemo(() => {
    const firstYear = yearsData && DateTime.local(yearsData[0]?.value).minus({ months: 1 }).toISODate();
    return firstYear === date?.minus({ months: 1 }).toISODate();
  }, [date]);

  const disableNextBtn = useMemo(() => {
    const lastYear = yearsData && DateTime.local(yearsData[yearsData.length - 1]?.value, 12).plus({ months: 1 }).toISODate();
    return lastYear === date?.plus({ months: 1 }).toISODate();
  }, [date]);

  const onResetHandler = useCallback(() => {
    setDatasetPreference({ calendarTransaction: { accountsToShow: accountsById.map((acc) => acc.id).toSet() } });
  }, [accountsById, setDatasetPreference]);

  return (
    <header className={classes.monthHeader}>
      <div className={classes.controlGroup}>
        <div className={classes.controlRoot}>
          <ButtonBase
            id="cal-prev-month"
            className={classNames(classes.iconControl, { [classes.iconControlDisabled]: disablePrevBtn })}
            onClick={onPrevMonth}
            disabled={disablePrevBtn}
          >
            <PrevIcon />
          </ButtonBase>
        </div>
        <div className={classes.controlRoot}>
          <ButtonBase id="cal-current-month" className={classes.iconControl} onClick={onCurrentMonth}>
            <TodayIcon className={classes.iconToday} />
          </ButtonBase>
        </div>
        <div className={classes.controlRoot}>
          <ButtonBase
            id="cal-next-month"
            className={classNames(classes.iconControl, { [classes.iconControlDisabled]: disableNextBtn })}
            onClick={onNextMonth}
            disabled={disableNextBtn}
          >
            <NextIcon />
          </ButtonBase>
        </div>

        {date && (
          <>
            <div className={classNames(classes.controlRoot, classes.monthPickerRoot)}>
              <DownshiftField
                key={`month-${date.month}`}
                items={monthsData}
                initialItemSelected={monthsData[monthSelectedIndex]}
                initialInputValue={monthsData[monthSelectedIndex].label}
                itemToString={labelFromItem}
                itemKey={keyFromItem}
                autoFocus={false}
                onSelected={onCustomMonth}
                textFieldProps={textFieldProps}
              />
            </div>

            <div className={classNames(classes.controlRoot, classes.yearPickerRoot)}>
              <DownshiftField
                key={`year-${date.year}`}
                items={yearsData}
                initialItemSelected={yearsData[yearSelectedIndex]}
                initialInputValue={yearsData[yearSelectedIndex]?.label}
                itemToString={labelFromItem}
                itemKey={keyFromItem}
                autoFocus={false}
                onSelected={onCustomYear}
                textFieldProps={textFieldProps}
              />
            </div>
          </>
        )}
      </div>

      <div className={classes.settingsRoot}>
        {showAllAccounts && showAccountPrefReset &&
          <Button
            id="reset-button"
            variant="contained"
            onClick={onResetHandler}
            classes={{ root: classes.button }}
          >
            Reset To Default
          </Button>}
        <QMenu
          customTrigger={<SettingsIcon className={classes.settingsIcon} />}
          name={'qmenu-calendar-settings'}
          menuIconButtonSize="medium"
          customTriggerClass={classes.settingsIconRoot}
          options={qMenuOptions}
          anchorOrigin={qMenuAnchorOrigin}
          transformOrigin={qMenuTransformOrigin}
          onChange={onQMenuChange}
        />
      </div>
    </header>
  );
};

CalTopHeader.propTypes = {
  curMonth: PropTypes.object,
  updateDateState: PropTypes.func,
  onPrefsOpen: PropTypes.func,
  onAccountsPrefsModalOpen: PropTypes.func,
};

export default React.memo(CalTopHeader);
