// BASE
import React, { useState, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Set } from 'immutable';
import PropTypes from 'prop-types';

// CUSTOM COMPONENTS
import QCloseBox from 'components/QCloseBox';
import CategoryReviewCard from 'components/CategoryReviewCard';
import { chartOfAccountsTypes, chartOfAccountsUtils } from '@quicken-com/react.flux.chart-of-accounts';

// SELECTORS, ACTIONS, UTILS
import { formatNumber } from 'components/QuickenControls/AmountField';
import { setAmountSign } from 'components/Budgets/EditBudgetsDialog/editBudgetHelpers';

// MUI COMPONENTS
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';

// PATH RELATIVE IMPORTS
import { TitleHeader, CategoryWrappedField, AmountWrappedField, ButtonArea } from './subComponents';

// STYLES HOOK
import { styles } from './styles';
const useStyles = makeStyles(styles);

/*
 * BudgetAddCategory Component ***********************************
 */

// PROP TYPES
const propTypes = {
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  accountIds: PropTypes.object,
  // exclusionSet: PropTypes.object,
  currency: PropTypes.string,
  categoryIds: PropTypes.array,
};

const BudgetAddCategory = (props) => {

  // PROPS ============================================================================
  const { onAdd, onClose, accountIds, currency = new Set(), categoryIds } = props;
  // STATE
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentAmount, setCurrentAmount] = useState(0);
  const [monthlyAverage, setMonthlyAverage] = useState(0);

  // EFFECTS ============================================================================

  // STYLES ============================================================================
  const classes = useStyles(props);

  // INTERNAL FUNCTIONS ============================================================================
  const internalOnChange = (e) => {
    if (Number(e.target.value) !== Number(currentAmount)) setCurrentAmount(Number(e.target.value));
  };
  const internalOnAdd = () => {
    onAdd({ coa: selectedCategory, amount: currentAmount });
  };
  const onCatChange = (coa) => {
    if (!chartOfAccountsUtils.coasAreEqual(coa, selectedCategory)) setSelectedCategory(chartOfAccountsTypes.mkChartOfAccount(coa));
  };
  const onSubmit = (e) => {
    if (selectedCategory) {
      onAdd({ coa: selectedCategory, amount: Number(e.target.value) });
      onClose();
    }
  };
  const duplicateCategory = useMemo(() => categoryIds.includes(selectedCategory?.id), [selectedCategory, categoryIds]);

  // const catFilterFn = (coa) =>
  //   !exclusionSet.find((xcoa) => coasAreEqual(xcoa, coa)) && (coa.type === 'CATEGORY') ;

  // RENDER ============================================================================
  return (
    <Dialog
      maxWidth="md"
      open
      onClose={onClose}
      classes={{ paperWidthMd: classes.dialogRoot }}
    >
      <QCloseBox onClose={onClose} id="add-to-budget-close-button" />
      <TitleHeader
        classes={classes}
      />
      <Divider classes={{ root: classes.dividerRoot }} />

      <div className={classes.fieldsContainer}>
        <CategoryWrappedField
          catFieldProps={{
            value: selectedCategory,
            onChange: onCatChange,
            // filterFn: catFilterFn,
            numPopularCats: 10,
            InputProps: {
              placeholder: 'Select a Category',
            },
          }}
        />
        <AmountWrappedField
          currentAmount={currentAmount}
          classes={{ inputField: classes.amountInputStyle }}
          onBlur={(e) => internalOnChange(e)}
          onSubmit={onSubmit}
          currency={currency}
        />
      </div>

      <Divider classes={{ root: classes.dividerRoot }} />

      {selectedCategory &&
        <>
          <CategoryReviewCard
            coa={selectedCategory}
            accountIds={accountIds}
            numMonths="3_MONTHS_PRIOR"
            classes={{ transactionList: classes.categoryReviewTxList, root: classes.categoryReviewCardRoot }}
            onAverageCalc={setMonthlyAverage}
            currency={currency}
            onSetValueFromGraph={(amt) => setCurrentAmount(setAmountSign(Number(Number(amt).toFixed(0)), selectedCategory))}
          />
          <div
            className={classes.monthlyAverage}
          >
            <ButtonBase
              focusRipple
              onClick={() => setCurrentAmount(setAmountSign(Number(Number(monthlyAverage).toFixed(0)), selectedCategory))}
            >
              <Typography
                variant="h6"
              >
                {`Monthly Average: ${formatNumber(setAmountSign(monthlyAverage, selectedCategory), currency)}`}
              </Typography>
            </ButtonBase>
          </div>

        </>}

      <ButtonArea
        onAdd={internalOnAdd}
        onClose={onClose}
        disableSubmit={selectedCategory === null || duplicateCategory}
      />
    </Dialog>
  );
};
BudgetAddCategory.propTypes = propTypes;
export default BudgetAddCategory;

