// @flow
import { Record, RecordFactory } from 'immutable';
import type { CoaType } from '@quicken-com/react.flux.chart-of-accounts';

export type BudgetItemType = {
  id: ?string,
  clientId: ?string,
  createdAt: ?string,
  modifiedAt: ?string,
  budgetId: ?string,
  startDate: ?string,
  amount: ?number,
  calculatedActualsAmount: ?number,
  calculatedRolloverAmount: ?number,
  rolloverOverrideAmount: ?number,
  groupId: ?string,
  type: ?string,
  coa: CoaType,
  rolloverType: ?string,
  isContinued: ?boolean,
  isDeleted: ?boolean,

  name: ?string,  // internal use
  isIncome: ?boolean, // internal use
  budgetedWithChild: ?boolean,  // internal use
  // eslint-disable-next-line no-undef
  txnIds: ?array,
}
export type BudgetItem = Record<BudgetItemType>;

export const mkBudgetItem: RecordFactory<BudgetItemType> =
  Record({
    id: null,
    clientId: null,
    createdAt: null,
    modifiedAt: null,
    budgetId: null,
    startDate: null,
    amount: 0,
    calculatedActualsAmount: null,
    calculatedRolloverAmount: null,
    rolloverOverrideAmount: undefined,
    groupId: null,
    type: 'COA',
    coa: null,
    rolloverType: 'NO_ROLLOVER',
    isContinued: false,
    isDeleted: false,

    // internal use only
    name: '',
    isIncome: false,
    budgetedWithChild: false,
    txnIds: [],
  });
