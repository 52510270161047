/*
 * RegPayeeField
 */

// BASE
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

import { transactionsUtils } from '@quicken-com/react.flux.transactions';

// CUSTOM COMPONENTS
import PayeeField from 'components/QuickenControls/PayeeField';
import QTypography from 'components/MUIWrappers/QTypography';
import QTip from 'components/QuickenControls/QTip';
import SPayeeField from 'components/SPayeeField';

// SELECTORS, ACTIONS, UTILS
import { txnHasTags } from 'data/transactions/utils';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';
import { isOldEdge } from 'utils/utils';
import isAcme from 'isAcme';
//  import { getSharedPreferencesByPath } from 'data/preferences/selectors';

// PATH RELATIVE IMPORTS
import { styles } from './styles';

const isIe = require('is-iexplorer') || isOldEdge();  // eslint-disable-line

const propTypes = {
  txn: PropTypes.object,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool,
  editable: PropTypes.bool,
  forceStatic: PropTypes.bool,
  inputRef: PropTypes.func,
  accountIds: PropTypes.object,
  regFieldKey: PropTypes.func,
};

// STYLES HOOK CREATION
const useStyles = makeStyles(styles);

const RegPayeeField = (props) => {

  // PROPS ============================================================================
  const { txn, onChange, onFocus, onBlur, editable, forceStatic, autoFocus, inputRef, accountIds, regFieldKey } = props;
  const theme = useTheme();

  // SELECTORS ==========================================================================
  const payeeShowFillInfoInMenu = useSelector((state) => featureFlagsSelectors.getFeatureFlags(state).get('payeeShowFillInfoInMenu'), shallowEqual);
  const payeeAutoFillCat = useSelector((state) => featureFlagsSelectors.getFeatureFlags(state).get('payeeAutoFillCat'), shallowEqual);
  const payeeAutoFillAmount = useSelector((state) => featureFlagsSelectors.getFeatureFlags(state).get('payeeAutoFillAmount'), shallowEqual);
  const payeeAutoFillMemo = useSelector((state) => featureFlagsSelectors.getFeatureFlags(state).get('payeeAutoFillMemo'), shallowEqual);
  const payeeAutoFillTags = useSelector((state) => featureFlagsSelectors.getFeatureFlags(state).get('payeeAutoFillTags'), shallowEqual);
  /*  const wrapText = useSelector((state) =>
    getSharedPreferencesByPath(state, { group: 'dataset', path: ['webApp', 'transactionRegister', 'wrapText'] }), shallowEqual);  */

  // https://github.com/facebook/react/issues/14904
  // have to keep local payee state to fix REACT cursor bug
  const [payee, setPayee] = useState(txn.payee);
  useEffect(() => { setPayee(txn.payee); }, [txn.payee]);

  const payeeObj = useMemo(() => ({ payee: txn.payee || '' }), [txn.payee]);

  // STYLES ============================================================================
  const classes = useStyles(props);

  const handleSChange = (event, value) => {  // handling changes for SPayeeField
    onChange?.(event, 'payee', value);
    setTimeout(() => regFieldKey({ key: 'Enter', stopPropagation: () => true, preventDefault: () => true }), 0);
  };


  // RENDER ============================================================================

  const staticValue = (<span>{'\u00A0'}</span>);

  if (isAcme) {
    return editable ? (
      <SPayeeField
        defaultValue={payee}
        onChange={handleSChange}
        allowCreateRule={Boolean(txn.cpData)}
        popupIcon={null}
        // openOnFocus={false}
        inRegister
        textFieldProps={{
          variant: 'standard',
          label: null,
          placeholder: null,
          margin: 'dense',
          InputProps: {
            disableUnderline: true,
            classes: { root: classes.inputRoot },
          },
          classes: { root: classes.payeeRoot },
        }}
        classes={{ root: classes.autoCompleteRoot }}
      />
    )
      :
      (
        <QTip
          wrapOnly
          title={txn.payee}
          wrapId={`${txn.id}-payee`}
        >
          <QTypography
            clickable={!forceStatic ? 'true' : null}
            thinFont
            className={classes.regItemCore}
          >
            {txn.payee || staticValue}
          </QTypography>
        </QTip>
      );
  }
  let menuFields = 'payee';
  if (payeeShowFillInfoInMenu) {
    if (payeeAutoFillCat && !transactionsUtils.isSplitTxn(txn) && (!txn.coa || txn.coa.type === 'UNCATEGORIZED')) {
      menuFields += 'category';
    }
    if (Number(txn.amount) === 0 && payeeAutoFillAmount) {
      menuFields += 'amount';
    }
    if (!txn.memo && payeeAutoFillMemo) {
      menuFields += 'memo';
    }
    if (!txnHasTags(txn) && payeeAutoFillTags) {
      menuFields += 'tags';
    }
  }

  // Companion render
  return (
    <>
      {editable ?
        <PayeeField
          disableUnderline={theme.components.register.noUnderline}
          accountIds={accountIds}
          menuShowFields={menuFields}
          editable
          fontSize={theme.components.register.fontSize.default}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          autoFocus={autoFocus}
          value={payeeObj}
          inputRef={inputRef}
          textFieldVariant="standard"
        >
        </PayeeField> :
        <QTip
          wrapOnly
          title={txn.payee}
          wrapId={`${txn.id}-payee`}
        >
          <QTypography
            clickable={!forceStatic ? 'true' : null}
            thinFont
            className={classes.regItemCore}
          >
            {txn.payee || staticValue}
          </QTypography>
        </QTip>}
    </>
  );
};

RegPayeeField.propTypes = propTypes;

// RegPayeeField.whyDidYouRender = {
//   logOnDifferentValues: true,
//   customName: 'RegPayeeField',
// }

export default (RegPayeeField);


