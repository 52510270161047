export const styles = {
  categoryName: {
    fontWeight: 500,
  },
  rootNode: {
    textTransform: 'uppercase',
    '&.rollup': {
      background: '#fff0f3',
    },
  },
};
