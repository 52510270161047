// @flow
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';

import { accountsSelectors } from '@quicken-com/react.flux.accounts';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/DoneRounded';
import { useTheme } from '@mui/material/styles';

import type { Institution } from 'data/institutions/types';
import {
  getInstitutionLogins as loadInstitutionLogins,
  refreshAccountsCredentialsProvided,
  refreshAccountsCredentialsNotProvided,
  refreshAccountsMfaFormSubmit,
  refreshAccountsMfaNotProvided,
} from 'data/institutionLogins/actions';
import { isInstitutionLoginAmbiguous } from 'data/institutionLogins/selectors';

import { bindPromiseAction } from 'utils/actionHelpers';

import * as actions from '../actions';
import InstitutionLoginCredentialsForm from '../InstitutionLoginCredentialsForm';
import MfaChallengeForm from '../MfaChallengeForm';
import * as selectors from '../selectors';
import StdDialogProgress from '../../StdDialogProgress';

type Props = {
  aggregator: Object,
  cpSetupMode: string,
  fullAddAccountsDialog: boolean,
  hideInitialPartnerAuthIntro: boolean,
  institution: Institution,
  provideForRefreshAccounts: boolean,
  scope: string,
  trackingProperties: Object,
  updateMode: boolean,
  onCancel: () => void,
}

const Authenticator = forwardRef((props: Props, ref) => {
  const {
    aggregator,
    cpSetupMode,
    fullAddAccountsDialog,
    hideInitialPartnerAuthIntro,
    institution,
    onCancel,
    provideForRefreshAccounts,
    scope,
    trackingProperties,
    updateMode,
  } = props;
  const dispatch = useDispatch();

  const [page, setPage] = useState(cpSetupMode !== 'PROVIDE-MFA' ? 'CREDENTIALS' : 'MFA');

  const error = useSelector((state) => selectors.getError(state, { scope }));
  const institutionLogin = useSelector((state) => selectors.getInstitutionLogin(state, { scope }));
  const isPolling = useSelector((state) => selectors.getIsPolling(state, { scope }));
  const isSubmitting = useSelector((state) => selectors.getIsSubmitting(state, { scope }));
  const mfaChallenge = useSelector((state) => selectors.getMfaChallenge(state, { scope }));

  const theme = useTheme();

  // these will only exist when we are updating an existing institution-login
  //
  const institutionLoginId = institutionLogin ? institutionLogin.id : null;
  const institutionLoginIsAmbigous = useSelector((state) => isInstitutionLoginAmbiguous(state, { institutionLoginId }));
  const accounts = useSelector((state) => accountsSelectors.getAccountsForInstitutionLoginId(state, { institutionLoginId }));

  useMount(() => {
    // lets make sure we have the latest institution-logins (useful for ambiguous message)
    dispatch(loadInstitutionLogins());
  });

  useImperativeHandle(ref, () => ({
    handleClose: (dEvent) => {
      if (provideForRefreshAccounts && dEvent !== 'SUBMIT_SUCCESS') {
        if (page === 'CREDENTIALS') {
          dispatch(refreshAccountsCredentialsNotProvided(institutionLogin ? institutionLogin.id : '0'));
        } else if (page === 'MFA') {
          dispatch(refreshAccountsMfaNotProvided(mfaChallenge ? mfaChallenge.institutionLoginId : '0'));
        }
      }
    },
  }));

  if (mfaChallenge && page !== 'MFA') {
    setPage('MFA');
  }

  const handleCredentialsSubmit = (institutionLoginWithCredentials, resolve, reject) => {
    if (provideForRefreshAccounts) {
      dispatch(refreshAccountsCredentialsProvided({ institutionLogin: institutionLoginWithCredentials }, { scope }));
      resolve();
    } else {
      const accountDiscoveryCredentialsFormSubmitPromise = bindPromiseAction(dispatch, actions.accountDiscoveryCredentialsFormSubmit);
      accountDiscoveryCredentialsFormSubmitPromise(
        { institutionLogin: institutionLoginWithCredentials.set('cpSetupMode', cpSetupMode) },
        { scope }
      ).then(
        resolve,
        reject,
      );
    }
  };

  const handleMFASubmit = (mfaResponse, resolve, reject) => {
    if (provideForRefreshAccounts) {
      const refreshAccountsMfaFormSubmitPromise = bindPromiseAction(dispatch, refreshAccountsMfaFormSubmit);
      refreshAccountsMfaFormSubmitPromise({ mfaResponse }, { scope }).then(
        () => {
          resolve();
        },
        reject,
      );
    } else {
      const accountDiscoveryMfaFormSubmitPromise = bindPromiseAction(dispatch, actions.accountDiscoveryMfaFormSubmit);
      accountDiscoveryMfaFormSubmitPromise({ mfaResponse }, { scope })
        .then(() => {
          setPage('CREDENTIALS');
          resolve();
        })
        .catch(() => {
          setPage('CREDENTIALS');
          reject(Error('accountDiscoveryMfaFormSubmitPromise'));  // TODO:Should we handle error on Mfa Form
        });
    }
  };

  const isBusy = isSubmitting || isPolling;
  return (
    <>
      {!fullAddAccountsDialog && institutionLoginIsAmbigous &&
        <Box bgcolor="grey.level2" display="flex" mt={1} mx={3} px={1} py={0.5}>
          <Typography variant="caption" style={{ color: theme.palette.grey.level7 }}>
            {`Associated accounts :${accounts && accounts.map((account) => ` ${account && account.name}`).toArray()}`}
          </Typography>
        </Box>}

      <Box display="flex" flex="1" flexDirection="column">
        {isBusy &&
          <StdDialogProgress icon={CheckIcon} label="Securing connection..." />}
        <Box display="flex" flex="1" flexDirection="column" style={{ visibility: isBusy ? 'hidden' : null }}>
          { page === 'CREDENTIALS' &&
            <InstitutionLoginCredentialsForm
              aggregator={aggregator}
              error={error}
              fullAddAccountsDialog={fullAddAccountsDialog}
              hideInitialPartnerAuthIntro={hideInitialPartnerAuthIntro}
              institutionLogin={institutionLogin}
              onCancel={onCancel}
              onSubmit={handleCredentialsSubmit}
              scope={scope}
              institution={institution}
              trackingProperties={trackingProperties}
              updateMode={updateMode}
            />}
          {page === 'MFA' &&
            <MfaChallengeForm
              institutionLogin={institutionLogin}
              isSubmitting={isSubmitting}
              mfaChallenge={mfaChallenge}
              onCancel={onCancel}
              onSubmit={handleMFASubmit}
              scope={scope}
              trackingProperties={trackingProperties}
              fullAddAccountsDialog={fullAddAccountsDialog}
              updateMode={updateMode}
            />}
        </Box>
      </Box>
    </>
  );
});

export default Authenticator;
