import React from 'react';
import PropTypes from 'prop-types';

import { localPreferences } from '@quicken-com/react.utils.core';

import { BugsnagErrorBoundary } from 'utils/core/bugsnag';

import LocalErrorBoundary from './LocalErrorBoundary';
import FallbackComponent from './FallbackComponent';

const ErrorBoundary = (props) => {
  const { children, ...otherProps } = props;
  const disableBugsnag = localPreferences.getDisableBugsnag();
  const ErrorBoundarySelected = disableBugsnag ? LocalErrorBoundary : BugsnagErrorBoundary;
  return (
    <ErrorBoundarySelected FallbackComponent={FallbackComponent} {...otherProps}>
      {children || null}
    </ErrorBoundarySelected>
  );
};

ErrorBoundary.propTypes = {
  children: PropTypes.any,
};

ErrorBoundary.whyDidYouRender = false;

export default ErrorBoundary;
