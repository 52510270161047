//
// SELECTORS, these are functions designed to work directly on and with the
// redux data store
//

import createCachedSelector, { LruCacheObject } from 're-reselect';
import { List as ImmutableList } from 'immutable';

import { getLogger } from '@quicken-com/react.utils.core';
import { accountsSelectors } from '@quicken-com/react.flux.accounts';

import { getIHoldsByAccountId, getIHoldsByAccountIdsSelectors, getIsLoading as getHoldingsLoadPending } from 'data/investmentHoldings/selectors';
import { mkInvestmentHolding } from 'data/investmentHoldings/types';

const log = getLogger('data/investmentHoldingList/selectors.js');

log.log('loaded');

// getHoldingListForAccountIds
//
// This selector expects the following properties:
//   - an array of accountIds
//   - sortBy property
//   - sortOrder property
//
// Returns an immutable list of holdings from the accounts specified by the accountIds array.

export const getHoldingListForAccountIds = createCachedSelector(
  (state, props) => props.accountIds,
  (state, props) => props.sortBy || 'change',
  (state, props) => props.sortOrder || 'descending',
  accountsSelectors.getAccountsById,
  getIHoldsByAccountId,
  getIHoldsByAccountIdsSelectors,
  (
    accountIds,
    sortBy,
    sortOrder,
    accountsById,
    holdingsByAccountIds,
    holdingsByAccountIdsSelectors,
  ) => {

    let accountIdsList = accountIds;
    if (!accountIds || accountIds.length === 0) {
      accountIdsList = accountsById.map((x) => x.id).toList();
    }

    // log.debug('getHoldingListForAccountIds accountsById:', accountsById);

    let holdingsForAccountIds = ImmutableList();
    accountIdsList.forEach((accountId) => {
      const holdingsForAccountIdSelector =
        holdingsByAccountIdsSelectors.get(accountId);
      if (holdingsForAccountIdSelector) {
        const holdingsForAccountId =
          holdingsForAccountIdSelector(holdingsByAccountIds, { sortBy, sortOrder });
        holdingsForAccountIds = holdingsForAccountIds.concat(holdingsForAccountId);
      }
    });

    const sortedHoldings = sortHoldings(holdingsForAccountIds, sortBy, sortOrder);
    return mergeHoldings(sortedHoldings);
  }
)(
  (state, props) => props.accountIds ? props.accountIds.toString() :
    'all', { cacheObject: new LruCacheObject({ cacheSize: 10 }) }
);

export const getTopHoldings = createCachedSelector(
  getHoldingListForAccountIds,
  (acctHoldings) => {
    let holdings = ImmutableList([]);
    let holdingsCount = 0;
    // log.debug('getTopHoldings acctHoldings:', acctHoldings.toJS());
    acctHoldings.forEach((holding) => {
      // if (!holding.showInInvestmentPanel) log.debug('HOLDING EXCLUDED FROM INVESTMENT PANEL:', holding.toJS());
      if (holding.showInInvestmentPanel && holdingsCount < 4) {
        holdings = holdings.push(holding);
        holdingsCount += 1;
      }
    });
    return holdings;
  }
)(
  (state, props) => props.accountIds ? props.accountIds.toString() : 'all',
  {
    cacheObject: new LruCacheObject({ cacheSize: 10 }),
  }
);

export const getSummarizedInvestmentData = createCachedSelector(
  (state) => getHoldingsLoadPending(state) || accountsSelectors.getLoadPending(state),
  accountsSelectors.getInvestmentAccounts,
  getHoldingListForAccountIds,
  (isLoadPending, accounts, acctHoldings) => {

    if (isLoadPending) return null;

    let todaysChange = 0;
    let totalValue = 0;
    let cashBalance = 0;

    // rollup cash balance
    if (accounts && accounts.size > 0) {
      accounts.map((account) => { cashBalance += account.cashBalance; return null; });
    }

    acctHoldings.forEach((holding) => {
      if (holding.amount && holding.amount !== 'N/A') {
        totalValue += (Number(holding.amount));
      }
      if (holding.quoteChange && !Number.isNaN(holding.quoteChange) && holding.numberOfShares) {
        if (todaysChange !== 'N/A') todaysChange += (holding.quoteChange * holding.numberOfShares);
      }
    });
    let todaysChangePercent = 0;
    const currentTotal = totalValue + cashBalance;
    const yesterdayTotal = currentTotal - todaysChange;
    if (Math.abs(yesterdayTotal) > 0.01) {
      if (yesterdayTotal > 0) {
        todaysChangePercent = (((currentTotal / yesterdayTotal) - 1) * 100);
      } else {
        todaysChangePercent = ((1 - (currentTotal / yesterdayTotal)) * 100);
      }
    }
    return {
      todaysChange,
      todaysChangePercent,
      totalValue,
      cashBalance,
    };
  }
)(
  (state, props) => props.accountIds ? props.accountIds.toString() : 'all',
  {
    cacheObject: new LruCacheObject({ cacheSize: 10 }),
  }
);

function sortHoldings(holdings, optSortBy, optSortOrder) {
  const sortBy = optSortBy !== undefined ? optSortBy : 'change';
  const sortOrder = optSortOrder !== undefined ? optSortOrder : 'descending';

  // log.debug('In selector performing SORT', holdings, sortBy, sortOrder);
  const sortFactor = sortOrder === 'ascending' ? -1 : 1;

  return holdings.toList().sort((a, b) => {
    let cmpValue = 0;
    switch (sortBy) {
      case 'change': {
        const valueA = Math.abs(a.valueChange);
        const valueB = Math.abs(b.valueChange);
        if (valueA > valueB) {
          cmpValue = -1;
        } else if (valueA < valueB) {
          cmpValue = 1;
        }
        break;
      }
      case 'security-change': {
        const valueA = Math.abs(a.quoteChangePercentage);
        const valueB = Math.abs(b.quoteChangePercentage);
        if (valueA > valueB) {
          cmpValue = -1;
        } else if (valueA < valueB) {
          cmpValue = 1;
        }
        break;
      }
      case 'ticker': {
        if (a.tickerSymbol > b.tickerSymbol) {
          cmpValue = -1;
        } else if (a.tickerSymbol < b.tickerSymbol) {
          cmpValue = 1;
        }
        break;
      }
      default:
        break;
    }

    return cmpValue * sortFactor;
  });
}

function mergeHoldings(holdings) {
  const holdingMap = {};
  const holdingList = [];

  holdings.valueSeq().forEach((holding) => {
    const key = holding.securityID;
    if (holdingMap[key]) {
      const existingHolding = holdingMap[key];
      const consolidatedHolding = existingHolding.toJS();
      consolidatedHolding.amount = existingHolding.amount + holding.amount;
      consolidatedHolding.amountDelta = existingHolding.amountDelta + holding.amountDelta;
      consolidatedHolding.balance = existingHolding.balance + holding.balance;
      consolidatedHolding.numberOfShares = existingHolding.numberOfShares + holding.numberOfShares;
      consolidatedHolding.valueChange = existingHolding.valueChange + holding.valueChange;
      holdingMap[key] = mkInvestmentHolding(consolidatedHolding);
    } else {
      holdingMap[key] = holding;
    }
  });

  Object.keys(holdingMap).forEach((key) => { holdingList.push(holdingMap[key]); });

  return ImmutableList(holdingList);
}
