// @flow
import { handleActions } from 'redux-actions';

import { getLogger } from '@quicken-com/react.utils.core';

import { createDialog, removeDialog, updateDialogProps } from './actions';
import { mkRootUiStore } from './types';
import type { RootUiStore, RootUiData } from './types';

const log = getLogger('data/rootUi/actions');

let nextId = 1;

/* eslint-disable no-param-reassign */
export const reducer = handleActions({

  [createDialog]: (state: RootUiStore, { payload: uiData }: {payload: RootUiData}) => {
    log.info('creating dialog', uiData, uiData.type, uiData.id, uiData.props);
    let uiDataWithId = uiData;
    if (!uiData.id || uiData.id === '0') {
      uiDataWithId = uiData.merge({ id: `ROOT_DIALOG_${nextId}` });
      nextId += 1;
    } else {
      // do not process if a dialog with the same ID is already on the active or queued stacks
      if ( // eslint-disable-line no-lonely-if
        state.activeModals.findIndex((x) => x.id === uiDataWithId.id) !== -1 ||
        state.queuedModals.findIndex((x) => x.id === uiDataWithId.id) !== -1
      ) {
        // log.debug('Dialog is already displayed or queued', uiDataWithId);
        return state;
      }
    }
    // log.debug('Done with check...');

    if (state.activeModals.size === 0 || uiData.allowNesting) {
      return state.update('activeModals', (modals) => modals.push(uiDataWithId));
    }
    return state.update('queuedModals', (modals) => modals.push(uiDataWithId));
  },

  [removeDialog]: (state: RootUiStore, { payload: dialogId = 0 }: {payload: number}) => {
    log.info(`remove dialog id=${dialogId}`);

    // when removing, optimize primary use case of popping last dialog pushed
    const activeModal = state.activeModals.last();
    if (activeModal != null && (dialogId === 0 || activeModal.id === dialogId)) {
      state = state.update('activeModals', (modals) => modals.pop());
    } else {
      // not really sure if this is a valid use-case or not...

      let index = state.activeModals.findIndex((x) => x.id === dialogId);
      if (index >= 0) {
        state = state.update('activeModals', (modals) => modals.splice(index, 1));
      } else {
        index = state.queuedModals.findIndex((x) => x.id === dialogId);
        if (index >= 0) {
          state = state.update('queuedModals', (modals) => modals.splice(index, 1));
        } else {
          log.warn('Tried to remove non-existing dialog', dialogId);
          return state;
        }
      }
    }

    // if we have no more active-modals, and we have a queued modal, then move first queued modal to active modals list
    if (state.activeModals.size === 0) {
      const queuedModal = state.queuedModals.get(0);
      if (queuedModal != null) {
        state = state.merge({
          activeModals: state.activeModals.push(queuedModal),
          queuedModals: state.queuedModals.delete(0),
        });
      }
    }

    return state;
  },
  [updateDialogProps]: (state: RootUiStore, { payload }) => {
    const { id, props } = payload;

    // check active list first
    const indexInActiveModals = state.activeModals.findIndex((x) => x.id === id);
    if (indexInActiveModals !== -1) {
      return state.set(
        'activeModals',
        state.activeModals.update(
          indexInActiveModals,
          (modal) => modal.mergeIn(['props'], props)
        )
      );
    }

    // check queued list if we didn't find one in active list
    const indexInQueuedModals = state.queuedModals.findIndex((x) => x.id === id);
    if (indexInQueuedModals !== -1) {
      return state.set(
        'queuedModals',
        state.queuedModals.update(
          indexInQueuedModals,
          (modal) => modal.mergeIn(['props'], props)
        )
      );
    }

    // do nothing if we couldn't find dialog
    return state;
  },

}, mkRootUiStore());

export const REDUCER_KEY = 'rootUiStore';
export default reducer;
