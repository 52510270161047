import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'utils/compose';
import makeStyles from '@mui/styles/makeStyles';
import DialogHoc from 'containers/Settings/ChildPages/Accounts/DialogHoc';
import DownloadButton from '../DownloadButton';

const useStyles = makeStyles(() => ({
  downloadLink: {
    display: 'flex',
    flex: 1,
  },
}));


const SingleFeatureModal = (props) => {
  const { feature, setDialog, onClose, showDownloadButton } = props;
  const classes = useStyles(props);

  const onCloseHandler = () => onClose && onClose();
  const DownloadBtn = () => <div className={classes.downloadLink}><DownloadButton /></div>;
  const btsArray = [...(showDownloadButton ? [DownloadBtn] : []), 'OK'];

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (feature) {
      setDialog(feature.label, feature.description, onCloseHandler, btsArray);
    }
  }, [feature]);

  return null;
};

SingleFeatureModal.propTypes = {
  // from withStyles
  classes: PropTypes.object,

  // DialogHoc
  setDialog: PropTypes.func,

  // Component
  onClose: PropTypes.func,
  feature: PropTypes.object,
  showDownloadButton: PropTypes.bool,
};

export default compose(
  DialogHoc(),
)(SingleFeatureModal);
