import { call, take, takeEvery, put, race } from 'redux-saga/effects';
import axios from 'axios';

import { resourceStoreTypes, resourceSagaUtils } from '@quicken-com/react.flux.core';

import { createDocument, createDocumentSuccess, createDocumentFailure, deleteDocument } from 'data/documents/actions';
import { mkDocument } from 'data/documents/types';

import * as actions from './actions';
import { getLastSyncDate } from './selectors';
import {
  transformResponseToDocuments,
  transformDocumentWithResponse,
  transformDocumentToRequestData } from './transformers';


// ===============================================================================================
// SAGAS functions for documents.  These are the functions responsible for communicating directly
// with external endpoints asynchronously.

// ====================================================
// ACTION WATCHERS to trigger SAGAS calls

const mkResourceConfig = (overrides) => resourceStoreTypes.mkQcsSyncResourceConfig({
  resourceName: 'document',
  resourceBaseUrl: '/documents',
  getLastSyncDate,

  transformResponseToResources: transformResponseToDocuments,
  transformResourceToRequestData: transformDocumentToRequestData,
  transformResourceWithResponse: transformDocumentWithResponse,

  ...overrides,
});

export function* getDocumentsActionWatcher() {
  while (true) {
    const action = yield take(actions.getDocuments);
    const resourceConfig = mkResourceConfig({
      successAction: actions.getDocumentsSuccess,
      failureAction: actions.getDocumentsFailure,
    });
    yield call(resourceSagaUtils.qcsSyncGetResources, resourceConfig, action);
  }
}

export function* createDocumentActionWatcher() {
  const resourceConfig = mkResourceConfig({
    successAction: actions.createDocumentSuccess,
    failureAction: actions.createDocumentFailure,
  });
  yield takeEvery(actions.createDocument, resourceSagaUtils.qcsSyncCreateResource, resourceConfig);
}

export function* deleteDocumentActionWatcher() {
  const resourceConfig = mkResourceConfig({
    successAction: actions.deleteDocumentSuccess,
    failureAction: actions.deleteDocumentFailure,
  });
  yield takeEvery(actions.deleteDocument, resourceSagaUtils.qcsSyncDeleteResource, resourceConfig);
}

export function* getDocumentActionWatcher() {
  const resourceConfig = mkResourceConfig({
    successAction: actions.getDocumentSuccess,
    failureAction: actions.getDocumentFailure,
    resourceBaseUrl: '/documents/%s/original',
  });
  yield takeEvery(actions.getDocument, resourceSagaUtils.qcsSyncGetResource, resourceConfig);
}

export function* uploadDocumentActionWatcher() {
  yield takeEvery(actions.uploadDocument, function* _uploadDocument(uploadDocumentAction) {
    yield put(createDocument(mkDocument(uploadDocumentAction.payload.document)));
    const { createDocumentSuccessAction, createDocumentFailureAction } = yield race({
      createDocumentSuccessAction: take((action) =>
        action.type === createDocumentSuccess.toString() && action.payload.clientId && action.payload.clientId === uploadDocumentAction.payload.document.clientId),
      createDocumentFailureAction: take((action) =>
        action.type === createDocumentFailure.toString() && action.payload.clientId && action.payload.clientId === uploadDocumentAction.payload.document.clientId),
    });

    if (createDocumentSuccessAction
      && assert(createDocumentSuccessAction.payload.url, `'url' unexpected create document response ${JSON.stringify(createDocumentSuccessAction, null, 2)}`)
      && assert(createDocumentSuccessAction.payload.contentType, `'contentType' unexpected create document response ${JSON.stringify(createDocumentSuccessAction, null, 2)}`)
      && assert(createDocumentSuccessAction.payload.id, `'id' unexpected create document response ${JSON.stringify(createDocumentSuccessAction, null, 2)}`)
    ) {
      let dataUploadResponse;
      try {
        dataUploadResponse = yield call(
          axios.put,
          createDocumentSuccessAction.payload.url,
          uploadDocumentAction.payload.data,
          { headers: { 'Content-Type': createDocumentSuccessAction.payload.contentType } },
        );
      } catch (error) {
        yield put(deleteDocument(mkDocument({ id: createDocumentSuccessAction.payload.id })));
        yield put(actions.uploadDocumentFailure({
          clientId: uploadDocumentAction.payload.clientId,
          errorDescription: `${error.name} ${error.message}`,
          ...error,
        }));
        uploadDocumentAction.meta?.reject?.(error);
        return;
      }

      if (dataUploadResponse && dataUploadResponse.status === 200) {
        yield put(actions.uploadDocumentSuccess({
          clientId: uploadDocumentAction.payload.clientId,
        }));
        uploadDocumentAction.meta?.resolve?.(createDocumentSuccessAction.payload);
      } else {
        yield put(deleteDocument(mkDocument({ id: createDocumentSuccessAction.payload.id })));
        yield put(actions.uploadDocumentFailure({
          clientId: uploadDocumentAction.payload.clientId,
          errorDescription: 'document upload',
          ...dataUploadResponse,
        }));
        uploadDocumentAction.meta?.reject?.(dataUploadResponse);
      }
    } else {
      yield put(actions.uploadDocumentFailure({
        clientId: uploadDocumentAction.payload.clientId,
        errorDescription: 'create document placeholder',
        ...(createDocumentFailureAction ? createDocumentFailureAction.payload : createDocumentSuccessAction.payload),
      }));
      uploadDocumentAction.meta?.reject?.(createDocumentFailureAction);
    }
  });
}

export function* uploadDataActionWatcher() {
  yield takeEvery(actions.uploadData, function* uploadData(action) {
    let result;
    try {
      result = yield call(
        axios.put,
        action.payload.url,
        action.payload.data,
        { headers: { 'Content-Type': action.payload.contentType } }
      );

      yield put(actions.uploadDataSuccess(result));
      action.meta?.resolve?.(result);

    } catch (error) {
      result = error;
      yield put(actions.uploadDataFailure(result));
      action.meta?.reject?.(result);
    }

    return result;
  });
}

// ====================================================
// EXPORTS

export default [
  getDocumentsActionWatcher,
  createDocumentActionWatcher,
  deleteDocumentActionWatcher,
  getDocumentActionWatcher,
  uploadDocumentActionWatcher,
  uploadDataActionWatcher,
];
