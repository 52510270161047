import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import makeStyles from '@mui/styles/makeStyles';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { deleteMemorizedRule } from 'data/memorizedRules/actions';
import { mkMemorizedRule } from 'data/memorizedRules/types';

import QButton from 'components/QButton';
import MemorizedRuleForm from 'components/Forms/MemorizedRuleForm';
import QIconButton from 'components/QIconButton';

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    position: 'absolute',
    left: 24,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

const MemorizedRuleDialogForm = React.memo((props) => {
  const { memorizedRule, fromTxn, viewExistingTransactions, setViewExistingTransactions, onClose, ...otherProps } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const formikRef = useRef();

  return (
    <>
      <DialogContent>
        <Box minWidth={650}>
          <MemorizedRuleForm
            formikRef={formikRef}
            memorizedRule={memorizedRule}
            onClose={onClose}
            fromTxn={fromTxn}
            setViewExistingTransactions={setViewExistingTransactions}
            viewExistingTransactions={viewExistingTransactions}
            {...otherProps}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        {
          memorizedRule?.id && !viewExistingTransactions &&
            <QIconButton
              className={classes.deleteButton}
              id="delete-memorized-rule"
              color="primary"
              aria-label="delete category rule"
              onClick={() => {
                dispatch(deleteMemorizedRule(mkMemorizedRule({
                  id: memorizedRule.id,
                  isDeleted: true,
                }),
                { undo: { userMessage: 'Category rule deleted.' } }));
                onClose?.(undefined, 'delete');
              }}
            >
              <DeleteOutlineIcon />
            </QIconButton>
        }

        {
          viewExistingTransactions &&
            <QButton
              variant={'outlined'}
              onClick={() => setViewExistingTransactions(false)}
            >
              back to rule details
            </QButton>
        }

        {
          !viewExistingTransactions &&
            <>
              <QButton
                id="memorized-rule-submit-button"
                variant="contained"
                onClick={() => formikRef.current?.submitForm?.()}
              >
                {memorizedRule?.id ? 'Update' : 'Create'}
              </QButton>
            </>
        }
      </DialogActions>
    </>
  );
});

MemorizedRuleDialogForm.propTypes = {
  memorizedRule: PropTypes.object,
  onClose: PropTypes.func,
  fromTxn: PropTypes.bool,
  viewExistingTransactions: PropTypes.bool,
  setViewExistingTransactions: PropTypes.func,
};

export default MemorizedRuleDialogForm;
