
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { DateTime } from 'luxon';
import { List as ImmutableList } from 'immutable';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { scheduledTransactionsUtils, scheduledTransactionsActions, scheduledTransactionsTypes } from '@quicken-com/react.flux.scheduled-transactions';
import { categoriesSelectors } from '@quicken-com/react.flux.categories';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';
import { chartOfAccountsTypes, chartOfAccountsUtils, chartOfAccountsSelectors } from '@quicken-com/react.flux.chart-of-accounts';
import { transactionsTypes } from '@quicken-com/react.flux.transactions';

import makeStyles from '@mui/styles/makeStyles';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';

// DATA
import { useDispatch, useSelector } from 'react-redux';
import store from 'store';
import { updateNewTags } from 'data/transactions/utils';

import { getBillerAccountForId } from 'data/billerAccounts/selectors';
import { isIncomeTxn } from 'data/transactions/selectors';

// CUSTOM
import StdDialog from 'components/Dialogs/StdDialog';
import QButton from 'components/QButton';
import ConnectedAvatar from 'components/ConnectedAvatar';
import DownshiftField from 'components/QuickenControls/DownshiftField';
import AmountField, { ShowSignEnum } from 'components/QuickenControls/AmountField';
import AccountField from 'components/QuickenControls/AccountField';
import CategoryField from 'components/QuickenControls/CategoryField';
import TagsField from 'components/QuickenControls/TagsField';
import BillAmountField, { autoAdjustAmountEnum } from 'components/BillPresentment/BillAmountField';
import DueDateField from 'components/BillPresentment/DueDateField';
import DateField from 'components/Transactions/DateField';


const useStyles = makeStyles((theme) => ({
  dialogContentPadding: {
    maxWidth: 600,
    paddingBottom: 16,
  },

  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    maxWidth: 552,
  },

  leftSide: {
    width: 80,
    marginRight: 32,
  },
  rightSide: {
    flexGrow: 2,
    minWidth: 420,
    paddingRight: 6,
    display: 'flex',
    flexDirection: 'column',
  },

  row: {
    display: 'flex',
    paddingBottom: 16,
    paddingRight: 40,
  },

  divider: {
    width: '100%',
    height: 1,
    backgroundColor: theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.15),
    marginBottom: 24,
  },
  dividerThin: {
    extend: 'divider',
    marginBottom: 8,
  },

  name: {
    paddingBottom: 16,
  },

  amtField: {
    paddingRight: 16,
  },
  amtTxt: {
    textAlign: 'left',
  },
  categories: {
    inputField: {
      '& input': {
        '&::-ms-clear ': {
          display: 'none',
        },
      },
    },
  },
  catField: {
    paddingRight: 16,
  },
  noPadding: {
    padding: 0,
  },
  expansionPanelSummary: {
    flexDirection: 'row-reverse',
    padding: 0,
  },
  expansionPanelSummaryContent: {
    paddingLeft: 12,
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.greyScaleDeprecated[5]}`,
    borderRadius: 4,
    padding: '6px 10px',
  },
  dateWidth: {
    width: '100%',
  },

  downShift: {
    width: '100%',
    paddingRight: 16,
  },
  accountField: {
    width: 276,
    paddingBottom: 16,
  },

  bottomSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  bottomLeft: {
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
  },
  bottomRight: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
  },
  greyText: {
    color: theme.palette.text.secondary,
  },
  type: {
    paddingTop: 8,
  },
  billAmount: {
    paddingRight: 16,
  },
}));

const incomeRecurrence = {
  byMonthDay: [1],
  frequency: 'WEEKLY',
  interval: 2,
};

const billRecurrence = {
  byMonthDay: [1],
  frequency: 'MONTHLY',
  interval: 1,
};


function ScheduledTransactionEditDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { open, onBack, title, onSubmit, onClose, fakeCreate } = props;
  const model = props.scheduledTransaction;

  const create = fakeCreate || !model?.id;

  const currentDate = DateTime.local().startOf('day');

  // Data stuff
  const presentBills = useSelector((state) => featureFlagsSelectors.getFeatureFlags(state).get('billPresentment'));
  const connected = presentBills && model?.billPresentmentAccountId && model?.billPresentmentAccountId !== '0';
  const billConnected = useSelector((state) => Boolean(connected && getBillerAccountForId(state, model?.billPresentmentAccountId)));

  const [focus, setFocus] = useState(null);


  // # ===================================================================================== #
  // # -----                               Formik Setup                                ----- #
  // # ===================================================================================== #
  const initialValues = useMemo(() => {
    const stModel = model;
    const scheduledTransaction = stModel?.toJS ? stModel.toJS() : stModel;
    let formikScheduledTransaction;
    if (scheduledTransaction) {
      const defaultCoa = scheduledTransaction.transaction?.coa || chartOfAccountsTypes.mkChartOfAccount({ id: '0', type: 'UNCATEGORIZED' });
      const isTransfer = chartOfAccountsUtils.isTransferCOA(defaultCoa);
      const isIncome = categoriesSelectors.isIncomeCat(null, defaultCoa.id);
      const defaultRecurrence = scheduledTransaction.recurrence || (isIncome ? incomeRecurrence : billRecurrence);

      const frequencyAlias = scheduledTransactionsUtils.frequencyAliasFromRecurrence(defaultRecurrence);
      const txnDate = DateTime.fromISO(scheduledTransaction.transaction?.postedOn);
      const dueOnDate = DateTime.fromISO(scheduledTransaction.dueOn);
      const endOnDate = scheduledTransaction.recurrence && DateTime.fromISO(scheduledTransaction.recurrence.endOn);
      const virtualEndOn = endOnDate && endOnDate.isValid ? endOnDate : null;
      const negator = scheduledTransactionsUtils.negatorFromCOA(scheduledTransaction.transaction?.coa);
      const virtualShowSign = Math.sign(scheduledTransaction?.transaction?.amount || -1) !== negator ? ShowSignEnum.ALWAYS : ShowSignEnum.NEVER;

      const { autoAdjustAmount, autoAdjustDueOn } = scheduledTransaction;

      const BPSFields = billConnected ? {
        autoAdjustAmount: autoAdjustAmount || autoAdjustAmountEnum.NONE,
        autoAdjustDueOn: Boolean(autoAdjustDueOn),
      } : {};

      const amount = String(scheduledTransaction.transaction && !Number.isNaN(scheduledTransaction.transaction.amount) ?
        String(negator < 0 ? Math.abs(scheduledTransaction.transaction.amount) : scheduledTransaction.transaction.amount) :
        0);

      const immutableTags = (scheduledTransaction.transaction && scheduledTransaction.transaction.tags) ?
        ImmutableList(scheduledTransaction.transaction.tags) : undefined;

      const spreadTxn = (scheduledTransaction.transaction && scheduledTransaction.transaction.toJS ? scheduledTransaction.transaction.toJS() : scheduledTransaction.transaction) || {};

      const state = store.getState();
      const isCredit = chartOfAccountsSelectors.isCreditBill(state, spreadTxn.accountId, defaultCoa);

      formikScheduledTransaction = { // all modifiable props should be listed below (formik requires it)
        ...scheduledTransaction,
        ...BPSFields,
        dueOn: dueOnDate?.isValid ? dueOnDate : ((txnDate?.isValid && txnDate) || currentDate),
        transaction: {
          payee: '',
          accountId: '',
          coa: defaultCoa,
          ...spreadTxn,
          isSubscription: Boolean(spreadTxn.isSubscription),
          isBill: Boolean(!spreadTxn.isSubscription && !isIncome && (isCredit || !isTransfer)),
          tags: immutableTags,
          amount,
        },
        virtualFrequency: frequencyAlias,
        virtualShowSign,
        virtualEndOn,
      };
    }
    return formikScheduledTransaction;
  }, [billConnected, model]); // eslint-disable-line react-hooks/exhaustive-deps

  const formikSubmit = (values, actions) => {
    // both the below dates are formatted in such a way because if you send an ISO QCS sometimes won't generate models
    // for same-day because it treats them as in the past depending on the time of day
    const dueOn = values.dueOn?.isValid ? values.dueOn.toFormat('yyyy-MM-dd') : undefined;
    const endOn = values.virtualEndOn?.isValid ? values.virtualEndOn.toFormat('yyyy-MM-dd') : '';

    const valTxn = { ...(model?.transaction || {}), ...(values?.transaction || {}) };

    const negator = scheduledTransactionsUtils.negatorFromCOA(valTxn.coa);
    const amount = (valTxn.amount?.includes('+') || valTxn.amount?.includes('-')) ? Number(valTxn.amount) : Number(valTxn.amount) * negator;
    valTxn.amount = amount;
    const count = undefined;

    const state = store.getState();
    const isCredit = valTxn && chartOfAccountsSelectors.isCreditBill(state, valTxn.accountId, valTxn.coa);
    const isIncome = isIncomeTxn(state, valTxn);
    const isTransfer = chartOfAccountsUtils.isTransferCOA(valTxn.coa);

    if (isIncome) {
      valTxn.isBill = false;
      valTxn.isSubscription = false;
    } else if (isCredit) {
      valTxn.isBill = true;
      valTxn.isSubscription = false;
    } else if (isTransfer) {
      valTxn.isBill = false;
      valTxn.isSubscription = false;
    }

    const recurrence = values.virtualFrequency === scheduledTransactionsUtils.frequencyAliasTypes.custom ?
      { ...model.recurrence, endOn } :
      scheduledTransactionsUtils.recurrenceFromFrequencyAlias(values.virtualFrequency, dueOn);

    const newTxn = updateNewTags(new transactionsTypes.CashFlowTransaction({ ...valTxn, amount }), true);
    const newRecurrence = scheduledTransactionsTypes.mkRecurrence({ endOn, count, ...recurrence });

    if (values.id) {
      dispatch(scheduledTransactionsActions.updateScheduledTransaction(scheduledTransactionsTypes.mkScheduledTransaction({
        ...values,
        dueOn,
        type: scheduledTransactionsTypes.STTypeEnum.UNKNOWN, // to avoid conflicts like BILL + isSubscription conflict
        recurrence: newRecurrence,
        transaction: newTxn,
      }), { undo: { userMessage: 'Recurring transaction updated.' } }));
    } else {
      dispatch(scheduledTransactionsActions.createScheduledTransaction(scheduledTransactionsTypes.mkScheduledTransaction({
        clientId: uuidv4().toUpperCase(), // backup for irresponsible developers
        ...values,
        dueOn,
        type: scheduledTransactionsTypes.STTypeEnum.UNKNOWN, // to avoid conflict like BILL + isSubscription conflict
        isUserVerified: true,
        recurrence: newRecurrence,
        transaction: newTxn,
      }), { undo: { userMessage: 'Recurring transaction created.' } }));
    }

    actions.setSubmitting(false);
    if (onSubmit) {
      onSubmit();
    } else {
      onClose(false);
    }
  };



  // # ===================================================================================== #
  // # -----                            Action Functions                               ----- #
  // # ===================================================================================== #
  const deleteFunction = () => {
    dispatch(scheduledTransactionsActions.deleteScheduledTransaction(scheduledTransactionsTypes.mkScheduledTransaction({
      clientId: model.clientId,
      id: model.id,
      isDeleted: true,
    }), { undo: { userMessage: 'Recurring transaction deleted.' } }));
    onClose?.(false, true);
  };


  // # ===================================================================================== #
  // # -----                                Renderers                                  ----- #
  // # ===================================================================================== #

  const renderBPSFields = (formikProps, isCredit) => {
    if (!billConnected) {
      return (<div className={classes.dividerThin} />);
    }

    return (
      <>
        <div className={classes.divider} />
        <Typography variant="body2" className={classes.greyText}>
          When a new bill arrives, update reminder to
        </Typography>

        <div className={classes.row}>
          <BillAmountField isCredit={isCredit} formikBag={formikProps} className={classes.billAmount} />

          <DueDateField isCredit={isCredit} formikBag={formikProps} />
        </div>

        <div className={classes.divider} />
      </>
    );
  };

  const renderForm = (formikProps) => {

    const { values, touched, errors } = formikProps;

    const valTxn = { ...(model?.transaction || {}), ...(values?.transaction || {}) };

    const state = store.getState();
    const isCredit = valTxn && chartOfAccountsSelectors.isCreditBill(state, valTxn.accountId, valTxn.coa);
    const isIncome = isIncomeTxn(state, valTxn);
    const isTransfer = chartOfAccountsUtils.isTransferCOA(valTxn.coa);

    return (
      <Form>
        <div className={classes.root}>
          <div className={classes.leftSide}>
            <ConnectedAvatar model={model} size={80} />
          </div>

          <div className={classes.rightSide}>
            <Field
              name="transaction.payee"
              validate={(value) => value && value.length > 0 ? undefined : 'required'}
            >
              {({ field }) =>
                <TextField
                  {...field}
                  id="payee"
                  label="Name"
                  className={classes.name}
                  error={Boolean(_.get(touched, field.name) && _.get(errors, field.name))}
                  InputProps={{
                    autoComplete: 'off',
                  }}
                  variant={'outlined'}
                  margin="normal"
                  fullWidth
                  autoFocus
                  onFocus={(event) => event.target.select()}
                />}
            </Field>

            <div className={classes.row}>
              <Field
                name="transaction.amount"
                validate={(value) => value === '' ? 'required' : undefined}
              >
                {({ field }) => (
                  <AmountField
                    {...field}
                    showSign={values.virtualShowSign}
                    showAmountAdornment
                    editable
                    omitCents={false}
                    currencySymbol="USD"
                    amountType="amount"
                    id="schedtxn-amount"
                    label="Recurring Amount"
                    fullWidth
                    textFieldVariant={'outlined'}
                    marginProp={'normal'}
                    autoComplete="off"
                    error={Boolean(_.get(touched, field.name) && _.get(errors, field.name))}
                    className={classes.amtField}
                    classes={{ inputField: classes.amtTxt }}
                  />
                )}
              </Field>

              {/* Date Field */}
              <Field
                name="dueOn"
                validate={(value) => {
                  let errorMessage;
                  if (!value) {
                    errorMessage = 'Required';
                  } else if (formikProps.initialValues.dueOn !== value) {
                    if (!value?.isValid) {
                      errorMessage = 'Invalid date format';
                    } else if (create && (value < currentDate)) {
                      errorMessage = 'Must be today or in the future';
                    } else if (formikProps.values.virtualEndOn && (value > formikProps.values.virtualEndOn)) {
                      errorMessage = `Must be before ${formikProps.values.virtualEndOn?.toLocaleString()}`;
                    }
                  }
                  return errorMessage;
                }}
              >
                {({ field }) => (
                  <DateField
                    {...field}
                    id="next-date"
                    date={field.value}
                    allowInvalid
                    onChange={(newDate) => {
                      formikProps.setFieldValue(field.name, newDate);
                    }}
                    onBlur={(newDate) => {
                      if (newDate) {
                        formikProps.setFieldValue(field.name, newDate);
                      }
                    }}
                    label="Next Date"
                    className={classes.dateWidth}
                    disablePast={create}
                    minDateMessage="Must be today or in the future"
                    maxDate={formikProps.values.virtualEndOn || undefined}
                    maxDateMessage={`Must be before ${formikProps.values.virtualEndOn?.toLocaleString()}`}
                    error={Boolean(_.get(errors, field.name))}
                    helperText={_.get(errors, field.name)}
                  />)}
              </Field>
            </div>


            {/* Bill Connect stuff */}
            {renderBPSFields(formikProps, isCredit)}

            <div className={classes.row}>
              <Field
                name="virtualFrequency"
                validate={(value) => value && value.length > 0 ? undefined : 'required'}
              >
                {({ field: { onChange, ...field } }) =>
                  <DownshiftField
                    key={_.get(values, field.name)}
                    autoFocus={focus === field.name}
                    initialItemSelected={_.get(values, field.name)}
                    onSelected={(frequencyAlias) => {
                      formikProps.setFieldValue(field.name, frequencyAlias);
                      setFocus(field.name);
                      if (frequencyAlias === scheduledTransactionsUtils.frequencyAliasTypes.custom) {
                        // TODO: impelment custom
                      }
                    }}
                    items={scheduledTransactionsUtils.frequencyAliases}
                    textFieldProps={{
                      InputProps: {
                        ...field,
                      },
                      id: 'frequency',
                      label: 'Frequency',
                      margin: 'normal',
                      variant: 'outlined',
                      fullWidth: true,
                      autoComplete: 'off',
                      error: Boolean(_.get(touched, field.name) && _.get(errors, field.name)),
                      helperText: _.get(touched, field.name) && _.get(errors, field.name),
                    }}
                    lightWeight
                    className={classes.downShift}
                  />}
              </Field>

              {/* End Date Field */}
              <Field
                name="virtualEndOn"
                validate={(value) => {
                  let errorMessage;
                  if (value) {
                    if (!value.isValid) {
                      errorMessage = 'Invalid date format';
                    } else if (formikProps.values.dueOn?.isValid && (formikProps.values.dueOn > value)) {
                      errorMessage = `Must be after ${formikProps.values.dueOn?.toLocaleString()}`;
                    }
                  }
                  return errorMessage;
                }}
              >
                {({ field }) => (
                  <DateField
                    {...field}
                    id="end-date"
                    date={field.value}
                    onChange={(newDate) => {
                      if (newDate === null) {
                        formikProps.setFieldValue(field.name, null);
                      } else {
                        formikProps.setFieldValue(field.name, newDate);
                      }
                    }}
                    onBlur={(newDate) => {
                      if (newDate) {
                        formikProps.setFieldValue(field.name, newDate);
                      } else {
                        formikProps.setFieldValue(field.name, null);
                      }
                    }}
                    label="End Date"
                    className={classes.dateWidth}
                    minDate={formikProps.values.dueOn || undefined}
                    minDateMessage={`Must be after ${formikProps.values.dueOn?.toLocaleString()}`}
                    allowNever
                    allowInvalid
                    error={Boolean(_.get(errors, field.name))}
                    helperText={_.get(errors, field.name)}
                  />)}
              </Field>
            </div>

            <Field
              name="transaction.accountId"
              validate={(value) => value && value.length > 0 ? undefined : 'required'}
            >
              {({ field: { onChange, ...field } }) =>
                <AccountField
                  key={_.get(values, field.name)}
                  onSelected={(account) => {
                    if (account) { // bugsnag check
                      if (!touched.transaction.isBill && !touched.transaction.isSubscription) {
                        if (chartOfAccountsSelectors.isBill(state, account.id, values.transaction && values.transaction.coa)) {
                          if (!values.transaction.isSubscription) {
                            formikProps.setFieldValue('transaction.isBill', true);
                            formikProps.setFieldValue('transaction.isSubscription', false);
                          }
                        }
                      }
                      setFocus(field.name);
                      formikProps.setFieldValue(field.name, account ? account.id : undefined);
                    }
                  }}
                  initialAccountId={_.get(values, field.name)}
                  autoFocus={focus === field.name}
                  id="account"
                  label="Account"
                  margin="normal"
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  doNotIncludeClosed
                  error={Boolean(_.get(touched, field.name) && _.get(errors, field.name))}
                  InputProps={{ ...field }}
                  className={classes.accountField}
                />}
            </Field>

            <div className={classes.row}>
              <Field
                name="transaction.coa"
                validate={(coa) => coa ? undefined : 'required'}
              >
                {({ field }) =>
                  <CategoryField
                    {...field}
                    onChange={(coa) => {
                      if (!formikProps.touched.transaction.isExcludedFromReports) {
                        if (coa.type === chartOfAccountsTypes.CoaTypeEnum.ACCOUNT || coa.type === chartOfAccountsTypes.CoaTypeEnum.BALANCE_ADJUSTMENT) {
                          formikProps.setFieldValue('transaction.isExcludedFromReports', true);
                          formikProps.setFieldValue('transaction.isExcludedFromF2S', true);
                        } else {
                          formikProps.setFieldValue('transaction.isExcludedFromReports', false);
                          formikProps.setFieldValue('transaction.isExcludedFromF2S', false);
                        }
                      }

                      // bill + sub flag updates
                      if (chartOfAccountsSelectors.isCreditBill(state, valTxn.accountId, coa)) {
                        // force credit payments to bill
                        formikProps.setFieldValue('transaction.isBill', true);
                        formikProps.setFieldValue('transaction.isSubscription', false);

                      } else if (chartOfAccountsSelectors.isIncomeOrTransfer(state, coa)) {
                        // force incomes and transfer to nothing
                        formikProps.setFieldValue('transaction.isBill', false);
                        formikProps.setFieldValue('transaction.isSubscription', false);

                      } else if (props.subscriptionOnly) {
                        // force subscription if entered from 'create subscription'
                        formikProps.setFieldValue('transaction.isBill', false);
                        formikProps.setFieldValue('transaction.isSubscription', true);

                      } else if (!values.transaction.isBill && !values.transaction.isSubscription) {
                        // force bill if no value
                        formikProps.setFieldValue('transaction.isBill', true);
                        formikProps.setFieldValue('transaction.isSubscription', false);
                      }
                      formikProps.setFieldValue(field.name, coa);
                    }}
                    error={Boolean(_.get(touched, field.name) && _.get(errors, field.name))}
                    label="Category"
                    editable
                    createEnabled
                    allowBlank
                    longCats={false}
                    margin="normal"
                    textFieldVariant={'outlined'}
                    initialFocus={false}
                    autoFocus={false}
                    fontSize="16px"
                    inputStyle={classes.categories}
                    InputProps={{
                      id: 'transaction-input',
                      style: { height: undefined },
                    }}
                    textFieldProps={{
                      helperText: _.get(touched, field.name) && _.get(errors, field.name),
                    }}
                    classes={{ root: classes.catField }}
                  />}
              </Field>


              <Field name="transaction.tags">
                {({ field }) =>
                  <TagsField
                    {...field}
                    onChange={(tags) => formikProps.setFieldValue(field.name, tags)}
                    error={Boolean(_.get(touched, field.name) && _.get(errors, field.name))}
                    helperText={_.get(touched, field.name) && _.get(errors, field.name)}
                    label="Tags"
                    placeholder=" "
                    margin="normal"
                    textFieldVariant={'outlined'}
                    id="transaction-tags"
                    fullWidth
                    autoFocus={false}
                  />}
              </Field>
            </div>

            <div className={classes.divider} />

            <div className={classes.bottomSection}>
              <div className={classes.bottomLeft}>
                <Typography variant="body2" className={classes.greyText}>
                  Type
                </Typography>

                {isIncome || isCredit || isTransfer ?
                  <Typography variant="body1" className={classes.type}>
                    {isIncome && 'Income'}
                    {!isIncome && isCredit && 'Bill'}
                    {!isIncome && !isCredit && 'Transfer'}
                  </Typography>
                  :
                  <>
                    <Field name="transaction.isBill">
                      {({ field }) =>
                        <FormControlLabel
                          {...field}
                          control={<Radio />}
                          label="Bill"
                          id="bill-checkbox"
                          checked={Boolean(field.value)}
                          onChange={(event, checked) => {
                            field.onChange(event, Boolean(checked));
                            if (checked) {
                              formikProps.setFieldValue('transaction.isSubscription', false);
                              formikProps.setFieldValue('transaction.isBill', true);
                            } else {
                              formikProps.setFieldValue('transaction.isSubscription', true);
                            }
                          }}
                        />}
                    </Field>

                    <Field name="transaction.isSubscription">
                      {({ field }) =>
                        <FormControlLabel
                          {...field}
                          control={<Radio />}
                          label="Subscription"
                          id="subscription-checkbox"
                          checked={Boolean(field.value)}
                          onChange={(event, checked) => {
                            field.onChange(event, Boolean(checked));
                            if (checked) {
                              formikProps.setFieldValue('transaction.isBill', false);
                              formikProps.setFieldValue('transaction.isSubscription', true);
                            } else {
                              formikProps.setFieldValue('transaction.isBill', true);
                            }
                          }}
                        />}
                    </Field>
                  </>}
              </div>

              <div className={classes.bottomRight}>
                <Typography variant="body2" className={classes.greyText}>
                  Visibility
                </Typography>

                <Field name="transaction.isExcludedFromReports">
                  {({ field }) =>
                    <FormControlLabel
                      {...field}
                      control={<Checkbox />}
                      label="Ignore from Reports"
                      id="ignore-checkbox"
                      checked={Boolean(field.value)}
                    />}
                </Field>

                <Field name="transaction.isExcludedFromF2S">
                  {({ field }) =>
                    <FormControlLabel
                      {...field}
                      control={<Checkbox />}
                      label="Ignore from Spending Plan"
                      id="ignore-spending-plan-checkbox"
                      checked={Boolean(field.value)}
                    />}
                </Field>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  };

  return (
    <Formik
      key={model?.id || model?.clientId}
      initialValues={initialValues}
      validateOnChange
      onSubmit={formikSubmit}
    >
      {(formikProps) => {
        const { errors, dirty } = formikProps || {};
        const hasErrors = Boolean(errors && Object.keys(errors).length);
        return (
          <StdDialog
            open={open}
            onClose={() => onClose?.(dirty)}
            aria-labelledby="edit-recurrence-dialog"
            title={title}
            showBackButton={Boolean(onBack)}
            onBack={onBack}
          >
            <DialogContent className={classes.dialogContentPadding} id="frm-root">
              {renderForm(formikProps)}
            </DialogContent>

            <DialogActions>
              <QButton
                id="delete-recurrence"
                variant={create ? 'outlined' : 'delete'}
                onClick={create ? () => onClose?.(dirty) : deleteFunction}
                disableOutline
              >
                {create ? 'Cancel' : 'Delete'}
              </QButton>
              <QButton
                id="create-update-recurrence"
                type="submit"
                variant="contained"
                disabled={(!dirty && !fakeCreate) || hasErrors}
                onClick={() => {
                  formikProps.submitForm();
                }}
              >
                {create ? 'Create' : 'Update'}
              </QButton>
            </DialogActions>
          </StdDialog>
        );
      }}
    </Formik>
  );
}

ScheduledTransactionEditDialog.defaultProps = {
  title: 'Edit series',
  subscriptionOnly: false,
};

ScheduledTransactionEditDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  scheduledTransaction: PropTypes.object,
  onClose: PropTypes.func, // accepts props 'dirty', and 'forceClose' in order
  onSubmit: PropTypes.func,
  onBack: PropTypes.func,
  subscriptionOnly: PropTypes.bool,
  fakeCreate: PropTypes.bool,
};

export default ScheduledTransactionEditDialog;
