/* eslint no-unused-expressions: 0 */
import styled from 'styled-components';
import isAcme from 'isAcme';


export const PrimaryTextLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

// this is the line that display refresh time and status
// (relates to property "dense", meaning if dense, display this line too)
export const SecondaryTextLine = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  font-style: italic;
  font-weight: 200;
  color: ${({ theme }) => theme.palette.text.primary};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;


export const styles = (theme) => {

  const { accountDrawer } = theme.components;
  const { accountListBaseFontSize: baseFontSize } = accountDrawer;
  return ({
    root: {
      width: '100%',
      background: isAcme ? theme.palette.background.paper : theme.palette.background,
    },
    collapseContainer: {
      paddingBottom: 5,
    },
    listItem: {
      ...theme.typography.subtitle1, // Maybe good?
      fontWeight: 400,
      fontSize: 16,
      paddingRight: accountDrawer.listPaddingRight,
      paddingLeft: accountDrawer.listPaddingLeft,
      marginLeft: 0,
      '&.selected': {
        background: accountDrawer.highlightColor,
      },
      '&.disabled': {
        cursor: 'default',
      },
      '&:hover': {
        backgroundColor: accountDrawer.hoverColor,
      },
    },
    listItemL0: { // superRegister
      extend: 'listItem',
      paddingLeft: accountDrawer.basePadding,
      textTransform: isAcme ? 'capitalize' : 'uppercase',
      height: accountDrawer.l0Height,
      '&.expandable': {
        fontWeight: 400,
      },
    },
    listItemL1: { // bank + investment
      extend: 'listItem',
      textTransform: isAcme ? 'capitalize' : 'uppercase',
      margin: accountDrawer.l1Margin,
      height: accountDrawer.l1Height,
      backgroundColor: accountDrawer.l1Color,
      '&.expandable': {
        fontWeight: 400,
      },
    },
    listItemL2: {
      extend: 'listItem',
      fontSize: baseFontSize * 0.90,
      height: accountDrawer.l2Height,
      textTransform: 'capitalize',
      '&.expandable': {
        fontWeight: 400,
      },
    },
    listItemL3: {
      extend: 'listItem',
      fontSize: baseFontSize * 0.80,
      height: accountDrawer.l3Height,
    },
    listItemText: {
      paddingRight: 0,
      margin: 0,
    },
    listItemTextL123: {
      '&.selected': {
        color: accountDrawer.highlightText,
      },
    },
    listItemTextL0: {
      ...(isAcme ? theme.typography.subtitle1 : theme.typography.body1),
      marginLeft: 0, // accountListLevelIndent,
      color: accountDrawer.textColor,
      fontSize: !isAcme && `${baseFontSize}px`,
      fontWeight: !isAcme && 500,
      '&.selected': {
        color: accountDrawer.highlightText,
      },
    },
    listItemTextL1: {
      ...(isAcme ? theme.typography.subtitle1 : theme.typography.body1),
      marginLeft: 0, // accountListLevelIndent,
      color: accountDrawer.textColor,
      fontSize: !isAcme && `${baseFontSize * 0.93}px`,
      fontWeight: !isAcme && 500,
      '&.selected': {
        color: accountDrawer.highlightText,
      },
    },
    listItemTextL2: {
      ...(isAcme ? theme.typography.body2 : theme.typography.body1),
      fontSize: !isAcme && `${baseFontSize * 0.85}px`,
      fontWeight: !isAcme && 500,
      color: accountDrawer.l2TextColor,
      '&.selected': {
        color: accountDrawer.highlightText,
      },
    },
    listItemTextL3: {
      ...theme.typography.body2,
      color: accountDrawer.l3TextColor,
      fontSize: !isAcme && `${baseFontSize * 0.85}px`,
      textAlign: 'left',
      '&.left': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginRight: 16,
      },
      marginLeft: accountDrawer.accountListLevelIndent,
      '&.selected': {
        color: accountDrawer.highlightText,
      },
    },
    listItemIconButtonL0: {
      fontSize: '18px',
      padding: '1px',
      color: accountDrawer.textColor,
    },
    listItemIconButtonL1: {
      fontSize: '18px',
      padding: '1px',
      color: accountDrawer.textColor,
    },
    listItemIconButtonL2: {
      fontSize: '18px',
      padding: '1px',
      color: accountDrawer.textColor,
    },
    listItemIconButtonL3: {
      fontSize: '18px',
      padding: '1px',
      color: accountDrawer.textColor,
    },
    secondaryActionIcon: {
      opacity: 0.8,
      color: theme.palette.greyScaleDeprecated[2],
    },
    positiveBalDark: {
      color: accountDrawer.positiveBalance,
    },
    negativeBalDark: {
      color: accountDrawer.negativeBalance,
    },
    errorIcon: {
      color: accountDrawer.errorIcon,
    },
    infoIcon: {
      color: theme.palette.color5.opacity100,
    },
    refreshingProgress: {
      fontSize: '20px',
      padding: '1px',
      color: theme.palette.greyScaleDeprecated[7],
    },
    sectionContainer: {
      marginBottom: 20,
    },
    accountBar: {
      width: 6,
      height: 4,
      marginRight: 4,
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    closedText: {
      width: 160,
      color: accountDrawer.textColor,
    },
  });
};
