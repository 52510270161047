// BASE
import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { List, Set } from 'immutable';

import { authSelectors } from '@quicken-com/react.flux.auth';
import { tracker } from '@quicken-com/react.utils.core';
import { accountsSelectors } from '@quicken-com/react.flux.accounts';
import { chartOfAccountsUtils } from '@quicken-com/react.flux.chart-of-accounts';

import { cacheableObject, setMaxCacheSize } from 'utils/objectCache';
import useQCurrency from 'components/QCurrency/useQCurrency';

// CUSTOM COMPONENTS
import QCloseBox from 'components/QCloseBox';
import QButton from 'components/QButton';
import QCard from 'components/QCard';

// SELECTORS, ACTIONS, UTILS
import { getBudgetItemsFiltered, getIsLoading as budgetItemsLoading } from 'data/budgetItems/budgetItemsSelectors';
import { createBudgetItemsAction, updateBudgetItemsAction, deleteBudgetItemsAction } from 'data/budgetItems/budgetItemsActions';
import { deleteBudgetsAction } from 'data/budgets/budgetsActions';
import { findGroupIdForCategoryId } from 'data/categoryGroups/categoryGroupsHelpers';
import { getCategoryGroupsById } from 'data/categoryGroups/categoryGroupsSelectors';
import { getBudgetTreeNodes } from 'data/budgets/budgetsSelectors';

// MUI COMPONENTS
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

// PATH RELATIVE IMPORTS
import BudgetSummary from './BudgetSummary';
import EditBudgetCategoryGroups from './EditBudgetCategoryGroups';
import BudgetAddCategory from './BudgetAddCategory';
import BudgetSuggestedCategories from './BudgetSuggestedCategories';
import { TitleHeader, AddCategoryBlock } from './subComponents';
import { createNewBudgetItems } from './editBudgetHelpers';
import { styles } from './styles';

// STYLES HOOK
const useStyles = makeStyles(styles);

// Used for cacheing budgeted COA objects
setMaxCacheSize(200);

/*
 * EditBudgetsDialog Component ***********************************
 */
const propTypes = {
  // Passed In
  onClose: PropTypes.func,
  budget: PropTypes.object,
  startDate: PropTypes.any,
  qCards: PropTypes.object,
};

const EditBudgetsDialog = (props) => {

  // PROPS =========================================================================
  const { onClose, budget, startDate, qCards } = props;

  const startingMonth = moment(startDate).month() + 1; // Number(month) || moment().month() + 1;

  // STATE =========================================================================
  const [currency, setCurrency] = useState('USD');
  const [dialogTitle, setDialogTitle] = useState(null);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [showSuggestedCategories, setShowSuggestedCategories] = useState(false);
  const [accountIds, setAccountIds] = useState(null);
  const [dateRange, setDateRange] = useState({});
  const [eoyDateRange, setEoyDateRange] = useState({});
  const [catsToExclude, setCatsToExclude] = useState(new Set());
  const [qCardsOn, setQCardsOn] = useState(Boolean(qCards));
  const [budgetItemsAddedCount, setBudgetItemsAddedCount] = useState(0);

  // DISPATCHERS ========================================================================
  const dispatch = useDispatch();
  const dispatchCreateBudgetItem = (data) => dispatch(createBudgetItemsAction([data], { undo: { userMessage: 'New budget item created.' } }));
  const dispatchCreateBudgetItems = (data) => dispatch(createBudgetItemsAction(data, { undo: { userMessage: 'New budget items created.' } }));
  const dispatchUpdateBudgetItems = (data) => dispatch(updateBudgetItemsAction(data, { undo: { userMessage: 'Budget items updated.' } }));
  const dispatchDeleteBudget = (data) => dispatch(deleteBudgetsAction([data]));
  const dispatchDeleteBudgetItems = (data) => dispatch(deleteBudgetItemsAction(data, { undo: { userMessage: 'Budget items deleted.' } }));


  // SELECTORS =========================================================================
  const isMacDataset = useSelector((state) => authSelectors.getIsMacDataset(state), shallowEqual);
  const accountsById = useSelector((state) => accountsSelectors.getAccountsById(state), shallowEqual);
  const categoryGroupsById = useSelector((state) => getCategoryGroupsById(state), shallowEqual);
  const budgetItems = useSelector((state) =>
    getBudgetItemsFiltered(state, { budget, ...dateRange }), shallowEqual);
  const budgetItemsToEndOfYear = useSelector((state) =>
    getBudgetItemsFiltered(state, { budget, ...eoyDateRange }), shallowEqual);
  const budgetItemsLoadPending = useSelector((state) => budgetItemsLoading(state), shallowEqual);
  const budgetTreeNodesInternal = useSelector((state) => getBudgetTreeNodes(state, { budget, ...dateRange }));
  const budgetTreeNodes = useMemo(() => budgetTreeNodesInternal.reverse(), [budgetTreeNodesInternal]);

  const { getAccountsSharedCurrency } = useQCurrency();

  const categoryIds = useMemo(() => budgetItems.map((budgetItem) => budgetItem.coa?.id), [budgetItems]);

  // EFFECTS =========================================================================
  /*
   * Determine the date ranges for these budget items
   */
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const monthVal = startingMonth;
    const year = moment(startDate).year();
    const sd = moment({ year, month: monthVal - 1 });
    const ed = moment(sd).endOf(monthVal);
    const eoy = moment({ year, month: 11 });
    setDateRange({ startDate: sd, endDate: ed });
    setEoyDateRange({ startDate: sd, endDate: eoy });
  }, [startingMonth]);
  /*
   * Determine the currency to use for display of values
   */
  useEffect(() => {
    // Determine the currency to use
    let [...idsToUse] = accountsById.keys();
    if (budget.filterAccountIds && budget.filterAccountIds.length > 0) {
      idsToUse = budget.filterAccountIds.map((x) => x.id);
    }

    setCurrency(budget.currency || getAccountsSharedCurrency(idsToUse));
    setAccountIds(List(idsToUse));

  }, [budget, accountsById]);  // will run if budget or accounts change
  /*
   * Determine dialog title (editing or creating?)
   */
  useEffect(() => {
    // If once budget items are loaded, the size is zero, set isCreating to persist for the whole session
    // we check for startDate, because until the range is set, there are no budget items
    if (!budgetItemsLoadPending && dateRange.startDate && !dialogTitle) {
      if ((isMacDataset && budgetItems.size > 0) || (!isMacDataset && (budgetItems.size > categoryGroupsById.size))) {
        setDialogTitle(`Edit ${moment(startDate).year()} Budget for ` +
          `${moment.months(startingMonth - 1)}${startingMonth !== 12 ? ' through December' : ''}`);
      } else {
        setDialogTitle(`Create a Budget for ${moment(startDate).year()}`);
      }
    }
  }, [budgetItemsLoadPending, budgetItems, dateRange, startDate, startingMonth]); // will only run once
  /*
   * Determine currently budgeted coas for filtering
   */
  useEffect(() => {
    // determine list of coa items from category list that are already budgeted
    let exclusionSet = new Set();
    budgetItems.forEach((bItem) => {
      if (bItem.coa) {
        const isParent = false; // categoriesById.find((cat) => cat.parentId === bItem.coa.id);
        if (!isParent) exclusionSet = exclusionSet.add(cacheableObject(bItem.coa));
      }
    });
    setCatsToExclude(exclusionSet);

  }, [budgetItems]);

  /*
   * Ensure there is a Misc category for top level category groups
   * ONLY for empty budgets
   */
  useEffect(() => {

    // console.log("CREATING DEFAULT BUDGET NODES", categoryGroupsById.toJS());
    //
    // NOT SUPPORTED BY MAC
    //
    if (!isMacDataset && budgetTreeNodes && budgetItems && !budgetItemsLoadPending && budgetTreeNodes.size === 0) {
      categoryGroupsById.forEach((catGroup) => {
        if (catGroup.name.toLowerCase().includes('personal')) {  // only create misc sections for personal groups
          if (catGroup.coas && catGroup.coas.length > 0) {
            const budgetItem = {
              amount: 0,
              coa: null,
              groupId: catGroup.id,
              budgetItemType: 'GROUP',
            };
            // if this item does not already exist, create it
            if (!budgetItems.find((x) => (x.groupId === budgetItem.groupId) && x.type === 'GROUP')) {
              addCategory(budgetItem);
            }
          }
        }
      });
    }
  }, [budgetTreeNodes, budgetItems, isMacDataset]);


  // STYLES =========================================================================
  const classes = useStyles(props);

  // INTERNAL FUNCTIONS =============================================================
  /*
   * Add Category, called when user selects a category and amount to add to the budget
   * If the category has children budgeted, then we have to create an OTHER value to
   * balance out the L1 value and the children's totals.  We also spread the budget to the end of the year
   */
  const addCategoryItems = (items) => {
    items.forEach((item) => {
      addCategory(item);
    });
    setShowSuggestedCategories(false);
  };

  const addCategory = (itemInfo) => {

    const groupId = itemInfo.groupId || findGroupIdForCategoryId(categoryGroupsById, itemInfo.coa.id);
    setBudgetItemsAddedCount(budgetItemsAddedCount + 1);
    const itemsToAdd = createNewBudgetItems(budget, groupId, dateRange, itemInfo);

    tracker.track(tracker.events.addedNewBudgetCategory, { number: itemsToAdd.size });

    if (itemsToAdd.size > 1) {
      dispatchCreateBudgetItems(itemsToAdd);
    } else {
      dispatchCreateBudgetItem(itemsToAdd.first());
    }
    setShowAddCategory(false);
  };

  const getMyItems = (refItem) =>
    budgetItemsToEndOfYear.filter((x) =>
      (x.groupId === refItem.groupId) && (x.type === refItem.type) && chartOfAccountsUtils.coasAreEqual(x.coa, refItem.coa)).map((x) =>
      x.set('amount', refItem.amount)).toList();

  const onChange = (changedItem) => {
    // find all other items that need to change
    //
    tracker.track(tracker.events.editedBudgetAmount);
    dispatchUpdateBudgetItems(getMyItems(changedItem));
  };
  const onDelete = (itemToDelete) => {
    setBudgetItemsAddedCount(budgetItemsAddedCount - 1);
    tracker.track(tracker.events.removedBudgetCategory);
    dispatchDeleteBudgetItems(getMyItems(itemToDelete));
  };

  const uniqueBudgetItems = () => {
    // Does not include the group level Misc categories that have no values
    const budgetItemsFiltered = budgetItems.filter((x) => x.type !== 'GROUP' || x.amount !== 0);
    const budgetsGrouped = budgetItemsFiltered.groupBy((x) => `${x.groupId}-${x.type}-${JSON.stringify(x.coa)}`);
    return budgetsGrouped.size;
  };

  const internalOnClose = () => {

    const numItems = uniqueBudgetItems();
    // If there are no relevant budget items, we delete the budget
    if (numItems === 0) {
      // Delete the budget
      if (budget) dispatchDeleteBudget(budget.set('isDeleted', true));
    }

    return onClose(uniqueBudgetItems());
  };

  // console.log("BUDGET TREE NODES ", budgetTreeNodes.toJS());
  // RENDER =========================================================================
  return (
    <>
      {qCards &&
        <QCard
          open={qCardsOn && !showAddCategory && !showSuggestedCategories}
          onClose={() => setQCardsOn(false)}
          cards={qCards}
          name="WFBudgetSetup"
          initialCard="firstCard"
          classes={{ qcard: classes.qCard }}
        />}

      <Dialog
        maxWidth="xs"
        open
        onClose={internalOnClose}
        classes={{ paperWidthXs: classes.dialogRoot }}
        disableEscapeKeyDown
      >
        {showAddCategory &&
          <BudgetAddCategory
            onAdd={addCategory}
            onClose={() => setShowAddCategory(false)}
            accountIds={accountIds}
            exclusionSet={catsToExclude}
            currency={currency}
            categoryIds={categoryIds}
          />}
        {showSuggestedCategories &&
          <BudgetSuggestedCategories
            onAddItems={addCategoryItems}
            onClose={() => setShowSuggestedCategories(false)}
            accountIds={accountIds}
            exclusionSet={catsToExclude}
            currency={currency}
          />}
        <TitleHeader
          classes={classes}
          dialogTitle={dialogTitle || ''}
          create={!budgetItems || budgetItems.size === (isMacDataset ? 0 : 2)}
          isMacDataset={isMacDataset}
        />
        <BudgetSummary
          classes={classes}
          budgetItems={budgetItems}
          currency={currency}
        />
        <AddCategoryBlock
          classes={classes}
          onAddCategory={() => setShowAddCategory(true)}
          onSuggestedCategories={() => {
            tracker.track(tracker.events.openBudgetSuggestion);
            setShowSuggestedCategories(true);
          }}
        />
        <EditBudgetCategoryGroups
          budgetItems={budgetItems}
          budgetTreeNodes={budgetTreeNodes}
          startDate={dateRange.startDate}
          currency={currency}
          onChange={onChange}
          onDelete={onDelete}
          accountIds={accountIds}
        />

        <DialogActions>
          <QButton onClick={internalOnClose} id="create-budget-done-button">
            Done
          </QButton>
        </DialogActions>

        <QCloseBox onClose={internalOnClose} id="create-budget-close-button" />
      </Dialog>
    </>
  );
};

EditBudgetsDialog.propTypes = propTypes;
export default EditBudgetsDialog;

/*
 *************** HELPER FUNCTIONS ******************
 */

