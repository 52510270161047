import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const TableHeader = (props) => (
  <TableHead>
    <TableRow>
      {Object.keys(props.columns).map((key) => {
        const column = props.columns[key];
        return (
          <TableCell key={key} className={classNames(props.classes.resetCell, props.classes?.[key])}>
            <div className={props.classes?.[`${key}Container`]}>{column.label}</div>
          </TableCell>
        );
      })}
    </TableRow>
  </TableHead>
);

TableHeader.whyDidYouRender = true;

TableHeader.propTypes = {
  columns: PropTypes.object,
  classes: PropTypes.object,
};

export default React.memo(TableHeader);
