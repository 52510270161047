import { DateTime } from 'luxon';

export const generateRolloverBalanceCalculationTable = (monthlyDetails) => {
  const selectedDate = DateTime.fromISO(monthlyDetails?.objMonthlyAmounts?.startDate);
  const budgetItemOfSelectedDate = monthlyDetails?.budgetItem?.budgetItems?.find((budgetItemIterated) => 
    DateTime.fromISO(budgetItemIterated.startDate).month === selectedDate.month);
  const isIncome = monthlyDetails?.budgetItem?.negate > 0;
  const rolloverAmount = (budgetItemOfSelectedDate?.rolloverOverrideAmount || budgetItemOfSelectedDate?.calculatedRolloverAmount || 0) * -1;
  return [{
    key: 'Rollover',
    keyName: 'rolloverAmount',
    datedOn: 'Last Month',
    operation: undefined,
    value: rolloverAmount,
    edit: true,
  },
  {
    key: 'Budget',
    keyName: 'budgetAmount',
    datedOn: 'This Month',
    operation: isIncome ? '-' : '+',
    value: monthlyDetails?.objMonthlyAmounts?.budgetAmount,
    edit: false,
  },
  {
    key: 'Actual',
    keyName: 'actualAmount',
    datedOn: 'This Month',
    operation: isIncome ? '+' : '-',
    value: monthlyDetails?.objMonthlyAmounts?.actualAmount,
    edit: false,
  },
  {
    key: 'Balance',
    keyName: 'balanceAmount',
    datedOn: undefined,
    operation: '=',
    value: monthlyDetails?.objMonthlyAmounts?.balanceAmount,
    edit: false,
  }];
};
