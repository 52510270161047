
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { scheduledTransactionsUtils } from '@quicken-com/react.flux.scheduled-transactions';

import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 23,
    borderRadius: 3,
  },
  icon: {
    marginRight: -6,
  },
  input: {
    ...theme.typography.caption,
    background: 'none',
    width: 'fit-content',
    height: '100%',
    padding: '0 6px !important',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '3px !important',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.1),
    },
    '&:focus': {
      backgroundColor: theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.1),
    },
  },
}));


export default function RecurrenceSelect(props) {
  const classes = useStyles();
  const { scheduledTxn, onChange, className, inputClass, iconComponent } = props;

  const initialFrequency = scheduledTxn && scheduledTransactionsUtils.frequencyAliasFromRecurrence(scheduledTxn.recurrence);
  const [recurrence, setRecurrence] = useState(initialFrequency);

  // frequency select menu population
  const { custom } = scheduledTransactionsUtils.frequencyAliasTypes;
  const hasCustom = initialFrequency === custom;
  const menuItems = scheduledTransactionsUtils.frequencyAliases.map((alias) => <MenuItem key={alias} value={alias}>{alias}</MenuItem>);
  // only show custom option if billScout defaulted to that
  if (hasCustom) menuItems.push(<MenuItem key={custom} value={custom}>{custom}</MenuItem>);

  const handleChange = (e) => {
    setRecurrence(e.target.value);
    if (onChange) {
      onChange(e.target.value === custom ?
        scheduledTxn.recurrence
        :
        scheduledTransactionsUtils.recurrenceFromFrequencyAlias(e.target.value, scheduledTxn.dueOn));
    }
  };

  return (
    <Select
      variant="standard"
      value={recurrence}
      onChange={handleChange}
      inputProps={{
        id: 'found_recurring_frequency',
        className: classNames(classes.input, inputClass),
        classes: {
          icon: classes.icon,
        },
      }}
      IconComponent={iconComponent || 'div'}
      disableUnderline
      className={classNames(classes.root, className)}
    >
      {menuItems}
    </Select>
  );
}

RecurrenceSelect.propTypes = {
  scheduledTxn: PropTypes.object,
  onChange: PropTypes.func,
  className: PropTypes.string,
  inputClass: PropTypes.string,
  iconComponent: PropTypes.any,
};
