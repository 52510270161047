import { List, Map as ImmutableMap, Record } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';

import type { QcsSyncResourceStoreProps } from '@quicken-com/react.flux.core/resourceStoreTypes';


export type FI_AGGREGATOR = 'FDS' | 'FDP' | 'FINICITY' | 'PLAID';

type InstitutionFormFieldProps = {
  key: string,
  name: string,
  type: string,

  displayOrder: number,
  displayLabel: string,
  helpText: ?string,
  maxLength: ?number,
  encrypt: ?boolean,
};
export type InstitutionFormField = RecordOf<InstitutionFormFieldProps>;
export const mkInstitutionFormField: RecordFactory<InstitutionFormFieldProps> =
  Record({
    key: 'unknown',
    name: 'unknown',
    type: 'unknown',
    displayOrder: 0,
    displayLabel: 'unknown',
    helpText: null,
    maxLength: null,
    encrypt: undefined,
  });

type InstitutionPartnerAuthProps = {
  intuitProperty: string,
  offeringRedirectUri: string,
  partnerAuthUrl: string,
  partnerUid: string,
  fields: List<InstitutionFormField>,
}
export type InstitutionPartnerAuth = RecordOf<InstitutionPartnerAuthProps>;
export const mkInstitutionPartnerAuth: RecordFactory<InstitutionPartnerAuthProps> =
  Record({
    intuitProperty: 'unknown',
    offeringRedirectUri: 'unknown',
    partnerAuthUrl: 'unknown',
    partnerUid: 'unknown',
    fields: List(),
  });

type InstitutionFormEncryptionProps = {
  "keyId": ?string,
  "expiresAt": ?string,
  "algorithm": ?string,
  "publicKey": ?string,
  "signature": ?string,
  "signatureAlgorithm": ?string,
};
export type InstitutionFormEncryption = RecordOf<InstitutionFormEncryptionProps>;
export const mkInstitutionFormEncryption: RecordFactory<InstitutionFormEncryptionProps> =
  Record({
    keyId: undefined,
    expiresAt: undefined,
    algorithm: undefined,
    publicKey: undefined,
    signature: undefined,
    signatureAlgorithm: undefined,
  });

type InstitutionChannelDataProps = {
  type: ?string,
  authType: ?string,
  formFields: List<InstitutionFormField>,
  formEncryption: InstitutionFormEncryption,
  formSignature: ?string,
  formSignatureAlgorithm: ?string,
  partnerAuth: ?any,
}
export type InstitutionChannelData = RecordOf<InstitutionChannelDataProps>;
export const mkInstitutionChannelData: RecordFactory<InstitutionChannelDataProps> =
  Record({
    type: undefined,
    authType: undefined,
    formFields: undefined,
    formEncryption: undefined,
    formSignature: undefined,
    formSignatureAlgorithm: undefined,
    partnerAuth: undefined,
  });

type InstitutionOfxDataProps = {
  bid: number,
  pid: number,
  name: string,
  contact_url: string,
  contact_phone: string,
  registraction_url: string,
}
export type InstitutionOfxData = RecordOf<InstitutionOfxDataProps>;
export const mkInstitutionOfxData: RecordFactory<InstitutionOfxDataProps> =
  Record({
    bid: 0,
    pid: 0,
    name: 'unknown',
    contact_url: 'unknown',
    contact_phone: 'unknown',
    registraction_url: 'unknown',
  });

type InstitutionFdsDataProps = {
  id: string,
  name: string,

  contact_url: string,
  contact_phone: string,
  contact_email: string,

  login_url: string,

  channelData: InstitutionChannelData,
};
export type InstitutionFdsData = RecordOf<InstitutionFdsDataProps>;
export const mkInstitutionFdsData: RecordFactory<InstitutionFdsDataProps> =
  Record({
    id: '0',
    name: 'unknown',

    contact_url: 'unknown',
    contact_phone: 'unknown',
    contact_email: 'unknown',

    login_url: 'unknown',

    channelData: undefined,
  });

type InstitutionFdpDataProps = {
  id: string,
  name: string,

  contact_url: string,
  contact_phone: string,
  contact_email: string,

  login_url: string,

  channelData: InstitutionChannelData,
  channelDataByType: ImmutableMap,
};
export type InstitutionFdpData = RecordOf<InstitutionFdpDataProps>;
export const mkInstitutionFdpData: RecordFactory<InstitutionFdpDataProps> =
  Record({
    id: undefined,
    name: undefined,

    contact_url: undefined,
    contact_phone: undefined,
    contact_email: undefined,

    login_url: undefined,

    channelData: undefined,
    channelDataByType: undefined,
  });

type InstitutionPlaidDataProps = {
  id: ?string,
  name: ?string,
};
export type InstitutionPlaidData = RecordOf<InstitutionPlaidDataProps>;
export const mkInstitutionPlaidData: RecordFactory<InstitutionPlaidDataProps> =
  Record({
    id: null,
    name: null,
  });


type InstitutionProps = {
  id: string,
  name: string,
  url: string,

  logo: ?string,
  logoUrl: ?string,
  logoStyle: 'BUTTON' | 'ICON',

  aggregator: string,
  ofx: InstitutionOfxData,
  fds: InstitutionFdsData,
  fdp: InstitutionFdpData,
  plaid: InstitutionPlaidData,
}
export type Institution = RecordOf<InstitutionProps>;
export const mkInstitution: RecordFactory<InstitutionProps> =
  Record({
    id: '0',
    name: 'unknown',
    url: null,

    logo: undefined,
    logoUrl: undefined,
    logoStyle: undefined,

    aggregator: undefined,
    ofx: mkInstitutionOfxData(),
    fds: mkInstitutionFdsData(),
    fdp: mkInstitutionFdpData(),
    plaid: mkInstitutionPlaidData(),
  });

type InstitutionStoreProps = QcsSyncResourceStoreProps & {
  suggested: List
}
export type InstitutionsStore = RecordOf<InstitutionStoreProps>;
export const mkInstitutionsStore: RecordFactory<InstitutionStoreProps> =
  Record({
    resourcesById: ImmutableMap(),
    lastSyncDate: null,

    loadPending: true,
    isLoading: false,

    popularIds: null,
  });



