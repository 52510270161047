// @flow
import React from 'react';
import { connect } from 'react-redux';

import { getLogger } from '@quicken-com/react.utils.core';
import { datasetsSelectors } from '@quicken-com/react.flux.datasets';
import { profileSelectors } from '@quicken-com/react.flux.profile';
import { authSelectors } from '@quicken-com/react.flux.auth';
import { accountsSelectors } from '@quicken-com/react.flux.accounts';
import { scheduledTransactionsSelectors } from '@quicken-com/react.flux.scheduled-transactions';

import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';
import { getActiveSubscription } from 'data/subscriptions/subscriptionsSelectors';
import { getTopTierEntitlement } from 'data/entitlements/entitlementsSelectors';
import { getBudgets } from 'data/budgets/budgetsSelectors';

const log = getLogger('...App/UsersnapLoader');

type Props = {
  enableUsersnap: boolean,
  customData: Object,
};

const USERSNAP_GLOBAL_API_KEY = '5ad0ae09-dac6-4ee2-acff-16810b16828e';

class UsersnapLoader extends React.PureComponent<Props, null> {

  // Was trying to configure usersnap to handle error conditions. However, this is not working. Need to work
  // with Usersnap to figure out how to do this.
  //
  // usersnapConfig = {
  //   loadHandler: () => {
  //     log.info('Usersnap Load Handler Called');
  //     UserSnap.on('error', (errorMessage, errorCode) => {
  //       log.info(`Error Code: ${errorCode}`);
  //       log.info(`Error Code: ${errorMessage}`);
  //     });
  //   },
  // };

  static defaultProps = {
    enableUsersnap: false,
  };

  // this should only be loaded once and cannot be unloaded (unless a browser refresh occurs)
  static usersnapScriptIsLoaded = false;

  componentDidMount() {
    if (this.props.enableUsersnap && !UsersnapLoader.usersnapScriptIsLoaded) {
      this.loadUsersnapScript();
    }
  }

  componentDidUpdate() {
    if (this.props.enableUsersnap && !UsersnapLoader.usersnapScriptIsLoaded) {
      this.loadUsersnapScript();
    }
  }

  loadUsersnapScript = () => {
    UsersnapLoader.usersnapScriptIsLoaded = true;

    // Was trying to configure usersnap to handle error conditions. However, this is not working. Need to work
    // with Usersnap to figure out how to do this.
    //
    // window._usersnapconfig = this.usersnapConfig;  // eslint-disable-line no-underscore-dangle

    // after usersnap loads, this will be called
    window.onUsersnapLoad = this.handleUsersnapLoad;

    const configScript = document.createElement('script');
    configScript.text = 'var _usersnapconfig = {};';
    configScript.type = 'text/javascript';

    const script = document.createElement('script');

    script.src = `https://widget.usersnap.com/global/load/${USERSNAP_GLOBAL_API_KEY}?onload=onUsersnapLoad`;

    // script.src = '//api.usersnap.com/load/02c6e8fe-3cc9-4fa9-9cb4-2fcd7acc4d11.js?onload=onUsersnapLoad';

    script.type = 'text/javascript';
    script.async = true;
    script.onerror = this.handleUsersnapLoadError;

    const scriptElement = document.getElementsByTagName('script')[0];
    if (scriptElement != null && scriptElement.parentNode != null) {
      scriptElement.parentNode.insertBefore(script, scriptElement);
    }
  };

  handleUsersnapLoad = (api) => {
    api.init({
      fields: {
        addScreenshot: {
          defaultValue: false,
        },
      },
    });

    api.on('open', (event) => {
      event.api.setValue('custom', this.props.customData);
    });
  };

  handleUsersnapLoadError = (error) => {
    log.error('Failed to load Usersnap', error);
  };

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  const profile = profileSelectors.getProfile(state);
  const authSession = authSelectors.getAuthSession(state);
  const datasets = datasetsSelectors.getDatasets(state);
  const activeSubscription = getActiveSubscription(state);
  const topTierEntitlement = getTopTierEntitlement(state);
  const accounts = accountsSelectors.getAccountsById(state);
  const scheduledTransactions = scheduledTransactionsSelectors.getScheduledTransactions(state);
  const budgets = getBudgets(state);

  const customData = {
    qcs_id: profile?.userId || 'unknown',
    dataset_id: authSession?.datasetId,
    dataset_platform: authSession?.datasetPlatform,
    dataset_created_by: authSession?.datasetCreatedByClientId,
    dataset_count: datasets?.size,
    subscription: `${activeSubscription?.productLineName} - ${activeSubscription?.tierName} (${activeSubscription?.expireOn})`,
    entitlement: `${topTierEntitlement?.id} (${topTierEntitlement?.expiresOn})`,
    account_count: accounts.size,
    scheduledTransactions_count: scheduledTransactions?.size,
    budget_count: budgets?.size,
    pathname: window.location.pathname,
  };

  return {
    enableUsersnap: featureFlagsSelectors.getFeatureFlags(state).get('usersnap'),
    customData,
  };
}

UsersnapLoader.whyDidYouRender = false;

export default connect(mapStateToProps, null)(UsersnapLoader);

