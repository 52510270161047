import { applyOpacityToHex } from 'themes/themeUtils';

// TODO: replace with Quicken palette
// colorScale - our pre-defined palette
// - should never be exported or accessed directly outside the theme file
// - never use any colors that not included in the palette
// - colors should be referenced by theme usage based sections
const colorScale = Object.freeze({
  color1: { // blueberry
    opacity100: '#496ED8',
    opacity70: '#89A0E6',
    opacity50: '#B7C4F0',
    opacity30: '#DAE2F7',
  },
  color2: { // aqua
    opacity100: '#5EBFEA',
    opacity70: '#96D6F1',
    opacity50: '#BBE4F6',
    opacity30: '#D3EDF9',
  },
  color3: { // wintergreen
    opacity100: '#91E5C5',
    opacity70: '#B7EED9',
    opacity50: '#D0F4E6',
    opacity30: '#E0F8EF',
  },
  color4: { // clementine
    opacity100: '#F2866D',
    opacity70: '#F5AA98',
    opacity50: '#F8C2B6',
    opacity30: '#FBDAD3',
  },
  color5: { // gold
    opacity100: '#EAC563',
    opacity70: '#F1D89A',
    opacity50: '#F6E6BD',
    opacity30: '#F9EFD4',
  },
  color6: { // orchid
    opacity100: '#9882C4',
    opacity70: '#BCAED9',
    opacity50: '#D3CAE6',
    opacity30: '#E2DDEF',
  },
  color7: { // sky
    opacity100: '#C2DDF6',
    opacity70: '#D7E8FA',
    opacity50: '#E5F0FC',
    opacity30: '#EEF5FD',
  },
  color8: { // ocean
    opacity100: '#255C99',
    opacity70: '#688EB4',
    opacity50: '#9CB3CD',
    opacity30: '#C4D4E0',
  },
  color9: { // jadeite
    opacity100: '#62B59E',
    opacity70: '#99CFC0',
    opacity50: '#BDE0D6',
    opacity30: '#D4EBE4',
  },
  grey: {
    level7: '#333333', // cool7
    level6: '#687982', // cool6
    level5: '#81929B', // cool5
    level4: '#A1AFB5', // cool4
    level3: '#CBD2D8', // cool3
    level2: '#EBEFF2', // cool2
    level1: '#F6F7F8', // cool1
    level0: '#FFFFFF', // cool0
  },
  others: {
    color1: '#3653D5',
    color2: '#2D46B5',
    color3: '#2BAB83',
    color4: '#C45353',
  },
});

const oceanBlue = '#00b045';
const pastelRed = '#da6561';

const black = '#000000';
const shadowGrey = '#3E3E3C';
const slateGrey = '#706E6B';
const koalaGrey = '#969492';
const cloudyGrey = '#C9C7C5';
const silverGrey = '#ecebea';
const shinyGrey = '#fafaf9';
const white = '#FFFFFF';

export const greyScale = [
  black,        // 0
  shadowGrey,   // 1
  slateGrey,    // 2
  koalaGrey,    // 3
  cloudyGrey,   // 4
  silverGrey,   // 5
  shinyGrey,    // 6
  white,        // 7
];

const colors = {
  primary: {
    light: '#1f93db',
    main: '#1f6bb1',
    dark: '#1a4985',
  },
  secondary: {
    light: '#74b8ff',
    main: '#2989ff',
    dark: '#004eae',
  },
  error: {
    main: '#eaa111',
  },
  text: {
    primary: '#3e3e3c',
    secondary: '#706e6b',
    disabled: '#c9c7c5',
    lightContrast: greyScale[7],
  },
  legend: {
    income: colorScale.others.color3, // legend color assigned to income recurrences
    bill: colorScale.others.color1, // legend color assigned to bill recurrences
    // refund: colorScale.color4.opacity100, // legend color assigned to refund
  },
  other: {
    quickTips: '#ef7d5b',
    overdueTxn: '#f29d50',
    dueTxn: '#1f599e',
  },
};

// -- THIS EXPORT SHOULD NOT BE USED EXCEPT IN 'themeBase.js' -- //
export const theme = {
  project: 'Quicken',
  palette: {
    colorScaleShouldNeverBeAccessedDirectly: colorScale,
    ...colors,
    ...colorScale,
    greyScaleDeprecated: greyScale, // TODO: remove from theme once all code references removed
    background: {
      default: '#f7f7f7',
      primary: '#f7f7f7',
    },
    border: {
      primary: colorScale.color1.opacity50,
    },
    link: {
      main: colorScale.others.color1,
      dark: colorScale.others.color2,
    },
    number: {
      positive: colorScale.others.color3,
      negative: colorScale.others.color4,
    },
    others: {
      linkDark: '#2D46B5',
      positive: '#2BAB83',
      negative: '#C45353',
      lightText: '#707070',
      darkText: '#333333',
      link: '#2989ff',
      titleFaded: '#9F9F9F',
    },
    data: {
      sequence1: ['#5cb3ea', '#ffee00', '#5ac402', '#ff8000', '#7d6fc4', '#ff3e7a', '#79d5e0', '#9b91c5', '#08a2b5', '#f1635f'],
    },
    black,
    white,
    mercuryGrey: '#E6E6E6',
  },
  typography: {
    caption: {
      color: greyScale[1],
    },
    h6: {
      color: greyScale[0],
    },
  },
  defaults: {},
  // override specific component styles for Quicken theme, colors should be referenced from the 'colors' object above,
  //    ----- ONLY in special cases should there be hex codes below here -----
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: colors.secondary.light,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:after': {
            borderBottomColor: colors.secondary.light,
          },
        },
      },
    },

    appBar: {
      color: colors.text.primary,
      borderRadius: 0,
      opacity: 1,
      borderBottom: `1px solid ${greyScale[4]}`,
      boxShadow: 'none',
      background: greyScale[7],
      zIndex: 1,
      height: 70,
      position: 'sticky',
      display: 'flex',
    },
    upcoming: {
      iconColor: greyScale[7],
      pastIconColor: greyScale[1],
    },
    featureHeader: {
      titleVariant: 'h6',
      subTitleVariant: 'subtitle1',
    },
    toolBar: {
      height: 70,
      paddingLeft: 32,
      paddingRight: 32,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    register: {
      transferDelimeter1: '[',
      transferDelimeter2: ']',
      fieldMargin: 8,

      pendingTextColor: '#4a90e2',
      pendingRowColor: '#f5faff',

    },
    accountCard: {
      background: 'inherit',
    },
    accountDrawer: {
      // -- Sizing -- //
      width: 306,
      accountListBaseFontSize: 14.5,
      drawerListTitleFontSize: '17px',

      basePadding: 16,
      listPaddingLeft: 16,
      listPaddingRight: 28,

      accountListLevelIndent: 4,
      iconSize: 20,

      // -- Text colors -- //
      // positiveBalance: null,
      highlightText: greyScale[1], // total + net-worth
      textColor: greyScale[1], // banking + investments
      headerListText: greyScale[1], // accounts bar
      l2TextColor: applyOpacityToHex(greyScale[1], 0.93),
      l3TextColor: applyOpacityToHex(greyScale[1], 0.88),
      negativeBalance: colorScale.others.color4,

      // -- Background colors -- //
      background: greyScale[5],
      highlightColor: greyScale[4], // selected account
      // hoverColor: null, // hover effect
      // l1Color: null, // banking + investments
      logoBackground: '#678fe81a',
      headerBackground: greyScale[5],

      // other colors
      borderColor: '#1e69ac', // right-side border
      divider: greyScale[3], // border of collapse button
      openControl: greyScale[7],  // background inside collapse button,
      icon: greyScale[3],  // icon inside collapse button,
      errorIcon: colorScale.others.color4,
      refreshIcon: greyScale[1], // color of refresh icon on click

      actionColor: greyScale[1], // add-FI button color
      actionButtonSize: 40,

      // row sizing
      l1Margin: 0,
      l0Height: 37,
      l1Height: 52,
      l2Height: 34,
      l3Height: 32,
    },
    navigation: {
      headerColor: '#1F74BA',
      barWidthOpen: 206,
      rowOpacity: 1,
      selectedBackground: applyOpacityToHex(greyScale[0], 0.3),
      hoverBackground: applyOpacityToHex(greyScale[0], 0.2),
      iconColor: '#d5e6f1',
      selectedIconColor: '#d5e6f1',
      iconHoverColor: '#d5e6f1',
      textColor: '#d5e6f1',
      selectedTextColor: '#d5e6f1',
    },
    spendingOverTime: {
      positiveDefault: oceanBlue,
      positiveDimmed: '#C3E7C8',
      negativeDefault: '#cc6c65',
      negativeDimmed: '#F4D2D0',
    },
    incomeVersusSpending: {
      spendingPositiveDefault: pastelRed,
      spendingPositiveDimmed: applyOpacityToHex(pastelRed, 0.3),
      spendingNegativeDefault: '#cc6c65',
      spendingNegativeDimmed: applyOpacityToHex('#cc6c65', 0.3),
      incomePositiveDefault: oceanBlue,
      incomePositiveDimmed: applyOpacityToHex(oceanBlue, 0.3),
      incomeNegativeDefault: oceanBlue,
      incomeNegativeDimmed: applyOpacityToHex(oceanBlue, 0.3),
    },
    netIncome: {
      positiveDefault: oceanBlue,
      positiveDimmed: '#C3E7C8',
      negativeDefault: pastelRed,
      negativeDimmed: '#F4D2D0',
    },
    income: {
      positiveDefault: oceanBlue,
      positiveDimmed: '#C3E7C8',
      negativeDefault: pastelRed,
      negativeDimmed: '#F4D2D0',
    },
    recentTransactions: {
      hoverBackground: applyOpacityToHex('#2989ff', 0.08),
    },
    qCards: {
      backgroundColor: '#1d53b5',
      titleColor: '#fff486',
      contentColor: '#FFF',
      closeColor: '#FFF',
      highlightColor: '#84def3',
      buttonTextColor: '#fff486',
    },
    categoryCard: {
      barChartIconColor: '#62B59E',
      tabBackgroundSelected: 'white',
      tabBackground: applyOpacityToHex('#1F74BA', 0.8),
      tabTextColorSelected: greyScale[1],
      tabTextColor: 'white',
    },
    txns: {
      itemContainer: greyScale[5],
      smartSearch: greyScale[2],
      searchBox: greyScale[3],
    },
    accountDiscovery: {
      background: greyScale[7],
      ignore: '#e34242',
      linkToBackground: greyScale[2],
    },
    calendar: {
      currentDayBorder: colorScale.color1.opacity70,
      currentDayBackground: colorScale.color7.opacity30,
      incomeColor: colorScale.color9.opacity100,
      expenseColor: colorScale.color5.opacity100,
      overdueColor: colors.other.overdueTxn,
      normalColor: greyScale[3],
    },
    tooltip: {
      backgroundColor: colorScale.grey.level0,
      textColor: colorScale.others.color6,
      primaryTextColor: colorScale.grey.level7,
    },
    report: {
      headerTypographyColor: greyScale[2],
      backgroundColor: colorScale.grey.level0,
      positiveDefault: oceanBlue,
      negativeDefault: pastelRed,
      chartScrollGripIconColor: colorScale.grey.level5,
      chartScrollGripBackground: colorScale.grey.level4,
    },
    progressChart: {
      primaryColor: '#00B044',
      secondaryColor: 'rgba(0, 176, 68, 0.2)',
      fillColor: '#DFDFDF',
      dangerColor: '#EB1515',
      textLabel: white,
    },
    yearlyBudget: {
      buttonText: '#1263DD',
      inputBorder: '#B7B7B7',
    },
    barChart: {
      primaryColor: '#00B044',
      dangerColor: '#EB1515',
    },
    categoryDialog: {
      primary: '#F8F8F8',
    },
  },
};
