// @flow
import { Record } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';

// ================================================================================================
// Rename Rules STore
// ================================================================================================

type RuleProps = {
  id: string,
  clientId: string,
  isDeleted: ?boolean,
  renamePayeeFrom: string,
  renamePayeeTo: string,
  isDoNotRename: ?boolean,
  matchCriteria: string,
};
export type Rule = RecordOf<RuleProps>;

export const mkRenameRule: RecordFactory<RuleProps> =
  Record({
    id: undefined,
    clientId: undefined,
    isDeleted: undefined,
    renamePayeeFrom: undefined,
    renamePayeeTo: undefined,
    isDoNotRename: undefined,
    matchCriteria: 'If Payee Contains',
  });

