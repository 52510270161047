import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'utils/compose';
import { v4 as uuidv4 } from 'uuid';
import { List as ImmutableList, Map as ImmutableMap } from 'immutable';

import { tracker } from '@quicken-com/react.utils.core';
import { authSelectors } from '@quicken-com/react.flux.auth';
import { categoriesActions } from '@quicken-com/react.flux.categories';
import { scheduledTransactionsActions, scheduledTransactionsTypes, scheduledTransactionsSelectors } from '@quicken-com/react.flux.scheduled-transactions';

import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import withStdForm from 'data/StdForm';
import StdFormDialog from 'components/Dialogs/StdFormDialog';
import QButton from 'components/QButton';
import ScheduleTransactionSelector, { INCOME_STEP, BILLS_STEP } from 'components/Dialogs/WfBillScout/ScheduleTransactionSelector';
import { WFBillScoutStyles as styles } from 'components/Dialogs/WfBillScout/styles';
import { getAllUpcomingTransactions } from 'data/transactions/selectors';
import TransactionSelectList from 'components/TransactionSelectList';
import SearchBox from 'components/SearchBox';
import ScheduledTransactionFormContainer from 'components/ScheduledTransactions/ScheduledTransactionFormContainer';
import QDialogs from 'components/QDialogs';
import { getIdsRefreshing } from 'data/institutionLogins/selectors';
import { getLesserBilledTransactions } from 'data/transactionList/selectors';

const typeStdForm = 'WEBFIRST_BILL_SCOUT_WIZARD';
const INITIAL_STEP = BILLS_STEP;

const UI_SCHEDULE_TRANSACTION_SELECTOR = 'SCHEDULE_TRANSACTION_SELECTOR';
const UI_TRANSACTION_SELECTOR = 'TRANSACTION_SELECTOR';
const INITIAL_CURRENT_UI = UI_SCHEDULE_TRANSACTION_SELECTOR;

const INITIAL_SELECTED_ITEMS = ImmutableMap({
  [INCOME_STEP]: ImmutableList([]),
  [BILLS_STEP]: ImmutableList([]),
});

const titles = {
  [`${UI_SCHEDULE_TRANSACTION_SELECTOR}_${BILLS_STEP}`]: {
    headline: 'Let\'s start with your bills',
    caption: `Review these recurring payments we found in your transactions.
    Only select your recurring payments.`,
  },
  [`${UI_SCHEDULE_TRANSACTION_SELECTOR}_${BILLS_STEP}_review`]: {
    headline: 'Review your bills',
    caption: 'Review your bills. You can update or remove any if required',
  },
  [`${UI_TRANSACTION_SELECTOR}_${BILLS_STEP}`]: {
    headline: 'Find bills from your transactions',
    caption: 'Only choose transactions that represent recurring bills, subscriptions or transfers.',
  },
  [`${UI_SCHEDULE_TRANSACTION_SELECTOR}_${INCOME_STEP}`]: {
    headline: 'Now add your income',
    caption: `Review these recurring income we found in your transactions.
    Select & add all that are your income.`,
  },
  [`${UI_SCHEDULE_TRANSACTION_SELECTOR}_${INCOME_STEP}_review`]: {
    headline: 'Review your income',
    caption: 'Review your income. You can update or remove any if required',
  },
  [`${UI_TRANSACTION_SELECTOR}_${INCOME_STEP}`]: {
    headline: 'Find income from your transactions',
    caption: 'Only choose items that represent recurring income payments (not one-time payments).',
  },
};

const trackingEvents = {
  [`${BILLS_STEP}-confirmed`]: tracker.events.confirmedBill,
  [`${BILLS_STEP}-addedNew`]: tracker.events.addedNewBill,
  [`${BILLS_STEP}-removed`]: tracker.events.removedBill,
  [`${BILLS_STEP}-edited`]: tracker.events.editedBill,
  [`${BILLS_STEP}-skip`]: tracker.events.skipBill,
  [`${INCOME_STEP}-confirmed`]: tracker.events.confirmedIncome,
  [`${INCOME_STEP}-addedNew`]: tracker.events.addedNewIncome,
  [`${INCOME_STEP}-removed`]: tracker.events.removedIncome,
  [`${INCOME_STEP}-edited`]: tracker.events.editedIncome,
  [`${INCOME_STEP}-skip`]: tracker.events.skipIncome,
};

class WfBillScout extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      // Check if it's a web first dataset to initiate a current UI, CMP-7267.
      currentUI: props.isWebFirstDataset ? INITIAL_CURRENT_UI : UI_TRANSACTION_SELECTOR,
      step: this.props.billsIncomeFlow ? this.props.initialStep : INITIAL_STEP,
      reviewCurrentStep: false,
      selectedScheduleTxns: INITIAL_SELECTED_ITEMS,
      selectedTxns: INITIAL_SELECTED_ITEMS,
      search: undefined,
      editScheduledTransaction: undefined,
    };

    if (this.props.institutionsRefreshing.isEmpty()) {
      this.props.getScheduledTransactions();
    }

    props.getCategories();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.institutionsRefreshing.isEmpty() && this.props.institutionsRefreshing.isEmpty()) {
      this.props.getScheduledTransactions();
    }
  }

  setEditScheduledTransaction = (editScheduledTransaction) => {
    this.setState({
      editScheduledTransaction,
    });
  };

  setSelectedScheduleTxnsItems = (step, list) => {
    this.setState({
      selectedScheduleTxns: this.state.selectedScheduleTxns.set(step, list),
    });
  };

  setSelectedTxnsItems = (step, list) => {
    this.setState({
      selectedTxns: this.state.selectedTxns.set(step, list),
    });
  };

  openTransactionSelector = () => {
    tracker.track(tracker.events.findFromTransaction);
    this.setState({
      currentUI: UI_TRANSACTION_SELECTOR,
    });
  };

  closeTransactionSelector = (props) => (event) => {
    // Check if it's a web first dataset to handle transactions selector, CMP-7267.
    if (this.props.isWebFirstDataset) {
      this.setState({
        currentUI: UI_SCHEDULE_TRANSACTION_SELECTOR,
      });
    } else {
      props?.handleClose(event, 'SKIP_BUTTON');
    }
  };

  showNextStep = () => {
    const { step } = this.state;

    if (step === BILLS_STEP) {
      this.setState({
        step: INCOME_STEP,
        reviewCurrentStep: false,
      });
    }
  };

  getSchTxnsOfStep() {
    const { scheduledTransactions } = this.props;
    const { step } = this.state;

    return scheduledTransactions.filter((x) => x.type === step);
  }

  filterTxnsByType = ({ amount }) => this.state.step === INCOME_STEP ? amount > 0 : amount < 0;

  createAndDeleteSchTxns = (closeAction) => (event) => {
    const { selectedScheduleTxns, step, reviewCurrentStep } = this.state;
    const selectedSchTxns = selectedScheduleTxns.get(step);
    const schTxnOfStep = this.getSchTxnsOfStep();

    const schTxnToVerify = schTxnOfStep.filter((schTxn) => !schTxn.isUserVerified && selectedSchTxns.contains(schTxn.id));

    // Verify Schedule Transactions
    if (schTxnToVerify.size && schTxnToVerify.size > 0) {
      schTxnToVerify.forEach((schTxn) => {
        this.props.dispatchUpdateScheduledTransactionAction(schTxn.merge({
          isUserVerified: true,
        }));
      });
      tracker.track(trackingEvents[`${step}-confirmed`], { number: schTxnToVerify.size });
    }

    // Clean search for next step
    this.resetSearch();

    // Clean selectedScheduleTxnsItems
    this.setSelectedScheduleTxnsItems(step, INITIAL_SELECTED_ITEMS.get(step));

    // Show review page before show next step
    if (!reviewCurrentStep) {
      this.showReviewStep();
      return;
    }

    // Show next step or close modal
    if (step === BILLS_STEP) {
      this.showNextStep();
    } else if (closeAction) {
      closeAction(event);
    }
  };

  showReviewStep = (reviewCurrentStep = true) => this.setState({ reviewCurrentStep });

  deleteScheduleTransaction = (schTxn) => {
    tracker.track(trackingEvents[`${this.state.step}-removed`], { number: 1 });
    this.props.dispatchDeleteScheduledTransactionAction(schTxn.merge({
      isDeleted: true,
    }));
  };

  onSkip = (closeAction) => (event) => {
    const { step, reviewCurrentStep } = this.state;


    tracker.track(trackingEvents[`${step}-skip`]);

    if (!reviewCurrentStep) {
      this.showReviewStep();
      return;
    }

    // Show next step or close modal
    if (step === BILLS_STEP) {
      this.showNextStep();
    } else if (closeAction) {
      closeAction(event);
    }
  };

  onGoBack = () => {
    this.showReviewStep(false);
  };

  createBillScout = () => {
    const { step } = this.state;
    const selectedTransactions = this.getSelectedTxns();

    selectedTransactions.forEach((txnID) => {
      if (txnID) {
        const clientId = uuidv4().toUpperCase();
        this.props.dispatchCreateBillScoutScheduleAction({ clientId, id: txnID });
      }
    });

    // Clean selectedTxns
    this.setSelectedTxnsItems(step, INITIAL_SELECTED_ITEMS.get(step));

    this.closeTransactionSelector();
    this.showReviewStep();
  };

  createEmptyScheduleTransaction = () => {
    const clientId = uuidv4().toUpperCase();
    tracker.track(tracker.events.addManually);

    this.closeTransactionSelector();
    this.setEditScheduledTransaction(scheduledTransactionsTypes.mkScheduledTransaction({
      clientId,
      type: this.state.step,
    }));
  };

  selectTxn = (event) => {
    const { id } = JSON.parse(event.currentTarget.id);
    const isSelected = this.checkTxnIsSelected(id);
    let selectedTxns = this.getSelectedTxns();

    if (isSelected) {
      selectedTxns = selectedTxns.filterNot((x) => x === id);
    } else {
      selectedTxns = selectedTxns.push(id);
    }

    this.setSelectedTxnsItems(this.state.step, selectedTxns);
  };

  checkTxnIsSelected(id) {
    const selectedItems = this.getSelectedTxns(this.state.type);
    return selectedItems.findIndex((x) => x === id) >= 0;
  }

  getBaseId = (id = '') => `WFBillScout-${this.state.currentUI}-${this.state.step}${id !== '' ? `-${id}` : ''}`;

  getSelectedTxns() {
    return this.state.selectedTxns.get(this.state.step);
  }

  getSelectedSchTxns() {
    return this.state.selectedScheduleTxns.get(this.state.step);
  }

  saveSearch = (search) => this.setState({ search });

  resetSearch = () => this.setState({ search: undefined });

  submitScheduleTransactionForm = ({ id, isUserVerified }) => {
    const { step } = this.state;

    if (id) {
      tracker.track(trackingEvents[`${step}-edited`], { number: 1 });
    } else {
      tracker.track(trackingEvents[`${step}-addedNew`], { number: 1 });
    }

    if (!isUserVerified) {
      const selectedItems = this.getSelectedSchTxns();
      const isSelected = selectedItems.findIndex((x) => x === id) >= 0;

      if (!isSelected) {
        this.setSelectedScheduleTxnsItems(step, selectedItems.push(id));
      }
    }
  };

  renderTitle() {
    const { classes, billsIncomeFlow } = this.props;
    const { step, currentUI, reviewCurrentStep } = this.state;
    const titleKey = currentUI !== UI_TRANSACTION_SELECTOR ?
      `${currentUI}_${step}${reviewCurrentStep ? '_review' : ''}` : `${currentUI}_${step}`;
    const titleData = titles[titleKey] || {};

    if (billsIncomeFlow && currentUI === UI_SCHEDULE_TRANSACTION_SELECTOR && !reviewCurrentStep) {
      titleData.headline = step === INCOME_STEP ? 'Select your income' : 'Select the bills you pay';
    }

    return (
      <div className={classes.headline}>
        <div className={classes.headlineTitle}>{titleData.headline}</div>
        <div id={this.getBaseId('headline')} className={classes.headlineCaption}>{titleData.caption}</div>
      </div>
    );
  }

  renderActions = (props) => {
    const { classes, singleStep } = this.props;
    const { currentUI, reviewCurrentStep, step } = this.state;
    const selectedTransactions = this.getSelectedTxns();
    const selectedSchTransactions = this.getSelectedSchTxns();
    const stepSchTransactions = this.getSchTxnsOfStep().filter((x) => x.isUserVerified);
    const stepLabel = step === BILLS_STEP ? 'bills' : 'income';
    return (
      <div className={classes.actions}>
        {currentUI === UI_SCHEDULE_TRANSACTION_SELECTOR && singleStep && reviewCurrentStep &&
          <QButton
            className={classes.wideButton}
            variant="contained"
            onClick={(event) => props.handleClose(event, 'SKIP_BUTTON')}
            id={this.getBaseId('action-done-button')}
          >
            Done
          </QButton>}
        {currentUI === UI_SCHEDULE_TRANSACTION_SELECTOR && singleStep && !reviewCurrentStep &&
          <QButton
            className={classes.wideButton}
            variant="contained"
            onClick={() => this.showReviewStep()}
            id={this.getBaseId('action-review-button')}
          >
            Review
          </QButton>}
        {currentUI === UI_SCHEDULE_TRANSACTION_SELECTOR && !singleStep && (
          <>
            <div className={classes.helperLine}>
              {reviewCurrentStep ?
                `${stepSchTransactions.size} ${stepLabel} added` :
                `${selectedSchTransactions.size} ${stepLabel} selected`}
            </div>
            {!reviewCurrentStep && (
              <QButton
                className={classes.wideButton}
                classes={{ textButton: classes.skipButton }}
                onClick={this.onSkip((event) => props.handleClose(event, 'SKIP_BUTTON'))}
                id={this.getBaseId('action-skip-button')}
              >
                Skip suggestions
              </QButton>
            )}
            <QButton
              className={classes.wideButton}
              classes={{ disableOther: classes.continueButton }}
              variant="contained"
              onClick={this.createAndDeleteSchTxns((event) => props.handleClose(event, 'COMPLETE_FLOW'))}
              id={this.getBaseId('action-continue-button')}
              disabled={!reviewCurrentStep && selectedSchTransactions.size === 0}
            >
              {!reviewCurrentStep && 'Add to list'}
              {reviewCurrentStep && step === BILLS_STEP && 'Continue to Income'}
              {reviewCurrentStep && step === INCOME_STEP && 'Finish'}
            </QButton>
          </>
        )}
        {currentUI === UI_TRANSACTION_SELECTOR && (
          <>
            <QButton
              className={classNames(classes.wideButton, classes.backButton)}
              onClick={this.closeTransactionSelector(props)}
              id={this.getBaseId('action-back-button')}
            >
              Cancel
            </QButton>
            <QButton
              className={classes.wideButton}
              classes={{ disableOther: classes.continueButton }}
              disabled={selectedTransactions.size === 0}
              onClick={this.createBillScout}
              id={this.getBaseId('action-add-to-list-button')}
              variant="contained"
            >
              Add to List
            </QButton>
          </>
        )}
      </div>
    );
  };

  render() {
    const { classes, allUpcomingTxns, institutionsRefreshing, hideAddManually, dialogId } = this.props;
    const { step, reviewCurrentStep, currentUI, search, editScheduledTransaction } = this.state;
    return (
      <StdFormDialog
        className={classes.dialog}
        dialogProps={{
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
          fullWidth: true,
          maxWidth: 'lg',
          classes: { paperWidthMd: classes.dialog },
        }}
        showCloseButton={currentUI === UI_SCHEDULE_TRANSACTION_SELECTOR}
        title={this.renderTitle()}
        renderActions={this.renderActions}
        dialogId={dialogId}
      >
        <>
          {currentUI === UI_SCHEDULE_TRANSACTION_SELECTOR && (
            <Grid container className={classes.body}>
              <ScheduleTransactionSelector
                scheduledTransactions={this.getSchTxnsOfStep()}
                allUpcomingTxns={allUpcomingTxns}
                selected={this.getSelectedSchTxns()}
                setSelectedItems={this.setSelectedScheduleTxnsItems}
                setEditScheduledTransaction={this.setEditScheduledTransaction}
                deleteScheduleTransaction={this.deleteScheduleTransaction}
                openTransactionSelector={this.openTransactionSelector}
                createEmptyScheduleTransaction={this.createEmptyScheduleTransaction}
                showFindingRecurring={!institutionsRefreshing.isEmpty()}
                type={step}
                reviewCurrentStep={reviewCurrentStep}
                baseId={this.getBaseId}
                hideAddManually={hideAddManually}
                onGoBack={this.onGoBack}
              />
            </Grid>
          )}

          {currentUI === UI_TRANSACTION_SELECTOR && (
            <Grid container className={classNames(classes.body, classes.bodyTxnSelector)}>
              <div id={this.getBaseId('search')} className={classes.searchBarRoot}>
                <SearchBox
                  onSearch={this.saveSearch}
                  autoSearch={false}
                  autoFocus
                  collapsable={false}
                  initialValue={search}
                  placeholder="Search Transactions"
                  showUnderline
                  classes={{
                    root: classes.searchBar,
                    input: classes.searchInput,
                    searchIcon: classes.searchIcon,
                    cancelIcon: classes.cancelIcon,
                  }}
                />
              </div>
              <TransactionSelectList
                clickFunc={this.selectTxn}
                highlight={this.getSelectedTxns()}
                filterByType={this.filterTxnsByType}
                filter={search}
                onReset={this.resetSearch}
                multiSelect
                hideCategory
                transactionListSelector={getLesserBilledTransactions}
              />
            </Grid>
          )}
          {editScheduledTransaction &&
          <ScheduledTransactionFormContainer
            key={editScheduledTransaction ? (editScheduledTransaction.id || editScheduledTransaction.clientId) : 'noKey'}
            scheduledTransaction={editScheduledTransaction && editScheduledTransaction.toJS()}
            onClose={() => { this.setEditScheduledTransaction(null); }}
            onSubmit={this.submitScheduleTransactionForm}
            deleteUserVerificationFromPayload
            hideDeleteIcon
          />}
        </>
      </StdFormDialog>
    );
  }
}

WfBillScout.propTypes = {
  // from withStyles
  classes: PropTypes.object,
  dialogId: PropTypes.string,

  // QDialogs
  // dialogAlert: PropTypes.func,

  getCategories: PropTypes.func,
  getScheduledTransactions: PropTypes.func,

  // BillsIncomeFlow
  initialStep: PropTypes.string,
  singleStep: PropTypes.bool,
  billsIncomeFlow: PropTypes.bool,
  hideAddManually: PropTypes.bool,

  // from connect
  allUpcomingTxns: PropTypes.object,
  scheduledTransactions: PropTypes.object,
  institutionsRefreshing: PropTypes.object,

  // Check for web first dataset, CMP-7267
  isWebFirstDataset: PropTypes.bool,

  dispatchCreateBillScoutScheduleAction: PropTypes.func,
  dispatchUpdateScheduledTransactionAction: PropTypes.func,
  dispatchDeleteScheduledTransactionAction: PropTypes.func,
};

function mapStateToProps(state, _props) {

  const scheduledTransactions = scheduledTransactionsSelectors.getScheduledTransactionsSortedByPayee(state);
  const allUpcomingTxns = getAllUpcomingTransactions(state, scheduledTransactions);
  return {
    allUpcomingTxns,
    scheduledTransactions,
    institutionsRefreshing: getIdsRefreshing(state),
    isWebFirstDataset: authSelectors.getIsWebfirstDataset(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCategories: () => dispatch(categoriesActions.getCategories()),
    getScheduledTransactions: () => dispatch(scheduledTransactionsActions.getScheduledTransactions()),
    dispatchUpdateScheduledTransactionAction: (data) => dispatch(scheduledTransactionsActions.updateScheduledTransaction(data)),
    dispatchDeleteScheduledTransactionAction: (data) => dispatch(scheduledTransactionsActions.deleteScheduledTransaction(data, { undo: { userMessage: 'Schedule Transaction deleted.' } })),
    dispatchCreateBillScoutScheduleAction: (data) => dispatch(scheduledTransactionsActions.createBillScoutSchedule(data)),
  };
}

export default compose(
  withStdForm({ type: typeStdForm }),
  withStyles(styles),
  QDialogs(),
  connect(mapStateToProps, mapDispatchToProps),
)(WfBillScout);
