import { createSelector } from 'reselect';

import { scheduledTransactionsSelectors } from '@quicken-com/react.flux.scheduled-transactions';

import type { BillerAccountStore } from './types';
import { REDUCER_KEY } from './reducer';


export const getBillerAccountsStore = (state: any): BillerAccountStore => state[REDUCER_KEY];

export const getBillerAccountsById = (state: any) => getBillerAccountsStore(state).resourcesById;
export const getLoadPending = (state: any) => getBillerAccountsStore(state).loadPending;
export const getLastSyncDate = (state: any) => getBillerAccountsStore(state).lastSyncDate;
export const getIsLoading = (state: any) => getBillerAccountsStore(state).isLoading;

export const getBillerAccountForId = (state: any, id: string) => getBillerAccountsById(state).get(id);

export const getBillerAccountsForLoginId = (state: any, id: string) => getBillerAccountsById(state).filter((account) => account.billerLoginID === id);

export const getUnconnectedAccounts = createSelector(
  getBillerAccountsForLoginId,
  scheduledTransactionsSelectors.getScheduledTransactions,
  (currentAccounts, scheduledTxns) => currentAccounts.filter((account) =>
    !(scheduledTxns.find((model) => model.billPresentmentAccountId === account.id)))
);

