import * as sessionStorageEx from 'utils/sessionStorageEx';

import { localPreferences, getLogger } from '@quicken-com/react.utils.core';

const logger = getLogger('utils/tabAuthExchange');

if (!sessionStorageEx.getAuthSession()) { // Ask other tabs for session storage
  logger.log('request sessionStorage sync');
  localPreferences.setGetSessionStorage(Date.now());
}

if (window.addEventListener) {
  window.addEventListener('storage', onStorage, false);
} else {
  window.attachEvent('onstorage', onStorage);
}
function onStorage(_event) {
  const event = _event || window.event;    // for IE
  if (event?.newValue) {
    switch (event.key) {
      case localPreferences.keys.getSessionStorage: // trigger 'storage' event that contains current sessionStorage
        if (sessionStorageEx.getAuthSession()) {
          localPreferences.setSessionStorage(sessionStorage);
          localPreferences.removeSessionStorage(); // sessionStorage is in the event so remove it from localStorage
        }
        break;
      case localPreferences.keys.sessionStorage: //
        if (event.newValue === 'null') { // logout from another tab
          sessionStorageEx.clear();
          window.location.assign(window.location.href);
        } else if (!sessionStorageEx.getAuthSession() && event.newValue) { // copy sessionStorage from 'storage' event
          const data = JSON.parse(event.newValue);
          try {
            Object.keys(data).forEach((key) => sessionStorage.setItem(key, data[key]));
          } catch (e) {
            assert(false, e);
          }
          window.location.assign(window.location.href); // now when we have authSession copy in sessionStorege - re-init
        }
        break;
      default:
    }
  }
}
