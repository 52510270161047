// @flow
import { Record } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';

// ================================================================================================
// Rename MemorizedRules STore
// ================================================================================================

type MemorizedRuleProps = {
  id: string,
  clientId: ?string,
  isDeleted: ?boolean,
  payee: string,
  transactionType: string,
  memo: ?string,
  amount: ?string,
  isNotAutoCategorized: ?boolean,
  isNotEditableByTransactions: ?boolean,
  isExcludedFromCalendar: ?boolean,
  coa: any,
  tags: ?Array,
  split: any,

};
export type MemorizedRule = RecordOf<MemorizedRuleProps>;

export const mkMemorizedRule: RecordFactory<MemorizedRuleProps> =
  Record({
    id: undefined,
    clientId: undefined,
    isDeleted: undefined,
    payee: undefined,
    transactionType: undefined,
    memo: undefined,
    amount: undefined,
    isNotAutoCategorized: undefined,
    isNotEditableByTransactions: undefined,
    isExcludedFromCalendar: undefined,
    coa: undefined,
    tags: undefined,
    split: undefined,
  });

