import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'utils/compose';

import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import withStyles from '@mui/styles/withStyles';

import { isAcme } from 'isAcme';
import QButton from 'components/QButton';
import StdDialog from 'components/Dialogs/StdDialog';
import { removeDialog } from 'data/rootUi/actions';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';

import { DialogTimeout, styles } from './styles';

class AlertDialog extends React.PureComponent {

  handleClose = (btn) => {
    this.props.onClose?.({ btnPressed: btn });
    this.props.removeDialog(this.props.dialogId);
  };

  renderButtons = () => {

    const newBtns = this.props.customDialogButtons;

    const btn1Variant = newBtns ? 'outlined' : 'text';
    const btn2Variant = newBtns ? 'contained' : 'text';

    const btns = [];
    this.props.buttons?.forEach((btn, index) => btns.push(
      <QButton
        variant={index === this.props.buttons.length - 1 ? btn2Variant : btn1Variant}
        id={`alrtdlg:${btn}`}
        key={`alrtdlg:${btn}`}
        onClick={() => this.handleClose(btn)}
        color="primary"
        style={{ marginLeft: 8 }}
      >
        {btn}
      </QButton>
    ));
    return btns;
  };

  render() {
    const { classes, tertiaryButton } = this.props; // eslint-disable-line no-unused-vars

    const isString = typeof this.props.content === 'string';

    let fullContainer = null;
    if (tertiaryButton && typeof tertiaryButton === 'string') {
      fullContainer = (
        <DialogActions
          style={{ paddingBottom: 24, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <QButton
            variant={'text'}
            id={`alrtdlg:${tertiaryButton}`}
            key={`alrtdlg:${tertiaryButton}`}
            onClick={() => this.handleClose(tertiaryButton)}
            color="primary"
          >
            {tertiaryButton}
          </QButton>
          <div style={{ marginRight: 0 }}>
            {this.renderButtons()}
          </div>
        </DialogActions>);
    }


    return (
      <StdDialog
        open
        showCloseButton={this.props.showCloseButton}
        onClose={() => { this.handleClose('Escape'); }}
        aria-describedby="alert-dialog-description"
        PaperProps={{ style: this.props.paperStyle }}
        classes={{ root: this.props.rootStyle }}
        title={<>{this.props.titleIcon}{this.props.title}</>}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            this.handleClose(this.props.buttons && this.props.buttons.length > 0 && this.props.buttons[this.props.buttons.length - 1]);
          } else if (event.key === 'Escape') {
            this.handleClose(this.props.buttons && this.props.buttons.length > 0 && this.props.buttons[0]);
          }
        }}
        sharedcomponentid={'DIALOG_ALERT'}
        {...(!isAcme ? { maxWidth: 'md' } : {})}
      >
        <DialogContent>
          <DialogContent
            id="alert-dialog-description"
            className={classes.textContent}
            style={{ paddingTop: isAcme ? 16 : 40 }}
          >
            {isString &&
              <Typography className={classes.textArea} variant="subtitle1">
                {this.props.content}
              </Typography>}
            {!isString &&
              <>
                {this.props.content}
              </>}
          </DialogContent>

          {(this.props.timeout > -1) &&
          <DialogTimeout>
            {this.props.timeout}
          </DialogTimeout>}
        </DialogContent>

        {(tertiaryButton && typeof tertiaryButton === 'string') ?
          fullContainer
          :
          <DialogActions style={{ paddingBottom: 24, paddingRight: 24, marginRight: 0 }}>
            {this.renderButtons()}
          </DialogActions>}
      </StdDialog>
    );
  }
}

AlertDialog.propTypes = {
  dialogId: PropTypes.string,
  onClose: PropTypes.func,
  buttons: PropTypes.array,
  tertiaryButton: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element]),
  title: PropTypes.string,
  titleIcon: PropTypes.object,
  timeout: PropTypes.number,
  classes: PropTypes.object,
  removeDialog: PropTypes.func,
  paperStyle: PropTypes.object,
  rootStyle: PropTypes.string,
  customDialogButtons: PropTypes.bool,
  showCloseButton: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    customDialogButtons: featureFlagsSelectors.getFeatureFlags(state).get('customDialogButtons'),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    removeDialog: (props) => dispatch(removeDialog(props)),
  };
}

export default compose(
  withStyles(styles, { name: 'AlertDialog' }),
  connect(mapStateToProps, mapDispatchToProps),
)(AlertDialog);

