import React from 'react';
import acctHeroImg from 'assets/webFirst/accounts-hero.png';
import billsHeroImg from 'assets/webFirst/bills-hero.png';
import budgetsHeroImg from 'assets/webFirst/budgets-hero.png';

/*
 * Flow Map
 */
export const staticConfig = (hasAccounts, showSuccess, showScheduled = true, isDeluxeUser = false) => {
  const config = [];
  config.push(
    {
      page: 'addAccounts',
      img: acctHeroImg,
      title: 'Connect your banks with Quicken.',
      bullets: [
        'We use bank-level encryption to secure your login credentials.',
        'Connect all your bank accounts and credit cards with Quicken to see your spending at a glance.',
        isDeluxeUser ? 'You can use the Quicken desktop app to add any investment or loan accounts.' :
          'Add all of your banks and credit cards to get the best insights into your spending.',
      ],
      buttons: [
        {
          label: `Add ${hasAccounts ? 'Another ' : 'Your First '} Account`,
          value: 'addAccount',
          id: hasAccounts ? 'add-another-account-button' : 'add-first-account-button',
        },
      ],
      noSkip: !hasAccounts,
      success: 'Your accounts have been successfully added.',
      successSub: {
        title: 'Have you added all your spending accounts?',
        subTitle: 'Make sure you add all your bank accounts, credit cards, and assets and liabilities securely ' +
          'into Quicken to see your net worth, projected balances, and spending charts.',
      },
      successQCard: {
        firstCard: {
          elementId: 'addFiButton',
          edge: 'righttop',
          width: 400,
          height: 85,
          nudge: { top: 0, left: 6 },
          title: 'Add Account Control',
          content: 'In the future, you can also click here to add more accounts.',
        },
      },
    },
  );
  if (showScheduled) {
    config.push(
      {
        page: 'connectBills',
        img: billsHeroImg,
        title: 'Settings your bills and income in one place, easily.',
        bullets: [
          'View upcoming bills and payments due and get alerts to avoid late fees.',
          'Get latest due amount directly from your biller and pay them from Quicken*.',
          'See future cash flow so you know how much you have left to spend.',
        ],
        caption: '*Billpay works on Quicken Windows or Mac desktop only',
        buttons: [
          {
            label: showSuccess ? 'Review your Bills and Income' : 'Set up your Bills and Income',
            value: 'setUpBills',
            id: showSuccess ? 'review-your-bills-and-income-button' : 'setup-your-bills-and-income-button',
          },
        ],
        success: 'Your bills have been added.',
        successSub: {
          title: 'Have you added all your bills and income?',
          subTitle: 'Click Review button below and add any manual bills you may have missed like your gardener, etc. ' +
            'Also you can download the Quicken desktop app and link the bills to the online biller and set up ' +
            'Bill Pay to electronically pay your bills.',
        },
        successQCard: {
          firstCard: {
            elementId: 'nav-menu-bills-and income',
            edge: 'righttop',
            width: 170,
            height: 230,
            nudge: { top: 0, left: 6 },
            title: 'View your Bills and Income',
            content: 'From here you can view your bills, income, and transfers.You can also add a new reoccurring ' +
              'expense on that page by selecting [+ ADD].',
          },
        },
      },
    );
  }
  config.push(
    {
      page: 'createBudget',
      img: budgetsHeroImg,
      title: <>Take control of your spending. <br />{'Create a Budget'}</>,
      bullets: [
        'Quicken helps you create a realistic budget and gives you an effective tool to manage it.',
        'Budget all, or just a few of your spending categories.',
        'Quickly see if you are over, under, or right on your plan.',

      ],
      buttons: [
        {
          label: 'Set up your budget',
          value: 'createBudget',
          id: 'set-up-budget-button',
        },
      ],
      success: 'You have created your budget!',
      successSub: {
        title: 'Did you budget all your important items?',
        subTitle: 'You can edit your budget at any time to change your budget amounts, add new budget entries, or ' +
          'delete budget entries. Be sure to use the tools in the budget editor to review your prior spending to ' +
          'help you find the right budget amount.',
      },
      successQCard: {
        firstCard: {
          elementId: 'nav-menu-budgets',
          edge: 'righttop',
          width: 170,
          height: 235,
          nudge: { top: 0, left: 6 },
          title: 'View your Budgets',
          content: 'In the future, you can also click here to to view how you are progressing against your budget' +
            ' or to edit your budget.',
        },
      },
    },
  );
  return config;
};

