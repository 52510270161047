
export const styles = ({ palette, _components }) => ({

  root: {
  },
  contentArea: {
    display: 'flex',
    flexDirection: 'row',
  },
  monthPickerSelect: {
    fontSize: '15px',
    fontWeight: 500,
    paddingTop: 4,
  },
  iconSelect: {
    marginTop: -1,
  },
  transactionListPanel: {
    flex: 1.25,
  },
  transactionList: {
    flex: 1.25,
    overflowY: 'scroll',
    maxHeight: 400,
    minWidth: 250,
    paddingRight: 12,
  },
  dividerRoot: {
    backgroundColor: palette.greyScaleDeprecated[4],
    marginBottom: 3,
  },
  chartPanel: {
    flex: 1,
    height: 200,
    marginTop: 'auto',
    marginBottom: 'auto',
    minWidth: 150,
  },

});

