import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { transactionsTypes } from '@quicken-com/react.flux.transactions';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';

import HighlightText from 'components/HighlightText';

const groups = {
  basicOperations: '',
  reinvest: 'Reinvest',
  income: 'Income (Div Int, Dist)',
  shareOperations: '',
  others: '',
  shorts: '',
  cashFlow: '',
};

const options = [
  {
    label: 'Buy',
    group: 'basicOperations',
    id: transactionsTypes.InvestmentTxnType.BUY,
  },
  {
    label: 'Sell',
    group: 'basicOperations',
    id: transactionsTypes.InvestmentTxnType.SELL,
  },
  {
    label: 'Stock Dividend',
    group: 'basicOperations',
    id: transactionsTypes.InvestmentTxnType.STOCK_DIVIDEND,
  },
  {
    label: 'Dividend',
    group: 'reinvest',
    id: transactionsTypes.InvestmentTxnType.REINVEST_DIVIDEND,
  },
  {
    label: 'Interest',
    group: 'reinvest',
    id: transactionsTypes.InvestmentTxnType.REINVEST_INTEREST,
  },
  {
    label: 'Long-term Capital Gain',
    group: 'reinvest',
    id: transactionsTypes.InvestmentTxnType.REINVEST_CAPITAL_GAIN_LONG,
  },
  {
    label: 'Short-term Capital Gain',
    group: 'reinvest',
    id: transactionsTypes.InvestmentTxnType.REINVEST_CAPITAL_GAIN_SHORT,
  },
  {
    label: 'Dividend',
    group: 'income',
    id: transactionsTypes.InvestmentTxnType.INCOME_DIVIDEND,
  },
  {
    label: 'Interest',
    group: 'income',
    id: transactionsTypes.InvestmentTxnType.INCOME_INTEREST,
  },
  {
    label: 'Long-term Capital Gain',
    group: 'income',
    id: transactionsTypes.InvestmentTxnType.CAPITAL_GAIN_LONG,
  },
  {
    label: 'Short-term Capital Gain',
    group: 'income',
    id: transactionsTypes.InvestmentTxnType.CAPITAL_GAIN_SHORT,
  },
  {
    label: 'Miscellaneous',
    group: 'income',
    id: transactionsTypes.InvestmentTxnType.INCOME_MISCELLANEOUS,
  },
  {
    label: 'Add Shares',
    group: 'shareOperations',
    id: transactionsTypes.InvestmentTxnType.ADD_SHARES,
  },
  {
    label: 'Remove Shares',
    group: 'shareOperations',
    id: transactionsTypes.InvestmentTxnType.REMOVE_SHARES,
  },
  { // TODO: no corresponding InvestmentTxnType
    label: 'Transfer Shares',
    group: 'shareOperations',
    id: 'VIRTUAL_TRANSFER_SHARES',
  },
  {
    label: 'Buy Bonds',
    group: 'others',
    id: transactionsTypes.InvestmentTxnType.BUY_BONDS,
  },
  {
    label: 'Sell Bonds',
    group: 'others',
    id: transactionsTypes.InvestmentTxnType.SELL_BONDS,
  },
  {
    label: 'Margin Interest Expense',
    group: 'others',
    id: transactionsTypes.InvestmentTxnType.EXPENSE_MARGIN_INTEREST,
  },
  {
    label: 'Miscellaneous Expense',
    group: 'others',
    id: transactionsTypes.InvestmentTxnType.EXPENSE_MISCELLANEOUS,
  },
  {
    label: 'Return of Capital',
    group: 'others',
    id: transactionsTypes.InvestmentTxnType.RETURN_OF_CAPITAL,
  },
  {
    label: 'Stock Split',
    group: 'others',
    id: transactionsTypes.InvestmentTxnType.STOCK_SPLIT,
  },
  {
    label: 'Short Sell',
    group: 'shorts',
    id: transactionsTypes.InvestmentTxnType.SHORT_SELL,
  },
  {
    label: 'Buy to Cover',
    group: 'shorts',
    id: transactionsTypes.InvestmentTxnType.COVER_SHORT_SELL,
  },
  {
    label: 'Payment/Deposit',
    group: 'cashFlow',
    id: 'VIRTUAL_CASH_FLOW',
  },
];

const InvestmentTransactionTypeField = (props) => {
  const { value: valueProp, textFieldProps, ...otherProps } = props;

  const [input, setInput] = useState('');

  const selectedOption = useMemo(() => options.find((option) => option.id === props.value),
    [props.value]);

  return (
    <Autocomplete
      id="investment-transaction-type-field"
      options={options}
      sx={{ minWidth: 240 }}
      disableClearable
      autoComplete
      autoHighlight
      clearOnBlur
      clearOnEscape
      renderInput={(params) => (
        <TextField
          {...params}
          label="Type"
          {...textFieldProps}
          InputProps={{ ...params.InputProps, ...textFieldProps?.InputProps }}
        />
      )}
      onInputChange={(_event, value, _reason) => setInput(value)}
      renderOption={(optionProps, option, _state) => (
        <ListItem {...optionProps}>
          <HighlightText
            text={option.label}
            searchKeys={[input]}
          />
        </ListItem>
      )}
      groupBy={(option) => option.group}
      renderGroup={(params) => !params.group ? params.children : (
        <>
          <Divider />
          {groups[params.group] && (
            <ListItem
              sx={{ color: 'text.disabled' }}
              key={params.group}
            >
              {groups[params.group]}
            </ListItem>
          )}
          <Box ml={2}>
            {params.children}
          </Box>
        </>
      )}
      {...otherProps}
      value={selectedOption}
      onChange={(_event, value, _reason, _details) => props.onChange?.(value?.id)}
    />
  );
};

InvestmentTransactionTypeField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  textFieldProps: PropTypes.object,
};

export default InvestmentTransactionTypeField;
