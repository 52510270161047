// @flow

/*
 * ACCOUNT STORE SAGAS
 *
 * These routines listen for request actions, and satisfies them.  These are asynchronous routines, and upon
 * completion will dispatch a new action with the results
 *
 *
 */
import { call, take, takeEvery, put } from 'redux-saga/effects';

import { resourceStoreTypes, resourceSagaUtils } from '@quicken-com/react.flux.core';
import { accountsSagas, accountsActions } from '@quicken-com/react.flux.accounts';

import * as actions from './actions';
import * as transformers from './transformers';

// ===============================================================================================
// SAGAS functions for accounts.  These are the functions responsible for communicating directly
// with external endpoints asynchronously.  Data is returned via ACTIONS, however, these can be
// split out (and should be) to be fully functional and JS shareable API callers via Axios and
// simply return an object in a provided callback, which can then choose to call the actions
//

// =================================================================================================
// Investment Account Watchers
// =================================================================================================

const mkResourceConfigInvestmentAccounts = (successAction, failureAction) => resourceStoreTypes.mkQcsSyncResourceConfig({
  resourceName: 'investment-account',
  resourceBaseUrl: '/q17/accounts',
  getLastSyncDate: () => null,      // We always want to refresh

  transformResponseToResources: transformers.transformResponseToInvestmentAccounts,
  transformResourceToRequestData: transformers.transformInvestmentAccountToQ17RequestData,
  transformResourcesToRequestData: transformers.transformInvestmentAccountsToQ17RequestData,

  successAction,
  failureAction,
});

export function* getInvestmentAccountsActionWatcher(): Generator<*, *, *> {
  // using a vanilla saga pattern so we only process a single get request
  //   - takeEvery would process every request
  //   - takeLatest would cancel any current request and start request again
  while (true) {
    const action = yield take(actions.getInvestmentAccounts);

    const resourceConfig = mkResourceConfigInvestmentAccounts(
      actions.getInvestmentAccountsSuccess, actions.getInvestmentAccountsFailure
    );
    yield call(resourceSagaUtils.qcsSyncGetResources, resourceConfig, action);
  }
}

export function* createInvestmentAccountActionWatcher(): Generator<*, *, *> {
  const resourceConfig = mkResourceConfigInvestmentAccounts(
    actions.createInvestmentAccountSuccess, actions.createInvestmentAccountFailure
  );
  yield takeEvery(actions.createInvestmentAccount, resourceSagaUtils.qcsSyncCreateResource, resourceConfig);
}

export function* updateInvestmentAccountActionWatcher(): Generator<*, *, *> {
  const resourceConfig = mkResourceConfigInvestmentAccounts(
    actions.updateInvestmentAccountSuccess, actions.updateInvestmentAccountFailure
  );
  yield takeEvery(actions.updateInvestmentAccount, resourceSagaUtils.qcsSyncUpdateResource, resourceConfig);
}

export function* batchInvestmentAccountsActionWatcher(): Generator<*, *, *> {
  const resourceConfig = mkResourceConfigInvestmentAccounts(
    actions.batchInvestmentAccountsSuccess, actions.batchInvestmentAccountsFailure
  );
  yield takeEvery(actions.batchInvestmentAccounts, resourceSagaUtils.qcsSyncUpdateResources, resourceConfig);
}

export function* deleteInvestmentAccountActionWatcher(): Generator<*, *, *> {
  const resourceConfig = mkResourceConfigInvestmentAccounts(
    actions.deleteInvestmentAccountSuccess, actions.deleteInvestmentAccountFailure
  );
  yield takeEvery(actions.deleteInvestmentAccount, resourceSagaUtils.qcsSyncDeleteResource, resourceConfig);
}

function* createInvestmentAccountSuccessActionWatcher() {
  yield takeEvery(actions.createInvestmentAccountSuccess, function* createInvestmentAccountSuccess({ payload }) {
    yield put(accountsActions.applyAccountsChanges([payload], { context: 'createInvestmentAccountSuccess' }));
  });
}

function* updateInvestmentAccountSuccessActionWatcher() {
  yield takeEvery(actions.updateInvestmentAccountSuccess, function* updateInvestmentAccountSuccess({ payload }) {
    yield put(accountsActions.applyAccountsChanges([payload], { context: 'updateInvestmentAccountSuccess' }));
  });
}

function* deleteInvestmentAccountSuccessActionWatcher() {
  yield takeEvery(actions.deleteInvestmentAccountSuccess, function* deleteInvestmentAccountSuccess({ payload }) {
    yield put(accountsActions.applyAccountsChanges([payload], { context: 'deleteInvestmentAccountSuccess' }));
  });
}

function* batchInvestmentAccountsSuccessActionWatcher() {
  yield takeEvery(actions.batchInvestmentAccountsSuccess, function* batchInvestmentAccountsSuccess({ payload }) {
    yield put(accountsActions.applyAccountsChanges(payload, { context: 'batchInvestmentAccountsSuccess' }));
  });
}

// =================================================================================================
// Exports
// =================================================================================================

export default [
  ...accountsSagas,
  getInvestmentAccountsActionWatcher,
  createInvestmentAccountActionWatcher,
  updateInvestmentAccountActionWatcher,
  batchInvestmentAccountsActionWatcher,
  deleteInvestmentAccountActionWatcher,
  createInvestmentAccountSuccessActionWatcher,
  updateInvestmentAccountSuccessActionWatcher,
  deleteInvestmentAccountSuccessActionWatcher,
  batchInvestmentAccountsSuccessActionWatcher,
];
