// BASE
import React from 'react';
import PropTypes from 'prop-types';

// CUSTOM COMPONENTS
import QButton from 'components/QButton';


// MUI COMPONENTNS
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';

// PATH RELATIVE IMPORTS

/*
 *************** SUB COMPONENTS ******************
 */
/*
 * Title Header sub component
 */
export const TitleHeader = (props) => {

  const { classes, dialogTitle, isMacDataset, create = false } = props;

  let subText;
  if (create) {
    if (isMacDataset) {
      subText = 'Here is a starter budget to get you going.  You can budget specific categories, ' +
        ' either from our suggestions list, or pick exactly the one to add from the full category list.';
    } else {
      subText = 'Here is a starter budget for you to fill out. You can enter just an income and expense amount, or you ' +
        'can budget specific categories, either from our suggestions list, or pick exactly the one to add from the ' +
        'full category list.';
    }
  } else {
    subText = 'This is your existing budget. You may choose to update the amount, add or remove categories.' +
      '  Changes you make will be automatically applied to the remaining months for the year.';
  }
  return (
    <div
      className={classes.header}
    >
      <Typography
        variant="h5"
        className={classes.title}
      >
        {dialogTitle}
      </Typography>
      <Typography
        style={{ paddingRight: 8 }}
        variant="body1"
      >
        {subText}
      </Typography>
    </div>
  );
};
TitleHeader.propTypes = {
  classes: PropTypes.object,
  dialogTitle: PropTypes.string,
  create: PropTypes.bool,
  isMacDataset: PropTypes.bool,
};
/*
 * Add Category sub component
 */
export const AddCategoryBlock = (props) => {

  const { classes, onAddCategory, onSuggestedCategories } = props;
  return (
    <div
      className={classes.addCategoryBlock}
    >
      <QButton
        variant="contained"
        id="edit-budgets-suggested-categories"
        className={classes.addCategoryButton}
        aria-label="Show Suggested Categories"
        onClick={onSuggestedCategories}
        classes={{ conButton: classes.addButtonPrimary }}
      >
        <AddIcon /> Suggestions
      </QButton>
      <QButton
        variant="contained"
        id="edit-budgets-add-category"
        className={classes.addCategoryButton}
        aria-label="Add Category"
        onClick={onAddCategory}
        classes={{ conButton: classes.addButtonSecondary }}
      >
        <AddIcon /> Add Category
      </QButton>
    </div>
  );
};
AddCategoryBlock.propTypes = {
  classes: PropTypes.object,
  onAddCategory: PropTypes.func,
  onSuggestedCategories: PropTypes.func,
};

