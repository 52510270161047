import { compose } from 'redux';
import { routerMiddleware } from 'connected-react-router/immutable';

import { history, localPreferences, getLogger } from '@quicken-com/react.utils.core';
import { initialize } from '@quicken-com/react.utils.redux-store';
import { AUTH_REDUCER_KEY } from '@quicken-com/react.flux.auth';

import { loadPersistedDatasetData } from 'data/persist/actions';
import { loadAuthSession } from 'utils/auth/storage';

import createReducer from './reducers';

const logger = getLogger('store.js');

const initialState = {};

const persistedAuthSession = loadAuthSession();
if (persistedAuthSession) {
  initialState[AUTH_REDUCER_KEY] = persistedAuthSession;
}

// If Redux DevTools Extension is installed use it, otherwise use Redux compose
let enhancerComposer = compose;
if (typeof window === 'object') {
  /* eslint-disable no-underscore-dangle */
  if (localPreferences.getDebug() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    enhancerComposer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // actionSanitizer,
      trace: true,
      traceLimit: 20,
    });

    // TODO: Uncomment the code below to restore support for Redux Saga
    // TODO: Dev Tools once it supports redux-saga version 1.x.x
    // if (window.__SAGA_MONITOR_EXTENSION__)
    //   reduxSagaMonitorOptions = {
    //     sagaMonitor: window.__SAGA_MONITOR_EXTENSION__,
    // };
  }
  /* eslint-enable */
}

// const actionBreakpointMiddleware = (_store) => (next) => (action) => {
//   assert(action.type !== 'YOUR_ACTION_HERE', 'breakpoint');
//   return next(action);
// };

const actionLoggerMiddleware = (_store) => (next) => (action) => {
  logger.debug('action: ', action.type, action);
  return next(action);
};

const store = initialize(
  initialState,
  createReducer,
  {
    enhancerComposer,
    middlewares: [
      actionLoggerMiddleware,
      routerMiddleware(history),
      // actionBreakpointMiddleware,
    ],
  }
);

// TODO: remove loadPersistedDatasetData once Quicken stop using credentials blob for Wells Fargo
if (persistedAuthSession.datasetId) {
  store.dispatch(loadPersistedDatasetData());
}

// TODO: I don't think it's really works - fix hot reload (keep in mind persistReducer)
if (module.hot) {
  module.hot.accept('./reducers', () => {
    store.replaceReducer(createReducer(store.injectedReducers));
  });
}

export default store;

