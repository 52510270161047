import store from 'store';
import { createStore } from 'redux';
import createReducer from 'reducers';
import { dehydrateImmutable } from 'utils/immutableSerialize';
import { coreActions } from '@quicken-com/react.flux.core';

export const copyState = (state, sanitize) => {
  const storeTmp = createStore(createReducer(), state || store.getState());
  if (sanitize) {
    storeTmp.dispatch(coreActions.sanitize());
  }
  const stateSanitized = storeTmp.getState();
  const json = dehydrateImmutable(stateSanitized);
  const blob = new Blob([json], { type: 'application/json' });
  return blob;
};

export const saveStore = (name = 'store.js', sanitize = false) => {
  const state = store.getState();
  const blob = copyState(state, sanitize);
  const fileDownload = document.getElementById('fileDownload') || document.createElement('a');
  fileDownload.id = 'fileDownload';
  fileDownload.style.display = 'none';
  fileDownload.href = URL.createObjectURL(blob);
  fileDownload.download = name;
  fileDownload.click();
};
