// @flow
/* eslint react/prop-types: 0 */

import React from 'react';
import compose from 'utils/compose';

import IconButton from '@mui/material/IconButton';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';

import QTip from 'components/QuickenControls/QTip';

const styles = (_theme) => ({
  root: {
    lineHeight: '1em',
  },
  rootSmall: {
    fontSize: '20px',
    padding: '10px',
  },
  rootSmallTarget: {
    fontSize: '20px',
    padding: '6px',
    height: 32,
    width: 32,
  },
  rootSmallNoPadding: {
    fontSize: '20px',
    padding: '0px',
  },
  rootMedium: {
    fontSize: '24px',
    padding: '12px',
  },
  rootMediumNoPadding: {
    fontSize: '24px',
    padding: '3px',
  },
  rootLarge: {
    fontSize: '36px',
    padding: '12px',
  },
});
const getSize = (theme) => theme.defaults.dense ? 'small' : 'medium';

const QIconButton = (
  {
    classes,
    children,
    IconComponent = null,
    IconProps,
    size = null,
    tooltip = null,
    tooltipProps,
    TooltipProps,
    theme,
    ...other
  }
) => {
  size = size !== null ? size : getSize(theme);  // eslint-disable-line no-param-reassign

  let child = null;
  if (IconComponent != null) {
    child = (
      <IconComponent
        fontSize="inherit"
        {...IconProps}
      />
    );
  } else {
    child = children && React.cloneElement(children, { fontSize: 'inherit', ...children.props });
  }

  return (
    <IconButton
      classes={{
        root: classNames(classes.root, classes[camelCase(`root-${size}`)]),
      }}
      {...other}
      size="large"
    >
      {tooltip &&
        <QTip
          title={tooltip}
          {...TooltipProps}
        >
          {child}
        </QTip>}
      {!tooltip &&
        child}
    </IconButton>
  );
};

export default compose(
  withStyles(styles, { withTheme: true }),
)(QIconButton);
