import { useDispatch, useSelector } from 'react-redux';

import * as preferencesActions from 'data/preferences/actions';
import * as preferencesSelectors from 'data/preferences/selectors';

function useQPreferences() {
  const dispatch = useDispatch();

  const setPreference = (payload, meta) => dispatch(preferencesActions.setPreference(payload, meta));

  const getSharedPreferences = () => {
    dispatch(preferencesActions.getPreferences({ section: 'shared', group: 'user' }));
    dispatch(preferencesActions.getPreferences({ section: 'shared', group: 'dataset' }));
  };

  const setDatasetPreference = (prefObj) => {
    dispatch(preferencesActions.setPreference({
      section: 'shared',
      group: 'dataset',
      preference: { webApp: prefObj },
    }, { context: 'QPreferences:setDatasetPreference' }));
  };

  const setUserPreference = (prefObj) => {
    dispatch(preferencesActions.setPreference({
      section: 'shared',
      group: 'user',
      preference: { webApp: prefObj },
    }, { context: 'SetupPageAcme:setUserPreference' }));
  };

  const datasetPreferences = useSelector((state) => preferencesSelectors.getSharedPreferencesByPath(state, { group: 'dataset', path: ['webApp'] }));
  const userPreferences = useSelector((state) => preferencesSelectors.getSharedPreferencesByPath(state, { group: 'user', path: ['webApp'] }));
  const sharedUserPreferences = useSelector((state) => preferencesSelectors.getSharedPreferencesByPath(state, { group: 'user' }));
  const sharedDatasetPreferences = useSelector((state) => preferencesSelectors.getSharedPreferencesByPath(state, { group: 'dataset' }));
  const accountPreferencesById = useSelector((state) => preferencesSelectors.accountPreferencesById(state));

  return ({
    setPreference,
    getSharedPreferences,
    setDatasetPreference,
    setUserPreference,
    datasetPreferences,
    userPreferences,
    sharedUserPreferences,
    sharedDatasetPreferences,
    accountPreferencesById,
  });
}

export default useQPreferences;
