// import { getLogger } from 'utils/logger';
import { createSelector } from 'reselect';
import { Map as ImmutableMap } from 'immutable';

import { categoriesSelectors } from '@quicken-com/react.flux.categories';


import { chartOfAccountsTypes } from '@quicken-com/react.flux.chart-of-accounts';

import { CATEGORY_GROUPS_REDUCER_KEY } from './categoryGroupsReducer';
// const log = getLogger('data/budgetItems/budgetItemsSelectors.js');

export const getStore = (state) => state[CATEGORY_GROUPS_REDUCER_KEY];

export const getLoadPending = (state) => getStore(state).loadPending;

export const getIsLoading = (state) => getStore(state).isLoading;

export const getLastSyncDate = (state) => getStore(state).lastSyncDate;

export const getCategoryGroupsByIdRaw = (state) => getStore(state).resourcesById;

export const getCategoryGroupById = (state, id) => getCategoryGroupsById(state).get(id);

export const getCategoryGroupsById = createSelector(
  categoriesSelectors.getCategoriesById,
  getCategoryGroupsByIdRaw,
  (categoriesById, categoryGroupsById) =>
    categoryGroupsById.map((group) => {
      if (group.isDynamic) {
        // create the ID list
        const coas = categoriesById.map((cat) =>
          (cat.type === group.type) ? ImmutableMap({ type: 'CATEGORY', id: cat.id }) : null).filter((x) => x !== null);
        return group.set('coas', coas.toList().toJS()).set('isDynamic', false);
      }
      return group;
    })
);

export const getCategoryTreeForCategoryGroup = createSelector(
  categoriesSelectors.getCategoriesById,
  (state, categoryGroup) => categoryGroup,
  (categoriesById, categoryGroup) =>
    (ImmutableMap()).withMutations((categoryGroupTree) => {
      if (categoryGroup.coas.length && categoriesById.count()) {
        categoryGroup.coas.reduce((childrenByParentIdMap, coa) => {
          if (coa.type === 'CATEGORY') {
            for (let category = categoriesById.get(coa.id); category; category = categoriesById.get(category.parentId || '0')) {
              const children = childrenByParentIdMap.get(category.parentId) || [];
              if (children.indexOf(category) < 0) {
                children.push(category);
              }
              childrenByParentIdMap.set(category.parentId || '0', children);
            }
          }
          return childrenByParentIdMap;
        }, categoryGroupTree);

        categoryGroupTree.forEach((categories) =>
          categories.sort((category1, category2) =>
            category1.name.localeCompare(category2.name, undefined, { numeric: true, sensitivity: 'base' })));
      }
    })
);

export const filterCategoriesByGroup = createSelector(
  (categoriesById, _categoryGroup) => categoriesById,
  (_categoriesById, categoryGroup) => categoryGroup,
  (categoriesById: any, categoryGroup: any) => categoriesById && categoryGroup && categoryGroup.coas &&
    categoriesById.filter((category) => categoryGroup.coas.find((coa) => coa.type === chartOfAccountsTypes.CoaTypeEnum.CATEGORY && coa.id === category.id)),
);
