import { handleActions, combineActions } from 'redux-actions';

import { coreActions } from '@quicken-com/react.flux.core';
import { transactionsActions as actions, transactionsReducer as transactionsReducerBit } from '@quicken-com/react.flux.transactions';

import { initReductions, addReduction, clearReductions } from 'data/optimalCacheManager';

import { TransactionStore } from './types';

let previousState;

const transactionsReducerPre = handleActions({}, TransactionStore({ resourceName: 'TRANSACTIONS' }));

const handleReductions = (state, transactions) => {
  let newState = state;

  // If the UPSERT is more than 10 transactions, then don't optimize, but force selectors to recalculate
  const doOptimize = (transactions?.length <= 10);
  if (doOptimize) {
    // Initialize the reduction using optimalCacheManager
    newState = initReductions(newState);
    transactions.forEach((transaction) => {
      const oldTransaction = previousState?.resourcesById.get(transaction.id || transaction.clientId);
      const newTransaction = transaction.isDeleted ? transaction : newState.resourcesById.get(transaction.id || transaction.clientId);
      newState = addReduction(newState, oldTransaction, newTransaction);
    });
  } else {
    newState = clearReductions(newState);
  }

  return newState;
};

const transactionsReducerPost = handleActions(
  {
    [coreActions.sanitize]: (state) =>
      state.set('lastReductions', initReductions(state.set('lastReductions', null)).lastReductions), // TODO: init lastReductions in Record prototype

    [combineActions(
      actions.createTransactions,
      actions.createTransactionsSuccess,
      actions.updateTransactions,
      actions.updateTransactionsSuccess,
      actions.deleteTransactions,
      actions.applyTransactionsChanges,
      actions.performTransactionActionSuccess,
    )]: (state, action) => handleReductions(state, action.payload),

    [actions.getTransactionsSuccess]: (state, { payload }) => handleReductions(state, payload.resources),
  },
  TransactionStore({ resourceName: 'TRANSACTIONS' }),
);

// chain reducers
export const reducer = (state, action) => {
  let newState = state;
  previousState = state;
  newState = transactionsReducerPre(newState, action);
  newState = transactionsReducerBit(newState, action);
  newState = transactionsReducerPost(newState, action);
  return newState;
};

export default reducer;
