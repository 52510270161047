
// CORE
import React from 'react';
import PropTypes from 'prop-types';

import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';

import makeStyles from '@mui/styles/makeStyles';
import LinkIcon from '@mui/icons-material/Link';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import UpdatedIcon from 'assets/status-updated.svg';

// DATA
import { useSelector } from 'react-redux';
import { getBillerAccountForId } from 'data/billerAccounts/selectors';
import { getBillerLoginForId } from 'data/billerLogins/selectors';
import { inError, isRefreshing } from 'data/billerLogins/utils';


const useStyles = makeStyles((theme) => ({
  icon: ({ size, reconnect, isPending }) => ({
    height: size,
    width: size,
    bottom: -size / 2 + size / 8,
    right: -size / 2 + size / 8,
    position: 'absolute',
    borderRadius: size / 2,

    zIndex: 1,
    backgroundColor: reconnect ? theme.palette.color5.opacity100 : theme.palette.greyScaleDeprecated[7],
    color: reconnect ? theme.palette.greyScaleDeprecated[7] : theme.palette.primary.main,
    padding: (reconnect && size / 24) || (isPending ? size / 6 : size / 16),
    boxShadow: theme.shadows[2],
  }),

  updatedIcon:
    ({ size }) => ({
      height: size,
      width: size,
      bottom: -size / 2 + size / 8,
      right: -size / 2 + size / 8,
      position: 'absolute',
      borderRadius: size / 2,

      zIndex: 1,
      backgroundColor: theme.palette.greyScaleDeprecated[7],
      padding: Math.round(size / 16),
      boxShadow: theme.shadows[2],
    }),
}));


// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
// ### - - -                  Main Component                     - - - ###
// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
function ConnectedIcon(props) {

  const { model, size, txn } = props;

  const presentBills = useSelector(featureFlagsSelectors.getFeatureFlags).get('billPresentment');
  const showIcon = presentBills && model && model.billPresentmentAccountId;

  let isPending = model?.billPresentmentAccountId === '0';
  const updated = txn && txn.ebillId;
  const connected = showIcon && !isPending;
  const billerAccount = useSelector((state) => connected && getBillerAccountForId(state, model?.billPresentmentAccountId));
  const billerLogin = useSelector((state) => connected && getBillerLoginForId(state, billerAccount?.billerLoginID));
  isPending = isPending || isRefreshing(billerLogin);

  const reconnect = model?.billPresentmentAccountId && billerLogin && inError(billerLogin);

  const classes = useStyles({ size, reconnect, isPending });
  let Icon = reconnect ? ErrorIcon : LinkIcon;
  if (isPending) Icon = CircularProgress;

  let title = reconnect ? 'Connection Error' : 'Connected';
  if (isPending) title = 'Connecting';
  if (updated) title = 'Updated';

  return ((showIcon) ?
    <Tooltip title={title} arrow enterDelay={300}>
      {updated ?
        <img
          alt="status-icon"
          src={UpdatedIcon}
          className={classes.updatedIcon}
        />
        :
        <Icon className={classes.icon} size={size} />}
    </Tooltip>
    :
    null
  );
}

ConnectedIcon.defaultProps = {
  size: 24,
};

ConnectedIcon.propTypes = {
  model: PropTypes.object,
  size: PropTypes.number,
  txn: PropTypes.object,
};

export default ConnectedIcon;
