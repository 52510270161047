import { select, takeEvery } from 'redux-saga/effects';

import { getLogger, tracker } from '@quicken-com/react.utils.core';
import { authSelectors } from '@quicken-com/react.flux.auth';
import { accountsActions, accountsSelectors } from '@quicken-com/react.flux.accounts';
import { transactionsActions } from '@quicken-com/react.flux.transactions';

import * as transactionsSelectors from 'data/transactions/selectors';
import { getBalanceForAccountAtDate } from 'data/accountBalances/retrievers';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';

import isAcme from 'isAcme';

const log = getLogger('data/accountBalances/sagas');


let checkedBalances = false;

// ====================================================
// VALIDATE BALANCES
export function* validateBalances() {

  const accountsPending = yield select(accountsSelectors.getLoadPending);
  const transactionsPending = yield select(transactionsSelectors.getLoadPending);
  const accountsLoading = yield select(accountsSelectors.getIsLoading);
  const transactionsLoading = yield select(transactionsSelectors.getIsLoading);
  const datasetId = yield select(authSelectors.getDatasetId);
  const showEvent = (yield select(featureFlagsSelectors.getFeatureFlags)).get('flagBalanceMismatchWithEvent');

  if (!isAcme && !accountsLoading && !transactionsLoading && !transactionsPending && !accountsPending && !checkedBalances) {

    checkedBalances = true;
    log.log('VALIDATE BALANCES FOR ALL ACCOUNTS ------ ');

    const accountsById = yield select(accountsSelectors.getAccountsById);

    accountsById.forEach((account) => {
      if (account.type !== 'INVESTMENT') {
        const bal1 = getBalanceForAccountAtDate({
          accountId: account.id,
          date: account.balanceAsOfOn,
        });
        /*
        log.log(
          'BALANCE COMPARE ',
          account.name,
          'balanceAsOfOn ', moment(account.balanceAsOfOn).format('MM/DD/YYYY'),
          'balanceAsOf ', Number(account.balanceAsOf),
          'currentBalanceAsOfOn ', moment(account.currentBalanceAsOfOn).format('MM/DD/YYYY'),
          'currenBalanceAsOf ', account.currentBalanceAsOf,
          'calculatedBalance to match balanceAsOfOn ',
          Number(bal1),
        );
        */
        if (showEvent && (Number(bal1).toFixed(2) !== Number(account.balanceAsOf).toFixed(2))) {
          const event = {
            datasetId,
            accountId: account.id,
            delta: (Number(bal1) - Number(account.balanceAsOf)).toFixed(2),
          };
          log.log('Balance Event Sent: ', event);
          tracker.track(tracker.events.accountBalanceMismatch, event);
        }
      }
    });
  }
}

// ====================================================
// ACTION WATCHERS to trigger SAGAS calls

export function* getAccountsSuccessActionWatcher() {
  yield takeEvery(accountsActions.getAccountsSuccess, validateBalances);
}
export function* getTransactionsSuccessActionWatcher() {
  yield takeEvery(transactionsActions.getTransactionsSuccess, validateBalances);
}


// ====================================================
// EXPORTS

export default [
  getAccountsSuccessActionWatcher,
  getTransactionsSuccessActionWatcher,
];


