import { handleActions } from 'redux-actions';

import { resourceUpserters, resourceStoreTypes } from '@quicken-com/react.flux.core';

import * as actions from './investmentPerformanceV2Actions';

const investmentPerformanceV2Reducer = handleActions({

  [actions.getInvestmentPerformanceV2]:
    (state) => state.merge({ isLoading: true }),
  [actions.getInvestmentPerformanceV2Success]:
    (state, action) => resourceUpserters.upsertResources(state, action.payload),
  [actions.getInvestmentPerformanceV2Failure]:
    (state, { payload: error }) => resourceUpserters.completeWithError(state, error),

}, resourceStoreTypes.mkQcsSyncResourceStore());

export const INVESTMENT_PERFORMANCE_V2_REDUCER_KEY = 'investmentPerformanceV2Store';
export default investmentPerformanceV2Reducer;
