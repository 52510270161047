import React from 'react';
import compose from 'utils/compose';
import { useSelector } from 'react-redux';

import { datasetsSelectors } from '@quicken-com/react.flux.datasets';
import { authSelectors } from '@quicken-com/react.flux.auth';

import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import withStdForm from 'data/StdForm';
import StdFormDialog from 'components/Dialogs/StdFormDialog';
import { useStyles } from 'components/Dialogs/HelpSupportDialog/styles';
import videoImage from 'assets/help-video.png';
import supportImage from 'assets/help-support.png';
import questionsImage from 'assets/help-questions.png';

const urls = {
  support: 'http://quicken.com/contact-support',
  video: 'https://www.youtube.com/user/Quicken/videos',
  questions: 'https://help.quicken.com/pages/viewpage.action?pageId=8422761',
};

const HelpSupport = () => {
  const classes = useStyles();
  const datasetId = useSelector(authSelectors.getDatasetId);
  const datasetsById = useSelector(datasetsSelectors.getDatasetsById);
  const currentDataset = datasetId && datasetsById.get(datasetId);
  const showVideoSection = ['QWIN_MWF', 'QMAC_MWF', 'QWIN_CA_MWF'].includes(currentDataset?.platform);

  return (
    <StdFormDialog
      dialogProps={{
        fullWidth: true,
        maxWidth: showVideoSection ? 'lg' : 'md',
      }}
      dialogId="DIALOG_HELP_SUPPORT"
      showCloseButton
    >
      <Grid container className={classes.root}>
        <Grid item className={classes.supportSectionRoot}>
          <div className={classes.title}>Support</div>
          <div className={classes.mediaHolder}>
            <Link target="_blank" href={urls.support}><img src={supportImage} alt="Support" /></Link>
          </div>
          <div className={classes.content}>
            <p>
              Visit <Link target="_blank" href={urls.support} underline={'hover'}>www.quicken.com/contact-support</Link> or
              call us at 650-250-1900 during office hours Mon - Fri, 5 AM to 5 PM (Pacific)
            </p>
          </div>
        </Grid>

        {showVideoSection ? (
          <Grid item className={classes.videoSectionRoot}>
            <div className={classes.title}>Videos</div>
            <div className={classes.mediaHolder}>
              <Link target="_blank" href={urls.video}><img src={videoImage} alt="Video" /></Link>
            </div>
            <div className={classes.content}>
              <p>Learn how to use Quicken <br />from our video library</p>
              <Link target="_blank" href={urls.video} underline={'hover'}>Go to Videos</Link>
            </div>
          </Grid>
        ) : (
          <Grid item className={classes.questionsSectionRoot}>
            <div className={classes.title}>Have questions?</div>
            <div className={classes.mediaHolder}>
              <Link target="_blank" href={urls.questions}><img src={questionsImage} alt="Questions" /></Link>
            </div>
            <div className={classes.content}>
              <p>
                Read our <Link target="_blank" href={urls.questions} underline={'hover'}> Frequently Asked Questions</Link> to resolve common issues.
              </p>
            </div>
          </Grid>
        )}
      </Grid>
    </StdFormDialog>
  );
};

HelpSupport.propTypes = {};

export default compose(
  withStdForm({ type: 'HelpSupport' })
)(HelpSupport);
