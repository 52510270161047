// @flow
import { call, put, select, take, takeEvery, takeLatest, delay } from 'redux-saga/effects';
import { Map as ImmutableMap } from 'immutable';
import { v4 as uuid } from 'uuid';

import { getEnvironmentConfig, getLogger, tracker } from '@quicken-com/react.utils.core';
import { resourceStoreTypes, resourceSagaUtils } from '@quicken-com/react.flux.core';
import { accountsActions, accountsTransformers } from '@quicken-com/react.flux.accounts';

import { cpMetaForResponseAction } from 'utils/actionHelpers';
import { RefreshAccountsMfaFormSubmitError } from 'data/institutionLogins/types';
import { DIALOG_TYPE_ACCOUNTS_DISCOVERY, mkAccountDiscoveryDialogProps } from 'components/Dialogs/AccountDiscovery/types';
import * as institutionsActions from 'data/institutions/actions';
import { getInstitutionsById } from 'data/institutions/selectors';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import * as institutionsSelectors from 'data/institutions/selectors';
import {
  transformRefreshingLoginsToRequestData,
  transformMfaResponseToMfaChallenge,
} from 'data/institutionLogins/transformers';
import { DIALOG_TYPE as REFRESH_TIMEOUT_DIALOG, mkRefreshTimeoutDialogProps, mkConfirmationDialogProps }
  from 'components/Dialogs/RefreshTimeoutDialog';
import AxiosFactory from 'utils/axiosFactory';

import * as actions from './actions';
import {
  getLastSyncDate,
  getCredentialBlobsForRefreshingLogins,
  getCredentialsForRefreshingLogins,
  wasUserActionCancelled,
  getIdsRefreshingWithUserActionsPending,
  getInstitutionLoginsById,
  getInstitutionLoginForId,
  hasUserActionBeenQueued,
  isUserActionPending,
  getTrackingAggregator,
} from './selectors';
import * as transformers from './transformers';
import type { InstitutionLogin } from './types';

const log = getLogger('data/institutionLogins/sagas');
const qcsAxios = AxiosFactory.get('qcs');

// =============================================================================
// Refresh Institution Logins
// =============================================================================

export function* preRefreshAccountsCheck(loginsToRefresh: Array<InstitutionLogin>, action: any): Generator<*, *, *> {
  // log.debug('Starting RefreshAccounts PreCheck');

  const credentialBlobsForRefreshingLogins = yield select(getCredentialBlobsForRefreshingLogins);
  const credentialsForRefreshingLogins = yield select(getCredentialsForRefreshingLogins);

  for (let i = 0; i < loginsToRefresh.length; ++i) {
    const login = loginsToRefresh[i];
    if (login.ewcLive) {
      log.debug('Found EWCLive account');
      if (
        credentialBlobsForRefreshingLogins.get(login.id) ||
        credentialsForRefreshingLogins.get(login.id)
      ) {
        // already have credentials for this login so continue on to checking other logins
        // log.debug('Already have credentials, continuing to next login');
        continue;   // eslint-disable-line
      }
      const userActionCancelled = yield select(wasUserActionCancelled, login.id);
      if (userActionCancelled) {
        // already asked user for credentials, but they cancelled so we won't ask again
        // log.debug('User cancelled credentials, continuing to next login');
        continue;   // eslint-disable-line
      }

      // ask user for credentials, then trigger refresh again
      const dialogData = mkRootUiData({
        id: uuid(),
        type: DIALOG_TYPE_ACCOUNTS_DISCOVERY,
        allowNesting: false,
        props: ImmutableMap(mkAccountDiscoveryDialogProps({
          mode: 'PROVIDE-CREDENTIALS',
          institutionLogin: login,

          afterCloseAction: action,
        })),
      });

      log.log('Triggering Credential Request', dialogData);
      yield put(createDialog(dialogData));
      yield put(actions.refreshAccountsTriggeredCredentialsRequest(login.id));

      // terminate refesh (will trigger again after handling credentials for institution-login)
      return false;
    }
  }

  return true;
}

function* triggerGetAccountsAction() {
  // we now deal with deleted institution id's directly in the accounts reducer
  // removing them from REDUX, so no need to call QCS at this point
  // yield put(getAccounts());
}

// =================================================================================================
// Login Actions
// =================================================================================================

// a saga for utilizing the institution-logins/{id}/action endpoints
//
// {
//   id: accountId to impact
//   action: 'close' || 'disconnect' || 'make-manual'
// }
//
export function* doInstitutionLoginAction({ payload, meta }) {
  const { qcsAction, id, params } = payload;
  const { resolve, reject } = meta;

  const requestUrl = `${getEnvironmentConfig().services_url}/institution-logins/${id}/${qcsAction.toLowerCase()}`;
  const requestBody = transformers.transformActionParamsToRequestData(qcsAction, params);

  try {
    const { data } = yield call(qcsAxios.put, requestUrl, requestBody);

    const login = transformers.transformQcsInstitutionLoginToInstitutionLogin(data.resource);
    yield put(actions.qcsInstitutionLoginActionResponse(login));

    // TODO: This could be done in a generalized utility method
    const { impactedResources } = data;
    if (impactedResources?.accounts) {
      const accounts = accountsTransformers.transformQcsAccountsToAccounts(impactedResources.accounts);
      yield put(accountsActions.applyAccountsChanges(accounts));
    }

    yield call(resolve);
  } catch (error) {
    log.error(`Error performing QCS account action ${qcsAction}`, error);
    yield put(actions.qcsInstitutionLoginActionResponse(error));
    yield call(reject, error);
  }
}

// =============================================================================
// Watchers
// =============================================================================

const mkResourceConfig = (successAction, failureAction) => resourceStoreTypes.mkQcsSyncResourceConfig({
  resourceName: 'institution-login',
  resourceBaseUrl: '/institution-logins',
  getLastSyncDate,

  transformResponseToResources: transformers.transformResponseToInstitutionLogins,
  transformResourceToRequestData: transformers.transformInstitutionLoginToRequestData,

  successAction,
  failureAction,
});

function* getInstitutionLoginsActionWatcher() : Generator<*, *, *> {
  // using a vanilla saga pattern so we only process a single get request
  //   - takeEvery would process every request
  //   - takeLatest would cancel any current request and start request again
  while (true) {
    const action = yield take(actions.getInstitutionLogins);

    const resourceConfig = mkResourceConfig(actions.getInstitutionLoginsSuccess, actions.getInstitutionLoginsFailure);
    yield call(resourceSagaUtils.qcsSyncGetResources, resourceConfig, action);
  }
}

export function* getInstitutionLoginSuccessActionWatcher(): Generator<*, *, *> {
  const action = yield take(actions.getInstitutionLoginsSuccess);

  const institutions = yield select(getInstitutionsById);
  const fetchInstitutionIds = action.payload.resources.reduce((institutionIds, login) => {
    if (login.institutionId && !institutions.has(login.institutionId)) {
      const channelType = login?.channel;
      institutionIds.add({ id: login.institutionId, channelType });
    }
    return institutionIds;
  }, new Set());
  const iterator = fetchInstitutionIds[Symbol.iterator]();
  for (let result = iterator.next(); !result.done; result = iterator.next()) {
    const { id, channelType } = result.value;
    yield put(institutionsActions.getInstitution({ id }, { props: { channelType } }));
  }
}

export function* updateInstitutionLoginActionWatcher(): Generator<*, *, *> {
  const resourceConfig = mkResourceConfig(actions.updateInstitutionLoginSuccess, actions.updateInstitutionLoginFailure);
  yield takeEvery(actions.updateInstitutionLogin, resourceSagaUtils.qcsSyncUpdateResource, resourceConfig);
}

export function* deleteInstitutionLoginActionWatcher(): Generator<*, *, *> {
  const resourceConfig = mkResourceConfig(actions.deleteInstitutionLoginSuccess, actions.deleteInstitutionLoginFailure);
  yield takeEvery(actions.deleteInstitutionLogin, resourceSagaUtils.qcsSyncDeleteResource, resourceConfig);
}

export function* deleteInstitutionLoginSuccessActionWatcher(): Generator<*, *, *> {
  yield takeEvery(actions.deleteInstitutionLoginSuccess, triggerGetAccountsAction);
}

export function* qcsInstitutionLoginActionActionWatcher(): Generator<*, *, *> {
  yield takeLatest(actions.qcsInstitutionLoginAction, doInstitutionLoginAction);
}

// =================================================================================================
// Refresh Accounts
// =================================================================================================

// poll every 3 seconds up to a total of 3 minutes and 30 seconds (seconds are buffer over QCS timeouts)
const REFRESH_POLL_INTERVAL = 3;
const REFRESH_POLL_ATTEMPTS = 210 / REFRESH_POLL_INTERVAL;

function* refreshPoll(url, institutionLoginId, meta, loginsToRefreshTrackingData): Generator<*, *, *> {
  assert(url, 'polling url is invalid - that should never happen...');
  let response;
  let numTimesLeftToPoll = REFRESH_POLL_ATTEMPTS;
  while (numTimesLeftToPoll > 0) {
    try {
      response = yield call(qcsAxios.get, url);
      log.log('Polling for refresh status', response.data);

      const refreshStatus = transformers.transformResponseToRefreshStatus(response.data);
      log.log('Status after transforming...', refreshStatus);

      // send refresh status
      yield put(actions.refreshAccountsStatuses(refreshStatus));

      let userActionIsPending = yield select(isUserActionPending);
      const { institutionLogins } = refreshStatus;
      for (let i = 0; i < institutionLogins.size; ++i) {
        const login = institutionLogins.get(i);
        if (login.status === 'ACTION_REQUIRED') {
          const userActionAlreadyQueued = yield select(hasUserActionBeenQueued, login.id);
          log.log('Refresh login status has action required', login, userActionAlreadyQueued);
          if (!userActionAlreadyQueued && login.aggregators && login.aggregators.size > 0) {

            // todo: currently QCS only supports a single aggregator per login, will need to
            // todo: fix when QCS supports multiple aggregators per login
            const aggregator = login.aggregators.get(0);

            // todo: this should be checking aggregator.aggStatus === 'MFA_CHALLENGE_READY_FOR_USER' but QCS isn't setting this
            if (aggregator && aggregator.mfaChallenges) {
              const mfaChallenge = transformMfaResponseToMfaChallenge(
                login.id, aggregator.mfaChallenges, login.submitUrl ? login.submitUrl : ''
              );

              const institutionLogin = yield select(getInstitutionLoginForId, mfaChallenge.institutionLoginId);
              const mfaChallengeDialogData = mkRootUiData({
                id: uuid(),
                type: DIALOG_TYPE_ACCOUNTS_DISCOVERY,
                allowNesting: !userActionIsPending,   // ok to nest MFA dialog unless we already a user action pending
                props: ImmutableMap(mkAccountDiscoveryDialogProps({
                  mode: 'PROVIDE-MFA',
                  institutionLogin,
                  mfaChallenge,
                })),
              });

              log.log('Triggering MFA', mfaChallenge);
              yield put(createDialog(mfaChallengeDialogData));
              yield put(actions.refreshAccountsTriggeredMfa(mfaChallenge.institutionLoginId));
              userActionIsPending = true;
            }
          }
        } else if (!login.isProcessing && loginsToRefreshTrackingData.has(login.id)) {
          const data = loginsToRefreshTrackingData.get(login.id);
          loginsToRefreshTrackingData.delete(login.id);
          if (login.status === 'OK') {
            tracker.track(tracker.events.refreshSuccess, data);
          } else {
            const aggregator = login.aggregators?.get(0);
            const trackingData = { ...data,
              error_code: aggregator?.aggStatus,
              agg_status_code: aggregator?.cpAggStatus,
              agg_status_detail: aggregator?.cpAggStatusDetail,
            };
            tracker.track(tracker.events.refreshError, trackingData);
          }
        }
      }

      if (!userActionIsPending && !response.data.isProcessing) {
        yield put(actions.refreshAccountsCompleted({ institutionLoginId, status: 'DONE_PROCESSING' }, meta));

        // server is done processing and we are not waiting for an MFA so stop polling
        // todo: can maybe optimize by not polling while waiting for MFA but need to restart once MFA is submitted
        break;
      }

      numTimesLeftToPoll -= 1;
      log.log(`Number of times left to poll is ${numTimesLeftToPoll} #refresh`);
      yield delay(REFRESH_POLL_INTERVAL * 1000);
    } catch (error) {
      log.log('How did we get here...', error);
      yield put(actions.refreshAccountsCompleted({ institutionLoginId, status: 'FAILED_WHEN_POLLING' }, meta));
      // trackRefreshErrors('FAILED_WHEN_POLLING', loginsToRefreshTrackingData);
      break;
    }
  }

  if (numTimesLeftToPoll === 0) {
    const idsRefreshingWithUserActionsPending = yield select(getIdsRefreshingWithUserActionsPending);
    if (idsRefreshingWithUserActionsPending.size > 0) {
      yield put(createDialog(mkRootUiData({
        type: REFRESH_TIMEOUT_DIALOG,
        props: ImmutableMap(mkRefreshTimeoutDialogProps({
          dialogIdsToClose: idsRefreshingWithUserActionsPending.toList(),
          confirmationDialogProps: mkConfirmationDialogProps({
            content: 'The server terminated the refresh operation due to length of time.',
            confirmLabel: 'retry',
            denyLabel: 'cancel',
            confirmAction: actions.refreshAccounts(),
          }),
        })),
      })));
    }
    yield put(actions.refreshAccountsCompleted({ institutionLoginId, status: 'TIMEOUT_WHILE_PROCESSING' }, meta));
    // trackRefreshErrors('TIMEOUT_WHILE_PROCESSING', loginsToRefreshTrackingData);
  }
}

function* isRefreshCancelledForLogin(login) {
  return yield select(wasUserActionCancelled, login.id);
}

export function* refreshAccounts(action: any): Generator<*, *, *> {
  const { payload: data, meta } = action;
  const institutionLoginId = data != null ? data.institutionLoginId : null;

  const loginsToRefresh = [];
  if (institutionLoginId) {
    const login = yield select(getInstitutionLoginForId, data.institutionLoginId);
    if (login) {
      const refreshCancelledForLogin = yield* isRefreshCancelledForLogin(login);
      if (!refreshCancelledForLogin) {
        loginsToRefresh.push(login);
      }
    }
  } else {
    const loginsById = yield select(getInstitutionLoginsById);

    const allLogins = loginsById.valueSeq().toArray();
    for (let i = 0; i < allLogins.length; ++i) {
      const login = allLogins[i];
      const refreshCancelledForLogin = yield* isRefreshCancelledForLogin(login);
      if (!refreshCancelledForLogin) {
        loginsToRefresh.push(login);
      }
    }
  }

  if (loginsToRefresh.length === 0) {
    yield put(actions.refreshAccountsCompleted({ institutionLoginId, status: 'NO_ACCOUNTS_TO_REFRESH' }, meta));
    return;
  }

  const precheckPassed = yield* preRefreshAccountsCheck(loginsToRefresh, action);
  if (!precheckPassed) {
    yield put(actions.refreshAccountsPrecheck());
    return;
  }

  const loginsToRefreshTrackingData = new Map();
  for (let i = 0; i < loginsToRefresh.length; i++) {
    const login = loginsToRefresh[i];

    const aggregator = yield select(getTrackingAggregator, login.id);
    const institution = login.institutionId ? yield select(institutionsSelectors.getInstitution, login.institutionId) : null;
    const trackingData = {
      aggregator,
      // initiator
      institution_login_id: login.id,
      institution_id: login.institutionId,
      institution_name: institution?.name ? institution.name : 'unknown',
    };
    loginsToRefreshTrackingData.set(login.id, trackingData);

    tracker.track(tracker.events.refreshStart, trackingData);
  }

  // special case when refreshing a single institution login
  let requestUrl;
  if (loginsToRefresh.length === 1) {
    requestUrl = `${getEnvironmentConfig().services_url}/institution-logins/${loginsToRefresh[0].id}/refresh`;
  } else {
    requestUrl = `${getEnvironmentConfig().services_url}/institution-logins/refresh`;
  }

  const credentialsBlobsForRefreshingLogins = yield select(getCredentialBlobsForRefreshingLogins);
  const credentialsForRefreshingLogins = yield select(getCredentialsForRefreshingLogins);
  const requestBody = transformRefreshingLoginsToRequestData(
    loginsToRefresh,
    credentialsBlobsForRefreshingLogins,
    credentialsForRefreshingLogins
  );

  let pollingReference = '';

  try {
    const response = yield call(qcsAxios.post, requestUrl, requestBody);
    log.log('Refresh accounts response is', response.data);

    pollingReference = response.data.pollingReference;
    log.log('pollingReference=', pollingReference);
  } catch (error) {
    log.log('Error refreshing accounts', error);
    yield put(actions.refreshAccountsCompleted({ institutionLoginId, status: 'FAILED_WHEN_POLLING' }, meta));
    // trackRefreshErrors('FAILED_WHEN_POLLING', loginsToRefreshTrackingData);

    // if we failed to kickoff refresh, were done
    return;
  }

  // poll until refresh is ready or cancel action
  if (pollingReference && pollingReference.length && pollingReference.length > 0) {
    requestUrl = `${getEnvironmentConfig().services_url}${pollingReference}`;
    yield* refreshPoll(requestUrl, institutionLoginId, meta, loginsToRefreshTrackingData);
  } else {
    yield put(actions.refreshAccountsCompleted({ institutionLoginId, status: 'DONE_PROCESSING' }, meta));
    // trackRefreshErrors('DONE_PROCESSING', loginsToRefreshTrackingData);
  }
}

export function* refreshAccountsWatcher(): Generator<*, *, *> {
  // using a vanilla saga pattern so we only process a single get request
  //   - takeEvery would process every request
  //   - takeLatest would cancel any current request and start request again
  while (true) {
    const action = yield take(actions.refreshAccounts);
    yield call(refreshAccounts, action);
  }
}

export function* submitMfaResponse({ payload: { mfaResponse }, meta }: {payload: any, meta: any}): Generator<*, *, *> {
  const { resolve, reject } = meta;

  // TODO: If user submits an MFA response but we are no longer polling, perhaps we should just fail...

  const requestUrl = `${getEnvironmentConfig().services_url}/${mfaResponse.submitUrl}`;
  const requestBody = {
    institutionLoginId: mfaResponse.institutionLoginId,
    mfaResponses: mfaResponse.answers.map((answer) => ({
      key: answer.id,
      response: answer.value,
    })),
  };

  try {
    yield call(qcsAxios.post, requestUrl, requestBody);

    yield put(actions.refreshAccountsMfaFormSubmitResponse({ institutionLoginId: mfaResponse.institutionLoginId }, cpMetaForResponseAction(meta)));
    yield call(resolve, mfaResponse.institutionLoginId);

  } catch (error) {

    log.log('Received error while submitting MFA', mfaResponse.institutionLoginId, error);
    const errorResponse = new RefreshAccountsMfaFormSubmitError(error, mfaResponse.institutionLoginId);

    yield put(actions.refreshAccountsMfaFormSubmitResponse(errorResponse, cpMetaForResponseAction(meta)));
    yield call(reject, errorResponse);
  }
}

export function* refreshAccountsMfaFormSubmitActionWatcher(): Generator<*, *, *> {
  yield takeEvery(actions.refreshAccountsMfaFormSubmit, submitMfaResponse);
}

export default [
  getInstitutionLoginsActionWatcher,
  updateInstitutionLoginActionWatcher,
  deleteInstitutionLoginActionWatcher,
  deleteInstitutionLoginSuccessActionWatcher,
  getInstitutionLoginSuccessActionWatcher,
  qcsInstitutionLoginActionActionWatcher,
  refreshAccountsWatcher,
  refreshAccountsMfaFormSubmitActionWatcher,
];


