import createCachedSelector, { LruCacheObject } from 're-reselect';
import { createFlexEqualSelector } from 'utils/selectorsHelpers';
import { createSelector } from 'reselect';
import moment from 'moment';
import { Map as ImmutableMap, List, OrderedSet } from 'immutable';

import { accountsSelectors } from '@quicken-com/react.flux.accounts';

import { getLoadPending as getTransactionsLoadPending, getTransactionsById } from 'data/transactions/selectors';

import { getLogger } from '@quicken-com/react.utils.core';
import { REDUCER_KEY } from './reducer';

const log = getLogger('data/payees/selectors');

export const getLoadPending = (state) => accountsSelectors.getLoadPending(state) || getTransactionsLoadPending(state);

export const getPayeesStore = (state) => state[REDUCER_KEY];

export const getPayeeTransactionsById = (state) => {
  const newState = getPayeesStore(state);
  return newState.payeesByAccountId;
};

export const getPayeesNameList = createSelector(
  getPayeeTransactionsById,
  (payeesById) => {

    let ret = null;
    const payeeNames = ImmutableMap().withMutations((map) => {
      payeesById.forEach((account) => account.forEach((payee) =>
        map.set(payee.name, { id: payee.id, name: payee.name, count: payee.count + (map.get(payee.name)?.count || 0) })));
      return map;
    });
    if (payeeNames) {
      ret = List(payeeNames.toList().toJS()).sort((a, b) => b.count - a.count);
    }
    return ret;
  }
);

export const getPayeesForAccountsAsList = createSelector(
  (state, props) => getPayeesForAccounts(state, props),
  (payeesForAccounts) => {
    if (payeesForAccounts) {
      return payeesForAccounts.toList();
    }
    return null;
  }
);


/**
 * Get payee data for the requested account node
 */

export const getPayeesForAccounts = createCachedSelector(
  (state) => ({
    obj: getPayeeTransactionsById(state),
    deep: false,
  }),
  (state) => ({
    obj: accountsSelectors.getAccountsById(state),
    deep: false,
  }),
  (state, props) => ({
    obj: props && props.accountIds && props.accountIds.size > 0 ? props.accountIds.sort() : undefined,
    deep: true,
  }),
  (
    { obj: payeeTransactionsById },
    { obj: accountsById },
    { obj: accountIds },
  ) => {
    log.log('Selector - Getting Payees for ', accountIds);

    let payeeList = ImmutableMap();

    let accounts = accountIds;
    if (!accountIds) {
      accounts = accountsById.map((x) => x.id);
    }
    accounts.forEach((accountId) => {

      const pList = payeeTransactionsById.get(accountId);

      if (pList) {
        payeeList = payeeList.mergeWith((a, b) =>
          moment(b.txn.postedOn).isAfter(moment(a.txn.postedOn)) ? b : a, pList);
      }
    });

    // Now we trim the payee list down if it is too large based on dates
    payeeList = payeeList.sort((a, b) =>
      moment(b.txn.postedOn).unix() - moment(a.txn.postedOn).unix());

    // Now we sort it by payee name so the list is alphabetized
    payeeList = payeeList.take(150).sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });

    return payeeList;
  },
)(
  (_state, props) => `${JSON.stringify(props && props.accountIds && props.accountIds.size > 0 ? props.accountIds.sort() : undefined)}`,
  {
    cacheObject: new LruCacheObject({ cacheSize: 5 }),
    selectorCreator: createFlexEqualSelector,
  },
);

export const getPayeeByName = createCachedSelector(
  (state) => getPayeesForAccounts(state),
  (state, name) => name && name.toLowerCase(),
  (payees, nameLowerCase) => payees.find((payee) => (payee.name && payee.name.toLowerCase()) === nameLowerCase)
)(
  (state, name) => `${name && name.toLowerCase()}`,
  {
    cacheObject: new LruCacheObject({ cacheSize: 10 }),
  }
);

export const getPayeesForTransactions = createCachedSelector(
  (state, transactions) => transactions || getTransactionsById(state),
  (transactions) => new OrderedSet().withMutations((payees) => transactions.forEach((transaction) => {
    if (transaction.payee && transaction.payee.length) {
      payees.add(transaction.payee.toLowerCase());
    }
  }))
)(
  (state, transactions) => transactions && transactions.hashCode(),
  {
    cacheObject: new LruCacheObject({ cacheSize: 10 }),
  }
);

export const getPayeesForTransactionsAndKey = createCachedSelector(
  (state, props) => getPayeesForTransactions(state, props.transactions),
  (state, props) => props.key && props.key.toLowerCase(),
  (payees, keyLowerCase) => payees.filter((payee) => (payee.includes(keyLowerCase)))
)(
  (state, props) => `${props.transactions && props.transactions.hashCode()}:${props.key && props.key.toLowerCase()}`,
  {
    cacheObject: new LruCacheObject({ cacheSize: 10 }),
  }
);





