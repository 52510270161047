import React from 'react';
import { connect } from 'react-redux';
import compose from 'utils/compose';
import PropTypes from 'prop-types';
import { doAccountDiscovery as doAccountDiscoveryAction } from 'components/Dialogs/AccountDiscovery/actions';
import { withRouter } from 'react-router-dom';

import { scheduledTransactionsSelectors } from '@quicken-com/react.flux.scheduled-transactions';
import { accountsSelectors } from '@quicken-com/react.flux.accounts';
import { tracker } from '@quicken-com/react.utils.core';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';

import { getBudgets } from 'data/budgets/budgetsSelectors';
import TaskListProgress from 'components/TaskListProgress';


let globalStateOpen = true;

class SetupStatusBar extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      open: globalStateOpen,
    };
  }

  setupItems = () => {
    const items = [];
    items.push(
      {
        name: 'addAccounts',
        title: 'Add Accounts',
        completed: this.props.accountsById.size > 0,
      }
    );
    if (this.props.useScheduledTransactions) {
      items.push(
        {
          name: 'connectBills',
          title: 'Connect Your Bills',
          completed: !this.props.isScheduledTransactionsLoading && this.props.scheduledTransactionsById &&
            this.props.scheduledTransactionsById.size && this.props.scheduledTransactionsById.filter((x) => x.isUserVerified).size > 0,
        }
      );
    }
    items.push(
      {
        name: 'createBudget',
        title: 'Create a Budget',
        completed: this.props.budgetsById && this.props.budgetsById.size > 0,
      }
    );
    return items;
  };

  onClick = (e, name) => {
    switch (name) {
      case 'addAccounts':
        if (!this.props.titleOnTop) {
          tracker.track(tracker.events.dashboardAddAccount);
        } else {
          tracker.track(tracker.events.wizardTopMenuAccounts);
        }
        this.props.history.push('/getting-started-webfirst?page=addAccounts');
        break;

      case 'connectBills':
        if (!this.props.titleOnTop) {
          tracker.track(tracker.events.dashboardBillscout);
        } else {
          tracker.track(tracker.events.wizardTopMenuBillscout);
        }
        this.props.history.push('/getting-started-webfirst?page=connectBills');
        break;

      case 'createBudget':
        if (!this.props.titleOnTop) {
          tracker.track(tracker.events.dashboardAddBudget);
        } else {
          tracker.track(tracker.events.wizardTopMenuBudgets);
        }
        this.props.history.push('/getting-started-webfirst?page=createBudget');
        break;

      default:
        break;
    }
  };

  render() {

    const { titleOnTop, classes, selected, onOpenToggle, name } = this.props;

    return (
      <TaskListProgress
        setupItems={this.setupItems()}
        selected={selected}
        open={this.state.open}
        titleOnTop={titleOnTop}
        onOpenToggle={titleOnTop ? null : (e, val) => {
          globalStateOpen = val;
          this.setState({ open: val });
          if (onOpenToggle) {
            onOpenToggle(val);
          }
        }}
        onClick={this.onClick}
        classes={classes}
        name={name}
      />
    );
  }
}

SetupStatusBar.propTypes = {
  // doAccountDiscovery: PropTypes.func,
  isScheduledTransactionsLoading: PropTypes.bool,
  history: PropTypes.object,
  titleOnTop: PropTypes.bool,
  selected: PropTypes.string,
  onOpenToggle: PropTypes.func,
  name: PropTypes.string,

  classes: PropTypes.object,

  accountsById: PropTypes.object,
  scheduledTransactionsById: PropTypes.object,
  budgetsById: PropTypes.object,
  useScheduledTransactions: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    accountsById: accountsSelectors.getAccountsById(state),
    scheduledTransactionsById: scheduledTransactionsSelectors.getScheduledTransactions(state),
    budgetsById: getBudgets(state),
    isScheduledTransactionsLoading: scheduledTransactionsSelectors.getIsLoading(state),
    useScheduledTransactions: featureFlagsSelectors.getFeatureFlags(state).get('scheduledTransactions'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    doAccountDiscovery: () => dispatch(doAccountDiscoveryAction({ initiator: 'setup status bar' })),
  };
}

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
)(SetupStatusBar));

