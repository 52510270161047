import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getIsOffline } from 'data/app/selectors';
import { bumpSelectors, bumpInterface, bumpTypes, bumpActions } from '@quicken-com/react.flux.bump';
import { authActions, authSelectors } from '@quicken-com/react.flux.auth';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';

const BumpConnector = () => {
  const accessToken = useSelector(authSelectors.getAccessToken);
  const bumpRequireAuthentication = useSelector(bumpSelectors.doesBumpRequireAuthentication);
  const bumpRequiresOpening = useSelector(bumpSelectors.doesBumpRequireOpening);
  const datasetId = useSelector(authSelectors.getDatasetId);
  const featureFlagsError = useSelector(featureFlagsSelectors.getError);
  const featureFlagsLoadPending = useSelector(featureFlagsSelectors.getLoadPending);
  const isOnline = !useSelector(getIsOffline);
  const refreshToken = useSelector(authSelectors.getRefreshToken);
  const bumpEnabled = useSelector((state) => featureFlagsSelectors.getFeatureFlag(state, 'webSocketQcs'));

  const dispatch = useDispatch();

  useEffect(() => {
    if (!featureFlagsLoadPending || featureFlagsError) {
      if (bumpEnabled) {
        if (bumpRequiresOpening) {
          if (datasetId && accessToken && isOnline) {
            bumpInterface.open('Open from React UseEffect');
          } else if (!accessToken && refreshToken) {
            dispatch(authActions.exchangeRefreshToken());
          }
        }
      } else {
        bumpInterface.close(bumpTypes.CLOSE_BUMP_DISABLED, 'Close from React UseEffect');
      }
    }
  }, [
    bumpEnabled,
    featureFlagsError,
    featureFlagsLoadPending,
    datasetId,
    accessToken,
    isOnline,
    dispatch,
    bumpRequiresOpening,
    refreshToken,
  ]);

  useEffect(() => {
    if (bumpRequireAuthentication) {
      if (datasetId && accessToken) {
        dispatch(bumpActions.sendBumpMessage({
          qcsDatasetId: datasetId,
          token: `Bearer ${accessToken}`,
        }));
      } else if (!accessToken && refreshToken) {
        dispatch(authActions.exchangeRefreshToken());
      }
    }
  }, [accessToken, bumpRequireAuthentication, datasetId, dispatch, refreshToken]);

  return null;
};

export default BumpConnector;


