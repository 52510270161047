// @flow
import React from 'react';

import { Formik, FormikBag, Field } from 'formik';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';

import ChatBubble from '@mui/icons-material/ChatBubbleOutlineRounded';
import InputAdornment from '@mui/material/InputAdornment';
import LockIcon from '@mui/icons-material/LockRounded';
import MailIcon from '@mui/icons-material/MailOutlineRounded';
import PhoneIcon from '@mui/icons-material/PhoneRounded';

import FormikSelect from 'components/Formik/Select';
import FormikTextField from 'components/Formik/TextField';

import StdDialogActions from 'components/Dialogs/StdDialogActions';
import StdDialogContent from 'components/Dialogs/StdDialogContent';
import StdDialogFormik from 'components/Dialogs/StdDialogFormik';

import { mkMfaResponse, mkMfaAnswer } from 'data/institutionLogins/types';
import type { MfaResponse, MfaChallenge, MfaChallengeItem, InstitutionLogin } from 'data/institutionLogins/types';

import styles from './styles';
import TertiaryTrust from '../TertiaryTrust';

type Props = {
  mfaChallenge: MfaChallenge,
  scope: string,

  onCancel: () => void,
  onSubmit: (MfaResponse, () => void, () => void) => void,
  isSubmitting: boolean,

  fullAddAccountsDialog: boolean,

  institutionLogin: InstitutionLogin,

  classes: Object,
}

type State = {
  key: ?string,
  value: ?string,
}

class MfaChallengeForm extends React.PureComponent<Props, State> {
  formik: FormikBag = null;
  mountRef = { current: false };

  constructor(props) {
    super(props);
    this.mountRef = React.createRef();
    this.mountRef.current = false;
    const challenger = this.props.mfaChallenge.challenges;
    let value = null;
    let key = null;
    if (challenger) {
      const firstChallenge = challenger.first();
      if (firstChallenge && firstChallenge.answerChoices) {
        value = firstChallenge.answerChoices.first().displayText;
        key = firstChallenge.key;
      }
    }
    this.state = {
      value,
      key,
    };
  }

  componentDidMount() {
    this.mountRef.current = true;
    if (this.state.key && this.state.value) {
      this.formik?.setFieldValue(this.state.key, this.state.value);
    }
  }

  componentWillUnmount() {
    this.mountRef.current = false;
  }

  handleCancel = () => {
    const { onCancel } = this.props;

    if (onCancel) {
      onCancel({
        step: 'mfa',
      });
    }
  };

  handleGridChange = (event: Object) => {
    this.setState({ value: event.target.value });
    this.formik?.setFieldValue(event.target.name, event.target.value);
  };

  handleSubmit = (values, _actions, mfaChallenge, onSubmit) => {
    const mfaResponse = mkMfaResponse({
      institutionLoginId: mfaChallenge.institutionLoginId,
      answers: mfaChallenge.challenges.map((challenge) => (
        mkMfaAnswer({
          id: challenge.key,
          value: values[challenge.key],
        })
      )),
      submitUrl: mfaChallenge.submitUrl,
    });
    if (onSubmit) {
      onSubmit(
        mfaResponse,
        () => { if (this.mountRef.current) this.formik?.setSubmitting?.(false); },
        () => { if (this.mountRef.current) this.formik?.setSubmitting?.(false); }
      );
    }
  };

  generateLine = (displayText) => {
    const { classes } = this.props;
    const text = /text/i.test(displayText);
    const email = /email/i.test(displayText);
    const call = /call/i.test(displayText) || /voice/i.test(displayText);
    const number = /\bXXX-XXX-(\d*)/i.exec(displayText);
    const mailDomain = /([a-zA-Z0-9*._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi.exec(displayText);
    if (text) {
      return (
        <div className={classes.messageLine}>
          <ChatBubble />
          {
            number ?
              <>
                <Typography variant="body2" className={classes.dropdownSpace} noWrap>
                  Text
                </Typography>
                <Typography variant="body2" className={classes.grayText} noWrap>
                  {number ? number[0] : ''}
                </Typography>
              </>
              :
              <Typography variant="body2" className={classes.dropdownSpace} noWrap>
                {displayText}
              </Typography>
          }
        </div>
      );
    }
    if (call) {
      return (
        <div className={classes.messageLine}>
          <PhoneIcon />
          {
            number ?
              <>
                <Typography variant="body2" className={classes.dropdownSpace}>
                  Call
                </Typography>
                <Typography variant="body2" className={classes.grayText}>
                  {number ? number[0] : ''}
                </Typography>
              </>
              :
              <Typography variant="body2" className={classes.dropdownSpace} noWrap>
                {displayText}
              </Typography>
          }
        </div>
      );
    }
    if (email) {
      return (
        <div className={classes.messageLine}>
          <MailIcon />
          {
            mailDomain ?
              <>
                <Typography variant="body2" className={classes.dropdownSpace}>
                  Email
                </Typography>
                <Typography variant="body2" className={classes.grayText}>
                  {mailDomain ? mailDomain[0] : ''}
                </Typography>
              </>
              :
              <Typography variant="body2" className={classes.dropdownSpace} noWrap>
                {displayText}
              </Typography>
          }
        </div>
      );
    }
    return (
      <div className={classes.messageLine}>
        <Typography variant="body2" noWrap>
          {displayText}
        </Typography>
      </div>
    );
  };

  renderAnswerField = (key) => (
    <Field
      component={FormikTextField}
      fullWidth
      variant="outlined"
      name={key}
      placeholder="Answer"
      style={{ paddingRight: '20px' }}
      InputProps={{
        autoFocus: true,
        endAdornment: (
          <InputAdornment position="end">
            <LockIcon style={{ color: '#706e6b' }} />
          </InputAdornment>
        ),
      }}
    />
  );

  renderChallenge = (challenge: MfaChallengeItem) => {
    const { classes, mfaChallenge } = this.props;
    const options = challenge.answerChoices && (mfaChallenge.challenges.size === 1);
    return (
      <div className={classes.challenge} key={challenge.key}>
        {challenge.base64Image && (
          <Box display="flex" justifyContent="center">
            <img alt="mfa" src={`data:image/jpeg;base64, ${challenge.base64Image}`} />
          </Box>
        )}
        <Typography variant="body2" className={options ? classes.bigQuestion : classes.littleQuestion}>
          {challenge.question}
        </Typography>
        {challenge.answerChoices ? this.renderAnswerChoices(challenge.key, challenge.answerChoices) : this.renderAnswerField(challenge.key)}
      </div>
    );
  };

  renderAnswerChoices = (key, choices) => {
    const { classes } = this.props;
    return choices.size > 3 ?
      <Field
        component={FormikSelect}
        fullWidth
        variant="outlined"
        value={this.state.value}
        name={key}
        input={
          <OutlinedInput
            id="key"
            labelWidth={0}
          />
        }
        MenuProps={{
          classes: {
            paper: classes.menu,
          },
        }}
      >
        {choices.map((choice) => (
          <MenuItem key={choice.displayText} value={choice.displayText}>
            {this.generateLine(choice.displayText)}
          </MenuItem>
        ))}
      </Field>
      :
      <Field
        name={key}
        value={this.state.value}
        render={({ field }) => (
          <RadioGroup
            {...field}
            aria-label={key}
            name={key}
            value={this.state.value}
            onChange={this.handleGridChange}
            className={classes.answerGrid}
          >
            {choices.map((choice) => (
              <FormControlLabel
                key={choice.displayText}
                value={choice.displayText}
                control={<Radio />}
                label={
                  <div className={classes.iconLine}>
                    {this.generateLine(choice.displayText)}
                  </div>
                }
                className={classes.formLabel}
              />
            ))}
          </RadioGroup>
        )}
      />;
  };

  renderForm = (formikBag) => {
    const { fullAddAccountsDialog, mfaChallenge, classes } = this.props;
    const { handleSubmit: formikHandleSubmit, isSubmitting } = formikBag;
    this.formik = formikBag;

    const cancelButtonLabel = fullAddAccountsDialog ? 'Back' : 'Cancel';
    const submitButtonLabel = fullAddAccountsDialog ? 'Continue' : 'Submit';

    return (
      <StdDialogFormik onSubmit={formikHandleSubmit}>
        <StdDialogContent
          flexDirection="column"
          className={fullAddAccountsDialog ? classes.lgFormContent : ''}
        >
          {mfaChallenge.challenges.map((challenge) => this.renderChallenge(challenge))}
        </StdDialogContent>
        <StdDialogActions
          primaryDisabled={isSubmitting}
          primaryLabel={submitButtonLabel}
          secondaryLabel={cancelButtonLabel}
          secondaryOnClick={!fullAddAccountsDialog && this.handleCancel}
          secondaryVariant="outlined"
          tertiaryNode={fullAddAccountsDialog && <TertiaryTrust />}
        />
      </StdDialogFormik>
    );
  };

  render() {
    const { mfaChallenge, onSubmit } = this.props;

    if (!mfaChallenge) {
      return null;
    }

    return (
      <Formik
        onSubmit={(values, actions) => this.handleSubmit(values, actions, mfaChallenge, onSubmit)}
        initialValues={{}}
      >
        {this.renderForm}
      </Formik>
    );
  }
}

export default withStyles(styles, { name: 'MfaChallengeForm' })(MfaChallengeForm);
