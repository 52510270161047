import React, { useEffect, useMemo, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { createRenameRule, updateRenameRule } from 'data/renameRules/actions';
import { mkRenameRule } from 'data/renameRules/types';
import { normalizePayeeQCS, containsPayeeQCS } from 'data/renameRules/utils';
import { getPayeeApplicableTransactions } from 'data/renameRules/selectors';

import AutoTagsField from 'components/AutoTagsField';
import Dump from 'components/Dump';
import SPayeeField from 'components/SPayeeField';
import QButton from 'components/QButton';
import StaticTransactionList from '../StaticTransactionList';

export const DIALOG_TYPE_RENAME_RULE_EDIT = 'DIALOG_TYPE_RENAME_RULE_EDIT';

const useStyles = makeStyles((theme) => ({
  paperInfo: {
    backgroundColor: theme.palette.color7.opacity30,
    padding: 20,
  },
  paperWarn: {
    backgroundColor: theme.palette.color5.opacity30,
    padding: 20,
  },
  input: {
    maxWidth: '90%',
    width: 500,
    minWidth: 200,
  },
  txnListItem: {
    gridTemplateColumns: '1.5fr 2fr 3fr 2fr 1.5fr',
  },
  redText: {
    color: theme.palette.number.negative,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const RenameRuleForm = React.memo((props) => {
  const { renameRule, transaction, onClose, formikRef, applyRule, setViewExistingTransactions, viewExistingTransactions, ...otherProps } = props;
  const { inferredPayee, payee: statementPayee } = transaction?.cpData || {};

  const dispatch = useDispatch();
  const classes = useStyles();

  const [applyToTransactions, setApplyToTransactions] = useState(applyRule);

  const formik = useFormik({
    initialValues: {
      ...renameRule,
    },
    onSubmit: (values) => {
      const payload = mkRenameRule({
        clientId: uuidv4().toUpperCase(),
        ...values,
        renamePayeeTo: values.renamePayeeTo.trim(),
      });
      if (values.id) {
        dispatch(updateRenameRule(payload, {
          undo: { userMessage: 'Payee rule updated.' },
          props: { renameRule, applyToTransactions },
        }));
      } else {
        new Promise((resolve, reject) => dispatch(createRenameRule(payload, {
          undo: { userMessage: 'Payee rule created.' },
          props: { renameRule, applyToTransactions, transaction },
          resolve,
          reject,
        }))).then((_resp) => {
          onClose?.(undefined, 'create-complete');
        }).catch((_e) => {
          onClose?.(undefined, 'create-error');
        });
      }
      onClose?.(undefined, 'submit');
    },
  });
  useImperativeHandle(formikRef, () => formik, [formik]);

  const renamePayeeApplicableTransactions = useSelector((state) => getPayeeApplicableTransactions(
    state,
    formik.values.renamePayeeFrom || transaction?.payee,
    formik.values.renamePayeeTo,
  ));

  useEffect(() => {
    formik.registerField('renamePayeeFrom', {
      validate: (value) => {
        let error;
        if (!value) {
          error = 'please enter payee pattern';
        } else if (value?.replace(/ /g, '').length < 3) {
          error = 'please enter payee pattern that is at least 3 characters';
        } else if (inferredPayee) {
          const inferredPayeeNormalized = normalizePayeeQCS(inferredPayee);
          if (!containsPayeeQCS(inferredPayeeNormalized, value)) {
            error = 'This rule will not apply to the original payee';
          }
        }
        return error;
      },
    });
    formik.registerField('renamePayeeTo', { validate: (value) => value ? undefined : 'required' });
  }, [formik, inferredPayee]);

  const handlePayeeChange = (_e, newValue) => {
    formik.setFieldValue('renamePayeeTo', newValue);
  };

  const keywords = useMemo(() => formik.values.renamePayeeFrom ? formik.values.renamePayeeFrom.split(' ') : [], [formik.values.renamePayeeFrom]);

  if (viewExistingTransactions) {
    return (
      <Box>
        <Typography variant="body2">
          {'Existing transactions that will be renamed to'}&nbsp;<b>{renameRule.renamePayeeTo}</b>
        </Typography>
        <Box marginTop={1}>
          <StaticTransactionList
            txns={renamePayeeApplicableTransactions.valueSeq()}
            listItemProps={{ className: classes.txnListItem }}
            classes={{ payee: classes.redText }}
          />
        </Box>
      </Box>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit} {...otherProps}>

      <Dump obj={props} />

      <Typography variant="body2">Create a rule to rename future matching transactions.</Typography>

      {transaction && (
        <Box marginTop={3}>
          <Typography variant="subtitle2">Details from transaction</Typography>
        </Box>
      )}

      <Box marginTop={1} maxWidth={632} display="flex" justifyContent={'space-between'}>
        {inferredPayee && (
          <Box>
            <ListItemText
              disableTypography
              primary={<Typography variant="body2" color="textSecondary">Simplifi shows payee as</Typography>}
              secondary={<Typography variant="body2">{inferredPayee}</Typography>}
            />
          </Box>
        )}

        {statementPayee && (
          <Box>
            <ListItemText
              disableTypography
              primary={<Typography variant="body2" color="textSecondary">Appears on your statement as</Typography>}
              secondary={<Typography variant="body2">{statementPayee}</Typography>}
            />
          </Box>
        )}
      </Box>

      <Box marginTop={3}>
        <Typography variant={'body1'}>If the payee or statement name contains (in this order)</Typography>
        <AutoTagsField
          id="renamePayeeFrom"
          value={keywords}
          onChange={(event, value, _reason) => {
            formik.setFieldValue('renamePayeeFrom', value.join(' '));
          }}
          onBlur={formik.handleBlur}
          normalize={normalizePayeeQCS}
          separator=" "
          autoFocus
          label="Keywords"
          placeholder="+ type to add keywords"
          margin="normal"
          variant="outlined"
          error={Boolean(formik.errors.renamePayeeFrom)}
          helperText={formik.errors.renamePayeeFrom || 'Keywords are not case-sensitive.'}
          className={classes.input}
        />
      </Box>

      <Box marginTop={3}>
        <Typography variant={'body1'}>Then rename it to</Typography>
        <SPayeeField
          id="renamePayeeTo"
          defaultValue={formik.values.renamePayeeTo || ''}
          onChange={handlePayeeChange}
          textFieldProps={{
            variant: 'outlined',
            label: 'Payee',
            placeholder: 'Payee',
            error: Boolean((formik.touched.renamePayeeTo || formik.submitCount) && formik.errors.renamePayeeTo),
            fullWidth: false,
            autoFocus: false,
            className: classes.input,
          }}
          popupIcon={transaction ? null : undefined}
        />
      </Box>

      <Box display="flex" alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              disabled={renamePayeeApplicableTransactions.size === 0}
              checked={Boolean(applyToTransactions)}
              onChange={(_event, value) => setApplyToTransactions(value)}
            />
          }
          disabled={renamePayeeApplicableTransactions.size === 0}
          label={`Also rename payee for existing transactions that match (${renamePayeeApplicableTransactions.size})`}
        />
        <QButton
          disabled={renamePayeeApplicableTransactions.size === 0}
          onClick={() => setViewExistingTransactions(true)}
        >
          view transactions
        </QButton>
      </Box>

    </form>
  );
});

RenameRuleForm.propTypes = {
  renameRule: PropTypes.object,
  transaction: PropTypes.object,
  onClose: PropTypes.func,
  formikRef: PropTypes.object,
  applyRule: PropTypes.bool,
  viewExistingTransactions: PropTypes.bool,
  setViewExistingTransactions: PropTypes.func,
};

export default RenameRuleForm;
