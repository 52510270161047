import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import makeStyles from '@mui/styles/makeStyles';
import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    maxWidth: 5150,
    minWidth: 200,
    padding: `${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(2)}`,
    margin: `${theme.spacing(1)} 0`,
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: theme.shadows[3],
    transition: '300ms',
    background: theme.palette.background.default,
    '&:hover': {
      boxShadow: theme.shadows[5],
    },
  },
  secondaryText: {
    color: theme.palette.text.secondary,
  },
  disabled: {
    opacity: 0.5,
  },
}));

const QPanelButton = React.forwardRef((props, ref) => {
  const { title, subtitle, startIcon, hideArrow, className, onClick, ...otherProps } = props;
  const classes = useStyles();

  const handleKeyDown = (e) => {
    if (onClick && e.keyCode === 13) onClick();
  };

  return (
    <ButtonBase
      component={Paper}
      elevation={4}
      onKeyDown={handleKeyDown}
      onClick={onClick}
      classes={{ disabled: classes.disabled }}
      {...otherProps}
      ref={ref}
      className={classNames(classes.button, className)}
      sharedcomponentid={'Q_PANEL_BUTTON'}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" height="100%">
        <Box display="flex">
          {startIcon}
          <Box ml={2} mr={3} display="flex" flexDirection="column" justifyContent="space-between">
            <Typography variant="body1">
              {title}
            </Typography>
            {
              subtitle &&
                <Typography className={classes.secondaryText} variant="body2">
                  {subtitle}
                </Typography>
            }
          </Box>
        </Box>
        {
          !hideArrow &&
            <IconButton size="large">
              <ArrowForwardIosIcon fontSize="small" />
            </IconButton>
        }
      </Box>
    </ButtonBase>
  );
});

QPanelButton.propTypes = {
  hideArrow: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  startIcon: PropTypes.node,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

export default QPanelButton;
