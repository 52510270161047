import { createSelector } from 'reselect';

import { getTransactionsOfTypes, TxnTypes } from 'data/transactions/selectors';

import { REDUCER_KEY } from './reducer';
import { normalizePayeeQCS, containsPayeeQCS } from './utils';

export const getStore = (state) => state[REDUCER_KEY];
export const getLoadPending = (state) => getStore(state).loadPending;
export const getIsLoading = (state) => getStore(state).isLoading;
export const getError = (state) => getStore(state).error;
export const getResourcesById = (state) => getStore(state).resourcesById;
export const getLastSyncDate = (state) => getStore(state).lastSyncDate;

export const getRulesById = createSelector(
  getResourcesById,
  (resourcesById) => resourcesById.sort((a, b) => a.renamePayeeTo?.localeCompare(b.renamePayeeTo)),
);

export const getPayeeApplicableTransactions = createSelector(
  (state) => getTransactionsOfTypes(state, TxnTypes.REGULAR_TXNS),
  (state, payeeFrom) => payeeFrom,
  (state, payeeFrom, payeeTo) => payeeTo,
  (transactions, payeeFrom, payeeTo) => {
    const payeeKeyWords = normalizePayeeQCS(payeeFrom);
    const normalizedPayeeTo = normalizePayeeQCS(payeeTo);
    return transactions.filter((transaction) => {
      const userPayee = normalizePayeeQCS(transaction.payee);
      return containsPayeeQCS(userPayee, payeeKeyWords) && userPayee !== normalizedPayeeTo;
    });
  },
);
