// CORE
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { accountsSelectors } from '@quicken-com/react.flux.accounts';
import { categoriesSelectors } from '@quicken-com/react.flux.categories';
import { chartOfAccountsTypes, chartOfAccountsSelectors } from '@quicken-com/react.flux.chart-of-accounts';
import { transactionsTypes } from '@quicken-com/react.flux.transactions';
import { scheduledTransactionsActions, scheduledTransactionsTypes, scheduledTransactionsUtils } from '@quicken-com/react.flux.scheduled-transactions';

// MUI
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// MUI ICONS
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// DATA
import { useDispatch, useSelector } from 'react-redux';

// CUSTOM
import AmountSpan from 'components/QuickenControls/AmountField/AmountSpan';
import PayeeSpan from 'components/QuickenControls/PayeeField/PayeeSpan';
import CategoryField from 'components/QuickenControls/CategoryField';
import { tracker } from '@quicken-com/react.utils.core';

import RecurrenceSelect from './RecurrenceSelect';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius * 2,
    margin: `${theme.spacing(1)} 0`,
    width: '100%',
    padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.greyScaleDeprecated[7],
  },
  slash: ({ isBill }) => ({
    position: 'absolute',
    left: -8,
    height: 92,
    width: 44,
    backgroundColor: theme.applyOpacityToHex(isBill ? theme.palette.secondary.main : theme.palette.number.positive, 0.2),
    transform: 'rotate(-12deg)',
  }),
  avatar: ({ isBill }) => ({
    height: 32,
    width: 32,
    borderRadius: 4,
    backgroundColor: isBill ? theme.palette.secondary.main : theme.palette.number.positive,
    fontWeight: 600,
  }),
  accountName: {
    paddingLeft: 5,
  },

  verticalStack: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: 252,
    padding: '0 10px',
  },
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },

  payee: {
    flexGrow: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 160,
    width: 160,
  },
  amount: {
    flexShrink: 0,
    color: ({ isBill }) => `${isBill ? theme.palette.primary : theme.palette.number.positive} !important`,
  },
  grey: {
    color: `${theme.palette.greyScaleDeprecated[2]} !important`,
  },
  category: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.1),
    },
  },

  toggleButton: {
    width: 24,
    height: 24,
    borderRadius: theme.shape.borderRadius,
    alignSelf: 'flex-start',
    transition: 0.2,
    '&:hover': {
      boxShadow: `0 1px 6px 1px ${theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.3)}`,
    },
  },

  greenCheck: {
    color: theme.palette.greyScaleDeprecated[7],
    backgroundColor: theme.palette.link.main,
    borderRadius: '50%',
    padding: 2,
  },
  uncheck: {
    color: theme.palette.greyScaleDeprecated[7],
    backgroundColor: theme.palette.greyScaleDeprecated[7],
    borderRadius: '50%',
    border: `2px solid ${theme.palette.greyScaleDeprecated[3]}`,
  },

  faded: {
    backgroundColor: `${theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[3], 0.2)} !important`,
  },
  disabled: {
    backgroundColor: `${theme.palette.greyScaleDeprecated[3]} !important`,
  },
  recurrenceSelectInputClass: {
    paddingRight: '24px !important',
  },
}));

export default function RecurringChip(props) {
  const { model, index, isBill, isGSF } = props;
  const classes = useStyles({ isBill });
  const theme = useTheme();
  const transaction = model.transaction || {};
  const dispatch = useDispatch();

  const [keep, setKeep] = useState(true);
  const [mounted, setMounted] = useState(false);
  // Scheduled Txn Values:
  const negator = scheduledTransactionsUtils.negatorFromType(model.type);

  // state
  const [payee, setPayee] = useState(transaction && transaction.payee);
  const [amount, setAmount] = useState(transaction && transaction.amount);
  const [category, setCategory] = useState(transaction && transaction.coa);
  const [txnIsBill, setTxnIsBill] = useState(transaction && transaction.isBill);
  const [txnIsSubscription, setTxnIsSubscription] = useState(transaction && transaction.isSubscription);
  const [recurrence, setRecurrence] = useState(model && model.recurrence);
  // refs for latest value capture when update/delete on unmount
  const capture = { payee, amount, category, recurrence, keep, isBill: txnIsBill, isSubscription: txnIsSubscription };
  const latestCapture = useRef();
  latestCapture.current = capture;
  // selector data
  const accountsById = useSelector(accountsSelectors.getAccountsById);
  const isCredit = useSelector((state) => chartOfAccountsSelectors.isCreditBill(state, transaction?.accountId, category));
  // derived constants
  const isTransfer = category?.type === 'ACCOUNT' || category?.type === 'BALANCE_ADJUSTMENT';
  const canChangeModelType = isBill && !isCredit && !isTransfer;

  useEffect(() => { // update state values on model change
    setPayee(transaction && payee);
    setAmount(transaction && amount);
    setCategory(transaction && category);
    setRecurrence(model && recurrence);
    setTxnIsBill(transaction && transaction.isBill);
    setTxnIsSubscription(transaction && transaction.isSubscription);
  }, [model]); // eslint-disable-line react-hooks/exhaustive-deps

  // process verification on unmount
  useEffect(() => {
    const timeout = setTimeout(() => setMounted(true), 0);
    return () => {
      const data = latestCapture.current;
      const spreadTxn = transaction.toJS ? transaction.toJS() : transaction;
      dispatch(data.keep ?
        scheduledTransactionsActions.updateScheduledTransaction(scheduledTransactionsTypes.mkScheduledTransaction({
          id: model.id,
          recurrence: data.recurrence,
          transaction: new transactionsTypes.CashFlowTransaction({
            ...spreadTxn,
            payee: data.payee,
            amount: data.amount,
            coa: data.category,
            isBill: data.isBill,
            isSubscription: data.isSubscription,
          }),
          isUserVerified: true,
        }))
        :
        scheduledTransactionsActions.deleteScheduledTransaction(scheduledTransactionsTypes.mkScheduledTransaction({
          id: model.id,
          isUserVerified: true,
          isDeleted: true,
        })));
      timeout && clearTimeout(timeout);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const recurringTypeLabel = useMemo(() => {
    if (!isBill) return 'Income';
    if (isTransfer && !isCredit) return 'Transfer';
    return txnIsSubscription ? 'Subscription' : 'Bill';
  }, [isBill, txnIsSubscription, isCredit, isTransfer]);

  const [firstShownType] = useState(recurringTypeLabel);

  const toggleVerify = () => {
    props.reportFunc(model.id, !keep);
    setKeep(!keep);
  };

  const filterCategories = useCallback((coa) => {
    if (isBill) {
      return categoriesSelectors.isExpenseCat(null, coa.id) || coa.type === 'BALANCE_ADJUSTMENT' || coa.type === 'ACCOUNT';
    }
    return categoriesSelectors.isIncomeCat(null, coa.id);
  }, [isBill]);

  // mix-panel tracking on field blur
  const updatePayee = (val) => {
    if (payee !== val) {
      setPayee(val);
      tracker.track(isGSF ? tracker.events.editModelGSF : tracker.events.editModel, { field: 'payee', type: firstShownType });
    }
  };
  const updateAmount = (val) => {
    if (amount !== val) {
      setAmount(val);
      tracker.track(isGSF ? tracker.events.editModelGSF : tracker.events.editModel, { field: 'amount', type: firstShownType });
    }
  };
  const updateCategory = (rawCOA) => {
    const newCOA = chartOfAccountsTypes.mkChartOfAccount({ id: rawCOA.id, type: rawCOA.type });
    setCategory(newCOA);
    tracker.track(isGSF ? tracker.events.editModelGSF : tracker.events.editModel, { field: 'category', type: firstShownType });
  };

  const updateModelType = (e) => {
    switch (e.target.value) {
      case 'Bill':
        setTxnIsBill(true);
        setTxnIsSubscription(false);
        break;
      case 'Subscription':
        setTxnIsBill(false);
        setTxnIsSubscription(true);
        break;
      default:
        break;
    }
    tracker.track(isGSF ? tracker.events.editModelGSF : tracker.events.editModel, { field: 'type', type: firstShownType });
  };

  const updateRecurrence = (val) => {
    if (recurrence !== val) {
      setRecurrence(val);
      tracker.track(isGSF ? tracker.events.editModelGSF : tracker.events.editModel, { field: 'frequency', type: firstShownType });
    }
  };

  useEffect(() => {
    if (isCredit) {
      setTxnIsBill(true);
      setTxnIsSubscription(false);
    } else if (isTransfer) {
      setTxnIsBill(false);
      setTxnIsSubscription(false);
    }
  }, [isCredit, isTransfer]);

  const typeSelectDisplayProps = useMemo(() => ({ style: { ...theme.typography.body2, paddingTop: 0, paddingBottom: 0 } }), [theme]);

  return (
    <Paper
      className={classes.root}
      elevation={3}
      id={`recurring-chip-${index}`}
      style={{
        opacity: !keep && 0.6,
        transform: mounted ? 'rotate3d(1, 0, 0, 0)' : 'rotate3d(1, 0, 0, -90deg)',
        transition: !keep ? 'opacity 0.2s ease' : `transform 0.5s ease ${parseFloat(index * 0.1).toFixed(3)}s`,
        transformOrigin: '0 0 5px',
      }}
    >
      <Checkbox
        checked={keep}
        onChange={toggleVerify}
      />

      <Box flex={1}>
        <PayeeSpan
          initialValue={payee}
          className={classes.payee}
          onBlur={updatePayee}
          variant="body1"
        />
        <Typography className={classes.accountName} variant={'body2'}>
          {accountsById?.get(model?.transaction.accountId)?.name || ''}
        </Typography>
      </Box>

      <Box flex={1}>
        <CategoryField
          value={category}
          onChange={updateCategory}
          editable
          name={`recurring-chip-${index}`}
          id={`recurring-chip-${index}`}
          createEnabled
          variant="body1"
          margin="none"
          disableUnderline
          filterFn={filterCategories}
          className={classes.category}
        />
        <Typography variant={'body2'} component={'div'}>
          {
            !canChangeModelType ?
              <span>{recurringTypeLabel}</span>
              :
              <Select
                variant="standard"
                SelectDisplayProps={typeSelectDisplayProps}
                disableUnderline
                IconComponent={ExpandMoreIcon}
                onChange={updateModelType}
                value={txnIsSubscription ? 'Subscription' : 'Bill'}
              >
                <MenuItem value="Bill">Bill</MenuItem>
                <MenuItem value="Subscription">Subscription</MenuItem>
              </Select>
          }
        </Typography>
      </Box>

      <Box flex={1} display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
        <AmountSpan
          initialValue={amount}
          negator={negator}
          className={classes.amount}
          onBlur={updateAmount}
          variant="body1"
          showSign={false}
        />
        <RecurrenceSelect
          scheduledTxn={model}
          onChange={updateRecurrence}
          iconComponent={ExpandMoreIcon}
          inputClass={classes.recurrenceSelectInputClass}
        />
      </Box>
    </Paper>
  );
}

RecurringChip.defaultProps = {
  index: 0,
  isBill: true,
};

RecurringChip.propTypes = {
  model: PropTypes.object,
  index: PropTypes.number,
  isBill: PropTypes.bool,
  isGSF: PropTypes.bool,
  reportFunc: PropTypes.func,
};
