// @flow
// CORE
import * as React from 'react';
import { List } from 'immutable';

import type { accountsTypes } from '@quicken-com/react.flux.accounts';

// MUI
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
// MUI ICONS
import LinkIcon from '@mui/icons-material/Link';
import CheckIcon from '@mui/icons-material/DoneRounded';
import IgnoreIcon from '@mui/icons-material/ClearRounded';

// LOCAL
import SelectNestedMenu from 'components/SelectNestedMenu';
import QSelect from 'components/QSelect';
import { ACCOUNT_ACTION_ADD, ACCOUNT_ACTION_IGNORE, mkAccountAction } from '../../../types';
import { useStyles } from './styles';


type Props = {
  name: string,

  linkableAccountsSection1: ?List<accountsTypes.Account>,
  linkableAccountsSection2: ?List<accountsTypes.Account>,
  linkableAccountsSection3: ?List<accountsTypes.Account>,

  // from withStyles
  classes: Object,
};

const AccountActionSelector = (props: Props) => {
  const {
    name = 'action',
    linkableAccountsSection1,
    linkableAccountsSection2,
    linkableAccountsSection3,
    ...other
  } = props;

  const classes = useStyles();

  const renderValue = (action) => {
    switch (action.type) {
      case 'IGNORE':
        return <IgnoreIcon className={classes.ignore} />;
      case 'LINK_TO':
        return <LinkIcon className={classes.link} />;
      default:
        return <CheckIcon className={classes.check} />;
    }
  };

  const renderLinkableAccounts = (linkableAccounts: List<accountsTypes.Account>, divider) => {
    const arr = linkableAccounts.toArray().map((account) => {
      const linkAction = mkAccountAction({
        id: account.id,
        menuItemLabel: account.name,
        accountToLinkTo: account,
      });
      return (
        <MenuItem key={linkAction.id} value={linkAction}>
          {linkAction.menuItemLabel}
        </MenuItem>
      );
    });
    if (divider) {
      arr.push(<Divider key={'divider'} />);
    }
    return arr;
  };

  const renderLinkToAccounts = () => {
    let menuItems = [];
    if (linkableAccountsSection1 && linkableAccountsSection1.size) {
      menuItems = menuItems.concat(renderLinkableAccounts(
        linkableAccountsSection1,
        (linkableAccountsSection2 && linkableAccountsSection2.size) || (linkableAccountsSection3 && linkableAccountsSection3.size)
      ));
    }
    if (linkableAccountsSection2 && linkableAccountsSection2.size) {
      menuItems = menuItems.concat(renderLinkableAccounts(
        linkableAccountsSection2,
        linkableAccountsSection3 && linkableAccountsSection3.size
      ));
    }
    if (linkableAccountsSection3 && linkableAccountsSection3.size) {
      menuItems = menuItems.concat(renderLinkableAccounts(linkableAccountsSection3, false));
    }

    const labeler = <> <LinkIcon className={classes.smallLink} /> Link To </>;

    return !menuItems.length ? null : (
      <SelectNestedMenu key={'LINK_TO'} MenuItemLabel={labeler} selectable={false} value="LINK_TO">
        {menuItems}
      </SelectNestedMenu>
    );
  };

  return (
    <QSelect
      {...other}
      name={name}
      renderValue={renderValue}
      IconComponent="div"
      classes={{
        select: classes.plainBackground,
      }}
    >
      <MenuItem id="addItem" key={ACCOUNT_ACTION_ADD.type} value={ACCOUNT_ACTION_ADD}>
        <CheckIcon className={classes.smallCheck} /> {ACCOUNT_ACTION_ADD.menuItemLabel}
      </MenuItem>
      <MenuItem id="ignoreItem" key={ACCOUNT_ACTION_IGNORE.type} value={ACCOUNT_ACTION_IGNORE}>
        <IgnoreIcon className={classes.smallX} /> {ACCOUNT_ACTION_IGNORE.menuItemLabel}
      </MenuItem>
      {renderLinkToAccounts()};
    </QSelect>
  );
};

export default AccountActionSelector;
