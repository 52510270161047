import { createAction } from 'redux-actions';

export const getPreferencesV2 = createAction('GET_PREFERENCES_V2');
export const getPreferencesV2Success = createAction('GET_PREFERENCES_V2_SUCCESS');
export const getPreferencesV2Failure = createAction('GET_PREFERENCES_V2_FAILURE');

export const updatePreferenceCriteria = createAction('UPDATE_PREFERENCE_CRITERIA', (payload) => payload, (payload, metadata) => metadata);
export const updatePreferenceCriteriaSuccess = createAction('UPDATE_PREFERENCE_CRITERIA_SUCCESS', (payload) => payload, (payload, metadata) => metadata);
export const updatePreferenceCriteriaFailure = createAction('UPDATE_PREFERENCE_CRITERIA_FAILURE');

