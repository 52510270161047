import { Record } from 'immutable';
import { defaultDatasetPreferences, defaultUserPreferences } from './defaults';

export const Preferences = Record(
  {
    shared: {
      user: {
        ...defaultUserPreferences,
      },
      dataset: {
        webApp: {
          ...defaultDatasetPreferences,
        },
      },
    },
    /* these are not yet supported
    client: {
      user: {
      },
      dataset: {
      },
      global: {
      },
    },
    qcs: {},
    */
  }
);

export const PreferencesStore = Record({
  isLoading: undefined,
  loadPending: {
    shared: {
      user: true,
      dataset: true,
    },
  },
  preferences: new Preferences(),
  error: undefined,
});

export const ShowBalance = Object.freeze({
  INCLUDE_PENDING: 'INCLUDE_PENDING',
  BANK_REPORTED: 'BANK_REPORTED',
});
