import { isAcme } from 'isAcme';

export const styles = (theme) => ({
  billMenu: {
    padding: 0,
  },
  billMenuItem: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.shape.padding[1],
  },
  recurringMenuPaper: {
    borderRadius: theme.shape.borderRadius * 2,
  },
  dialogClasses: {
    fullWidth: {
      padding: '10 20 10 20',
      width: '95%',
      maxWidth: '95%',
    },
  },
  panel: {
    padding: '10px 20px 10px 20px',
    background: theme.palette.greyScaleDeprecated[7],
    marginBottom: '9px',
    marginLeft: 10,
  },
  reduceWidth: {
    width: 'fit-content',
  },
  dialogPaper: {
    width: '100%',
  },
  flexRow: {
    display: 'flex',
  },
  flexCol: {
    extend: 'flexRow',
    flexDirection: 'column',
  },
  marginRight: {
    marginRight: 40,
  },
  detailsRow: {
    display: 'flex',
    marginBottom: 2,
  },
  whiteBg: {
    backgroundColor: theme.palette.greyScaleDeprecated[3],
  },

  checkRow: {
    marginTop: 0,
  },
  attachRow: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 10px',
    margin: '8px 0',
    border: `1px solid ${theme.palette.greyScaleDeprecated[5]}`,
    borderRadius: 4,
  },

  txfrRow: {
    marginTop: 15,
    textAlign: 'left',
    width: '100%',
  },
  rowIcon: {
    flex: 1,
    maxWidth: 39,
    marginTop: 'auto',
    marginBottom: 'auto',
    color: theme.palette.greyScaleDeprecated[3],
    paddingBottom: 3,
  },
  fieldContainer: {
    flex: 1,
    maxWidth: 500,
  },
  bankInfoContainer: {
    flex: 1,
    marginTop: 5,
  },
  tagsFieldContainer: {
    marginBottom: 10,
  },
  bankInfoText: {
    color: theme.palette.greyScaleDeprecated[3],
  },
  simpleEditContainer: {
    backgroundColor: isAcme ? theme.palette.background.default : 'unset',
    '&:hover': {
      // background: theme.components.register.fieldHoverColor,
    },
    border: `1px solid ${theme.palette.greyScaleDeprecated[5]}`,
    borderRadius: 4,
    padding: '6px 10px',
  },
  simpleTitles: {
    fontSize: theme.components.register.fontSize.default,
    '&.add-top-margin': {
      marginTop: 10,
    },
  },
  simpleTextField: {
    width: '100%',
    marginTop: 0,
    '& input': {
      fontSize: theme.components.register.fontSize.default,
      color: theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.6),
    },
    '&:focus-within': {
      background: theme.palette.greyScaleDeprecated[7],
      '&:hover': {
        background: theme.palette.greyScaleDeprecated[7],
      },
    },
  },
  textField: {
    background: theme.palette.greyScaleDeprecated[7],
    paddingLeft: 0,
    paddingRight: 0,
    color: 'black',
    paddingBottom: '4px',
    paddingTop: 0,
    marginTop: '0px',
    width: '100%',
    fontSize: '13px',
    '& input': {
      padding: '0px',
      paddingBottom: '4px',
      fontSize: theme.components.register.fontSize.default,
      paddingLeft: 0,
    },
    '&.nudge': {
      marginTop: '1px',
    },
  },
  webFirstContainer: {
    padding: '10px 16px',
    marginBottom: 10,
    borderRadius: 4,
    border: `1px solid ${theme.palette.greyScaleDeprecated[5]}`,
  },
  detailsContainer: {
    marginBottom: 15,
    marginLeft: 4,
    marginRight: 40,
    background: isAcme ? theme.palette.background.paper : theme.palette.greyScaleDeprecated[7],
  },
  addDoc: {
    marginTop: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    border: `1px solid ${theme.palette.greyScaleDeprecated[5]}`,
    borderRadius: 4,
  },
  buttons: {
    color: theme.palette.greyScaleDeprecated[3],
  },
  padAround: {
    marginBottom: 8,
    padding: '8px 4px',
  },
  buttonHolder: {
    textAlign: 'right',
    margin: 'auto',
    marginTop: '10px',
    background: theme.palette.greyScaleDeprecated[7],
  },
  link: {
    fontSize: theme.components.register.fontSize.large,
    verticalAlign: 'inherit',
    marginLeft: 4,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.linkColor,
    '&:active': {
      background: theme.palette.greyScaleDeprecated[5],
    },
    '&:hover': {
      fontWeight: 'bold',
    },
    width: 'auto',
    textAlign: 'left',
    justifyContent: 'left',
  },
  checkboxField: {
    padding: 0,
    paddingRight: 10,
  },
  group: {
    border: `1px solid ${theme.palette.greyScaleDeprecated[5]}`,
    borderRadius: 4,
    padding: 6,
    marginBottom: 8,
    backgroundColor: isAcme ? theme.palette.background.default : 'unset',
  },
  contentRow: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    marginLeft: 0,
  },
  paper: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 60,
    paddingRight: 60,
    margin: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});


