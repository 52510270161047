import { call, take, takeEvery, select, put } from 'redux-saga/effects';

import { resourceStoreTypes, resourceSagaUtils } from '@quicken-com/react.flux.core';
import { tracker } from '@quicken-com/react.utils.core';
import { transactionsActions, transactionsTypes } from '@quicken-com/react.flux.transactions';

import { normalizePayeeQCS } from 'data/renameRules/utils';

import * as actions from './actions';
import { getLastSyncDate, getPayeeApplicableTransactions } from './selectors';
import { transformResponseToRules, transformRulesToRequestData, transformRuleToRequestData } from './transformers';


// ===============================================================================================
// SAGAS functions for rules.  These are the functions responsible for communicating directly
// with external endpoints asynchronously.

// ====================================================
// ACTION WATCHERS to trigger SAGAS calls

const mkResourceConfig = (successAction, failureAction) => resourceStoreTypes.mkQcsSyncResourceConfig({
  resourceName: 'rule',
  resourceBaseUrl: '/rename-rules',
  getLastSyncDate,

  transformResponseToResources: transformResponseToRules,
  transformResourceToRequestData: transformRuleToRequestData,
  transformResourcesToRequestData: transformRulesToRequestData,

  successAction,
  failureAction,
});

export function* getRenameRulesActionWatcher() {
  // using a vanilla saga pattern so we only process a single get request
  //   - takeEvery would process every request
  //   - takeLatest would cancel any current request and start request again
  while (true) {
    const action = yield take(actions.getRenameRules);

    const config = mkResourceConfig(actions.getRenameRulesSuccess, actions.getRenameRulesFailure);
    yield call(resourceSagaUtils.qcsSyncGetResources, config, action);
  }
}

export function* createRenameRuleActionWatcher() {
  const config = mkResourceConfig(actions.createRenameRuleSuccess, actions.createRenameRuleFailure);
  yield takeEvery(actions.createRenameRule, function* updateRenameRule(action) {
    const response = yield call(resourceSagaUtils.qcsSyncCreateResource, config, action);
    if (action.meta?.props?.applyToTransactions && Math.trunc(response?.status / 100) === 2) {
      yield call(applyRenameRule, action);
    }
    tracker.track(tracker.events.payeeRuleCreate, {
      initiator: action.meta?.props?.transaction ? 'transaction' : 'settings',
      number_keywords: normalizePayeeQCS(action.payload.renamePayeeFrom).split(' ').length,
      apply_past: action.meta?.props?.applyToTransactions,
    });
  });
}

export function* updateRenameRuleActionWatcher() {
  const config = mkResourceConfig(actions.updateRenameRuleSuccess, actions.updateRenameRuleFailure);
  yield takeEvery(actions.updateRenameRule, function* updateRenameRule(action) {
    const response = yield call(resourceSagaUtils.qcsSyncUpdateResource, config, action);
    if (action.meta?.props?.applyToTransactions && Math.trunc(response?.status / 100) === 2) {
      yield call(applyRenameRule, action);
    }
    tracker.track(tracker.events.payeeRuleEdit, {
      number_keywords: normalizePayeeQCS(action.payload.renamePayeeFrom).split(' ').length,
      apply_past: action.meta?.props?.applyToTransactions,
    });
  });
}

export function* updateBatchRenameRuleActionWatcher() {
  const config = mkResourceConfig(actions.updateRenameRuleBatchSuccess, actions.updateRenameRuleBatchFailure);
  yield takeEvery(actions.updateRenameRuleBatch, function* updateRenameRuleBatch(action) {
    yield call(resourceSagaUtils.qcsSyncUpdateResources, config, action);
    // TODO: make sure this works to update txns for multiple rules
    // const response = yield call(resourceSagaUtils.qcsSyncUpdateResources, config, action);
    // if (action.meta?.props?.applyToTransactions && Math.trunc(response?.status / 100) === 2) {
    //   for (const rule of action.payload) {
    //     yield call(applyRenameRule, { payload: { renamePayeeFrom: rule.renamePayeeFrom, renamePayeeTo: rule.renamePayeeTo } });
    //     tracker.track(tracker.events.payeeRuleEdit, {
    //       number_keywords: normalizePayeeQCS(rule.renamePayeeFrom).split(' ').length,
    //       apply_past: action.meta?.ops?.applyToTransactions,
    //     });
    //   }
    // }
  });
}

function* applyRenameRule(action) {
  const renameRuleApplicableTransactions = yield select(
    getPayeeApplicableTransactions,
    action.meta?.props?.transaction?.payee || action.payload.renamePayeeFrom,
    action.payload.renamePayeeTo
  );
  if (renameRuleApplicableTransactions.size) {
    yield put(transactionsActions.updateTransactions(
      renameRuleApplicableTransactions
        .toIndexedSeq()
        .toArray()
        .map((transaction) => new transactionsTypes.CashFlowTransaction({
          ...transaction?.toJS?.(),
          payee: action.payload.renamePayeeTo,
        }))
    ));
  }
}

export function* deleteRenameRuleActionWatcher() {
  const config = mkResourceConfig(actions.deleteRenameRuleSuccess, actions.deleteRenameRuleFailure);
  yield takeEvery(actions.deleteRenameRule, function* deleteRenameRule(action) {
    yield call(resourceSagaUtils.qcsSyncDeleteResource, config, action);
    tracker.track(tracker.events.payeeRuleDelete);
  });
}


// ====================================================
// EXPORTS

export default [
  getRenameRulesActionWatcher,
  createRenameRuleActionWatcher,
  updateRenameRuleActionWatcher,
  updateBatchRenameRuleActionWatcher,
  deleteRenameRuleActionWatcher,
];
