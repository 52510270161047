import { takeEvery, takeLeading } from 'redux-saga/effects';

import { resourceStoreTypes, resourceSagaUtils } from '@quicken-com/react.flux.core';

import { getLastSyncDate } from './preferencesV2Selectors';
import * as actions from './preferencesV2Actions';

const mkResourceConfig = (props) => resourceStoreTypes.mkQcsSyncResourceConfig({
  resourceName: 'preferences-v2',
  resourceBaseUrl: '/v2/preferences',
  getLastSyncDate,
  ...props,
});

export function* getPreferencesV2ActionWatcher() {
  const config = mkResourceConfig({
    successAction: actions.getPreferencesV2Success,
    failureAction: actions.getPreferencesV2Failure,
  });
  yield takeLeading(actions.getPreferencesV2, resourceSagaUtils.qcsSyncGetResources, config);
}

export function* updatePreferenceCriteriaActionWatcher() {
  const config = mkResourceConfig({
    resourceBaseUrl: '/v2/preferences/criteria',
    successAction: actions.updatePreferenceCriteriaSuccess,
    failureAction: actions.updatePreferenceCriteriaFailure,
  });
  yield takeEvery(actions.updatePreferenceCriteria, resourceSagaUtils.qcsSyncCreateResource, config);
}

export default [
  getPreferencesV2ActionWatcher,
  updatePreferenceCriteriaActionWatcher,
];
