import { takeEvery, call, select, put } from 'redux-saga/effects';
import { List } from 'immutable';

import { resourceStoreTypes, resourceSagaUtils } from '@quicken-com/react.flux.core';

import { getPreferences } from 'data/preferences/selectors';
import { setPreference } from 'data/preferences/actions';
import * as actions from './budgetsActions';
import { getLastSyncDate } from './budgetsSelectors';
import { mkBudgetSchedule, mkBudgetRecord } from './budgetsTypes';

const mkResourceConfig = (props) => resourceStoreTypes.mkQcsSyncResourceConfig({
  resourceName: 'budgets',
  resourceBaseUrl: '/budgets',
  getLastSyncDate,
  // transformResponseToResources: ,
  // transformResourceToRequestData:,
  ...props,
});

export function* createBudgetsActionWatcher() {
  const config = mkResourceConfig({
    successAction: actions.createBudgetsSuccessAction,
    failureAction: actions.createBudgetsFailureAction,
  });
  yield takeEvery(actions.createBudgetsAction, resourceSagaUtils.qcsSyncUpdateResources, config);
}

export function* updateBudgetActionWatcher() {
  const config = mkResourceConfig({
    successAction: actions.updateBudgetSuccessAction,
    failureAction: actions.updateBudgetFailureAction,
  });
  yield takeEvery(actions.updateBudgetAction, resourceSagaUtils.qcsSyncUpdateResource, config);
}

function* deleteBudgets(config, data) {
  const newData = data;
  newData.payload = data.payload.map((item) => item.set('isDeleted', true));
  return yield call(resourceSagaUtils.qcsSyncUpdateResources, config, newData);
}

export function* deleteBudgetsActionWatcher() {
  const config = mkResourceConfig({
    successAction: actions.deleteBudgetsSuccessAction,
    failureAction: actions.deleteBudgetsFailureAction,
  });
  yield takeEvery(actions.deleteBudgetsAction, deleteBudgets, config);
}

export function* getBudgetsActionWatcher() {
  const config = mkResourceConfig({
    successAction: actions.getBudgetsSuccessAction,
    failureAction: actions.getBudgetsFailureAction,
    transformResponseToResources: (response: any) => response.data.resources.map((data) => mkBudgetRecord({
      ...data,
      schedule: mkBudgetSchedule(data.schedule),
    })),
  });
  yield takeEvery(actions.getBudgetsAction, resourceSagaUtils.qcsSyncGetResources, config);
}

function* updateCallFullCalcBudgetPath(config, data) {
  let newConfig = config.set('resourceBaseUrl', config.resourceBaseUrl.replace('budgetIdDynamic', data.payload));
  newConfig = newConfig.set('transformResponseToResource', () => data.payload);
  yield call(resourceSagaUtils.qcsSyncGetResource, newConfig, data);
}

export function* callFullCalcBudgetActionWatcher() {
  const config = resourceStoreTypes.mkQcsSyncResourceConfig({
    resourceName: 'budgetCalcFull',
    resourceBaseUrl: '/budget-calc/budgetIdDynamic/full',
    successAction: actions.callFullCalcBudgetSuccessAction,
    failureAction: actions.callFullCalcBudgetFailureAction,
  });
  yield takeEvery(actions.callFullCalcBudgetAction, updateCallFullCalcBudgetPath, config);
}

function* triggerSetPreference({ payload }) {
  const preferences = yield select(getPreferences);
  const { fullCalcTriggeredList = List() } = preferences?.shared?.dataset?.budgetOptions;
  const newfullCalcTriggeredList = fullCalcTriggeredList.push(payload?.resources[0]);
  const newPayload = {
    section: 'shared',
    group: 'dataset',
    preference: {
      budgetOptions: {
        fullCalcTriggeredList: newfullCalcTriggeredList,
      },
    },
  };
  yield put(setPreference(newPayload));
}

export function* callFullCalcBudgetSuccessActionWatcher() {
  yield takeEvery(actions.callFullCalcBudgetSuccessAction, triggerSetPreference);
}

export default [
  getBudgetsActionWatcher, 
  createBudgetsActionWatcher, 
  updateBudgetActionWatcher, 
  deleteBudgetsActionWatcher,
  callFullCalcBudgetActionWatcher,
  callFullCalcBudgetSuccessActionWatcher,
];
