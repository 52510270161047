import { isTablet, isMobile } from 'react-device-detect';

export const regItemCore = (theme) => ({
  fontSize: theme.components.register.fontSize.default,
});

export const regItem = (theme) => ({
  ...regItemCore(theme),
  flex: 1,
  overflow: 'hidden',
  paddingTop: '4px',
  paddingBottom: '4px',
  marginTop: 'auto',
  marginBottom: 'auto',
  paddingLeft: '5px',
  paddingRight: 2,
  marginRight: theme.components.register.fieldMargin,
  minWidth: '5px',
  lineHeight: '24px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  display: 'flex',          // ie-11 needs this
  flexDirection: 'column',  // ie-11 needs this
  justifyContent: 'center', // ie-11 needs this
  borderRadius: 4,
  outline: 'none',

  '&:focus': {
    outlineOffset: '-4px',
  },
  '& p': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  '&.amount': {
    flex: 1,
    textAlign: 'right',
    '& input': {
      textAlign: 'right',
    },
  },
  '&.quantity': {
    minWidth: 32,
  },
  '&.protectWidth': {
    minWidth: 89,
  },
  '&.protectWidthDate': {
    minWidth: 89,
    maxWidth: 89,
  },
  '&.flex2': {
    flex: 2,
  },
  '&.wraptext': {
    whiteSpace: 'normal',
    overflow: 'visible',
    overflowWrap: 'break-word',
    '-ms-word-wrap': 'break-word',
    '& p': {
      overflow: 'visible',
      textOverflow: 'unset',
      whiteSpace: 'normal',

    },
  },
  '&.click': {
    cursor: 'pointer',
  },
  '&.editable': {
    paddingTop: theme.components.register.fieldPadding,
    paddingBottom: theme.components.register.fieldPadding,
    paddingLeft: 4,
    paddingRight: 2,
    '&:focus-within': {
      background: theme.palette.greyScaleDeprecated[6],
      border: `solid 1px ${theme.components.register.fieldBorderColor}`,
    },
  },
  // '&.iconpad': {
  //   paddingRight: '30px',
  // },
});

export const sharedStyles = (theme) => ({
  regItemCore: {
    ...regItemCore(theme),
  },
  regItem: {
    ...regItem(theme),
  },
  checkBox: {
    fontSize: 20,
    padding: '6px',
  },
  select: {
    height: '100%',
    marginTop: 0,
    fontSize: 14,
    padding: 0,
    minWidth: 35,
    '& svg': {
      top: 0,
    },
    '& select': {
      height: '18px',
      lineHeight: '18px',
      paddingBottom: 0,
    },
  },
  statusColumn: {
    ...regItem(theme),
    marginTop: 0,
    marginBottom: 0,
    flex: 0,
    maxWidth: (process.env.PROJECT === 'acme') ? 90 : 40,
    minWidth: (process.env.PROJECT === 'acme') ? 90 : 40,
    cursor: 'pointer',
    paddingLeft: 0,
    textAlign: 'center',
    overflow: 'visible',
  },
  iconButtonColumn: {
    textAlign: 'center',
    flex: '0 0 32px',
    maxWidth: '32px',
    minWidth: '32px',
    margin: 'auto',
    color: theme.palette.text.primary, // '#676767',
    '&.nopoint': {
      cursor: 'default',
    },
    '&.skinny': {
      flex: '0 0 25px',
      maxWidth: '25px',
      minWidth: '25px',
    },
  },
  hideableIconButtonColumn: {
    extend: 'iconButtonColumn',
    opacity: 0,
    '&.show': {
      opacity: 1,
    },
  },
  iconColumn: {
    display: 'flex',
    flex: '0 0 32px',
    maxWidth: '32px',
    minWidth: '32px',
    justifyContent: 'center',
    margin: 'auto',
    color: '#676767',
    '&.nopoint': {
      cursor: 'default',
    },
  },
  menuColumn: {
    ...regItem(theme),
    flex: 1,
    minWidth: 30,
    maxWidth: 50,
    paddingLeft: '0px',
    borderLeft: 'none',
  },
  clickable: {
    cursor: 'pointer',
  },
  tooltip: {
    width: 'inherit',
    height: 'inherit',
  },
  turnUp: {
    marginTop: -1,
    display: 'inline-block',
    color: theme.palette.greyScaleDeprecated[0],
    marginLeft: '1px',
    transform: 'rotate(0deg) scale(0.75)',
    transition: 'all 0.4s ease',
  },
  turnDown: {
    extend: 'turnUp',
    transform: 'rotate(180deg) scale(0.75)',
  },
  turnDown90: {
    extend: 'turnDown',
    transform: 'rotate(90deg) scale(0.75)',
  },
  accountFieldColor: {
    height: '100%',
    minHeight: 20,
    width: (isTablet || isMobile) ? 35 : 12,
    paddingTop: 2,
    paddingBottom: 2,
  },
  accountFieldName: {
    height: '100%',
    width: 'auto',
    textAlign: 'left',
    '&.full': {
    },
  },
});
