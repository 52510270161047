import { call, fork, race, take, put, takeEvery } from 'redux-saga/effects';

import { getEnvironmentConfig, getLogger } from '@quicken-com/react.utils.core';
import { resourceStoreTypes, resourceSagaUtils } from '@quicken-com/react.flux.core';
import store from '@quicken-com/react.utils.redux-store';
import { transactionsActions } from '@quicken-com/react.flux.transactions';

import AxiosFactory from 'utils/axiosFactory';
import * as budgetItemsActions from 'data/budgetItems/budgetItemsActions';
import * as budgetItemsSelectors from 'data/budgetItems/budgetItemsSelectors';

import * as categoryGroupsActions from 'data/categoryGroups/categoryGroupsActions';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';

import * as transformers from './transformers';

const log = getLogger('data/budgetItems/budgetItemsSagas.js');

const mkResourceConfig = (props) => resourceStoreTypes.mkQcsSyncResourceConfig({
  resourceName: 'budgetItems',
  resourceBaseUrl: '/budgets/budget-items',
  getLastSyncDate: budgetItemsSelectors.getLastSyncDate,
  ...props,
  transformResourceToRequestData: transformers.transformBudgetItemToApiRequestData,
  transformResourcesToRequestData: transformers.transformBudgetItemsRequestData,
});

function* recalcBudgetItems(action) {

  try {
    log.log('recalcBudgetItemsAction: server calculation start...');
    const qcsAxios = AxiosFactory.get('qcs');
    const requestUrl = `${getEnvironmentConfig().services_url}/budget-calc`;
    const response = yield call(qcsAxios.get, requestUrl);
    log.log('recalcBudgetItemsAction: server calculation complete...\nresponse = ', response);
  } catch (error) {
    log.log('recalcBudgetItemsAction: server calculation failed...\nerror = ', error);
  }

  const resourceConfig = mkResourceConfig({
    successAction: budgetItemsActions.getBudgetItemsSuccessAction,
    failureAction: budgetItemsActions.getBudgetItemsFailureAction,
  });
  yield call(resourceSagaUtils.qcsSyncGetResources, resourceConfig, action);
}

const getBudgetItemsActionQueue = [];
export function* getBudgetItemsActionWatcher() {
  while (true) {
    const { getBudgetItemsAction, getBudgetItemsSuccessAction, getBudgetItemsFailureAction } = yield race({
      getBudgetItemsAction: take(budgetItemsActions.getBudgetItemsAction),
      getBudgetItemsSuccessAction: take(budgetItemsActions.getBudgetItemsSuccessAction),
      getBudgetItemsFailureAction: take(budgetItemsActions.getBudgetItemsFailureAction),
    });

    let launchAction = false;
    if (getBudgetItemsAction) {
      launchAction = getBudgetItemsActionQueue.length === 0;
      if (getBudgetItemsActionQueue.length < 2) {
        getBudgetItemsActionQueue.push(getBudgetItemsAction);
      }
    } else if (getBudgetItemsSuccessAction || getBudgetItemsFailureAction) {
      getBudgetItemsActionQueue.shift();
      launchAction = getBudgetItemsActionQueue.length > 0;
    }
    if (launchAction) {
      yield fork(recalcBudgetItems, getBudgetItemsActionQueue[0]);
    }
  }
}

export function* updateBudgetItemsActionWatcher(): Generator<*, *, *> {
  const resourceConfig = mkResourceConfig({
    successAction: budgetItemsActions.updateBudgetItemsSuccessAction,
    failureAction: budgetItemsActions.updateBudgetItemsFailureAction,
  });
  yield takeEvery(budgetItemsActions.updateBudgetItemsAction, resourceSagaUtils.qcsSyncUpdateResources, resourceConfig);
}

function* deleteBudgetItems(config, data) {
  const newData = data;
  newData.payload = data.payload.map((item) => item.set('isDeleted', true));
  return yield call(resourceSagaUtils.qcsSyncUpdateResources, config, newData);
}

export function* deleteBudgetItemsActionWatcher() {
  const resourceConfig = mkResourceConfig({
    successAction: budgetItemsActions.deleteBudgetItemsSuccessAction,
    failureAction: budgetItemsActions.deleteBudgetItemsFailureAction,
  });
  yield takeEvery(budgetItemsActions.deleteBudgetItemsAction, deleteBudgetItems, resourceConfig);
}

export function* createBudgetItemsActionWatcher(): Generator<*, *, *> {
  const resourceConfig = mkResourceConfig({
    successAction: budgetItemsActions.createBudgetItemsSuccessAction,
    failureAction: budgetItemsActions.createBudgetItemsFailureAction,
  });
  yield takeEvery(budgetItemsActions.createBudgetItemsAction, resourceSagaUtils.qcsSyncUpdateResources, resourceConfig);
}

export function* updateBudgetItemActionWatcher() {
  const resourceConfig = mkResourceConfig({
    successAction: budgetItemsActions.updateBudgetItemSuccess,
    failureAction: budgetItemsActions.updateBudgetItemFailure,
  });
  yield takeEvery(budgetItemsActions.updateBudgetItem, resourceSagaUtils.qcsSyncUpdateResource, resourceConfig);
}

function* transactionModified(action) {
  const featureFlags = featureFlagsSelectors.getFeatureFlags(store.getState());
  if (featureFlags && featureFlags.get('budgets')
    && action && action.meta && action.meta.context && (
    action.meta.context === 'Reg_Budgets'
    || action.meta.context === 'addAccounts'
  )
    && (action.type !== transactionsActions.getTransactionsSuccess.toString() || (action.payload && action.payload.resources && action.payload.resources.length > 0))
  ) {
    yield put(budgetItemsActions.getBudgetItemsAction());
  }
}

function* budgetItemAdded() {
  const featureFlags = featureFlagsSelectors.getFeatureFlags(store.getState());
  if (featureFlags && featureFlags.get('budgets')) {
    yield put(budgetItemsActions.getBudgetItemsAction());
  }
}

function* checkCategoryGroups(_action) {

  // console.log("CHECK CATEGORY GROUPS FOR BUDGET ITEMS ", action);


}

function* transactionModifiedActionWatcher() {
  yield takeEvery(budgetItemsActions.createBudgetItemsSuccessAction, budgetItemAdded);
  yield takeEvery(transactionsActions.getTransactionsSuccess, transactionModified);
  yield takeEvery(transactionsActions.createTransactionsSuccess, transactionModified);
  yield takeEvery(transactionsActions.updateTransactionsSuccess, transactionModified);
  yield takeEvery(transactionsActions.deleteTransactionsSuccess, transactionModified);
  yield takeEvery(transactionsActions.performTransactionActionSuccess, transactionModified);
  yield takeEvery(categoryGroupsActions.updateCategoryGroupAction, checkCategoryGroups);
}

export default [
  getBudgetItemsActionWatcher,
  updateBudgetItemsActionWatcher,
  updateBudgetItemActionWatcher,
  transactionModifiedActionWatcher,
  deleteBudgetItemsActionWatcher,
  createBudgetItemsActionWatcher];

