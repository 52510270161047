import { handleActions, combineActions } from 'redux-actions';
import { resourceUpserters, resourceStoreTypes } from '@quicken-com/react.flux.core';

import { showMCPDialog } from 'components/Dialogs/MCPDialog/mcpDialogActions';
import * as notificationsActions from 'data/notifications/notificationsActions';
import { mkNotification } from 'data/notifications/notificationsTypes';
import { OrderedMap } from 'immutable';
import MCToastSnackbarContent from 'components/MCToastSnackbarContent';
import { v4 as uuidv4 } from 'uuid';
import { MC_RULES_REDUCER_KEY as REDUCER_KEY, MessageTypeEnum } from './mcRulesTypes';
import * as actions from './mcRulesActions';

const mcRulesReducer = handleActions({

  [actions.mcRulesPollSuccess]:
    (state, { payload: data, asyncDispatch }) => {
      if (data.resources) {
        data.resources
          .filter((message) => message.type === MessageTypeEnum.POP_UP)
          .forEach((message) => message.id && asyncDispatch(showMCPDialog(message.id)));
        const notifications = OrderedMap(
          data.resources
            .filter((message) => message.type === MessageTypeEnum.TOAST)
            .map((message) => {
              const id = uuidv4();
              return [
                id,
                mkNotification({
                  id,
                  snackbarContent: MCToastSnackbarContent,
                  snackbarContentProps: { message },
                })];
            })
        );
        if (notifications && notifications.size) {
          asyncDispatch(notificationsActions.addNotifications(notifications));
        }
      }
      return resourceUpserters.upsertResources(state, data);
    },

  [combineActions(
    actions.mcRulesExecuteFailure,
    actions.mcRulesSubmitDataFailure,
    actions.mcRulesPollFailure,
  )]:
    (state, { payload: error }) => resourceUpserters.completeWithError(state, error),

}, resourceStoreTypes.mkQcsSyncResourceStore());

export const MC_RULES_REDUCER_KEY = REDUCER_KEY;
export default mcRulesReducer;
