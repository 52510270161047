import { mkSubscription } from 'data/subscriptions/subscriptionsTypes';
import { DateTime } from 'luxon';
import { getLogger } from '@quicken-com/react.utils.core';

const log = getLogger('data/subscriptions/helpers.js');

export function getSubscriptionsOverride() {
  let subscriptions;

  let subscriptionsOverride;
  try {
    const subscriptionsJSON = localStorage?.getItem('subscriptionsOverride');
    subscriptionsOverride = JSON.parse(subscriptionsJSON);
  } catch (e) {
    log.error(e);
  }
  if (Array.isArray(subscriptionsOverride)) {
    subscriptions = subscriptionsOverride.map((subscription) => mkSubscription({
      effectiveAt: DateTime.local().toISO(),
      expireAt: DateTime.local().plus({ years: 1 }).toISO(),
      expireOn: DateTime.local().plus({ years: 1 }).toISODate(),
      createdAt: DateTime.local().toISO(),
      ...subscription,
    }));
  }

  return subscriptions;
}

export const getSubscriptionTrackingProps = (subscription) => {
  const props = {};

  if (subscription) {
    props.subscriptionStatus = subscription.autoRenew ? 'subscribed' : 'canceled';

    let purchaseChannel;
    switch (subscription.merchant) {
      case 'Quicken':
        purchaseChannel = 'direct';
        break;
      case 'Apple':
        purchaseChannel = 'apple';
        break;
      case 'Google':
        purchaseChannel = 'google';
        break;
      default:
    }
    props.purchaseChannel = purchaseChannel;

    let subscriber;
    if (subscription.active) {
      subscriber = 'Subscribed';
    } else {
      const dateNow = Date.now();
      const dateExpired = Date.parse(subscription.expireAt);
      if (dateExpired >= dateNow) {
        subscriber = 'Not Subscribed';
      } else {
        subscriber = 'Expired';
      }
    }
    if (subscriber) {
      props.subscriber = subscriber;
    }

    let tierCountry;
    switch (subscription.productLineName) {
      case 'Quicken US':
        tierCountry = 'US';
        break;
      case 'Quicken CA':
        tierCountry = 'CA';
        break;
      default:
        tierCountry = null;
    }
    if (tierCountry) {
      props.tierCountry = tierCountry;
    }

    let tierName;
    switch (subscription.tierName) {
      case 'Starter':
        switch (subscription.productLineName) {
          case 'Quicken US':
            tierName = 'Starter';
            break;
          case 'Quicken CA':
            tierName = 'Starter (CA)';
            break;
          default:
            tierName = null;
        }
        break;
      case 'Deluxe':
        switch (subscription.productLineName) {
          case 'Quicken US':
            tierName = 'Deluxe';
            break;
          case 'Quicken CA':
            tierName = 'Deluxe (CA)';
            break;
          default:
            tierName = null;
        }
        break;
      case 'Premier':
        tierName = 'Premier';
        break;
      case 'Home and Business':
        tierName = 'HB (CA)';
        break;
      case 'Home, Business & Rental Property':
        tierName = 'HBR';
        break;
      default:
        tierName = subscription.tierName;
    }
    if (tierName) {
      props.tier = tierName;
    }

    if (subscription.isInTrialPeriod != null) {
      props.subscriptionState = subscription.isInTrialPeriod ? 'trial' : 'paid';
    }

    if (subscription.renewalFrequency != null) {
      props.subscriptionTerm = subscription.renewalFrequency === 'P1M' ? 'monthly' : 'annual';
    }
  }

  return props;
};
