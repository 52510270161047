// @flow
import React from 'react';
import { connect } from 'react-redux';
import compose from 'utils/compose';
import classNames from 'classnames';
import { Map as ImmutableMap } from 'immutable';
import isAcme from 'isAcme';
import { withRouter } from 'react-router-dom';
import { DateTime } from 'luxon';

import { getLogger, tracker } from '@quicken-com/react.utils.core';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';

import { isOldEdge } from 'utils/utils';

import AmountField from 'components/QuickenControls/AmountField';
import QTip from 'components/QuickenControls/QTip';
import Dump from 'components/Dump';

import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import CircularProgress from '@mui/material/CircularProgress';

import { withStyles } from '@mui/styles';

import ArrowDown from '@mui/icons-material/ArrowDropDown';
import ArrowUp from '@mui/icons-material/ArrowDropUp';
import Bolt from '@mui/icons-material/Bolt';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PriorityHigh from '@mui/icons-material/PriorityHigh';

import QIconButton from 'components/QIconButton';

import { getQcsAggregationAction } from 'data/aggErrors/errors';
import {
  getInstitutionLoginsById,
  getIsRefreshingAll as getIsAllInstitutionLoginsRefreshing,
  getIdsRefreshing as getInstitutionLoginIdsRefreshing,
} from 'data/institutionLogins/selectors';
import { ShowBalance } from 'data/preferences/types';

import * as preferencesSelectors from 'data/preferences/selectors';
import { authSelectors } from '@quicken-com/react.flux.auth';
import { createErrorDialog } from 'data/aggErrors/actions';

import type { DisplayOptions } from '../types';
import { PrimaryTextLine, SecondaryTextLine, styles } from './styles';

const isIe = require('is-iexplorer') || isOldEdge();  // eslint-disable-line

const log = getLogger('components/AccountList/AccountRow/index.js');

const MAX_LEVEL_TO_EXPAND_COLLAPSE = 2;

type Props = {
  id: string,
  node: Object,
  displayOptions: ?DisplayOptions,
  dense: boolean,
  omitCents: boolean,
  showColors: boolean,
  balanceType: string,

  onClick: any,
  onExpandCollapse: any,

  selectedNodeId: any,

  institutionLoginsById: any,

  isAllInstitutionLoginsRefreshing: boolean,
  institutionLoginsIdsRefreshing: Set<string>,

  accountColorsById: any,
  featureFlags: ImmutableMap<string, any>,
  dispatch: (any) => void,

  theme: Object,

  classes: Object,

  history: Object,
  isWebFirstDataset: boolean,
};

class AccountRow extends React.PureComponent<Props> {

  getBalance = (node) => {
    switch (this.props.balanceType) {
      case 'online':
        return node.sumOnlineAccountBalances;
      case ShowBalance.BANK_REPORTED:
        return node.sumCPOnlineAccountBalances;
      case 'today':
      case ShowBalance.INCLUDE_PENDING:
        return node.sumCurrentAccountBalances;
      case 'final':
        return node.sumFinalAccountBalances;
      default:
        return node.sumEndingAccountBalances;
    }
  };

  handleAggAction = (aggAction) => {
    const institutionId = aggAction.institutionLogin && aggAction.institutionLogin.institutionId
      ? aggAction.institutionLogin.institutionId
      : '0';
    if (aggAction.actionWithConfirmation) {
      tracker.track(tracker.events.fiLoginFixItFlowStart,
        {
          type: aggAction.type,
          id: aggAction.id,
          institution_id: institutionId,
          agg_status: aggAction.status,
          agg_status_code: aggAction.statusCode || 'null',
          location: 'account list',
          cancellable: true,
        });
      this.props.dispatch(this.props.isWebFirstDataset || isAcme ? aggAction.actionWithConfirmation : createErrorDialog(aggAction));
    } else if (aggAction.action) {
      tracker.track(tracker.events.fiLoginFixItFlowStart,
        {
          type: aggAction.type,
          id: aggAction.id,
          institution_id: institutionId,
          agg_status: aggAction.status,
          agg_status_code: aggAction.statusCode || null,
          location: 'account list',
        });
      this.props.dispatch(aggAction.action);
    }
  };

  renderPrimaryText = (node, classes, omitCents, selClass, iconSpace) => {
    let depth = node.depth === 2 && node.isLeaf === true ? 3 : node.depth;
    const balance = this.getBalance(node);
    const negClass = !isAcme && (this.getBalance(node) < 0 ? classes.negativeBalDark : classes.positiveBalDark);

    if (node.depth === 1 && node.displayOrder === 0) {
      depth = 0;
    }
    const levelClass = classes[`listItemTextL${depth}`];
    const isClosedAccount = node?.account?.isClosed;

    return (
      <PrimaryTextLine>
        <span
          style={{ width: `${node.displayLabel.length + 2}ch`, marginRight: isClosedAccount ? 0 : undefined, overflow: 'hidden' }}
          className={classNames(levelClass, 'left', selClass)}
        >
          <Dump obj={node} />
          <QTip
            title={node.displayLabel}
            wrapOnly
          >
            <div
              style={{ width: '100%' }}
              className={classNames(levelClass, 'left', selClass)}
            >
              <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre' }}>
                {isClosedAccount ? <i>{node.displayLabel}</i> : node.displayLabel}
              </div>
              {iconSpace}
            </div>
          </QTip>
        </span>
        {isClosedAccount &&
          <i className={classes.closedText}>(Closed)</i>}
        <AmountField
          id={`${this.props.id}-balance`}
          value={balance}
          className={classNames(levelClass, selClass, negClass)}
          omitCents={omitCents}
          currencySymbol={node.currency}
        />
      </PrimaryTextLine>
    );
  };

  renderSecondaryText = (node, institutionLogin) => {

    log.log('Rendering secondary text');
    if (!node.account) {
      // secondary text line only exists when we have an account
      return null;
    }

    let lastSuccessfulRefreshOn = '';
    if (node.account.aggregators && node.account.aggregators.size > 0) {
      const aggregator = node.account.aggregators.get(0);
      if (aggregator.lastRefreshSuccessfulAt) {
        const lastRefreshSuccessAt = DateTime.fromISO(aggregator.lastRefreshSuccessfulAt);
        lastSuccessfulRefreshOn = lastRefreshSuccessAt.toLocaleString();
      }
      log.log('LastSuccessfulRefresh', aggregator.lastRefreshSuccessfulAt, lastSuccessfulRefreshOn);
    }

    return (
      <SecondaryTextLine>
        <div>
          {institutionLogin ? `${institutionLogin.name}` : ''}
        </div>
        <div title="refresh-date">
          {lastSuccessfulRefreshOn}
        </div>
      </SecondaryTextLine>
    );
  };

  renderAggAction = (institutionLogin, aggStatus, aggStatusCode, classes, depth) => {
    if (institutionLogin != null) {
      if (this.props.isAllInstitutionLoginsRefreshing) {
        return null;
      }
      if (this.props.institutionLoginsIdsRefreshing.has(institutionLogin.id)) {
        return (
          <QIconButton
            disabled
            aria-label="Refreshing"
            classes={{ root: classes.refreshingProgress }}
            size="small"
          >
            <CircularProgress size={20} style={{ color: this.props.theme.palette.blue5 }} />
          </QIconButton>
        );
      }
      const aggAction = getQcsAggregationAction(institutionLogin, aggStatus, aggStatusCode, this.props.featureFlags, null, this.props.isWebFirstDataset);
      return (
        <QIconButton
          aria-label="aggregation action"
          classes={{
            root: classes[`listItemIconButtonL${depth}`],
          }}
          onClick={(e) => {
            e.stopPropagation();
            this.handleAggAction(aggAction);
          }}
          size="small"
        >
          {aggAction.type === 'ERROR'
            ? <PriorityHigh classes={{ root: classes.errorIcon }} />
            : this.props.isWebFirstDataset && <Bolt classes={{ root: classes.infoIcon }} />}
        </QIconButton>
      );
    }
    return null;
  };

  renderGoalLine = (text, amount) => {
    const { classes, node, omitCents } = this.props;
    return (
      <PrimaryTextLine>
        <span
          style={{ width: '100%', paddingLeft: 8 }}
          className={classNames(classes.listItemTextL3, 'left')}
        >
          <QTip
            title={text}
            wrapOnly
          >
            <div
              style={{ width: '100%' }}
              className={classNames(classes.listItemTextL3, 'left')}
            >
              {text}
            </div>
          </QTip>
        </span>
        <AmountField
          id={`${this.props.id}-balance`}
          value={amount}
          className={classes.listItemTextL3}
          omitCents={omitCents}
          currencySymbol={node.currency}
        />
      </PrimaryTextLine>);
  };

  render() {
    const { selectedNodeId, node, dense = false, displayOptions, onClick, onExpandCollapse,
      classes, omitCents, showColors } = this.props;

    const isLeafNode = node.depth === 2 && node.isLeaf === true;
    let depth = isLeafNode ? 3 : node.depth;
    if (node.depth === 1 && node.displayOrder === 0) {
      depth = 0;
    }

    let aggStatus = null;
    let aggStatusCode = null;
    if (node.account && node.account.aggregators) {
      const aggregator = node.account.aggregators.get(0);

      aggStatus = aggregator.aggStatus;
      aggStatusCode = aggregator.aggStatusCode;
    }

    // const rowClass = displayOptions.selected ? 'selected' : '';
    const nodeHasFixedExpansion = node.depth > MAX_LEVEL_TO_EXPAND_COLLAPSE;
    const nodeIsExpandable = node.children && node.expandable && !nodeHasFixedExpansion;

    const selClass = selectedNodeId === node.id ? 'selected' : '';

    const institutionLogin = !(node.account && node.account.institutionLoginId && this.props.institutionLoginsById) ? null :
      this.props.institutionLoginsById.get(node.account.institutionLoginId);

    const drawerTheme = this.props.theme.components.accountDrawer;

    const UpIcon = isAcme ? ArrowUp : ExpandLess;
    const DownIcon = isAcme ? ArrowDown : ExpandMore;

    const expandable = isAcme ? (node.children && node.expandable && !nodeHasFixedExpansion && node.id !== 'HIDDEN') :
      (node.children && node.expandable && !nodeHasFixedExpansion);

    const displayGoals = isAcme && depth === 3 && Number(node.sumGoalsAccountBalances) !== 0 && !node.children;
    const hasAggAction = (aggStatus != null && aggStatus !== 'OK') || (institutionLogin?.optionalAction);


    const iconSpace = (
      <ListItemSecondaryAction
        style={(isAcme && expandable) ? { left: drawerTheme.basePadding - 6 } : { right: (drawerTheme.listPaddingRight - drawerTheme.iconSize) / 2 }}
      >
        {expandable &&
          <QIconButton
            aria-label="Expand/Collapse"
            classes={{
              root: classes[`listItemIconButtonL${depth}`],
            }}
            onClick={(e) => {
              e.stopPropagation();
              onExpandCollapse(displayOptions);
            }}
          >
            {displayOptions && displayOptions.expand ? <UpIcon /> : <DownIcon />}
          </QIconButton>}
        {hasAggAction && this.renderAggAction(institutionLogin, aggStatus, aggStatusCode, classes, depth)}
      </ListItemSecondaryAction>
    );

    const primaryText = this.renderPrimaryText(node, classes, omitCents, selClass, iconSpace);

    return (
      <div
        role="list"
      >
        <ListItem
          button
          className={classNames(selClass, nodeIsExpandable && 'expandable', classes[`listItemL${depth}`])}
          onClick={(e) => {
            e.stopPropagation();
            onClick(node);
          }}
          key={`${node.id}`}
          id={this.props.id}
        >
          {node.account && showColors &&
          <div
            className={classes.accountBar}
            style={{ background: this.props.accountColorsById[node.account.id] }}
          />}

          <ListItemText
            disableTypography
            primary={primaryText}
            secondary={!dense ? this.renderSecondaryText(node, institutionLogin) : null}
            className={classNames(selClass, classes.listItemText)}
            style={{ width: '100%' }}
          />
        </ListItem>
        {node.children && node.expandable &&
          <Collapse
            classes={{
              root: classes.collapseContainer,
            }}
            in={nodeHasFixedExpansion || (displayOptions && displayOptions.expand)}
            unmountOnExit
          >
            {node.children
              .filter((child) => !child.hide)
              .map((child, i) => (
                <AccountRow
                  {...this.props}
                  id={`${this.props.id}-${child.displayLabel}-${i}`}
                  node={child}
                  displayOptions={
                    displayOptions && displayOptions.children ? displayOptions.children.get(child.id) : null
                  }
                  key={child.id}
                  style={{ transitionDelay: `${i * 0.05}s` }}
                />
              ))}
          </Collapse>}

        {displayGoals &&
          <>
            {/* Savings Goals section */}
            <ListItem
              button
              className={classes.listItemL3}
              onClick={(e) => {
                e.stopPropagation();
                this.props.history.push('/goals');
              }}
              key={`${node.id}-savings-goals`}
              id={`${this.props.id}-savings-goals`}
            >
              <ListItemText
                disableTypography
                primary={this.renderGoalLine('Savings Goals', (0 - node.sumGoalsAccountBalances))}
                className={classes.listItemText}
                style={{ width: '100%' }}
              />
            </ListItem>

            {/* Available Balance section */}
            <ListItem
              button
              className={classes.listItemL3}
              onClick={(e) => {
                e.stopPropagation();
                onClick(node);
              }}
              key={`${node.id}-available-balance`}
              id={`${this.props.id}-available-balance`}
            >
              <ListItemText
                disableTypography
                primary={this.renderGoalLine('Available Balance', (this.getBalance(node) + node.sumGoalsAccountBalances))}
                className={classes.listItemText}
                style={{ width: '100%' }}
              />
            </ListItem>
          </>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    institutionLoginsById: getInstitutionLoginsById(state),

    isAllInstitutionLoginsRefreshing: getIsAllInstitutionLoginsRefreshing(state),
    institutionLoginsIdsRefreshing: getInstitutionLoginIdsRefreshing(state),

    accountColorsById: preferencesSelectors.accountColorsById(state),

    featureFlags: featureFlagsSelectors.getFeatureFlags(state),
    isWebFirstDataset: authSelectors.getIsWebfirstDataset(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default compose(
  withStyles(styles, { name: 'AccountRow', withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(AccountRow));
