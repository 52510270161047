import React from 'react';
import PropTypes from 'prop-types';

import { profileSelectors } from '@quicken-com/react.flux.profile';
import { authSelectors } from '@quicken-com/react.flux.auth';

import QButton from 'components/QButton';
import { shallowEqual, useSelector } from 'react-redux';
import { safeRefIn } from 'utils/utils';
import { hasCanadaEntitlements } from 'data/entitlements/entitlementsSelectors';

const OSLabel = {
  mac: 'Mac',
  win: 'Windows',
};

const DownloadButton = (props) => {
  const profile = useSelector(profileSelectors.getProfile);
  const datasetObject = useSelector((state) => authSelectors.getCurrentDataset(state), shallowEqual);
  const isCanadaUser = useSelector(hasCanadaEntitlements);

  let isMac = datasetObject && datasetObject.platform === 'QMAC_MWF';

  if (props.overwriteOS) {
    isMac = props.overwriteOS === OSLabel.mac;
  }

  const OSBrand = OSLabel[isMac ? 'mac' : 'win'];
  const userEmail = safeRefIn(profile, ['primaryEmail', 'address']) || '';
  const macDownload = `https://download.quicken.com/mac/Quicken.dmg?email=${userEmail}`;
  const winDownload = isCanadaUser ?
    `https://download.quicken.com/windows/Quicken-Canada.exe?email=${userEmail}` :
    `https://download.quicken.com/windows/Quicken.exe?email=${userEmail}`;
  const downloadLink = isMac ? macDownload : winDownload;

  const onURLOpener = (url) => () => {
    props.onClick && props.onClick();
    window.open(url, '_blank');
  };

  return (
    <QButton
      onClick={onURLOpener(downloadLink)}
      variant="contained"
    >
      DOWNLOAD QUICKEN {OSBrand}
    </QButton>
  );
};


DownloadButton.propTypes = {
  overwriteOS: PropTypes.string,
  onClick: PropTypes.object,
};

export default DownloadButton;
