
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';

// CUSTOM
import getSymbolFromCurrency from 'currency-symbol-map';
import numeral from 'numeral';

const useStyles = makeStyles((theme) => ({
  root: {
    color: `${theme.palette.greyScaleDeprecated[0]} !important`,
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 3,
    verticalAlign: 'middle',
    border: '1px solid transparent',
  },
  normal: {
    extend: 'root',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.1),
    },
  },
  focused: {
    letterSpacing: '0.4px',
    extend: 'root',
    border: `1px solid ${theme.applyOpacityToHex(theme.palette.primary.main, 0.6)}`,
    backgroundColor: theme.palette.greyScaleDeprecated[7],
  },
  editSpan: {
    outlineWidth: 0,
    outlineStyle: 'none',
    minWidth: 4,
    padding: 3,
    paddingRight: 5,
  },
  underlineFocused: {
    extend: 'focused',
    borderRadius: 0,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
}));

// --------------------------------------------
//    Utility Functions
// --------------------------------------------
function pruneString(value) {
  let str = '';
  const legalChars = '0123456789.';
  for (let i = 0; i < value.length; i += 1) {
    // only keep legal characters (only numbers and decimals)
    str += legalChars.indexOf(value[i]) < 0 ? '' : value[i];
  }
  return str;
}
function formatNumber(value, fmtString = '0,0.00') {
  return `${numeral(Math.abs(Number(value))).format(fmtString)}`;
}
function chopValue(value, decimals = 2) {
  const periodIndex = value.indexOf('.');
  if (periodIndex > -1 && value.length > periodIndex + decimals) {
    return value.slice(0, periodIndex + decimals);
  }
  return value;
}

function signFromValue(value) {
  switch (Math.sign(value)) {
    case 1:
      return '+';
    case -1:
      return '-';
    default:
      return '';
  }
}
function operatorFromSign(operator, negator) {
  switch (operator) {
    case '+':
      return 1;
    case '-':
      return -1;
    default:
      return negator;
  }
}

function getCaretPosition(el) {
  let caretPos = 0;
  if (typeof window.getSelection !== 'undefined' && el) {
    const selection = window.getSelection();
    if (selection && selection.rangeCount) {
      const range = selection.getRangeAt(0);
      const selected = range.toString().length;
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(el);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretPos = preCaretRange.toString().length - selected;
    }
  }
  return caretPos;
}
function setCaretPosition(el, offset) {
  if (typeof window.getSelection !== 'undefined') {
    const range = document.createRange();
    const sel = window.getSelection();
    range.setStart(el.firstChild, offset);
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
  }
}


// -------------------------------------------------------------------------------
//                          FUNCTIONAL COMPONENT
// -------------------------------------------------------------------------------
function AmountSpan(props) {
  const { initialValue, negator, onBlur, className, variant, style, currencyString, showSign, onChange,
    editable, underlineOnFocus, focusedOnMount, clearFieldOnClick, id, shouldStopProp, ...otherProps } = props;
  const classes = useStyles();
  const theme = useTheme();

  //    State
  // --------------------------------------------
  const [amount, setAmount] = useState(initialValue);
  const [sign, setSign] = useState(signFromValue(initialValue));
  const [spanRef] = useState(React.createRef());
  const [focused, setFocus] = useState(false);
  const [chop, setChop] = useState(false);
  //    Effects
  // --------------------------------------------
  useEffect(() => {
    setAmount(initialValue);
    setSign(signFromValue(initialValue));
    if (spanRef && spanRef.current) {
      spanRef.current.blur();
      spanRef.current.textContent = formatNumber(initialValue);
    }
  }, [initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    focusedOnMount && spanRef && spanRef.current && spanRef.current.focus();
  }, [spanRef, focusedOnMount]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const node = spanRef && spanRef.current;
    if (node && onChange) {
      node.addEventListener('DOMSubtreeModified', handleChange);
    }
    return node && (() => node.removeEventListener('DOMSubtreeModified', handleChange));
  }, [spanRef, onChange]); // eslint-disable-line react-hooks/exhaustive-deps

  if (chop) { // truncate extra decimal digits
    const node = spanRef && spanRef.current;
    const caretPosition = getCaretPosition(node);
    node.textContent = chopValue(node.innerText);
    setCaretPosition(node, caretPosition);
    setChop(false);
  }

  //    Function Variables
  // --------------------------------------------
  let update = true; // controls how to handle the blur (don't update on escape)

  //    Functions / event handlers
  // --------------------------------------------
  const handleChange = () => {
    if (onChange && spanRef && spanRef.current) {
      onChange(Number(chopValue(pruneString(spanRef.current.innerText))));
    }
  };
  const amtFocus = () => {
    props.onFocus();
    setFocus(true);
    spanRef.current.textContent = pruneString(spanRef.current.innerText);
    update = true;
  };
  const amtKeyDown = (e) => {
    let selectedChars;
    const currentString = spanRef.current.innerText;
    const keyNum = e.keyCode || e.which;
    switch (keyNum) {
      case 8: // backspace
        break;
      case 13: // enter
        spanRef.current.blur();
        break;
      case 27: // escape
        update = false;
        spanRef.current.blur();
        break;
      case 65: // a
      case 67: // c
      case 86: // v
        if (!(e.metaKey || e.ctrlKey)) { // allow copy and paste and select
          e.preventDefault();
        }
        break;
      case 110: // decimal
      case 190: // period
        if (currentString.length > 10) {
          e.preventDefault();
        }
        if (currentString && currentString.indexOf('.') >= 0) {
          selectedChars = window.getSelection && window.getSelection().getRangeAt(0).toString();
          if (!selectedChars || selectedChars.indexOf('.') === -1) { // if period is not selected, prevent period entry
            e.preventDefault();
          }
        }
        break;
      default:
        if (e.key === '+' || e.key === '-') { // key is a sign
          setSign(e.key);
          e.preventDefault();
        } else if (keyNum === 32 || (keyNum >= 48 && keyNum <= 222)) {
          if ('0123456789'.indexOf(e.key) === -1) {
            e.preventDefault(); // invalid keys
          } else if (currentString.length > 10) {
            e.preventDefault(); // valid digit but too long
          } else {
            const caretPosition = getCaretPosition(spanRef.current);
            const periodIndex = currentString.indexOf('.');
            if (periodIndex >= 0 && caretPosition > periodIndex && currentString.length > periodIndex + 2) {
              if (caretPosition === currentString.length) {
                e.preventDefault();
              } else { // truncate post-decimal submissions
                setChop(true);
              }
            }
          }
        }
        break;
    }
  };
  const amtBlur = () => {
    setFocus(false);
    if (update) {
      const signedValue = parseFloat(spanRef.current.innerText).toFixed(2) * operatorFromSign(sign, negator);
      // update amount if different
      if (Number(signedValue) !== Number(amount)) {
        setAmount(signedValue);
        onBlur && onBlur(signedValue);
      }
      spanRef.current.textContent = formatNumber(spanRef.current.innerText);
    } else { // reformat last valid amount
      spanRef.current.textContent = formatNumber(amount);
    }
  };
  const amtPaste = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    let text = clipboardData && clipboardData.getData('Text'); // only keep textData
    text = text && pruneString(text); // only insert valid text
    window.document.execCommand('insertText', false, text);
  };
  const handleClick = (e) => {
    shouldStopProp && e.stopPropagation();
    !focused && spanRef.current.focus();
    if (clearFieldOnClick && spanRef.current.textContent === '0.00') {
      spanRef.current.textContent = '';
    }
  };
  //    Main Render / return
  // --------------------------------------------
  return (
    <div
      role="presentation"
      onClick={editable ? handleClick : () => {}}
      className={classNames((editable && (focused ? ((underlineOnFocus && classes.underlineFocused) || classes.focused) : classes.normal)) || classes.root, className)}
      style={{
        ...theme.typography[variant],
        lineHeight: '100%',
        ...style,
      }}
      id={`${id}-root`}
      sharedcomponentid={'AMOUNT_SPAN'}
      {...otherProps}
    >
      <span style={{ paddingLeft: 5 }}>
        {`${(showSign || sign !== signFromValue(negator)) ? sign : ''}${getSymbolFromCurrency(currencyString || 'N/A')}`}
      </span>
      <span
        aria-label="amount"
        id={`${id}-span`}
        role="textbox"
        tabIndex={editable ? 0 : undefined}
        onKeyDown={amtKeyDown}
        onBlur={amtBlur}
        onFocus={amtFocus}
        ref={spanRef}
        contentEditable={editable}
        className={classes.editSpan}
        onPaste={amtPaste}
      />
    </div>
  );
}

AmountSpan.defaultProps = {
  style: {},
  variant: 'body1',
  editable: true,
  currencyString: 'USD',
  showSign: false,
  negator: -1,
  id: 'amountSpan',
  underlineOnFocus: false,
  focusedOnMount: false,
  clearFieldOnClick: false,
  shouldStopProp: true,
  onFocus: () => {},
};

AmountSpan.propTypes = {
  variant: PropTypes.string,
  initialValue: PropTypes.number,
  negator: PropTypes.number,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  currencyString: PropTypes.string,
  showSign: PropTypes.bool,
  onChange: PropTypes.func,
  editable: PropTypes.bool,
  underlineOnFocus: PropTypes.bool,
  focusedOnMount: PropTypes.bool,
  clearFieldOnClick: PropTypes.bool,
  id: PropTypes.string,
  shouldStopProp: PropTypes.bool,
  onFocus: PropTypes.func,
};

export default AmountSpan;
