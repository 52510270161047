// CORE
import React, { useState, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { List as ImmutableList } from 'immutable';

import { accountsActions, accountsTypes, accountsSelectors, accountsUtils } from '@quicken-com/react.flux.accounts';

// MUI
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import ListItemText from '@mui/material/ListItemText';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// DATA
import { batchInvestmentAccounts } from 'data/accounts/actions';
import { getInstitutionLoginForId } from 'data/institutionLogins/selectors';
import { getInstitution } from 'data/institutions/selectors';

// LOCAL
import { isQuicken } from 'isAcme';
import StdDialog from 'components/Dialogs/StdDialog';
import QButton from 'components/QButton';
import bankIcon from 'assets/nav-menu/accounts.svg';
import Dump from 'components/Dump';

export const DIALOG_TYPE_ACCOUNT_EDIT = 'DIALOG_TYPE_ACCOUNT_EDIT';

const useStyles = makeStyles(({ shape, palette, spacing }) => ({
  dialogContent: {
    minWidth: 500,
    display: 'flex',
  },
  logo: {
    maxHeight: 50,
    maxWidth: 80,
    height: 'auto',
    width: 'auto',
    verticalAlign: 'sub',
    marginRight: spacing(1.25),
    backgroundColor: 'white',
    borderRadius: shape.borderRadius,
  },
  plaidLogo: {
    height: spacing(7),
    background: 'none',
    borderRadius: '50%',
    boxShadow: `0px 0px 1px 1px ${palette.greyScaleDeprecated[7]}`,
  },
  notice: {
    backgroundColor: palette.primary.light,
  },
  backgroundDefault: {
    backgroundColor: palette.background.default,
  },
}));

const AccountEditDialog = (props) => {
  const { accountId, ...otherProps } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const stdDialogRef = useRef();
  const theme = useTheme();

  const account = useSelector((state) => accountsSelectors.getAccountById(state, accountId));
  const institutionLogin = useSelector((state) => getInstitutionLoginForId(state, account?.institutionLoginId));
  const institution = useSelector((state) => getInstitution(state, institutionLogin?.institutionId));
  const institutionLogo = useMemo(() => {
    const isPlaidLogo = institutionLogin?.channel?.includes('PLAID');
    const imgSrc = isPlaidLogo ? (institution?.logo && `data:image/svg;base64, ${institution?.logo}`) : institution?.logoUrl;
    return (
      <img
        alt="institution logo"
        src={imgSrc ?? bankIcon}
        className={classNames(classes.logo, (isPlaidLogo && institution?.logo) && classes.plaidLogo)}
      />
    );
  }, [institutionLogin, institution, classes.logo, classes.plaidLogo]);
  const actualNormalizeOnlineBalance = account?.aggregators?.first?.()?.normalizeOnlineBalance;
  const [normalizeOnlineBalance, setNormalizeOnlineBalance] = useState(actualNormalizeOnlineBalance);

  // ====================================== Component State ================================================ //
  const [updatedAccountName, setUpdatedAccountName] = useState(null);

  // ====================================== Redux Selectors ================================================ //
  const accountsById = useSelector(accountsSelectors.getAccountsById);

  // ====================================== Callbacks ================================================ //
  const handleUpdateAccount = useCallback((type, params = {}) => {
    const options = { id: account.id, ...params };
    if (isQuicken && type === 'INVESTMENT') {
      dispatch(batchInvestmentAccounts([accountsTypes.mkInvestmentAccount({ type, ...options })]));
      return;
    }
    const accountData = accountsTypes.mkUpdateAccount(type, options).toJS();
    accountData.currency = undefined;
    accountData.recurringTxn = undefined;
    dispatch(accountsActions.updateAccount(accountData, { undo: { userMessage: 'Account updated' } }));
  }, [account, dispatch]);

  const handleUpdateAccountNameType = useCallback(() => {
    stdDialogRef.current?.handleClose?.();
    const params = {};
    if (updatedAccountName) {
      params.name = updatedAccountName;
    }

    const aggregator = account?.aggregators?.first?.();
    if (aggregator && Boolean(aggregator.normalizeOnlineBalance) !== Boolean(normalizeOnlineBalance)) {
      params.aggregators = ImmutableList([aggregator.merge({
        normalizeOnlineBalance,
      })]);
    }
    handleUpdateAccount(account.type, params);
  }, [handleUpdateAccount, updatedAccountName, normalizeOnlineBalance, account]);

  const nameAtInstitution = useMemo(() => accountsUtils.isActiveConnectedAccount(account) ? account.aggregators.get(0)?.accountName : null, [account]);

  const updatedAccountNameErrorMessage = useMemo(() => {
    if (updatedAccountName === '') return 'Account name cannot be empty';
    if (accountsById.filter((x) => x.name !== account?.name).some((x) => x.name.toLowerCase() === updatedAccountName?.toLowerCase())) return 'Account name must be unique';
    return null;
  }, [updatedAccountName, accountsById, account]);

  const updatedAccountNameHelperText = useMemo(() => {
    if (updatedAccountNameErrorMessage) return updatedAccountNameErrorMessage;
    return nameAtInstitution ? <span style={{ marginLeft: 15 }}>Name at institution: {nameAtInstitution}</span> : '';

  }, [updatedAccountNameErrorMessage, nameAtInstitution]);

  return (
    <StdDialog
      stdDialogRef={stdDialogRef}
      title={'Edit account'}
      showCloseButton
      {...otherProps}
    >
      <Dump obj={{ props, actualNormalizeOnlineBalance, normalizeOnlineBalance, updatedAccountName, account }} />
      <DialogContent className={classes.dialogContent}>
        {institutionLogo}
        <div style={{ marginLeft: 10, flexGrow: 1 }}>
          <TextField
            error={updatedAccountNameErrorMessage}
            label={!isQuicken ? 'Name' : ''}
            style={{ width: '100%' }}
            variant={'outlined'}
            defaultValue={account?.name}
            onChange={(e) => setUpdatedAccountName(e.target.value)}
            helperText={updatedAccountNameHelperText}
            id={'edit_account_name_field'}
          />

          { !isQuicken && ['BANK', 'CREDIT'].includes(account?.type) && account?.aggregators?.size > 0 && (
            <Box marginTop={4}>
              <Paper variant="outlined" className={classes.backgroundDefault}>
                <ListItemText
                  disableTypography
                  primary={
                    <Box display="flex" alignItems="flex-end" paddingLeft={2}>
                      <Typography variant="body2">
                        Balance with Pending&nbsp;
                      </Typography>
                      <Tooltip
                        arrow
                        placement="top"
                        title="Set this to off if your pending transactions are double counted. This happens when the balance Simplifi is starting with already has pending transactions included. This won't take effect until the next refresh from your bank."
                      >
                        <IconButton size="small">
                          <HelpIcon htmlColor={theme.palette.info.light} fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  }
                  secondary={
                    <Box paddingRight={2} paddingTop={1} paddingBottom={1}>
                      <FormControlLabel
                        // value="start"
                        control={
                          <Checkbox
                            style={{
                              alignSelf: 'flex-start',
                              paddingTop: 2,
                            }}
                            color="primary"
                            checked={Boolean(normalizeOnlineBalance)}
                            onChange={(_event, value) => setNormalizeOnlineBalance(value)}
                          />
                        }
                        label={
                          <Typography variant="caption">
                            Do not subtract pending transactions when calculating the balance with pending for this account, they are already subtracted.
                          </Typography>
                        }
                        labelPlacement="start"
                      />
                    </Box>
                  }
                />
              </Paper>

              {Boolean(account?.aggregators?.first?.()?.normalizeOnlineBalance) !== Boolean(normalizeOnlineBalance) && (
                <Box paddingTop={2}>
                  <Paper elevation={0} className={classes.notice}>
                    <Box px={2} py={1}>
                      <Typography variant="caption">
                        This will take affect after the next successful refresh with your bank.
                      </Typography>
                    </Box>
                  </Paper>
                </Box>
              )}
            </Box>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <QButton
          id={'edit_account_cancel_button'}
          variant={'outlined'}
          onClick={() => stdDialogRef.current?.handleClose?.()}
        >
          Cancel
        </QButton>

        <QButton
          variant={'contained'}
          disabled={
            (!updatedAccountName || updatedAccountName === account?.name || updatedAccountNameErrorMessage)
            && Boolean(actualNormalizeOnlineBalance) === Boolean(normalizeOnlineBalance)
          }
          onClick={handleUpdateAccountNameType}
          id={'edit_account_update_button'}
        >
          Update
        </QButton>
      </DialogActions>
    </StdDialog>
  );
};

AccountEditDialog.propTypes = {
  accountId: PropTypes.string,
};

export default AccountEditDialog;
