import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import compose from 'utils/compose';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { accountsSelectors } from '@quicken-com/react.flux.accounts';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CheckIcon from '@mui/icons-material/Check';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import withStyles from '@mui/styles/withStyles';

import QPreferences from 'components/QPreferences';

import { colorLookup } from 'data/preferences/utils';

import { getLogger } from '@quicken-com/react.utils.core';

const log = getLogger('components/QuickenControls/AccountsMenu/index.js');

const styles = (theme) => ({
  menuRoot: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  txPrefMenuWhite: {
    color: 'white',
    width: 'inherit',
  },
  txPrefMenuBlack: {
    color: theme.palette.greyScaleDeprecated[2],
    width: 'inherit',
  },
  menuHolder: {
    marginTop: -5,
    display: 'inline',
    height: 'inherit',
    width: 24,
  },
  menuSubHead: {
    paddingLeft: 7,
    paddingRight: 5,
    paddingBottom: 5,
    color: theme.palette.greyScaleDeprecated[3],
  },
  listIcon: {
    marginLeft: 0,
    marginRight: 0,
    minWidth: 0,
  },
  listText: {
    paddingLeft: 10,
  },
  popOver: {
    padding: 20,
  },
  iconFiller: {
    width: 24,
  },
  colorBar: {
    width: 10,
    height: 10,
    marginLeft: 5,
  },
  parentList: {
    paddingBottom: 0,
    '&:focus': {
      outline: 'none',
    },
  },
});

/*
 *
 * AccountsMenu
 *
 */
class AccountsMenu extends PureComponent {

  constructor(props) {

    super(props);

    const options = [];

    props.accounts.forEach((account) => {
      const color = props.accountPreferencesById[account.id]?.color;
      options.push({ color: colorLookup(color), label: account.name, value: account.id });
    });

    this.state = {
      isSelectBox: Boolean(props.selected),
      selected: props.selected,
      options,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.selected !== this.state.selected) {
      this.setState({ selected: newProps.selected });
    }
  }

  onExited = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  handleRequestClose = () => {
    log.log('REQUEST CLOSE');
  };

  handleClick = (value) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  initMenu = () => {
  };

  makeCheckListItem = (item, selected) => {

    const id = item.value;
    const { transactionRegister: regPrefs } = this.props.datasetPreferences;

    return (
      <ListItem
        dense
        button
        onClick={() => this.handleClick(id)}
        key={`qmenu_li_${item.value}`}
        disableGutters
      >
        {selected &&
          <ListItemIcon className={this.props.classes.listIcon}>
            <CheckIcon />
          </ListItemIcon>}
        {!selected &&
          <div className={this.props.classes.iconFiller} />}

        {regPrefs.showAccountColors &&
          <div
            className={this.props.classes.colorBar}
            style={{ opacity: this.props.showColors ? 1 : 1, background: item.color }}
          />}

        <ListItemText
          className={this.props.classes.listText}
          primary={item.label}
        />
      </ListItem>
    );
  };

  keyDown = (e) => {
    if (e.key === 'Enter' ||
      e.key === 'ArrowUp' ||
      e.key === 'ArrowDown' ||
      e.key === 'Tab') {
      e.stopPropagation();
    }
    if (e.key === 'Escape') {
      if (this.props.onClose) {
        e.stopPropagation();
        this.props.onClose();
      }
    }
  };

  renderOptions = (options) => {

    const data = [];
    options.forEach((item) => {

      if (item.isDivider) {
        data.push(<Divider key={uuidv4()} />);
      } else if (item.isSubheader) {
        data.push(
          <ListSubheader key={`acctmenu_sub_${item.label}`}>
            <ListItemText
              className={this.props.classes.menuSubHead}
              secondary={item.label}
            />
          </ListSubheader>
        );
      } else if (this.state.isSelectBox) {
        data.push(this.makeCheckListItem(item, this.state.selected === item.value));
      } else {
        data.push(
          <MenuItem
            key={`acctmenu_li_${item.value}`}
            button
            dense
            onClick={() => this.handleClick(item.value)}
            disableGutters
          >
            <ListItemText
              className={this.props.classes.listText}
              primary={item.label}
            />
          </MenuItem>
        );
      }
    });
    return data;
  };

  render() {
    const open = (this.props.element !== null); // Boolean(this.state.anchorEl);

    return <>
      <Menu
        id="long-menu"
        open={open}
        anchorEl={this.props.element}
        onClose={this.handleRequestClose}
        onKeyDown={this.keyDown}
        onBackdropClick={this.props.onClose || null}
        classes={{ paper: this.props.classes.menuRoot }}
        TransitionProps={{
          onExited: this.onExited,
        }}
      >
        {this.renderOptions(this.state.options)}

      </Menu>
    </>;
  }
}

AccountsMenu.propTypes = {
  selected: PropTypes.string,
  onChange: PropTypes.func,             // your onChange function, sends the new selection
  element: PropTypes.object,
  onClose: PropTypes.func,
  showColors: PropTypes.bool,

  classes: PropTypes.object,
  accounts: PropTypes.object,
  accountPreferencesById: PropTypes.object,
  datasetPreferences: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    accounts: accountsSelectors.getTransactionAccounts(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  withStyles(styles, { withTheme: true }),
  QPreferences(),
  connect(mapStateToProps, mapDispatchToProps),
)(AccountsMenu);

