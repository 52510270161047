// @flow
import { combineActions, handleActions } from 'redux-actions';
import { hash } from 'immutable';

import { resourceUpserters, resourceStoreTypes, resourceUndoUpserters, coreActions } from '@quicken-com/react.flux.core';

import * as actions from './actions';

const reducer = handleActions({

  [coreActions.sanitize]: (state) =>
    state.set('resourcesById', state.resourcesById.map((resource) => resource.merge({
      payee: hash(resource.payee).toString(),
      memo: hash(resource.memo).toString(),
    }))),

  [actions.createMemorizedRule]:
    (state, { payload: memorizedRule }) => resourceUpserters.upsertResource(state, memorizedRule),
  [actions.createMemorizedRuleSuccess]:
    (state, action) => resourceUndoUpserters.upsertResourceSetupUndo(state, action, actions.deleteMemorizedRule),

  [actions.updateMemorizedRule]:
    (state, action) => resourceUndoUpserters.upsertResourceSetupUndo(state, action, actions.updateMemorizedRule),
  [actions.updateMemorizedRuleSuccess]:
    (state, { payload: memorizedRule }) => resourceUpserters.upsertResource(state, memorizedRule),

  [actions.combineMemorizedRules]:
    (state, action) => resourceUpserters.upsertResources(state, { resources: action.payload }),
  [actions.combineMemorizedRulesSuccess]:
    (state, action) => resourceUpserters.upsertResources(state, { resources: action.payload }),

  [actions.deleteMemorizedRule]:
    (state, action) => resourceUndoUpserters.upsertResourceSetupUndo(state, action, actions.createMemorizedRule),
  [actions.deleteMemorizedRuleSuccess]:
    (state, { payload: memorizedRule }) => resourceUpserters.upsertResource(state, memorizedRule),

  [combineActions(
    actions.createMemorizedRuleFailure,
    actions.updateMemorizedRuleFailure,
    actions.deleteMemorizedRuleFailure,
    actions.combineMemorizedRulesFailure,
  )]: (state, { payload: error, asyncDispatch }) => {
    asyncDispatch(actions.getMemorizedRules());
    return resourceUpserters.resetResources(state, error);
  },

  [actions.getMemorizedRules]:
    (state) => state.merge({ isLoading: true }),
  [actions.getMemorizedRulesSuccess]:
    (state, { payload: resources }) => resourceUpserters.upsertResources(state, resources),
  [actions.getMemorizedRulesFailure]:
    (state, { payload: error }) => resourceUpserters.completeWithError(state, error),

  [actions.makeMemorizedRuleFromTransactionAction]: (state) => state,

}, resourceStoreTypes.mkQcsSyncResourceStore());

export const REDUCER_KEY = 'memorizedRuleStore';
export default reducer;
