// @flow
import { DateTime, Interval, Duration } from 'luxon';

import createCachedSelector, { LruObjectCache } from 're-reselect';
import { isAcme } from 'isAcme';

// Note: Unless specified, all timezones will be in local time (whatever that is for a given user)

let startOfToday = null;
export const getStartOfToday = () => {
  const today = DateTime.local().startOf('day');
  if (+startOfToday !== +today) {
    startOfToday = today;
  }
  return startOfToday;
};
export const getStartOfDayFromToday = createCachedSelector(
  getStartOfToday,
  (relativeValue, _) => relativeValue,
  (_, unit = 'days') => unit,
  (currentDay, relativeValue, unit) => currentDay.plus({ [unit]: relativeValue })
)(
  (relativeValue, unit = 'days') => `${relativeValue}_${unit}`
);

let startOfThisMonth = null;
export const getStartOfThisMonth = () => {
  const thisMonth = DateTime.local().startOf('month');
  if (+startOfThisMonth !== +thisMonth) {
    startOfThisMonth = thisMonth;
  }
  return startOfThisMonth;
};
let endOfThisMonth = null;
export const getEndOfThisMonth = () => {
  const thisMonth = DateTime.local().endOf('month');
  if (+endOfThisMonth !== +thisMonth) {
    endOfThisMonth = thisMonth;
  }
  return endOfThisMonth;
};
export const getStartOfMonthFromThisMonth = createCachedSelector(
  getStartOfThisMonth,
  (relativeMonths) => relativeMonths,
  (thisMonth, relativeMonths) => thisMonth.plus({ months: relativeMonths })
)(
  (relativeMonths) => relativeMonths
);

let startOfThisYear = null;
export const getStartOfThisYear = () => {
  const thisYear = DateTime.local().startOf('year');
  if (+startOfThisYear !== +thisYear) {
    startOfThisYear = thisYear;
  }
  return startOfThisYear;
};
export const getStartOfYearFromThisYear = createCachedSelector(
  getStartOfThisYear,
  (relativeYears) => relativeYears,
  (thisYear, relativeYears) => thisYear.plus({ years: relativeYears })
)(
  (relativeYears) => relativeYears
);

export const getInterval = createCachedSelector(
  (startDate, _) => startDate,
  (_, endDate) => endDate,
  (startDate, endDate) =>
    (startDate <= endDate) ? Interval.fromDateTimes(startDate, endDate) : Interval.fromDateTimes(endDate, startDate)
)(
  (startDate, endDate) => `${startDate}_${endDate}`,
  {
    cacheObject: new LruObjectCache({ cacheSize: 50 }),
  }
);


export const getPredefinedRange = (predefinedRange) => {
  switch (predefinedRange.value) {
    case 'TODAY':
      return getInterval(getStartOfToday(), getStartOfDayFromToday(1));
    case 'MONTH_TO_TODAY':
      return getInterval(getStartOfThisMonth(), getStartOfDayFromToday(1));
    case 'THIS_MONTH':
      return getInterval(getStartOfThisMonth(), getEndOfThisMonth());
    case 'YEAR_TO_TODAY':
      return getInterval(getStartOfThisYear(), getStartOfDayFromToday(1));
    case 'LAST_30_DAYS':
      return getInterval(getStartOfDayFromToday(-29), getStartOfDayFromToday(1));
    case 'LAST_2_MONTHS':
      return getInterval(getStartOfMonthFromThisMonth(-1, 'months'), getEndOfThisMonth());
    case 'LAST_3_MONTHS':
      return getInterval(getStartOfMonthFromThisMonth(-2, 'months'), getEndOfThisMonth());
    case 'LAST_6_MONTHS':
      return isAcme ? getInterval(getStartOfMonthFromThisMonth(-5, 'months'), getEndOfThisMonth()) :
        getInterval(getStartOfDayFromToday(-6, 'months'), getStartOfDayFromToday(+1));
    case 'LAST_12_MONTHS':
      return isAcme ? getInterval(getStartOfMonthFromThisMonth(-11, 'months'), getEndOfThisMonth()) :
        getInterval(getStartOfDayFromToday(-1, 'year'), getEndOfThisMonth());
    case 'LAST_MONTH':
      return getInterval(getStartOfMonthFromThisMonth(-1), getStartOfThisMonth());
    case 'LAST_YEAR':
      return getInterval(getStartOfYearFromThisYear(-1), getStartOfThisYear());
    case '6_MONTHS':
      return getInterval(getStartOfMonthFromThisMonth(-5), getStartOfMonthFromThisMonth(1));
    case '12_MONTHS':
      return getInterval(getStartOfMonthFromThisMonth(-11), getStartOfMonthFromThisMonth(1));

      // Not compatible, but used by web first
    case '3_MONTHS_PRIOR':
      return getInterval(getStartOfMonthFromThisMonth(-3), getStartOfThisMonth());
    case '6_MONTHS_PRIOR':
      return getInterval(getStartOfMonthFromThisMonth(-6), getStartOfThisMonth());
    case '12_MONTHS_PRIOR':
      return getInterval(getStartOfMonthFromThisMonth(-12), getStartOfThisMonth());

    case 'CUSTOM':
      return getInterval(predefinedRange.startDate, predefinedRange.endDate);

    default:
      // todo: should log an error here
      break;
  }
  return null;
};


// For Reference, Date Settings_Unused Supported by QMAC & QWIN

// This Month       - Start of Current Month to Start of Current Month + 1
// This Quarter     - Start of Current Quarter to Start of Current Quarter + 1
// This Year        - Start of Current Year to Start of Current Year + 1
//
// Last Week (Windows Only)
// Last Month       - Start of Current Month - 1 to Start of Current Month
// Last Quarter     - Start  of Current Quarter - 1 to Start of Current Quarter
// Last Year        - Start of Current Year - 1 to Start of Current Year
//

// Week to Date (Windows Only)
// Month to Date                       - Start of Current Month - Start of Today + 1
// Quarter to Date                     - Start of Current Quarter - Start of Today + 1
// Year to Date                        - Start of Current Year - Start of Today + 1
// Earliest to Date (Windows Only)     - Epoch to Start of Today + 1
// Custom to Date (Windows Only)

// Last 7 Days                         - Start of Current Day - 6 -> Start of Today + 1
// Last 14 Days (MAC Only)             - Start of Current Day - 13 -> Start of Today + 1
// Last 30 Days                        - Start of Current Day - 29 -> Start of Today + 1
// Last 60 Days (MAC Only)             - Start of Current Day - 60 -> Start of Today + 1
// Last 90 Days (MAC Only)             - Start of Current Day - 90 -> Start of Today + 1
// Last 12 Months                      - Start of Current Day - (12 months) -> Start of Today + 1

// Custom Range

// Daily              - Windows has bug, only shows today and last two days (assume it should show current and past 30 days
// Monthly            - Windows shows current month and past 12 months
// Yearly             - Windows shows current year and past N years

export const getSmartDateLabel = (isoDate) => {
  const today = DateTime.local().startOf('day');
  const txnDate = DateTime.fromISO(isoDate).startOf('day');
  const dayDiff = txnDate.diff(today, 'days').days;

  // UI STUFF BELOW
  // # -------------------------------------------
  // date formatting for render
  let dayString = 'someday';
  const absDiff = Math.abs(dayDiff);
  switch (dayDiff) {
    case 0:
      dayString = 'today';
      break;
    case -1:
      dayString = 'yesterday';
      break;
    case 1:
      dayString = 'tomorrow';
      break;
    default:
      if (absDiff > 6) {
        if (txnDate.year === today.year) {
          dayString = `on ${txnDate.toFormat('LLL d')}`;
        } else {
          dayString = `on ${txnDate.toFormat('LLL d yyyy')}`;
        }
      } else {
        dayString = dayDiff > 0 ? `In ${dayDiff} days` : `${absDiff} days ago`;
      }
      break;
  }

  return dayString;
};

export const getSmartTimeLabel = (isoDateTime) => {
  let label = '-';

  const now = DateTime.local();
  const dateTime = DateTime.fromISO(isoDateTime);
  if (verify(dateTime.isValid, `getSmartTimeLabel: invalid isoDateTime = ${isoDateTime}`)) {
    const duration = Duration.fromMillis(now.toMillis() - dateTime.toMillis());
    const minutes = Math.floor(duration.as('minutes'));

    if (minutes < 0) {
      label = dateTime.toLocaleString(DateTime.DATE_SHORT);
    } else if (minutes < 1) {
      label = 'now';
    } else if (minutes === 1) {
      label = '1 minute';
    } else if (minutes < 60) {
      label = `${minutes} minutes`;
    } else {
      const hours = Math.floor(duration.as('hours'));
      if (hours === 1) {
        label = '1 hour';
      } else if (hours < 24) {
        label = `${hours} hours`;
      } else {
        const days = Math.floor(duration.as('days'));
        if (days === 1) {
          label = '1 day';
        } else if (days < 365) {
          label = `${days} days`;
        } else {
          label = dateTime.toLocaleString(DateTime.DATE_SHORT);
        }
      }
    }
  }

  return label;
};
