import React from 'react';
import compose from 'utils/compose';
import classNames from 'classnames';

import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import FlagIcon from '@mui/icons-material/Flag';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';

import { flagFieldOptions } from 'components/TransactionRegister/transactionsConfig';


const styles = () => ({
  buttonRoot: {
    padding: 0,
    minWidth: 'unset',
  },
  iconRoot: {
    fontSize: 20,
  },
});

class FlagField extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
    this.btnRef = null;
    this.actions = null;
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
    event.stopPropagation();
  };

  handleClose = (item) => {
    this.setState({ anchorEl: null });
    if (this.props.onChange && item) {
      const e = { target: { value: item.value ? String(item.value) : null } };
      this.props.onChange(e);
    }
    setTimeout(this.setBtnFocus, 100);
  };

  onKeyDown = (event) => {
    if (event.key === 'Escape' || event.key === 'Enter') {
      event.stopPropagation();
    }
    if (event.key === 'Escape') this.handleClose(null);
  };

  setBtnFocus = () => {
    if (this.btnRef && this.actions) {
      this.btnRef.focus();
      this.actions.focusVisible();
    }
  };

  getRef = (x) => {
    this.btnRef = x;
    if (this.props.inputRef) {
      this.props.inputRef(x);
    }
  };


  makeMenuItem = (item) => {

    const text = item.label;
    const showText = false;

    if (!item.inMenu) return '';
    return (
      <MenuItem key={`flagmenu:${item.label}`} onClick={() => this.handleClose(item)}>
        <ListItemIcon>
          {item.value !== 'NONE' ?
            <FlagIcon
              style={{
                fontSize: 20,
                color: item.color,
              }}
            />
            :
            <OutlinedFlagIcon
              style={{
                fontSize: 20,
                color: this.props.theme.components.payeeField.displayTemplate,
              }}
            />}
        </ListItemIcon>
        {showText &&
          <ListItemText>
            {text}
          </ListItemText>}
      </MenuItem>
    );
  };

  render() {
    const { anchorEl } = this.state;
    const { value, autoFocus, inputRef, onChange, classes, editable, includeEmpty, show, ...other } = this.props;

    const menuItems = flagFieldOptions.map((item) => this.makeMenuItem(item));
    if (includeEmpty) {
      menuItems.push(this.makeMenuItem({ label: ' No Change', value: null }));
    }

    const currItemRef = value ? flagFieldOptions.find((x) => String(x.value) === String(value)) : null;

    return (
      <div
        {...other}
      >
        {show &&
          <Button
            {...other}
            classes={{ root: classes.buttonRoot }}
            autoFocus={autoFocus}
            aria-owns={anchorEl ? 'flagField-menu' : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
            ref={this.btnRef}
            action={(actions) => {
              if (autoFocus && actions) {
                this.actions = actions;
                actions.focusVisible();
              }
            }}
          >
            {(currItemRef && currItemRef.value !== 'NONE') ?
              <FlagIcon
                style={{
                  color: currItemRef.color,
                }}
                className={classes.iconRoot}
              />
              :
              <OutlinedFlagIcon
                className={classNames(classes.iconRoot, editable ? 'editable' : '')}
              />}
          </Button>}
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => this.handleClose(null)}
          onKeyDown={this.onKeyDown}
        >
          {menuItems}
        </Menu>
      </div>
    );
  }
}

FlagField.propTypes = {
  value: PropTypes.string,
  autoFocus: PropTypes.bool,
  inputRef: PropTypes.func,
  onChange: PropTypes.func,
  editable: PropTypes.bool,
  includeEmpty: PropTypes.bool,
  show: PropTypes.bool,

  classes: PropTypes.object,
  theme: PropTypes.object,
};


export default compose(
  withStyles(styles, { withTheme: true }),
)(FlagField);
