import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { List } from 'immutable';

import { accountsSelectors } from '@quicken-com/react.flux.accounts';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import StdDialogActions from 'components/Dialogs/StdDialogActions';
import type { InstitutionLogin } from 'data/institutionLogins/types';

type ExistingInstitutionLoginProps = {
  institutionLogin: InstitutionLogin,
}

const ExistingInstitutionLogin = ({ institutionLogin } : ExistingInstitutionLoginProps) => {
  const accounts = useSelector((state) => accountsSelectors.getAccountsForInstitutionLoginId(state, { institutionLoginId: institutionLogin.id }));
  const theme = useTheme();

  return (
    <Box display="flex" flex="1" flexDirection="column" key={institutionLogin.id}>
      <FormControlLabel value={institutionLogin} control={<Radio />} label={institutionLogin.name} />
      <Box display="flex" mt={-2} mx={3} px={1} py={0.5}>
        <Typography variant="caption" style={{ color: theme.palette.grey.level6 }}>
          {`Linked Accounts: ${accounts && accounts.map((account) => ` ${account && account.name}`).toArray()}`}
        </Typography>
      </Box>
    </Box>
  );
};

type Props = {
  existingLoginsAtInstitution: List<InstitutionLogin>,
  fiBrandingIndent: number,
  onSelect: (any) => void,
}

const ExistingLoginPicker = (props: Props) => {
  const { existingLoginsAtInstitution, fiBrandingIndent, onSelect } = props;
  const [value, setValue] = useState();

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSelect(value);
  };

  return (
    <Box component="form" display="flex" flex="1" flexDirection="column" mt={2} ml={`${fiBrandingIndent}px`} mr={3} onSubmit={handleSubmit}>
      <Box display="flex" flex="1" flexDirection="column">
        <Typography variant="subtitle1">
          Select which existing connection you&apos;d like to update.
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup aria-label="existing_connection" name="connection" onChange={handleRadioChange}>
            {existingLoginsAtInstitution.map((institutionLogin) =>
              <ExistingInstitutionLogin institutionLogin={institutionLogin} />)}
          </RadioGroup>
        </FormControl>
      </Box>
      <StdDialogActions
        primaryDisabled={!value}
        primaryLabel="Continue"
        primaryOnClick={handleSubmit}
      />
    </Box>
  );
};

export default ExistingLoginPicker;

