import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isAcme } from 'isAcme';

import { scheduledTransactionsUtils, scheduledTransactionsSelectors } from '@quicken-com/react.flux.scheduled-transactions';
import { chartOfAccountsSelectors } from '@quicken-com/react.flux.chart-of-accounts';
import { transactionsUtils } from '@quicken-com/react.flux.transactions';

// MUI
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Avatar from '@mui/material/Avatar';
import DialogContent from '@mui/material/DialogContent';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';

// DATA
import { getAccountString } from 'data/accounts/retrievers';
import { isIncomeTxn } from 'data/transactions/selectors';

// CUSTOM
import AmountField, { ShowSignEnum, ShowColorEnum } from 'components/QuickenControls/AmountField';
import QButton from 'components/QButton';
import QTypography from 'components/MUIWrappers/QTypography';
import StdDialog from 'components/Dialogs/StdDialog';
import SearchBox from 'components/SearchBox';
import ZeroStateView from 'components/ZeroStateView';
import zeroStateIcon from 'assets/zero-state-images/safebox.svg';

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    paddingTop: 16,
  },
  searchRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 12,
  },
  searchBar: {
    height: 40,
    paddingLeft: 4,
    borderRadius: 12,
    backgroundColor: theme.palette.greyScaleDeprecated[7],
    border: `1px solid ${theme.palette.greyScaleDeprecated[4]}`,
    margin: 'auto 0 0 4px',
    '&.open': {
      width: 242,
    },
    '&:hover': {
      borderColor: theme.palette.greyScaleDeprecated[0],
    },
    '&:focus-within': {
      borderColor: theme.palette.primary.main,
    },
  },
  searchInput: {
    fontSize: '14px',
    lineHeight: 1.43,
    fontWeight: 'normal',
    color: isAcme ? theme.palette.searchInput : '#000000',
    '&:-webkit-autofill': { // disable autofill color because it makes it ugly
      '-webkit-background-clip': 'text',
    },
  },
  zeroStateView: {
    margin: 2,
    minHeight: 200,
  },
  containerListItem: {
    height: 72,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.14)',
    borderRadius: 8,
    border: `solid 1px ${theme.palette.color7.opacity30}`,
    margin: '4px 0',
  },
  textColor: {
    color: theme.palette.text.secondary,
  },
  listItemButton: {
    borderRadius: 7,
  },
  actionRow: {
    display: 'flex',
    paddingTop: 16,
    alignItems: 'center',
  },
  canceledChip: {
    color: theme.palette.greyScaleDeprecated[7],
    backgroundColor: theme.palette.greyScaleDeprecated[2],
    padding: '4px 8px',
    borderRadius: 4,
    marginLeft: 4,
  },
}));

const SelectScheduledTransactionDialog = (props) => {
  const classes = useStyles();
  const { onSelected, onCreate, modelsOverride, txn, ...otherProps } = props;
  const scheduledTransactions = useSelector((state) => modelsOverride || scheduledTransactionsSelectors.getUserVerifiedScheduledTransactions(state));
  const [search, setSearch] = useState();

  const height = (scheduledTransactions.size * 76) - 4;

  const isIncome = useSelector((state) => isIncomeTxn(state, txn));
  const isCredit = useSelector((state) => chartOfAccountsSelectors.isCreditBill(state, txn?.accountId, txn?.coa));
  const isTransfer = transactionsUtils.isUITransferTxn(txn) && !isCredit;

  const filteredScheduledTransactions = useMemo(() => scheduledTransactions
    .filter((scheduledTransaction) => {
      let include = true;
      if (search) {
        include = scheduledTransaction.transaction.payee.toLowerCase().includes(search);
        if (!include) {
          const frequencyAlias = scheduledTransactionsUtils.frequencyAliasFromRecurrence(scheduledTransaction.recurrence);
          include = frequencyAlias?.toLowerCase()?.includes(search);
        }
        if (!include) {
          include = String(scheduledTransaction.transaction.amount).includes(search);
        }
        if (!include) {
          const accountString = getAccountString(scheduledTransaction.transaction.accountId);
          include = accountString?.toLowerCase()?.includes(search);
        }
      }
      return include;
    })
    .toList()
    .sort((ST1, ST2) =>
      ST1.isCompleted !== ST2.isCompleted ? ((0 + ST1.isCompleted) || -1) : // show completed last
        ST1.transaction.payee?.localeCompare(ST2.transaction.payee, undefined, {
          sensitivity: 'base',
          numeric: true,
          caseFirst: 'upper',
        })), [scheduledTransactions, search]);

  let copy = 'series';
  let placeholder = 'series';
  if (isTransfer) {
    copy = 'transfer';
    placeholder = 'transfers';
  } else if (isIncome) {
    copy = 'income';
    placeholder = 'income';
  } else {
    copy = 'bill or subscription';
    placeholder = 'bills & subscriptions';
  }

  return (
    <StdDialog
      title={`Link to existing ${copy}`}
      fullWidth
      maxWidth="md"
      {...otherProps}
    >
      <DialogContent className={classes.dialogRoot}>
        <div className={classes.searchRow}>
          <QTypography variant="body1" className={classes.textColor}>
            Choose a recurrence to link the transaction to
          </QTypography>
          <SearchBox
            onSearch={(key) => setSearch(key && key.toLowerCase())}
            autoSearch
            autoFocus
            collapsable={false}
            placeholder={`Search ${placeholder}`}
            classes={{
              root: classes.searchBar,
              input: classes.searchInput,
            }}
          />
        </div>
        {!filteredScheduledTransactions.size ?
          <ZeroStateView
            primary="We couldn't find any scheduled transactions"
            icon={zeroStateIcon}
            secondary="Try resetting the search"
            size="small"
            className={classes.zeroStateView}
            style={{ height }}
          />
          :
          <List
            component="nav"
            aria-label="setup recurring options"
            style={{ padding: 0, height, minHeight: 220 }}
          >
            {filteredScheduledTransactions.map((scheduledTransaction, index) => (
              <React.Fragment key={scheduledTransaction.id || scheduledTransaction.clientId}>
                <ListItem
                  id={`existing-recurring-transaction-${index}`}
                  button
                  onClick={() => onSelected?.(scheduledTransaction)}
                  classes={{
                    container: classes.containerListItem,
                    button: classes.listItemButton,
                  }}
                >
                  <ListItemIcon>
                    <Avatar>{scheduledTransaction.transaction.payee?.[0].toUpperCase()}</Avatar>
                  </ListItemIcon>

                  <ListItemText
                    primary={
                      <>
                        {scheduledTransaction.transaction.payee}
                        {scheduledTransaction.isCompleted &&
                          <Typography variant="caption" className={classes.canceledChip}>canceled</Typography>}
                      </>
                    }
                    primaryTypographyProps={{ noWrap: true }}
                    secondary={scheduledTransactionsUtils.frequencyAliasFromRecurrence(scheduledTransaction.recurrence)}
                    sx={{ flexGrow: 2 }}
                  />

                  <ListItemText
                    primary={
                      <AmountField
                        showSign={ShowSignEnum.POSITIVE_ONLY}
                        showColor={ShowColorEnum.POSITIVE_ONLY}
                        value={scheduledTransaction.transaction.amount}
                      />
                    }
                    secondary={getAccountString(scheduledTransaction.transaction.accountId)}
                    secondaryTypographyProps={{ style: { textAlign: 'right' }, noWrap: true }}
                    primaryTypographyProps={{ style: { textAlign: 'right' } }}
                  />

                  <ListItemSecondaryAction>
                    <NavigateNextIcon />
                  </ListItemSecondaryAction>
                </ListItem>
              </React.Fragment>
            ))}
          </List>}
        {onCreate &&
          <div className={classes.actionRow}>
            <QTypography variant="body1" className={classes.textColor} style={{ marginRight: 8 }}>
              Don&apos;t see it?
            </QTypography>

            <QButton
              onClick={onCreate}
            >
              Create new recurrence
            </QButton>

          </div>}
      </DialogContent>
    </StdDialog>
  );
};

SelectScheduledTransactionDialog.propTypes = {
  onSelected: PropTypes.func,
  onCreate: PropTypes.func,
  modelsOverride: PropTypes.object, // if provided, uses these models instead of calling a selector to pull them
  txn: PropTypes.object, // if provided, displays type-specific UI
};

export default SelectScheduledTransactionDialog;
