import React, { PureComponent } from 'react';
import compose from 'utils/compose';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment';
import { formatNumber } from 'components/QuickenControls/AmountField';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import DoneIcon from '@mui/icons-material/DoneRounded';

import QTip from 'components/QuickenControls/QTip';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { getFieldString } from 'data/transactions/searchFilter';

// import { getLogger } from 'utils/logger';
// const log = getLogger('components/QuickenControls/TransactionSelectList/index.js');

const styles = (theme) => ({

  txRow: {
    display: 'flex',
    flexFlow: 'row',
    textAlign: 'left',
    width: '100%',
    cursor: 'pointer',
  },
  txField: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '&.cleared': {
      fontWeight: 500,
    },
  },
  listRoot: {
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 0,
    paddingBottom: 2,
  },
  listFooter: {
    overflow: 'visible',
  },
  selectedItem: {
    backgroundColor: theme.palette.blue5,
  },
});

class TransactionSelectList extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      totalAmount: props.transactions.reduce((v, txn) => v + (txn.state === 'CLEARED' ? Number(txn.amount) : 0), 0.0),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.transactions !== this.props.transactions) {
      this.setState({ totalAmount: nextProps.transactions.reduce((v, txn) => v + (txn.state === 'CLEARED' ? Number(txn.amount) : 0), 0) });
    }
  }


  getStringForField = (txn, field) => {

    switch (field) {
      case 'posting':
        return txn.cpData ? moment(txn.cpData.postedOn).format('MM/DD/YYYY') : '';
      case 'postedOn':
        return moment(txn.postedOn).format('MM/DD/YYYY');

      case 'state':
        return txn.state === 'CLEARED' ? <DoneIcon style={{ color: this.props.theme.palette.green1 }} /> : '';


      default:
        return getFieldString(field, txn.toJS());
    }
  };

  transactionClick = (txn) => {
    if (this.props.onClick) {
      this.props.onClick(txn);
    }
  };

  renderTransactions = (title, transactions, cols) => {

    const { classes } = this.props;

    const ret = [];
    transactions.reverse().forEach((txn) => {
      ret.push(
        <MenuItem
          key={`txrowrecon:${title}-${txn.id}`}
          classes={{ selected: classes.selectedItem, root: classes.listRoot }}
          onClick={() => this.transactionClick(txn)}
          divider
        >
          <div
            className={this.props.classes.txRow}
          >
            {this.renderTxRow(title, txn, cols)}
          </div>
          <Divider />
        </MenuItem>
      );
    });
    return (
      <MenuList
        classes={{ root: classes.listRoot }}
        dense
        disablePadding
        onKeyDown={this.onKeyDown}
      >
        {ret}
      </MenuList>
    );
  };

  renderTxRow = (title, txn, cols) => {

    const ret = [];
    cols.forEach((x) => {
      ret.push(
        <div
          key={`${title}:${txn.id}:${x.label}`}
          style={{
            flex: x.flex,
            textAlign: x.align,
            paddingLeft: 2,
            paddingRight: 2,
            minWidth: x.minWidth,
            overflow: 'hidden',
          }}
        >
          <QTip
            wrapOnly
            title={String(this.getStringForField(txn, x.field))}
          >
            <Typography
              variant="body2"
              className={classNames(this.props.classes.txField, txn.state === 'CLEARED' ? 'cleared' : '')}
            >
              {this.getStringForField(txn, x.field)}
            </Typography>
          </QTip>
        </div>
      );
    });
    return ret;
  };

  render() {

    const { title, className, transactions, theme, classes, currencyString, accountType } = this.props;


    let cols = [
      { label: 'Clr', flex: 1, field: 'state', align: 'center' },
      { label: 'Date', flex: 2, field: 'postedOn', align: 'left', minWidth: 85 },
      { label: 'Posted', flex: 2, field: 'posting', align: 'left', minWidth: 85 },
    ];
    if (accountType !== 'CREDIT') {
      cols.push({ label: 'Chk#', flex: 2, field: 'check', align: 'left' });
    }
    cols = cols.concat([
      { label: 'Payee', flex: 3, field: 'payee', align: 'left' },
      { label: 'Amount', flex: 2, field: 'amount', align: 'right', padding: 2 },
    ]);

    return (
      <>
        <List
          className={className}
          classes={{ root: classes.listRoot }}
          dense
          disablePadding
          subheader={<div />}
          onKeyDown={this.props.onKeyDown}
          id={this.props.id}
        >

          <ListSubheader disableGutters style={{ background: theme.palette.greyScaleDeprecated[7] }}>
            <div className={classes.listRoot}>
              <div style={{ width: '100%', background: theme.palette.blue4, textAlign: 'center' }}>
                <Typography variant="subtitle2">
                  {title}
                </Typography>
              </div>
            </div>

            <div className={classes.listRoot}>
              <div style={{ width: '100%', display: 'flex', flexFlow: 'row' }}>
                {cols.map((x) => (
                  <div
                    key={`${title}:header:${x.label}`}
                    style={{
                      paddingRight: x.padding,
                      flex: x.flex,
                      textAlign: x.align,
                      minWidth: x.minWidth,
                      background: theme.palette.greyScaleDeprecated[5],
                    }}
                  >
                    <Typography variant="subtitle2">
                      {x.label}
                    </Typography>
                  </div>))}
              </div>
            </div>
          </ListSubheader>

          {this.renderTransactions(title, transactions, cols)}

          {this.props.showTotals &&

          <ListItem classes={{ root: classes.listRoot }}>
            <div style={{ width: '100%', background: theme.palette.greyScaleDeprecated[6], display: 'flex', flexFlow: 'row', textAlign: 'center' }}>
              {cols.map((x) => (
                <div
                  key={`${title}:footer:${x.label}`}
                  style={{
                    wordBreak: 'keep-all',
                    flex: x.field === 'amount' || x.field === 'state' ? 4 : 0,
                    textAlign: x.field === 'state' ? 'left' : x.align,
                    minWidth: x.field === 'amount' || x.field === 'state' ? x.minWidth : null,
                    paddingLeft: 4,
                    paddingRight: 4,
                  }}
                >
                  <Typography variant="subtitle2" className={classes.listFooter}>
                    {x.field === 'state' ? `Cleared ${this.props.title}` : ''}
                    {x.field === 'amount' ? formatNumber(this.state.totalAmount, currencyString, '0,00.00') : ''}
                  </Typography>
                </div>))}
            </div>
          </ListItem>}
        </List>
      </>
    );

  }
}
TransactionSelectList.propTypes = {
  transactions: PropTypes.object,
  accountType: PropTypes.string,
  title: PropTypes.string,
  showTotals: PropTypes.bool,
  onClick: PropTypes.func,
  theme: PropTypes.object,
  onKeyDown: PropTypes.func,
  id: PropTypes.string,
  currencyString: PropTypes.string,
  className: PropTypes.string,

  classes: PropTypes.object,
};

export default compose(
  withStyles(styles, { withTheme: true }),
)(TransactionSelectList);

