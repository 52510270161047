import * as React from 'react';
import PropTypes from 'prop-types';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import withTheme from '@mui/styles/withTheme';

import keycode from 'keycode';

import { getLogger } from '@quicken-com/react.utils.core';

const log = getLogger('SelecNestedMenu');

// select component sends following values to child
//   onClick: this.handleItemClicked
//   role: 'option'
//   selected,
//   value: undefined,
//   'data-value': child.props.value

class SelectNestedMenu extends React.PureComponent {

  anchor = null;

  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    event.stopPropagation();
    const { selectable } = this.props;

    if (selectable) {
      log.debug('Selecting nested menu parent item');
      this.setState({ anchorEl: null });
      this.props.onClick();
    } else {
      log.debug('Opening nested menu');
      this.setState({ anchorEl: this.anchor });
    }
  };

  handleArrowClick = (event) => {
    event.stopPropagation();
    this.setState({ anchorEl: this.anchor });
  };

  handleRequestClose = (event) => {
    log.debug('Handling Request close...');
    this.setState({ anchorEl: null });

    if (this.props.onChildRequestClose) {
      this.props.onChildRequestClose(event);
      log.log('Test');
    }

    let closeAll = false;
    if (event instanceof KeyboardEvent) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        closeAll = false;
      }
    }

    // A bit of a hack, but we have no other way of closing the whole tree
    // without cooperating with the parent menu
    if (closeAll) {
      document.querySelectorAll('div[class^="MuiBackdrop-"]').forEach((backdrop) => {
        backdrop.click();
      });
    }
  };

  handleClickAway = (e) => {
    log.debug('Nested Clicked away');
    this.handleRequestClose(e);
  };

  handleBackdropClick = (e) => {
    log.debug('BackdropClick');
    e.stopPropagation(e);
  };

  onChildClick = (child) => (event) => {
    event.stopPropagation();
    if (this.props.onNestedItemClick) {
      this.props.onNestedItemClick(child, event);
    }
  };

  handleBlur = (_event) => {
    // if (this.ignoreNextBlur === true) {
    //   // The parent components are relying on the bubbling of the event.
    //   event.stopPropagation();
    //   this.ignoreNextBlur = false;
    //   return;
    // }
    //
    // if (this.props.onBlur) {
    //   const { value, name } = this.props;
    //   event.persist();
    //   event.target = { value, name };
    //   this.props.onBlur(event);
    // }
  };

  handleKeyDown = (event) => {
    log.debug('KeyDown...');
    event.stopPropagation();
    if (['space', 'right'].indexOf(keycode(event)) !== -1) {
      event.preventDefault();
      // Opening the menu is going to blur the. It will be focused back when closed.
      this.ignoreNextBlur = true;
      this.setState({ anchorEl: this.anchor });
    }
    if (['left'].indexOf(keycode(event)) !== -1) {
      event.preventDefault();
      // Closing the menu is going to blur the. It will be focused back when closed.
      this.ignoreNextBlur = true;
      this.setState({ anchorEl: null });
    }
    // event.preventDefault();
    // event.stopPropagation();

  };


  render() {
    const { children, MenuItemLabel, selectable, theme } = this.props;
    const { anchorEl } = this.state;

    return (
      <MenuItem
        id={typeof MenuItemLabel === 'string' ? MenuItemLabel.replace(/\s/g, '') : MenuItemLabel.id}
        dense
        onBlur={this.handleBlur}
        onClick={this.handleClick}
        onKeyDown={this.handleKeyDown}
      >
        {MenuItemLabel}
        <ArrowDropDown
          style={{ float: 'right', transform: 'rotate(-90deg)', marginLeft: 'auto', color: theme.palette.text.primary }}
          onClick={selectable ? this.handleArrowClick : undefined}
          tabIndex={0}
        />
        <div
          ref={(el) => { this.anchor = el; }}
          style={{ position: 'absolute', right: 0 }}
        />
        <Menu
          autoFocus
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handleRequestClose}
          onBackdropClick={this.handleBackdropClick}
        >
          {React.Children.map(children, (menuItem) => (
            React.isValidElement(menuItem)
              ? React.cloneElement((menuItem), {
                // onKeyDown: this.handleKeyDown,
                onClick: this.onChildClick(menuItem),
                // ...(menuItem.props),
              })
              : menuItem
          ))}
        </Menu>
      </MenuItem>
    );
  }
}

SelectNestedMenu.propTypes = {
  children: PropTypes.any,
  selectable: PropTypes.bool,
  MenuItemLabel: PropTypes.node,

  onClick: PropTypes.func,
  onChildRequestClose: PropTypes.func,
  onNestedItemClick: PropTypes.func,
  theme: PropTypes.object,
};

export default withTheme(SelectNestedMenu);
