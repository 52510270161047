
import { List, Map as ImmutableMap, Record } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';

import type { QcsSyncResourceStoreProps } from '@quicken-com/react.flux.core/resourceStoreTypes';


type FieldEncryptionProps = {
  id: string,
  expiresAt: string,
  algorithm: string,
  publicKey: string,
  signatureAlgorithm: string,
  signature: string,
}
export type FieldEncryption = RecordOf<FieldEncryptionProps>;
export const mkFieldEncryption: RecordFactory<FieldEncryptionProps> =
  Record({
    id: undefined,
    expiresAt: undefined,
    algorithm: undefined,
    publicKey: undefined,
    signatureAlgorithm: undefined,
    signature: undefined,
  });


type BillerProps = {
  id: string,
  name: string,

  websiteUrl: string,
  loginUrl: string,

  resetPasswordURL: string,
  createAccountURL: string,

  accountType: string,
  credentials: Array,

  refreshingEnabled: boolean,
  fieldEncryption: FieldEncryption,

  logo: ?any,
}
export type Biller = RecordOf<BillerProps>;
export const mkBiller: RecordFactory<BillerProps> =
  Record({
    id: undefined,
    name: undefined,

    websiteURL: undefined,
    loginURL: undefined,

    resetPasswordURL: undefined,
    createAccountURL: undefined,

    accountType: undefined,
    credentials: undefined,

    refreshingEnabled: false,
    fieldEncryption: mkFieldEncryption(),

    logo: undefined,
  });

type BillerStoreProps = QcsSyncResourceStoreProps & {
  popularIds: List
}
export type BillerStore = RecordOf<BillerStoreProps>;
export const mkBillerStore: RecordFactory<BillerStoreProps> =
  Record({
    resourcesById: ImmutableMap(),
    lastSyncDate: null,

    loadPending: true,
    isLoading: false,

    error: null,

    popularIds: null,
  });



