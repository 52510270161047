export const normalizePayeeQCS = (payee) => payee ?
  payee.replace(/['"]/g, '')
    .replace(/[.,/#!$%^*;:{}=\-_`~()]/g, ' ')
    .replace(/ +/g, ' ')
    .replace(/ ?& ?/g, '&')
    .trimStart()
    .toLowerCase() :
  payee;

export const containsPayeeQCS = (inferredPayee, renamePayeeFrom) => {
  let payee = inferredPayee;
  const contains = payee && renamePayeeFrom && renamePayeeFrom
    .split(' ')
    .every((keyword) => {
      const indexFound = payee.indexOf(keyword);
      if (indexFound >= 0) {
        payee = payee.slice(indexFound + keyword.length, payee.length);
        return true;
      }
      return false;
    });
  return contains;
};

export const capitalizePayee = (payee) => payee?.replace(/(^|\s)\S/g, (wordBoundary) => wordBoundary.toUpperCase());
