import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import makeStyles from '@mui/styles/makeStyles';

import { deleteRenameRule } from 'data/renameRules/actions';
import { mkRenameRule } from 'data/renameRules/types';

import QButton from 'components/QButton';

import RenameRuleForm from 'components/Forms/RenameRuleForm';
import QIconButton from 'components/QIconButton';

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    position: 'absolute',
    left: 24,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

const RenameRuleDialogForm = React.memo((props) => {
  const { renameRule, onClose, applyRule, viewExistingTransactions, setViewExistingTransactions, transaction, ...otherProps } = props;

  const dispatch = useDispatch();
  const classes = useStyles();
  const formikRef = useRef();

  return (
    <>
      <DialogContent>
        <Box minWidth={600}>
          <RenameRuleForm
            formikRef={formikRef}
            renameRule={renameRule}
            onClose={onClose}
            applyRule={applyRule}
            viewExistingTransactions={viewExistingTransactions}
            setViewExistingTransactions={setViewExistingTransactions}
            transaction={transaction}
            {...otherProps}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        {renameRule?.id && !viewExistingTransactions && (
          <QIconButton
            className={classes.deleteButton}
            color="primary"
            id="delete-rename-rule"
            aria-label="delete payee rule"
            onClick={() => {
              dispatch(deleteRenameRule(mkRenameRule({
                id: renameRule.id,
                isDeleted: true,
              }),
              { undo: { userMessage: 'Payee rule deleted.' } }));
              onClose?.(undefined, 'delete');
            }}
          >
            <DeleteOutlineIcon />
          </QIconButton>
        )}

        {!viewExistingTransactions && (
          <>
            <QButton
              id="rename-rule-submit-button"
              variant="contained"
              onClick={() => formikRef.current?.submitForm?.()}
            >
              {renameRule?.id ? 'Update' : 'Create'}
            </QButton>
          </>
        )}

        {viewExistingTransactions && (
          <QButton variant={'outlined'} onClick={() => setViewExistingTransactions(false)}>
            back to rule details
          </QButton>
        )}
      </DialogActions>
    </>
  );
});

RenameRuleDialogForm.propTypes = {
  renameRule: PropTypes.object,
  transaction: PropTypes.object,
  onClose: PropTypes.func,
  applyRule: PropTypes.bool,
  viewExistingTransactions: PropTypes.bool,
  setViewExistingTransactions: PropTypes.func,
};

export default RenameRuleDialogForm;
