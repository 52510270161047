import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import { accountsSelectors } from '@quicken-com/react.flux.accounts';
import { transactionsTypes, transactionsUtils } from '@quicken-com/react.flux.transactions';
import { scheduledTransactionsSelectors, scheduledTransactionsUtils } from '@quicken-com/react.flux.scheduled-transactions';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';

// DATA
import { useSelector, useDispatch } from 'react-redux';
import { showMatchTxnDialog } from 'components/Dialogs/MatchTxnDialog/actions';
import { removeDialog } from 'data/rootUi/actions';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';
import { getBillerAccountForId } from 'data/billerAccounts/selectors';
import { getBillerAccounts } from 'data/billerAccounts/actions';
import { isIncomeTxn } from 'data/transactions/selectors';

// CUSTOM
import QButton from 'components/QButton';
import StdDialog from 'components/Dialogs/StdDialog';
import useQData from 'components/QData/useQData';
import AmountField, { ShowSignEnum } from 'components/QuickenControls/AmountField';
import ScheduledTransactionEditDialog from 'components/ScheduledTransactions/ScheduledTransactionEditDialog';
import AlertDialog from 'components/Dialogs/AlertDialog';
import PayeeField from 'components/QuickenControls/PayeeField';
import CategoryField from 'components/QuickenControls/CategoryField';
import AccountField from 'components/QuickenControls/AccountField';
import DateField from 'components/Transactions/DateField';
import ConnectedAvatar from 'components/ConnectedAvatar';
import UpdatedIcon from 'assets/status-updated.svg';
import VisibilitySection from 'components/Transactions/VisibilitySection';

const useStyles = makeStyles((theme) => ({
  content: {
    maxWidth: 600,
    padding: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  leftSide: {
    width: 80,
    marginRight: 32,
  },
  rightSide: {
    flexGrow: 2,
    minWidth: 420,
    paddingRight: 6,
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    paddingRight: 40,
  },

  payee: {
    width: '100%',
    paddingBottom: 8,
  },
  amtTxt: {
    width: '68%',
    textAlign: 'left',
    paddingRight: 16,
  },

  billText: {
    backgroundColor: theme.palette.greyScaleDeprecated[5],
    borderRadius: 8,
    padding: '4px 8px',
    margin: '12px 0 4px',
    display: 'flex',
  },
  updatedIcon: {
    paddingRight: 10,
  },

  manualFields: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 80,
    paddingBottom: 12,
  },

  amountInput: {
    textAlign: 'left',
    paddingRight: 16,
  },
  calIcon: {
    color: theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[2], 0.8),
  },

  linkRow: {
    margin: '8px 0 12px',
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.15),
    marginBottom: 16,
  },

  greyText: {
    color: theme.palette.text.secondary,
  },

  actions: {
    width: '100%',
    padding: 24,
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  leftButton: {
    position: 'absolute',
    left: 24,
  },
}));
const buttonMargin = { marginLeft: 8 };

function operatorFromAmt(amt) {
  const sign = Math.sign(amt);
  if (sign === 1) {
    return '+';
  }
  return '-';
}

// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
// ### - - -                  Dialog Component                   - - - ###
// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
function EditReminderDialog(props) {
  const classes = useStyles();
  const { txn, context } = props;

  const { qDataSaveTransactions, qDataDeleteTransactions } = useQData(context);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBillerAccounts());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isPendingScheduledTxn = txn.source === 'SCHEDULED_TRANSACTION_PENDING';

  const negator = scheduledTransactionsUtils.negatorFromCOA(txn.coa);
  const showSign = Math.sign(txn?.amount || -1) !== negator ? ShowSignEnum.ALWAYS : ShowSignEnum.NEVER;

  let initialAmount = '0';
  if (!Number.isNaN(txn.amount)) {
    const abs = Math.abs(txn.amount);
    if (showSign === ShowSignEnum.ALWAYS) {
      initialAmount = `${operatorFromAmt(txn.amount)}${abs}`;
    } else {
      initialAmount = `${abs}`;
    }
  }

  // +++     State values     +++
  const [amount, setAmount] = useState(initialAmount);
  const [date, setDate] = useState(DateTime.fromISO(txn.postedOn));
  const [excludeReports, setExcludeReports] = useState(Boolean(txn.isExcludedFromReports));
  const [excludeF2S, setExcludeF2S] = useState(Boolean(txn.isExcludedFromF2S));

  const [edit, setEdit] = useState(null);
  const [dirtyDialog, setDirtyDialog] = useState(false);
  const [payee, setPayee] = useState(txn.payee);
  const [coa, setCoa] = useState(txn.coa);
  const [accountId, setAccount] = useState(txn.accountId);

  const model = useSelector((state) => scheduledTransactionsSelectors.getScheduledTransactionById(state, txn.stModelId));
  const accounts = useSelector(accountsSelectors.getAccountsById);
  const accountName = accounts.get(txn.accountId).name;
  const signedAmt = (amount.includes('+') || amount.includes('-')) ? Number(amount) : Number(amount) * negator;
  const isDirty = (Number(txn.amount) !== signedAmt) || (date.toUTC().toISO() !== txn.postedOn)
    || (excludeReports !== Boolean(txn.isExcludedFromReports)) || (excludeF2S !== Boolean(txn.isExcludedFromF2S)
      || (txn.coa !== coa || txn.accountId !== accountId || txn.payee !== payee));

  const presentBills = useSelector(featureFlagsSelectors.getFeatureFlags).get('billPresentment');
  const connected = presentBills && model?.billPresentmentAccountId && model?.billPresentmentAccountId !== '0';
  const billerAccount = useSelector((state) => connected && getBillerAccountForId(state, model?.billPresentmentAccountId));
  const billConnected = Boolean(billerAccount);
  const updatedBill = Boolean(txn?.ebillId);

  const isIncome = useSelector((state) => isIncomeTxn(state, txn));

  const openEdit = () => setEdit(true);
  const closeEditDialog = (editDirty) => {
    if (editDirty) {
      setDirtyDialog(true);
    } else {
      setEdit(false);
    }
  };

  const closeCheck = (e, reason) => {
    if (isDirty) {
      if (reason === 'ENTER_KEY_DOWN') {
        onSave();
      } else {
        setDirtyDialog(true);
      }
    }
  };

  const closeAndLink = () => {
    closeCheck();
    dispatch(removeDialog());
    dispatch(showMatchTxnDialog(txn, context));
  };

  const amountChange = (e) => setAmount(e.target.value);

  const onSave = useCallback(() => {
    const txnToSave = new transactionsTypes.CashFlowTransaction({
      ...txn.toJS(),
      isExcludedFromF2S: excludeF2S,
      isExcludedFromReports: excludeReports,
      postedOn: date.toUTC().toISO(),
      amount: (Number(signedAmt.toFixed(2))),
      tags: txn.tags,
      coa,
      accountId,
      payee,
    });
    qDataSaveTransactions([txnToSave]);
    dispatch(removeDialog());
  }, [accountId, dispatch, qDataSaveTransactions, date, coa, excludeF2S, excludeReports, signedAmt, payee, txn]);
  const onDelete = () => {
    qDataDeleteTransactions([txn]);
    dispatch(removeDialog());
  };

  const onHandleClose = useCallback((event, reason) => {
    if (reason === 'SUBMIT_BUTTON' || reason === 'ENTER_KEY_DOWN') { // update
      onSave();
    }
    dispatch(removeDialog());
  }, [onSave, dispatch]);

  return (
    <>
      <StdDialog
        open={edit === null}
        onClose={onHandleClose}
        title={isPendingScheduledTxn ? 'Edit reminder' : 'Edit transaction'}
        sharedcomponentid={'EDIT_REMINDER_DIALOG'}
      >
        <div className={classes.content}>
          <div className={classes.leftSide}>
            <ConnectedAvatar model={model} txn={txn} size={80} />
          </div>

          <div className={classes.rightSide}>
            {isPendingScheduledTxn ?
              <div className={classes.payee}>
                <Typography variant="h4" noWrap>
                  {txn.payee}
                </Typography>
                <Typography variant="body1">
                  {accountName}
                </Typography>
              </div>
              :
              <PayeeField
                editable
                name="edit-reminder-payee-field"
                label="Payee Name"
                variant={'h6'}
                value={{ payee: (payee || '') }}
                onChange={(event) => (event && event.target.value) && setPayee(event.target.value.name)}
                fontSize="14px"
                width="100%"
                bulkEdit={false}
                textFieldVariant={'outlined'}
                marginProp={'normal'}
                classes={{ root: classes.textField }}
              />}

            <div className={classes.row}>
              <AmountField
                value={amount}
                showSign={showSign}
                showAmountAdornment
                id="Reminder_Amount"
                label="Amount"
                currencySymbol="USD"
                amountType="amount"
                fullWidth
                editable
                autoComplete="off"
                onChange={amountChange}
                marginProp={'normal'}
                textFieldVariant={'outlined'}
                className={classes.amtTxt}
              />

              {/* Date Field */}
              <DateField
                date={date}
                onBlur={setDate}
              />
            </div>

            {billConnected &&
            <Typography variant="caption" className={classes.billText}>
              {updatedBill ?
                <>
                  <img
                    alt="updated-icon"
                    src={UpdatedIcon}
                    className={classes.updatedIcon}
                  />
                  The amount and date of this reminder have been updated with your latest bill information.
                </>
                :
                'The amount and date of this reminder will be updated once your biller issues a new bill.'}
            </Typography>}

            {/* manual transaction fields */}
            {!isPendingScheduledTxn &&
            <div className={classes.manualFields}>
              <AccountField
                initialAccountId={accountId}
                onSelected={(selectedAccount) => setAccount(selectedAccount?.id)}
                label="Account"
                variant="outlined"
                margin="normal"
                fullWidth
              />

              {!transactionsUtils.isSplitTxn(txn) &&
              <CategoryField
                variant="body1"
                id={txn.id}
                editable
                value={coa}
                label="Category"
                onChange={(txnCoa) => setCoa(txnCoa)}
                textFieldVariant="outlined"
                margin="normal"
              />}
            </div>}


            {/* link option */}
            {isPendingScheduledTxn &&
            <Typography
              variant="body2"
              className={classes.linkRow}
            >
              Already {isIncome ? 'received' : 'paid'}?
              <QButton
                type="button"
                id="link-button"
                aria-label="link-transaction"
                onClick={closeAndLink}
              >
                LINK TRANSACTION
              </QButton>
            </Typography>}

            <div className={classes.divider} />

            {/* Ignore Checkboxes */}
            <VisibilitySection
              excludeF2S={excludeF2S}
              setExcludeF2S={setExcludeF2S}
              excludeReports={excludeReports}
              setExcludeReports={setExcludeReports}
            />
          </div>
        </div>

        <div className={classes.actions}>
          {isPendingScheduledTxn &&
          <QButton
            type="button"
            id="edit-series"
            aria-label="edit-series"
            onClick={openEdit}
            className={classes.leftButton}
          >
            Edit Series
          </QButton>}

          <QButton
            type="button"
            id="delete-reminder"
            aria-label="delete-reminder"
            onClick={onDelete}
            disabled={!txn || !txn.id}
            variant="delete"
            disableOutline
          >
            Delete
          </QButton>

          <QButton
            type="submit"
            id="create-update-recurrence"
            variant="contained"
            disabled={!isDirty}
            onClick={onSave}
            style={buttonMargin}
          >
            Update
          </QButton>
        </div>
      </StdDialog>

      {edit &&
      <ScheduledTransactionEditDialog
        open={Boolean(model)}
        key={model ? (model.clientId || model.id) : 'noKey'}
        scheduledTransaction={model}
        onClose={closeEditDialog}
      />}

      {dirtyDialog &&
      <AlertDialog
        dialogId={'ignore this warning'}
        title="Just checking..."
        content="Are you sure you want to discard all changes?"
        onClose={(btnObj) => {
          setDirtyDialog(false);
          if (btnObj.btnPressed === 'Discard') {
            if (edit) {
              setEdit(null);
            }
          }
        }}
        buttons={['Go Back', 'Discard']}
      />}
    </>
  );
}

EditReminderDialog.defaultProps = {
  txn: {},
  context: '',
};

EditReminderDialog.propTypes = {
  txn: PropTypes.object,
  context: PropTypes.string,
};

export default EditReminderDialog;


