import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';

import Typography from '@mui/material/Typography';
import Portal from '@mui/material/Portal';
import QTip from 'components/QuickenControls/QTip';
import QIconButton from 'components/QIconButton';
import BalanceSelectMenu from 'components/BalanceSelectMenu';
import AddIcon from '@mui/icons-material/AddRounded';
import RefreshButton from 'containers/App/Header/RefreshButton';
import QButton from 'components/QButton';
import { useTheme } from '@mui/material/styles';

import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';

import { setPreference } from 'data/preferences/actions';
import { getSharedPreferencesByPath } from 'data/preferences/selectors';
import { ShowBalance } from 'data/preferences/types';
import { isAcme, isQuicken } from 'isAcme';

export const AccountDrawerToggle = (props) => {

  const { classes, handleDrawerToggle, open, anchorEl } = props;

  const [isQTipOpen, setIsQTipOpen] = useState(true);

  const DrawerControlIcon = open ? ChevronLeftIcon : ChevronRightIcon;

  // Once the value of open changes, we know it is safe to show QTips again without the 'render forever' ToolTip bug!
  useEffect(() => {
    setIsQTipOpen(true);
  }, [open]);

  // This guarantees there is no race condition and setQTipOpen is always set to false first!
  const toggleQTip = (e, callback) => {
    setIsQTipOpen(false);
    callback(e);
  };

  return (
    <Portal
      container={anchorEl}
    >
      <div
        className={classNames(classes.drawerOpenControl, open ? 'open' : '')}
      >
        {/* This was to fix a bug where the QTip remained onscreen forever if it was rendered during the AccountDrawer opening/closing */}
        {isQTipOpen ? (
          <QTip
            title={`${!open ? 'Open' : 'Close'} the account bar`}
            placement="right"
            wrapId="account-drawer"
          >
            <QIconButton
              size="small-no-padding"
              aria-label="Close the account bar"
              onClick={(e) => {
                toggleQTip(e, handleDrawerToggle);
              }}
              id="account-drawer-toggle"
              style={{ height: 24, width: 24 }}
            >
              <DrawerControlIcon className={classes.drawerIcons} />
            </QIconButton>
          </QTip>
        ) : (
          <QIconButton
            size="small-no-padding"
            aria-label="Close the account bar"
            onClick={(e) => {
              toggleQTip(e, handleDrawerToggle);
            }}
            id="account-drawer-toggle"
            style={{ height: 24, width: 24 }}
          >
            <DrawerControlIcon className={classes.drawerIcons} />
          </QIconButton>
        )}

      </div>
    </Portal>
  );
};

AccountDrawerToggle.propTypes = {
  classes: PropTypes.object,
  handleDrawerToggle: PropTypes.func,
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
};

const balanceMenuItems = [{
  primary: 'Balance with pending',
  secondary: 'Your bank balance minus any pending transactions. This balance is used to project your cash flow.',
  showBalance: ShowBalance.INCLUDE_PENDING,
}, {
  primary: 'Bank Balance',
  secondary: 'The balance we received from your bank. It typically does not include pending transactions.',
  showBalance: ShowBalance.BANK_REPORTED,
}];

export const AccountListHeader = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const showBalance = useSelector((state) => getSharedPreferencesByPath(state, { group: 'dataset', path: ['shared', 'showBalance'] }))
    || ShowBalance.INCLUDE_PENDING;

  const balanceToggle = useSelector((state) => featureFlagsSelectors.getFeatureFlag(state, 'balanceToggle'));
  const label = theme.components.accountDrawer.addFiLabel;
  const size = theme.components.accountDrawer.actionButtonSize;

  const [balanceMenuAnchorEl, setBalanceMenuAnchorEl] = React.useState(null);

  const { classes, addFiEnabled, handleAddInstitutionLogin } = props;

  return (
    <>
      <Typography variant="h5" className={isQuicken ? classes.drawerListTitle : ''}>
        Accounts
      </Typography>

      {isQuicken && (
        <RefreshButton
          isInAccountBar
          id="sync-arrow"
          classes={{ buttonRoot: classes.drawerHeaderIcons }}
        />
      )}

      {addFiEnabled && (
        <QTip
          title="Add your account"
          placement="right"
        >
          <QButton
            id="addFiButton"
            aria-label="Add Institution Login"
            onClick={handleAddInstitutionLogin()}
            style={{ minWidth: 0, backgroundColor: 'transparent', height: !label && size, width: !label && size, marginRight: label && -16 }}
            variant={label ? 'text' : 'containedIcon'}
          >
            <AddIcon className={!label ? classNames(classes.drawerHeaderIcons, isAcme ? classes.roundedButton : '') : ''} />
            {label && <span style={{ marginTop: 2 }}>{label}</span>}
          </QButton>
        </QTip>
      )}

      {balanceToggle && (
        <div
          role="none"
          onClick={(event) => event.stopPropagation()}
        >
          <IconButton
            id="balance-options"
            aria-label="balance options"
            className={classes.buttonShift}
            onClick={(event) => {
              setBalanceMenuAnchorEl(event.currentTarget);
              event.stopPropagation();
            }}
            size="large"
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            id="balance-menu"
            anchorEl={balanceMenuAnchorEl}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            keepMounted
            open={Boolean(balanceMenuAnchorEl)}
            onClose={() => setBalanceMenuAnchorEl(null)}
            PaperProps={{
              style: { maxWidth: '38ch' },
            }}
          >
            <MenuList className={classes.borderCols}>
              {balanceMenuItems.map((item) => (
                <ListItem
                  key={item.primary}
                  id={item.primary}
                  button
                  selected={item.showBalance === showBalance}
                  alignItems="flex-start"
                  onClick={(event) => {
                    event.stopPropagation();
                    dispatch(setPreference({
                      section: 'shared',
                      group: 'dataset',
                      preference: {
                        shared: {
                          showBalance: item.showBalance,
                        },
                      },
                    }));
                    setBalanceMenuAnchorEl(null);
                  }}
                >
                  <ListItemIcon>
                    {item.showBalance === showBalance && <CheckIcon color="primary" />}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.primary}
                    secondary={item.secondary}
                  />
                </ListItem>
              ))}
            </MenuList>
          </Menu>
        </div>
      )}

      {isQuicken && (
        <BalanceSelectMenu
          classes={{ balanceMenu: classes.balanceMenu, iconColor: classes.drawerHeaderIcons }}
          menuIconButtonSize="small"
        />
      )}
    </>
  );
};

AccountListHeader.propTypes = {
  classes: PropTypes.object,
  handleAddInstitutionLogin: PropTypes.func,
  addFiEnabled: PropTypes.bool,
};
