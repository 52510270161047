import { DateTime } from 'luxon';

import useQPreferences from 'components/QPreferences/useQPreferences';
import { BUDGET_VIEWS, BUDGET_ANNUAL_SUBVIEWS } from './constants';

export const useBudgetOptions = (budgetId = null, dashboardRef = null) => {
  const { datasetPreferences, setDatasetPreference } = useQPreferences();
  const isToDateColumnEnabled = datasetPreferences?.budgetOptions?.showToDateColumn || false;
  const viewState = datasetPreferences?.budgetOptions?.viewState?.[budgetId] || {};
  let budgetView = BUDGET_VIEWS.monthly.key;
  if (viewState?.viewType && dashboardRef !== 'dashboard') {
    budgetView = viewState?.viewType;
  }
  const annualSubView = viewState?.subViewType || BUDGET_ANNUAL_SUBVIEWS.budget.key;
  const selectedMonthDate = datasetPreferences?.selectedMonthDate || DateTime.now();

  return {
    viewState,
    budgetView,
    annualSubView,
    isToDateColumnEnabled,
    setDatasetPreference,
    selectedMonthDate,
  };
};
