// @flow
import { List, Map as ImmutableMap } from 'immutable';

import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';

import * as types from 'data/institutionLogins/types';
import type { Credential, InstitutionLogin } from './types';

const transformOptionalAction = (qLogin: any) => {
  if (qLogin.optionalAction === 'FI_MIGRATION' && qLogin.channel === qLogin.fiMigrationInfo?.toFI?.channel) {
    return undefined;
  }
  return qLogin.optionalAction;
};

export const transformQcsInstitutionLoginToInstitutionLogin = (qLogin : any) => {

  let aggregators = null;
  if (qLogin.aggregators) {
    const connectedAggregators = qLogin.aggregators
      .filter((aggregator) => aggregator.cpId != null)
      .map((aggregator) => types.mkInstitutionLoginAggregator({
        channel: aggregator.cpChannel || 'FDS_DEFAULT',
        cpId: aggregator.cpId,

        aggStatus: aggregator.aggStatus,
        aggStatusCode: aggregator.aggStatusCode,
        aggStatusDetail: aggregator.aggStatusDetail,

        lastStatusUpdatedAt: aggregator.lastStatusUpdatedAt,

        lastRefreshAttemptedAt: aggregator.lastRefreshAttemptedAt,
        lastRefreshSuccessfulAt: aggregator.lastRefreshSuccessfulAt,
      }));
    if (connectedAggregators.length > 0) {
      aggregators = List(connectedAggregators);
    }
  }

  return types.mkInstitutionLogin({
    id: qLogin.id,
    name: qLogin.name,
    isDeleted: qLogin.isDeleted,

    institutionId: qLogin.institutionId,
    brandingId: qLogin.brandingId,
    cpInstitutionId: qLogin.cpInstitutionId,
    channel: qLogin.channel,

    optionalAction: transformOptionalAction(qLogin),

    fiMigrationInfo: qLogin.fiMigrationInfo
      ? types.mkFiMigrationInfo({
        toInstitutionId: qLogin.fiMigrationInfo.toFI.institutionId,
        toChannel: qLogin.fiMigrationInfo.toFI.channel,
      })
      : undefined,

    // TODO: this belongs on QCS, shouldn't be hardcoded here!
    ewcLive: false,  // deprecated - no longer required for any FI

    aggregators,
  });
};

export const transformQcsInstitutionLoginsToInstitutionLogins = (qLogins : any) =>
  qLogins.map(transformQcsInstitutionLoginToInstitutionLogin);

export const transformResponseToInstitutionLogins = (response: any): Array<InstitutionLogin> =>
  transformQcsInstitutionLoginsToInstitutionLogins(response.data.resources);

export const transformInstitutionLoginToRequestData = (institutionLogin: InstitutionLogin) => ({
  id: institutionLogin.id ? institutionLogin.id : null,
  cpSetupMode: 'SKIP_ACCOUNT_DISCOVERY',
  channel: institutionLogin.channel,
  name: institutionLogin.name ? institutionLogin.name : null,
  aggregators: !institutionLogin.aggregators || institutionLogin.aggregators.size === 0 ? null :
    [{
      cpChannel: institutionLogin.aggregators.get(0).channel,
      cpId: institutionLogin.aggregators.get(0).channel.cpId,
    }],

  // todo: should we support clearing of brandingId and/or institutionId - (deactivation)
  // todo: what about setting of brandingId and/or institutionId - (activation)
  // todo: what about changing of brandingId and/or institutionId - (i.e. deactivation - reactivation)
});

export const transformRefreshingLoginsToRequestData = (
  logins: Array<InstitutionLogin>,
  credentialsBlobsForRefreshingLogins: ImmutableMap<string, string>,
  credentialsForRefreshingLogins: ImmutableMap<string, List<Credential>>,
) => {
  const loginRefreshCredentials = [];
  if (credentialsBlobsForRefreshingLogins.size > 0) {
    credentialsBlobsForRefreshingLogins.forEach((credentialsBlob, institutionLoginId) => {
      if (logins.find((login) => login.id === institutionLoginId)) {
        loginRefreshCredentials.push({
          institutionLoginId,
          credentialsBlob,
        });
      }
    });
  }
  if (credentialsForRefreshingLogins.size > 0) {
    credentialsForRefreshingLogins.forEach((credentials, institutionLoginId) => {
      if (logins.find((login) => login.id === institutionLoginId)) {
        const tCredentials: Array<*> = credentials.toArray().map((credential) =>
          ({ name: credential.key, key: credential.key, value: credential.value }));
        loginRefreshCredentials.push({
          institutionLoginId,
          credentials: tCredentials,
        });
      }
    });
  }
  return {
    ...(featureFlagsSelectors.dangerouslyUseFeatureFlags()?.get('ignoreRefreshThrottle')
      && { doIgnoreThrottle: true }),
    loginRefreshCredentials: loginRefreshCredentials.length > 0 ? loginRefreshCredentials : null,
    ...(featureFlagsSelectors.dangerouslyUseFeatureFlags()?.get('investmentTransactions')
      && { investmentAggregationType: 'FULL_AGGREGATION' }),
  };
};

export const transformMfaResponseToMfaChallenge = (institutionLoginId: string, mfaResponse: any, submitUrl: string) => (
  types.mkMfaChallenge({
    institutionLoginId,
    challenges: List(mfaResponse.map((challenge) => {
      if (challenge.type === 'IMAGE') {
        return types.mkMfaChallengeItem({
          key: challenge.key,
          sequence: challenge.sequence,
          question: challenge.displayText,
          base64Image: challenge.image,
          answerChoices: !challenge.answerChoices ? null :
            List(challenge.answerChoices.map((answerChoice) => (
              types.mkMfaAnswerChoice({
                displayText: answerChoice.displayText,
              })
            ))),
        });
      }
      return types.mkMfaChallengeItem({
        key: challenge.key,
        sequence: challenge.sequence,
        question: challenge.question,
        answerChoices: !challenge.answerChoices ? null :
          List(challenge.answerChoices.map((answerChoice) => (
            types.mkMfaAnswerChoice({
              displayText: answerChoice.displayText,
            })
          ))),
      });
    })),
    submitUrl,
  })
);

export const transformActionParamsToRequestData = (action, params) => {
  if (action === 'disconnect') {
    const data = {};
    data.doSynchrounous = !!(params && params.doSynchrounous);
    return data;
  }
  return null;
};

// /////////////////////////////////////////////////////////////////////////////
// Transformers for Refresh Statusus
// /////////////////////////////////////////////////////////////////////////////

export function transformResponseToRefreshStatus(response: any) {

  return types.makeRefreshResponse({
    jobId: response.jobId,

    isProcessing: response.isProcessing,
    status: response.status,

    institutionLogins: List(
      response.institutionLogins.map((login) => types.makeRefreshInstitutionLogin({
        id: login.id,
        isProcessing: login.isProcessing,
        status: login.status,
        credentialsBlob: login.credentialsBlob,
        submitUrl: login.pollingReference,
        aggregators: !login.aggregators ? null : List(
          login.aggregators.map((aggregator) => types.makeRefreshAggregator({
            channel: aggregator.channel,   // todo: QCS is not returning this, should put in defect/enhancement

            isProcessing: aggregator.isProcessing,
            aggStatus: aggregator.aggStatus,
            cpAggStatus: aggregator.cpAggStatus,
            cpAggStatusDetail: aggregator.cpAggStatusDetail,

            mfaChallenges: aggregator.mfaChallenges,
          }))
        ),
      }))
    ),
  });
}
