import createCachedSelector, { LruCacheObject } from 're-reselect';

import { getLogger } from '@quicken-com/react.utils.core';
import { accountsSelectors } from '@quicken-com/react.flux.accounts';
import { categoriesSelectors } from '@quicken-com/react.flux.categories';
import { chartOfAccountsSelectors } from '@quicken-com/react.flux.chart-of-accounts';

import { coaIncludedInList } from './helpers';

const log = getLogger('components/QuickenControls/CategoryField/selectors.js');

export const getFilteredChartOfAccountList = createCachedSelector(
  chartOfAccountsSelectors.getChartOfAccountNodesById,
  accountsSelectors.getAccountsById,
  categoriesSelectors.getCategoriesById,
  (state, props) => props.filter ? props.filter.trim() : null,
  (state, props) => props.filterFn,
  (state, props) => props.allowNone,
  (state, props) => props.stType,
  (coasById, accountsById, categoriesById, filter, filterFn, allowNone, stType) => {
    log.log('Calling getFilteredChartOfAccountList selector...', filter, allowNone);
    if (coasById.size === 0 || !filter) {
      // don't show _ categories
      return coasById.filter((x) =>
        (x.inList) && (x.name.charAt(0) !== '_') && (!filterFn || filterFn(x.coa)) &&
        (allowNone || x.coa.type !== 'NONE') &&
          coaIncludedInList(x.coa, categoriesById, accountsById, stType)).toList().toJS();
    }

    const lcFilter = filter.toLowerCase();
    return coasById.toList().filter((coa) => (
      coa.fullPathName.toLowerCase().indexOf(lcFilter) !== -1
    )).toJS();
  }
)(
  (state, props) => {
    const filter = props.filter ? props.filter.trim() : null;
    return filter || 'none';
  },
  {
    cacheObject: new LruCacheObject({ cacheSize: 10 }),
  }
);


