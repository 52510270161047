// the purpose of sessionStorageEx is to safely abstract session storage
// import { getBuildConfig } from '@quicken-com/react.utils.core';

const dataKeys = Object.freeze({
  authSession: 'auth_session',
  entitlements: 'entitlements',
  sandBox: 'sand_box', // true/false - sandbox mode

  // ^^^ add your new key above
});

let sessionStorageInterface;
try {
  sessionStorage.setItem('check', 'test');
  const check = sessionStorage.getItem('check');
  if (check === 'test') {
    sessionStorage.removeItem('check');
    sessionStorageInterface = sessionStorage;
  }
} catch (e) {
  assert(false, e);
}
if (!sessionStorageInterface) {
  assert(false, 'session storage in not available - simulating');
  let sessionStorageStub = {};
  sessionStorageInterface = {
    setItem: (key, value) => { sessionStorageStub[key] = value; },
    getItem: (key) => sessionStorageStub[key],
    removeItem: (key) => delete sessionStorageStub[key],
    clear: () => { sessionStorageStub = {}; },
    key: (index) => Object.keys(sessionStorageStub)[index],
    length: () => Object.keys(sessionStorageStub).length,
  };
}

export function setItem(key, value) {
  if (value === undefined) {
    removeItem(key);
  } else {
    const jsonValue = JSON.stringify(value);
    try {
      sessionStorageInterface?.setItem(key, jsonValue);
    } catch (e) {
      assert(false, `can't save session storage data ${key} = ${value}\n${e}`);
    }
  }
}

export function getItem(key) {
  let value;

  try {
    const jsonValue = sessionStorageInterface?.getItem(key);
    if (jsonValue) {
      value = JSON.parse(jsonValue);
    }
  } catch (e) {
    assert(false, `can't get session storage data for "${key}"\n${e}`);
  }

  return value;
}

export function removeItem(key) {
  try {
    sessionStorageInterface?.removeItem(key);
  } catch (e) {
    assert(false, `can't delete session storage data for "${key}"\n${e}`);
  }
}

export function clear() {
  try {
    sessionStorageInterface?.clear();
  } catch (e) {
    assert(false, "can't clear session storage data");
  }
}

export const getAuthSession = () => getItem(dataKeys.authSession);
export const setAuthSession = (value) => setItem(dataKeys.authSession, value);
export const removeAuthSession = () => removeItem(dataKeys.authSession);

export const getEntitlements = () => getItem(dataKeys.entitlements);
export const setEntitlements = (value) => setItem(dataKeys.entitlements, value);
export const removeEntitlemets = () => removeItem(dataKeys.entitlements);

export const getSandbox = () => {
  let value = false;
///////////////////////////////////////////////
////////////////////////////////////
///////////
  return value;
};
export const setSandbox = (value) => setItem(dataKeys.sandBox, value);
export const removeSandBox = () => removeItem(dataKeys.sandBox);

