// @flow
import { List as ImmutableList } from 'immutable';

import { tagsTypes } from '@quicken-com/react.flux.tags';
import { chartOfAccountsTypes } from '@quicken-com/react.flux.chart-of-accounts';

import * as types from './types';

export function transformMemorizedRuleToRequestData(memorizedRule: types.MemorizedRule) {
  return {
    id: memorizedRule.id,
    clientId: memorizedRule.clientId,
    isDeleted: memorizedRule.isDeleted,
    payee: memorizedRule.payee,
    transactionType: memorizedRule.transactionType,
    memo: memorizedRule.memo,
    amount: memorizedRule.amount,
    isNotAutoCategorized: memorizedRule.isNotAutoCategorized,
    isNotEditableByTransactions: memorizedRule.isNotEditableByTransactions,
    isExcludedFromCalendar: memorizedRule.isExcludedFromCalendar,
    coa: memorizedRule.coa ?
      {
        type: memorizedRule.coa.type,
        id: memorizedRule.coa.id,
      } :
      undefined,
    tags: memorizedRule.tags?.map((tag) => ({ id: tag.id })),
    split: memorizedRule.split,
  };
}

export function transformResponseToMemorizedRules(response: any) {
  return response.data.resources.map((data) => (
    types.mkMemorizedRule({
      id: data.id,
      clientId: data.clientId,
      isDeleted: data.isDeleted,
      payee: data.payee,
      transactionType: data.transactionType,
      memo: data.memo,
      amount: data.amount,
      isNotAutoCategorized: data.isNotAutoCategorized,
      isNotEditableByTransactions: data.isNotEditableByTransactions,
      isExcludedFromCalendar: data.isExcludedFromCalendar,
      coa: data.coa ?
        chartOfAccountsTypes.mkChartOfAccount({ id: data.coa.id, type: data.coa.type }) :
        undefined,
      tags: data.tags ? ImmutableList(data.tags.map((tag) => tagsTypes.mkTag(tag))) : undefined,
      split: data.split,
    })
  ));
}
