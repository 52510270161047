import React from 'react';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import InputChip from './InputChip';


const styles = (_theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    position: 'relative',
  },
  inputField: {
    background: 'transparent',
    maxWidth: '100%',
    outline: 'none',
    border: 'none',
    minWidth: 20,
    flexGrow: 1,
    padding: '4px 2px 4px 2px',
    fontSize: 14,
    height: 27,
  },
  placeholder: {
    minHeight: 26,
    left: 0,
    outline: 'none',
    border: 'none',
    color: 'black',
    opacity: '100%',
    fontSize: 14,
    maxWidth: '100%',
    padding: '9px 2px 6px 2px',
    position: 'absolute',
    whiteSpace: 'noWrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  noPadding: {
    padding: '0 !important',
    minHeight: 17,
  },

  hiddenInput: {
    height: 0,
    width: 0,
    opacity: 0,
    display: 'none',
  },
});

class ChippedInput extends React.PureComponent {

  constructor(props) {
    super(props);
    this.spanRef = React.createRef();
    this.state = {
      value: '',
      hoverDelete: false,
    };
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.spanRef.current?.focus();
    }
    this.spanRef.current?.addEventListener('input', this.handleInput);
  }

  componentDidUpdate(prevProps, _prevState) {
    const numberOldChips = (prevProps.chips?.size === undefined ? prevProps.chips?.length : prevProps.chips?.size) || 0;
    const numberNewChips = (this.props.chips?.size === undefined ? this.props.chips?.length : this.props.chips?.size) || 0;

    if (numberOldChips < numberNewChips) { // added a chip
      if (this.spanRef?.current && (this.spanRef.current?.innerText?.length > 0)) { // there is text to reset
        this.spanRef.current.innerText = ''; // clear span text
        this.state.value = '';
      }
    }
  }

  componentWillUnmount() {
    this.spanRef.current?.removeEventListener('input', this.handleInput);
  }

  handleInput = (event) => {
    const { maxLength } = this.props;
    const value = event?.target?.innerText || '';
    if (maxLength && this.spanRef && (this.spanRef.current?.innerText.length > maxLength)) {
      this.spanRef.current.innerText = this.state.value;
    } else {
      this.setState({ value, hoverDelete: false });
      this.props.onChange(value);
    }
  };

  handleFocus = (event) => {
    this.setState({ hoverDelete: false });
    if (this.spanRef.current) this.spanRef.current.focus();
    this.props.onFocus?.(event);
  };

  handleKeyDown = (event) => {
    const { chips, keyDelete } = this.props;
    const { hoverDelete, value } = this.state;
    if (event.key === 'Backspace' && value === '' && keyDelete && !hoverDelete && ((chips.length > 0) || (chips.size > 0))) {
      this.setState({ hoverDelete: true });
    } else if (event.key === 'Backspace' && keyDelete && hoverDelete) {
      const finalChip = Array.isArray(chips) ? chips[chips.length - 1] : chips.last();
      this.handleDelete(finalChip);
    } else if (event.key === 'Enter') {
      // solves a race condition with the menu selecting an item
      setTimeout(() => {
        if (this.spanRef.current) {
          this.spanRef.current.innerText = '';
          this.setState({ value: '' });
        }
      }, 100);
    } else if (event.key !== 'ArrowDown' && event.key !== 'ArrowUp') {
      // validation to avoid ArrowDown/ArrowUp, it affects the nestedQMenu event listener, thus setState fails there
      this.setState({ hoverDelete: false });
    }
  };

  handleDelete = (chipToDelete) => {
    if (!chipToDelete) return;
    this.props.onDelete(chipToDelete);
    if (this.props.chips.length === 1 || this.props.chips.size === 1) {
      this.setState({ hoverDelete: false });
    }
  }

  handleBlur = (ev) => {
    if (this.spanRef.current) {
      this.spanRef.current.innerText = '';
      this.setState({ value: '' });
    }
    this.props.onBlur?.(ev);
  }

  render() {

    const { classes, chips, placeholder, bulkEdit, textFieldVariant, margin, id, inputRef, editable } = this.props;
    const { value, hoverDelete } = this.state;

    return (
      <div
        className={classes.root}
        onClick={this.handleFocus}
        role="presentation"
      >
        {chips?.map((chip, index) => (
          <InputChip
            key={`${id}-chipped-input-chip-${index}`} // eslint-disable-line react/no-array-index-key
            id={`${id}-chipped-input-chip-${index}`}
            label={chip}
            onDelete={this.handleDelete}
            hovered={hoverDelete && index === (chips.length || chips.size) - 1}
          />
        ))}
        <span
          role="presentation"
          onKeyDown={this.handleKeyDown}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          className={classNames(classes.inputField, textFieldVariant === 'outlined' && margin === 'dense' && classes.noPadding)}
          ref={this.spanRef}
          contentEditable={editable}
        />

        {/* Have a hidden input here for MUI to detect the value in inputRef for 'isFilled' checks (controls placeholder + outline) */}
        <input
          value={this.props.value}
          onFocus={this.handleFocus}
          onBlur={this.handleFocus}
          className={classes.hiddenInput}
          ref={inputRef}
          readOnly
        />
        {placeholder && value === '' && (chips.length === 0 || chips.size === 0) &&
        <span role="presentation" onClick={this.handleFocus} className={classes.placeholder} style={!bulkEdit ? { color: '#999' } : {}}>
          {placeholder}
        </span>}
      </div>
    );
  }
}

ChippedInput.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  chips: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  inputRef: PropTypes.func,
  keyDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  autoFocus: PropTypes.bool,
  bulkEdit: PropTypes.bool,
  textFieldVariant: PropTypes.string,
  margin: PropTypes.string,
  id: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  editable: PropTypes.bool,
};

// These are prop injectors
const StyledInput = withStyles(styles)(ChippedInput);

export default React.forwardRef((props, ref) =>
  <StyledInput
    {...props}
    inputRef={ref}
  />);

