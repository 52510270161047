import React, { forwardRef, useState } from 'react';
import Menu from '@mui/material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import NestedMenuItem from './NestedMenuItem';
import IconMenuItem from './IconMenuItem';
import { StyledButton } from './styles';

/* eslint react/prop-types: 0 */

/**
 * Create a JSX element with nested elements creating a nested menu.
 * Every menu item should have a uid provided
 */
function nestedMenuItemsFromObject({ menuItemsData, isOpen, handleClose }) {
  return menuItemsData.map((item) => {
    const { leftIcon, rightIcon, label, items, callback, sx, disabled } = item;

    if (items && items.length > 0) {
      // Recurse deeper
      return (
        <NestedMenuItem
          key={label}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          label={label}
          parentMenuOpen={isOpen}
          sx={sx}
          disabled={disabled}
        >
          {/* Call this function to nest more items */}
          {nestedMenuItemsFromObject({
            menuItemsData: items,
            isOpen,
            handleClose,
          })}
        </NestedMenuItem>
      );
    }

    // No children elements, return MenuItem
    return (
      <IconMenuItem
        key={label}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        label={label}
        onClick={() => {
          handleClose();
          callback && callback();
        }}
        sx={sx}
        disabled={disabled}
      />
    );
  });
}

const NestedDropdown = forwardRef((props, ref) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const {
    menuItemsData: data,
    onClick,
    ButtonProps,
    MenuProps,
    ...rest
  } = props;

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    onClick && onClick(e);
  };

  const handleClose = () => setAnchorEl(null);

  const menuItems = nestedMenuItemsFromObject({
    menuItemsData: data?.items ?? [],
    isOpen: open,
    handleClose,
  });

  return (
    <div ref={ref} {...rest}>
      <StyledButton
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        disableElevation
        disableFocusRipple
        disableRipple
        {...ButtonProps}
      >
        {data?.label ?? 'Menu'}
      </StyledButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { minWidth: '172px' } }}
        {...MenuProps}
      >
        {menuItems}
      </Menu>
    </div>
  );
});

export default NestedDropdown;
