import React from 'react';
import PropTypes from 'prop-types';

const AnnualViewCategoryLabel = ({ label }) => {
  if (!label.includes(':')) { 
    return <> {label} </>;
  }

  const arrDisplayLabel = label.split(':');
  const lastDiaplayLabel = arrDisplayLabel.pop();
  return (
    <>
      {arrDisplayLabel.join(': ')}: <strong>{lastDiaplayLabel}</strong>
    </>
  );
};

AnnualViewCategoryLabel.propTypes = {
  label: PropTypes.string,
};

export default AnnualViewCategoryLabel;
