import { takeEvery, put, select, call } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { resourceStoreTypes, resourceSagaUtils } from '@quicken-com/react.flux.core';
import { tracker, getLogger } from '@quicken-com/react.utils.core';
import { transactionsActions, transactionsTypes } from '@quicken-com/react.flux.transactions';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';

import isAcme from 'isAcme';
import { addMatches } from 'components/QData/core';
import { getTransactionsByAccountId } from 'data/transactions/selectors';
import { preProcessUpdate } from 'data/transactions/preProcess';

import * as actions from './actions';
import { getLastSyncDate, getMemorizedRuleApplicableTransactions } from './selectors';
import { transformResponseToMemorizedRules, transformMemorizedRuleToRequestData } from './transformers';
import { mkMemorizedRule } from './types';

const log = getLogger('data/memorizedRules/sagas.js');

// ===============================================================================================
// SAGAS functions for memorizedRules.  These are the functions responsible for communicating directly
// with external endpoints asynchronously.

// ====================================================
// ACTION WATCHERS to trigger SAGAS calls

const mkResourceConfig = (successAction, failureAction) => resourceStoreTypes.mkQcsSyncResourceConfig({
  resourceName: 'memorizedRule',
  resourceBaseUrl: '/memorized-rules',
  getLastSyncDate,

  transformResponseToResources: transformResponseToMemorizedRules,
  transformResourceToRequestData: transformMemorizedRuleToRequestData,

  successAction,
  failureAction,
});

export function* makeMemorizedRuleFromTransaction(action) {

  const txn = action.payload;
  const newRule = isAcme ?
    mkMemorizedRule({
      clientId: uuidv4().toUpperCase(),
      payee: txn.payee,
      coa: txn.coa,
    }) :
    mkMemorizedRule({
      clientId: uuidv4().toUpperCase(),
      isDeleted: false,
      payee: txn.payee,
      transactionType: txn.amount > 0 ? 'DEPOSIT' : 'PAYMENT',
      memo: txn.memo,
      amount: txn.amount,
      isNotAutoCategorized: false,
      isNotEditableByTransactions: false,
      isExcludedFromCalendar: true,
      coa: txn.coa,
      tags: txn.tags,
      split: txn.split,
    });
  yield put(actions.createMemorizedRule(newRule, { props: { transaction: txn } }));
}

export function* getMemorizedRulesActionWatcher() {
  const resourceConfig = mkResourceConfig(actions.getMemorizedRulesSuccess, actions.getMemorizedRulesFailure);
  yield takeEvery(actions.getMemorizedRules, resourceSagaUtils.qcsSyncGetResources, resourceConfig);
}

export function* makeMemorizedRuleFromTransactionActionWatcher() {
  yield takeEvery(actions.makeMemorizedRuleFromTransactionAction, makeMemorizedRuleFromTransaction);
}

export function* createMemorizedRuleActionWatcher() {
  const resourceConfig = mkResourceConfig(actions.createMemorizedRuleSuccess, actions.createMemorizedRuleFailure);
  yield takeEvery(actions.createMemorizedRule, function* createMemorizedRule(action) {
    const response = yield call(resourceSagaUtils.qcsSyncCreateResource, resourceConfig, action);
    if (action.meta?.props?.applyToTransactions && Math.trunc(response?.status / 100) === 2) {
      yield call(applyMemorizedRule, action);
    }
    tracker.track(tracker.events.categoryRuleCreate, {
      initiator: action.meta?.props?.transaction ? 'transaction' : 'settings',
      apply_past: action.meta?.props?.applyToTransactions,
    });
  });
}

export function* updateMemorizedRuleActionWatcher() {
  const resourceConfig = mkResourceConfig(actions.updateMemorizedRuleSuccess, actions.updateMemorizedRuleFailure);
  yield takeEvery(actions.updateMemorizedRule, function* updateMemorizedRule(action) {
    const response = yield call(resourceSagaUtils.qcsSyncUpdateResource, resourceConfig, action);
    if (action.meta?.props?.applyToTransactions && Math.trunc(response?.status / 100) === 2) {
      yield call(applyMemorizedRule, action);
    }
    tracker.track(tracker.events.categoryRuleEdit, {
      apply_past: action.meta?.props?.applyToTransactions,
    });
  });
}

function* applyMemorizedRule(action) {
  const memorizedRuleOld = action.meta.props?.memorizedRule;
  const memorizedRuleApplicableTransactions = yield select(getMemorizedRuleApplicableTransactions, action.payload, memorizedRuleOld);
  if (memorizedRuleApplicableTransactions.size) {
    const txnsToUpdate = memorizedRuleApplicableTransactions
      .toIndexedSeq()
      .toArray()
      .map((transaction) => new transactionsTypes.CashFlowTransaction({
        ...transaction?.toJS?.(),
        coa: action.payload.coa,
      }));
    const txnsByAccountId = yield select(getTransactionsByAccountId);
    const featureFlags = yield select(featureFlagsSelectors.getFeatureFlags);
    const { errors, ...txnPack } = preProcessUpdate({
      txns: txnsToUpdate,
      txnsByAccountId,
      featureFlags,
    });
    if (errors && errors.length > 0) {
      log.error('There was a problem applying memorized rules' +
        '\n - Aborting - ' +
        '\nThe problem likely happened in preprocess from applying changes to past txns');
    } else {
      addMatches(txnPack);
      const { allTxnsToDelete, allTxnsToUpdate, allTxnsToCreate } = txnPack;
      if (allTxnsToDelete && allTxnsToDelete.length > 0) {
        yield put(transactionsActions.deleteTransactions(allTxnsToDelete, { context: 'memorized-rules', undo: { userMessage: 'Transaction(s) deleted.' } }));
      }
      if (allTxnsToUpdate && allTxnsToUpdate.length > 0) {
        yield put(transactionsActions.updateTransactions(allTxnsToUpdate, { context: 'memorized-rules', undo: { userMessage: 'Transaction(s) updated.' } }));
      }
      if (allTxnsToCreate && allTxnsToCreate.length > 0) {
        yield put(transactionsActions.createTransactions(allTxnsToCreate, { context: 'memorized-rules', undo: { userMessage: 'Transaction(s) created.' } }));
      }
    }
  }
}

export function* deleteMemorizedRuleActionWatcher() {
  const resourceConfig = mkResourceConfig(actions.deleteMemorizedRuleSuccess, actions.deleteMemorizedRuleFailure);
  yield takeEvery(actions.deleteMemorizedRule, function* deleteMemorizedRule(action) {
    yield call(resourceSagaUtils.qcsSyncDeleteResource, resourceConfig, action);
    tracker.track(tracker.events.categoryRuleDelete);
  });
}

export function* combineMemorizedRulesActionWatcher() {
  const resourceConfig = mkResourceConfig(actions.combineMemorizedRulesSuccess, actions.combineMemorizedRulesFailure);
  yield takeEvery(actions.combineMemorizedRules, function* combineMemorizedRules(action) {
    yield call(resourceSagaUtils.qcsSyncUpdateResources, resourceConfig, action);
  });
}

export function* combineMemorizedRulesSuccessActionWatcher() {
  yield takeEvery(actions.combineMemorizedRulesSuccess, function* applyMemorizedRuleToTransactions(action) {
    if (action.meta?.props?.applyToTransactions && Math.trunc(action.meta?.response?.status / 100) === 2 && action.payload?.length === 2) {
      // we need to fake the action for applyMemorizedRule to make sure it uses the correct rules
      const actionForApply = { ...action, payload: action.payload[1] }; // meta should have an empty object as the old rule
      yield call(applyMemorizedRule, actionForApply);
    }
  });
}

// ====================================================
// EXPORTS

export default [
  getMemorizedRulesActionWatcher,
  createMemorizedRuleActionWatcher,
  updateMemorizedRuleActionWatcher,
  deleteMemorizedRuleActionWatcher,
  makeMemorizedRuleFromTransactionActionWatcher,
  combineMemorizedRulesActionWatcher,
  combineMemorizedRulesSuccessActionWatcher,
];
