import { createSelector } from 'reselect';
import { PREFERENCES_V2_REDUCER_KEY } from './preferencesV2Reducer';

export const getResourceStore = (state) => state[PREFERENCES_V2_REDUCER_KEY];
export const getLoadPending = (state) => getResourceStore(state).loadPending;
export const getIsLoading = (state) => getResourceStore(state).isLoading;
export const getError = (state) => getResourceStore(state).error;
export const getLastSyncDate = (state) => getResourceStore(state).lastSyncDate;
export const getPreferencesV2 = (state) => getResourceStore(state).resourcesById;

export const getPreferencesV2ByName = createSelector(
  getPreferencesV2,
  (preferencesById) => preferencesById.mapEntries(([_id, preference]) => [preference.name, preference]),
);

export const getPreferenceV2 = createSelector(
  getPreferencesV2ByName,
  (state, name) => name,
  (preferencesByName, name) => preferencesByName.get(name),
);

export const getPreferenceValueV2 = createSelector(
  getPreferencesV2ByName,
  (state, name) => name,
  (preferencesByName, name) => preferencesByName.get(name)?.value,
);
