import { createAction } from 'redux-actions';

export const getBudgetItemsAction = createAction('GET_BUDGET_ITEMS');
export const getBudgetItemsSuccessAction = createAction('GET_BUDGET_ITEMS_SUCCESS');
export const getBudgetItemsFailureAction = createAction('GET_BUDGET_ITEMS_FAILURE');

export const updateBudgetItemsAction = createAction('UPDATE_BUDGET_ITEMS', (payload) => payload, (payload, meta) => meta);
export const updateBudgetItemsSuccessAction = createAction('UPDATE_BUDGET_ITEMS_SUCCESS');
export const updateBudgetItemsFailureAction = createAction('UPDATE_BUDGET_ITEMS_FAILURE');

export const updateBudgetItem = createAction('UPDATE_BUDGET_ITEM', (payload) => payload, (payload, meta) => meta);
export const updateBudgetItemSuccess = createAction('UPDATE_BUDGET_ITEM_SUCCESS');
export const updateBudgetItemFailure = createAction('UPDATE_BUDGET_ITEM_FAILURE');

export const createBudgetItemsAction = createAction('CREATE_BUDGET_ITEMS', (payload) => payload, (payload, meta) => meta);
export const createBudgetItemsSuccessAction = createAction('CREATE_BUDGET_ITEMS_SUCCESS', (payload) => payload, (payload, meta) => meta);
export const createBudgetItemsFailureAction = createAction('CREATE_BUDGET_ITEMS_FAILURE');

export const deleteBudgetItemsAction = createAction('DELETE_BUDGET_ITEMS', (payload) => payload, (payload, meta) => meta);
export const deleteBudgetItemsSuccessAction = createAction('DELETE_BUDGET_ITEMS_SUCCESS', (payload) => payload, (payload, meta) => meta);
export const deleteBudgetItemsFailureAction = createAction('DELETE_BUDGET_ITEMS_FAILURE');
