import { List, Map as ImmutableMap, Record, Set } from 'immutable';

const SpecialTypesEnum = Object.freeze({
  UNDEFINED: '#UNDEFINED_D5DD5E3AF72344D281E115ADDE6FE30F',
  IMMUTABLE_RECORD: '#IMMUTABLE_RECORD_69EF907DCACF4306A78F340D5665B567',
  IMMUTABLE_LIST: '#IMMUTABLE_LIST_59EC9576AEBA47F296A3252718E085E2',
  IMMUTABLE_MAP: '#IMMUTABLE_MAP_473F18C36BB64E72AA14739480CDEC51',
  IMMUTABLE_SET: '#IMMUTABLE_SET_1E9A6C0ACD5C49FDA0A2183DB94AA975',
});

const simpleTypes = ['number', 'string', 'boolean', 'symbol'];

export const convertToJSRecursive = (object) => {
  let jsObject;

  let goRecursive = true;
  if (simpleTypes.includes(typeof object) || object === null) {
    jsObject = object;
    goRecursive = false;
  } else if (List.isList(object)) {
    jsObject = object.toArray();
    jsObject.push(SpecialTypesEnum.IMMUTABLE_LIST);
  } else if (ImmutableMap.isMap(object)) {
    jsObject = object.toObject();
    jsObject[SpecialTypesEnum.IMMUTABLE_MAP] = true;
  } else if (Record.isRecord(object)) {
    jsObject = object.toObject();
    jsObject[SpecialTypesEnum.IMMUTABLE_RECORD] = true;
  } else if (Set.isSet(object)) {
    jsObject = object.toArray();
    jsObject.push(SpecialTypesEnum.IMMUTABLE_SET);
  } else if (Array.isArray(object)) {
    jsObject = [...object];
  } else if (object === undefined) {
    jsObject = SpecialTypesEnum.UNDEFINED;
    goRecursive = false;
  } else {
    jsObject = { ...object };
  }
  if (goRecursive) {
    Object.keys(jsObject).forEach((key) => {
      if (typeof object[key] !== 'function') jsObject[key] = convertToJSRecursive(jsObject[key]);
    });
  }

  return jsObject;
};

export const dehydrateImmutable = (immutable) => {
  const jsObject = convertToJSRecursive(immutable);
  const dehydrated = JSON.stringify(jsObject);
  return dehydrated;
};

const buildRecordDefaultsDeep = (object) => Object.fromEntries(Object.entries(object).map(([key, value]) => {
  let entries;

  if (List.isList(value)) {
    entries = [key, List()];
  } else if (ImmutableMap.isMap(value)) {
    entries = [key, ImmutableMap()];
  } else if (Set.isSet(value)) {
    entries = [key, Set()];
  } else if (Array.isArray(value)) {
    entries = [key, []];
  } else if (value === null) {
    entries = [key, value];
  } else if (typeof value === 'object') {
    entries = [key, buildRecordDefaultsDeep(value)];
  } else {
    entries = [key, undefined];
  }

  return entries;
}));

export const convertToImmutableRecursive = (jsObject) => {
  let object;

  if (jsObject === SpecialTypesEnum.UNDEFINED) {
    object = undefined;
  } else if (simpleTypes.includes(typeof jsObject) || jsObject === null) {
    object = jsObject;
  } else if (Array.isArray(jsObject)) {
    if (jsObject[jsObject.length - 1] === SpecialTypesEnum.IMMUTABLE_LIST) {
      jsObject.pop();
      object = List(jsObject.map(convertToImmutableRecursive));
    } else if (jsObject[jsObject.length - 1] === SpecialTypesEnum.IMMUTABLE_SET) {
      jsObject.pop();
      object = Set(jsObject.map(convertToImmutableRecursive));
    } else {
      object = jsObject.map(convertToImmutableRecursive);
    }
  } else {
    object = {};
    Object.keys(jsObject).forEach((key) => {
      object[key] = convertToImmutableRecursive(jsObject[key]); // eslint-disable-line no-param-reassign
    });

    if (object[SpecialTypesEnum.IMMUTABLE_MAP]) {
      delete object[SpecialTypesEnum.IMMUTABLE_MAP]; // eslint-disable-line no-param-reassign
      object = ImmutableMap(object);
    } else if (object[SpecialTypesEnum.IMMUTABLE_RECORD]) {
      delete object[SpecialTypesEnum.IMMUTABLE_RECORD]; // eslint-disable-line no-param-reassign
      // const defaults = _.cloneDeepWith(jsObject, (value, key) => key ? null : undefined);
      const defaults = buildRecordDefaultsDeep(object);
      const Rec = Record(defaults);
      object = new Rec(object);
    }
  }

  return object;
};


export const hydrateImmutable = (json) => {
  const jsObject = JSON.parse(json);
  const hydrated = convertToImmutableRecursive(jsObject);
  return hydrated;
};

