import { takeEvery } from 'redux-saga/effects';

import { resourceStoreTypes, resourceSagaUtils } from '@quicken-com/react.flux.core';

import * as actions from './actions';
import { getLastSyncDate } from './selectors';
import { mkAlertRule } from './types';

const mkResourceConfig = (successAction, failureAction) => resourceStoreTypes.mkQcsSyncResourceConfig({
  resourceName: 'alertRule',
  resourceBaseUrl: '/alert/alert-rules',
  getLastSyncDate,
  successAction,
  failureAction,
  transformResponseToResources: (response: any) => response.data.resources.map((data) => mkAlertRule(data)),
});

export function* getAlertRulesActionWatcher() {
  yield takeEvery(actions.getAlertRules, resourceSagaUtils.qcsSyncGetResources, mkResourceConfig(actions.getAlertRulesSuccess, actions.getAlertRulesFailure));
}

export function* updateAlertRuleActionWatcher() {
  const resourceConfig = mkResourceConfig(actions.updateAlertRuleSuccess, actions.updateAlertRuleFailure);
  yield takeEvery(actions.updateAlertRule, resourceSagaUtils.qcsSyncUpdateResource, resourceConfig);
}

export default [
  updateAlertRuleActionWatcher,
  getAlertRulesActionWatcher,
];
