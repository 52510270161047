import { handleActions } from 'redux-actions';
import { DateTime } from 'luxon';
import { push } from 'connected-react-router';

import { localPreferences } from '@quicken-com/react.utils.core';
import { resourceStoreTypes, resourceUpserters } from '@quicken-com/react.flux.core';

import * as actions from 'data/entitlements/entitlementsActions';
import { mkEntitlement, ENTITLEMENTS_REDUCER_KEY as KEY } from 'data/entitlements/entitlementsTypes';

import { loadEntitlements, saveEntitlements, clearEntitlements } from './entitlementsUtils';

const savedStore = loadEntitlements();

const entitlementsReducer = handleActions({

  [actions.getEntitlements]:
    (state) => state.merge({ isLoading: true }),
  [actions.getEntitlementsSuccess]:
    (state, { payload }) => {
      let entitlements = payload.resources;

////////////////////////////////////////////////////////////////////////////////////////
      const entitlementsOverrideEntries = Object.entries(localPreferences.getItem('entitlementsOverride') || {});
      entitlements = entitlements.map((entitlement) => {
        const entitlementOverrideEntry = entitlementsOverrideEntries.find(([key]) => key === entitlement.id);
        const valueOverride = entitlementOverrideEntry ? entitlementOverrideEntry[1] : undefined;
        return entitlementOverrideEntry ?
          entitlement.merge({
            active: Boolean(valueOverride),
            expiresOn: valueOverride ? DateTime.local().plus({ years: 1 }).toISODate() : DateTime.local().toISODate(),
            expiresAt: valueOverride ? DateTime.local().plus({ years: 1 }).toISO() : DateTime.local().toISO(),
          }) : entitlement;
      });
      entitlementsOverrideEntries
        .filter(([key]) => !entitlements.find((entitlement) => entitlement.id === key))
        .forEach(([key, value]) => entitlements.push(mkEntitlement({
          id: key,
          active: Boolean(value),
          expiresOn: value ? DateTime.local().plus({ years: 1 }).toISODate() : DateTime.local().toISODate(),
          expiresAt: value ? DateTime.local().plus({ years: 1 }).toISO() : DateTime.local().toISO(),
        })));
///////////////

      const newState = resourceUpserters.upsertResources(state, payload.merge({ resources: entitlements, replaceCollection: true }));
      saveEntitlements(newState);

      return newState;
    },
  [actions.getEntitlementsFailure]:
    (state, { payload: error, asyncDispatch }) => {
      if (error.message !== 'sandbox'
        && error.message !== 'offline'
        && error.message !== 'valid auth tokens not found locally') {
        clearEntitlements();
        asyncDispatch(push('/fetch-subscription'));
      }
      return resourceUpserters.completeWithError(state, error);
    },

////////////////////////////////////////////////////////////////////////////////////
  [actions.setEntitlement]:
    (state, { payload }) => {
      const newState = resourceUpserters.upsertResource(state, payload);
      saveEntitlements(newState);
      return newState;
    },
///////////

  [actions.createEntitlement]:
    (state) => state.merge({ isLoading: true }),
  [actions.createEntitlementSuccess]:
    (state, { payload }) => {
      const newState = resourceUpserters.upsertResources(state, payload);
      saveEntitlements(newState);
      return newState;
    },
  [actions.createEntitlementFailure]:
    (state, { payload: error }) => resourceUpserters.completeWithError(state, error),

}, resourceStoreTypes.mkQcsSyncResourceStore(savedStore));

export const ENTITLEMENTS_REDUCER_KEY = KEY;
export default entitlementsReducer;
