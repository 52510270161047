import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { push } from 'connected-react-router';

import { getLogger, localPreferences, tracker, getBuildConfig, getClientConfig, platform } from '@quicken-com/react.utils.core';
import store from '@quicken-com/react.utils.redux-store';

const logger = getLogger('utils/core/bugsnag');

const errorsBlackList = [ // send error to bugsnag, but suppress any other handling - errorClass:errorMessage
  /NetworkError:.*/,
  /Error:Network Error/,
  /HTTPError:.*/,
  /LaunchDarklyFlagFetchError:.*/,
  /Assert:.*/,
  /Error:valid auth tokens not found locally/,
];

const treatAsWarning = [ // send error to bugsnag and popup in dev build, but don't show error page + don't track in Mixpanel - errorClass:errorMessage
  'ReferenceError:grecaptcha is not defined',
  'TypeError:can\'t redefine non-configurable property "userAgent"', // some firefox extension specific error
  /InvalidError:.*/, // another flavor of unhandled rejection
  /UnhandledRejection:.*/,
  /Error EventDispatcher is disposed.*/, // handled amchart error which is not reproducable
];

const disableBugsnag = localPreferences.getDisableBugsnag();

let gUserId = null;
let gDatasetId = null;
export const bugsnagIdentify = (userId, datasetId) => {
  gUserId = userId;
  gDatasetId = datasetId;
};

let showErrorPageTimer;
if (!disableBugsnag) {
  Bugsnag.start({
    apiKey: getBuildConfig().bugsnag_api_key, // eslint-disable-line camelcase
    appType: getClientConfig().product_name,  // it's abusing appType a bit - feel free to change it if needed
    appVersion: getClientConfig().app_version,
    autoDetectErrors: true,
    autoTrackSessions: true,
    collectUserIp: true,
    enabledBreadcrumbTypes: [
      'error',
      'navigation',
      'request',
      'user',
      // 'log', // sorry no logs until sanitize them
    ],
    logger,
    maxBreadcrumbs: 40,
    maxEvents: 1,
    releaseStage: getBuildConfig().bugsnag_release_stage, // eslint-disable-line camelcase
    metadata: {
      device: platform.device.type,
    },
    plugins: [new BugsnagPluginReact(React)],
    onError: (event) => {
      const sendReport = !localPreferences.getIgnoreBugsnagReport();
      if (event.originalError) {
        console.error(event.originalError); // eslint-disable-line no-console
      }

      const newEvent = event;
      newEvent.setUser(gUserId);
      newEvent.addMetadata('user', { datasetId: gDatasetId });

      const error = event?.errors?.[0];
      const { errorClass, errorMessage, stacktrace, type } = error;

      const errorDetails = JSON.stringify(stacktrace?.[0]);
      const props = {
        href: window.location.href,
        path: window.location.pathname,
        exception_type: type,
        exception_class: errorClass,
        exception_name: errorMessage,
        exception_reason: errorDetails,
      };
      tracker.track(tracker.events.exception, props);

      const context = `${errorClass}:${errorMessage}`;
      if (!sendReport) {
        logger.warn('send crash report is suppressed from settings - report not gonna be sent...');
      }
      if (errorsBlackList.find((mask) => typeof mask === 'string' ? context.includes(mask) : mask.test?.(context))) {
        logger.warn(`error blacklisted: '${context}`, newEvent);
      } else {
        logger.warn(`error will be sent to bugsnag: '${context}`, newEvent);
        let showErrorPage = !treatAsWarning.find((mask) => typeof mask === 'string' ? context.includes(mask) : mask.test?.(context));
        if (!showErrorPage) {
          logger.warn(`error ignored: '${context}`);
        }

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
///////////////////////////////////
//////////////////////////////////////////////
///////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////
////////////////////////////////
/////////
/////////////////

        if (showErrorPage) {
          clearTimeout(showErrorPageTimer);
          showErrorPageTimer = setTimeout(() => {
            tracker.track(tracker.events.crash, props);
            setTimeout(() => {
              if (window.location.pathname !== '/error') {
                store?.dispatch(push('/error'));
              }
            }, 1000);
          }, 1000);
        }
      }

      return sendReport;
    },
  });
}

export const dismissErrorPage = () => clearTimeout(showErrorPageTimer);

export const bugsnagNotify = (error, onError, ...others) => {
  if (!disableBugsnag) {
    Bugsnag.notify(error, onError, ...others);
    dismissErrorPage();
  }
};

export const BugsnagErrorBoundary = !disableBugsnag && Bugsnag.getPlugin('react');

export default !disableBugsnag && Bugsnag;
