import React, { forwardRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { OrderedMap } from 'immutable';
import { v4 as uuidv4 } from 'uuid';

import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import SnackbarContent from '@mui/material/SnackbarContent';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/ClearRounded';

import { getLogger } from '@quicken-com/react.utils.core';

import SimplifiIcon from 'assets/simplifi-logo.svg';
import QButton from 'components/QButton';
import { addNotifications, removeNotifications } from 'data/notifications/notificationsActions';
import { mkNotification } from 'data/notifications/notificationsTypes';

import {
  allowInstallPromptToBeDisabled,
  getActivePwaNotification,
  setActivePwaNotification,
  trackBrowserInstall,
  trackInstall,
  trackInstallDismissed,
  trackInstallPrompted,
} from './PwaHelpers';
import { useStyles } from './styles';

const log = getLogger('InstallViaInstallPrompt');

type Props = {
  onDismiss: (boolean) => void,
  onInstall: () => void,
}

const InstallPrompt = forwardRef((props : Props, ref) => {
  const { onDismiss, onInstall } = props;
  const classes = useStyles();

  const allowDisable = allowInstallPromptToBeDisabled();

  return (
    <SnackbarContent
      className={classes.root}
      classes={{
        message: classes.muiMessage,
      }}
      ref={ref}
      message={
        <Paper className={classes.toast} elevation={3} square={false}>
          <IconButton
            className={classes.closeButton}
            aria-label="Dismiss"
            id="snackbar-dismiss"
            type="button"
            onClick={() => onDismiss(false)}
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <img className={classes.icon} src={SimplifiIcon} alt="" />
          <Typography className={classes.title} variant="h6">Install Simplifi by Quicken</Typography>
          <Typography className={classes.message} variant="body1">
            Add Simplifi to your applications to have instant access to the app and
            enhanced features!
          </Typography>
          <div className={classes.actions}>
            <QButton
              className={classes.actionSecondary}
              id="button-dismiss"
              onClick={() => onDismiss(allowDisable)}
              variant="outlined"
            >
              {allowDisable ? 'Never Show' : 'Not Now'}
            </QButton>
            <QButton
              className={classes.action}
              id="button-install"
              onClick={() => onInstall()}
              variant="contained"
            >
              Install
            </QButton>
          </div>
        </Paper>
      }
    />
  );
});

const InstallViaInstallPrompt = () => {
  log.info('Waiting for a beforeinstallprompt event...');
  const dispatch = useDispatch();

  useEffect(() => {

    const handleDismiss = (id, disable) => {
      trackInstallDismissed(disable);
      dispatch(removeNotifications([id]));
      setActivePwaNotification(null);
    };

    const handleInstall = (id, event) => {
      trackInstall();
      dispatch(removeNotifications([id]));

      // show native prompt
      event.prompt();

      // decide what to do after the user chooses
      event.userChoice.then((choice) => {
        trackBrowserInstall(choice.outcome === 'accepted');
      });

      setActivePwaNotification(null);
    };

    const beforeInstallPromptHandler = (event, count = 1) => {
      log.info('Received beforeInstallPrompt');
      event.preventDefault();

      // Do not show a PWA notification if one is already being shown. But, try again in a minute for up to 5 minutes.
      // Otherwise; won't show till at least next hard refresh.
      //
      if (getActivePwaNotification()) {
        if (count < 5) {
          setInterval(() => {
            beforeInstallPromptHandler(event, count + 1);
          }, 60000);
        }
        return;
      }

      trackInstallPrompted();
      setActivePwaNotification('PWA_INSTALL_PROMPT');

      const id = uuidv4();
      dispatch(addNotifications(OrderedMap([
        [
          id,
          mkNotification({
            id,
            anchorOrigin: { horizontal: 'right', vertical: 'top' },
            snackbarContent: InstallPrompt,
            snackbarContentProps: {
              onClose: () => handleDismiss(id, false),
              onDismiss: (disable) => handleDismiss(id, disable),
              onInstall: () => handleInstall(id, event),
            },
            autoHideDuration: 30000,
          }),
        ],
      ])));
    };

    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    };

  }, [dispatch]);

  return null;
};

export default InstallViaInstallPrompt;
