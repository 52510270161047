import makeStyles from '@mui/styles/makeStyles';
import { isAcme } from 'isAcme';

const useStyles = makeStyles((theme) => {
  const { spacing, typography } = theme;
  return ({
    titleBar: {
      alignItems: 'center',
      display: 'flex',
      height: 58,
      justifyContent: 'center',
      padding: spacing(2, 3),
      ...typography.h6,
      ...(isAcme ? {} : {
        fontWeight: 400,
        width: '100%',
        textAlign: 'center',
      }),
    },
    buttonSpace: {
      padding: spacing(2, 7),
    },
    closeButton: {
      position: 'absolute',
      right: spacing(1.5),
      padding: spacing(0.5),
    },
    backButton: {
      position: 'absolute',
      left: spacing(1.5),
      padding: spacing(0.5),
    },
    titleLabel: {
      width: '100%',
      textAlign: 'center',
    },
  });
});

export default useStyles;
