
// CORE
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field } from 'formik';
import _ from 'lodash';

import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

// CUSTOM COMPONENTS
import FormikSelect from 'components/Formik/Select';

const useStyles = makeStyles((_theme) => ({
  dueDateField: {
    width: '100%',
  },
}));

const name = 'autoAdjustDueOn';

// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
// ### - - -                   Menu Component                    - - - ###
// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
function DueDateField(props) {

  const { formikBag, className } = props;
  const { touched, errors } = formikBag;

  const helperText = _.get(touched, name) && _.get(errors, name);
  const inError = Boolean(helperText);
  const classes = useStyles();

  // render return
  return (
    <FormControl variant="outlined" margin="normal" className={classNames(classes.dueDateField, className)} error={inError}>
      <InputLabel id={'Bill-Date-Label'}>Date</InputLabel>
      <Field
        component={FormikSelect}
        name={name}
        validate={(val) => val === undefined && 'required'}
        variant="outlined"
        fullWidth
        label="Date"
        id="Bill-Date-Select"
      >
        <MenuItem value>
          Due Date
        </MenuItem>
        <MenuItem value={false}>
          {"Don't auto-update"}
        </MenuItem>
      </Field>

      <FormHelperText id="Bill-Date-HelpText">{helperText}</FormHelperText>
    </FormControl>
  );
}

DueDateField.defaultProps = {
};

DueDateField.propTypes = {
  formikBag: PropTypes.object,
  //  isCredit: PropTypes.bool,
  className: PropTypes.string,
};

export default DueDateField;

