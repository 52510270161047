import { Map as ImmutableMap } from 'immutable';

// KEN COMMENTS (modified by JLA for investment holdings)
// So here is the update flow I was trying to achieve:
// - If an investment holding changes, then that will cause the overall top-level store to change (iHoldsByAccountId)
// - However, if holding reference to the investment holding within an accountID then that will only change if investment holdings in the account change
// - So, in Register View, it will cause it to re-render if the 2nd level map changes
// - But, each investment holding row shouldn't have it's render called unless the investment holding changed
// - This is supposed to be handled through a combination of things,
//   - React knows the row because it should be based on investment holding id so it can find even if list changed
// - The investment holding itself which is passed down as prop shouldn't have changed even though the parent map may have changed


// upsertIHold
//
// Optimized to upsert a single investment holding into the investment holding store.
//
// Returns the updated state.
//

export function upsertIHold(state, iHoldToUpsert) {

  let iHoldsByIdForAccountId = state.iHoldsByAccountId.get(iHoldToUpsert.accountID);
  if (!iHoldsByIdForAccountId) {
    // if we don't have a map of investment holdings by ID for this account, create it now
    iHoldsByIdForAccountId = new Map();
  }

  if (!iHoldToUpsert.isDeleted) {
    iHoldsByIdForAccountId = iHoldsByIdForAccountId.set(iHoldToUpsert.id, iHoldToUpsert);
  } else {
    iHoldsByIdForAccountId = iHoldsByIdForAccountId.remove(iHoldToUpsert.id);
  }

  // update iHoldsByAccountId entry for the account of the upserted investment holding
  const iHoldsByAccountId = iHoldsByIdForAccountId.size > 0 ?
    state.iHoldsByAccountId.set(iHoldToUpsert.accountID, iHoldsByIdForAccountId) :
    state.iHoldsByAccountId.delete(iHoldToUpsert.accountID);

  return state.merge({ iHoldsByAccountId, isLoading: false });
}

// upsertIHolds
//
// Optimized to upsert multiple transactions into the transaction store.
//
export function upsertIHolds(state, data) {
  const {
    iHolds,
    lastSyncDate,
  } = data;

  if (iHolds.size === 1) {
    return upsertIHold(state, iHolds.get(0));
  }

  const iHoldsToUpsertByAccountId = iHolds.reduce((map, iHold) => {
    let iHoldsToUpsertForAccountId = map.get(iHold.accountID);
    if (!iHoldsToUpsertForAccountId) {
      map.set(iHold.accountID, iHoldsToUpsertForAccountId = []);
    }
    iHoldsToUpsertForAccountId.push(iHold);
    return map;
  }, new Map());

  if (iHoldsToUpsertByAccountId.size === 0) {
    // No holdings in any account
    return state.merge({ iHoldsByAccountId: ImmutableMap(), isLoading: false });
  }

  return state.merge({
    iHoldsByAccountId: state.iHoldsByAccountId.withMutations((iHoldsByAccountId) => {

      if (iHoldsByAccountId.size > iHoldsToUpsertByAccountId.size) {
        // Find any accounts that are empty and removed the holdings
        const accountIdsToDelete = [];
        iHoldsByAccountId.forEach((holds, accountId) => {
          if (!iHoldsToUpsertByAccountId.get(accountId)) accountIdsToDelete.push(accountId);
        });
        accountIdsToDelete.forEach((accountId) => iHoldsByAccountId.delete(accountId));
      }

      iHoldsToUpsertByAccountId.forEach((holds, accountId) => {
        let iHoldsByIdForAccountId = ImmutableMap();    // Always create a new map
        iHoldsByIdForAccountId = iHoldsByIdForAccountId.withMutations((iHoldsById) => {
          holds.forEach((hold) => {
            if (!hold.isDeleted) {
              iHoldsById.set(hold.id, hold);
            } else {
              iHoldsById.delete(hold.id);
            }
          });
        });

        if (iHoldsByIdForAccountId.size > 0) {
          iHoldsByAccountId.set(accountId, iHoldsByIdForAccountId);
        } else {
          iHoldsByAccountId.delete(accountId);
        }
      });
    }),
    isLoading: false,
    lastSyncDate: lastSyncDate || state.lastSyncDate,
  });
}
