
// CORE
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Map, OrderedMap } from 'immutable';

import { scheduledTransactionsActions, scheduledTransactionsTypes } from '@quicken-com/react.flux.scheduled-transactions';
import { chartOfAccountsSelectors } from '@quicken-com/react.flux.chart-of-accounts';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';

// DATA
import store from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { removeDialog } from 'data/rootUi/actions';
import { getBiller } from 'data/billers/selectors';
import { getUnconnectedAccounts, getBillerAccountForId } from 'data/billerAccounts/selectors';
import { deleteBillerLogin } from 'data/billerLogins/actions';
import { getBillerLoginForId } from 'data/billerLogins/selectors';
import { addNotifications, removeNotifications } from 'data/notifications/notificationsActions';
import { mkNotification } from 'data/notifications/notificationsTypes';


// CUSTOM COMPONENTS
import { getLogger, tracker } from '@quicken-com/react.utils.core';
import StdDialog from 'components/Dialogs/StdDialog';
import bankIcon from 'assets/nav-menu/accounts.svg';
import { autoAdjustAmountEnum } from 'components/BillPresentment/BillAmountField';
import SearchPage from './SearchPage';
import LoginPage from './LoginPage';
import AccountsPage from './AccountsPage';
import CompletePage from './CompletePage';
import NotificationContent from './NotificationContent';
// import { billerHardcode, singleAccountsHardcode, multipleAccountsHardcode, seriesHardcode } from './testData';

const log = getLogger('BillPresentment/index');


const useStyles = makeStyles((theme) => ({
  content: {
    maxHeight: 592,
    overflow: 'auto',
    padding: '12px 24px',
    position: 'relative',
    display: 'flex',
  },
  root: {
    margin: '12px 32px 48px 0',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 316,
    maxHeight: '100%',
    width: 'calc(100% - 118px)',
  },
  brandingIcon: {
    height: 66,
    width: 104,
    margin: '12px 18px 0 0',
    border: `1px solid ${theme.palette.greyScaleDeprecated[4]}`,
    borderRadius: 8,
    backgroundColor: theme.palette.greyScaleDeprecated[6],
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 72,
    flexShrink: 0,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.secondary,
  },
  spinner: {
    marginRight: 8,
  },
}));
const setWidth = { width: 600 };


// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
// ### - - -                  Main Component                     - - - ###
// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
function BillPresentmentDialog(props) {

  const classes = useStyles();
  const dispatch = useDispatch();
  const { series, initiator } = props;
  // const series = seriesHardcode;
  if (!series) log.error('no series sent, should not happen!');
  const snackId = series?.id;

  const isMounted = useRef();

  const connectedAccount = useSelector((state) => getBillerAccountForId(state, series?.billPresentmentAccountId));
  const connectedLogin = useSelector((state) => getBillerLoginForId(state, connectedAccount?.billerLoginID));
  const reconnect = Boolean(connectedLogin);

  const [openState, setOpenState] = useState(true);
  const [selectedBiller, setSelectedBiller] = useState(null); // we get after biller selection
  const [billerLogin, setBillerLogin] = useState(null); // we get after successful login
  const [complete, setComplete] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const [accountMap, setAccountMap] = useState(Map()); // map of account ID's to models to connect
  const [editAccount, setEditAccount] = useState(null); // credit account being edited

  // loginPage state
  // This is here so we can garbo collect cancelled logins
  const [currentLogin, setCurrentLogin] = useState(connectedLogin);

  const biller = useSelector((state) => (getBiller(state, selectedBiller?.id || series?.providerBillerId) || selectedBiller || {}));

  const mixpanelProps = { initiator, biller_id: biller?.id, biller_name: biller?.name };

  const currentAccounts = useSelector((state) => getUnconnectedAccounts(state, billerLogin?.id));
  // currentAccounts = multipleAccountsHardcode;

  let step = 'search';
  if (billerLogin) {
    step = 'discovery';
  } else if (reconnect || selectedBiller) {
    step = 'authentication';
  }

  // track mounted state because of async promise
  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);

  useEffect(() => {
    let autoClose;
    if (complete) {
      autoClose = setTimeout(() => onClose(), 5000);
    }
    return () => clearTimeout(autoClose);
  }, [complete]); // eslint-disable-line react-hooks/exhaustive-deps

  // Accounts logic
  const updateAccountMap = useCallback((id, modelToMap) => {
    const newModel = scheduledTransactionsTypes.mkScheduledTransaction({
      autoAdjustAmount: autoAdjustAmountEnum.BALANCE,
      autoAdjustDueOn: true,
      ...(modelToMap?.toJS ? modelToMap?.toJS() : modelToMap),
      transaction: modelToMap.transaction,
      billPresentmentAccountId: id,
      providerBillerId: biller?.id,
    });
    const newAccountMap = modelToMap ? accountMap.set(id, newModel) : accountMap.delete(id);
    setAccountMap(newAccountMap);
  }, [accountMap, biller]);

  // Auto-link on one account
  // -------------------------------------------------------------------------------------------------
  if (currentAccounts?.size === 1 && !complete && !editAccount) {
    const account = currentAccounts.first();
    const state = store.getState();
    const isCredit = series.transaction && chartOfAccountsSelectors.isCreditBill(state, series.transaction.accountId, series.transaction.coa);

    if (isCredit) {
      setEditAccount(account);
      updateAccountMap(account.id, series);
    } else {
      setComplete(true);
      const newModel = scheduledTransactionsTypes.mkScheduledTransaction({
        ...(series?.toJS ? series?.toJS() : series),
        transaction: series.transaction,
        billPresentmentAccountId: account.id,
        providerBillerId: biller?.id,
        autoAdjustAmount: autoAdjustAmountEnum.BALANCE,
        autoAdjustDueOn: true,
      });
      dispatch(scheduledTransactionsActions.updateScheduledTransaction(newModel));

      tracker.track(tracker.events.addBillerComplete, {
        ...mixpanelProps,
        bills_found: 1,
        bills_linked: 1,
        amount_selected: 'statement', // 'minimum', 'custom',
        date_selected: 'due date', // 'pay date',
        model_category: chartOfAccountsSelectors.getCoaStringSelector(undefined, series.transaction?.coa),
      });
    }
  }


  // # ===================================================================================== #
  // # -----                            Action Functions                               ----- #
  // # ===================================================================================== #

  const hideSnackbar = () => {
    dispatch(removeNotifications([snackId]));
    setShowSnackbar(false);
  };

  const cleanLogin = () => {
    if (currentLogin && !complete) {
      if (currentLogin?.id && currentLogin.id !== '0') {
        dispatch(deleteBillerLogin(currentLogin));
      }
      setCurrentLogin(null);
    }
  };

  function onClose() {
    setOpenState(false);
    hideSnackbar();
    dispatch(removeDialog(`BPS-${series?.id}`));

    if (!reconnect && !complete) { // if users exits process early, clean model + login
      cleanLogin();
      dispatch(scheduledTransactionsActions.updateScheduledTransaction(scheduledTransactionsTypes.mkScheduledTransaction({
        id: series.id,
        billPresentmentAccountId: null,
        providerBillerId: null,
      })));
    }

    if (!complete) {
      tracker.track(tracker.events.addBillerCancel, { ...mixpanelProps, step, reinitializing: false });
    }
  }

  const makeSnackbar = () => {
    setShowSnackbar(true);
    dispatch(addNotifications(OrderedMap([
      [snackId,
        mkNotification({
          id: snackId,
          autoHideDuration: null,
          onClose: () => { log.log('Snackbar should not close'); },
          snackbarContent: NotificationContent,
          snackbarContentProps: {
            name: biller?.name,
            onClose,
          },
        }),
      ],
    ])));
  };

  function restart() {
    setOpenState(true);
    setSelectedBiller(null);
    setBillerLogin(null);
    setComplete(false);
    if (!reconnect) cleanLogin();
  }

  const goBack = () => {
    if (editAccount) {
      setEditAccount(null);
    } else if (accountMap?.size) {
      setAccountMap(Map());
    } else {
      if (!reconnect) cleanLogin();

      if (billerLogin) {
        setBillerLogin(null);
      } else {
        setSelectedBiller(null);
      }
    }
    tracker.track(tracker.events.addBillerCancel, { ...mixpanelProps, step, reinitializing: true });
  };


  // ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
  // ### - - -                            Render Section                           - - - ###
  // ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
  const accountPage = Boolean(billerLogin);

  const renderPage = () => {
    if (complete) {
      return <CompletePage biller={biller} />;
    }
    if (accountPage) {
      return (<AccountsPage
        accounts={currentAccounts}
        biller={biller}
        series={series}
        accountMap={accountMap}
        updateAccountMap={updateAccountMap}
        complete={complete}
        setComplete={setComplete}
        onRestart={restart}
        mixpanelProps={mixpanelProps}
        editAccount={editAccount}
        setEditAccount={setEditAccount}
      />);
    }
    if (reconnect || selectedBiller) {
      const websiteLink = biller?.loginURL || biller?.websiteURL;
      return (
        <>
          <img
            alt="Bank Icon"
            className={classes.brandingIcon}
            src={biller?.logo || bankIcon}
          />

          <div className={classes.root}>
            <div className={classes.header}>
              <Typography variant="subtitle1">
                {biller?.name}
              </Typography>

              <Typography variant="body2" noWrap>
                <a href={websiteLink} target="_blank" className={classes.link}>
                  {websiteLink}
                </a>
              </Typography>
            </div>

            <LoginPage
              biller={biller}
              series={series}
              onComplete={reconnect ? setComplete : setBillerLogin}
              connectedLogin={connectedLogin}
              showSnackbar={makeSnackbar}
              hideSnackbar={hideSnackbar}
              mixpanelProps={mixpanelProps}
              currentLogin={currentLogin}
              setCurrentLogin={setCurrentLogin}
            />
          </div>
        </>
      );
    }
    return <SearchPage onSelect={setSelectedBiller} initiator={initiator} />;
  };

  const allowBack = Boolean(!complete && !reconnect && (selectedBiller || billerLogin));
  const dialogStyle = { display: showSnackbar && 'none' };

  return (
    <StdDialog
      title="Connect to Account"
      open={openState}
      maxWidth="md"
      fullWidth={!accountPage}
      onClose={onClose}
      disableBackdropClick={allowBack}
      showBackButton={allowBack}
      onBack={goBack}
      style={dialogStyle}
      sharedcomponentid={'BILL_PRESENTMENT_DIALOG'}
    >
      <div className={classes.content} style={accountPage ? setWidth : undefined}>
        {renderPage()}
      </div>
    </StdDialog>
  );
}

BillPresentmentDialog.defaultProps = {
  initiator: 'other???',
};

BillPresentmentDialog.propTypes = {
  series: PropTypes.object,
  initiator: PropTypes.string,
};

export default BillPresentmentDialog;
