import { handleActions } from 'redux-actions';

import { getLogger } from '@quicken-com/react.utils.core';
import { resourceStoreTypes, resourceUpserters } from '@quicken-com/react.flux.core';
import * as actions from './categoryGroupsActions';

const log = getLogger('data/categoryGroups/categoryGroupsReducer.js');

export const CATEGORY_GROUPS_REDUCER_KEY = 'categoryGroupsStore';

const initialState = resourceStoreTypes.mkQcsSyncResourceStore();
const categoryGroupListsReducer = handleActions({
  [actions.getCategoryGroupsAction]: (state) => {
    log.log('getCategoryGroupsAction...');
    return state.merge({ isLoading: true });
  },
  [actions.getCategoryGroupsSuccessAction]: (state, { payload }) => {
    log.log('getCategoryGroupSuccessAction...\n', payload);
    return resourceUpserters.upsertResources(state, payload);
  },
  [actions.getCategoryGroupsFailureAction]:
    (state, { payload: error }) => resourceUpserters.completeWithError(state, error),

  [actions.updateCategoryGroupAction]: (state) => {
    log.log('updateCategoryGroupAction...');
    return state.merge({ isLoading: true });
  },
  [actions.updateCategoryGroupSuccessAction]: (state, { payload }) => {
    log.log('updateCategoryGroupSuccessAction...\n', payload);
    return resourceUpserters.upsertResource(state, payload, false); // no merge
  },
  [actions.updateCategoryGroupFailureAction]: (state, { payload: error }) => {
    log.log('updateCategoryGroupFailureAction...\n', error);
    return resourceUpserters.completeWithError(state, error);
  },

}, initialState);

export default categoryGroupListsReducer;
