/*
 * PREFERENCE STORE SAGAS
 *
 * These routines listen for request actions, and satisfies them.  These are asynchronous routines, and upon
 * completion will dispatch a new action with the results
 *
 *
 */
import { List as ImmutableList } from 'immutable';
import { call, put, takeEvery, select } from 'redux-saga/effects';

import { getEnvironmentConfig, getLogger } from '@quicken-com/react.utils.core';
import { resourceStoreTypes, resourceSagaUtils } from '@quicken-com/react.flux.core';
import { accountsActions, accountsSelectors } from '@quicken-com/react.flux.accounts';

import * as preferencesSelectors from 'data/preferences/selectors';

import { defaultDatasetPreferences } from 'data/preferences/defaults';
import AxiosFactory from 'utils/axiosFactory';
import { requiredColumns, fieldData } from 'components/TransactionRegister/transactionsConfig';

import { initAccountPrefs } from './utils';

import * as actions from './actions';

const log = getLogger('data/preferences/sagas.js');

// ===============================================================================================
// SAGAS functions for preferences.  These are the functions responsible for communicating directly
// with external endpoints asynchronously.  Data is returned via ACTIONS, however, these can be
// split out (and should be) to be fully functional and JS shareable API callers via Axios and
// simply return an object in a provided callback, which can then choose to call the actions
//

function constructPreferencesUrl(data) {

  let requestUrl;
  let urlSection;

  switch (data?.section) {
    case 'shared':
      urlSection = data.group === 'dataset' ? '' : `/${data.group}`;
      requestUrl = `${getEnvironmentConfig().services_url}/preferences${urlSection}`;
      break;

    case 'client':
      urlSection = data.group === 'dataset' ? '' : `/${data.group}`;
      requestUrl = `${getEnvironmentConfig().services_url}/preferences/client${urlSection}`;
      break;

    default:
      requestUrl = `${getEnvironmentConfig().services_url}/preferences`;
      break;

  }

  return requestUrl;
}

// ====================================================
// GET PREFERENCES
//
// specify the section and they group as follows { section: <string>, group: <string> }
//
// SECTION: one of 'shared', 'client', 'qcs'
// GROUP: shared, one of 'user', 'dataset;
//        client, one of 'user', 'dataset', 'global'
//        qcs: only top level
//
export function* getPreferences({ payload: data }) {
  log.log('Getting preferences...', data);

  try {
    const qcsAxios = AxiosFactory.get('qcs');

    const requestUrl = constructPreferencesUrl(data);

    // ASYNC CALL HERE
    const response = yield call(qcsAxios.get, requestUrl, data?.group === 'user' && { headers: { 'qcs-dataset-id': null } });

    // SUCCESS CALLBACK
    //
    // convert the preference array to an immutable object
    // you can do any transformation here to convert from 3rd party format
    // to your internal state format

    // remove deleted

    log.log('Got Preferences', response.data, data);

    // this does nothing actually except set loading
    yield put(actions.getPreferencesSuccess({ resources: response.data, path: data }));

  } catch (error) {
    // ERROR CALLBACK
    // todo: make sure this is an axios error
    assert(false, error);

    yield put(actions.getPreferencesFailure(error));
  }
}


// ====================================================
// SET PREFERENCE
// if you want to use yield, put a star here
//
// Pass in an object { section, group, preference }
//
// SECTIONS: one of 'shared', 'client', 'qcs'
// GROUP: shared, one of 'user', 'dataset;
//        client, one of 'user', 'dataset', 'global'
//        qcs: only top level
//
//
// preference is a object structure following the rules described here (do not send JSON, we convert for you)
// https://quickenteam.atlassian.net/wiki/spaces/EN/pages/99037174/Preference+Service
//
//

export function* setPreference(action) {
  const config = resourceStoreTypes.mkQcsSyncResourceConfig({
    resourceName: 'preferences',
    resourceBaseUrl: action.payload.group !== 'dataset' ? `/preferences/${action.payload.group}` : '/preferences',
    successAction: actions.setPreferenceSuccess,
    failureAction: actions.setPreferenceFailure,
    transformResourceToRequestData: (resource) => resource.preference,
    ...(action.payload.group !== 'dataset' && { axiosConfig: { headers: { 'qcs-dataset-id': null } } }),
  });
  yield call(resourceSagaUtils.qcsSyncUpdateResource, config, action);
}

export function* resetAllPreferences() {

  try {
    const qcsAxios = AxiosFactory.get('qcs');
    const data = {
      section: 'shared',
      group: 'dataset',
      preference: null,
    };
    // first set redux prefs to defaults with a faux response
    yield put(actions.setPreferenceSuccess({
      data,
      response: { data: defaultDatasetPreferences },
    }));

    // now null out the prefs on QCS
    const requestUrl = constructPreferencesUrl(data);

    yield call(qcsAxios.put, requestUrl, { webApp: null }, data.group === 'user' && { headers: { 'qcs-dataset-id': null } });
  } catch (error) {
    assert(false, error);

    log.log('Error in resetting preferences');
  }
}

// ====================================================
// DELETE PREFERENCE
// if you want to use yield, put a star here
export function* deletePreference({ payload: _data }) {
  /*
    let requestUrl = `${getEnvironmentConfig().services_url}/preferences`;
    requestUrl += `/${data.id}`;
    log.log(`In SAGAS: Delete Preference ${requestUrl}`, data);

    try {
      const qcsAxios = AxiosFactory.get('qcs');

      // yield below causes the call to happen async, code following it is the
      // callback functions (one for success, one for error)
      //
      // assumes that the data passed in is valid and per the QCS spec
      yield call(qcsAxios.delete, requestUrl, data);

      // create an immutable object
      // const preference = types.Preference(data);
      yield put(actions.deletePreferenceSuccess(data.id));
    } catch (error) {
      log.log('Update Preference Error...', error);
      yield put(actions.deletePreferenceFailure(error));
    }
  */
}

// ====================================================
// INIT ACCOUNT PREFERENCES
export function* initAccountPreferences() {

  const accountsPending = yield select(accountsSelectors.getLoadPending);
  const prefsPending = yield select(preferencesSelectors.getLoadPendingState);
  if (!accountsPending && !prefsPending.shared.dataset) {
    const accounts = yield select(accountsSelectors.getAccountsById);
    const acctPrefs = yield select(preferencesSelectors.allAccountPreferences);

    if (accounts) {
      const newAcctPrefs = initAccountPrefs(accounts, acctPrefs);

      if (newAcctPrefs) {

        const prefObj = {
          section: 'shared',
          group: 'dataset',
          preference: { webApp: { accountsPrefs: newAcctPrefs } },
        };
        yield put(actions.setPreference(prefObj, { context: 'initAccountPreferences1' }));
      }
    }
    //
    // Also check if the transaction columns config was updated with new defaults
    // if the required defaults in the code contain any columns NOT in the registerPrefs, then
    // add required defaults in
    //
    const prefs = yield select(preferencesSelectors.getSharedPreferencesByPath,
      { group: 'dataset', path: ['webApp'] });

    const buildRegFields = (regFields, regFieldsDefault) => {
      let newRegFields = regFields || ImmutableList();
      regFieldsDefault?.forEach((col) => {
        if (!newRegFields.includes(col)) {
          const slot = newRegFields.findIndex((x) => fieldData[x].position > fieldData[col].position);
          newRegFields = newRegFields.insert(slot, col);
        }
      });
      return newRegFields;
    };
    const regFieldsCashFlow = prefs.transactionRegister.regFieldsSort.cashFlow;
    const newFieldsCashFlow = buildRegFields(regFieldsCashFlow, requiredColumns.default);
    const regFieldsInvestment = prefs.transactionRegister.regFieldsSort.investment;
    const newRegFieldsInvestment = buildRegFields(regFieldsInvestment, requiredColumns.investment);
    if (newFieldsCashFlow !== regFieldsCashFlow || newRegFieldsInvestment !== regFieldsInvestment) {
      const prefObj = {
        section: 'shared',
        group: 'dataset',
        preference: { webApp: { transactionRegister: { regFieldsSort: {
          cashFlow: newFieldsCashFlow,
          investment: newRegFieldsInvestment,
        } } } },
      };
      yield put(actions.setPreference(prefObj, { context: 'initAccountPreferences2' }));
    }

  }
}

// ====================================================
// ACTION WATCHERS to trigger SAGAS calls

export function* getPreferencesActionWatcher() {
  yield takeEvery(actions.getPreferences, getPreferences);
}

export function* getPreferencesUserActionWatcher() {
  yield takeEvery(actions.getPreferencesUser, function* getPreferencesUser(action) {
    getPreferences({
      ...action,
      payload: {
        ...action.payload,
        group: 'user',
      },
    });
  });
}

export function* getAccountsSuccessActionWatcher() {
  yield takeEvery(accountsActions.getAccountsSuccess, initAccountPreferences);
}

export function* upsertAccountsSuccessActionWatcher() {
  yield takeEvery(accountsActions.batchAccountsSuccess, initAccountPreferences);
}

export function* initAccountPreferencesActionWatcher() {
  yield takeEvery(actions.initAccountPreferences, initAccountPreferences);
}

export function* resetAllPreferencesActionWatcher() {
  yield takeEvery(actions.resetAllPreferences, resetAllPreferences);
}

export function* getPreferencesSuccessActionWatcher() {
  yield takeEvery(actions.getPreferencesSuccess, initAccountPreferences);
}

export function* setPreferenceActionWatcher() {
  yield takeEvery(actions.setPreference, setPreference);
}

export function* deletePreferenceActionWatcher() {
  yield takeEvery(actions.deletePreference, deletePreference);
}


// ====================================================
// EXPORTS

export default [
  getPreferencesActionWatcher,
  getPreferencesUserActionWatcher,
  setPreferenceActionWatcher,
  deletePreferenceActionWatcher,
  getAccountsSuccessActionWatcher,
  getPreferencesSuccessActionWatcher,
  initAccountPreferencesActionWatcher,
  resetAllPreferencesActionWatcher,
  upsertAccountsSuccessActionWatcher,
];
