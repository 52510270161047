import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ButtonBase from '@mui/material/ButtonBase';
import { doHelpSupportModal as doHelpSupportModalAction } from 'components/Dialogs/HelpSupportDialog/actions';
import { useDispatch } from 'react-redux';

const HelpSupportButton = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();

  return (
    <ButtonBase
      className={classes.navMenuItem}
      component={React.forwardRef((propers, ref) => <NavLink {...propers} innerRef={ref} />)}
      to=""
      id="nav-menu-shared-components"
      onClick={() => dispatch(doHelpSupportModalAction())}
    >
      <div>
        <HelpOutlineIcon titleAccess="Help" />
      </div>
      <div style={{ marginLeft: 24, textAlign: 'left' }} className={classes.navText}>
        Help
      </div>
    </ButtonBase>
  );
};

HelpSupportButton.propTypes = {
  classes: PropTypes.object,
};

export default HelpSupportButton;
