import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import compose from 'utils/compose';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import queryString from 'query-string';

import { tracker } from '@quicken-com/react.utils.core';
import { authActions } from '@quicken-com/react.flux.auth';
import { accountsSelectors } from '@quicken-com/react.flux.accounts';
import { tagsActions } from '@quicken-com/react.flux.tags';
import { categoriesActions } from '@quicken-com/react.flux.categories';

import QCard from 'components/QCard';
import QDialogs from 'components/QDialogs';
import WFBudgetSetup from 'components/Dialogs/WFBudgetSetup';

import { getCategoryGroupListsAction } from 'data/categoryGroupLists/categoryGroupListsActions';
import { getCategoryGroupsAction } from 'data/categoryGroups/categoryGroupsActions';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';
import { scheduledTransactionsSelectors } from '@quicken-com/react.flux.scheduled-transactions';

import { doAccountDiscovery as doAccountDiscoveryAction } from 'components/Dialogs/AccountDiscovery/actions';
import { doWFBillsScout as doWFBillsScoutAction } from 'components/Dialogs/WfBillScout/actions';
import { getBudgets } from 'data/budgets/budgetsSelectors';
import { getTopTierEntitlement } from 'data/entitlements/entitlementsSelectors';
import GettingStartedModulePage from './GettingStartedModulePage';
import { staticConfig } from './config';

// import { getLogger } from 'utils/logger';
// const log = getLogger('containers/GettingStartedWebFirst');

const styles = () => ({
});

class GettingStartedWebFirst extends PureComponent { // eslint-disable-line react/prefer-stateless-function

  static propTypes = {
    // Accounts
    doAccountDiscovery: PropTypes.func,
    accountsById: PropTypes.object,
    scheduledTransactions: PropTypes.object,
    showScheduledTransactions: PropTypes.bool,
    history: PropTypes.object,
    location: PropTypes.object,
    doAuthLogout: PropTypes.func,
    tier: PropTypes.object,

    // Bills
    doWFBillsScout: PropTypes.func,

    dialogAlert: PropTypes.func,

    // Dispatchers
    getCategoriesAction: PropTypes.func,
    getCategoryGroupLists: PropTypes.func,
    getCategoryGroups: PropTypes.func,
    getTagsAction: PropTypes.func,

  };

  constructor(props) {
    super(props);

    this.state = {
      setupStep: 0,
      startedResourceAddNum: false,
      stepCompletedSuccessfully: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.getSetupState(window.location);
  }

  componentDidMount() {
    const { getCategoriesAction, getCategoryGroupLists, getCategoryGroups, getTagsAction } = this.props;
    setTimeout(() => {
      getCategoriesAction();
      getCategoryGroupLists();
      getCategoryGroups();
      getTagsAction();
    }, 1000);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getSetupState(nextProps.location);
    }
    if (this.state.startedResourceAddNum !== false) {  // exclude the number zero
      switch (this.state.setupStep) {
        case 0:
          if (nextProps.accountsById.size > this.state.startedResourceAddNum) {
            this.setState({ stepCompletedSuccessfully: true, startedResourceAddNum: false });
          }
          break;
        case 1: {
          const nextVerifiedSchTxns = nextProps.scheduledTransactions.size && nextProps.scheduledTransactions.filter((x) => x.isUserVerified);
          if (nextVerifiedSchTxns.size > this.state.startedResourceAddNum) {
            this.setState({ stepCompletedSuccessfully: true, startedResourceAddNum: false });
          }
        }
          break;

        default:
          break;
      }
    }
  }

  isDeluxeUser = () => {
    const { tier } = this.props;
    return tier && tier.id && ['US_QUICKEN_DELUXE', 'CA_QUICKEN_DELUXE'].includes(tier.id);
  };

  getConfig = () =>
    staticConfig(this.hasAccounts(), this.state.stepCompletedSuccessfully, this.props.showScheduledTransactions, this.isDeluxeUser());

  getSetupState = (location) => {
    const query = queryString.parse(location.search);
    if (query) {
      const navPageIndex = this.getConfig().findIndex((rec) => rec.page === query.page);
      if (navPageIndex >= 0) {
        this.setState({ setupStep: navPageIndex });
      }
    }
  };

  hasAccounts = () =>
    this.props.accountsById.size > 0;

  onButtonClick = (e, value) => {

    switch (value) {
      case 'addAccount':
        tracker.track(tracker.events.wizardAddAccount);
        this.setState({ startedResourceAddNum: this.props.accountsById.size });
        this.props.doAccountDiscovery();
        break;
      case 'setUpBills':
        tracker.track(tracker.events.wizardBillscout);
        this.setState({ startedResourceAddNum: this.props.scheduledTransactions.filter((x) => x.isUserVerified).size });
        this.props.doWFBillsScout();
        break;
      case 'createBudget':
        tracker.track(tracker.events.wizardAddBudget);
        this.setState({ startedResourceAddNum: 'budgetPending' });
        break;

      default:
        break;
    }
  };

  closeToDashboard = (noSkip) => {
    if (noSkip) {
      this.props.dialogAlert(
        '',
        'Do you want to sign out?',
        (b) => {
          if (b.btnPressed === 'Yes') {
            tracker.track(tracker.events.wizardWFClose);
            this.props.doAuthLogout();
          }
        },
        ['Yes', 'No']
      );
    } else {
      this.props.history.push('/');
    }
  };

  advanceFlow = () => {
    if (this.state.setupStep < (this.getConfig().length - 1)) {
      this.setState({ stepCompletedSuccessfully: false, setupStep: this.state.setupStep + 1 });
    } else {
      // Skip the budgets section
      if (!this.state.stepCompletedSuccessfully) {
        tracker.track(tracker.events.skipBudgetFlow);
      }
      this.closeToDashboard();
    }
  };
  retractFlow = () => {
    if (this.state.setupStep > 0) {
      this.setState({ stepCompletedSuccessfully: false, setupStep: this.state.setupStep - 1 });
    }
  };

  render() {

    const { accountsById } = this.props;
    const { setupStep, stepCompletedSuccessfully, startedResourceAddNum } = this.state;

    const stepVal = accountsById.size > 0 ? setupStep : 0;
    const configRecord = this.getConfig()[stepVal];
    const skipText = (setupStep < (this.getConfig().length - 1)) ? 'Next' : 'Done';
    const renderNext = !configRecord.noSkip; //  && (this.state.setupStep < (this.getConfig().length - 1));
    const successQCard = stepCompletedSuccessfully && configRecord.successQCard;

    return (
      <>
        {successQCard &&
          <QCard
            name="setup"
            cards={successQCard}
            open
            initialCard="firstCard"
          />}
        <GettingStartedModulePage
          onCompleted={renderNext ? this.advanceFlow : null}
          config={configRecord}
          onClose={() => this.closeToDashboard(configRecord.noSkip)}
          skipText={skipText}
          onButtonClick={this.onButtonClick}
          showSuccess={stepCompletedSuccessfully}
        />
        {startedResourceAddNum === 'budgetPending' &&
          <WFBudgetSetup
            onClose={() => this.setState({ stepCompletedSuccessfully: true, startedResourceAddNum: false })}
          />}

      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    accountsById: accountsSelectors.getAccountsById(state),
    scheduledTransactions: scheduledTransactionsSelectors.getScheduledTransactions(state),
    showScheduledTransactions: featureFlagsSelectors.getFeatureFlags(state).get('scheduledTransactions'),
    budgetsById: getBudgets(state),
    tier: getTopTierEntitlement(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    doAccountDiscovery: () => dispatch(doAccountDiscoveryAction({ initiator: 'getting started' })),
    doWFBillsScout: () => dispatch(doWFBillsScoutAction()),
    doAuthLogout: () => dispatch(authActions.authLogout({ reason: 'AUTH_LOGOUT_USER_INITIATED' }, { context: 'GettingStartedWebFirst' })),
    getCategoriesAction: () => dispatch(categoriesActions.getCategories()),
    getCategoryGroupLists: () => dispatch(getCategoryGroupListsAction()),
    getCategoryGroups: () => dispatch(getCategoryGroupsAction()),
    getTagsAction: () => dispatch(tagsActions.getTags()),
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  QDialogs(),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(GettingStartedWebFirst));
