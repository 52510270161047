import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useTimeout } from 'react-use';
import { formatAmount } from 'components/QCurrency/core';
import { filterCalendarTxnBasedOnPreferences } from 'data/transactions/selectors';
import TransactionLabel from '../TransactionLabel';
import { calDayStyles } from '../../styles';

const useStyles = makeStyles(calDayStyles);

const maxTxnsDefault = 3;
const hoverDelay = 500;

const CalDay = (props) => {
  const classes = useStyles();
  const dayRef = useRef();

  const { day, currentMonth, currentDay, txnList, setPopper, date, balances, onTxnsModalOpen } = props;

  const filteredTxnList = useSelector((state) => filterCalendarTxnBasedOnPreferences(state, { txnList }));
  const [isReady, cancelTimeout, resetTimeout] = useTimeout(hoverDelay);
  const [hoveringContent, setHoveringContent] = useState('');

  const showDailyBalances = Boolean(balances);
  const maxTxns = showDailyBalances && showMoreLabel ? maxTxnsDefault - 1 : maxTxnsDefault;
  const isReadyFlag = isReady();

  useEffect(() => {
    const isHovering = Boolean(hoveringContent);
    if (!isHovering) {
      cancelTimeout();
    }

    if (isHovering && isReady(isReadyFlag) === null) {
      resetTimeout();
    }

    if (isHovering) {
      if (isReady(isReadyFlag) && hoveringContent === 'full') {
        setPopper(dayRef.current, { hoveringContent, content: popoverContents() });
      }
    }
  }, [isReadyFlag, isReady, hoveringContent, cancelTimeout, resetTimeout, popoverContents, setPopper]);

  const { positiveBalance, negativeBalance } = classes;
  const getBalanceClass = useCallback((amount) => (amount >= 0.0 ? positiveBalance : negativeBalance), [positiveBalance, negativeBalance]);

  const popoverContents = useCallback(() => ({
    header: <header className={classes.popperHeader}>{date.toLocaleString(DateTime.DATE_HUGE)}</header>,
    transactions: renderTxnPopper(),
    balance: renderBalancePopper(),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [classes, date, renderTxnPopper, renderBalancePopper, filteredTxnList]);

  const renderTxnPopper = useCallback(() => (
    <div className={classes.popperTxnList}>
      {filteredTxnList.size > 0 ? (
        <>
          {renderedFilteredTxnList}
          <Divider className={classes.balanceDivider} />
          <div className={classes.balanceLabelRoot}>
            <strong>Total</strong>
            <strong>{formatAmount(txnsTotal)}</strong>
          </div>
        </>
      ) : 'No transactions'}
    </div>
  ), [classes, filteredTxnList, txnsTotal, renderedFilteredTxnList]);

  const renderBalancePopper = useCallback(() => {
    const totalClass = getBalanceClass(balances?.total);

    return (
      <div className={classes.popperBalanceList}>
        {renderedBalanceList && renderedBalanceList}
        <Divider className={classes.balanceDivider} />
        <div className={classes.balanceLabelRoot}>
          <strong>Balance</strong>
          <strong className={totalClass}>{formatAmount(balances?.total)}</strong>
        </div>
      </div>
    );
  }, [balances, classes, getBalanceClass, renderedBalanceList]);

  const onMouseEnter = useCallback(() => {
    setHoveringContent('full');
  }, [setHoveringContent]);

  const onMouseLeaveParent = useCallback(() => {
    if (hoveringContent) {
      setHoveringContent('');
    }
  }, [hoveringContent, setHoveringContent]);

  const cachedOnTxnsModalOpen = useCallback(() => onTxnsModalOpen && onTxnsModalOpen(date, filteredTxnList, formatAmount(txnsTotal)), [onTxnsModalOpen, date, txnsTotal, filteredTxnList]);
  const showMoreLabel = useMemo(() => filteredTxnList?.size > maxTxns, [filteredTxnList, maxTxns]);
  const smallTxnList = useMemo(() => showMoreLabel ? filteredTxnList.slice(0, maxTxns) : filteredTxnList, [showMoreLabel, filteredTxnList, maxTxns]);
  const dailyBalancesClassNames = classNames(classes.dailyBalanceLabel,
    { [classes.dailyBalanceLabelFloating]: showMoreLabel }, { [classes.negativeBalance]: Math.sign(balances?.total) < 0 });

  const renderedSmallTxnList = useMemo(() => smallTxnList &&
    smallTxnList.map((txn) => <TransactionLabel key={txn.id} txn={txn} />), [smallTxnList]);

  const renderedFilteredTxnList = useMemo(() =>
    filteredTxnList && filteredTxnList.map((txn) => <TransactionLabel key={txn.id} txn={txn} />), [filteredTxnList]);
  const txnsTotal = useMemo(() =>
    filteredTxnList && filteredTxnList.reduce((acc, txn) => parseFloat(acc) + parseFloat(txn.amount ?? 0), 0), [filteredTxnList]);


  const renderedBalanceList = useMemo(() => balances && [...balances.accountsBalances.keys()].map((key) => {
    const { balance } = balances.accountsBalances.get(key);
    const balanceClass = getBalanceClass(balance);
    return (
      <div key={key} className={classes.balanceLabelRoot}>
        <span className={classes.balanceAccountLabel}>{balances.accountsBalances.get(key).name}</span>
        <span className={classNames(balanceClass, classes.balanceAmountLabel)}>{formatAmount(balance)}</span>
      </div>
    );
  }), [balances, getBalanceClass, classes]);


  return (
    <Grid
      ref={dayRef}
      className={classNames(classes.day, { [classes.notCurrentMonth]: !currentMonth })}
      onClick={cachedOnTxnsModalOpen}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeaveParent}
    >
      <header className={classes.header}>
        <span className={classNames(classes.dayIndicator, { [classes.currentDay]: currentDay })}>{day}</span>
      </header>
      {smallTxnList && (
        <div className={classes.txnWrapper}>
          {renderedSmallTxnList && renderedSmallTxnList}
          {showMoreLabel && (
            <TransactionLabel key="more-label" showMoreLabel={showMoreLabel} extraTxns={filteredTxnList.size - maxTxnsDefault} />
          )}
          {showDailyBalances && (
            <div
              id={`balance-label-${date.toISODate()}`}
              data-hover="balance-label"
              className={dailyBalancesClassNames}
            >{formatAmount(balances.total)}
            </div>
          )}
        </div>
      )}
    </Grid>
  );
};

CalDay.propTypes = {
  day: PropTypes.number,
  date: PropTypes.object,
  currentMonth: PropTypes.bool,
  currentDay: PropTypes.bool,
  txnList: PropTypes.object,
  setPopper: PropTypes.func,
  balances: PropTypes.object,
  onTxnsModalOpen: PropTypes.func,
};

CalDay.whyDidYouRender = true;

export default React.memo(CalDay);
