import React, { useState } from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { makeStyles, useTheme } from '@mui/styles';
import Typography from '@mui/material/Typography';

import AmountField, { formatNumber } from 'components/QuickenControls/AmountField';
import QButton from 'components/QButton';

import styles from './styles';

const useStyles = makeStyles(styles);

const RolloverBalanceCalculationView = ({ 
  displayLabel = '', 
  objMonthlyAmounts = {}, 
  budgetItem, 
  arrMonthlyAmounts, 
  setArrMonthlyAmounts, 
  hidePopperAndContents, 
  handleRolloverAmountSave,
  rolloverAmountForDialog,
  rolloverTableData,
  editedRolloverAmount,
  setEditedRolloverAmount,
  amountFormat,
  colorBalance,
}) => {
  const classes = useStyles();
  const { components } = useTheme();
  
  const [showRolloverEdit, setShowRolloverEdit] = useState(false);

  const calculateBalance = ({ budgetAmount, actualAmount }, rolloverAmountPassed, negate) => {
    if (negate > 0) { // Income category
      return rolloverAmountPassed - budgetAmount + actualAmount;
    } 
    return rolloverAmountPassed + budgetAmount - actualAmount;
  };

  // eslint-disable-next-line no-unused-vars
  const handleEditResetClick = () => {
    if (showRolloverEdit) {
      setEditedRolloverAmount(rolloverAmountForDialog);
    }
    setShowRolloverEdit(!showRolloverEdit);
  };

  const selectedDate = DateTime.fromISO(objMonthlyAmounts?.startDate);
  const calculatedBalance = calculateBalance(objMonthlyAmounts, editedRolloverAmount, budgetItem?.negate);
  
  return (
    <div className={classes.viewRolloverWrapper}>
      <div className={classes.rolloverMainHead}>
        <Typography variant="subtitle1" color="inherit">
          {displayLabel}
        </Typography>
        <div>
          <IconButton
            className={classes.closeButton}
            aria-label="Close"
            id="rollover-dialog-close"
            onClick={hidePopperAndContents}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className={classes.rolloverDate}>
        <Typography variant="subtitle2" color="inherit">
          {selectedDate.toFormat('MMMM')} {selectedDate.year}
        </Typography>
      </div>
      <table className={classes.rolloverTable}>
        {rolloverTableData.map((rolloverTableCell) => (
          <tr key={rolloverTableCell.key}>
            <td className={classes.rollOverOperation}>{rolloverTableCell.operation}</td>
            <td className={classes.rollOverName}>
              {rolloverTableCell.key}
              <div className={classes.rolloverDatedon}>
                {rolloverTableCell.datedOn}
              </div>
            </td>
            <td className={classes.rollOverAmounts}>
              {
                showRolloverEdit && rolloverTableCell.key === 'Rollover' &&
                <AmountField 
                  className={classes.input}
                  value={formatNumber(editedRolloverAmount, false, amountFormat)}
                  editable
                  hideCurrencyString
                  disableUnderline={components.register.noUnderline}
                  onChange={(e) => setEditedRolloverAmount(Number(e.target.value) || 0)}
                />
              }
              {
                (!showRolloverEdit || rolloverTableCell.key !== 'Rollover') && rolloverTableCell.key !== 'Balance' && 
                formatNumber(rolloverTableCell.value, false, amountFormat)
              }
              {
                rolloverTableCell.key === 'Balance' && 
                <span style={{ color: calculatedBalance < 0 ? colorBalance : undefined }}>
                  {formatNumber(calculatedBalance, false, amountFormat)}
                </span>
              }
            </td>
            <td className={classes.rollOverEdit}>
              {/* TODO disabling edit until Qwin supports rolloverOverrideAmount sync */ }
              {/* {rolloverTableCell.edit ? <span role="button" tabIndex="0" aria-label="edit/reset" onClick={handleEditResetClick} onKeyDown={handleEditResetClick}> { showRolloverEdit ? 'Reset' : 'Edit' } </span> : ''} */}
            </td>
          </tr>
        ))}
      </table>
      <div className={classes.buttonWrapper}>
        <QButton
          variant="text"
          onClick={() => handleRolloverAmountSave(budgetItem, objMonthlyAmounts, arrMonthlyAmounts, setArrMonthlyAmounts, calculatedBalance)}
          color="primary"
          disabled={rolloverAmountForDialog === editedRolloverAmount}
        >
          SAVE
        </QButton>
        <QButton
          variant="text"
          color="primary"
          onClick={hidePopperAndContents}
        >
          CANCEL
        </QButton>
      </div>
    </div>
  );
};

RolloverBalanceCalculationView.propTypes = {
  displayLabel: PropTypes.string,
  objMonthlyAmounts: PropTypes.object, 
  budgetItem: PropTypes.object, 
  arrMonthlyAmounts: PropTypes.array, 
  setArrMonthlyAmounts: PropTypes.func, 
  hidePopperAndContents: PropTypes.func, 
  handleRolloverAmountSave: PropTypes.func,
  rolloverAmountForDialog: PropTypes.number,
  rolloverTableData: PropTypes.array,
  editedRolloverAmount: PropTypes.number,
  setEditedRolloverAmount: PropTypes.func,
  amountFormat: PropTypes.string,
  colorBalance: PropTypes.string,
};

export default RolloverBalanceCalculationView;
