import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { transactionsUtils } from '@quicken-com/react.flux.transactions';

import makeStyles from '@mui/styles/makeStyles';
import ButtonBase from '@mui/material/ButtonBase';
import MenuButton from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/EditRounded';
import VisibilityOnIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffRounded';
import RepeatIcon from '@mui/icons-material/RepeatRounded';

// DATA
import { useDispatch, useSelector } from 'react-redux';
import useQData from 'components/QData/useQData';
import { isIncomeTxn } from 'data/transactions/selectors';
import { showTxnDetailsDialog } from 'components/Transactions/DetailsDialog/actions';

const useStyles = makeStyles((theme) => ({
  grey: {
    color: theme.palette.greyScaleDeprecated[2],
  },
  light: {
    height: 32,
    width: 32,
    padding: 4,
    opacity: 0.5,
    '&:hover': {
      opacity: 0.9,
      color: theme.palette.greyScaleDeprecated[2],
    },
  },
  menu: {
    marginLeft: 8,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: theme.palette.greyScaleDeprecated[5],
    },
  },
  menuItem: {
    display: 'flex',
    color: theme.palette.text.primary,
    alignItems: 'center',
    padding: theme.shape.padding[2],
  },
  iconContainer: {
    marginRight: theme.shape.padding[2],
    color: theme.palette.greyScaleDeprecated[1],
    minWidth: 30,
  },
}));

const stopProp = (e) => e.stopPropagation();

const wrapperId = 'TRANSACTION_MENU';

const TransactionMenu = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { className, style, txn, ...otherProps } = props;

  const { qDataSaveTransactions } = useQData(wrapperId);
  const isTransfer = transactionsUtils.isUITransferTxn(txn);
  const isIncome = useSelector((state) => isIncomeTxn(state, txn));

  const [menuAnchor, setMenuAnchor] = useState(null);

  const buttonClick = (e) => {
    e.stopPropagation();
    setMenuAnchor(e.target);
  };

  const closeMenu = useCallback(() => setMenuAnchor(null), []);

  const startCashflowTransactionEdit = () => {
    dispatch(showTxnDetailsDialog({ txn }));
    setMenuAnchor(null);
  };

  const doRecurring = () => {
    dispatch(showTxnDetailsDialog({ txn, onlyRecurring: true }));
    setMenuAnchor(null);
  };

  const doExcludeReports = () => {
    qDataSaveTransactions([txn.set('isExcludedFromReports', !txn.isExcludedFromReports)]);
    closeMenu();
  };
  const doExcludeF2S = () => {
    qDataSaveTransactions([txn.set('isExcludedFromF2S', !txn.isExcludedFromF2S)]);
    closeMenu();
  };

  const menuClasses = useMemo(() => ({ root: classes.menuItem }), [classes]);
  const listClasses = useMemo(() => ({ root: classes.iconContainer }), [classes]);

  // render return
  return (
    <div role="presentation" onClick={stopProp} onKeyDown={stopProp} style={style} {...otherProps}>
      <ButtonBase
        className={classNames(classes.menu, className)}
        onClick={buttonClick}
        focusRipple
      >
        <MenuButton className={classes.light} />
      </ButtonBase>

      {menuAnchor && txn.type === 'CASH_FLOW' &&
      <Menu
        id="account-settings-menu"
        anchorEl={menuAnchor}
        keepMounted
        open
        onClose={closeMenu}
        onClick={stopProp}
      >
        <MenuItem
          id="txn-menu-edit"
          onClick={startCashflowTransactionEdit}
          classes={menuClasses}
        >
          <ListItemIcon classes={listClasses}>
            <EditIcon />
          </ListItemIcon>
          Edit transaction
        </MenuItem>

        <MenuItem
          id="txn-menu-spending-plan"
          onClick={doExcludeF2S}
          classes={menuClasses}
        >
          <ListItemIcon classes={listClasses}>
            {txn.isExcludedFromF2S ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
          </ListItemIcon>
          {txn.isExcludedFromF2S ? 'Include in Spending Plan' : 'Ignore from Spending Plan'}
        </MenuItem>

        <MenuItem
          id="txn-menu-reports"
          onClick={doExcludeReports}
          classes={menuClasses}
        >
          <ListItemIcon classes={listClasses}>
            {txn.isExcludedFromReports ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
          </ListItemIcon>
          {txn.isExcludedFromReports ? 'Include in reports' : 'Ignore from reports'}
        </MenuItem>

        {/* TODO: reviewed logic and incorporation */}
        {/*
        <MenuItem
          id="txn-menu-review"
          // onClick={}
          classes={menuClasses}
        >
          <ListItemIcon classes={listClasses} >
            <CheckCircleIcon />
          </ListItemIcon>
          {true ? 'Mark' : 'Unmark'} as reviewed
        </MenuItem>
        */}

        {/* TODO: flag logic and incorporation */}
        {/*
        <MenuItem
          id="txn-menu-flag"
          // onClick={}
          classes={menuClasses}
        >
          <ListItemIcon classes={listClasses} >
            <FlagIcon />
          </ListItemIcon>
          Follow-up flag
        </MenuItem>
        */}

        <Divider key="divider" />

        <MenuItem
          id="txn-menu-recurring"
          onClick={doRecurring}
          classes={menuClasses}
          disabled={Boolean(txn.stModelId || txn.isBill)}
        >
          <ListItemIcon classes={listClasses}>
            <RepeatIcon />
          </ListItemIcon>
          {(isTransfer || isIncome) ? 'Mark as recurring' : 'Mark as bill or recurring'}
        </MenuItem>
      </Menu>}
    </div>
  );
};

TransactionMenu.propTypes = {
  txn: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.string,
};
TransactionMenu.defaultProps = {
};

export default TransactionMenu;
