// @flow
import { createSelector } from 'reselect';
import { OrderedMap } from 'immutable';

import { undoSelectors } from '@quicken-com/react.flux.undo';

import { NOTIFICATIONS_REDUCER_KEY } from './notificationsReducer';
import { notificationFromUndo } from './notificationsUtils';

const getStore = (state: Object) => state[NOTIFICATIONS_REDUCER_KEY];

export const getNotifications = createSelector(
  (state) => getStore(state).notifications,
  undoSelectors.getUndoStack,
  (notifications, undoStack) => {
    const undoNotifications = OrderedMap(undoStack.map((undo) => [undo.key, notificationFromUndo(undo)]));
    return notifications
      .merge(undoNotifications)
      ?.sortBy((notification) => notification.createdAt)
      .toIndexedSeq()
      .groupBy((notification) => `${notification.anchorOrigin.vertical}:${notification.anchorOrigin.horizontal}`)
      .flatMap((notificationsGroup) => (
        OrderedMap(notificationsGroup.map((notification, anchorIndex) => [notification.id, notification.merge({ anchorIndex })]))
      ));
  }
);

export const getNotificationById = (state: Object, id: string) => getNotifications(state).get(id);
