import React from 'react';
import compose from 'utils/compose';
import classNames from 'classnames';

import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';

import Modal from '@mui/material/Modal';
import Popper from '@mui/material/Popper/index';
import IconButton from '@mui/material/IconButton/index';
import Divider from '@mui/material/Divider/index';
import ListItem from '@mui/material/ListItem/index';
import ArrowIcon from '@mui/icons-material/KeyboardArrowRight';

import QTypography from 'components/MUIWrappers/QTypography';
import ListItemIcon from '@mui/material/ListItemIcon';

/*
*   - 'options' for the menu should be passed down as an array of objects, like the following
*   {
*     label: <string>,            // this is what's displayed in the menu
*     value: <any>,               // this is what's returned when an option is selected
*
*     ***-OPTIONAL PROPERTIES-***
*     subMenu: <array of options> // will render a subMenu under this list item
*     id: <string>                // will give the menu option a specified id
*     customRender: <node>        // will be displayed inside the list item instead of regular QTypography wrapping
*     isSubheader: <bool>         // if true shows the option as a subheader and restricts selecting it
*     onSelect: <func>            // overrides regular onSelect functionality (which regularly returns 'value')
*     divider: <bool>             // adds a list divider after this option
*     nop: <bool>                 // nop === no-operation, this option will not display in the menu
*     backgroundColor: <string>   // overrides list item bg color, separate from 'style' to ensure hover colors display properly
*     style: <object>             // overrides styling on the list item, to override styling on the text use 'customRender'
*                                     note: do NOT add backgroundColor in here, it will mess up the hover effects
*     startIcon:                  // icon that goes to left of option text
*     subtext:                    // text displayed under the main option
*   }
*
*   - currently doesn't support groupLists as options
*   - keyDown events are caught by the menu for navigation
*
*/


const styles = (theme) => ({
  divider: {
    margin: `${theme.shape.padding[1]}px 0px`,
  },
  item: {
    cursor: 'pointer',
    justifyContent: 'space-between',
    borderRadius: theme.shape.borderRadius * 1,
    '&:hover': {
      backgroundColor: theme.palette.grey.level1,
    },
  },
  flexRow: {
    display: 'flex',
  },
  popper: {
    zIndex: 1500, // just needs to be >1300 to display on top of Modal
    backgroundColor: theme.palette.greyScaleDeprecated[7],
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: '0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 5px 5px -3px rgba(0, 0, 0, 0.2)',
    maxHeight: '95%',
  },
  primaryMenu: {
    maxHeight: '100%',
    overflowY: 'auto',
    padding: theme.shape.padding[2],
  },
  root: {
  },
  rootPopper: {
    minWidth: 280,
    maxWidth: '50%',
  },
  secondaryMenu: {
    maxHeight: 'calc(95vh)',
    overflowY: 'auto',
    padding: theme.shape.padding[2],
  },
  subheader: {
    color: `${theme.palette.text.secondary}`,
    textAlign: 'left',
    padding: theme.shape.padding[2],
  },
  startIcon: {
    minWidth: 30,
    height: 'auto',
    marginRight: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  rightArrowStyles: {
    padding: 0,
    width: 20,
    height: 20,
  },
  rightArrowButton: {
    padding: 0,
    width: 30,
    height: 30,
  },
});

class NestedQMenu extends React.PureComponent {

  constructor(props) {
    super(props);
    this.rootRef = React.createRef();
    this.scrollRefInternal = [React.createRef()];
    this.trackedOptions = [];
    this.placement = null;
    this.state = {
      // the lists match up indecies to the depth of the menu
      anchors: [],
      path: [],
      subNodes: [],
      lastScrollTop: 0,
      headerHeight: 0,
    };
    this.composeItems(props.options, 0);
  }

  componentDidMount = () => {
    // document.addEventListener('keydown', this.handleKeyPress); // moved to componentDidUpdate
    this.initMenu('first');
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.options !== this.props.options) {
      this.initMenu('first');
    }
    if (!prevProps.open && this.props.open) {
      document.addEventListener('keydown', this.handleKeyPress, { capture: true });
    } else if (prevProps.open && !this.props.open) {
      document.removeEventListener('keydown', this.handleKeyPress, { capture: true });
      this.clearAllTracking();
    }
  };

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.handleKeyPress, { capture: true });
    this.closeMenus();
  };

  scrollRef = () => this.scrollRefInternal[this.state.path.length];

  setScrollRef = (sr) => {
    const depth = this.state.path.length;
    this.scrollRefInternal[depth] = sr;
  };

  handlePrimaryMenuScroll = (_event) => {
    if (this.state.path.length > 0 && Math.abs(this.state.lastScrollTop - (this.scrollRef()?.scrollTop || 0)) > 50) {
      this.clearAllTracking();
      this.setState({ lastScrollTop: this.scrollRef()?.scrollTop || 0 });
    }
  };

  handleOptionHover = (event, option) => {
    let { anchors, path, subNodes } = this.state;
    this.setState({ lastScrollTop: this.scrollRef()?.scrollTop || 0 });
    const { menuDepth } = option;
    if (menuDepth <= path.length) { // we need to backtrack
      path = path.slice(0, menuDepth).concat([option]);  // always make the path end at the current hovered on
      anchors = anchors.slice(0, menuDepth);
      subNodes = subNodes.slice(0, menuDepth);
      if (option.subMenu) {
        anchors = anchors.concat([event.currentTarget]);
        subNodes = subNodes.concat([option.subMenu]);
      }
    } else if (option.subMenu) {  // we need to add a new level
      path = path.concat([option]);
      anchors = option.subMenu ? anchors.concat([event.currentTarget]) : anchors;
      subNodes = option.subMenu ? subNodes.concat([option.subMenu]) : subNodes;
    }
    this.setState({ path, anchors, subNodes });
  };

  handleOptionClick = (event, option) => {
    if (this.props.onChange && option.value) {
      this.props.onChange(option.value);
    }
    if (this.props.currentHover) {
      this.props.currentHover(null);
    }
  };

  // sends a 'fake' event to key press because logic is already there
  handleArrowClick = (event) => {
    this.handleKeyPress({
      key: 'ArrowRight',
      stopPropagation: () => 0,
      preventDefault: () => 0,
    });
    event.stopPropagation();
  };

  handleKeyPress = (event) => {
    if (this.props.options.length === 0 || !this.props.open) {
      return;
    }
    // ################################## -- Enter -- ################################## //
    if (event.key === 'Enter') {
      const currentBoi = this.state.path[this.state.path.length - 1];
      if (!currentBoi) {
        return;
      }
      event.stopPropagation();
      event.preventDefault();
      if (currentBoi.onSelect) {
        currentBoi.onSelect();
      } else {
        this.handleOptionClick(event, this.state.path[this.state.path.length - 1]);
      }
    } else if (event.key === 'Tab' || event.key === 'Escape') {
    // ################################## -- Tab -- ################################## //
      this.closeMenus();
    } else if (['ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft'].includes(event.key)) {
      if (this.state.path.length === 0) {
        if (event.key === 'ArrowDown') {
          this.initMenu('first');
        } else if (event.key === 'ArrowUp') {
          if (this.scrollRef()) {
            this.scrollRef().scrollTop = this.scrollRef().scrollHeight;
          }
          this.initMenu('last');
        }
        event.stopPropagation();
        event.preventDefault();
        return;
      }
      if ((event.key === 'ArrowRight' && this.state.path[this.state.path.length - 1].subMenu)
        || (event.key === 'ArrowLeft' && this.state.path.length > 1)) { // if it's moving the menu don't move the cursor
      }
      let { path, anchors, subNodes } = this.state;
      const currentOption = path[path.length - 1];
      // ################################## -- ArrowRight -- ################################## //
      if (event.key === 'ArrowRight' && path[path.length - 1].subMenu) {
        const trackedOptions = this.trackedOptions[this.getActiveDepth() + 1]?.[0];
        if (trackedOptions) {
          path = path.concat([trackedOptions]); // add the first of the subMenu to the path
        }
        if (path && path[path.length - 1].subMenu) {  // if moving right leads to another subMenu
          const { menuIndex, menuDepth, label } = path[path.length - 1];
          anchors = anchors.concat([document.getElementById(`${menuIndex}${label}${menuDepth}`)]);
          subNodes = subNodes.concat([path[path.length - 1].subMenu]);
        }
      } else if (event.key === 'ArrowDown') {
        // ################################## -- ArrowDown -- ################################## //
        const { menuIndex, menuDepth, label } = this.state.path[this.state.path.length - 1];
        const child = document.getElementById(`${menuIndex}${label}${menuDepth}`);
        const scroller = this.scrollRef();
        if (scroller && child && child.offsetTop + (2 * child.offsetHeight) >= scroller.offsetHeight + scroller.scrollTop) {
          scroller.scrollTop += child.offsetHeight;
        }
        this.handlePrimaryMenuScroll();
        const nextOption = this.getNextOption(path[path.length - 1]);
        path = path.slice(0, path.length - 1).concat([nextOption]);
        if (currentOption.subMenu) {
          anchors = anchors.slice(0, anchors.length - 1);
          subNodes = subNodes.slice(0, subNodes.length - 1);
        }
        if (nextOption.subMenu) {
          const nextId = `${nextOption.menuIndex}${nextOption.label}${nextOption.menuDepth}`;
          anchors = anchors.concat([document.getElementById(nextId)]);
          subNodes = subNodes.concat([nextOption.subMenu]);
        }
        event.preventDefault();
        event.stopPropagation();
      } else if (event.key === 'ArrowUp') {
        // ################################## -- ArrowUp -- ################################## //
        const { menuDepth, menuIndex, label } = this.state.path[this.state.path.length - 1];
        const child = document.getElementById(`${menuIndex}${label}${menuDepth}`);
        if (this.scrollRef() && child && child.offsetTop - child.offsetHeight <= this.scrollRef().scrollTop) {
          this.scrollRef().scrollTop -= child.offsetHeight;
        }
        this.handlePrimaryMenuScroll();
        const prevOption = this.getPrevOption(path[path.length - 1]);
        path = path.slice(0, path.length - 1).concat([prevOption]);

        if (currentOption.subMenu) {
          anchors = anchors.slice(0, anchors.length - 1);
          subNodes = subNodes.slice(0, subNodes.length - 1);
        }
        if (prevOption && prevOption.subMenu) {
          const prevId = `${prevOption.menuIndex}${prevOption.label}${prevOption.menuDepth}`;
          anchors = anchors.concat([document.getElementById(prevId)]);
          subNodes = subNodes.concat([prevOption.subMenu]);
        }
        event.preventDefault();
        event.stopPropagation();
      } else if (event.key === 'ArrowLeft') {
        // ################################## -- ArrowLeft -- ################################## //
        path = path.length > 1 ? path.slice(0, path.length - 1) : path;
        if (path.length < anchors.length) {
          anchors = anchors.slice(0, anchors.length - 1);
          subNodes = subNodes.slice(0, subNodes.length - 1);
        }
      }
      this.setState({ path, anchors, subNodes });
    }
  };

  findOption = (options, option, path = []) => {
    let pathFound;

    options.forEach((item) => {
      if (item.value === option.value) {
        pathFound = [...path, item];
      } else if (item.subMenu && item.subMenu.length) {
        pathFound = this.findOption(item.subMenu, option, [...path, item]);
      }
    });

    return pathFound;
  };

  initMenu = (firstOrLast, offset = 0) => {
    if (!this.props.open) return;
    let path;
    if (this.props.selectedOption) {
      path = this.findOption(this.trackedOptions[0], this.props.selectedOption);
    } else if (firstOrLast === 'first') {
      const first = this.trackedOptions[0][0 + offset];
      if (first) {
        path = [first];
        setTimeout(() => {
          const scroll = this.scrollRef();
          if (scroll && scroll.scrollTop) {
            scroll.scrollTop = 0;
          }
        }, 0);
      }
    } else if (firstOrLast === 'last') {
      const last = this.trackedOptions[0][this.trackedOptions[0].length - 1 + offset];
      path = last ? [last] : undefined;
    }
    if (!path || !path.length) return;

    const option = path[path.length - 1];
    this.setState({
      path,
      subNodes: option && option.subMenu ? [option.subMenu] : [],
      anchors: option && option.subMenu ? [document.getElementById(`${option.menuIndex}${option.label}${option.menuDepth}`)] : [],
    });
  };

  getActiveDepth = () => this.state.path.length - 1;

  getNextOption = (currentOption) => {
    if (currentOption.menuIndex >= this.trackedOptions[currentOption.menuDepth].length - 1) {
      if (this.scrollRef()) {
        this.scrollRef().scrollTop = 0;
      }
      return this.trackedOptions[currentOption.menuDepth][0]; // wraps back around to the beginning
    }
    return this.trackedOptions[currentOption.menuDepth][currentOption.menuIndex + 1];
  };

  getPrevOption = (currentOption) => {
    if (currentOption.menuIndex <= 0) {
      if (this.scrollRef()) {
        this.scrollRef().scrollTop = this.scrollRef().scrollHeight;
      }
      return this.trackedOptions[currentOption.menuDepth][this.trackedOptions[currentOption.menuDepth].length - 1]; // wrap around to end
    }
    return this.trackedOptions[currentOption.menuDepth][currentOption.menuIndex - 1];
  };

  pathContainsOption = (path, option) => path.some((crumb) => crumb.label === option.label
    && option.menuDepth === crumb.menuDepth && crumb.menuIndex === option.menuIndex);

  composeItems = (options, depth = 0) => {
    if (!options) return [];
    if (this.trackedOptions.length < depth + 1) this.trackedOptions.push([]);
    else this.trackedOptions[depth] = [];
    const items = [];
    const { path } = this.state;
    options.forEach((option, index) => {
      if (!option) {
        return;
      }
      if (option.isSubheader) {
        items.push(
          <ListItem
            key={`subtitle-${option.label}`}
            style={{ backgroundColor: option.backgroundColor || '' }}
            className={this.props.classes.subheader}
          >
            {
              option.startIcon &&
                <ListItemIcon className={this.props.classes.startIcon}>
                  {option.startIcon}
                </ListItemIcon>
            }
            <QTypography variant="caption">
              {option.label}
            </QTypography>
          </ListItem>
        );
      } else if (option.isGroupList) {
        // TODO: add group lists into the menu
      } else if (!option.nop) {  // regular items and ones with submenus
        const trackedOption = { ...option, menuDepth: depth, menuIndex: this.trackedOptions[depth].length };
        this.trackedOptions[depth].push(trackedOption);
        items.push(
          <ListItem
            id={option.id || `${this.trackedOptions[depth].length - 1}${option.label}${depth}`}
            // eslint-disable-next-line react/no-array-index-key
            key={`qnested-menuitem-${index}${option.label}${depth}`}
            onMouseEnter={(event) => this.handleOptionHover(event, trackedOption)}
            onClick={option.onSelect ? option.onSelect : (event) => this.handleOptionClick(event, trackedOption, depth)}
            className={this.props.classes.item}
            style={{
              backgroundColor: !this.pathContainsOption(path, trackedOption) ? option.backgroundColor || '' : this.props.theme.palette.grey.level1,
              ...(option.style ? option.style : {}),
            }}
            // selected={this.pathContainsOption(path, trackedOption)}
          >
            {
              option.customRender ?
                option.customRender
                :
                <div className={this.props.classes.flexRow}>
                  {
                    option.startIcon &&
                      <ListItemIcon classes={{ root: this.props.classes.startIcon }}>
                        {option.startIcon}
                      </ListItemIcon>
                  }
                  <div>
                    <QTypography>
                      {option.label}
                    </QTypography>
                    {
                      option.subtext &&
                        <QTypography variant="caption">
                          {option.subtext}
                        </QTypography>
                    }
                  </div>
                </div>
            }
            {
              option.subMenu &&
                <IconButton
                  onClick={this.handleArrowClick}
                  className={this.props.classes.rightArrowButton}
                  size="large"
                >
                  <ArrowIcon className={this.props.classes.rightArrowStyles} />
                </IconButton>
            }
          </ListItem>
        );
      }
      if (option.divider) {
        items.push(
          <Divider
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}divider${depth}`}
            className={this.props.classes.divider}
          />
        );
      }
    });
    return items;
  };

  getAnchor = () =>
    this.props.anchorEl || null;

  closeMenus = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.clearAllTracking();
    if (this.props.currentHover) {
      this.props.currentHover(null);
    }
  };

  setHeaderRef = (ref) => {
    if (ref) {
      const headerHeight = ref.getBoundingClientRect().bottom - ref.getBoundingClientRect().top;
      this.setState({ headerHeight });
    }
  };

  clearAllTracking = () => this.setState({ path: [], anchors: [], subNodes: [] });

  render() {

    const { anchorEl, options, classes, open, header, width } = this.props;
    const { anchors, subNodes, headerHeight } = this.state;
    if (!anchorEl || !options) return <div />;

    const spaceBelow = window.innerHeight - anchorEl.getBoundingClientRect().top - 80;
    const spaceAbove = anchorEl.getBoundingClientRect().top - 80;
    const rootPlacement = spaceAbove > spaceBelow ? 'top' : 'bottom';
    this.placement = rootPlacement;
    const rootHeight = (spaceAbove > spaceBelow ? spaceAbove : spaceBelow) - headerHeight;  // 80 for spacing

    const menuItems = this.composeItems(options, 0);

    // callback to let parent know if the user is navigating through the menu
    if (this.props.currentHover) {
      if (this.state.path.length > 0 && open) {
        this.props.currentHover(this.state.path[this.state.path.length - 1]);
      } else {
        this.props.currentHover(null);
      }
    }

    const filteredAnchors = anchors.filter((x) => x);

    return (
      <Modal
        className={classes.root}
        open={open}
        onBackdropClick={this.closeMenus}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      >
        <>
          <Popper
            id="picker-boi"
            key="picker-boi"
            open
            anchorEl={this.getAnchor}
            placement={rootPlacement}
            className={menuItems.length > 0 ? classNames(classes.popper, classes.rootPopper) : ''}
            style={{
              // maxHeight: rootHeight,
              width: width || anchorEl.offsetWidth,
            }}
            modifiers={[{
              name: 'flip',
              options: {
                fallbackPlacements: ['right', 'left', 'bottom', 'top'],
              },
            }]}
          >
            <div
              key="headerDiv=="
              ref={this.setHeaderRef}
            >
              { header }
            </div>
            <div
              id="top-wrapper-qnested-menu"
              className={classes.primaryMenu}
              style={{ maxHeight: rootHeight - 40 }}
              ref={(node) => {
                if (this.state.path.length <= 1) {
                  this.setScrollRef(node);
                }
              }}
            >
              {menuItems.map((item) => item)}
            </div>
          </Popper>
          {open && filteredAnchors.length > 0 && filteredAnchors.map((anchor, index) => {
            const label = this.state.path[index]?.label;
            const popperId = `qnested-popper-menu-item-${label}`;
            const id = `qnested-menu-item-${label}`;
            return (
              <Popper
                id={popperId}
                key={popperId}
                open
                anchorEl={anchor}
                className={classes.popper}
                placement="right"
              >
                <div
                  id={id}
                  className={classes.secondaryMenu}
                  ref={(node) => {
                    if (this.state.path.length > 1 && index === anchors.length - 1) {
                      this.setScrollRef(node);
                    }
                  }}
                >
                  {this.composeItems(subNodes[index], index + 1).map((item) => item)}
                </div>
              </Popper>
            );
          })}
        </>
      </Modal>
    );
  }
}
NestedQMenu.propTypes = {
  onClose: PropTypes.func,
  anchorEl: PropTypes.object,
  classes: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.array,
  selectedOption: PropTypes.object,
  open: PropTypes.bool,
  header: PropTypes.object,
  theme: PropTypes.object,
  currentHover: PropTypes.func, // callback that passes the option currently being hovered, has some extra key-values, just ignore
  width: PropTypes.number,
};

export default compose(
  withStyles(styles, { withTheme: true })
)(NestedQMenu);
