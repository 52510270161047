import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  accDetails: {
    paddingLeft: 6,
    height: 19,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: theme.palette.greyScaleDeprecated[2],
  },
  cell: {
    margin: '0 1%',
    borderBottom: 'none',
    display: 'flex',

    '&:first-child': {
      marginLeft: '0px',
    },
    '&:last-child': {
      marginLeft: '0px',
    },
  },
}));
