import moment from 'moment';
import lodash from 'lodash';
import { memoizeWithObjectKey } from 'utils/memoizeWithKey';

import { transactionsUtils } from '@quicken-com/react.flux.transactions';

import { isUnacceptedScheduledTxn, getTxnStateInfo, isNextScheduledInstance } from 'data/transactions/utils';
import { filterByKeys, findInObject } from 'utils/utils';
import { isAcme } from 'isAcme';
import { getFieldString, filterTransactionsByExpression, filterTransactionsByFilterObject } from 'data/transactions/searchFilter';
import * as transactionsConfig from './transactionsConfig';

export function fieldIsMoveable(field) {

  const items = findInObject(transactionsConfig.fieldData, (x, k) => k === field);

  if (items.length > 0) {
    return items[0].moveable;
  }
  return false;
}
//
// props needs to contain
// Props must have:
// accountIds, editRunningBalance, regEditableFields, headerFields, editDownloaded
// showAccountColors, showSplitWindow, scheduledTransactionsById, txnCancelExtraColumn
//

export function fieldIsEditable(txn, fld, props) {

  const stateInfo = getTxnStateInfo(txn);
  const skipAmounts = (txn.cpData && !props.editDownloaded) || props.showSplitWindow;

  if (fld === 'state' && isAcme) {
    if (stateInfo.status !== 'PENDING' && stateInfo.entry !== 'scheduled') return false;
    // if (stateInfo.status !== 'scheduled') return false;
  }

  let fieldNotActive = !props.headerFields.includes(fld) ||
    !props.regEditableFields.includes(fld) ||
    (fld === 'account' && (props.accountIds.size <= 1 ||
      (stateInfo.entry === 'bank' || stateInfo.entry === 'matched'))) ||
    (fld === 'balance' && (!props.editRunningBalance || props.accountIds.size > 1)) ||
    (!props.txnCancelExtraColumn && fld === 'close') ||
    (props.showAccountColors && fld === 'account') ||
    (isUnacceptedScheduledTxn(txn) && transactionsConfig.recurringEditable.indexOf(fld) < 0) ||
    (fld === 'select' && stateInfo.status === 'UPCOMING' && !isNextScheduledInstance(txn, props.scheduledTransactionsById)) ||
    (!props.showAccountColors && fld === 'accountColor');

  fieldNotActive = fieldNotActive || (skipAmounts && (fld === 'amount' || fld === 'income' || fld === 'expense')) ||
    (transactionsUtils.isSplitTxn(txn) && (fld === 'tags' || fld === 'category')) || (props.showSplitWindow && fld === 'category');

  // Condition for account not synced case
  if ((fld === 'menu' || fld === 'state') && txn?.coa?.type === 'ACCOUNT' && props.allAccountIds && !props.allAccountIds.includes(txn.coa.id)) {
    fieldNotActive = true;
  } 

  return !fieldNotActive;
}

//
// Download the current view to a CSV file
//
export function downloadRegisterToCSV(props) {

  const { headers, data } = convertRegViewToArrays(props);

  const filename = `quicken_dsv_${moment().format('MM_DD_YYYY_h:mm:ss:a')}.csv`;
  if (headers && data) {
    const csvData = convertToFile(headers, data);

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(csvData, filename);
    } else {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(csvData);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}


function createCSVRow(regColumns, txn, longCats) {

  const row = [];
  regColumns.forEach((field) => {
    row.push(`"${getFieldString(field, txn, longCats)}"`);
  });
  return row;
}
function createCSVSplitRow(regColumns, txn, longCats) {

  const row = [];
  regColumns.forEach((field) => {
    if (field === 'category' || field === 'amount') {
      row.push(`"${getFieldString(field, txn, longCats)}"`);
    } else {
      row.push('');
    }
  });
  return row;
}

function convertRegViewToArrays(props) {

  const data = [];

  const headers = Object.keys(filterByKeys(transactionsConfig.fieldData, (x) => x.exportable));

  // Per Kelly J, if there are selected transactions, download those, otherwise
  // download all the current acctTxns

  if (props.uiState.selectedTxns && props.uiState.selectedTxns.size > 0) {
    processTxnList(props, headers, data, props.uiState.selectedTxns);
  } else {
    props.acctTxns.forEach((section) => {
      // const shortKey = key.slice(key.indexOf(':') + 1);
      // if (props.uiState.openSections.has(shortKey)) {
      processTxnList(props, headers, data, section);
      // }
    });
  }

  return { headers, data };
}

function processTxnList(props, headers, data, txnList) {
  txnList.forEach((txn) => {
    data.push(createCSVRow(headers, txn, props.longCats));
    if (transactionsUtils.isSplitTxn(txn)) {
      txn.split.items.forEach((item) => {
        data.push(createCSVSplitRow(headers, item, props.longCats));
      });
    }
  });
}

function convertToFile(headers, data) {

  // This special prepend is a flag to microsoft EXCEL to use UTF-8 in importing
  // which is required for currency symbols that are multi-byte
  //
  let str = '\uFEFF';

  headers.forEach((x) => { str = `${str},${x}`; });
  str = `${str}\n`;
  data.forEach((x) => {
    x.forEach((y) => {
      str = `${str},${y}`;
    });
    str = `${str}\n`;
  });

  return new Blob([str], { type: 'text/csv; charset=utf-8' });
}


export function txnListMetrics(acctTxns) {

  let numTxns = 0;
  let sumTxns = 0;
  let earliestDate = moment();
  let reviewedCount = 0;

  acctTxns.forEach((section, _) => {
    numTxns += section.size;
    section.forEach((txn) => {
      sumTxns += Number(txn.amount);
      if (moment(txn.postedOn).isBefore(earliestDate)) {
        earliestDate = moment(txn.postedOn);
      }
      reviewedCount += (txn.isReviewed ? 1 : 0);
    });
  });

  return { numTxns, sumTxns, earliestDate, reviewedCount };
}

/**
 * This is a filter factory that builds a filtering function from
 * an expression. Lodash memoizer ensures that for the same expression
 * string, the same function instance is always returned. This allows
 * the function to be passed to a reselector and not bust the cache.
 *
 * This could be considered a leak in the sense that a function
 * will be preserved for each filter expression a user executes, but practically
 * that will be bounded by the duration of the session. The backing map
 * implementation could probably be swapped out if this turns out to
 * be an issue.
 */
export const createFilterFromExpression = lodash.memoize((expression) => {
  if (expression) {
    return (txns) => filterTransactionsByExpression(txns, expression);
  }
  return null;
});

export const createFilterFromFilterObject = lodash.memoize((filterObject) => {
  if (filterObject) {
    return (txns) => filterTransactionsByFilterObject(txns, filterObject);
  }
  return null;
});

const filterFromObjectAndExpression = (txns, filterObject, filterExpression) => {

  let newTxns = txns;
  if (newTxns && filterObject) {
    newTxns = filterTransactionsByFilterObject(newTxns, filterObject);
  }
  if (newTxns && filterExpression && filterExpression.length > 0) {
    newTxns = filterTransactionsByExpression(newTxns, filterExpression);
  }
  return newTxns;
};

export const createFilterFromObjectAndExpression = (filterObject, filterExpression) => {

  const cacheObject = {
    filterObject,
    filterExpression,
  };

  return memoizeWithObjectKey(
    'registerFilters',
    () => (txns) => filterFromObjectAndExpression(txns, filterObject, filterExpression),
    cacheObject,
    20
  );

};
