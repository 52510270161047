import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'utils/compose';

import { getLogger } from '@quicken-com/react.utils.core';

import QDialogs from 'components/QDialogs';

import { helpMap } from './helpMap';

const log = getLogger('components/QHelp/index.js');

export default () => (WrappedComponent) => {

  class QHelp extends PureComponent {

    showQHelp = (id, props = {}) => {
      log.log('Showing help component ', props);

      const btnRecs = props.buttons || helpMap[id].btns;
      let buttons = helpMap[id].infoUrl ? ['Close', 'Read More'] : ['Close'];

      if (btnRecs) {
        buttons = btnRecs.map((x) => x.label);
      }
      const allProps = { ...helpMap[id].props, ...props };

      const HelpComponent = helpMap[id].component;
      if (HelpComponent) {
        const content = [<HelpComponent helpProps={allProps} key={`help-id-${id}`} />];

        this.props.dialogAlertCustom({
          title: helpMap[id].title || 'Quicken Help',
          content,
          onClose: (resp) => {
            if (btnRecs) {
              btnRecs.forEach((btn) => {
                if (btn.label === resp.btnPressed && btn.action) {
                  btn.action();
                }
              });

            } else if (resp.btnPressed === 'Read More') {
              window.open(helpMap[id].infoUrl, '_blank');
            }
          },
          buttons,
          paperStyle: { paddingBottom: 0, maxWidth: helpMap[id].maxWidth || 600 },
        });
      }
    };

    render() {

      const qHelpProps = {
        showQHelp: this.showQHelp,
      };

      // wrapped component with its props, the state from HOC and uiStateProps
      return (
        <WrappedComponent
          {...{
            ...this.props,
            ...qHelpProps,
          }}
        />
      );
    }
  }

  function mapStateToProps() {
    return {};
  }

  function mapDispatchToProps() {
    return {};
  }


  QHelp.propTypes = {
    createDialog: PropTypes.func,
    dialogAlertCustom: PropTypes.func,
  };

  return compose(
    QDialogs(),
    connect(mapStateToProps, mapDispatchToProps),
  )(QHelp);

};
