
// CORE
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

import { useDispatch, useSelector } from 'react-redux';

import { getLogger, tracker } from '@quicken-com/react.utils.core';

import { searchForBillers, getPopularBillersAction, getBiller, getBillerLogo } from 'data/billers/actions';
import { getPopularBillers } from 'data/billers/selectors';

// CUSTOM COMPONENTS
import { bindPromiseAction } from 'utils/actionHelpers';

const log = getLogger('BillPresentment/SearchPage');


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 472,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginBottom: 12,
  },
  searchWrapper: {
    position: 'absolute',
    width: '100%',
    paddingBottom: 12,
    zIndex: 2,
    top: 38,
  },
  dropDown: {
    maxHeight: 370,
    overflow: 'scroll',
    borderRadius: 8,
    zIndex: 9,
    background: theme.palette.greyScaleDeprecated[7],
    boxShadow: `0 5px 7px 1px ${theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.3)}`,
  },
  billerSpace: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 -12px',
    justifyContent: 'center',
  },
  billerButton: {
    width: 104,
    maxWidth: 104,
    height: 66,
    margin: 15,
    borderRadius: 8,
    border: `1px solid ${theme.palette.greyScaleDeprecated[7]}`,
    flexGrow: 1,
    flexDirection: 'column',
    backgroundColor: theme.palette.greyScaleDeprecated[5],
    '&:hover': {
      transition: 'all 0.2s',
      backgroundColor: theme.palette.color7.opacity30,
      border: `1px solid ${theme.palette.greyScaleDeprecated[4]}`,
      fontWeight: 500,
    },
  },
  greyIcon: {
    height: 32,
    width: 38,
    borderRadius: 4,
    backgroundColor: theme.palette.greyScaleDeprecated[5],
    marginBottom: 4,
  },
  chipText: {
    fontWeight: 400,
    width: 'calc(100% - 12px)',
    minWidth: 148,
  },
  grid: {
    position: 'absolute',
    width: '100%',
    top: 96,
    zIndex: 0,
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
  centered: {
    marginTop: 16,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

// extracted functions

function extractHostname(biller) {
  const url = biller.websiteURL || biller.loginURL || biller.createAccountURL || biller.resetPasswordURL;

  let hostname;
  // find & remove protocol (http, ftp, etc.) and get hostname
  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }
  // find & remove any port number
  hostname = hostname.split(':')[0];
  // find & remove any query "?"
  hostname = hostname.split('?')[0];
  // find & remove any beginning www.
  if (hostname.indexOf('www.') === 0) {
    hostname = hostname.slice(4);
  }
  return hostname;
}

function renderSuggestion(params) {
  const { suggestion, key, itemProps, highlightedIndex, selectedItem } = params;
  const isHighlighted = highlightedIndex === key;
  const isSelected = selectedItem && selectedItem.name === suggestion.name;

  const url = extractHostname(suggestion);

  return (
    <MenuItem
      {...itemProps}
      key={key}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      <ListItemText
        primary={suggestion.name}
        secondary={url}
      />
    </MenuItem>
  );
}

const keyHandle = (highlightedIndex, selectHighlightedItem) => (e) => {
  if (e.keyCode === 9 && highlightedIndex !== null) {
    e.preventDefault();
    selectHighlightedItem();
  }
};

// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
// ### - - -                  Main Component                     - - - ###
// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
function SearchPage(props) {

  const classes = useStyles();
  const dispatch = useDispatch();

  const { onSelect, initiator } = props;

  const popularBillers = useSelector(getPopularBillers);

  const [billers, setBillers] = useState();

  useEffect(() => {
    if (popularBillers) {
      popularBillers.forEach((popularBiller) => {
        if (!popularBiller.logo) {
          dispatch(getBillerLogo(popularBiller.id));
        }
      });
    } else {
      dispatch(getPopularBillersAction());
    }
  }, [popularBillers]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelect = (biller) => {
    log.log('SELECTING: ', biller);
    dispatch(getBiller(biller?.id));
    onSelect(biller);
    tracker.track(tracker.events.addBillerSelect, { initiator, biller_id: biller?.id, biller_name: biller?.name });
  };

  const searchBillersPromise = useCallback(bindPromiseAction(dispatch, searchForBillers));
  const searchBillers = (input) => {
    if (input && input.length > 0) {
      searchBillersPromise(input)
        .then((resolvedBillers) => {
          setBillers(resolvedBillers);
        })
        .catch((error) => log.error(error));
      // setLastSearchValue(input);
    } else {
      // Note: This gets called when the control leaves focus. This can happen clicking away or even by selecting
      // item. In this case, input will not exists. However, on screen, input is still left in control (perhaps
      // interaction with material-ui input isn't setup correctly (not really sure). Also, I don't clear last
      // search term here because when we select an item, then it will always be cleared due to this behavior.
      //
      setBillers(null);
    }
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.centered} variant="subtitle1">
        Search all Biller Institutions
      </Typography>
      <Downshift
        initialHighlightedIndex={0}
        itemToString={(item) => item ? item.label : ''}
        onInputValueChange={(input) => searchBillers(input, dispatch)}
        onSelect={handleSelect}
      >
        {({ getInputProps, getItemProps, reset, selectedItem, highlightedIndex, selectHighlightedItem }) => {
          const { onBlur, onChange, value, ...inputProps } = getInputProps();
          return (
            <div className={classes.searchWrapper}>
              <TextField
                variant="outlined"
                fullWidth
                margin="dense"
                onBlur={onBlur}
                onChange={onChange}
                onKeyDown={keyHandle(highlightedIndex, selectHighlightedItem)}
                InputProps={{
                  autoFocus: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="Clear search" onClick={reset} size="large">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  ...inputProps,
                  value: value || '',
                  inputProps: {
                    id: 'bankSearch',
                  },
                }}
                label="Search"
                className={classes.searchBar}
              />
              {billers ? (
                <Paper square className={classes.dropDown}>
                  {billers.map((biller, key) => (
                    renderSuggestion({
                      suggestion: biller,
                      key,
                      itemProps: getItemProps({ item: biller }),
                      highlightedIndex,
                      selectedItem,
                    })
                  ))}
                </Paper>
              ) : null}
            </div>
          );
        }}
      </Downshift>
      <div className={classes.grid}>
        <Typography className={classes.centered} variant="subtitle1">
          Or select from popular ones
        </Typography>

        <div className={classes.billerSpace}>
          {popularBillers?.map((biller) =>
            <ButtonBase className={classes.billerButton} key={biller.id} onClick={() => handleSelect(biller)}>
              <img alt={biller.name} src={biller.logo} />
            </ButtonBase>).toIndexedSeq()}
        </div>
      </div>
    </div>
  );
}

SearchPage.propTypes = {
  onSelect: PropTypes.func,
  initiator: PropTypes.string,
};

export default SearchPage;
