import { DateTime } from 'luxon';

import { categoriesSelectors } from '@quicken-com/react.flux.categories';

import { BudgetNodeTypes, BudgetRollOverType } from './budgetsTypes';

/**
 * calculate toDate & Year Summary values for budget Items
 */
export const updateBudgetItemSummary = (budgetTree, parentBudget, budgetType, ignoreTypes) => {
  const toDate = DateTime.now().endOf('month');
  Object.keys(parentBudget).forEach((key) => {
    const index = budgetTree.findIndex(((item) => item.key === key));
    const categoryToDate = {
      actualAmount: 0,
      balanceAmount: 0,
      budgetAmount: 0,
      isToDate: true,
      startDate: toDate,
    };
    let toDateIndex = -1;
    let rolloverType;
    if (index >= 0 && budgetTree[index].type === budgetType) {
      budgetTree[index].monthlyAmounts = Object.values(parentBudget[key]).sort((budgetA, budgetB) => budgetA.startDate - budgetB.startDate);// eslint-disable-line no-param-reassign
      const summary = budgetTree[index].monthlyAmounts.reduce((accumulator, monthly, monthlyItemIndex) => {
        // roll over all & roll over positive just use the prev month balance as it accumulates the values
        // TODO gr & cee calculation has to use child values
        const useRollOverBalance = (budgetTree[index].type === BudgetNodeTypes.CATEGORY_EE 
          || budgetTree[index].type === BudgetNodeTypes.CATEGORY_OTHER) && monthly.rolloverType !== BudgetRollOverType.NO_ROLLOVER;
        const calculatedSummary = {
          actualAmount: accumulator.actualAmount + monthly.actualAmount,
          balanceAmount: useRollOverBalance ? monthly.balanceAmount : accumulator.balanceAmount + monthly.balanceAmount,
          budgetAmount: accumulator.budgetAmount + monthly.budgetAmount,
        };
        if (monthly.startDate.valueOf() <= toDate.valueOf()) {
          categoryToDate.actualAmount = calculatedSummary.actualAmount;
          categoryToDate.balanceAmount = useRollOverBalance ? monthly.balanceAmount : calculatedSummary.balanceAmount;
          categoryToDate.budgetAmount = calculatedSummary.budgetAmount;
          toDateIndex = monthlyItemIndex;
        }
        rolloverType = monthly.rolloverType;
        return calculatedSummary;
      }, {
        actualAmount: 0,
        balanceAmount: 0,
        budgetAmount: 0,
      });

      // use budget items summary to calculate parent coa items summary
      if (budgetType === BudgetNodeTypes.GROUP || budgetType === BudgetNodeTypes.CATEGORY_ROLL_UP) {
        const balance = budgetTree[index].childrenKeys.reduce((acc, coaKey) => {
          const coaIndex = budgetTree.findIndex(((item) => item.key === coaKey));
          return {
            balanceAmount: acc.balanceAmount + (!ignoreTypes.includes(budgetTree[coaIndex].type) ? (budgetTree[coaIndex].summary?.balanceAmount || 0) : 0),
            toDateBalance: acc.toDateBalance + (!ignoreTypes.includes(budgetTree[coaIndex].type) ? (budgetTree[coaIndex].monthlyAmounts?.[toDateIndex + 1]?.balanceAmount || 0) : 0),
          };
        }, { balanceAmount: 0, toDateBalance: 0 });
        summary.balanceAmount = balance.balanceAmount;
        categoryToDate.balanceAmount = balance.toDateBalance;
      }
      budgetTree[index].rolloverType = rolloverType;// eslint-disable-line no-param-reassign
      budgetTree[index].summary = summary;// eslint-disable-line no-param-reassign
      budgetTree[index].monthlyAmounts.splice(toDateIndex + 1, 0, categoryToDate);
    }
  });
};

// TODO: move this function as selector to avoid repeat calculation
export const getBudgetStartDate = (budget, budgetItems) => {
  // use budget start in case it has only one year of budget items
  if (budget?.schedule?.totalFrequency === 12) {
    return DateTime.fromISO(budget?.schedule?.startDate).startOf('month');
  }

  // split budget-items dates respective to budget years
  function splitBudgetDates(dates) {
    const datesSplit = [];
    for (let dateIndex = 0; dateIndex < dates.length; dateIndex += 12) {
      datesSplit.push(dates.slice(dateIndex, dateIndex + 12));
    }
    return datesSplit;
  }

  // Filter unique dates from budgetItems date
  const budgetItemDates = budgetItems?.toList().map(({ startDate }) => startDate)
    .filter((startDate, index, startDates) => index === startDates.indexOf(startDate));

  const budgetItemDatesSplit = splitBudgetDates(budgetItemDates.toArray().sort((dateA, dateB) => new Date(dateA) - new Date(dateB)));

  // find current budget start date
  const currentDate = DateTime.local();
  let startDate;
  budgetItemDatesSplit.forEach((dates) => {
    const timestamps = dates.map((date) => Date.parse(date));
    const minBudgetDate = DateTime.fromMillis(Math.min(...timestamps));
    const maxBudgetDate = DateTime.fromMillis(Math.max(...timestamps)).endOf('month');
    startDate = currentDate >= minBudgetDate && currentDate <= maxBudgetDate ? minBudgetDate : startDate;
  });
  return startDate?.startOf('month');
};

export const updateNegateBasedOnBudgetItems = (budgetTree) => {
  budgetTree.forEach((categoryNode, index) => {
    if (categoryNode.type === BudgetNodeTypes.GROUP) {
      const { coa } = categoryNode?.budgetItems[0] || null;
      if (coa && coa.id) {
        const isIncome = categoriesSelectors.isIncomeCat(null, coa?.id) || false;
        budgetTree[index].negate = isIncome ? 1 : -1; // eslint-disable-line no-param-reassign
      }
    }
  });
};
