/* eslint react/prop-types: 0 */
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import QIconButton from 'components/QIconButton';
import CloseIcon from '@mui/icons-material/Close';

const styles = (_theme) => ({
  closeIcon: {
    color: '#aaa',
  },
  closeBox: {
    position: 'absolute',
    right: '5px',
    top: '5px',
  },
});
const useStyles = makeStyles(styles);

const QCloseBox = (props) => {

  const { onClose } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.closeBox} {...(props.id ? { id: props.id } : {})}>
      <QIconButton
        size="small-target"
        onClick={onClose}
        IconProps={{ classes: { root: classes.closeIcon } }}
        IconComponent={CloseIcon}
      />
    </div>
  );
};

export default QCloseBox;
