
export function findGroupIdForCategoryId(categoryGroupsById, catId) {

  let ret = null;
  if (categoryGroupsById && catId) {
    categoryGroupsById.some((group) => {
      if (group.coas) {
        if (group.coas.find((item) => item.id === catId)) {
          ret = group.id;
        }
      }
      return ret !== null;
    });
  }
  return ret;
}
