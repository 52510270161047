export const styles = (theme) => ({

  categoryNameWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: (props) => (props.depth + 1) * 10,
  },
  categoryName: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  categoryNameParent: {
    extend: 'categoryName',
    fontStyle: 'italic',
    color: theme.palette.greyScaleDeprecated[3],
    paddingLeft: 8,
  },
  categoryRow: {
    width: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 5,
    marginLeft: (props) => props.isRollup ? 10 * (props.depth + 1) : null,
    '&.rollup': {
      background: theme.palette.greyScaleDeprecated[5],
    },
  },
  categoryAmount: {
    flex: 0,
    minWidth: 120,
    paddingLeft: 4,
    paddingRight: 2,
    borderRadius: 3,
    marginRight: 6,
    textAlign: 'right',
  },
  amountInputStyle: {
    paddingTop: 2,
    paddingBottom: 2,
    fontWeight: (props) => props.isRollup ? 500 : null,
  },
  barChartIconHolder: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  barChartIcon: {
    color: theme.components.categoryCard.barChartIconColor,
  },
  categoryReviewCardRoot: {
    maxWidth: 650,
    width: 450,
  },
  categoryReviewTxList: {
    maxHeight: 200,
  },
  hide: {
    visibility: 'hidden',
  },
  iconHolder: {
    minWidth: 40,
  },
  headerText: {
    fontWeight: 500,
  },

});
