import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import makeStyles from '@mui/styles/makeStyles';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Zoom from '@mui/material/Zoom';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { boxShadowsToDropShadows } from 'themes/themeBase';
import Fade from '@mui/material/Fade';

const arrowSize = 10;
const borderRadius = 20;

const useStyles = makeStyles((theme) => ({
  popper: ({ dark, greyscaled }) => {
    let backgroundColor = theme.palette.background.paper;
    if (dark) {
      backgroundColor = theme.palette.greyScaleDeprecated[0];
    } else if (greyscaled) {
      backgroundColor = theme.components.categoryDialog.primary;
    }
    return {
      zIndex: theme.zIndex.modal,
      filter: boxShadowsToDropShadows(theme.shadows[16]),
      backgroundColor,
      color: dark ? theme.palette.text.lightContrast : theme.palette.text.primary,
      borderRadius,
      '&[data-popper-placement*="left"] .arrow': {
        right: 0,
        marginRight: -arrowSize - arrowSize + 1,
        marginTop: borderRadius,
        marginBottom: borderRadius,
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: 'transparent',
      },
      '&[data-popper-placement*="top"] .arrow': {
        bottom: 0,
        marginBottom: -arrowSize - arrowSize + 1,
        marginLeft: borderRadius,
        marginRight: borderRadius,
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: 'transparent',
      },
      '&[data-popper-placement*="right"] .arrow': {
        left: 0,
        marginLeft: -arrowSize - arrowSize + 1,
        marginTop: borderRadius,
        marginBottom: borderRadius,
        borderLeftColor: 'transparent',
        borderTopColor: 'transparent',
        borderBottomColor: 'transparent',
      },
      '&[data-popper-placement*="bottom"] .arrow': {
        top: 0,
        marginTop: -arrowSize - arrowSize + 1,
        marginLeft: borderRadius,
        marginRight: borderRadius,
        borderLeftColor: 'transparent',
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
      },
    };
  },
  arrow: ({ dark, greyscaled }) => {
    let borderColor = theme.palette.background.paper;
    if (dark) {
      borderColor = theme.palette.greyScaleDeprecated[0];
    } else if (greyscaled) {
      borderColor = theme.components.categoryDialog.primary;
    }
    return {
      zIndex: theme.zIndex.tooltip,
      position: 'absolute',
      borderColor,
      borderStyle: 'solid',
      borderWidth: arrowSize,
    };
  },
  content: ({ greyscaled }) => ({ 
    borderRadius,
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: greyscaled ? theme.components.categoryDialog.primary : undefined,
  }),
}));

const QPopper = (props) => {
  const { 
    modifiers, 
    onClickAway, 
    className, 
    style, 
    arrowPadding, 
    transitionEffect = 'zoom', 
    children,
    placement = 'auto',
    ...otherProps 
  } = props;
  const classes = useStyles(props);
  const [arrowRef, setArrowRef] = useState();

  const renderPopperContents = () => (
    <div>
      <span
        className={classNames(classes.arrow, 'arrow')}
        ref={(ref) => setArrowRef(ref)}
      />
      <Paper
        elevation={0}
        className={classNames(classes.content, className)}
      >
        {children}
      </Paper>
    </div>
  );

  return props.anchorEl ? (
    <ClickAwayListener onClickAway={onClickAway}>
      <Popper
        className={classes.popper}
        keepMounted={false}
        disablePortal={false}
        sharedcomponentid={'Q_POPPER'}
        placement={placement}
        modifiers={[{
          name: 'flip',
          options: {
            fallbackPlacements: ['top', 'right', 'bottom', 'left'],
          },
        }, {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
            padding: arrowPadding ?? 20,
          },
        },
        ...(modifiers ?? []),
        ]}
        {...otherProps}
        transition
      >
        {({ TransitionProps }) => (
          <>
            {
              transitionEffect === 'zoom' && 
              <Zoom {...TransitionProps} timeout={200}>
                { renderPopperContents() }
              </Zoom>
            }
            {
              transitionEffect === 'fade' && 
              <Fade {...TransitionProps} timeout={350}>
                { renderPopperContents() }
              </Fade>
            }
          </>
        )}
      </Popper>
    </ClickAwayListener>
  ) : null;
};

QPopper.defaultProps = {
  onClickAway: noop,
};

QPopper.propTypes = {
  anchorEl: PropTypes.object,
  modifiers: PropTypes.array,
  children: PropTypes.any,
  open: PropTypes.bool,
  onClickAway: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  dark: PropTypes.bool,
  greyscaled: PropTypes.bool,
  arrowPadding: PropTypes.any,
  transitionEffect: PropTypes.oneOf(['zoom', 'fade']),
  placement: PropTypes.string,
};

export default QPopper;
