import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { getCommonConfig, getLogger } from '@quicken-com/react.utils.core';
import { profileSelectors } from '@quicken-com/react.flux.profile';
import { authSelectors } from '@quicken-com/react.flux.auth';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';

import { getActiveSubscription } from 'data/subscriptions/subscriptionsSelectors';
import { getFinancialInstitutionsPendo } from 'data/institutionLogins/selectors';
import { getSubscriptionTrackingProps } from 'data/subscriptions/helpers';

const log = getLogger('utils/pendo.js');

function updatePendo({
  // profile,
  authSession,
  subscription,
  addFiDefaultProvider,
  financialInstitutions,
  currentDataset,
}) {
  if (window.pendo) {
    const subscriptionProps = getSubscriptionTrackingProps(subscription);
    const subscriptionPropsPendo = {};
    Object.keys(subscriptionProps).forEach((propName) => {
      subscriptionPropsPendo[_.snakeCase(propName)] = subscriptionProps[propName];
    });
    // Call this whenever information about your visitors becomes available
    // Please use Strings, Numbers, or Bools for value types.
    const metaData = {
      visitor: {
        id: authSession.qcsId, // Required if user is logged in
        scope: authSession.scope,
        groups: authSession.groups,
        // email: profile.username, // Recommended if using Pendo Feedback, or NPS Email
        // full_name: `${profile.firstName} ${profile.lastName}`, // Recommended if using Pendo Feedback
        // role:         // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
        app_version: String(process.env.APP_VERSION),
        default_aggregator: addFiDefaultProvider,
        financial_institutions: financialInstitutions?.join(', '),
        financial_institutions_list: financialInstitutions,
        ...(currentDataset && { current_dataset_created: currentDataset.createdAt }),
        ...subscriptionPropsPendo,
      },

      account: {
        // id: subscription.productLineName, // Highly recommended
        // name: subscription.productLineName, // Optional
        // is_paying: subscription.active, // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel: subscription.tierName, // Optional
        // planPrice:    // Optional
        // creationDate: subscription.effectiveAt, // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
        // expireOn: subscription.expireOn,
        // ...subscriptionPropsPendo,
      },
    };
    log.debug('\npendo identify:\n', metaData);
    try {
      window.pendo.updateOptions?.(metaData);
    } catch (e) {
      assert(false, e);
    }

    // if (authSession?.accessToken) {
    //   window.pendo.loadGuides?.();
    //   window.pendo.startGuides?.(); // triggers CORS exception
    //   log.log('pendo: start');
    // } else {
    //   window.pendo.stopGuides?.();
    //   log.log('pendo: stop');
    // }
  }
}

const Pendo = () => {
  const featureFlagsError = useSelector(featureFlagsSelectors.getError);
  const featureFlagsLoadPending = useSelector(featureFlagsSelectors.getLoadPending);
  const pendoEnabled = useSelector((state) => featureFlagsSelectors.getFeatureFlag(state, 'pendo'));
  const addFiDefaultProvider = useSelector((state) => featureFlagsSelectors.getFeatureFlag(state, 'addFiDefaultProvider'));
  const profile = useSelector(profileSelectors.getProfile);
  const authSession = useSelector(authSelectors.getAuthSession);
  const subscription = useSelector(getActiveSubscription);
  const financialInstitutions = useSelector(getFinancialInstitutionsPendo);
  const currentDataset = useSelector(authSelectors.getCurrentDataset);

  useEffect(() => {
    if (!featureFlagsLoadPending || featureFlagsError) {
      if (!window.pendo && pendoEnabled && getCommonConfig().pendo_api_key) {
        // we don't pass pendo_api_key to pendo sdk - it's hardcoded instead inside @quicken-com/react.external.pendo-simplifi
        // pendo sdk code is not compatible with ES5 'strict' mode, so we can't cross pendo sdk boundary
        log.log('Init Pendo...');
        try {
          require('@quicken-com/react.external.pendo-simplifi'); // eslint-disable-line global-require
          assert(window.pendo, 'pendo init failed');
          window.pendo?.initialize?.({
            disableCookies: true, // Do not use cookies for storing any values, defaulting to in-memory storage only. Default is false.
            disablePersistence: true, // Do not use cookies to remember any Visitor identifiers or metadata, last seen Guide Step, and various other pieces of state. While this will prevent the Agent writing cookies, it will also ignore any identifiers already stored in cookies and may produce an increase of anonymous Visitors in Pendo, depending on how soon your application identifies the Visitor. Default is false.
            // 'guides.delay': true, // Guides will be loaded on initialize but not displayed initially. Call pendo.startGuides() to start the Guide sub-system. Default is false.
            // disableGuides: true, // Completely disables the Guides sub-system. Default is false. Set to true to disable guides.
          });
        } catch (e) {
          assert(false, e);
        }
        updatePendo({ profile, authSession, subscription });
      }
    }
  }, [featureFlagsLoadPending, featureFlagsError, pendoEnabled, profile, authSession, subscription]);

  useEffect(() => updatePendo({
    profile,
    authSession,
    subscription,
    addFiDefaultProvider,
    financialInstitutions,
    currentDataset,
  }), [
    profile,
    authSession,
    subscription,
    addFiDefaultProvider,
    financialInstitutions,
    currentDataset,
  ]);

  return null;
};

export default Pendo;


