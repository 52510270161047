import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';

const TableHeader = (props) => {
  const { classes, columns, selectedAccounts, totalRows, onChangeAccountsToShow } = props;
  const isIndeterminate = useMemo(() => selectedAccounts > 0 && selectedAccounts < totalRows, [totalRows, selectedAccounts]);
  const isChecked = useMemo(() => totalRows > 0 && selectedAccounts === totalRows, [totalRows, selectedAccounts]);
  const onChange = useCallback(() => onChangeAccountsToShow(isChecked ? 'CLEAR' : 'ALL'), [onChangeAccountsToShow, isChecked]);
  return (
    <TableHead>
      <TableRow>
        {Object.keys(columns).map((key) => {
          const column = columns[key];
          return (
            <TableCell key={key} className={classNames(props.classes.resetCell, props.classes?.[key])}>
              <div className={classes?.[`${key}Container`]}>
                {column.label ? column.label : (
                  <Checkbox
                    color="default"
                    indeterminate={isIndeterminate}
                    checked={isChecked}
                    onChange={onChange}
                  />)}
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

TableHeader.propTypes = {
  columns: PropTypes.object,
  classes: PropTypes.object,
  selectedAccounts: PropTypes.number,
  totalRows: PropTypes.number,
  onChangeAccountsToShow: PropTypes.func,
};


TableHeader.whyDidYouRender = true;
export default React.memo(TableHeader);
