
// BASE
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Set } from 'immutable';
import { useSelector, shallowEqual } from 'react-redux';

import { categoriesSelectors } from '@quicken-com/react.flux.categories';
import { chartOfAccountsUtils } from '@quicken-com/react.flux.chart-of-accounts';

// CUSTOM COMPONENTS
import QCloseBox from 'components/QCloseBox';
import QButton from 'components/QButton';
import QCard from 'components/QCard';

// SELECTORS / ACTIONS / UTILITIES
import { getPopularCatsMonthlyAverage } from 'data/transactions/selectors';
import { getPredefinedRange } from 'utils/date/utils';

// MUI COMPONENTS
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

// PATH RELATIVE
import SuggestedBudgetItem from './SuggestedBudgetItem';

// STYLES HOOK
import { styles } from './styles';
const useStyles = makeStyles(styles);

/*
 * BudgetSuggestedCategories Component ***********************************
 */

// PROP TYPES
const budgetSuggestedCategoriesPropTypes = {
  // passed in
  onAddItems: PropTypes.func,
  onClose: PropTypes.func,
  accountIds: PropTypes.object,
  exclusionSet: PropTypes.object,
  currency: PropTypes.string,
  // map state to props - dispatch to props
};

const qCards = {
  firstCard: {
    elementId: 'suggested-categories-checkbox-Category',
    edge: 'left',
    width: 284,
    height: 200,
    next: 'secondCard',
    title: 'Select All Categories',
    content: 'If you want to start from your most regularly used categories, click here to select all categories.  You can ' +
    'then de-select any that you do not want to include.',
  },
  secondCard: {
    elementId: 'cat-row-container: 0',
    edge: 'right',
    width: 284,
    height: 200,
    prev: 'firstCard',
    title: 'Suggested Amounts',
    content: 'The suggested amount is based on your past spending patterns.  Once you add the item, you will be ' +
      'able to edit the amounts on the main screen.',
  },
};

const MAX_LIST_SIZE = 20;
const BudgetSuggestedCategories = (props) => {

  // PROPS
  const { onAddItems, onClose, accountIds, currency, exclusionSet = new Set() } = props;

  // STATE
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [qCardsOn, setQCardsOn] = useState(true);

  // SELECTORS
  const popularCatsMonthlyAverage = useSelector((state) =>
    getPopularCatsMonthlyAverage(state,
      { dateRange: getPredefinedRange({ value: '12_MONTHS_PRIOR' }), sortByAmount: true }), shallowEqual);

  const popularCatsList = useMemo(() => popularCatsMonthlyAverage.filter((x) =>
    !exclusionSet.find((xcoa) => chartOfAccountsUtils.coasAreEqual(xcoa, x.coa))).slice(0, MAX_LIST_SIZE), [exclusionSet, popularCatsMonthlyAverage]);

  const incomeCats = popularCatsList.filter((x) => categoriesSelectors.isIncomeCat(null, x.coa.id));
  const expenseCats = popularCatsList.filter((x) => !categoriesSelectors.isIncomeCat(null, x.coa.id));

  // EFFECTS

  // STYLES ============================================================================
  const classes = useStyles(props);

  // INTERNAL FUNCTIONS ================================================================

  /*
   * showHideItem
   */
  const showHideItem = (id) => {

    const newSelectedItems = selectedItems.has(id) ? selectedItems.delete(id) : selectedItems.add(id);
    setSelectedItems(newSelectedItems);
  };

  /*
   * selectUnselectAll
   */
  const selectUnselectAll = () => {
    if (selectedItems.size === popularCatsList.size) {
      setSelectedItems(new Set());
    } else {
      setSelectedItems(new Set(popularCatsList.map((x) => x.coa.id)));
    }
  };

  /*
   * buildAddListFromIds
   */
  const buildAddListFromIds = () => selectedItems.map((id) => {

    const catId = id;
    const node = popularCatsList.find((x) => x.coa.id === id);

    return (
      {
        coa: { type: 'CATEGORY', id: catId },
        amount: node ? Math.abs(node.monthlyAverage) : 0,
      });
  });


  /*
   * getInnerNodes
   */
  const getInnerNodes = (name, nodes) => {

    const localNodeList = [];
    nodes.forEach((node, index) => {

      localNodeList.push(
        <React.Fragment key={`budgetNode:${name}:${node.key || (node.coa && node.coa.id)}`}>
          <div id={`cat-row-container: ${index}`}>
            <SuggestedBudgetItem
              item={node}
              selected={selectedItems.has(node.key || node.coa.id)}
              onChange={showHideItem}
              accountIds={accountIds}
              currency={currency}
            />
          </div>
        </React.Fragment>
      );
    });
    return localNodeList;
  };

  // RENDER ==============================================================================

  return (
    <Dialog
      maxWidth="md"
      open
      onClose={onClose}
      classes={{ paperWidthMd: classes.dialogRoot }}
    >
      <QCard
        open={qCardsOn}
        onClose={() => setQCardsOn(false)}
        cards={qCards}
        name="WFBudgetSetupSuggestedCats"
        initialCard="firstCard"
        classes={{ qcard: classes.qCard }}
      />
      <QCloseBox onClose={onClose} id="choose-budget-close-button" />

      <TitleHeader classes={classes} />
      <Divider classes={{ root: classes.dividerRoot }} />

      <SuggestedBudgetItem
        header
        selected={selectedItems.size === popularCatsList.size}
        onChange={selectUnselectAll}
        classes={{ categoryRow: classes.headerRow }}
      />

      <CategorySelectionList
        classes={classes}
        incomeNodes={getInnerNodes('income', incomeCats)}
        expenseNodes={getInnerNodes('expense', expenseCats)}
      />

      <DialogActions className={classes.dialogActions}>
        <QButton onClick={onClose} id="choose-budget-cancel">
          Cancel
        </QButton>
        <QButton
          disabled={selectedItems.size === 0}
          onClick={() => onAddItems(buildAddListFromIds())}
          id="add-items-to-budget-button"
        >
          {`Add ${selectedItems.size} Items to Budget` }
        </QButton>
      </DialogActions>

    </Dialog>
  );
};

BudgetSuggestedCategories.propTypes = budgetSuggestedCategoriesPropTypes;
export default BudgetSuggestedCategories;

/*
 *************** SUB COMPONENTS ******************
 */
/*
 * Title Header sub component
 */
export const TitleHeader = (props) => {

  const { classes } = props;
  return (
    <div
      className={classes.header}
    >
      <Typography
        variant="h5"
        className={classes.title}
      >
        Choose from your most used categories
      </Typography>
      <Typography
        variant="body1"
      >
        Select a category to add to your budget.  An average amount from your previous transactions
        will be entered on your behalf.  After you select the items and save them, you can change the default amounts
        on the main edit screen.
      </Typography>
    </div>
  );
};
TitleHeader.propTypes = {
  classes: PropTypes.object,
};

/*
 * CategorySelectionList
 */
export const CategorySelectionList = (props) => {

  const { classes, expenseNodes, incomeNodes } = props;

  return (
    <div style={{ overflowY: 'scroll' }}>
      <Typography
        variant="subtitle1"
        className={classes.sectionHeader}
      >
        INCOME
      </Typography>
      {incomeNodes}

      <Typography
        variant="subtitle1"
        className={classes.sectionHeader}
      >
        EXPENSES
      </Typography>
      {expenseNodes}
    </div>
  );
};
CategorySelectionList.propTypes = {
  classes: PropTypes.object,
  expenseNodes: PropTypes.array,
  incomeNodes: PropTypes.array,
};


