
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import compose from 'utils/compose';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import QButton from 'components/QButton';
import SetupStatusBar from 'components/SetupStatusBar';
import QCloseBox from 'components/QCloseBox';

import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import GettingStartedModule from 'components/GettingStartedModule';

import successImage from 'assets/webFirst/accounts-success.png';

// import { getLogger } from 'utils/logger';
// const log = getLogger('containers/GettingStartedModulePage');

import { styles } from './styles';

class GettingStartedModulePage extends PureComponent { // eslint-disable-line react/prefer-stateless-function

  static propTypes = {
    classes: PropTypes.object,
    onCompleted: PropTypes.func,
    config: PropTypes.object,
    onClose: PropTypes.func,
    skipText: PropTypes.string,
    onButtonClick: PropTypes.func,
    showSuccess: PropTypes.bool,
  };

  render() {

    const { classes, skipText, showSuccess, config } = this.props;

    return (
      <Dialog
        open
        hideBackdrop={Boolean(showSuccess && config.successQCard)}
        disableEscapeKeyDown={config.noSkip}
        onClose={this.props.onClose}
        maxWidth="md"
        classes={{ paperWidthMd: classes.paperWidthMd }}
        BackdropProps={{ style: { opacity: 0.4 } }}
      >
        <QCloseBox onClose={this.props.onClose} id="set-up-your-quicken-close-button" />

        <div style={{ height: '100%', padding: '10px 48px' }}>

          {!showSuccess &&
            <>
              <SetupStatusBar
                classes={{ root: classes.statusBar }}
                titleOnTop
                selected={config.page}
              />
              <GettingStartedModule config={config} />
            </>}
          {showSuccess &&
          <>
            <div style={{ textAlign: 'center' }}>
              <Typography className={classes.title} style={{ marginTop: 15 }}>
                Awesome!
              </Typography>
              <Typography className={classes.subTitle}>
                {config.success}
              </Typography>
              <img
                alt="Connection success"
                src={successImage}
                style={{ marginTop: 20 }}
              />
              <Divider
                style={{ margin: 20, marginTop: 45, marginBottom: 45 }}
              />
              <Typography className={classes.successTitle}>
                {config.successSub.title}
              </Typography>
              <Typography className={classes.successSubTitle}>
                {config.successSub.subTitle}
              </Typography>
            </div>
          </>}
        </div>

        <div className={classes.buttonArea}>
          {this.props.config.buttons.map((btn) =>
            <div key={`SETUPBTN: ${btn.value}`}>
              <QButton
                variant={showSuccess ? null : 'contained'}
                classes={{ conButton: classes.setupButton, root: showSuccess ? classes.skipTextButton : classes.skipSecondaryTextButton }}
                onClick={(e) => this.props.onButtonClick(e, btn.value)}
                id={btn.id}
              >
                {btn.label}
              </QButton>
            </div>)}
          {this.props.onCompleted &&
          <div>
            <QButton
              variant={showSuccess ? 'contained' : null}
              classes={{ conButton: classes.setupButton, root: showSuccess ? classes.skipSecondaryTextButton : classes.skipTextButton }}
              onClick={this.props.onCompleted}
              id={`get-started-${skipText.toLowerCase()}-button`}
            >
              {skipText}
            </QButton>
          </div>}
        </div>
        {!showSuccess && config.caption &&
          <Typography className={classes.bottomCaption} variant="caption">{config.caption}</Typography>}
      </Dialog>
    );
  }
}

function mapStateToProps(_state) {
  return {
  };
}

function mapDispatchToProps(_dispatch) {
  return {
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(GettingStartedModulePage));
