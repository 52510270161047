import compose from 'utils/compose';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import { removeDialog } from 'data/rootUi/actions';
import { updateBudgetItemsAction } from 'data/budgetItems/budgetItemsActions';
import { getCategoryBudgetTransactions } from 'data/budgets/budgetsSelectors';

import YearlyBudgetEditView from './yearlyBudgetEditView';
import styles from './styles';

const mapStateToProps = (state, props) => (
  { 
    annualBudgetDataOfCategory: props.annualBudgetDataOfCategory,
    categoryBudgetDetails: getCategoryBudgetTransactions(state, props.budgetId, props.annualBudgetDataOfCategory.id, false, null, null, props.allCategoryIdsOfBudget),
    showCalculateAverageView: props.showCalculateAverageView,
    directlyCloseCalculateAverageView: props.directlyCloseCalculateAverageView,
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    removeDialog: () => dispatch(removeDialog()),
    updateBudgetItems: (data) => dispatch(updateBudgetItemsAction(data, { undo: { userMessage: 'Budget items updated.' } })),
  }
);

export default compose( 
  withStyles(styles, { withTheme: true }), 
  connect(mapStateToProps, mapDispatchToProps)
)(YearlyBudgetEditView);
