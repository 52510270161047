/*
 * PREFERENCE SELECTORS - getting data out of the preference store
 *
 * These routines require a "state" value from the preference store, to access
 * the data model from anywhere,use the preferenceStore module which will create the
 * state then call these routines
 */

import { mapJSObject } from 'utils/utils';
import { createSelector } from 'reselect';
import { colorLookup } from './utils';

export const REDUCER_KEY = 'preferencesStore';

/*
 * For these selectors, state is global state as it is provided to React (not specific to preferences)
 * We use them to select into the store and get data for this REDUCER_KEY
 */
export const getPreferenceStore = (store) => store[REDUCER_KEY];  // store has multiple reducer states
export const getPreferences = (state) => getPreferenceStore(state).preferences;
export const getError = (state) => getPreferenceStore(state).error;
export const getIsLoading = (state) => getPreferenceStore(state).isLoading;
export const getLoadPending = (state) => getPreferenceStore(state).loadPending.shared.user || getPreferenceStore(state).loadPending.shared.dataset;
export const getLoadPendingState = (state) => getPreferenceStore(state).loadPending;

/*
 * The selectors below operate directly on the preferences datastore (getPreferenceStore)
 */

const pathIn = (obj, path) => path.reduce((curObj, curPath) => curObj && curObj[curPath], obj);

// -------------------------------------------------
// getClientPreferencesByPath
//
// Given an preference list (same format as state), will return an object
// with the data for the given ID
//
// section is one of 'user', 'dataset', 'global'
//
// path is an array of keys to the preference you desire
//
// see types.js for path possibilities
//
/*  // TODO this will be relevant when /preferences/client is implemented on QCS
export function getClientPreferencesByPath(state, { group, path }) {

  if (!state.loadPending) {
    const prefs = getPreferences(state);
    if (group) {
      return path ? pathIn(prefs.client[group], path) : prefs.client[group];
    }
    // if no group,path is a  key
    if (path) {
      return pathIn(prefs.client, path);
    }
  }
  return null;
}
*/

// -------------------------------------------------
// getSharedPreferencesByPath
//
// Given an preference list (same format as state), will return an object
// with the data for the given ID
//
// section is one of 'user', 'dataset', 'global'
//
// path is an array of keys to the preference you desire
//
// see types.js for path possibilities
//
export function getSharedPreferencesByPath(state, { group, path }) {

  // if (!state.loadPending) {
  const prefs = getPreferences(state);
  if (group) {
    const retVal = path ? pathIn(prefs.shared[group], path) : prefs.shared[group];
    return retVal;
  }
  // if no group,path is a  key
  if (path) {
    return pathIn(prefs.shared, path);
  }
  // }
  return null;
}

export function allAccountPreferences(state) {
  const prefs = getSharedPreferencesByPath(state, { group: 'dataset', path: ['webApp'] });
  return (prefs && prefs.accountsPrefs ? prefs.accountsPrefs : null);
}

export function accountPreferencesById(state) {

  const acctPrefs = allAccountPreferences(state);
  return acctPrefs && acctPrefs.byId;

}

export const getAccountNodePreferencesById = createSelector(
  (state) => getSharedPreferencesByPath(state, { group: 'dataset', path: ['webApp', 'accountNodePrefs'] }),
  (acctPrefs) => acctPrefs
);

export const accountPreferencesForId = createSelector(
  (state, id) => id,
  (state) => getSharedPreferencesByPath(state, { group: 'dataset', path: ['webApp', 'accountsPrefs'] }),
  (accountId, accountsPrefs) => {

    if (accountsPrefs && accountsPrefs.byId) {
      const pref = accountsPrefs.byId[accountId];
      return pref;
    }
    return {};
  }
);

export const accountColorsById = createSelector(
  allAccountPreferences,
  (accountPrefs) => {

    // console.log(accountPrefs);
    if (accountPrefs && accountPrefs.byId) {
      return mapJSObject(accountPrefs.byId, (item) => colorLookup(item.color));
    }
    return {};
  }
);

export const accountColorForId = createSelector(
  (state, id) => id,
  (state, id) => accountPreferencesForId(state, id),
  (accountId, pref) => {

    // console.log("ACCOUNT PREFS FOR ID ", accountId, pref);
    if (pref) {
      return colorLookup(pref.color);
    }
    return '#FFF';
  }
);

const dataPathObject = { group: 'dataset', path: ['webApp'] };
export const getDatasetPreferences = (state) => getSharedPreferencesByPath(state, dataPathObject);

export const showFlags = createSelector(
  getDatasetPreferences,
  (datasetPreferences) => datasetPreferences?.transactionRegister?.regFieldsSort?.cashFlow?.includes?.('userFlag'),
);

export const showReviewed = createSelector(
  getDatasetPreferences,
  (datasetPreferences) => datasetPreferences?.transactionRegister?.regFieldsSort?.cashFlow?.includes?.('reviewed'),
);

export const showGSF = createSelector(
  getDatasetPreferences,
  (datasetPreferences) => !(datasetPreferences?.completedGSF || !datasetPreferences?.setupStateIndex),
);

export const isCompareToRegisterEnabled = createSelector(
  (state) => getSharedPreferencesByPath(state, { group: 'dataset', path: ['compareToRegister', 'enabled'] }),
  (compareToRegisterStatus) => compareToRegisterStatus
);

export const getRunningBalanceExcludeAccountIds = createSelector(
  (state) => getSharedPreferencesByPath(state, { group: 'dataset', path: ['excludePendingInRunningBalance'] }),
  (runningBalanceExcludeAccountIds) => runningBalanceExcludeAccountIds
);

export const isRealTimeQuotesEnabled = createSelector(
  (state) => getSharedPreferencesByPath(state, { group: 'dataset', path: ['datasetOptions', 'useRealtimeQuotes'] }),
  (useRealtimeQuotes) => useRealtimeQuotes
);
