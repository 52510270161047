import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import compose from 'utils/compose';
import { withRouter } from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map';
import QProgressBar from 'components/QProgressBar/QProgressBar';
import QCurrency from 'components/QCurrency';
import { BudgetNodeTypes } from 'data/budgets/budgetsTypes';
import * as budgetItemsActions from 'data/budgetItems/budgetItemsActions';
import * as budgetsActions from 'data/budgets/budgetsActions';
import { tracker } from '@quicken-com/react.utils.core';
import withStyles from '@mui/styles/withStyles';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRightRounded';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import Dump from 'components/Dump';

const inputFontSize = 13;
const styles = (theme) => ({
  input: {
    ...theme.typography.body2,
    fontSize: inputFontSize,
    width: 100,
    height: 20,
    '& input': {
      minHeight: 9,
      padding: '0 2px 0',
      '-moz-appearance': 'textfield',
      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  },
  inputAdornment: {
    ...theme.typography.body2,
    fontSize: `${inputFontSize}px !important`,
    marginRight: 0,
  },
});

class BudgetNode extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      userTargetAmount: undefined,
      node: undefined,
    };
  }

  updateBudgetItem = () => {
    const { node, userTargetAmount } = this.state;
    if (!Number.isNaN(userTargetAmount) && node && node.negate) {
      const newTargetAmount = userTargetAmount * node.negate;
      if (!Number.isNaN(newTargetAmount) && node.targetAmount !== newTargetAmount) {
        const budgetItem = node.budgetItems && node.budgetItems.size === 1 ?
          node.budgetItems.first() : null;
        if (budgetItem) {
          tracker.track(tracker.events.editBudget, { range: 'current_month' });
          this.props.dispatchUpdateBudgetItemAction({
            id: budgetItem.id,
            amount: newTargetAmount,
          });
          // QWIN refresh budget items only when budget updated
          // TODO Dummy budget update call. tobe removed later once QWIN fixes the issue.
          this.props.dispatchUpdateBudgetAction(this.props.budget);
        }
      }

      this.setState({
        node: undefined,
        newTargetAmount: undefined,
      });
    }
  };

  getProgressBarColor = (isIncome, targetAmount, temp, theme) => {
    const amountLeft = Number.isNaN(temp) ? 0 : Number(temp);
    let overBudget = false;
    if (isIncome && targetAmount < 0 && amountLeft > 0) overBudget = true;
    else if (!isIncome && targetAmount >= 0 && amountLeft < 0) overBudget = true;
    return overBudget ? theme.palette.red2 : theme.palette.green2;
  };

  getAmountLeft = (showCents, targetTotalAmount, actualAmount) => {
    if (showCents) {
      return targetTotalAmount - actualAmount;
    }
    return targetTotalAmount - Math.round(actualAmount);
  }

  render() {
    const { theme, node, showChildLink, editInPlace } = this.props;
    const { currency, showCents } = this.props.budget;
    const currencySymbol = getSymbolFromCurrency(currency || 'N/A') || '';
    const isIncome = node.negate > 0;
    const actualAmount = !Number.isNaN(node.actualAmount) ? node.actualAmount * node.negate : undefined;
    const targetAmount = !Number.isNaN(node.targetAmount) ? node.targetAmount * node.negate : undefined;
    const rolloverAmount = !Number.isNaN(node.rolloverAmount) && !Number.isNaN(node.negate) ? node.rolloverAmount * node.negate : 0;
    const targetTotalAmount = !Number.isNaN(targetAmount) ? targetAmount + rolloverAmount : undefined;
    const amountLeft = !Number.isNaN(targetTotalAmount) && !Number.isNaN(actualAmount) ? this.getAmountLeft(showCents, targetTotalAmount, actualAmount) : undefined;
    const showLabelAmount = !editInPlace || node.type === BudgetNodeTypes.GROUP || node.type === BudgetNodeTypes.CATEGORY_ROLL_UP;
    const baseLink = '/budgets';
    let navLink = `${baseLink}?displayBudget=${this.props.budget.id}`;
    if (showChildLink) {
      navLink = `${navLink}&displayNode=${node.key}`;
    }
    let progressLabel;
    const value = !Number.isNaN(actualAmount) ? actualAmount : null;
    const total = !Number.isNaN(targetAmount) ? targetAmount : null;
    if (value * total > 0) {
      progressLabel = showCents ? `${Math.round((actualAmount / targetAmount) * 100)}%` : `${Math.round((Math.round(actualAmount) / (targetAmount)) * 100)}%`;
    } else {
      progressLabel = '';
    }
    return (
      <div
        role="presentation"
        style={{
          ...this.props.style,
          width: '100%',
          cursor: 'pointer',
          position: 'relative',
        }}
        key={node.key}
        onClick={() => {
          if (showChildLink) {
            this.props.history.replace(navLink);
          } else if (this.props.isParent && node.children && node.children.size > 0) {
            this.props.history.replace(baseLink);
          }
        }}
      >
        <Dump obj={node} />
        <Typography
          id={`budgets-heading-${this.props.index}`}
          variant="body2"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: inputFontSize,
            fontWeight: 500,
          }}
        >
          {`${node.displayLabel.toUpperCase()}`}
          {node.children && node.children.size > 0 &&
          <KeyboardArrowRight id={`budgets-drill-down-arrow-${this.props.index}`} />}
        </Typography>

        <span
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <span>
            <Typography
              id={`budgets-actual-amount-heading-${this.props.index}`}
              variant="body2"
              style={{ display: 'inline-block', fontSize: inputFontSize, transform: showLabelAmount ? 'none' : 'translateY(-3px)' }}
            >
              {`${!Number.isNaN(actualAmount) && this.props.formatAmount(actualAmount, currency, !showCents)} of`}
              &nbsp;
            </Typography>

            {showLabelAmount ?
              <Typography
                id={`budgets-target-ro-amount-heading-${this.props.index}`}
                variant="body2"
                style={{ display: 'inline', fontSize: inputFontSize }}
              >
                {this.props.formatAmount(targetAmount, currency, !showCents)}
              </Typography>
              :
              <FormControl>
                <Input
                  id={`budgets-target-amount-heading-${this.props.index}`}
                  className={this.props.classes.input}
                  inputProps={{ 'aria-label': 'budget target amount' }}
                  value={this.state.node && this.state.node.key === node.key && !Number.isNaN(this.state.newTargetAmount) ?
                    this.state.newTargetAmount
                    :
                    (!Number.isNaN(targetAmount) && targetAmount.toFixed(showCents ? 2 : 0))}
                  onChange={(event) => this.setState({ node, userTargetAmount: event.target.value })}
                  onKeyUp={(event) => {
                    event.key === 'Enter' && this.updateBudgetItem();
                    event.stopPropagation();
                  }}
                  onClick={(event) => event.stopPropagation()}
                  type="number"
                  startAdornment={
                    <InputAdornment
                      position="start"
                      classes={{
                        root: this.props.classes.inputAdornment,
                      }}
                      disableTypography
                    >
                      {currencySymbol}
                    </InputAdornment>
                  }
                  onBlur={this.updateBudgetItem}
                />
              </FormControl>}

            {!Number.isNaN(rolloverAmount) && rolloverAmount !== 0 &&
            <Typography
              id={`budgets-rollover-amount-${this.props.index}`}
              variant="body2"
              style={{ display: 'inline' }}
            >
              {` ${rolloverAmount >= 0 ? '+' : '-'} ${this.props.formatAmount(Math.abs(rolloverAmount), currency, !showCents)} (rollover)`}
            </Typography>}
          </span>

          <Typography
            id={`budgets-amount-over-or-left-${this.props.index}`}
            variant="body2"
            style={{ display: 'inline', fontSize: inputFontSize }}
          >
            {!Number.isNaN(amountLeft) && amountLeft * (targetAmount || +1) < 0 ?
              `${this.props.formatAmount(Math.abs(amountLeft), currency, !showCents)} Over` :
              `${this.props.formatAmount(Math.abs(amountLeft), currency, !showCents)} Left`}
          </Typography>
        </span>

        <QProgressBar
          id={`budgets-percentage-${this.props.index}`}
          value={value}
          total={total}
          color={this.getProgressBarColor(isIncome, targetAmount, amountLeft, theme)}
          height={20}
          noShadow
          noRoundCorners
          backgroundColor={theme.palette.greyScaleDeprecated[6]}
          text={progressLabel}
        />

      </div>
    );
  }
}

function mapStateToProps(_state, _props) {
  /*
  const query = props.location && props.location.search && queryString.parse(props.location.search);
  const queryBudgetId = query && query.displayBudget ? query.displayBudget : null;
  const queryBudgetNodeKey = query && query.displayNode ? query.displayNode : null;
  return ({ queryBudgetId, queryBudgetNodeKey});
  */
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateBudgetItemAction: (budgetItem) =>
      dispatch(budgetItemsActions.updateBudgetItem(budgetItem, { undo: { userMessage: 'Budget target updated.' } })),  
    dispatchUpdateBudgetAction: (data) => dispatch(budgetsActions.updateBudgetAction(data)),
  };
}

BudgetNode.propTypes = {
  formatAmount: PropTypes.func,
  editInPlace: PropTypes.bool,
  budget: PropTypes.object,
  node: PropTypes.object,
  history: PropTypes.object,
  dispatchUpdateBudgetItemAction: PropTypes.func,
  theme: PropTypes.object,
  style: PropTypes.object,
  showChildLink: PropTypes.bool,
  index: PropTypes.number,
  classes: PropTypes.object,
  isParent: PropTypes.bool,
  dispatchUpdateBudgetAction: PropTypes.func,
};

export default compose(
  withStyles(styles, { withTheme: true }),
  QCurrency(),
)(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BudgetNode)
  )
);
