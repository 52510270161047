// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import compose from 'utils/compose';

import { tracker } from '@quicken-com/react.utils.core';
import { authActions, authSelectors } from '@quicken-com/react.flux.auth';
import type { AuthStore } from '@quicken-com/react.flux.auth'; // TODO: setup flow support for bit.dev

import IconButton from '@mui/material/IconButton';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleRounded';
import withStyles from '@mui/styles/withStyles';

import QButton from 'components/QButton';
import * as npsActions from 'data/nps/npsActions';
import StdDialog from 'components/Dialogs/StdDialog';
import { removeDialog } from 'data/rootUi/actions';
import { isAcme } from 'isAcme';

export const styles = (theme) => ({
  dialogBackground: {
    backgroundColor: isAcme ? theme.palette.background.default : 'unset',
  },
});

type Props = {
  dispatchPostNPSSurveyResultAction: (any) => void,
  onClose: (Event) => void,
  classes: any,
  dispatchUpdateAuth: (AuthStore) => void,
  isWebFirstDataset: boolean,
  title: string,
  removeDialog: (any) => void,
  dialogId: string,
}

type State = {
  feedback: any,
  score?: any,
  hoverScore?: any,
  thankYou: boolean,
}

class NPSSurveyDialog extends PureComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      feedback: '',
      score: undefined,
      hoverScore: undefined,
      thankYou: false,
    };
  }

  componentDidMount() {
    tracker.track(tracker.events.nps, { status: 'start' });
  }

  handleClose = (event: Event, reason: string) => {

    let surveyResult;
    if (!this.state.thankYou) {
      this.props.dispatchUpdateAuth({ surveyRequested: false }); // optimistic update

      tracker.track(tracker.events.nps, {
        status: 'response',
        feedback: this.state.feedback.length > 0 ? this.state.feedback : undefined,
        rating: this.state.score,
      });

      if (reason === 'OK_BUTTON') {
        surveyResult = 'COMPLETED';
      } else if (!this.state.score) {
        surveyResult = 'ABANDONED';
      } else {
        surveyResult = 'CANCELLED';
      }
      this.props.dispatchPostNPSSurveyResultAction({ surveyResult });
    }

    if (surveyResult === 'COMPLETED') {
      this.setState({ thankYou: true });
      setTimeout(() => this.props.onClose?.(event), 2500);
    } else {
      this.props.onClose?.(event);
    }
    this.props.removeDialog(this.props.dialogId);
  };

  renderThankYou() {
    return (
      <DialogContent>
        <CheckCircleOutlineIcon
          color="primary"
          style={{
            width: 64,
            height: 64,
            marginTop: 24,
            marginBottom: 24,
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
        <Typography
          variant="h6"
          align="center"
          style={{
            marginBottom: 24,
          }}
        >
          Thank you.
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
        >
          Your feedback helps us discover new opportunities to improve {isAcme ? 'Simplifi by Quicken' : 'Quicken'} and make sure you have the best possible
          experience.
        </Typography>

      </DialogContent>
    );
  }

  renderNPS() {
    const { classes } = this.props;
    let message;
    if (isAcme) {
      message = 'How likely are you to recommend Simplifi by Quicken to a friend or colleague?';
    } else if (this.props.isWebFirstDataset) {
      message = 'How likely are you to recommend Quicken to a friend or colleague?'; // Quicken Web First
    } else {
      message = 'How likely are you to recommend Quicken on the Web to a friend or colleague who uses Quicken?'; // Quicken
    }
    return (
      <StdDialog
        open
        onClose={this.handleClose}
        title={this.props.title}
        sharedcomponentid={'DIALOG_TYPE_NPS_SURVEY'}
      >
        <DialogTitle
          className={classes.dialogBackground}
        >
          {message}
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'nowrap',
            }}
          >
            { [...Array(11).keys()].map((score) => (
              <IconButton
                style={{
                  border: score === this.state.score ? 'solid 2px' : 'solid 1px',
                  width: 32,
                  height: 32,
                  fontSize: 14,
                }}
                key={score}
                id={`nps-score-${score}`}
                aria-label={score}
                color={score === this.state.hoverScore || score === this.state.score ? 'primary' : 'default'}
                onClick={() => this.setState({ score })}
                onMouseOver={() => this.setState({ hoverScore: score })}
                onFocus={() => this.setState({ hoverScore: score })}
                onMouseOut={() => this.setState({ hoverScore: undefined })}
                onBlur={() => this.setState({ hoverScore: undefined })}
                size="large"
              >
                <div style={{ position: 'absolute' }}>
                  {score}
                </div>
              </IconButton>
            )) }
          </div>

          <Typography
            variant="caption"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'nowrap',
              marginTop: 8,
            }}
          >
            <span>
              Not likely at all
            </span>
            <span>
              Extremely likely
            </span>
          </Typography>

          <br />

          {this.state.score !== undefined &&
          <TextField
            autoFocus
            label="What is the primary reason for your score?"
            id="nps-feedback"
            aria-label="What is the primary reason for your score?"
            multiline
            value={this.state.feedback}
            onChange={(event) => this.setState({ feedback: event.target.value })}
            margin="normal"
            fullWidth
            inputProps={{ maxLength: 255 }}
            helperText={`You can enter up to 255 characters (${255 - this.state.feedback.length} remaining).`}
            rows="4"
            maxRows="4"
            variant="outlined"
          />}

        </DialogContent>

        {this.state.score !== undefined &&
        <DialogActions>
          <QButton
            id="nps-send"
            aria-label="Send"
            onClick={(event) => this.handleClose(event, 'OK_BUTTON')}
          >
            Submit
          </QButton>
        </DialogActions>}

      </StdDialog>
    );
  }

  render() {
    return this.state.thankYou ? this.renderThankYou() : this.renderNPS();
  }
}

function mapStateToProps(state) {
  return ({
    authSession: authSelectors.getAuthSession(state),
    isWebFirstDataset: authSelectors.getIsWebfirstDataset(state),
  });
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateAuth: (data) => dispatch(authActions.updateAuth(data)),
    dispatchPostNPSSurveyResultAction: (data) => dispatch(npsActions.postNPSSurveyResultAction(data)),
    removeDialog: (props) => dispatch(removeDialog(props)),
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(NPSSurveyDialog);
