import React from 'react';
import { DateTime } from 'luxon';

import Box from '@mui/material/Box';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';

import { dispatchSimpleNotification } from 'data/notifications/notificationsUtils';

class VersionDisplay extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showVersion: false };
    const date = DateTime.fromRFC2822(process.env.COMMIT_TIME).toLocaleString(DateTime.DATETIME_SHORT);
    this.version = `Version: ${process.env.APP_VERSION} (${process.env.BUILD_NUMBER}/${process.env.COMMIT} - ${date})`;
  }

  componentDidMount() {
    window.addEventListener('keydown', this.downHandler);
    window.addEventListener('keyup', this.upHandler);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.downHandler);
    window.removeEventListener('keyup', this.upHandler);
  }
  downHandler = (e) => {
    if (e.altKey) { // can't use 'control' key because of MAC's control + click
      this.setState({ showVersion: true });
    }
  }
  upHandler = () => {
    this.setState({ showVersion: false });
  };

  render() {
    return (this.state.showVersion ?
      <Box
        position="fixed"
        right={0}
        bottom={0}
        p={1}
        color="black"
        zIndex="13000"
        onClick={() => {
          navigator?.clipboard?.writeText(this.version);
          dispatchSimpleNotification('Version copied to clipboard');
        }}
      >
        {this.version}
        &nbsp;<FileCopyOutlinedIcon fontSize="inherit" />
      </Box>
      :
      null
    );
  }
}

export default VersionDisplay;
