import React, { PureComponent } from 'react';

import { getLogger } from '@quicken-com/react.utils.core';

import Debug from 'components/Debug';

const logger = getLogger();

export default class QPureComponent extends PureComponent {

  constructor(props) {
    super(props);
    logger.debug(`${this.constructor.name}: constructor`);
  }

  UNSAFE_componentWillMount() {
    logger.debug(`${this.constructor.name}: UNSAFE_componentWillMount`);
  }

  componentDidMount() {
    logger.debug(`${this.constructor.name}: componentDidMount`);
  }

  componentDidUpdate(prevProps, prevState, _snapshot) {
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////
////////////////////////////////////////////////////////////////
///////////////////////////////////////
////////////////////////////////
//////////////////////////////
///////
///////
////////////////////////////
////////////////////////////////////////////////////////////////
///////////////////////////////////////
////////////////////////////////
///////////////////////////////
///////
///////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////
  }

  componentWillUnmount() {
    logger.debug(`${this.constructor.name}: componentWillUnmount`);
  }

  render(dump) {
    logger.debug(`${this.constructor.name}: render`);
    let component = null;
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////
/////////////
    return component;
  }

}

