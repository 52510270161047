
import { Record, Map as ImmutableMap } from 'immutable';
import type { RecordFactory, RecordOf } from 'immutable';

import type { QcsSyncResourceStoreProps } from '@quicken-com/react.flux.core/resourceStoreTypes';



// ////////////////////////////////////////////////////////////////////////////////////////////////
// Biller Account - Base Biller Account Properties
// ////////////////////////////////////////////////////////////////////////////////////////////////

type BillerAccountProps = {
  id: string,
  createdAt: ?string,
  modifiedAt: ?string,

  providerBillerAccountID: ?string,

  billerLoginID: string,
  name: string,
  nickName: string,

  isHidden: boolean,
  isPayable: boolean,
}
export type BillerAccount = RecordOf<BillerAccountProps>;


export const mkBillerAccount: RecordFactory<BillerAccountProps> =

  Record({
    id: undefined,
    createdAt: undefined,
    modifiedAt: undefined,

    providerBillerAccountID: undefined,

    billerLoginID: undefined,
    name: undefined,
    nickName: undefined,

    isHidden: false,
    isPayable: false,
  });

// ================================================================================================
// BillerAccountsStore - Biller Accounts Store Properties & Types
// ================================================================================================

type BillerAccountStoreProps = QcsSyncResourceStoreProps & {
  isRefreshing: boolean,  // true when accounts are refreshing on server
};
export type BillerAccountStore = RecordOf<BillerAccountStoreProps>;

export const mkBillerAccountStore: RecordFactory<BillerAccountStoreProps> =
  Record({
    resourcesById: ImmutableMap(),
    lastSyncDate: undefined,

    loadPending: true,
    isLoading: false,

    error: null,

    isRefreshing: false,
  });
