// core
import React from 'react';
import { List as ImmutableList } from 'immutable';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import compose from 'utils/compose';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { isAcme } from 'isAcme';

// DATA
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';
import { tagsActions, tagsTypes, tagsSelectors } from '@quicken-com/react.flux.tags';

// MUI
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';

// CUSTOM
import QIconButton from 'components/QIconButton';
import NestedQMenu from 'components/NestedQMenu';
import QTypography from 'components/MUIWrappers/QTypography';
import ChippedInput from './ChippedInput';


export function findTagName(tags, id) {
  const tag = tags.find((x) => String(x.id) === String(id));
  return tag ? tag.name : '';
}

export function findTagId(tags, name) {
  const tag = tags.find((x) => x.name.toLowerCase() === name.toLowerCase());
  return tag ? tag.id : '0';
}


export function makeTagNameArray(tags, txnTags) {
  let txString = '';
  if (txnTags) {
    txString = txnTags.reduce((res, item) => {
      //
      // if there is no name match yet (no id from QCS yet on create), then
      // preserve the name that was given
      //
      const name = findTagName(tags, item.id);
      return name.length > 0 ? `${res}${name},` : `${res}${item.name},`;
    }, '');
  }

  return txString;
}

const styles = (_theme) => ({
  root: {
    flexGrow: 1,
  },
  fullWidth: {
    width: '100%',
  },
  outlinedMargin: {
    padding: '14.5px 14px',
  },
  outlineDense: {
    padding: isAcme ? '10.5px 14px' : '8.5px 14px',
  },
  inputLabel: {
    height: 27,
  },
});

class TagsField extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      query: null,
      tagAwaitingCreation: null,
    };
    this.currentHover = null;
  }

  componentDidUpdate(prevProps) {
    const { allTags, value } = this.props;
    const { tagAwaitingCreation } = this.state;

    const tagCreated = tagAwaitingCreation ? allTags.find((t) => t.id === tagAwaitingCreation.id || t.name === tagAwaitingCreation.name) : null;
    if (allTags !== prevProps.allTags && tagCreated) {
      const updatedTagArray = value?.toArray() || [];
      updatedTagArray.push(tagsTypes.mkTag({ ...tagCreated.toJS() }));
      this.setState({ tagAwaitingCreation: null }); /* eslint-disable-line react/no-did-update-set-state */
      this.props.onChange(ImmutableList(updatedTagArray));
    }
  }

  createNewTag = (tagName, clientId) => {
    const name = tagName?.trim();
    this.currentHover = null;
    if (assert(name, `valid tag name required "${tagName}" -> "${name}"`)) {
      const tagToCreate = tagsTypes.mkTag({
        clientId,
        name,
      });
      this.props.createTag(tagToCreate);
      this.setState({ tagAwaitingCreation: tagToCreate });
    }
  };

  optionSort = ({ label: firstName }, { label: secondName }) => {
    if (firstName.toLowerCase() < secondName.toLowerCase()) return -1;
    if (firstName.toLowerCase() > secondName.toLowerCase()) return 1;
    return 0;
  };

  createMenuOptions = (allTags, valueTags) => {
    const menuOptions = [];

    allTags.keySeq().forEach((tagId) => {
      let hasTag = false;
      if (valueTags) {
        valueTags.forEach((tag) => {
          if (tag.id === tagId) {
            // if the ids match
            hasTag = true;
          } else if (tag.name && tag.name !== 'unknown' && findTagName(allTags, tagId) === tag.name) {
            // if the tag has a name and it matches in the list
            hasTag = true;
          }
        });
      }
      if (!hasTag) {
        const tagName = findTagName(allTags, tagId);
        menuOptions.push({
          label: tagName,
          value: { tagInfo: { id: tagId, name: tagName }, name: tagName },
        });
      }
    });

    if (this.state.query) {
      const filteredMenuOptions = menuOptions.map((option) => {
        const matchIndex = option.label.toLowerCase().indexOf(this.state.query.toLowerCase());
        if (matchIndex === -1) return null;
        return {
          label: option.label,
          value: option.value,
          customRender: this.buildMatchedLabel(option.label, matchIndex),
        };
      }).filter((op) => op !== null).sort(this.optionSort);

      if (!filteredMenuOptions.find((option) => option.label.toLowerCase() === this.state.query.toLowerCase())
        && findTagId(allTags, this.state.query.toLowerCase()) === '0') {
        const { query } = this.state;
        if (query?.trim?.()) {
          filteredMenuOptions.push({
            label: query,
            value: { tagInfo: { id: null, name: query }, name: query, clientId: uuidv4() },
            customRender: this.buildMatchedLabel(`Create "${query}"`, 8),
          });
        }
      }

      // sorted before adding the create option
      return filteredMenuOptions;
    }
    return menuOptions.sort(this.optionSort);
  };

  buildMatchedLabel = (label, indexOfMatch) => (
    <QTypography>
      {label.slice(0, indexOfMatch)}
      <span style={{ fontWeight: this.props.theme.typography.fontWeightMedium }}>{label.slice(indexOfMatch, indexOfMatch + this.state.query.length)}</span>
      {label.slice(indexOfMatch + this.state.query.length)}
    </QTypography>
  );

  handleChange = (value) => {
    this.setState({ query: value !== '' || value !== ' ' ? value : null });
  };

  handleKeyDown = (event) => {
    if (this.props.tagsCharFilter && this.props.tagsCharFilter.includes(event.key)) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (event.key === 'Enter' && (this.state.showMenu || this.state.query)) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
    if (event.key === 'Tab' && this.currentHover) {
      this.menuOnChange(this.currentHover.value);
    } else if (event.key === 'ArrowDown' && !this.state.showMenu) {
      this.setState({ showMenu: true });
      event.stopPropagation();
      event.preventDefault();
    }
    if (this.props.onKeyDown) {
      this.props.onKeyDown(event);
    }
  };

  handleCurrentHover = (current) => {
    if (this.currentHover !== current) this.currentHover = current;
  };

  handleTagRemove = (label, event) => {
    const { allTags, value } = this.props;
    event && event.stopPropagation();

    const tagId = findTagId(allTags, label);
    const updatedTagArray = value?.filter((v) => v.id !== tagId && v.name !== label) || [];

    this.props.onChange(ImmutableList(updatedTagArray));
  };

  dropDownClick = () => {
    this.setState({ showMenu: !this.state.showMenu, query: null });
    // if (this.inputRef) setTimeout(() => this.inputRef?.focus?.(), 50); // TODO: code does nothing - inputRef doesn't contain focus() anymore
  };

  menuOnChange = (tag) => {
    const { value } = this.props;

    if (tag.tagInfo.id === null) {
      this.createNewTag(tag.name, tag.clientId);
    } else {
      let updatedTagArray;
      if (Array.isArray(value)) {
        updatedTagArray = value.slice();
      } else {
        updatedTagArray = value?.toArray?.() || [];
      }

      updatedTagArray.push(tagsTypes.mkTag({
        id: tag.tagInfo.id,
        name: tag.name,
        clientId: tag.clientId || null,
      }));

      this.props.onChange(ImmutableList(updatedTagArray));
    }
    this.setState({ showMenu: false, query: null });

  };

  handleDelete = (tagToDelete) => {
    this.handleTagRemove(tagToDelete);
  }

  getTagChips = () => {
    const definiteTags = this.props.value?.map((tag) => tag.name && tag.name !== 'unknown' ? tag.name : findTagName(this.props.allTags, tag.id)) || [];
    return this.state.tagAwaitingCreation ? definiteTags.concat([this.state.tagAwaitingCreation.name]) : definiteTags;
  }

  render() {

    const { classes, autoFocus, name, margin, label, InputProps, fullWidth, placeholder, disableUnderline,
      fieldId, id, value, allTags, bulkEdit, theme, textFieldVariant, textFieldProps, className } = this.props;
    const { query, showMenu } = this.state;

    const menuOpen = showMenu || query !== null;

    const menuIcon = <QIconButton
      style={{ color: theme.palette.grey.level6, cursor: 'pointer', fontSize: '23px' }}
      aria-label="Open Tag List"
      focusRipple
      IconComponent={this.state.showMenu || this.state.query !== null ? ExpandLessRoundedIcon : ExpandMoreRoundedIcon}
      onClick={this.dropDownClick}
      size="small-no-padding"
      TooltipProps={{ enterDelay: 10 }}
      tabIndex={-1}
    />;

    let inputValue = value;
    if (inputValue?.size === 0) inputValue = null;
    if (!inputValue) inputValue = query ? ' ' : '';

    return (
      <div
        className={classNames(fullWidth ? classes.fullWidth : '', classes.root, className)}
        id={id}
        name={name}
        ref={(x) => { this.fieldRef = x; }}
        sharedcomponentid={'TagsField'}
      >
        <TextField
          name="tags-field"
          id={fieldId}
          label={label}
          placeholder={placeholder}
          margin={margin}
          variant={textFieldVariant}
          className={classes.textField}
          fullWidth={fullWidth}
          value={inputValue}
          autoFocus={autoFocus}
          autoComplete="off"
          {...textFieldProps}
          onKeyDown={this.handleKeyDown}
          onClick={this.dropDownClick}
          InputLabelProps={{
            classes: {
              root: classes.inputLabel,
            },
            // shrink: Boolean(value),
          }}
          InputProps={{
            ...InputProps,
            inputComponent: ChippedInput,
            ...(textFieldVariant !== 'outlined' && disableUnderline && { disableUnderline: true }),
            classes: {
              ...InputProps?.classes,
              root: textFieldVariant === 'outlined' && (margin === 'dense' ? classes.outlineDense : classes.outlinedMargin),
            },
            name: 'tags-field',
            inputProps: { // these are props being passed down to ChippedInput
              bulkEdit,
              textFieldVariant,
              margin,
              id: 'tag-input',
              maxLength: 32,
              chips: this.getTagChips(),
              onChange: this.handleChange,
              keyDelete: true,
              onDelete: this.handleDelete,
              value: inputValue,
              editable: this.props.editable,
            },
            endAdornment: (
              <InputAdornment position="end">
                {menuIcon}
              </InputAdornment>
            ),
          }}
        />
        <NestedQMenu
          anchorEl={this.fieldRef}
          name="tag-menu"
          options={this.createMenuOptions(allTags, value)}
          onChange={this.menuOnChange}
          open={menuOpen}
          arrowClick={this.arrowClick}
          onClose={() => {
            this.setState({ showMenu: false, query: null });
          }}
          currentHover={this.handleCurrentHover}
        />
      </div>
    );
  }
}

TagsField.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  autoFocus: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  fieldId: PropTypes.string,
  allTags: PropTypes.object,
  createTag: PropTypes.func,
  label: PropTypes.string,
  margin: PropTypes.string,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  InputProps: PropTypes.object,
  bulkEdit: PropTypes.bool,
  theme: PropTypes.object,
  tagsCharFilter: PropTypes.object,
  textFieldVariant: PropTypes.string,
  textFieldProps: PropTypes.object,
  className: PropTypes.string,
  editable: PropTypes.bool,
};

TagsField.defaultProps = {
  fullWidth: true,
  placeholder: 'Tags',
  bulkEdit: false,
  textFieldVariant: 'standard',
  editable: true,
};

function mapStateToProps(state) {
  return {
    allTags: tagsSelectors.getTags(state),
    tagsCharFilter: featureFlagsSelectors.getFeatureFlags(state).get('tagsCharFilter'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createTag: (data) => dispatch(tagsActions.createTag(data)),
  };
}

// These are prop injectors
export default compose(
  withStyles(styles, { withTheme: true }),  // creates this.props.classes
)(connect(mapStateToProps, mapDispatchToProps)(TagsField));

