import { DateTime } from 'luxon';

import { BUDGET_ANNUAL_COPY_OPTIONS } from 'containers/BudgetsV2/constants';

export const getBudgetAmountOfCurrentMonth = (budgetCategoryData, month = DateTime.now()) => {
  const { budgetItems, negate } = budgetCategoryData;
  const currentMonth = month.toFormat('MM-yyyy');
  const updateDataIndex = budgetItems.findIndex((monthlyAmountItem) => DateTime.fromISO(monthlyAmountItem.startDate).toFormat('MM-yyyy') === currentMonth);
  return negate * budgetItems[updateDataIndex].amount || 0;
};

export const updateMonthlyBudgetAmount = (period, budgetCategoryData, budgetAmountToUpdate, dateFromApplyOptions = DateTime.now()) => {
  const budgetCategoryDataClone = { ...budgetCategoryData };
  const { budgetItems } = budgetCategoryDataClone;

  switch (period) {
    case BUDGET_ANNUAL_COPY_OPTIONS.currentMonth: {
      const currentMonth = dateFromApplyOptions.toFormat('MM-yyyy');
      const updateDataIndex = budgetItems.findIndex((monthlyAmountItem) =>
        DateTime.fromISO(monthlyAmountItem.startDate).toFormat('MM-yyyy') === currentMonth);
      if (updateDataIndex >= 0) {
        budgetItems[updateDataIndex].amount = budgetItems[updateDataIndex].isIncome ? Number(budgetAmountToUpdate) : Number(budgetAmountToUpdate) * -1;
      }
      break;
    }
    case BUDGET_ANNUAL_COPY_OPTIONS.restOfYear: {
      const date = dateFromApplyOptions;
      budgetItems.forEach((monthlyAmountItem, index) => {
        if (DateTime.fromISO(monthlyAmountItem.startDate) >= date) {
          budgetItems[index].amount = budgetItems[index].isIncome ? Number(budgetAmountToUpdate) : Number(budgetAmountToUpdate) * -1;
        }
      });
      break;
    }
    case BUDGET_ANNUAL_COPY_OPTIONS.pastMonths: {
      const date = dateFromApplyOptions;
      budgetItems.forEach((monthlyAmountItem, index) => {
        if (DateTime.fromISO(monthlyAmountItem.startDate) < date) {
          budgetItems[index].amount = budgetItems[index].isIncome ? Number(budgetAmountToUpdate) : Number(budgetAmountToUpdate) * -1;
        }
      });
      break;
    }

    default: {
      budgetItems.forEach((_, index) => {
        budgetItems[index].amount = budgetItems[index].isIncome ? Number(budgetAmountToUpdate) : Number(budgetAmountToUpdate) * -1;
      });
      break;
    }
  }
  budgetCategoryDataClone.budgetItems = budgetItems;

  return budgetCategoryDataClone;
};

export const handleMonthlyItemsUpdate = (amount, budgetItemId, budgetCategoryData) => {
  const budgetCategoryDataClone = { ...budgetCategoryData };
  const { budgetItems } = budgetCategoryDataClone;
  const updateItemIndex = budgetItems.findIndex((budgetItem) => budgetItem.id === budgetItemId);
  budgetItems[updateItemIndex].amount = budgetItems[updateItemIndex].isIncome ? Number(amount) : Number(amount) * -1;
  budgetCategoryDataClone.budgetItems = budgetItems;
  return budgetCategoryDataClone;
};

export const processDataToUpdateBudgetItems = (monthlyBudgetData, budgetItems) => {
  const budgetItemsToUpdate = [];
  budgetItems.forEach((monthlyAmountItem) => {
    const updateItemIndex = monthlyBudgetData.findIndex((budgetItem) => budgetItem.id === monthlyAmountItem.id);
    if (updateItemIndex >= 0) {
      const budgetItemToUpdate = monthlyBudgetData[updateItemIndex];
      if (budgetItemToUpdate.amount !== monthlyAmountItem.amount) {
        budgetItemsToUpdate.push(budgetItemToUpdate);
      }
    }
  });
  return budgetItemsToUpdate;
};

export const generateApplyOptions = (month, year) => [
  {
    label: `to ${DateTime.fromISO(month).toFormat('MMM')} only`,
    value: BUDGET_ANNUAL_COPY_OPTIONS.currentMonth,
  },
  {
    label: `forward to end of ${year.year}`,
    value: BUDGET_ANNUAL_COPY_OPTIONS.restOfYear,
  },
  {
    label: `to all of ${year.year}`,
    value: BUDGET_ANNUAL_COPY_OPTIONS.wholeYear,
  },
];
