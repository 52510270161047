import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import { calWeekDaysHeaderStyles } from '../../styles';

const useStyles = makeStyles(calWeekDaysHeaderStyles);

const CalWeekDaysHeader = (props) => {
  const classes = useStyles();

  return (
    <header className={classes.weekDays}>
      {props.days.map((weekday) => (
        <strong key={weekday} className={classes.dayLabel}>{weekday}</strong>
      ))}
    </header>
  );
};

CalWeekDaysHeader.propTypes = {
  days: PropTypes.array,
};

export default CalWeekDaysHeader;
