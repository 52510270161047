// @flow
import { createSelector } from 'reselect';
import { List } from 'immutable';

import { accountsSelectors } from '@quicken-com/react.flux.accounts';

import { isErrorType } from 'data/institutionLogins/utils';

import { REDUCER_KEY } from './reducer';
import type { InstitutionLoginsStore } from './types';

// const log = getLogger('institutionLogins/selectors');

export const getInstitutionLoginsStore = (state: any): InstitutionLoginsStore => state[REDUCER_KEY];

export const getInstitutionLoginsById = (state: any) => getInstitutionLoginsStore(state).resourcesById;
export const getLoadPending = (state: any) => getInstitutionLoginsStore(state).loadPending;
export const getLastSyncDate = (state: any) => getInstitutionLoginsStore(state).lastSyncDate;

export const getInstitutionLoginForId = (state: any, id: string) => getInstitutionLoginsById(state).get(id);

export const getIsRefreshingAll = (state: any) => getInstitutionLoginsStore(state).isRefreshingAll;
export const getIdsRefreshing = (state: any) => getInstitutionLoginsStore(state).idsRefreshing;

export const getIsRefreshingSome = (state: any) => getIdsRefreshing(state).size > 0;
export const getIsRefreshing = (state: any) => getIsRefreshingAll(state) || getIdsRefreshing(state).size > 0;

export const getIdsRefreshingWithUserActionsPending = (state: any) =>
  getInstitutionLoginsStore(state).idsRefreshingWithUserActionsPending;

export const getIdsRefreshingWithCancelledUserActions = (state: any) =>
  getInstitutionLoginsStore(state).idsRefreshingWithCancelledUserActions;

export const getCredentialBlobsForRefreshingLogins = (state: any) =>
  getInstitutionLoginsStore(state).credentialBlobsForRefreshingLogins;

export const getCredentialsForRefreshingLogins = (state: any) =>
  getInstitutionLoginsStore(state).credentialsForRefreshingLogins;

export const hasUserActionBeenQueued = (state: any, institutionLoginId: string) => (
  getIdsRefreshingWithUserActionsPending(state).has(institutionLoginId) ||
  getIdsRefreshingWithCancelledUserActions(state).has(institutionLoginId)
);

export const isUserActionPending = createSelector(
  getIdsRefreshingWithUserActionsPending,
  (_, props) => props ? props.institutionLoginIdId : null,
  (idsRefreshingWithUserActionsPending, institutionLoginId) => (
    institutionLoginId == null ?
      idsRefreshingWithUserActionsPending.size > 0 :
      idsRefreshingWithUserActionsPending.has(institutionLoginId)
  )
);
export const wasUserActionCancelled = (state: any, institutionLoginId: string) =>
  getIdsRefreshingWithCancelledUserActions(state).has(institutionLoginId);

export const getInstitutionLoginsByName = createSelector(
  getInstitutionLoginsById,
  (institutionLoginsById) => !institutionLoginsById ? null :
    institutionLoginsById.sort((a, b) => (
      a.name.localeCompare(
        b.name,
        undefined,
        { numeric: true, sensitivity: 'base' }
      )
    ))
);

export const isInstitutionLoginAmbiguous = createSelector(
  getInstitutionLoginsById,
  (_, props) => props.institutionLoginId,
  (institutionLoginsById, institutionLoginId) => {
    if (!institutionLoginId) {
      return undefined;
    }
    const institutionLogin = institutionLoginsById.get(institutionLoginId);
    const ambiguousInstitutionLogin = institutionLoginsById.find((iInstitutionLogin) =>
      iInstitutionLogin.institutionId === institutionLogin.institutionId && iInstitutionLogin.id !== institutionLogin.id);
    return ambiguousInstitutionLogin !== undefined;
  }
);

export const getErrorCount = createSelector(
  (state, accountsByInstitutionLoginId) => accountsByInstitutionLoginId,
  getInstitutionLoginsByName,
  (accountsByInstitutionLoginId, institutionLoginsByName) => {
    let count = 0;
    let loginsErrorCount = 0;
    let accountsErrorCount = 0;
    let institutionLogin = {};
    institutionLoginsByName && institutionLoginsByName.forEach((login) => {
      const accounts = accountsByInstitutionLoginId?.get(login.id);
      if (!accounts) {
        // if we don't have any accounts for login, then ingore login
        return;
      }
      const loginAggStatus = login.aggregators?.get(0).aggStatus;
      if (isErrorType(loginAggStatus)) {
        loginsErrorCount += 1;
        institutionLogin = login;
      } else {
        accounts?.forEach((account) => {
          const accountAggStatus = account.aggregators?.get(0).aggStatus;
          if (accountAggStatus && isErrorType(accountAggStatus)) {
            accountsErrorCount += 1;
            institutionLogin = login;
          }
        });
      }
      count = loginsErrorCount + accountsErrorCount;
    });
    return { count, institutionLogin };
  }
);

export const getTrackingAggregator = createSelector(
  getInstitutionLoginForId,
  (institutionLogin) => {
    if (institutionLogin.channel) {
      if (institutionLogin.channel.startsWith('FDS')) {
        return 'INTUIT_FDS';
      }
      if (institutionLogin.channel.startsWith('FDP')) {
        return 'INTUIT_FDP';
      }
      if (institutionLogin.channel.startsWith('FINICITY')) {
        return 'FINICITY';
      }
      if (institutionLogin.channel.startsWith('PLAID')) {
        return 'PLAID';
      }
    }
    return 'UNKNOWN';
  },
);

// This selector returns all "pure" manual accounts. A pure manual is an account that either does not belong to an
// institution-login or belongs to an "unconnected" institution-login. While QCS supports accounts being grouped into
// unconnected-logins, it is only used by Windows to group all manual accounts into a single unconnectted
// intitution-login (this isn't required but is a holdover from Windows syncing to Mint).
//
export const getPureManualAccounts = createSelector(
  getInstitutionLoginsById,
  accountsSelectors.getAccountsByInstitutionLoginId,
  (institutionLoginsById, accountsByInstitutionId) => {
    const pureManualAccounts = accountsByInstitutionId.reduce((list, accounts, institutionLoginId) => {
      const institutionLogin = institutionLoginsById.get(institutionLoginId);
      if (Number(institutionLoginId) === 0 || (institutionLogin && Number(institutionLogin.institutionId) === 0)) {
        accounts.forEach((account) => list.push(account));
      }
      return list;
    }, []);

    return List(pureManualAccounts);
  }
);

export const getFinancialInstitutionsPendo = createSelector(
  accountsSelectors.getAccountsById,
  getInstitutionLoginsById,
  (accounts, logins) => accounts.reduce((results, account) => {
    const login = logins.get(account.institutionLoginId);
    account.aggregators?.forEach?.((aggregator) => {
      const financialInstitutionPendo = `${aggregator.channel || ''}:${login?.id || ''}:${aggregator.aggStatus || ''}:${aggregator.aggStatusCode || ''}:${login?.name || ''}`;
      if (!results.includes(financialInstitutionPendo)) {
        results.push(financialInstitutionPendo);
      }
    });
    return results;
  }, []),
);
