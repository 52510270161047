import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';

import { scheduledTransactionsActions, scheduledTransactionsSelectors } from '@quicken-com/react.flux.scheduled-transactions';

// DATA
import { removeDialog } from 'data/rootUi/actions';

// CUSTOM
import SelectTransactionDialog from 'components/Dialogs/SelectTransactionDialog';
import ScheduledTransactionEditDialog from 'components/ScheduledTransactions/ScheduledTransactionEditDialog';

import ReviewDialog from './ReviewDialog';
import { DIALOG_TYPE } from './actions';

// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
// ### - - -                  Dialog Component                   - - - ###
// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
function ScheduledTransactionAddDialog(props) {
  const { confirmation, billsOnly, incomeOnly, subscriptionOnly } = props;
  const dispatch = useDispatch();
  const models = useSelector(scheduledTransactionsSelectors.getScheduledTransactions);

  const [open, setOpen] = useState(true);

  const [manualClient, setManualClient] = useState(undefined);
  const [waitingClient, setWaitingClient] = useState(undefined);
  const [createdEdit, setCreatedEdit] = useState(undefined);

  let createdModel;
  const finalClientId = waitingClient || manualClient;
  if (finalClientId) {
    createdModel = models.find((model) => model.clientId === finalClientId);
  }

  const defaultModel = { clientId: manualClient, transaction: { isBill: !subscriptionOnly, isSubscription: subscriptionOnly } };

  const selectTransaction = (transaction) => {
    const clientId = uuidv4().toUpperCase();
    if (transaction?.id) {
      dispatch(scheduledTransactionsActions.createBillScoutSchedule({ clientId, id: transaction.id }, { props: { isSubscription: subscriptionOnly } }));
      setWaitingClient(clientId);
      if (!confirmation) {
        closeDialog();
        // returnFunc?.(clientId);
      }
    } else {
      setManualClient(clientId);
    }
  };

  const createSubmit = () => {
    setWaitingClient(waitingClient || manualClient);
    setCreatedEdit(false);
  };

  const closeDialog = () => {
    setOpen(false);
    dispatch(removeDialog(DIALOG_TYPE));
  };

  const goBack = () => {
    if (createdEdit) {
      setCreatedEdit(false);
    } else {
      setManualClient(undefined);
    }
  };

  const edit = () => {
    setCreatedEdit(true);
  };

  let isBill;
  let title;
  if (billsOnly) {
    isBill = true;
    title = 'Add bill';
  } else if (subscriptionOnly) {
    isBill = true;
    title = 'Add subscription';
  } else if (incomeOnly) {
    isBill = false;
    title = 'Add income';
  }
  return (
    <>
      <SelectTransactionDialog
        open={open && !manualClient && !waitingClient}
        onClose={closeDialog}
        onTransactionSelected={selectTransaction}
        title={title || 'Add a Bill or Paycheck'}
        subtitle="Choose a transaction to make recurring"
        selectorProps={{ isBill }}
        sharedcomponentid={'SCHEDULED_TRANSACTION_ADD_DIALOG'}
      />

      <ScheduledTransactionEditDialog
        open={open && (createdEdit || (!createdModel && Boolean(manualClient)))}
        scheduledTransaction={createdModel || defaultModel}
        onSubmit={createSubmit}
        onClose={closeDialog}
        onBack={goBack}
        title={createdEdit ? 'Edit series' : 'Create series'}
        subscriptionOnly={subscriptionOnly}
        sharedcomponentid={'SCHEDULED_TRANSACTION_ADD_DIALOG'}
      />

      <ReviewDialog
        open={open && !createdEdit && Boolean(waitingClient || createdModel)}
        model={createdModel}
        onClose={closeDialog}
        edit={edit}
        sharedcomponentid={'SCHEDULED_TRANSACTION_ADD_DIALOG'}
      />
    </>
  );
}

ScheduledTransactionAddDialog.defaultProps = {
  confirmation: true,
  billsOnly: false,
  incomeOnly: false,
  subscriptionOnly: false,
};

ScheduledTransactionAddDialog.propTypes = {
  confirmation: PropTypes.bool,
  billsOnly: PropTypes.bool,
  incomeOnly: PropTypes.bool,
  subscriptionOnly: PropTypes.bool,
};

export default ScheduledTransactionAddDialog;


