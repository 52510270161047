import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { List as ListImmutable } from 'immutable';
import { Helmet } from 'react-helmet';
import { v4 as uuidv4 } from 'uuid';

import { tracker } from '@quicken-com/react.utils.core';
import BudgetHeader from 'containers/BudgetsV2/components/BudgetHeader';
import BudgetEmbedded from 'containers/BudgetsV2/components/BudgetEmbedded';
import { computeViewState, ViewStateEnum } from 'containers/OverviewPage/components/helpers';
import zeroStateIcon from 'assets/zero-state-images/budgets.svg';
import LoadingView from 'components/LoadingView';
import ZeroStateView from 'components/ZeroStateView';
import QButton from 'components/QButton';
import WFBudgetSetup from 'components/Dialogs/WFBudgetSetup';
import { BUDGET_VIEWS, BUDGET_ANNUAL_SUBVIEWS } from 'containers/BudgetsV2/constants';

import { mkBudgetItem } from 'data/budgetItems/budgetItemsTypes';
import underConstructionImage from 'assets/under-construction.png';
import { staticConfig } from 'containers/SetupPage/GettingStartedWebFirst/config';
import EditBudgetsDialog from 'components/Budgets/EditBudgetsDialog';
import GettingStartedModule from 'components/GettingStartedModule';
import { getBudgetViewStateObject } from './utils';

const staticConfigWF = staticConfig(true, false, true);
const budgetConfig = staticConfigWF.length && staticConfigWF.length >= 3 && staticConfigWF[2]; // Get third element Budgets

class BudgetsV2 extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showEditDialog: false,
      budgetsBarStuck: false,
      transactionsBarStuck: false,
      showNewBudgetSetup: false,
    };
    this.goToDate(new Date());
    if (!this.props.isLoading) {
      this.props.dispatchGetUserPreferencesAction();
      this.refreshData();
    }
    setTimeout(() => {
      const budgetId = props?.budget?.id;
      if (budgetId) {
        this.budgetFullCalcTrigger(budgetId);
      }
    }, 1000);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    if (this.props.budgetsCreation && this.props.isWebFirstDataset && (
      (nextProps.uiState.startDate !== this.props.uiState.startDate) ||
      (nextProps.loadPending !== this.props.loadPending) ||
      (nextProps.isLoading !== this.props.isLoading)
    )) {
      this.checkBudgetExistence(nextProps);
    }
  }

  componentDidUpdate(prevProps) {
    const navPath = this.fullNavPath(this.props);
    const prevNavPath = this.fullNavPath(prevProps);
    if (JSON.stringify(navPath) !== JSON.stringify(prevNavPath)) {
      const nodesType = this.props.budgetTreeNodes && this.props.budgetTreeNodes.reduce((results, node) => {
        const resultsMutable = results;
        resultsMutable.isExpense = resultsMutable.isExpense || node.negate < 0;
        resultsMutable.isIncome = resultsMutable.isIncome || node.negate > 0;
        return resultsMutable;
      }, {
        isIncome: false,
        isExpense: false,
      });
      let groupType;
      if (nodesType && nodesType.isIncome && !nodesType.isExpense) {
        groupType = 'income';
      } else if (nodesType && nodesType.isExpense && !nodesType.isIncome) {
        groupType = 'expense';
      } else {
        groupType = 'dynamic';
      }
      tracker.track(tracker.events.budgetView, { group_type: groupType, level: navPath && (navPath.length - 1) });
    }
  }

  budgetFullCalcTrigger = (budgetId) => {
    const fullCalcTriggeredList = this.props?.preferences?.shared?.dataset?.budgetOptions?.fullCalcTriggeredList || [];
    if (!fullCalcTriggeredList.includes(budgetId)) {  
      this.props.dispatchBudgetFullCalcCall(budgetId);
    }
  }

  fullNavPath(props) {
    let navPath;
    if (props.navigationPath && props.budget) {
      navPath = [props.budget.id, ...props.navigationPath.map((node) => node.id)];
    } else if (props.budget) {
      navPath = [props.budget.id];
    }
    return navPath;
  }

  goPrevMonth = () => {
    const newDate = new Date(this.props.uiState.startDate.getFullYear(), this.props.uiState.startDate.getMonth() - 1, 1);
    this.goToDate(newDate);
    tracker.track(tracker.events.budgetChange, { type: 'prev_date' });
  };

  goThisMonth = () => {
    this.goToDate(new Date());
    tracker.track(tracker.events.budgetChange, { type: 'cur_date' });
  };

  goNextMonth = () => {
    const newDate = new Date(this.props.uiState.startDate.getFullYear(), this.props.uiState.startDate.getMonth() + 1, 1);
    this.goToDate(newDate);
    tracker.track(tracker.events.budgetChange, { type: 'next_date' });
  };

  goToDate = (date) => {
    this.props.setUIState({
      startDate: new Date(date.getFullYear(), date.getMonth(), 1),
      endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
    });
  };

  handleNavigationClick = (node) => {
    if (this.props.budget) {
      if (node) {
        this.props.history.replace(`/budgets?displayBudget=${this.props.budget.id}&displayNode=${node.key}`);
      } else {
        this.props.history.replace(`/budgets?displayBudget=${this.props.budget.id}`);
      }
    }
  };

  handleBudgetChange = (event) => {
    if (event.target && event.target.value) {
      this.props.dispatchSetSharedDatasetPreferenceAction({ budgetOptions: { lastViewedBudgetId: event.target.value } });
      this.props.setUIState({ selectedAnnualBudgetItem: null });
      this.props.history.replace(`/budgets?displayBudget=${event.target.value}`);
      this.goThisMonth(); 
      tracker.track(tracker.events.budgetChange, { type: 'budget' });
      this.budgetFullCalcTrigger(event.target.value);
    }
  };

  handleViewChange = ({ id, name }, annualSubView, event) => {
    if (event.target.value === BUDGET_VIEWS.annual.key) {
      this.props.history.replace(`/budgets?displayBudget=${id}`);
    }
    this.props.dispatchSetSharedWebAppDatasetPreferenceAction(
      getBudgetViewStateObject(id, name, event.target.value, annualSubView)
    );
    tracker.track(tracker.events.budgetViewChange, { changedBudgetView: event.target.value });
  }

  handleAnnualSubViewChange = ({ id, name }, budgetView, event) => {
    this.props.dispatchSetSharedWebAppDatasetPreferenceAction(
      getBudgetViewStateObject(id, name, budgetView, event.target.value)
    );
    tracker.track(tracker.events.annualBudgetViewChange, { annualViewType: BUDGET_ANNUAL_SUBVIEWS[event.target.value].label });
  }

  refreshData = () => {
    this.props.dispatchGetBudgetsAction();
    this.props.dispatchGetBudgetItemsAction();
    this.props.dispatchGetCategoryGroupListsAction();
    this.props.dispatchGetCategoryGroupsAction();
    this.props.dispatchGetCategoriesAction();
    this.props.dispatchGetPreferencesAction();
    this.props.dispatchGetTransactionsAction();
  };

  updateBudget = () => {
    this.props.dispatchGetBudgetItemsAction();
  }

  deleteBudget = () => {
    const { budget, dispatchDeleteBudgetAction } = this.props;
    if (!budget) return;

    this.props.dialogAlert(
      `Delete the budget "${budget.name}"`,
      `This will delete the budget "${budget.name}" and all its items.  This cannot be undone. Are you sure?`,
      (ret) => {
        if (ret.btnPressed === 'Yes') {
          dispatchDeleteBudgetAction(budget.set('isDeleted', true));
        }
      },
      ['Yes', 'Cancel'],
      'delete',
      false
    );
  };

  checkBudgetExistence = (props) => {

    const { budgetItems, budgetTreeNodes, isLoading, loadPending, budget } = props;
    const { startDate } = props.uiState;

    if (!budget) return;

    const budgetItemsViewingYear = budgetItems.filter((x) => x.budgetId === budget.id && (moment(x.startDate).year() === moment(startDate).year()));
    const budgetItemsPriorYear = budgetItems.filter((x) => x.budgetId === budget.id && (moment(x.startDate).year() === (moment(startDate).year() - 1)));

    // if the year being viewed has no budgetItems, but the year PRIOR to it does, then
    // offer to create items for that year.
    if (isLoading || loadPending || budgetItemsViewingYear.size > 0 || budgetItemsPriorYear.size === 0) {
      return;
    }

    const year = moment(startDate).year();
    const priorYear = Number(year) - 1;

    if (budgetTreeNodes && budgetTreeNodes.size < 1) {
      this.props.dialogAlert(
        `Create budget for ${year}?`,
        `Quicken creates budgets one year at a time, and you currently do not have a budget defined for ${year}. ` +
        `Would you like to create a ${year} budget based on your budget for ${priorYear}?`,
        (ret) => {
          if (ret.btnPressed.includes('Yes')) {
            // TODO create budget items for the next year
            const monthByMonth = !(ret.btnPressed.includes('December'));
            this.createNewBudget(props, startDate, budgetItemsPriorYear, monthByMonth);
          }
        },
        [`Yes, copy each month from ${priorYear}`, `Yes, copy from December, ${priorYear}`, 'No'],
        'info',
      );
    }
  };

  createNewBudget = (props, startDate, budgetItemsPriorYear, monthByMonth) => {

    const { budget, dispatchCreateBudgetItemsAction } = props;

    let allBudgetItemsToSave = new ListImmutable();
    // iterate over 12 months
    for (let month = 0; month < 12; month++) {
      const copyMonth = monthByMonth ? month : 11;
      const budgetItemsToCopy = budgetItemsPriorYear.filter((x) => moment(x.startDate).month() === copyMonth);

      const budgetItemsToSave = budgetItemsToCopy.map((item) =>
        mkBudgetItem({
          clientId: uuidv4().toUpperCase(),
          budgetId: budget.id,
          startDate: moment(startDate).month(month).format('YYYY-MM-DD'),
          amount: item.amount,
          groupId: item.groupId,
          type: item.type,
          coa: item.coa,
          calculatedActualsAmount: null,
          calclulatedRolloverAmount: null,
        }));
      allBudgetItemsToSave = allBudgetItemsToSave.concat(budgetItemsToSave.toList());
    }
    dispatchCreateBudgetItemsAction(allBudgetItemsToSave);
  };

  render() {
    const {
      classes,
      loadPending,
      isLoading,
      hasReportableAccounts,
      budgets,
      budget,
      navigationPath,
      budgetTreeNode,
      budgetTreeNodes,
      transactions,
      budgetsEnabled,
      theme,
      budgetAccountIds,
      budgetsCreation,
      isWebFirstDataset,
    } = this.props;

    const view = computeViewState(
      () => isLoading,
      () => !(budgets && budgets.size),
      hasReportableAccounts,
      loadPending,
      !budgetsEnabled,
    );

    const { showEditDialog, showNewBudgetSetup } = this.state;

    if (this.props.dashboardRef === 'dashboard' && budget?.id) {
      this.props.dispatchSetSharedWebAppDatasetPreferenceAction(
        getBudgetViewStateObject(budget.id, budget.name, BUDGET_VIEWS.monthly.key)
      );
    }

    return (
      <div
        style={{ height: '100%' }}
      >
        <Helmet>
          <title>Budgets</title>
        </Helmet>

        {view === ViewStateEnum.DISABLED &&
          <img
            alt="page is under construction"
            src={underConstructionImage}
            style={{
              display: 'block',
              width: '50%',
              marginTop: 40,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />}

        {view === ViewStateEnum.LOADING &&
          <LoadingView
            style={{ height: theme.defaults.content.height }}
          />}

        {view === ViewStateEnum.LOAD_PENDING &&
          <ZeroStateView
            style={{ margin: 40 }}
            primary="No Data"
            icon={zeroStateIcon}
            secondary="We can't fetch your data from the server. Please try to refresh the page."
          />}

        {(view === ViewStateEnum.ZERO || view === ViewStateEnum.EMPTY) && !isWebFirstDataset &&
          <ZeroStateView
            primary=""
            style={{ margin: 'auto', height: theme.defaults.content.height }}
            icon={zeroStateIcon}
            secondary={"It looks like you don't have a budget set up. If you have a budget set up on the desktop, be sure to sync it first." +
              `${budgetsCreation && isWebFirstDataset ? "Or you can create a budget from here by clicking 'Create' " : ''}`}
          >
            {budgetsCreation && isWebFirstDataset &&
              <div style={{ marginBottom: 25 }}>
                <QButton
                  variant="contained"
                  onClick={() => this.setState({ showNewBudgetSetup: true })}
                >
                  Create a Budget
                </QButton>
              </div>}
          </ZeroStateView>}

        {(view === ViewStateEnum.ZERO || view === ViewStateEnum.EMPTY || (view === ViewStateEnum.MAIN && showNewBudgetSetup)) &&
          isWebFirstDataset && budgetConfig && (
          <div className={classes.gettingStartedModuleRoot}>
            <GettingStartedModule config={budgetConfig} />
            <div className={classes.buttonArea}>
              {budgetConfig.buttons.map((btn) =>
                <div key={`SETUPBTN: ${btn.value}`}>
                  <QButton
                    variant="contained"
                    classes={{ conButton: classes.setupButton }}
                    onClick={() => btn.value === 'createBudget' && this.setState({ showNewBudgetSetup: true })}
                    id={btn.id}
                  >
                    {btn.label}
                  </QButton>
                </div>)}
            </div>
          </div>
        )}

        {view === ViewStateEnum.MAIN && !showNewBudgetSetup &&
          <div>
            <BudgetHeader
              isLoading={view === ViewStateEnum.LOADING}
              budget={budget}
              budgets={budgets}
              budgetsCreation={budgetsCreation && isWebFirstDataset}
              startDate={this.props.uiState.startDate}
              goNextMonth={this.goNextMonth}
              goPrevMonth={this.goPrevMonth}
              goToDate={this.goToDate}
              goThisMonth={this.goThisMonth}
              handleNavigationClick={this.handleNavigationClick}
              handleBudgetChange={this.handleBudgetChange}
              onEdit={() => this.setState({ showEditDialog: true })}
              onDelete={this.deleteBudget}
              handleViewChange={this.handleViewChange}
              handleAnnualSubViewChange={this.handleAnnualSubViewChange}
              dashboardRef={this.props.dashboardRef}
            />
            <BudgetEmbedded
              isLoading={view === ViewStateEnum.LOADING}
              budgetsCreation={budgetsCreation && isWebFirstDataset}
              budget={budget}
              createNewBudget={this.createNewBudget}
              accountIds={budgetAccountIds}
              navigationPath={navigationPath}
              budgetTreeNodes={budgetTreeNode ? new ListImmutable([budgetTreeNode]) : budgetTreeNodes}
              transactions={transactions}
              budgetsBarStuck={this.state.budgetsBarStuck}
              transactionsBarStuck={this.state.transactionsBarStuck}
              onTxnUpdate={this.updateBudget}
              handleNavigationClick={this.handleNavigationClick}
              dashboardRef={this.props.dashboardRef}
              budgetUiState={this.props.uiState}
              setBudgetUiState={this.props.setUIState}
            />
          </div>}
        {showNewBudgetSetup &&
          <WFBudgetSetup
            onClose={() => this.setState({ showNewBudgetSetup: false })}
          />}
        {showEditDialog &&
          <EditBudgetsDialog
            onClose={() => this.setState({ showEditDialog: false })}
            budget={budget}
            startDate={this.props.uiState.startDate}
            month={moment(this.props.uiState.startDate).format('MM')}
            budgetTreeNodes={budgetTreeNodes}
          />}
      </div>
    );
  }
}

BudgetsV2.propTypes = {
  classes: PropTypes.object,
  budgets: PropTypes.object,
  budgetsCreation: PropTypes.bool,
  budget: PropTypes.object,
  budgetTreeNode: PropTypes.object,
  budgetTreeNodes: PropTypes.object,
  transactions: PropTypes.object,
  navigationPath: PropTypes.array,
  dispatchGetUserPreferencesAction: PropTypes.func,
  dispatchGetBudgetsAction: PropTypes.func,
  dispatchDeleteBudgetAction: PropTypes.func,
  dispatchGetBudgetItemsAction: PropTypes.func,
  dispatchGetCategoryGroupListsAction: PropTypes.func,
  dispatchGetCategoryGroupsAction: PropTypes.func,
  dispatchGetCategoriesAction: PropTypes.func,
  dispatchGetPreferencesAction: PropTypes.func,
  dispatchGetTransactionsAction: PropTypes.func,
  dispatchSetSharedDatasetPreferenceAction: PropTypes.func,
  dispatchSetSharedWebAppDatasetPreferenceAction: PropTypes.func,
  hasReportableAccounts: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadPending: PropTypes.bool,
  uiState: PropTypes.any,
  setUIState: PropTypes.func,
  history: PropTypes.object,
  budgetsEnabled: PropTypes.bool,
  theme: PropTypes.object,
  dialogAlert: PropTypes.func,
  budgetAccountIds: PropTypes.object,
  isWebFirstDataset: PropTypes.bool,
  dashboardRef: PropTypes.string,
  preferences: PropTypes.object,
  dispatchBudgetFullCalcCall: PropTypes.func,
};

export default BudgetsV2;
