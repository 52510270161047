import createCachedSelector, { LruCacheObject } from 're-reselect';
import { createValueCache } from 'utils/selectorsHelpers';
import { REDUCER_KEY } from './reducer';
// const log = getLogger('data/documents/selector.js');

export const MAX_TX_ATTACHMENTS = 5;

export const getDocumentsStore = (state) => state[REDUCER_KEY];
export const getLastSyncDate = (state: any) => getDocumentsStore(state).lastSyncDate;

export const getDocuments = (state) => {
  const newState = getDocumentsStore(state);
  return newState.resourcesById;
};

export function getDocumentById(state, id) {
  if (state.resourcesById) {
    return state.resourcesById.find((x) => (String(x.id) === String(id))) || null;
  }
  return null;
}

export const getDocumentsForIds = createCachedSelector(
  getDocuments,
  (state, ids) => ids,
  (documents, ids) => documents.filter((document) => ids?.includes(document.id)),
)(
  (state, ids) => ids?.join(',') || 'none',
  { cacheObject: new LruCacheObject({ cacheSize: 5 }) },
);

// attachments is array of objects { id, clientId }
export const getDocumentsForAttachments = createCachedSelector(
  getDocuments,
  (state, attachments) => attachments,
  createValueCache((documents, attachments) => documents.filter((document) => attachments?.find((attachment) =>
    attachment.id === document.id || attachment.clientId === document.clientId))),
)(
  (state, attachments) => attachments?.map((attachment) => attachment.id || attachment.clientId).join(',') || 'none',
  { cacheObject: new LruCacheObject({ cacheSize: 5 }) }
);
