import compose from 'utils/compose';
import withStyles from '@mui/styles/withStyles';

import QDialogs from 'components/QDialogs';
import styles from './styles';
import BudgetEmbedded from './BudgetEmbedded';

export default compose(
  QDialogs(),
  withStyles(styles, { withTheme: true }),
)(BudgetEmbedded);
