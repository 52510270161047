import { createAction } from 'redux-actions';

export const getDocuments = createAction('GET_DOCUMENTS');
export const getDocumentsSuccess = createAction('GET_DOCUMENTS_SUCCESS');
export const getDocumentsFailure = createAction('GET_DOCUMENTS_FAILURE');

export const createDocument = createAction('CREATE_DOCUMENT', (payload) => payload, (payload, meta) => meta);
export const createDocumentSuccess = createAction('CREATE_DOCUMENT_SUCCESS');
export const createDocumentFailure = createAction('CREATE_DOCUMENT_FAILURE');

export const deleteDocument = createAction('DELETE_DOCUMENT');
export const deleteDocumentSuccess = createAction('DELETE_DOCUMENT_SUCCESS');
export const deleteDocumentFailure = createAction('DELETE_DOCUMENT_FAILURE');

export const getDocument = createAction('GET_DOCUMENT', (payload) => payload, (payload, meta) => meta);
export const getDocumentSuccess = createAction('GET_DOCUMENT_SUCCESS');
export const getDocumentFailure = createAction('GET_DOCUMENT_FAILURE');

export const uploadDocument = createAction('UPLOAD_DOCUMENT', (payload) => payload, (payload, meta) => meta);
export const uploadDocumentSuccess = createAction('UPLOAD_DOCUMENT_SUCCESS');
export const uploadDocumentFailure = createAction('UPLOAD_DOCUMENT_FAILURE');

export const uploadData = createAction('UPLOAD_DATA', (payload) => payload, (payload, meta) => meta);
export const uploadDataSuccess = createAction('UPLOAD_DATA_SUCCESS');
export const uploadDataFailure = createAction('UPLOAD_DATA_FAILURE');
