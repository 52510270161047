import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'utils/compose';

import QTip from 'components/QuickenControls/QTip';
import QTypography from 'components/MUIWrappers/QTypography';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';

import PayeeFieldEditable from './PayeeFieldEditable';


class PayeeField extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    if (nextProps.value.payee !== this.props.value.payee) {
      this.setState({ value: nextProps.value });
    }
  }

  render() {

    const { editable, clickable, classNameWhenNotEditable } = this.props;

    return (
      <>
        {editable &&

          <PayeeFieldEditable
            {...this.props}
          />}
        {!editable &&
          <QTip
            wrapOnly
            title={this.props.tooltip ? this.state.value.payee : null}
          >
            <QTypography
              variant="body2"
              clickable={clickable}
              thinFont
              className={classNameWhenNotEditable}
            >
              {this.state.value.payee}
            </QTypography>
          </QTip>}
      </>
    );
  }
}

PayeeField.propTypes = {
  editable: PropTypes.bool,
  value: PropTypes.object,
  clickable: PropTypes.bool,
  classNameWhenNotEditable: PropTypes.string,
  tooltip: PropTypes.bool, // show a tooltip on static value?
};

function mapStateToProps(state) {
  return {
    // selector uses prop 'accountIds', if not specified, it uses 'all accounts'
    useAllAccounts: featureFlagsSelectors.getFeatureFlags(state).get('payeeFieldsIncludeAllAccounts'),
  };
}

// note: order-matters - props flow from top to bottom
export default compose(
)(connect(mapStateToProps, null)(PayeeField));


