import axios from 'axios';
import { takeEvery, put, race, take, call, delay, select } from 'redux-saga/effects';

import { resourceStoreTypes, resourceSagaUtils } from '@quicken-com/react.flux.core';
import { subscribeForDumpUpdates } from '@quicken-com/react.flux.bump';
import { tagsActions } from '@quicken-com/react.flux.tags';
import { categoriesActions } from '@quicken-com/react.flux.categories';
import { transactionsActions } from '@quicken-com/react.flux.transactions';

import { mkDocument } from 'data/documents/types';
import { importData, importDataSuccess, importDataFailure, pfmImport, pfmImportSuccess, pfmImportFailure } from 'data/import/importActions';
import { createDocument, createDocumentSuccess, createDocumentFailure,
  getDocument, getDocumentSuccess, getDocumentFailure, deleteDocument } from 'data/documents/actions';
import { tracker } from '@quicken-com/react.utils.core';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';

subscribeForDumpUpdates(
  'PFM_IMPORT',
  undefined,
  function* pfmImportUpdater(_message) {
    yield put(transactionsActions.getTransactions(undefined, { context: 'import' }));
    yield put(tagsActions.getTags(undefined, { context: 'import' }));
    yield put(categoriesActions.getCategories(undefined, { context: 'import' }));
  },
);

export function* pfmImportActionWatcher() {
  const config = resourceStoreTypes.mkQcsSyncResourceConfig({
    resourceName: 'pfm-importer',
    resourceBaseUrl: '/pfm-importer/import?retry=true',
    getLastSyncDate: () => null,
    successAction: pfmImportSuccess,
    failureAction: pfmImportFailure,
    transformResourceWithResponse: (resource, response) => ({ ...resource, ...response }),
  });
  yield takeEvery(pfmImport, resourceSagaUtils.qcsSyncCreateResource, config);
}

export function* importDataActionWatcher() {
  yield takeEvery(importData, function* _importData(importDataAction) {

    tracker.track(tracker.events.csvImport, { status: 'start', type: importDataAction.payload.provider });

    yield put(createDocument(mkDocument(importDataAction.payload.document)));
    const { createDocumentSuccessAction, createDocumentFailureAction } = yield race({
      createDocumentSuccessAction: take((action) =>
        action.type === createDocumentSuccess.toString() && action.payload.clientId && action.payload.clientId === importDataAction.payload.document.clientId),
      createDocumentFailureAction: take((action) =>
        action.type === createDocumentFailure.toString() && action.payload.clientId && action.payload.clientId === importDataAction.payload.document.clientId),
    });

    if (createDocumentSuccessAction
      && createDocumentSuccessAction.payload.url
      && createDocumentSuccessAction.payload.contentType
      && createDocumentSuccessAction.payload.id
    ) {
      let dataUploadResponse;
      try {
        dataUploadResponse = yield call(
          axios.put,
          createDocumentSuccessAction.payload.url,
          importDataAction.payload.data,
          { headers: { 'Content-Type': createDocumentSuccessAction.payload.contentType } },
        );
      } catch (error) {
        yield put(importDataFailure({
          clientId: importDataAction.payload.clientId,
          provider: importDataAction.payload.provider,
          errorDescription: `${error.name} ${error.message}`,
          ...error,
        }));
        return;
      }

      if (dataUploadResponse && dataUploadResponse.status === 200) {
        yield put(getDocument(mkDocument({ id: createDocumentSuccessAction.payload.id })));
        const { getDocumentSuccessAction, getDocumentFailureAction } = yield race({
          getDocumentSuccessAction: take((action) => action.type === getDocumentSuccess.toString()
              && action.payload.resources && action.payload.resources.length === 1
              && action.payload.resources[0].id === createDocumentSuccessAction.payload.id),
          getDocumentFailureAction: take((action) => action.type === getDocumentFailure.toString()
            && action.payload.resources && action.payload.resources.length === 1
            && action.payload.resources[0].id === createDocumentSuccessAction.payload.id),
        });

        const url = getDocumentSuccessAction && getDocumentSuccessAction.payload.resources && getDocumentSuccessAction.payload.resources.length === 1 && getDocumentSuccessAction.payload.resources[0].url;
        if (url) {
          yield put(pfmImport({
            clientId: importDataAction.payload.clientId,
            accountId: importDataAction.payload.accountId,
            provider: importDataAction.payload.provider,
            url,
          }));
          const { pfmImportSuccessAction, pfmImportFailureAction } = yield race({
            pfmImportSuccessAction: take((action) => action.type === pfmImportSuccess.toString()
              && action.payload.clientId === importDataAction.payload.clientId),
            pfmImportFailureAction: take((action) => action.type === pfmImportFailure.toString()
              && action.payload.clientId === importDataAction.payload.clientId),
          });

          yield put(deleteDocument(mkDocument({ id: createDocumentSuccessAction.payload.id })));

          if (pfmImportSuccessAction) {
            yield put(importDataSuccess({
              clientId: importDataAction.payload.clientId,
              provider: importDataAction.payload.provider,
              ...pfmImportSuccessAction.payload,
            }));

            // dirty hack - delete once websocket impelmented
            const webSocketEnabled = yield select(featureFlagsSelectors.getFeatureFlag, 'webSocketQcs');
            if (!webSocketEnabled) {
              yield delay(3000);
              yield put(transactionsActions.getTransactions(undefined, { context: 'import' }));
              yield put(tagsActions.getTags(undefined, { context: 'import' }));
              yield put(categoriesActions.getCategories(undefined, { context: 'import' }));
            }
          } else {
            yield put(importDataFailure({
              clientId: importDataAction.payload.clientId,
              provider: importDataAction.payload.provider,
              errorDescription: 'pfm importer',
              ...pfmImportFailureAction.payload,
            }));
          }
        } else {
          yield put(importDataFailure({
            clientId: importDataAction.payload.clientId,
            provider: importDataAction.payload.provider,
            errorDescription: 'get document url',
            ...(getDocumentSuccessAction ? getDocumentSuccessAction.payload : getDocumentFailureAction.payload),
          }));
        }
      } else {
        yield put(importDataFailure({
          clientId: importDataAction.payload.clientId,
          provider: importDataAction.payload.provider,
          errorDescription: 'document upload',
          ...dataUploadResponse,
        }));
      }
    } else {
      yield put(importDataFailure({
        clientId: importDataAction.payload.clientId,
        provider: importDataAction.payload.provider,
        errorDescription: 'create document placeholder',
        ...(createDocumentFailureAction ? createDocumentFailureAction.payload : createDocumentSuccessAction.payload),
      }));
    }
  });

}

export function* importDataSuccessActionWatcher() {
  yield takeEvery(importDataSuccess, function* _importDataSuccess(importDataSuccessAction) {
    tracker.track(tracker.events.csvImport, {
      status: 'complete',
      type: importDataSuccessAction.payload.provider,
    });
  });
}

export function* importDataFailureActionWatcher() {
  yield takeEvery(importDataFailure, function* _importDataFailure(importDataFailureAction) {
    tracker.track(tracker.events.csvImport, {
      status: 'error',
      type: importDataFailureAction.payload.provider,
      error_description: importDataFailureAction.payload.errorDescription,
    });
  });
}

export default [
  importDataActionWatcher,
  importDataSuccessActionWatcher,
  importDataFailureActionWatcher,
  pfmImportActionWatcher,
];
