import { useMemo, useEffect } from 'react';
import { usePrevious } from 'react-use';
import { useSelector } from 'react-redux';

import { authSelectors } from '@quicken-com/react.flux.auth';

import { getClientConfigFlags } from 'utils/clientConfigFlags';
import { getActiveSubscription } from 'data/subscriptions/subscriptionsSelectors';

const ClientConfig = () => {
  const authSession = useSelector(authSelectors.getAuthSession);
  const subscription = useSelector(getActiveSubscription);
  const {
    qcsId,
    datasetId,
    datasetPlatform,
    datasetCreatedByClientId,
    datasetCreatedAt,
    scope,
    groups,
  } = authSession;
  const updateTriggers = useMemo(() => ({
    qcsId,
    datasetId,
    datasetPlatform,
    datasetCreatedByClientId,
    datasetCreatedAt,
    scope,
    groups,
    subscription,
  }), [
    qcsId,
    datasetId,
    datasetPlatform,
    datasetCreatedByClientId,
    datasetCreatedAt,
    scope,
    groups,
    subscription,
  ]);

  const updateTriggersPrev = usePrevious(updateTriggers);

  useEffect(() => {
    if (updateTriggersPrev !== updateTriggers) {
      getClientConfigFlags();
    }
  }, [updateTriggersPrev, updateTriggers]);

  return null;
};

export default ClientConfig;
