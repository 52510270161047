import { formatNumber } from 'components/QuickenControls/AmountField';

export const generateChartData = (totalSpendings, annualBudget, totalSavings, currency, theme, amountFormat) => {

  const chartData = [];
  let annualBudgetForCalculation = annualBudget;
  if (totalSpendings < annualBudget) {
    chartData.push({
      from: chartData[chartData.length - 1]?.to || 0,
      to: totalSpendings,
      value: totalSpendings,
      label: '',
      fill: theme.components.progressChart.primaryColor,
      graphValue: Number(formatNumber(totalSpendings, '', amountFormat)) === 0 ? '' : formatNumber(totalSpendings, currency, amountFormat),
    }, {
      from: totalSpendings,
      to: annualBudget,
      value: annualBudget,
      label: '',
      fill: theme.components.progressChart.secondaryColor,
      graphValue: '',
    });
  } else {
    annualBudgetForCalculation = totalSpendings - annualBudget;
    chartData.push({
      from: chartData[chartData.length - 1]?.to || 0,
      to: annualBudget,
      value: annualBudget,
      label: '',
      fill: theme.components.progressChart.primaryColor,
      graphValue: Number(formatNumber(annualBudget, '', amountFormat)) === 0 ? '' : formatNumber(annualBudget, currency, amountFormat),
    }, {
      from: annualBudget,
      to: annualBudget + annualBudgetForCalculation,
      value: totalSpendings,
      label: '',
      fill: theme.components.progressChart.dangerColor,
      graphValue: '',
    });
  }

  chartData.push({
    from: chartData[chartData.length - 1]?.to || 0,
    to: (chartData[chartData.length - 1]?.to || 0) + totalSavings,
    value: totalSavings,
    label: '',
    fill: theme.components.progressChart.fillColor,
    graphValue: '',
  });

  return chartData;
};
