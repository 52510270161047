const Styles = ({ palette, components }) => ({
  headingStyle: {
    fontWeight: 500,
    textAlign: 'left',
  },
  dialogContentStyle: {
    backgroundColor: components.categoryDialog.primary,
  },
  subHeadTextStyle: {
    fontSize: 14.5,
    textTransform: 'capitalize',
    fontWeight: 400,
    lineHeight: '17px',
    color: palette.text.primary,
  },
  tableContentStyle: {
    fontSize: 14.5,
    lineHeight: '28px',
    fontWeight: 400,
  },
  ellipseStyle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: 25,
  },
  qScrollStyle: {
    backgroundColor: palette.grey.level0,
    padding: '23px 15px', 
    paddingBottom: 0,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    width: 500,
    height: 254,
    maxHeight: 300,
    overflow: 'auto',
    '& th': {
      extend: 'tableContentStyle',
      color: palette.text.secondary,
      textAlign: 'left',
      '&:first-child': {
        width: '60%',
      },
      width: 150,
      '&:last-child': {
        textAlign: 'right',
      },
    },
    '& td': {
      extend: 'tableContentStyle',
      '&:last-child': {
        textAlign: 'right',
      },
    },
  },
  chartWrapper: {
    height: 280,
    padding: '23px 15px 0',
  },
  specialRow: {
    borderTop: '1px solid #eee',
  },
  tabStyle: {
    fontWeight: 400,
    color: palette.text.primary,
    textTransform: 'capitalize',
  },
  currentTabStyle: {
    background: palette.grey.level0,
    borderTop: `2px solid ${palette.primary.light}`,
  },
  avarageWrapper: {
    marginBottom: 10,
  },
  totalTable: {
    width: '100%',
    background: palette.grey.level0,
    textAlign: 'right',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    '& td': {
      fontSize: 14,
      '&:first-child': { width: '60%' },
      '&:nth-child(2)': { textAlign: 'left' },
      '&:last-child': { paddingRight: 15 },
    },
  },
  dialogTitle: {
    paddingLeft: 24,
    justifyContent: 'left',
  },
  barChartWrapper: {
    height: '100%',
  },
});

export default Styles;
