import { bumpInterface } from '@quicken-com/react.flux.bump';
import { getEnvironmentConfig } from '@quicken-com/react.utils.core';
import store from '@quicken-com/react.utils.redux-store';
import { authActions } from '@quicken-com/react.flux.auth';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';

import { getIsOffline } from 'data/app/selectors';
import { isAuthenticatedWithDataSetId } from 'data/entitlements/entitlementsSelectors';

bumpInterface.bumpInterfaceInit({
  create: () => {
    const socket = new WebSocket(getEnvironmentConfig().websocket_qcs_url);
    socket.onclose = bumpInterface.onClose;
    socket.onerror = bumpInterface.onError;
    socket.onmessage = bumpInterface.onMessage;
    socket.onopen = bumpInterface.onOpen;
    return socket;
  },
  isOffline: () => getIsOffline(store.getState()),
  reconnectTimeout: () => {
    const state = store.getState();
    return isAuthenticatedWithDataSetId(state) && featureFlagsSelectors.getFeatureFlag(store.getState(), 'webSocketQcs');
  },
  reAuth: () => store.dispatch(authActions.invalidateAccessToken(undefined, { context: 'WS auth failed' })),
});
