// CORE
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

// FLUX
import { getLogger } from '@quicken-com/react.utils.core';
import { accountsSelectors, accountsUtils } from '@quicken-com/react.flux.accounts';
import { categoriesSelectors } from '@quicken-com/react.flux.categories';
import { transactionsTypes, transactionsUtils } from '@quicken-com/react.flux.transactions';
import { scheduledTransactionsActions, scheduledTransactionsTypes, scheduledTransactionsSelectors } from '@quicken-com/react.flux.scheduled-transactions';
import { chartOfAccountsSelectors } from '@quicken-com/react.flux.chart-of-accounts';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import OpenNewIcon from '@mui/icons-material/OpenInNewRounded';
import RepeatIcon from '@mui/icons-material/RepeatRounded';
import RepeatOneIcon from '@mui/icons-material/RepeatOneRounded';
import LinkIcon from '@mui/icons-material/Link';
import UnlinkIcon from '@mui/icons-material/LinkOff';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import Dialog from '@mui/material/Dialog';

// DATA
import store from 'store';
import useQData from 'components/QData/useQData';
import { useDispatch, useSelector } from 'react-redux';
import { getCreatedTxnByClientAndAccountId, getTransactionsByAccountId, getTransactionById, getTxnByMatchedTxnAndAccountId } from 'data/transactions/selectors';
import { getMatchState, isSupportedTransferTxn, isExistingTxn } from 'data/transactions/utils';
import { getAccountById, getAccountString } from 'data/accounts/retrievers';
import { showFlags, showReviewed, getDatasetPreferences } from 'data/preferences/selectors';
import { removeDialog } from 'data/rootUi/actions';
import { normalizePayeeQCS } from 'data/renameRules/utils';

// CUSTOM COMPONENTS
import StdDialog from 'components/Dialogs/StdDialog';
import QButton from 'components/QButton';
import AccountField from 'components/QuickenControls/AccountField';
import StatusField from 'components/Transactions/StatusField';
import DateField from 'components/Transactions/DateField';
import SPayeeField from 'components/SPayeeField';
import QAmountField from 'components/QAmountField';
import VisibilitySection from 'components/Transactions/VisibilitySection';
import BoxField from 'components/Transactions/BoxField';
import AttachmentsField from 'components/AttachmentsField';
import ScheduledTransactionEditDialog from 'components/ScheduledTransactions/ScheduledTransactionEditDialog';
import SelectScheduledTransactionDialog from 'components/Dialogs/SelectScheduledTransactionDialog';
import QPanelButton from 'components/QPanelButton';
import FlagField from 'components/Transactions/FlagField';
import ReviewField from 'components/Transactions/ReviewField';
import LoadingView from 'components/LoadingView';
import MemorizedRuleEditDialog from 'components/Dialogs/MemorizedRuleEditDialog';
import RenameRuleEditDialog from 'components/Dialogs/RenameRuleEditDialog';
import QTip from 'components/QuickenControls/QTip';
import Dump from 'components/Dump';

// assets
import plannedSpendingOrchid from 'assets/planned-spending-orchid.svg';

import CategorySection from './CategorySection';
// import { incomeTxn, expenseTxn } from './testData';

const log = getLogger('component/Transactions/DetailsDialog/index.js');

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 24px 24px',
    marginBottom: 60,
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
  },
  cpData: {
    paddingLeft: 14,
  },
  link: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  openIcon: {
    height: 14,
    width: 14,
    marginBottom: 2,
    marginLeft: 2,
  },
  flagField: {
    width: 246,
    flexBasis: 246,
    flexGrow: 0,
    marginRight: 'auto',
  },
  reviewField: {
    marginLeft: 'auto',
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: 8,
  },
  thinRow: {
    extend: 'row',
    paddingBottom: 0,
  },

  alignedField: {
    width: 270,
    paddingRight: 24,
  },
  leftField: {
    extend: 'alignedField',
    minWidth: 180,
  },
  rightField: {
    flexBasis: 180,
    flexShrink: 0,
  },
  payeeField: {
    width: 540,
    paddingRight: 24,
  },
  spacer: {
    extend: 'rightField',
    opacity: 0,
  },

  seriesColumn: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 14,
    paddingBottom: 8,
  },
  line: {
    display: 'flex',
    alignItems: 'center',
    marginTop: -4,
  },
  seriesLine: {
    paddingRight: 16,
    color: theme.palette.text.secondary,
  },
  spacedIcon: {
    marginRight: 8,
  },
  oneTimeRow: {
    paddingLeft: 14,
    paddingBottom: 8,
  },

  refundLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingLeft: 14,
    paddingBottom: 8,
  },

  divider: {
    width: '100%',
    height: 1,
    backgroundColor: theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.15),
    marginBottom: 16,
  },

  bottomSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    width: 360,
    flexBasis: 360,
    flexGrow: 0,
  },

  greyText: {
    color: theme.palette.text.secondary,
  },

  recurringRoot: {
    padding: 24,
    display: 'flex',
    minHeight: 464,
  },
  img: {
    width: 100,
    height: 100,
    marginRight: 16,
    flexShrink: 0,
  },
  recurringColumn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  recurringSubtitle: {
    paddingTop: 16,
    paddingBottom: 24,
    color: theme.palette.text.secondary,
  },

  fullWidth: {
    width: '100%',
  },

  actions: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
    padding: '0 24px 24px',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  recurringButton: {
    position: 'absolute',
    left: 16,
  },
  recurringIcon: {
    marginRight: 8,
  },
  deleteButton: {
    marginRight: 16,
  },

  circularProgress: {
    height: 48,
    width: 48,
    margin: 'auto',
    marginTop: 48,
    marginBottom: 48,
  },
}));


function clickPayeeLink(name) {
  window.open(`https://www.google.com/search?q=${encodeURIComponent(name)}`);
}

const defaultTxn = new transactionsTypes.CashFlowTransaction({ state: 'CLEARED' });

const wrapperId = 'TRANSACTION_DETAILS_DIALOG';

const negativeCompare = (val1, val2) => (!val2 && !val2) ? true : undefined;


// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
// ### - - -                  Dialog Component                   - - - ###
// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
function DetailsDialog(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { onClose, onlyRecurring, defaultAccountId, focusSplits } = props;

  const [txn, setTxn] = useState(props.txn || defaultTxn);
  const create = !isExistingTxn(txn);
  const isManual = txn.source === 'USER_ENTERED';

  const { qDataPerformTransactionAction, qDataSaveTransactions, qDataDeleteTransactions, qDataUnMatchTransaction } = useQData(wrapperId);

  const accounts = useSelector(accountsSelectors.getAccountsById);
  const defaultId = txn.accountId || defaultAccountId || accounts.first()?.id;

  const createRules = useSelector(getDatasetPreferences).defaultCreateRuleToChecked;

  const connectedTxn = useSelector((state) => getTransactionById(state, txn?.id));

  // +++              State values                 +++
  // - - - - - - - - - - - - - - - - - - - - - - - - -

  // UI values
  const [open, setOpen] = useState(true);

  const [showRecurringOptions, setShowRecurringOptions] = useState(onlyRecurring);
  const [recurringSelect, setRecurringSelect] = useState(false);
  const [editRecurring, setEditRecurring] = useState(false);

  const [txnClientId, setTxnClientId] = useState(null); // client ID when doing recurring things on manual txn create

  const [waitingForTxn, setWaitingForTxn] = useState(false);
  const [waitingForTransfer, setWaitingForTransfer] = useState(false);

  const [modelIdToLink, setModelIdToLink] = useState(null);

  const [otherTransfer, setOtherTransfer] = useState(null);

  const [touchedReviewed, setTouchedReviewed] = useState(false);

  const [creatingCategoryRule, setCreatingCategoryRule] = useState(false);
  const [creatingRenameRule, setCreatingRenameRule] = useState(false);


  // txForm values
  const [flag, setFlagInternal] = useState(txn.userFlag);
  const [reviewed, setReviewed] = useState(txn.isReviewed);
  const [accountId, setAccountInternal] = useState(defaultId);
  const [status, setStatusInternal] = useState(create ?
    ((accountsUtils?.isManualAccount(accounts.get(defaultId)) && 'CLEARED') || 'PENDING')
    : txn.state);
  const [date, setDateInternal] = useState(DateTime.fromISO(txn.postedOn));
  const [payee, setPayeeInternal] = useState(txn.payee || '');
  const [amount, setAmountInternal] = useState(Number(txn.amount || 0));
  const [coa, setCoaInternal] = useState(txn.coa);
  const [tags, setTagsInternal] = useState(txn.tags);
  const [excludeReports, setExcludeReportsInternal] = useState(Boolean(txn.isExcludedFromReports));
  const [excludeF2S, setExcludeF2SInternal] = useState(Boolean(txn.isExcludedFromF2S));
  const [memo, setMemoInternal] = useState(txn.memo || '');
  const [attachments, setAttachmentsInternal] = useState(txn.attachments);
  const [split, setSplitInternal] = useState(txn.split);
  const [isBill, setIsBillInternal] = useState(Boolean(txn.isBill));

  // condense form values into Immutable and dirty check
  // not memoizing because it basically needs to re-do on every meaningful state change
  const tempTxn = (connectedTxn || txn);
  const spreadTxn = tempTxn.toJS ? tempTxn.toJS() : tempTxn;
  const formattedDate = date?.isValid ? date.toUTC().toISO() : spreadTxn.postedOn;
  const formattedAmount = Number(amount?.toFixed?.(2));
  const finalTags = tags?.size ? tags : undefined;
  const finalMemo = (spreadTxn.memo !== '' && memo === '') ? undefined : memo;
  const finalCoa = split ? null : coa;

  const newTxnObj = {
    ...spreadTxn,
    accountId,
    state: status,
    postedOn: formattedDate,
    payee,
    amount: formattedAmount,
    coa: finalCoa,
    tags: finalTags,
    isExcludedFromReports: excludeReports,
    isExcludedFromF2S: excludeF2S,
    memo: finalMemo,
    attachments,
    split,
    isBill,
    userFlag: flag,
    isReviewed: reviewed,
  };
  const currentTxn = new transactionsTypes.CashFlowTransaction(newTxnObj);
  const isDirty = !_.isEqualWith(spreadTxn, currentTxn.toJS(), negativeCompare);

  const validPayee = Boolean(payee && (payee !== ''));

  // #  Function declarations to update the reviewed Field
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const updateReviewed = useCallback((val) => {
    setReviewed(val);
    setTouchedReviewed(true);
  }, []);

  const applyReview = useCallback(() => {
    if (!touchedReviewed) {
      setReviewed(true);
    }
  }, [touchedReviewed]);

  const setFlag = useCallback((val) => {
    setFlagInternal(val);
    applyReview();
  }, [applyReview]);
  const setAccount = useCallback((val) => {
    setAccountInternal(val);
    applyReview();
    if (create) {
      setStatusInternal(accountsUtils?.isManualAccount(getAccountById(val)) ? 'CLEARED' : 'PENDING');
    }
  }, [applyReview, create]);
  const setStatus = useCallback((val) => {
    setStatusInternal(val);
    applyReview();
  }, [applyReview]);
  const setDate = useCallback((val) => {
    setDateInternal(val);
    applyReview();
  }, [applyReview]);
  // TODO: maybe replace with standard func after register gets nuked
  const setPayee = useCallback((_e, val) => {
    if (createRules && txn.cpData?.inferredPayee && val) {
      setCreatingRenameRule(true);
    }
    setPayeeInternal(val);
    applyReview();
  }, [applyReview, createRules, txn]);
  const setAmount = useCallback((val) => {
    setAmountInternal(val);
    applyReview();
  }, [applyReview]);
  const setCoa = useCallback((val) => {
    setCoaInternal(val);
    applyReview();
  }, [applyReview]);
  const setTags = useCallback((val) => {
    setTagsInternal(val);
    applyReview();
  }, [applyReview]);
  const setExcludeReports = useCallback((val) => {
    setExcludeReportsInternal(val);
    applyReview();
  }, [applyReview]);
  const setExcludeF2S = useCallback((val) => {
    setExcludeF2SInternal(val);
    applyReview();
  }, [applyReview]);
  const setMemo = useCallback((val) => {
    setMemoInternal(val);
    applyReview();
  }, [applyReview]);
  const setAttachments = useCallback((val) => {
    setAttachmentsInternal(val);
    applyReview();
  }, [applyReview]);
  const setSplit = useCallback((val) => {
    setSplitInternal(val);
    applyReview();
  }, [applyReview]);
  const setIsBill = useCallback((val) => {
    setIsBillInternal(val);
    applyReview();
  }, [applyReview]);


  // +++                  Data                     +++
  // - - - - - - - - - - - - - - - - - - - - - - - - -

  const showFlag = useSelector(showFlags);
  const showReview = useSelector(showReviewed);

  const model = useSelector((state) => scheduledTransactionsSelectors.getScheduledTransactionById(state, txn.stModelId));
  const incomeCOAs = useSelector(categoriesSelectors.getIncomeCOAIds);
  const isIncome = transactionsUtils.isIncomeFromTxn(currentTxn, incomeCOAs);
  const isCredit = useSelector((state) => chartOfAccountsSelectors.isCreditBill(state, accountId, coa));
  const isTransfer = transactionsUtils.isUITransferTxn(currentTxn) && !isCredit;

  const linkableScheduledTransactions = useSelector((state) => showRecurringOptions ? scheduledTransactionsSelectors.typeFilteredScheduledTransactions(state, currentTxn) : undefined);

  const createdTxn = useSelector((state) => getCreatedTxnByClientAndAccountId(state, txnClientId, accountId));

  // # ===================================================================================== #
  // # -----                             Action Functions                              ----- #
  // # ===================================================================================== #

  const updateCOA = useCallback((newCOA) => {
    setCoa(newCOA);
    if (create && newCOA) {
      const newIsIncome = transactionsUtils.isIncomeFromTxn({ ...txn, isBill, coa: newCOA }, incomeCOAs);
      if (isIncome !== newIsIncome) { // if coa type change, apply default sign
        const newAmount = Math.abs(amount) * (newIsIncome ? 1 : -1);
        setAmount(newAmount);
      }
    }
    if (validPayee && newCOA && createRules) {
      setCreatingCategoryRule(true);
    }
  }, [setCoa, create, validPayee, createRules, txn, isBill, incomeCOAs, isIncome, amount, setAmount]);

  const selectAccount = useCallback((selectedAccount) => setAccount(selectedAccount?.id), [setAccount]);

  const updateAmount = useCallback((e) => {
    const newAmount = Number(e.target.value);
    setAmount(newAmount);
  }, [setAmount]);

  // no memo callback because this uses currentTxn
  const exitRecurringCreate = () => {
    setEditRecurring(false);
    if (editRecurring && model) {
      dispatch(scheduledTransactionsActions.deleteScheduledTransaction(scheduledTransactionsTypes.mkScheduledTransaction({
        id: model.id,
        ...model.toJS?.(),
        isUserVerified: true,
      })));
      setTxn(txn.merge({ stModelId: undefined, isBill: false }));
      setIsBillInternal(false);
    }
  };

  // always new so memo unnecessary
  const resetToEdit = () => {
    setShowRecurringOptions(false);
    setRecurringSelect(false);
    exitRecurringCreate();
  };

  // always new so memo unnecessary
  const closeAll = () => {
    log.log('\nclosingAll');
    setOpen(false);
    resetToEdit();
    onClose?.();
    dispatch(removeDialog(`TRANSACTION_DETAILS_${props.txn?.id || '0'}`));
  };

  const onDelete = () => {
    qDataDeleteTransactions([txn]);
    closeAll();
  };

  const onSave = () => {
    qDataSaveTransactions([currentTxn]);
    closeAll();
  };

  // not memoizing because its only used in a non-memoized inline render
  const unMatchTxn = () => {
    // todo : once matchedTxnId is set
    //  when unmatching replace txn ID with that so that we go to the bank-downloaded txn, not the user-entered one
    qDataUnMatchTransaction(txn);
  };

  const supportedTransfer = useMemo(() => transactionsUtils.isTransferTxn(txn) && isSupportedTransferTxn(txn), [txn]);
  // not passed to child components so not memoizing
  const goToTransfer = () => {
    if (supportedTransfer) {
      let destAcct = null;
      let altAmount = null;
      if (transactionsUtils.isSplitTxn(txn)) {
        txn.split.items.forEach((item) => {
          if (item.coa.type === 'ACCOUNT') {
            destAcct = item.coa.id;
            altAmount = item.amount;
          }
        });
      } else {
        destAcct = txn.coa.id;
      }
      if (destAcct) {
        const state = store.getState();
        const foundTxn = transactionsUtils.findMatchingTransferTx(txn, getTransactionsByAccountId(state).get(destAcct), altAmount)?.txn;
        if (foundTxn) {
          setWaitingForTransfer(true);
          setTimeout(() => {
            setOtherTransfer(foundTxn);
            setWaitingForTransfer(false);
          }, 500);
        }
      }
    }
  };

  // #   Recurring Options
  // - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // not memoizing because closeAll always different
  const viewRecurring = () => {
    // TODO REMOVE_ACME
    // dispatch(showViewSeriesDialog(txn));
    closeAll();
  };

  const unlinkRecurring = useCallback(() => {
    if (txn.stModelId) {
      qDataPerformTransactionAction(
        { action: 'unlink-to-scheduled-txn', id: txn.id },
        { undo: { userMessage: 'Transaction unlinked.' } }
      );
      // todo : once matchedTxnId is set
      //  if matched or source === 'SCHEDULED_TRANSACTION', replace txn ID with that so that we go to the bank-downloaded txn
      setTxn(txn.set('stModelId', undefined));
      setIsBill(false);
    }
  }, [qDataPerformTransactionAction, setIsBill, txn]);


  const unMarkAsBill = useCallback(() => setIsBill(false), [setIsBill]);

  const markRecurring = useCallback(() => setShowRecurringOptions(true), []);

  const stopRecurring = useCallback(() => setShowRecurringOptions(false), []);

  const createTxn = () => {
    log.log('\nCreating txn!');
    setWaitingForTxn(true);
    const clientId = uuidv4().toUpperCase();
    setTxnClientId(clientId);
    qDataSaveTransactions([currentTxn.set('clientId', clientId)]);
  };

  const startRecurringSelect = () => {
    setRecurringSelect(true);
  };
  const exitRecurringSelect = () => {
    setRecurringSelect(false);
  };
  const createRecurring = () => {
    exitRecurringSelect();
    if (create) {
      createTxn();
    } else {
      setEditRecurring(true);
      dispatch(scheduledTransactionsActions.createBillScoutSchedule({ clientId: uuidv4().toUpperCase(), id: txn.id }));
    }
  };

  const saveRecurring = () => {
    log.debug('\nSaving recurring');
    setEditRecurring(false);
    setShowRecurringOptions(false);
    setRecurringSelect(false);
  };

  const linkTxn = (recurringId) => {
    const txnToUse = createdTxn || currentTxn;
    const modelId = recurringId || modelIdToLink;
    if (txnToUse?.id && modelId) {
      log.log('\nLinking txn');
      setModelIdToLink(null);
      setWaitingForTxn(true);
      qDataPerformTransactionAction({ action: 'link-to-scheduled-txn', id: txnToUse.id, otherId: modelId },
        { undo: { userMessage: 'Transaction linked.' } });
    }
  };
  const linkRecurring = (item) => {
    log.log('\nLinking recurring');
    if (create) {
      createTxn();
      setModelIdToLink(item.id);
    } else {
      linkTxn(item.id);
    }
    resetToEdit();
  };
  const doOneTimeBill = () => {
    setIsBill(true);
    resetToEdit();
  };

  // # ===================================================================================== #
  // # -----                                  Effects                                  ----- #
  // # ===================================================================================== #

  // waiting for QCS processor (only in create)
  useEffect(() => {
    if (create && createdTxn && isExistingTxn(createdTxn) && waitingForTxn) { // found txn
      setTxn(createdTxn);
      setWaitingForTxn(false);
      setTxnClientId(null);
      if (modelIdToLink) { // if link - link txn
        linkTxn(modelIdToLink);
      } else { // do bill scout
        setEditRecurring(true);
        dispatch(scheduledTransactionsActions.createBillScoutSchedule({ clientId: uuidv4().toUpperCase(), id: createdTxn.id }));
      }
    }
  }, [createdTxn]); // eslint-disable-line react-hooks/exhaustive-deps

  // update txn when we get a new record from QCS
  useEffect(() => {
    if (connectedTxn) {
      if (waitingForTxn && !create && connectedTxn?.stModelId) { // if the connected txn updates after link and is not deleted, stop waiting
        setWaitingForTxn(false);
      }
      log.info('new QCS txn', connectedTxn?.toJS?.());
      // if just unlinked a scheduled transaction and QCS restored the reminder, switch the UI over to display
      // the just-unlinked bank-downloaded txn
      const matchedTxnId = txn.matchedTxn?.id;
      if ((connectedTxn.source === 'SCHEDULED_TRANSACTION_PENDING') && matchedTxnId && (txn.id !== matchedTxnId)) {
        log.info('reminder restored, switching to txn with ID:', matchedTxnId);
        setTxn(txn.set('id', matchedTxnId));
      } else { // this is likely after a standard link-to-recurring or an unlink (that wasn't a matched txn that QCS auto-restored as a reminder)
        log.info('updating to new QCS txn');
        setTxn(connectedTxn);
        if (connectedTxn.stModelId) { // update bill flag if has modelId
          setIsBillInternal(connectedTxn.isBill);
        }
      }
    } else if (!create && waitingForTxn) { // txn was deleted after being auto-matched on link to recurring
      log.log('Txn was auto-deleted after link to recurring!\nLooking for auto-matched reminder');
      const state = store.getState();
      const autoMatchedTxn = getTxnByMatchedTxnAndAccountId(state, currentTxn.id, txn?.accountId);
      if (autoMatchedTxn) { // found reminder, so update txn with reminder info.
        log.log('Found reminder!\nSwitching to matched txn');
        setWaitingForTxn(false);
        setTxn(autoMatchedTxn);
        setIsBillInternal(autoMatchedTxn.isBill);
      } else { // txn was deleted but cannot find reminder it matched to
        log.warn('Looked for auto-matched reminder after txn was deleted, but could not find it!\n' +
          'Closing dialog to prevent editing a deleted txn');
        closeAll();
      }
    }
  }, [connectedTxn]); // eslint-disable-line react-hooks/exhaustive-deps

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  // # ===================================================================================== #
  // # -----                                Renderers                                  ----- #
  // # ===================================================================================== #

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const cpSection = useMemo(() => {
    const { cpData } = txn;
    if (!cpData) {
      return null;
    }
    const bankName = getAccountString(txn.accountId);

    let displayDate;
    if (cpData.postedOn) {
      displayDate = DateTime.fromFormat(cpData.postedOn || '', 'yyyy-M-d');
    } else {
      displayDate = DateTime.fromISO(txn.postedOn);
    }

    return (
      <Typography
        variant="caption"
        color="textSecondary"
        display="block"
        className={classes.cpData}
      >
        {`Appears on your ${bankName} statement as `}
        <Link
          className={classes.link}
          id="statement-name-link"
          onClick={() => clickPayeeLink(cpData.payee)}
        >
          {cpData.payee}
          <OpenNewIcon fontSize="small" className={classes.openIcon} />
        </Link>
        {` on ${displayDate.toLocaleString(DateTime.DATE_MED)}. `}
      </Typography>
    );
  }, [classes, txn]);

  const flagRow = useMemo(() => {
    if (!showFlag && !showReview) return null;
    return (
      <div className={classes.row}>
        {showFlag &&
        <FlagField
          value={flag}
          onChange={setFlag}
          className={classes.flagField}
        />}

        {showReview &&
          <ReviewField
            value={reviewed}
            onChange={updateReviewed}
            className={classes.reviewField}
          />}
      </div>
    );
  }, [classes, flag, reviewed, setFlag, updateReviewed, showFlag, showReview]);

  const accountRow = useMemo(() => (
    <div className={classes.row}>
      <AccountField
        initialAccountId={accountId}
        onSelected={selectAccount}
        label="Account"
        variant={isManual ? 'outlined' : 'standard'}
        margin="normal"
        className={classes.leftField}
        readOnly={!isManual}
        id="account-select"
      />

      {/* Status Field */}
      <StatusField
        status={status}
        onChange={setStatus}
        className={classes.alignedField}
        readOnly={!create && (status === 'CLEARED')}
      />

      {/* Date Field */}
      <DateField
        date={date}
        onChange={setDate}
        className={classes.rightField}
        allowInvalid
      />
    </div>
  ), [classes, accountId, selectAccount, isManual, status, setStatus, create, date, setDate]);


  // todo: if we change QCS logic to allow scheduled transaction unlink (turn to manual),
  //  we can change this to a manual txn wait check instead
  const acceptedSchedule = (txn.source === 'SCHEDULED_TRANSACTION') && (getMatchState(txn) !== 'matched');

  const UnlinkWrapper = acceptedSchedule ? QTip : React.Fragment;
  const unlinkProps = acceptedSchedule ? {
    title: 'This is a reminder for a recurring bill. ' +
      'You can keep this transaction and it may match to a bank downloaded transaction when it comes in, or you can delete the transaction if you no longer want this reminder.',
    placement: 'right',
    arrow: true,
  } : {};

  // not memoizing because viewRecurring is always new
  const recurringSection = () => {
    if (model && (txn.source !== 'SCHEDULED_TRANSACTION_PENDING')) {
      return (
        <div className={classes.seriesColumn}>
          <Typography variant="body1">
            {isIncome ? 'Recurring income' : 'Recurring bill'}
          </Typography>

          <div className={classes.line}>
            <Typography variant="body2" noWrap className={classes.seriesLine}>
              Series:&nbsp;
              <Link
                id={'view-series-button'}
                onClick={viewRecurring}
                className={classes.link}
              >
                {model?.transaction?.payee || 'No Payee?'}
              </Link>
            </Typography>

            <UnlinkWrapper {...unlinkProps}>
              <QButton
                id="unlink-button"
                onClick={unlinkRecurring}
                disabled={acceptedSchedule}
              >
                <UnlinkIcon className={classes.spacedIcon} /> Unlink
              </QButton>
            </UnlinkWrapper>
          </div>
        </div>
      );
    }
    if (isBill) {
      return (
        <Typography variant="body1" className={classes.oneTimeRow}>
          One-time bill&nbsp;
          <QButton
            id="unmark-bill"
            onClick={unMarkAsBill}
          >
            <RemoveCircleIcon className={classes.spacedIcon} /> Unmark as bill
          </QButton>
        </Typography>
      );
    }
    return null;
  };

  // not memoizing because the amountField can change it's internal state and that wants to be preserved (not removed on a memoized rerender)
  const payeeRow = (
    <div className={classes.thinRow}>
      <SPayeeField
        defaultValue={payee}
        onChange={setPayee}
        allowCreateRule={Boolean(txn.cpData)}
        popupIcon={null}
        className={classes.payeeField}
        id="payee-field-editable"
        autoFocus={!focusSplits}
      />

      {/* Amount Field */}
      <QAmountField
        label="Amount"
        margin="normal"
        textFieldVariant="outlined"
        editable
        submitOnBlur
        value={amount}
        onChange={updateAmount}
        onSubmit={updateAmount}
        expectedSign={isIncome ? 'positive' : 'negative'}
        className={classes.rightField}
        id="details-amount"
      />
    </div>
  );

  const isMatched = useMemo(() => getMatchState(txn) === 'matched' && !txn.stModelId, [txn]);
  // not memoizing because it requires currentTxn
  const renderRefundLine = () => (
    <div className={classes.refundLine}>
      <Typography variant="caption">
        {isMatched &&
        <>
          {txn.source === 'USER_ENTERED' ?
            'A manual and bank transaction were combined to create this entry. '
            :
            'A pending and cleared transaction were combined to create this entry. '}
          <Link
            className={classes.link}
            onClick={unMatchTxn}
            id="unmerge-button"
          >
            Unmerge
          </Link>
          <br />
        </>}

        {supportedTransfer &&
        <>
          Both sides of transfer detected&nbsp;
          <Link
            className={classes.link}
            onClick={goToTransfer}
            id="transfer-other-side-button"
          >
            Go to other side
          </Link>
        </>}
      </Typography>
    </div>
  );

  const bottomSection = useMemo(() => (
    <div className={classes.bottomSection}>
      {/* Visibility */}
      <VisibilitySection
        excludeF2S={excludeF2S}
        setExcludeF2S={setExcludeF2S}
        excludeReports={excludeReports}
        setExcludeReports={setExcludeReports}
      />

      <div className={classes.rightSide}>
        <Typography variant="body2" className={classes.greyText}>
          Additional Info
        </Typography>

        <BoxField
          id="memo-field"
          label="Note"
          placeholder="Add your note here"
          value={memo}
          onChange={setMemo}
          margin="dense"
        />

        <AttachmentsField
          id="attachments-field"
          attachments={attachments}
          onChange={(_event, newAttachments, _reason, _details) => setAttachments(newAttachments)}
          box
          margin="dense"
        />
      </div>
    </div>
  ), [attachments, classes, excludeF2S, excludeReports, memo, setAttachments, setExcludeF2S, setExcludeReports, setMemo]);


  // +++            Recurring Section              +++
  // - - - - - - - - - - - - - - - - - - - - - - - - -

  // not memoizing because it needs 'doOneTimeBill' which requires currentTxn which changes on every state change
  const renderRecurringOptions = () => {
    let recurringName;
    let copyText;
    if (isTransfer) {
      recurringName = 'transfer';
      copyText = 'transfers';
    } else {
      recurringName = isIncome ? 'income' : 'bill or subscription';
      copyText = isIncome ? 'income' : 'bills and subscriptions';
    }

    return (
      <div className={classes.recurringRoot}>
        <img
          alt="Edit Icon"
          className={classes.img}
          src={plannedSpendingOrchid}
        />

        <div className={classes.recurringColumn}>
          <Typography className={classes.recurringSubtitle}>
            {`Linking transactions to recurring ${copyText} helps with planning and tracking payments in Spending Plan.` +
            ' (You can manually link to an existing recurrence if we weren’t able to auto-match it.)'}
          </Typography>

          <QPanelButton
            title={`Create a new recurring ${recurringName}`}
            subtitle={'Set the frequency to plan for upcoming payments.'}
            startIcon={<RepeatIcon />}
            onClick={createRecurring}
            id="create-new-recurring-button"
          />
          <QPanelButton
            title={`Link to existing ${recurringName}`}
            subtitle={'Assign this transaction to a recurrence that’s already set up.'}
            startIcon={<LinkIcon />}
            onClick={startRecurringSelect}
            id="link-existing-button"
            disabled={!linkableScheduledTransactions?.size}
          />

          <br />

          {!isIncome && !isTransfer &&
          <QPanelButton
            title="Mark as one-time bill"
            subtitle={'No future payments, but added to bills in your Spending Plan.'}
            startIcon={<RepeatOneIcon />}
            onClick={doOneTimeBill}
            hideArrow
            id="mark-as-bill-button"
          />}
        </div>
      </div>
    );
  };



  // # ===================================================================================== #
  // # -----                               Main Return                                 ----- #
  // # ===================================================================================== #

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  let title = create ? 'Create transaction' : 'Transaction detail';
  if (showRecurringOptions) {
    if (isIncome) {
      title = 'Mark as recurring income';
    } else {
      title = 'Mark as bill or recurring';
    }
    if (isTransfer) {
      title = 'Mark as recurring transfer';
    }
  }

  const showEditRecurring = Boolean(showRecurringOptions && editRecurring);
  const showRecurringSelect = Boolean(showRecurringOptions && recurringSelect);

  const validAttachments = !attachments || !attachments.find((item) => item && (!item.id || !item.url));
  const completeEnough = Boolean(validPayee && accountId && (coa || split) && date?.isValid && validAttachments);

  const waiting = waitingForTxn || waitingForTransfer || (editRecurring && !model);

  let loadingLine;
  if (waitingForTransfer) {
    loadingLine = 'Finding transfer';
  } else {
    loadingLine = (modelIdToLink || (!create && waitingForTxn)) ? 'Assigning to recurring series' : 'Creating recurring series';
  }

  return (otherTransfer ?
    <DetailsDialog
      txn={otherTransfer}
      onClose={closeAll}
    />
    :
    <>
      <StdDialog
        title={title}
        maxWidth="md"
        fullWidth
        sharedcomponentid={'TRANSACTION_DETAILS_DIALOG'}
        onBack={stopRecurring}
        showBackButton={showRecurringOptions}
        onClose={closeAll}
        open={open && !showEditRecurring && !showRecurringSelect && !waiting && !creatingCategoryRule && !creatingRenameRule}
      >
        <Dump obj={txn} />

        {showRecurringOptions ?
          renderRecurringOptions()
          :
          <div className={classes.root}>
            {/* Appears as section */}
            {cpSection}

            {/* Flag/reviewed section */}
            {flagRow}

            {/* Account, status, date row */}
            {accountRow}

            {/* Recurring section */}
            {recurringSection()}

            {/* Payee + Amount row */}
            {payeeRow}
            {renderRefundLine()}

            {/* Category + Tags row */}
            <CategorySection
              coa={coa}
              setCoa={updateCOA}
              tags={tags}
              setTags={setTags}
              split={split}
              setSplit={setSplit}
              amount={amount}
              focusSplits={focusSplits}
              validPayee={validPayee}
              setCoaInternal={setCoa} // so that we dont trigger rule creation on resetting splits
            />

            {/* Divider */}
            <div className={classes.divider} />

            {/* Visibility + Info section */}
            {bottomSection}

            {/* Buttons */}
            <div className={classes.actions}>
              {!model && !isBill &&
                <QButton
                  id="make-recurring-button"
                  onClick={markRecurring}
                  className={classes.recurringButton}
                  disabled={!completeEnough}
                >
                  <RepeatIcon className={classes.recurringIcon} /> MARK AS BILL OR RECURRING
                </QButton>}

              {!create &&
                <QButton
                  id="delete-txn"
                  aria-label="delete-txn"
                  onClick={onDelete}
                  disabled={create}
                  variant="delete"
                  disableOutline
                  className={classes.deleteButton}
                >
                  Delete
                </QButton>}

              <QButton
                type="submit"
                id="save-txn"
                variant="contained"
                disabled={!isDirty || !completeEnough}
                onClick={onSave}
              >
                {create ? 'Create' : 'Update'}
              </QButton>
            </div>
          </div>}
      </StdDialog>

      <ScheduledTransactionEditDialog
        fakeCreate
        open={showEditRecurring && !waiting}
        scheduledTransaction={model}
        onSubmit={saveRecurring}
        onClose={closeAll}
        onBack={exitRecurringCreate}
        title={'Create series'}
        sharedcomponentid={'SCHEDULED_TRANSACTION_EDIT_DIALOG'}
      />

      <SelectScheduledTransactionDialog
        open={showRecurringSelect && !waiting}
        modelsOverride={linkableScheduledTransactions}
        onClose={closeAll}
        onSelected={linkRecurring}
        onCreate={createRecurring}
        showBackButton
        onBack={exitRecurringSelect}
        txn={currentTxn}
      />

      {waiting &&
      <Dialog
        open
        maxWidth="xs"
        fullWidth
        onClose={closeAll}
      >
        <LoadingView
          title={loadingLine}
          size={65}
          style={{ margin: '64px 48px' }}
        />
      </Dialog>}

      {creatingCategoryRule &&
      <MemorizedRuleEditDialog
        dialogId="add-memorized-rule"
        memorizedRule={{
          payee,
          coa,
        }}
        fromTxn
        onClose={() => setCreatingCategoryRule(false)}
      />}

      {creatingRenameRule &&
      <RenameRuleEditDialog
        renameRule={{
          renamePayeeFrom: normalizePayeeQCS(txn?.cpData?.inferredPayee),
          renamePayeeTo: payee,
        }}
        transaction={txn}
        applyRule
        onClose={() => setCreatingRenameRule(false)}
      />}

    </>
  );
}

DetailsDialog.defaultProps = {
  // txn: incomeTxn,
};

DetailsDialog.propTypes = {
  txn: PropTypes.object,
  onClose: PropTypes.func,
  onlyRecurring: PropTypes.bool,
  defaultAccountId: PropTypes.string,
  focusSplits: PropTypes.bool,
};

export default React.memo(DetailsDialog);
