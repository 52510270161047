import { handleActions, combineActions } from 'redux-actions';

import { getLogger } from '@quicken-com/react.utils.core';
import { resourceStoreTypes, resourceUpserters, resourceUndoUpserters } from '@quicken-com/react.flux.core';

import * as actions from './budgetItemsActions';

const log = getLogger('data/budgetItems/budgetItemsReducer.js');

export const BUDGET_ITEMS_REDUCER_KEY = 'budgetItemsStore';

const initialState = resourceStoreTypes.mkQcsSyncResourceStore();

export const budgetItemsReducer = handleActions({

  // GET BUDGET ITEMS =========================================
  [actions.getBudgetItemsAction]:
    (state) => {
      log.log('getBudgetItemsAction...');
      return state.merge({ isLoading: true });
    },
  [actions.getBudgetItemsSuccessAction]:
    (state, { payload }) => {
      log.log('getBudgetItemsSuccessAction...\n', payload);
      return resourceUpserters.upsertResources(state, payload);
    },
  [actions.getBudgetItemsFailureAction]:
    (state, { payload: error }) => resourceUpserters.completeWithError(state, error),


  // UPDATE BUDGET ITEMS =========================================
  [actions.updateBudgetItemsAction]:
    (state, action) => {
      log.log('updateBudgetItemsAction...\n', action);
      return resourceUndoUpserters.upsertResourcesSetupUndo(state, action, actions.updateBudgetItemsAction, false);
    },
  [actions.updateBudgetItemsSuccessAction]:
    (state, action) => {
      log.log('updateBudgetItemsSuccessAction...\n', action);
      return resourceUpserters.upsertResources(state, { resources: action.payload });
    },

  // CREATE BUDGET ITEMS =========================================
  [actions.createBudgetItemsAction]: (state) => {
    log.log('createBudgetItemsAction...');
    return state.merge({ isLoading: true });
  },
  [actions.createBudgetItemsSuccessAction]:
    (state, action) => {
      log.log('createBudgetItemsSuccessAction...\n', action);
      return resourceUndoUpserters.upsertResourcesSetupUndo(state, action, actions.deleteBudgetItemsAction, true);
    },

  // DELETE BUDGET ITEMS ========================================= (only used by undo at this time)
  [actions.deleteBudgetItemsAction]:
    (state, action) => resourceUndoUpserters.upsertResourcesSetupUndo(state, action, actions.createBudgetItemsAction, false),

  [actions.deleteBudgetItemsSuccessAction]:
    (state, action) => {
      log.log('deleteBudgetItemsSuccessAction...\n', action);
      return resourceUpserters.upsertResources(state, { resources: action.payload });
    },

  // UPDATE SINGLE BUDGET ITEM =========================================
  [actions.updateBudgetItem]:
    (state, action) => resourceUndoUpserters.upsertResourceSetupUndo(state, action, actions.updateBudgetItem),
  [actions.updateBudgetItemSuccess]:
    (state, { payload }) => resourceUpserters.upsertResource(state, payload),


  [combineActions(
    actions.updateBudgetItemsFailureAction,
    actions.updateBudgetItemFailure,
    actions.createBudgetItemsFailureAction,
    actions.deleteBudgetItemsFailureAction,
  )]: (state, { payload: error, asyncDispatch }) => {
    asyncDispatch(actions.getBudgetItemsAction());
    return resourceUpserters.resetResources(state, error);
  },

}, initialState);

export default budgetItemsReducer;
