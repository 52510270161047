import compose from 'utils/compose';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import { getCategoryBudgetTransactions } from 'data/budgets/budgetsSelectors';
import { removeDialog } from 'data/rootUi/actions';

import IndividualCategoryView from './individualCategoryView';
import styles from './styles';

const mapStateToProps = (state, props) => ({
  data: getCategoryBudgetTransactions(state, props.budgetId, props.categoryId, false, null, null, props.allCategoryIdsOfBudget, props.isEverythingElse, props.displayLabel),
  clickedMonthDate: props?.clickedMonthDate,
});

const mapDispatchToProps = (dispatch) => ({
  removeDialog: () => dispatch(removeDialog()),
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps)
)(IndividualCategoryView);
