import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { isAcme } from 'isAcme';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { Tabs, Tab } from '@mui/material';

import compose from 'utils/compose';
import QPreferences from 'components/QPreferences';
import { getBalancesForAccountsAtDates } from 'data/accountBalances/selectors';
import { tracker } from '@quicken-com/react.utils.core';
import QPopper from 'components/QPopper';

import CalDay from '../CalDay';
import { calDaysGridStyles } from '../../styles';
import CalTxnsModal from '../CalTxnsModal';

const tabs = ['TRANSACTIONS', 'BALANCES'];

const useStyles = makeStyles(calDaysGridStyles);

const CalDaysGrid = (props) => {
  const classes = useStyles();
  const {
    daysTxsMap, curMonth, onTransactionClick, accountIds,
    datasetPreferences: { calendarTransaction: calendarTransactionPref },
  } = props;

  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [popperData, setPopperData] = useState(null);
  const [showTxnModal, setShowTxnModal] = useState(null);
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const todayDate = DateTime.local().toISODate();

  const setPopper = useCallback((popperEl, data) => {
    setAnchorEl(popperEl);
    setPopperData(data);
    if (data) {
      tracker.track(tracker.events.calendarOnOpenPopover, {
        type: data.hoveringContent === 'full' ? 'Transactions' : 'Balances',
      });
    } else {
      setCurrentTab(tabs[0]);
    }
  }, [setAnchorEl, setPopperData, setCurrentTab]);

  const onGoToBills = useCallback((id) => {
    if (isAcme) {
      history.replace(`/settings/Recurring/${id}`);
    } else {
      history.replace(`/bills-and-income?selectNode=${id}`);
    }
    tracker.track(tracker.events.calendarOnGoToBills, { schTxn: id });
  }, [history]);

  const onMouseLeave = useCallback(() => {
    setPopper(null, null);
  }, [setPopper]);

  const onTxnsModalOpen = useCallback((date) => {
    setShowTxnModal(date);
    tracker.track(tracker.events.calendarTxnModalOpen, { date: date.toISODate() });
  }, [setShowTxnModal]);

  const onTxnsModalClose = useCallback(() => {
    setShowTxnModal(null);
  }, [setShowTxnModal]);

  const cachedCalendarTransactionPref = useMemo(() => calendarTransactionPref, [calendarTransactionPref]);

  const balancesData = useSelector((state) => {
    const canGetData = cachedCalendarTransactionPref.showDailyBalances && daysTxsMap;
    return canGetData ? getBalancesForAccountsAtDates(state, {
      accountIds, dates: [...daysTxsMap.keys()],
    }) : null;
  });

  const balances = useMemo(() => balancesData, [balancesData]);
  const renderedDays = useMemo(() => (
    daysTxsMap && daysTxsMap.map((txnList, date) => {
      const dateLux = DateTime.fromISO(date);
      const currentMonth = dateLux.month === curMonth.date.month;
      const currentDay = todayDate === date;
      const balanceForDay = balances && (balances.has(date) ? balances.get(date) : undefined);
      return (
        <CalDay
          key={dateLux.toISODate()}
          setPopper={setPopper}
          day={dateLux.day}
          date={dateLux}
          currentMonth={currentMonth}
          currentDay={currentDay}
          txnList={txnList}
          balances={balanceForDay}
          onTxnsModalOpen={onTxnsModalOpen}
        />
      );
    }).toList()
  ), [daysTxsMap, balances, curMonth, onTxnsModalOpen, todayDate, setPopper]);

  const handleTabChange = (e, tab) => {
    setCurrentTab(tab);
  };

  const renderPopperContent = useCallback(() => (
    <div className={classes.popperRoot}>
      { popperData?.content?.header }
      <Tabs 
        value={currentTab} 
        onChange={handleTabChange}
        classes={{
          indicator: {
            bottom: 0,
          },
        }}
        className={classes.tabs}
        variant="fullWidth"
      >
        {
          tabs.map((tab) => (
            <Tab label={tab} index={tab} value={tab} />
          ))
        }
      </Tabs>
      { currentTab === tabs[0] && popperData?.content?.transactions }
      { currentTab === tabs[1] && popperData?.content?.balance }
    </div>
  ), [popperData, classes, currentTab]);

  const openPopper = useMemo(() => Boolean(anchorEl) && Boolean(popperData), [anchorEl, popperData]);

  return (
    <>
      <div className={classes.days} onMouseLeave={onMouseLeave}>
        {daysTxsMap && renderedDays}
        <QPopper
          id="transaction-calendar-popper"
          open={openPopper}
          anchorEl={anchorEl}
          placement="top"
          disablePortal
          transition
          transitionEffect="fade"
          modifiers={[
            {
              name: 'offset',
              enabled: true,
              options: {
                offset: [0, 10],
              },
            },
          ]}
        >
          {renderPopperContent()}
        </QPopper>
      </div>
      <p className={classes.footnote}>The Calendar View only uses information from your banking accounts. It does not include investment account information.</p>
      {showTxnModal && (
        <CalTxnsModal
          onClose={onTxnsModalClose}
          date={showTxnModal.toLocaleString(DateTime.DATE_HUGE)}
          txns={daysTxsMap.get(showTxnModal.toISODate())}
          onGoToRegister={onTransactionClick}
          onGoToBills={onGoToBills}
        />
      )}
    </>
  );
};

CalDaysGrid.propTypes = {
  daysTxsMap: PropTypes.object,
  curMonth: PropTypes.object,
  accountIds: PropTypes.object,
  onTransactionClick: PropTypes.func,
  datasetPreferences: PropTypes.object,
};

CalDaysGrid.whyDidYouRender = true;

export default compose(
  QPreferences(),
)(React.memo(CalDaysGrid));
