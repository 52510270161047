// @flow
import { combineActions, handleActions } from 'redux-actions';

import { accountsActions } from '@quicken-com/react.flux.accounts';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';
// import { getLogger } from '@quicken-com/react.utils.core';

import { getQcsAggregationAction } from 'data/aggErrors/errors';
import {
  addInstitutionLoginAndAccountsResponse,
  institutionLoginSetCredentialsBlob,
  refreshAccountsCredentialsProvided,
  refreshAccountsMfaFormSubmitResponse,
} from 'data/institutionLogins/actions';

import * as actions from './actions';
import { mkInstitutionLoginUpserterStore, mkInstitutionLoginUpserterData } from './types';

// const log = getLogger('AccountDiscovery/reducer');

const getDataForScope = (state, scope) => {
  assert(scope, 'Cannot access InstitutionLoginUpserterisAuthenticationDoneStore without a scope');
  return state.upserterData.get(scope);
};

const setDataForScope = (state, scope, data) => {
  assert(scope, 'Cannot access InstitutionLoginUpserterStore without a scope');
  return state.set('upserterData', state.upserterData.set(scope, data));
};

const mergeDataForScope = (state, scope, data) => {
  assert(scope, 'Cannot access InstitutionLoginUpserterStore without a scope');
  return setDataForScope(
    state,
    scope,
    (assert(getDataForScope(state, scope), 'can not get data for scope (mergeDataForScope)') ?? mkInstitutionLoginUpserterData())
      .merge(data),
  );
};

export const reducer = handleActions({

  [actions.accountDiscoverySetup]: (state, { payload, meta: { scope } }) =>
    setDataForScope(state, scope, mkInstitutionLoginUpserterData(payload)),

  [actions.accountDiscoveryCleanup]: (state, { meta: { scope } }) =>
    state.set('upserterData', state.upserterData.remove(scope)),

  [actions.institutionSelected]: (state, { payload: { channel, institution }, meta: { scope } }) => {
    const data = getDataForScope(state, scope);

    const institutionLogin = data.institutionLogin ? data.institutionLogin.set('institutionId', institution.id) : null;
    return mergeDataForScope(state, scope, { channel, institution, institutionLogin });
  },

  [actions.accountDiscoveryCredentialsFormSubmit]: (state, { meta: { scope } }) =>
    mergeDataForScope(state, scope, {
      error: false,
      isSubmitting: true,
    }),

  [actions.accountDiscoveryCredentialsFormSubmitResponse]: {
    next(state, { payload: { institutionLogin }, meta: { scope } }) {
      return mergeDataForScope(state, scope, {
        institutionLogin,

        isPolling: true,
        isSubmitting: false,
      });
    },
    throw(state, { meta: { scope } }) {
      return mergeDataForScope(state, scope, { isSubmitting: false });
    },
  },

  [actions.accountDiscoveryMfaRequired]: (state, { payload: { mfaChallenge }, meta: { scope } }) =>
    mergeDataForScope(state, scope, {
      mfaChallenge,

      isPolling: false,
      isSubmitting: false,
    }),

  [actions.accountDiscoveryMfaFormSubmit]: (state, { meta: { scope } }) =>
    mergeDataForScope(state, scope, {
      error: null,

      isSubmitting: true,
    }),

  [actions.accountDiscoveryMfaFormSubmitResponse]: {
    next(state, { meta: { scope } }) {
      return mergeDataForScope(state, scope, {
        mfaChallenge: null,

        isPolling: true,
        isSubmitting: false,
      });
    },
    throw(state, { meta: { scope } }) {
      return mergeDataForScope(state, scope, {
        mfaChallenge: null,

        isSubmitting: false,
      });
    },
  },

  [actions.getPartnerAuthUris]: (state, { meta: { scope } }) =>
    mergeDataForScope(state, scope, {
      error: false,
    }),

  [actions.getPartnerAuthUrisSuccess]: (state, { payload: { partnerAuthUris }, meta: { scope } }) =>
    mergeDataForScope(state, scope, {
      partnerAuthUris,
    }),

  [actions.getPartnerAuthUrisFailure]: (state, { payload: { error }, meta: { scope } }) => {
    const status = error?.response?.status;
    const code = error?.response?.data?.errors?.[0]?.code;
    // const description = payload?.response?.data?.errors?.[0]?.description;
    return mergeDataForScope(state, scope, {
      error: {
        error: status,
        title: 'This Bank is not available right now',
        text: 'This Bank is not available right now.',
        status,
        statusCode: code,
        code: null,
        // actionLabel: 'Try Again',
        // action: refreshAccounts({ institutionLoginId: institutionLogin.id }),
        // showConfirmationDialog: true,
        // institutionLogin,
      },
    });
  },

  [actions.accountDiscoverySuccessWithAccounts]: (state, { payload, meta: { scope }, asyncDispatch }) => {
    const { institutionLogin, accountDiscoveryData } = payload;
    if (accountDiscoveryData.credentialsBlob) {
      asyncDispatch(
        institutionLoginSetCredentialsBlob({
          institutionLoginId: accountDiscoveryData.institutionLoginId,
          credentialsBlob: accountDiscoveryData.credentialsBlob,
        })
      );
    }
    return mergeDataForScope(state, scope, {
      institutionLogin,
      accountDiscoveryData,

      isAuthenticationDone: true,
      isPolling: false,
      isSubmitting: false,
    });
  },

  [actions.accountDiscoverySuccess]: (state, { payload: { institutionLogin }, meta: { scope } }) =>
    mergeDataForScope(state, scope, {
      institutionLogin,
      isAuthenticationDone: true,
      isPolling: false,
      isSubmitting: false,
    }),
  [actions.accountDiscoveryFailure]: (state, { payload: { adStatus, institutionLogin, isWebFirstDataset }, meta: { scope } }) => {
    let error = null;
    if (adStatus.status === 'AGGREGATOR_IN_ERROR') {
      const aggregator = adStatus.aggregators.get(0);
      if (aggregator) {
        const { aggStatus, cpAggStatusCode, userInstructions } = aggregator;
        error = getQcsAggregationAction(
          institutionLogin,
          aggStatus,
          cpAggStatusCode,
          featureFlagsSelectors.dangerouslyUseFeatureFlags(),
          userInstructions && new Error(userInstructions),
          isWebFirstDataset,
        );
      }
    }
    return mergeDataForScope(state, scope, {
      institutionLogin,
      error,

      isPolling: false,
      isSubmitting: false,
    });
  },
  [actions.accountDiscoveryError]: (state, { payload: { institutionLogin, error, isWebFirstDataset }, meta: { scope } }) => {
    const aggStatus = error?.aggStatus || error?.response?.status;
    const aggStatusCode = error?.aggStatusCode || error?.response?.data?.errors?.[0]?.code;

    const aggAction = getQcsAggregationAction(institutionLogin, aggStatus, aggStatusCode, featureFlagsSelectors.dangerouslyUseFeatureFlags(), error, isWebFirstDataset);
    return mergeDataForScope(state, scope, {
      institutionLogin,
      error: aggAction,

      isPolling: false,
      isSubmitting: false,
    });
  },

  [actions.accountDiscoveryStopped]: (state, { meta: { scope } }) =>
    mergeDataForScope(state, scope, {
      isPolling: false,
      isSubmitting: false,
    }),

  [actions.accountDiscoveryWidgetUpdateSuccess]:
    (state, { payload: { institutionLogin, accountDiscoveryData }, meta: { scope } }) =>
      mergeDataForScope(state, scope, {
        institutionLogin,
        accountDiscoveryData,

        isAuthenticationDone: true,
      }),

  [actions.discoverInstitutionLoginWithAccounts]:
    (state, { payload: { institutionLogin }, meta: { scope } }) =>
      mergeDataForScope(state, scope, {
        institutionLogin,
        isSubmitting: true,
      }),

  [combineActions(
    actions.discoverInstitutionLoginWithAccountsResponse
  )]: {
    next(state, { payload: { institutionLogin, accountDiscoveryData, updateStrategy = 'UPDATE' }, meta: { scope } }) {

      // If we haven't got an institutionId in the institutionLogin (we won't if new login), then
      // use one in current state object.
      //
      const data = getDataForScope(state, scope);
      const mergedInstitutionLogin = !institutionLogin.institutionId
        ? institutionLogin.set('institutionId', data.institutionId)
        : institutionLogin;

      return mergeDataForScope(state, scope, {
        institutionLogin: mergedInstitutionLogin,
        accountDiscoveryData,
        updateStrategy,

        isAuthenticationDone: true,
        isSubmitting: false,
      });
    },
    throw(state, { meta: { scope } }) {
      return mergeDataForScope(state, scope, {
        isSubmitting: false,
      });
    },
  },

  [actions.discoverInstitutionLoginWithAccountsDiscard]:
    (state, { _payload, meta: { scope } }) =>
      mergeDataForScope(state, scope, {
        institutionLogin: null,
        isSubmitting: false,
      }),

  [addInstitutionLoginAndAccountsResponse]: {
    next(state, { payload: { institutionLogin }, meta: { scope } }) {
      return mergeDataForScope(state, scope, {
        institutionLogin,

        isAccountUpsertingDone: true,
        isSubmitting: false,
      });
    },
    throw(state, { meta: { scope } }) {
      return mergeDataForScope(state, scope, {
        isSubmitting: false,
      });
    },
  },

  [refreshAccountsCredentialsProvided]: (state, { meta: { scope } }) => {
    if (!scope) return state;
    return mergeDataForScope(state, scope, { isAuthenticationDone: true });
  },

  [refreshAccountsMfaFormSubmitResponse]: {
    next(state, { payload: { institutionLoginId }, meta: { scope } }) {
      if (!scope) return state;
      const data = getDataForScope(state, scope);
      if (data.institutionLogin && data.institutionLogin.id === institutionLoginId) {
        return mergeDataForScope(state, scope, { isAuthenticationDone: true });
      }
      return state;
    },
  },

  [combineActions(accountsActions.batchAccountsSuccess, accountsActions.batchAccountsFailure)]: (state, { meta: { scope } }) => {
    if (!scope) return state;
    return mergeDataForScope(state, scope, {
      isAccountUpsertingDone: true,
      isSubmitting: false,
    });
  },

}, mkInstitutionLoginUpserterStore());

export const REDUCER_KEY = 'upsertInstitutionLoginStore';
export default reducer;
