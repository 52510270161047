// @flow
import React, { ComponentType } from 'react';
import compose from 'utils/compose';
import { connect } from 'react-redux';
import type { Dispatch } from 'react-redux';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';
import QButton from 'components/QButton';

import StdDialog, { isDialogCloseAction } from 'components/Dialogs/StdDialog';
import { removeDialog } from 'data/rootUi/actions';

import { styles } from './styles';
import type { ConfirmationDialogProps, ACTION_EVENT_TYPES } from './types';
export { DIALOG_TYPE, mkConfirmationDialogProps } from './types';


const CONTENT_VIEWS = {};

export const registerContentView = (key: string, component: ComponentType<any>) => {
  CONTENT_VIEWS[key] = component;
};

type Props = ConfirmationDialogProps & {
  dialogId: string,
  onClose: (ACTION_EVENT_TYPES) => void,
  dispatch: Dispatch,

  onRef: (any) => void,

  classes: Object,
  sharedcomponentid: ?string,

  removeDialog: () => void,
}

type State = {
  confirmButtonDisabled: boolean,
}

class ConfirmationDialog extends React.PureComponent<Props, State> {

  constructor(props) {
    super(props);

    this.state = {
      confirmButtonDisabled: Boolean(props.textEntryConfirmationText),
    };
  }

  handleTextEntryConfirmation = (event) => {
    const { textEntryConfirmationText } = this.props;
    this.setState({ confirmButtonDisabled: event.target.value !== textEntryConfirmationText });
  };

  handleClose = (event: Event, dEvent: ACTION_EVENT_TYPES) => {
    const {
      tertiaryAction,
      confirmAction,
      contentViewId,
      denyAction,
      dialogCloseAliasAction,
      dispatch,
      onClose,
      unregisterContentViewOnClose,
    } = this.props;

    onClose?.(event, dEvent);
    this.props.removeDialog(this.props.dialogId);

    if ((dEvent === 'CONFIRM' || (dialogCloseAliasAction === 'CONFIRM' && isDialogCloseAction(dEvent))) && confirmAction) {
      if (typeof confirmAction === 'function') {
        confirmAction(dEvent, event);
      } else {
        dispatch(confirmAction);
      }
    }
    if ((dEvent === 'DENY' || (dialogCloseAliasAction === 'DENY' && isDialogCloseAction(dEvent))) && denyAction) {
      if (typeof denyAction === 'function') {
        denyAction(dEvent, event);
      } else {
        dispatch(denyAction);
      }
    }
    if ((dEvent === 'APPLY_TO_ALL' || (dialogCloseAliasAction === 'APPLY_TO_ALL' && isDialogCloseAction(dEvent))) && tertiaryAction) {
      if (typeof tertiaryAction === 'function') {
        tertiaryAction(dEvent, event);
      } else {
        dispatch(tertiaryAction);
      }
    }
    if (contentViewId && unregisterContentViewOnClose) {
      delete CONTENT_VIEWS[contentViewId];
    }
  };

  render() {
    const { classes, content, contentViewId, confirmLabel, denyLabel, tertiaryLabel, textEntryConfirmationText, confirmButtonVariant, denyButtonVariant, tertiaryLabelVariant } = this.props;
    const { confirmButtonDisabled } = this.state;
    const isString = typeof this.props.content === 'string';

    return (
      <StdDialog
        open
        onClose={this.handleClose}
        showCloseButton
        sharedcomponentid={this.props.sharedcomponentid || 'DIALOG_CONFIRMATION'}
        title={this.props.title}
        maxWidth="md"
      >
        <DialogContent>
          {content && isString && (
            <Typography variant="body1" id="confirmation-dialog-description">
              {content}
            </Typography>
          )}
          {!isString && content}

          {contentViewId && CONTENT_VIEWS[contentViewId]}
          {textEntryConfirmationText && (
            <TextField
              autoFocus
              className={classes.confirmationTextField}
              id="text-confirmation"
              label={`Type ${textEntryConfirmationText} to confirm`}
              onChange={this.handleTextEntryConfirmation}
            />
          )}
        </DialogContent>
        <DialogActions>
          {tertiaryLabel &&
          <QButton
            className={classes.tertiary}
            variant={tertiaryLabelVariant || 'delete'}
            id={`confirm-${tertiaryLabel}`}
            disableOutline
            onClick={(event) => this.handleClose(event, 'APPLY_TO_ALL')}
          >
            {tertiaryLabel}
          </QButton>}
          {denyLabel && (
            <QButton
              className={classes.cancelButton}
              variant={denyButtonVariant || 'outlined'}
              id={`confirm-${denyLabel}`}
              onClick={(event) => this.handleClose(event, 'DENY')}
              color="primary"
            >
              {denyLabel}
            </QButton>
          )}
          <QButton
            variant={confirmButtonVariant || 'contained'}
            id={`confirm-${confirmLabel}`}
            disabled={confirmButtonDisabled}
            onClick={(event) => this.handleClose(event, 'CONFIRM')}
            color="primary"
          >
            {confirmLabel}
          </QButton>
        </DialogActions>
      </StdDialog>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    removeDialog: (props) => dispatch(removeDialog(props)),
    dispatch,
  };
}

export default compose(
  withStyles(styles, { name: 'ConfirmationDialog' }),
  connect(null, mapDispatchToProps),
)(ConfirmationDialog);
