// @flow
import { List, Record, RecordFactory } from 'immutable';
import { DateTime } from 'luxon';

export const BudgetNodeTypes = Object.freeze({
  GROUP: 'gr',
  GROUP_EE: 'gree',
  CATEGORY_ROLL_UP: 'crup',
  CATEGORY_EE: 'cee',
  CATEGORY_OTHER: 'coth',
  TRANSFER: 'xfer',
});

export const BudgetRollOverType = Object.freeze({
  NO_ROLLOVER: 'NO_ROLLOVER',
  ROLLOVER_POSITIVE: 'ROLLOVER_POSITIVE_ONLY',
  ROLLOVER_ALL: 'ROLLOVER_ALL',
});

// AccountNode
export const BudgetTreeNodeImmutable = Record({
  key: null, // '<BudgetNodeTypes>:<id>'
  type: null, // BudgetNodeTypes
  id: null,
  coas: null, // ListImmutable of COAs
  displayLabel: '',
  targetAmount: null,
  actualAmount: null,
  rolloverAmount: null,
  negate: -1,
  depth: 0,
  parent: null,
  children: List(),
  budgetItems: List(),
  isEverythingElse: false,
});

type BudgetScheduleType = {
  startDate: ?string,
  frequency: ?string,
  period: ?string,
  totalFrequncy: ?number,
};
export const mkBudgetSchedule: RecordFactory<BudgetScheduleType> =
  Record({
    startDate: DateTime.local().toFormat('yyyy-01-01'),
    frequency: 'YEARLY',
    period: 'MONTHLY',
    totalFrequency: 12,
  });

type BudgetRecordType = {
  id: ?string,
  clientId: ?string,
  createdAt: ?string,
  modifiedAt: ?string,
  name: ?string,
  catGroupListId: ?string,
  showCents: ?boolean,
  includeReminders: ?boolean,
  showToDateColumn: ?boolean,
  showParentCategoryRollup: ?boolean,
  showBalanceInFutureMonths: ?boolean,
  viewType: ?string,
  viewSubType: ?string,
  mode: ?string,
  schedule: BudgetScheduleType,
  currency: ?string,
  filterAccountIds: ?List,
  doNotIncludeSavingsToSavings: ?boolean,
  doNotIncludeSavingsToGoals: ?boolean,
  createMode: ?string,
  lastRanAt: ?string,
  isDeleted: ?boolean,
}
export type BudgetRecord = Record<BudgetRecordType>;
export const mkBudgetRecord: RecordFactory<BudgetRecordType> =
  Record({
    id: null,
    clientId: null,
    createdAt: null,
    modifiedAt: null,
    name: null,
    catGroupListId: null,
    showCents: false,
    includeReminders: true,
    showToDateColumn: false,
    showParentCategoryRollup: false,
    showBalanceInFutureMonths: false,
    viewType: 'GRAPH_VIEW',
    viewSubType: 'GRAPH_MONTHLY',
    mode: 'QWIN_CLASSIC',
    schedule: mkBudgetSchedule(),
    currency: undefined,
    filterAccountIds: List(),
    doNotIncludeSavingsToSavings: false,
    doNotIncludeSavingsToGoals: false,
    createMode: 'NONE',
    lastRanAt: null,
    isDeleted: false,
  });

type BudgetItemRecordType = {
    id: ?string,
    clientId: ?string,
    createdAt: ?string,
    modifiedAt: ?string,
    budgetId: ?string,
    startDate: ?string,
    amount: ?number,
    calculatedActualsAmount: ?number,
    calculatedRolloverAmount: ?number,
    groupId: ?string,
    type: ?string,
    coa: ?List,
    rolloverType: ?string,
    isContinued: ?boolean,
  }

export const mkBudgetItemRecord: RecordFactory<BudgetItemRecordType> =
  Record({
    id: null,
    clientId: null,
    createdAt: null,
    modifiedAt: null,
    budgetId: null,
    startDate: null,
    amount: 0,
    calculatedActualsAmount: 0,
    calculatedRolloverAmount: 0,
    groupId: null,
    type: null,
    coa: List(),
    rolloverType: '',
    isContinued: false,
  });
