import React, { forwardRef } from 'react';
import Box from '@mui/material/Box';

import { StyledMenuItem, StyledTypography } from './styles';

/* eslint react/prop-types: 0 */

const IconMenuItem = forwardRef(({ leftIcon, rightIcon, label, MenuItemProps, className, ...props }, ref) => (
  <StyledMenuItem
    {...MenuItemProps}
    ref={ref}
    className={className}
    {...props}
  >
    <Box sx={{ display: 'flex' }}>
      {leftIcon}
      <StyledTypography>{label}</StyledTypography>
    </Box>
    {rightIcon}
  </StyledMenuItem>
));

export default IconMenuItem;
