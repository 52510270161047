
import React from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import QCurrency from 'components/QCurrency';

const propTypes = {
  classes: PropTypes.object,
  amount: PropTypes.number,
  title: PropTypes.string,
  currency: PropTypes.string,
  disableColor: PropTypes.bool,
  formatAmount: PropTypes.func,
};

const BudgetSummaryItem = (props) => {
  const { title, amount, classes, currency, disableColor, formatAmount } = props;
  const colorClass = !disableColor && (amount < 0 ? 'negative' : 'positive');
  return (
    <div
      className={classes.summaryItem}
    >
      <Typography
        variant="body2"
        className={classes.summaryItemHeader}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        className={classNames(classes.summaryItemBody, colorClass)}
      >
        {formatAmount(amount, currency, true)}
      </Typography>
    </div>
  );
};

BudgetSummaryItem.propTypes = propTypes;
export default compose(
  QCurrency(),
)(BudgetSummaryItem);
