import { exists } from 'utils/utils';

const colorOptions = [
  '#FB7F79',
  '#A5A6A9',
  '#FC6D30',
  '#1793D2',
  '#FDA573',
  '#FEE38B',
  '#49B750',
  '#ADD7AD',
  '#474F57',
  '#1CB3C1',
  '#9478B9',
  '#84DAE0',
  '#3F5CAC',
  '#96A6D2',
  '#5B2C93',
  '#AA8FB6',
  '#75C2E7',
  '#2B2E34',
  '#F62F3C',
  '#A2A8AD',
  '#622770',
  '#81888E',
  '#FED449',
  '#AFB4B9',
];

export function colorLookup(index) {
  return colorOptions[index];
}


function getNextUnusedColor(prefsById) {

  let maxVal = 0;
  Object.keys(prefsById).forEach((key) => {
    const item = prefsById[key];
    maxVal = Math.max(item.color ? item.color : 0, maxVal);
  });
  return (maxVal + 1) % colorOptions.length;  // rotate through colors
}

export function initAccountPrefs(accounts, accountPrefs) {

  let dirty = false;

  try {

    let aPrefs = accountPrefs;

    // HACK ALERT
    // Until prefs allows us to delete a preference, I am marking this preference deleted with
    // the string 'clear'
    //
    let prefsById;

    if (aPrefs && (aPrefs !== 'clear')) {
      prefsById = aPrefs.byId;
    } else {
      prefsById = {};
      aPrefs = {};
    }

    accounts.forEach((item) => {

      const { id } = item;
      if (!exists(prefsById[id]) || !exists(prefsById[id].color)) {

        if (!prefsById[id]) {
          prefsById[id] = {};
        }
        prefsById[id].color = getNextUnusedColor(prefsById);
        dirty = true;
      }
    });

    if (dirty) {

      aPrefs.byId = prefsById;

      return aPrefs;

    }
    return null;

  } catch (e) {
    return null;
  }
}


