import { localPreferences, crashReporterInterface, tracker } from '@quicken-com/react.utils.core';

// verify (dev mode) - checks value and if value=false then log the message + popup alert; returns value back!
// verify (release mode) - checks value and if value=false then log the message; returns value back!
// assert (dev mode) - checks value and if value=false then log the message + popup alert + track assert; returns value back!
// assert (release mode) - checks value and if value=false then log the message + track assert; returns value back!

const { sprintf } = require('sprintf-js');

const getDescription = (message, ...substitutions) => {
  let description;

  if (typeof message === 'string') {
    if (substitutions?.length) {
      description = sprintf(message, ...substitutions);
    } else {
      description = message;
    }
  } else if (String(message) !== '[object Object]') {
    description = String(message);
  } else {
    description = JSON.stringify(message, null, 2);
  }

  return description;
};

export const verify = (assertion, message, ...substitutions) => {
  if (!assertion) {
    console.assert(assertion, message, ...substitutions); // eslint-disable-line no-console

    const description = getDescription(message, ...substitutions);
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////
//////////////////////////////////////////
//////////////////////////
/////////////////////
/////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
///////////////////////////////////////
//////////
////////////////////////////////////////////////////
//////////////////////////////////////////////
////////////////////////////////////////////////////////////////
///////
/////
/////////////

    tracker.track(tracker.events.assert, { description });
  }
  return assertion;
};

export const assert = (assertion, message, ...substitutions) => {
  const result = verify(assertion, message, ...substitutions);

  if (!result) {
    const description = getDescription(message, ...substitutions);
    const error = new Error(description);
    error.name = 'Assert';
    crashReporterInterface.reportError(error, (event) => { event.context = error.message; }); // eslint-disable-line no-param-reassign
  }

  return result;
};

if (window) {
  window.assert = assert;
  window.verify = verify;
}
