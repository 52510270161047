/* eslint-disable indent */

import React from 'react';
import QPureComponent from 'QPureComponent';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import compose from 'utils/compose';

import { datasetsActions, datasetsSelectors } from '@quicken-com/react.flux.datasets';
import { profileActions, profileSelectors } from '@quicken-com/react.flux.profile';
import { authSelectors } from '@quicken-com/react.flux.auth';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';

import { featurePages, navMainOrder } from 'containers/App/paths';

import quickenLogo from 'assets/thewhiteq.svg';
import HelpIcon from 'assets/nav-menu/help.inline.svg';

import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import withStyles from '@mui/styles/withStyles';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';

import { getPreferences } from 'data/preferences/actions';
import { getTopTierEntitlement, isAuthenticated } from 'data/entitlements/entitlementsSelectors';
import { getRulesById, getLoadPending as getRulesLoadPending, getIsLoading as getRulesIsLoading } from 'data/renameRules/selectors';
import { getMemorizedRulesById, getLoadPending as getMemorizedRulesLoadPending, getIsLoading as getMemorizedRulesIsLoading } from 'data/memorizedRules/selectors';

import QPreferences from 'components/QPreferences';
import QDialogs from 'components/QDialogs';
import GuidedTooltip from 'components/GuidedTooltip';
import NotificationsNavIcon from 'components/Notifications/NotificationsNavIcon';
import { extractDetailsForTracker } from 'containers/BudgetsV2/utils';
import { isAcme, isQuicken } from 'isAcme';
import { tracker } from '@quicken-com/react.utils.core';

import RefreshButton from './RefreshButton';
import QCardToggleButton from './QCardToggleButton';
import ProfileMenu from './ProfileMenu';
import { MainNavItem } from './MainNavItem';
import HelpSupportButton from './HelpSupportButton';

import { styles } from './styles';

class Header extends QPureComponent {

  constructor(props) {
    super(props);
    this.state = {
      popperAnchorEl: undefined,
      open: undefined,
      canOpen: true,
      showNewRuleTooltip: false,
    };
    this.settingsPageNavRef = React.createRef();
    this.settingsPageGuidedTooltipTimeout = null;
  }

  componentDidUpdate(prevProps) {
    if (isAcme  // only for simplifi
      && !this.props.datasetPreferences.dontShowNewRuleTooltip
      && (
        (this.props.renameRules.size > prevProps.renameRules.size && !prevProps.renameRulesLoading)
        || (this.props.memorizedRules.size > prevProps.memorizedRules.size && !prevProps.memorizedRulesLoading)
      ) // number of rules went up
      && !this.props.history?.location?.pathname?.includes?.('settings')) { // don't show if already on the settings page
      this.setState({ showNewRuleTooltip: true }); // eslint-disable-line
      this.settingsPageGuidedTooltipTimeout = setTimeout(() => {
        this.setState({ showNewRuleTooltip: false }); // eslint-disable-line
      }, 10000);
    }
  }

  goDash = () => {
    this.props.history.push('/');
  };

  //-------------------------------------------------------------------------------------------------------------------

  getSettingsPage = () => {
    const nav = featurePages.find((x) => x.path === '/settings');
    if (nav && (!nav.featureFlag || this.props.featureFlags.get(nav.featureFlag))) {
      return (
        <>
          <MainNavItem
            key="nav-item-settings"
            nav={nav}
            iconRef={this.settingsPageNavRef}
            selected={this.props.selected}
            classes={this.props.classes}
            iconSize={this.props.theme.components.navigation.iconSize}
            displayNode={
              this.props.selectedAccountNodes && nav.preserveDisplayNode ?
                this.props.selectedAccountNodes[nav.path] : 'all'
            }
            closeHeader={this.drawerCannotOpen}
            handleTracking={this.handleTracking}
          />
          <GuidedTooltip
            anchorEl={this.settingsPageNavRef.current}
            open={this.state.showNewRuleTooltip}
            placement={'right'}
            title="Rule created"
            body="Payee rename and category rules can be edited or deleted in Settings."
            cancelLabel="don't show again"
            continueLabel="got it"
            onCancel={() => {
              this.settingsPageGuidedTooltipTimeout && clearTimeout(this.settingsPageGuidedTooltipTimeout);
              this.setState({ showNewRuleTooltip: false });
              this.props.setDatasetPreference({ dontShowNewRuleTooltip: true });
            }}
            onContinue={() => {
              this.settingsPageGuidedTooltipTimeout && clearTimeout(this.settingsPageGuidedTooltipTimeout);
              this.setState({ showNewRuleTooltip: false });
            }}
            modifiers={{
              offset: {
                enabled: true,
                offset: '0,20',
              },
            }}
          />
        </>
      );
    }
    return null;
  };

  inProductNotificationsOnClick = (event) => {
    this.drawerCannotOpen();
    this.setState({
      showNotifications: !this.state.showNotifications,
    });
    event.stopPropagation();
  };

  inProductNotificationsButtonRef = (ref) => this.setState({ notificationsAnchorEl: ref });

  notificationsClose = () => this.setState({ showNotifications: false });

  drawerCannotOpen = () => {
    this.setState({
      canOpen: false,
    });
  };

  drawerCanOpen = () => {
    this.setState({
      canOpen: true,
    });
  };

  getNavbarStyle = (classes) => classNames(classes.navbar, !this.state.canOpen ? 'expand' : 'closed');

  onCloseHeader = () => {
    this.drawerCanOpen();
    this.props.onMouseLeave();
  }

  handleTracking = (pathClicked) => {
    if (pathClicked === '/budgets') {
      const detailsToTrack = extractDetailsForTracker(this.props.sharedPreferences);
      tracker.track(tracker.events.clickBudgetMenu, detailsToTrack);
    }
  };

  render() {
    super.render();

    const {
      authenticated, classes, theme, selected, onMouseEnter, onMouseLeave,
      showRefreshIcon, qCardsOn, hide, selectedAccountNodes, tier, inProductNotifications,
    } = this.props;

    return (
      <Slide in={authenticated} direction="right">
        <AppBar
          id="application-header-bar"
          elevation={0}
          position="fixed"
          className={hide ? classes.staticNav : this.getNavbarStyle(classes)}
          onMouseLeave={this.drawerCanOpen}
          onMouseEnter={this.drawerCannotOpen}
        >
          <div className={classes.appDiv}>
            <div
              className={classes.logoSection}
              style={{ cursor: hide && 'default' }}
            >
              <Button
                onClick={this.drawerCannotOpen}
                id="logo-nav"
                classes={{
                  root: classes.rootButton,
                  text: classNames({ [classes.textButton]: isQuicken }),
                }}
                component={
                  React.forwardRef((props, ref) => hide ?
                    <div
                      {...props}
                      ref={ref}
                    />
                    :
                    <NavLink
                      {...props}
                      innerRef={ref}
                    />)
                }
                to="/"
                style={{ cursor: hide && 'default' }}
              >
                <img
                  src={quickenLogo}
                  style={{ filter: 'grayscale(1)', height: 32 }}
                  alt="quicken logo, click to go home"
                />
              </Button>
            </div>
            <div
              className={classes.appBarContainer}
              onMouseEnter={hide ? undefined : onMouseEnter}
              onMouseLeave={hide ? undefined : onMouseLeave}
            >
              <Toolbar classes={{ root: classes.toolbar }}>


                <div className={classes.betaLabel}>
                  {`${this.props.datasetPreferences.kioskmode ? '*' : ''}`}
                </div>

                {
                  !hide &&
                    <div className={classes.navItemsHolder}>
                      <div>
                        <div className={classes.navPopover}>
                          {navMainOrder.map((navKey) => {
                            const nav = featurePages.find((x) => x.path === `/${navKey}`);
                            if (nav && (!nav.featureFlag || this.props.featureFlags.get(nav.featureFlag)) &&
                              (!nav.hideOnTiers.length || !nav.hideOnTiers.includes(tier?.id))) {
                              return (
                                <MainNavItem
                                  key={`nav-item-${nav.path}`}
                                  nav={nav}
                                  selected={selected}
                                  style={{ paddingBottom: 10 }}
                                  classes={classes}
                                  canOpenSubMenu={!this.state.canOpen}
                                  iconSize={theme.components.navigation.iconSize}
                                  displayNode={
                                    selectedAccountNodes && nav.preserveDisplayNode ?
                                      this.props.selectedAccountNodes[nav.path] : 'all'
                                  }
                                  closeHeader={this.onCloseHeader}
                                  handleTracking={this.handleTracking}
                                />
                              );
                            }
                            return null;
                          })}
                        </div>
                      </div>
                    </div>
                }
              </Toolbar>
            </div>
            <div className={classes.navBottomSection}>
              <div className={classes.navButtonBottom}>
                {
                  this.props.onQCardsClick &&
                    <QCardToggleButton
                      size={theme.components.navigation.iconSize}
                      classes={{ buttonRoot: classes.qCardButton }}
                      qCardsOn={qCardsOn}
                      onClick={this.props.onQCardsClick}
                      closeHeader={this.drawerCannotOpen}
                    />
                }

                {isQuicken && <HelpSupportButton classes={classes} />}

                {
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////
////////////////////////////////
/////////////////////////////////////////
/////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////
///////////////////////////////////////
/////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
//////////////////////
//////////////////////////////////////////////////////
/////////////////////
///////////////////////////
                }

                {
                  showRefreshIcon && !hide &&
                    <RefreshButton
                      closeHeader={this.drawerCannotOpen}
                      id="sync-arrow"
                      classes={{ refreshDivButton: classes.navMenuItem }}
                      size={theme.components.navigation.iconSize}
                    />
                }

                {!hide && this.getSettingsPage()}

                {
                  isAcme && this.props.showIntercom &&
                    <ButtonBase
                      id="intercom-launcher"
                      className={classes.navMenuItemSupport}
                    >
                      <HelpIcon />
                      <Typography className={classes.navText}>
                        Help Center
                      </Typography>
                    </ButtonBase>
                }
                {
                  !hide && inProductNotifications &&
                    <ButtonBase
                      id="in-product-notifications"
                      className={classes.navMenuItem}
                      onClick={this.inProductNotificationsOnClick}
                    >
                      <NotificationsNavIcon
                        popperAnchorEl={this.state.notificationsAnchorEl}
                        open={Boolean(this.state.showNotifications)}
                        ref={this.inProductNotificationsButtonRef}
                        onClose={this.notificationsClose}
                      />
                      <Typography className={classes.navText}>
                        Notifications
                      </Typography>
                    </ButtonBase>
                }

                <ProfileMenu classes={classes} />
              </div>
            </div>
          </div>
        </AppBar>
      </Slide>
    );
  }
}

Header.propTypes = {
  selected: PropTypes.string,
  selectedAccountNodes: PropTypes.object,
  showRefreshIcon: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  qCardsOn: PropTypes.bool,
  onQCardsClick: PropTypes.func,
  profile: PropTypes.object,
  getProfile: PropTypes.func,
  authenticated: PropTypes.bool,
  datasets: PropTypes.object,
  getDatasets: PropTypes.func,
  getUserPreferences: PropTypes.func,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object,
  datasetPreferences: PropTypes.object,
  inProductNotifications: PropTypes.bool,
  hide: PropTypes.bool,
  tier: PropTypes.object,
  showIntercom: PropTypes.bool,
  renameRules: PropTypes.object,
  memorizedRules: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    datasets: datasetsSelectors.getSortedDatasets(state),
    profile: profileSelectors.getProfile(state),
    authenticated: isAuthenticated(state),
    datasetId: authSelectors.getDatasetId(state),
    authSession: authSelectors.getAuthSession(state),
    featureFlags: featureFlagsSelectors.getFeatureFlags(state),
    inProductNotifications: featureFlagsSelectors.getFeatureFlag(state, 'inProductNotifications'),
    tier: getTopTierEntitlement(state),
    showIntercom: featureFlagsSelectors.getFeatureFlag(state, 'intercom'),

    renameRules: getRulesById(state),
    renameRulesLoading: getRulesIsLoading(state) || getRulesLoadPending(state),
    memorizedRules: getMemorizedRulesById(state),
    memorizedRulesLoading: getMemorizedRulesIsLoading(state) || getMemorizedRulesLoadPending(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProfile: () => dispatch(profileActions.getProfile()),
    getDatasets: () => dispatch(datasetsActions.getDatasets()),
    getUserPreferences: () => {
      dispatch(getPreferences({ section: 'shared', group: 'user' }));
    },
  };
}

export default compose(
  withStyles(styles, { name: 'Header', withTheme: true }),
  QPreferences(),
  QDialogs(),
)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Header)));
