

import { createAction } from 'redux-actions';

export const getBillerAccounts = createAction('GET_BILLER_ACCOUNTS');
export const getBillerAccountsSuccess = createAction('GET_BILLER_ACCOUNTS_SUCCESS');
export const getBillerAccountsFailure = createAction('GET_BILLER_ACCOUNTS_FAILURE');

export const getBillerAccountsForLoginID = createAction('GET_BILLER_ACCOUNTS_FOR_ID');

