// @flow
import * as types from './types';

export function transformDocumentToRequestData(document: types.Document) {
  return {
    id: document.id,
    isDeleted: document.isDeleted,
    name: document.name,
    description: document.description,
    documentType: document.documentType,
    contentType: document.contentType,
    clientId: document.clientId,
    url: document.url,
    size: document.documentSize,
  };
}

export function transformResponseToDocuments(response: any) {
  return response.data.resources.map((data) => (
    transformDataToDocument(data)));
}

export function transformDataToDocument(data: any) {
  return (
    types.mkDocument({
      id: data.id,
      isDeleted: data.isDeleted,
      name: data.name,
      description: data.description,
      documentType: data.documentType,
      contentType: data.contentType,
      clientId: data.clientId,
      url: data.url,
      documentSize: data.size,
    })
  );
}

export function transformDocumentWithResponse(document: types.Document, response: any): types.Document {
  return document.merge({ id: response.data.id, url: response.data.url });
}
