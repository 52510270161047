
// BASE
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';

import { chartOfAccountsSelectors } from '@quicken-com/react.flux.chart-of-accounts';

// CUSTOM COMPONENTS / ASSETS
import AmountField from 'components/QuickenControls/AmountField';
import QIconButton from 'components/QIconButton';
import CategoryReviewPopupCard from 'components/CategoryReviewPopupCard';
import QTip from 'components/QuickenControls/QTip';

// SELECTORS / ACTIONS / UTILITIES


// MUI COMPONENTS / ASSETS
import Typography from '@mui/material/Typography';
import BarChartIcon from '@mui/icons-material/BarChartRounded';
import Checkbox from '@mui/material/Checkbox';

// PATH RELATIVE
import { styles } from './styles';

// STYLES HOOK
const useStyles = makeStyles(styles);


/*
 * BudgetGroupItem Component ***************************************
 */

// PROP TYPES
const suggestedBudgetItemPropTypes = {
  item: PropTypes.object,  // has a coa, monthlyAverage, and optional key (for onChange)
  onChange: PropTypes.func,
  selected: PropTypes.bool,
  accountIds: PropTypes.object,
  currency: PropTypes.string,
  header: PropTypes.bool,
};

const SuggestedBudgetItem = (props) => {

  // PROPS
  const { item = { id: 'header' }, currency, accountIds, selected, onChange, header } = props;

  // STATE
  const [catReviewAnchorEl, setCatReviewAnchorEl] = useState(null);
  const [showIcons, setShowIcons] = useState(false);

  // SELECTORS

  // EFFECTS

  // STYLES
  const classes = useStyles(props);

  // INTERNAL FUNCTIONS
  const constructName = () => {
    if (header) {
      return ['Category', ''];
    }
    let str = chartOfAccountsSelectors.getCoaStringSelector(undefined, item.coa);
    let parentStr = '';
    const catParts = str.split(':');
    if (catParts && catParts.length > 1) {
      parentStr = `(${catParts[catParts.length - 2]})`;
      str = catParts[catParts.length - 1];
    }

    return [str, parentStr];
  };

  const nameArray = constructName();

  return (
    <div
      className={classes.categoryRow}
      onMouseEnter={() => setShowIcons(true)}
      onMouseLeave={() => setShowIcons(false)}
    >
      <Checkbox
        checked={selected}
        id={`suggested-categories-checkbox-${nameArray[0]}${nameArray[1]}`}
        onChange={header ? onChange : () => onChange(item.key || item.coa.id)}
      />
      <div className={classes.categoryNameWrapper}>
        <Typography
          key={`editBudgetCats:str1 ${item.id}`}
          variant={header ? 'subtitle1' : 'body2'}
          className={header ? classes.headerText : classes.categoryName}
        >
          {nameArray[0]}
        </Typography>
        <Typography
          key={`editBudgetCats:str2 ${item.id}`}
          variant="body2"
          className={classes.categoryNameParent}
        >
          {nameArray[1]}
        </Typography>
      </div>
      <div
        className={classes.categoryAmount}
      >
        {header &&
          <Typography
            variant={header ? 'subtitle1' : 'body2'}
            className={header ? classes.headerText : classes.categoryName}
          >
            Amount
          </Typography>}
        {!header &&
          <QTip
            title="You can edit the default amount after you add it to the budget"
          >
            <AmountField
              id={`budgetAmt:${nameArray[0]}`}
              value={Math.abs(item.monthlyAverage)}
              currencySymbol={currency}
              currencyGap
              disableUnderline
              className={header ? null : classes.amountInputStyle}
              omitCents
            />
          </QTip>}
      </div>
      <div className={classNames(classes.iconHolder, showIcons ? '' : classes.hide)}>
        {!header &&
          <>
            <QTip
              title="Category Details Card..."
            >
              <QIconButton
                aria-label="Show Budget Item Info"
                onClick={(e) => setCatReviewAnchorEl(e.currentTarget)}
                id={`row-${item.id}-delete-item`}
                size="small"
              >
                <BarChartIcon
                  className={classes.barChartIcon}
                />
              </QIconButton>
            </QTip>
            <CategoryReviewPopupCard
              anchorEl={catReviewAnchorEl}
              open
              onClose={() => setCatReviewAnchorEl(null)}
              coaIds={[item.coa.id]}
              classes={{
                root: classes.categoryReviewCardRoot,
              }}
              currency={currency}
              accountIds={accountIds}
            />
          </>}
      </div>
    </div>
  );
};
SuggestedBudgetItem.propTypes = suggestedBudgetItemPropTypes;

/* *** BUDGET GROUP ITEM export *** */
export default SuggestedBudgetItem;
