import React from 'react';
import { Map as ImmutableMap } from 'immutable';

import { categoriesSelectors } from '@quicken-com/react.flux.categories';

import { exists } from 'utils/utils';

import store from 'store';

import AccountsIcon from 'assets/nav-menu/accounts.settings.simplifi.inline.svg';
import CategoriesIcon from 'assets/nav-menu/categories.tags.settings.simplifi.inline.svg';
import TagIcon from 'assets/nav-menu/tag-icon.inline.svg';
import PayeeIcon from 'assets/nav-menu/payee-store-front.inline.svg';
import AdvancedIcon from 'assets/nav-menu/renaming.rules.settings.simplifi.inline.svg';

export const itemMenuConfig = [
  {
    resourceType: 'categories',
    title: 'Categories',
    id: 'add_filter_button_category',
    icon: <CategoriesIcon />,
  },
  {
    resourceType: 'payees',
    title: 'Payee',
    id: 'add_filter_button_payee',
    icon: <PayeeIcon />,
  },
  {
    resourceType: 'tags',
    title: 'Tag',
    id: 'add_filter_button_tag',
    icon: <TagIcon />,
  },
  {
    resourceType: 'accounts',
    title: 'Account',
    id: 'add_filter_button_account',
    icon: <AccountsIcon />,
  },
  {
    resourceType: 'advanced',
    title: 'Advanced',
    id: 'add_filter_button_advanced',
    icon: <AdvancedIcon />,
  },
];

const countDefinedItems = (imap) => {
  if (!imap) return 0;
  return (imap.reduce((v, x) => v + (exists(x) && 1) || 0, 0));
};

export const countFilterItems = (filterObject) => {
  if (!filterObject) return 0;
  const count = ImmutableMap(filterObject.toObject()).filter((v, k) => k !== 'payees' && k !== 'payeeNames').reduce((v, x) =>
    v + (x?.size || 0), 0) || 0;
  return count + countDefinedItems(ImmutableMap(filterObject?.advanced.toObject()));
};

export const countFilterItemsForDisplay = (filterObject) => {
  if (!filterObject) return 0;
  let count = ImmutableMap(filterObject.toObject()).filter((v, k) => k !== 'payees' && k !== 'payeeNames' && k !== 'categories').reduce((v, x) =>
    v + (x?.size || 0), 0) || 0;
  count += filterObject.get('categories')?.reduce((accum, catId) => {
    const category = categoriesSelectors.getCategoryForId(store.getState(), catId);
    const hasParent = category?.parentId !== '0';
    const parentIsSelected = hasParent && filterObject.get('categories').has(category?.parentId);
    return accum + (parentIsSelected ? 0 : 1);
  }, 0) || 0;
  return count + countDefinedItems(ImmutableMap(filterObject?.advanced.toObject()));
};

export const advancedKeyToName = {
  isBillOrSubscription: 'Bills',
  isExcludedFromF2S: 'Ignored in spending plan',
  isExcludedFromReports: 'Ignored in reports',
  isReviewed: 'Reviewed',
};

const getCategoryName = (categoriesById, it, accountsById) => {
  switch (it) {
    case 'BALANCE_ADJUSTMENT_0':
      return 'Balance Adjustment';
    case 'BALANCE_ADJUSTMENT_1':
      return 'Opening Balance';
    case 'BALANCE_ADJUSTMENT_2':
      return 'Transfer';
    case 'BALANCE_ADJUSTMENT_3':
      return 'Credit Card Payment';
    case 'UNCATEGORIZED':
      return 'Uncategorized';
    default:
      if (categoriesById?.has(it)) {
        return categoriesById.get(it)?.name || '';
      }
      if (accountsById?.has(it)) {
        return `Transfer: ${accountsById.get(it)?.name || ''}`;
      }
      return 'error';
  }
};

export const getNameFromKeyFactory = (categoriesById, payeesNameList, tagsById, accountsById) => (itemKey, item) => {
  switch (itemKey) {
    case 'categories':
      return getCategoryName(categoriesById, item, accountsById);
    case 'payees':
      return payeesNameList.find((payee) => payee.id === item)?.name || '';
    case 'tags':
      return tagsById.get(item)?.name || '';
    case 'accounts':
      return accountsById.get(item).name || '';
    case 'advanced':
      return `${!item.val ? 'Not' : ''} ${advancedKeyToName[item.key]}`;
    default:
      return 'error';
  }
};

/** Removes an item from the filter object
 * @param itemKey is one of ['categories', 'payees', 'tags', 'accounts', 'advanced']
 * @param item is the id of the category, tag or payee to remove, or the advanced property
 * @param filterObject is the current filterObject, a new one will be returned
 * @param categoriesById should be pulled from getCategoriesById selector
 * @param payeesNameList should be pulled from getPayeesNameList selector
 * @returns a new filter object with the removed item, if a parent category is removed it removes the children
 * */
export const removeItemFromFilterObject = (itemKey, item, filterObject, categoriesById, payeesNameList) => {
  let updatedObject = null;
  switch (itemKey) {
    case 'categories':
    case 'accounts':
    case 'tags':
      updatedObject = filterObject.set(itemKey, filterObject.get(itemKey).delete(item));
      if (itemKey === 'categories') {
        const childIds = categoriesById?.filter((cat) => cat?.parentId === item)?.keySeq()?.toArray() || [];
        if (childIds.length > 0) updatedObject = updatedObject.set('categories', updatedObject.get('categories').subtract(childIds));
      }
      break;
    case 'payees': {
      const nameToRemove = payeesNameList.find((payee) => payee.id === item)?.name;
      if (nameToRemove) {
        updatedObject = filterObject.set('payees', filterObject.get('payees').delete(item));
        updatedObject = updatedObject.set('payeeNames', updatedObject.get('payeeNames').delete(updatedObject.get('payeeNames').indexOf(nameToRemove)));
      }
      break;
    }
    case 'advanced':
      updatedObject = filterObject.set('advanced', filterObject.get('advanced').delete(item.key));
      break;
    default:
      updatedObject = filterObject;
  }
  return updatedObject.set('numFilterItems', countFilterItems(updatedObject));
};
