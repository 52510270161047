
// CORE
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
    outlineWidth: 0,
    outlineStyle: 'none',
    minWidth: 16,
    maxWidth: 160,
    padding: theme.spacing(0, 0.625),
    width: 'fit-content',
    color: `${theme.palette.greyScaleDeprecated[0]} !important`,
    borderRadius: 3,
    verticalAlign: 'middle',
    border: '1px solid transparent',
    overflow: 'hidden',

    display: 'block',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.1),
    },

    '&:focus': {
      display: 'flex',
      border: `1px solid ${theme.applyOpacityToHex(theme.palette.primary.main, 0.6)}`,
      backgroundColor: theme.palette.greyScaleDeprecated[7],
    },
  },
}));


// -------------------------------------------------------------------------------
//                          FUNCTIONAL COMPONENT
// -------------------------------------------------------------------------------
export default function PayeeSpan(props) {
  const { initialValue, onBlur, className, variant, style, onChange, ...otherProps } = props;
  const classes = useStyles();
  const theme = useTheme();

  //    State
  // --------------------------------------------
  const [payee, setPayee] = useState(initialValue);
  const [spanRef] = useState(React.createRef());

  //    Effects
  // --------------------------------------------
  useEffect(() => {
    setPayee(initialValue);
    spanRef.current.textContent = initialValue;
  }, [initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const node = spanRef && spanRef.current;
    if (node && onChange) {
      node.addEventListener('DOMSubtreeModified', handleChange);
    }
    return node && (() => node.removeEventListener('DOMSubtreeModified', handleChange));
  }, [spanRef, onChange]); // eslint-disable-line react-hooks/exhaustive-deps

  //    Function Variables
  // --------------------------------------------
  let update = true; // controls how to handle the blur (don't update on escape)

  //    Functions / event handlers
  // --------------------------------------------
  const handleChange = () => {
    if (onChange && spanRef && spanRef.current) {
      onChange(spanRef.current.innerText);
    }
  };

  const handleKeyDown = (e) => {
    switch (e.keyCode) {
      case 8: // backspace
        break;
      case 13: // enter
        spanRef.current.blur();
        break;
      case 27: // escape
        update = false;
        spanRef.current.blur();
        break;
      default:
        break;
    }
  };

  const handlePaste = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const text = clipboardData && clipboardData.getData('Text'); // only keep textData
    window.document.execCommand('insertText', false, text);
  };

  const handleBlur = () => {
    spanRef.current.scrollLeft = 0;
    if (update) {
      setPayee(spanRef.current.innerText);
      onBlur && onBlur(spanRef.current.innerText);
    } else {
      spanRef.current.textContent = payee;
    }
  };


  //    Main Render / return
  // --------------------------------------------
  return (
    <span
      id="payeeSpan"
      role="textbox"
      tabIndex={0}
      ref={spanRef}
      contentEditable
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      onPaste={handlePaste}
      className={classNames(classes.root, className)}
      style={{
        ...theme.typography[variant],
        ...style,
      }}
      {...otherProps}
    />
  );
}

PayeeSpan.defaultProps = {
  style: {},
  variant: 'body1',
};

PayeeSpan.propTypes = {
  initialValue: PropTypes.string,
  variant: PropTypes.string,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
};
