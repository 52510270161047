import { createAction } from 'redux-actions';

export const getAlertRules = createAction('GET_ALERT_RULES');
export const getAlertRulesSuccess = createAction('GET_ALERT_RULES_SUCCESS');
export const getAlertRulesFailure = createAction('GET_ALERT_RULES_FAILURE');

export const updateAlertRule = createAction('UPDATE_ALERT_RULE', (payload) => payload, (payload, meta) => meta);
export const updateAlertRuleSuccess = createAction('UPDATE_ALERT_RULE_SUCCESS', (payload) => payload, (payload, meta) => meta);
export const updateAlertRuleFailure = createAction('UPDATE_ALERT_RULE_FAILURE');

