import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  infoIcon: {
    color: theme.palette.greyScaleDeprecated[3],
    marginLeft: theme.spacing(1),
  },
  linkOverload: {
    color: `${theme.palette.error.main} !important`,
  },
  footer: {
    alignItems: 'stretch',
    alignSelf: 'stretch',
    display: 'flex',
    padding: theme.spacing(3),
  },
}));

