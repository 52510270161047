
/*
 * Cache Manager for Optimized Updating for redux selectors
 *
 *
 */
import { Map as ImmutableMap, List as ImmutableList } from 'immutable';
import { memoizeWithKeysSetCache, memoizeWithKeyRetrieveCache } from 'utils/memoizeWithKey';
import { getOrCreateStringFromObject } from 'utils/objectToKeyUtils';

/* ************************************
 * INIT REDUCTIONS
 *********************************** */
export function initReductions(store) {

  let lastReductions = store.get('lastReductions');

  const thisUpsertId = new Date().valueOf();
  const initialChange = ImmutableMap({ priorValue: null, newValue: null, upsertId: thisUpsertId });

  // push a starting NOP transaction (to record the upsertId.  Both values NULL is a NOP
  if (!lastReductions) {
    return store.set('lastReductions',
      ImmutableMap({ changeList: ImmutableList([initialChange]), upsertId: thisUpsertId }));
  }
  lastReductions = lastReductions.set('changeList', lastReductions.get('changeList').push(initialChange));
  lastReductions = lastReductions.set('upsertId', thisUpsertId);
  return store.set('lastReductions', lastReductions);
}

/* ************************************
 * CLEAR REDUCTIONS
 *********************************** */
export function clearReductions(store) {
  return store.set('lastReductions', null);
}

/* ************************************
 * ADD REDUCTIONS
 *********************************** */
export function addReduction(store, priorValue, newValue) {

  // console.log("ADD REDUCTION", store, priorValue, newValue);
  const lastReductions = store.get('lastReductions');
  const newItem = ImmutableMap({ priorValue, newValue, upsertId: lastReductions.get('upsertId') });
  return store.setIn(['lastReductions', 'changeList'], lastReductions.get('changeList').push(newItem));
}

/* ************************************
 * GET OPTIMAL CACHE
 *********************************** */
export const getOptimalCache = (
  store,
  name,
  cacheString,
  strictCacheString,
  compareFn,
  processFn,
  maxSize
) => {

  // console.log("GET OPTIMAL CACHE ", name, cacheString, strictCacheString);
  const lastReductions = store.get('lastReductions');
  // If this full combo of parms is cached, use that

  if (memoizeWithKeyRetrieveCache(name, strictCacheString)) {
    // console.log("Returning match for strictCacheString", strictCacheString);
    return memoizeWithKeyRetrieveCache(name, strictCacheString).cachedObject;
  }

  // if there are no reductions, then force regeneration
  if (!lastReductions || lastReductions.get('changeList').size === 0) {
    // console.log("no last reductions or changelist", store.toJS());
    return null;
  }
  // if there was a recent reduction, and a cache present, possibly
  // skip the expensive selector and only replace the values that changed
  const cacheRecord = memoizeWithKeyRetrieveCache(name, cacheString);
  let cachedValues = cacheRecord && cacheRecord.cachedObject;

  // console.log("CACHED VALUES ", name, cacheRecord, cacheString);
  // if there are no cached values, then force regeneration
  // we force into an ImmutableMap to determine size (not supported by JS objects)
  if (!cachedValues || ImmutableMap(cachedValues).size === 0) {
    return null;
  }
  // console.log("FOUND PREVIOUS CACHE RECORD ",name , cacheRecord.lastUpsertIdProcessed);

  const changeList = lastReductions.get('changeList');
  // console.log("changeList ", lastReductions, changeList);
  // reduce the reduction set to only those changes that occurred after the stored upsertId in the cache record
  // since multiple updates can occur in the single upsert, find the LAST occurance of the upsert ID
  const startIndex = changeList.findLastIndex((x) => x.get('upsertId') === cacheRecord.lastUpsertIdProcessed);

  let cacheFail = false;

  // if no lastUpsert location is found, then we have to regenerate the list, so don't bother trying
  // console.log("START INDEX IS ", startIndex);
  if (startIndex !== -1) {
    const filteredChanges = changeList.slice(startIndex + 1);  // skip the presumed NOP
    filteredChanges.some((lastReduction) => {
      const priorValue = lastReduction.get('priorValue');
      const newValue = lastReduction.get('newValue');

      // if priorValue is null, and newValue is null, this is a NOP, and is ignored
      // if priorValue is null, and newValue is not, this is a CREATE, and we must regenerate
      if (!priorValue) {
        if (!newValue) return false;  // NOP
        cacheFail = true;
        // console.log("Missing Prior Value - CREATE");
        return true;  // force update and end processing
      }

      if (compareFn(priorValue, newValue)) {

        // returns null to indicate the cache cannot be used
        // console.log("TRY TO REPLACE IN CACHE LIST THE TRANSACTION ", cachedValues, lastReduction.get('priorValue'));

        cachedValues = processFn(cachedValues, newValue);
        if (cachedValues === null) {
          cacheFail = true;
          return true;
        }
        return false;
      }
      cacheFail = true;
      return true; // return true to end the processing
    });
  } else {
    cacheFail = true;
  }
  // console.log("CACHE FAIL IS ", name, cacheFail);
  if (!cacheFail) {
    // write the new transaction list to the cache and
    // set the last upsert processed to ignore in the future
    setOptimalCache(store, name, cacheString, strictCacheString, cachedValues, maxSize);
    return cachedValues;
  }
  return null;
};

/* ************************************
 * SET OPTIMAL CACHE
 *********************************** */
export const setOptimalCache = (store, name, cacheString, strictCacheString, cachedValues, maxSize) => {

  const lastReductions = store.get('lastReductions');
  const changeList = lastReductions && lastReductions.get('changeList');

  const lastUpsertIdProcessed = (changeList && changeList.size && changeList.last().get('upsertId')) || 'initial';
  // console.log("SET OPTIMAL CACHE ", name, strictCacheString, cacheString, changeList && changeList.last().toJS(), lastUpsertIdProcessed);

  memoizeWithKeysSetCache(name, [strictCacheString, cacheString], { cachedObject: cachedValues, lastUpsertIdProcessed }, maxSize);
  // memoizeWithKeySetCache(name, strictCacheString, { cachedObject: cachedValues, lastUpsertIdProcessed }, maxSize);
};


export const getOptimalCacheFromObject = (
  store,
  name,
  cacheObject,
  strictCacheObject,
  compareFn,
  processFn,
  maxSize
) =>
  getOptimalCache(
    store,
    name,
    getOrCreateStringFromObject(name, cacheObject, maxSize * 2, memoizeWithKeyRetrieveCache),
    getOrCreateStringFromObject(name, strictCacheObject, maxSize * 2, memoizeWithKeyRetrieveCache),
    compareFn,
    processFn,
    maxSize
  );

export const setOptimalCacheFromObject = (store, name, cacheObject, strictCacheObject, cachedValues, maxSize) =>
  setOptimalCache(
    store,
    name,
    getOrCreateStringFromObject(name, cacheObject, maxSize * 2, memoizeWithKeyRetrieveCache),
    getOrCreateStringFromObject(name, strictCacheObject, maxSize * 2, memoizeWithKeyRetrieveCache),
    cachedValues,
    maxSize
  );


