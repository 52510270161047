// @flow
import { combineActions, handleActions } from 'redux-actions';
import { hash } from 'immutable';

import { resourceUpserters, resourceStoreTypes, resourceUndoUpserters, coreActions } from '@quicken-com/react.flux.core';

import * as actions from './actions';

const reducer = handleActions({

  [coreActions.sanitize]: (state) =>
    state.set('resourcesById', state.resourcesById.map((resource) => resource.merge({
      renamePayeeFrom: hash(resource.renamePayeeFrom).toString(),
      renamePayeeTo: hash(resource.renamePayeeTo).toString(),
    }))),

  [actions.createRenameRule]:
    (state, { payload: renameRule }) => resourceUpserters.upsertResource(state, renameRule),
  [actions.createRenameRuleSuccess]:
    (state, action) => resourceUndoUpserters.upsertResourceSetupUndo(state, action, actions.deleteRenameRule),

  [actions.updateRenameRule]:
    (state, action) => resourceUndoUpserters.upsertResourceSetupUndo(state, action, actions.updateRenameRule),
  [actions.updateRenameRuleSuccess]:
    (state, { payload: renameRule }) => resourceUpserters.upsertResource(state, renameRule),

  [actions.updateRenameRuleBatch]:
    (state, action) => resourceUndoUpserters.upsertResourcesSetupUndo(state, action, actions.updateRenameRuleBatch, false),
  [actions.updateRenameRuleBatchSuccess]:
    (state, action) => resourceUndoUpserters.upsertResourcesSetupUndo(state, action, actions.updateRenameRuleBatch, true),

  [actions.deleteRenameRule]:
    (state, action) => resourceUndoUpserters.upsertResourceSetupUndo(state, action, actions.createRenameRule),
  [actions.deleteRenameRuleSuccess]:
    (state, { payload: renameRule }) => resourceUpserters.upsertResource(state, renameRule),

  [combineActions(
    actions.createRenameRuleFailure,
    actions.updateRenameRuleFailure,
    actions.deleteRenameRuleFailure,
    actions.updateRenameRuleBatchFailure,
  )]: (state, { payload: error, asyncDispatch }) => {
    asyncDispatch(actions.getRenameRules());
    return resourceUpserters.resetResources(state, error);
  },

  [actions.getRenameRules]:
    (state) => state.merge({ isLoading: true }),
  [actions.getRenameRulesSuccess]:
    (state, { payload: resources }) => resourceUpserters.upsertResources(state, resources),
  [actions.getRenameRulesFailure]:
    (state, { payload: error }) => resourceUpserters.completeWithError(state, error),

}, resourceStoreTypes.mkQcsSyncResourceStore());

export const REDUCER_KEY = 'renameRuleStore';
export default reducer;
