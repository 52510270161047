import { createAction } from 'redux-actions';

export const getEntitlements = createAction('GET_ENTITLEMENTS');
export const getEntitlementsSuccess = createAction('GET_ENTITLEMENTS_SUCCESS');
export const getEntitlementsFailure = createAction('GET_ENTITLEMENTS_FAILURE');
//////////////////////////////////////////////////////////////////////////////////
export const setEntitlement = createAction('SET_ENTITLEMENT');
/////////
export const createEntitlement = createAction('CREATE_ENTITLEMENT', (payload) => payload, (payload, metadata) => metadata);
export const createEntitlementSuccess = createAction('CREATE_ENTITLEMENT_SUCCESS');
export const createEntitlementFailure = createAction('CREATE_ENTITLEMENT_FAILURE');
