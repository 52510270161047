/*
 * Category Review Card
 */

// BASE
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { List, Set } from 'immutable';
import moment from 'moment';

import { accountsSelectors, accountsUtils } from '@quicken-com/react.flux.accounts';
import { categoriesSelectors } from '@quicken-com/react.flux.categories';
import { chartOfAccountsSelectors } from '@quicken-com/react.flux.chart-of-accounts';
import { scheduledTransactionsSelectors } from '@quicken-com/react.flux.scheduled-transactions';

// CUSTOM COMPONENTS
import MonthlyData from 'containers/OverviewPage/components/MonthlyData';

// SELECTORS, ACTIONS, UTILS

import { getPredefinedRange } from 'utils/date/utils';
import { transactionsSortFunction } from 'data/transactions/utils';
import { getSpendingForPeriods, getIncomeForPeriods } from 'data/otReports/selectors';
import { calcMontlyAverageForScheduledTxn } from 'data/scheduledTransactions/scheduledTransactionsUtils';

// MUI COMPONENTS
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

// PATH RELATIVE IMPORTS
import { styles } from './styles';
import MiniTxnList from './miniTxnList';

const DEFAULT_MONTHS = '3_MONTHS_PRIOR';
const propDefaults = {
  numMonths: DEFAULT_MONTHS,
  format: ['transactions', 'history'],
};
const propTypes = {
  currency: PropTypes.string,
  accountIds: PropTypes.object,
  coa: PropTypes.object,
  // You can pass in your own list of Id's, but they need to be of the same type (INCOME or EXPENSE)
  coaIds: PropTypes.array,
  numMonths: PropTypes.string,
  onAverageCalc: PropTypes.func,
  onSetValueFromGraph: PropTypes.func,
  classes: PropTypes.object,
  excludeCategoryInTooltip: PropTypes.bool,
  format: PropTypes.array,   // transactions, graph, bills, or all
  coaTitle: PropTypes.string, // optional label for cat or group of cats
};

// STYLES HOOK CREATION
const useStyles = makeStyles(styles);

const CategoryReviewCard = (props) => {

  // PROPS ============================================================================
  const { accountIds, currency = 'USD', numMonths, coa, coaIds,
    onAverageCalc, onSetValueFromGraph, format, coaTitle, excludeCategoryInTooltip } = props;

  // STATE ============================================================================
  const [localAccountIds, setLocalAccountIds] = useState(null);
  const [monthsToView, setMonthsToView] = useState(numMonths);
  const [displayTransactions, setDisplayTransactions] = useState(List());
  const [graphPeriods, setGraphPeriods] = useState(null);
  const [scheduledTxns, setScheduledTxns] = useState(List());

  // SELECTORS ==========================================================================
  const accountsById = useSelector((state) => accountsSelectors.getAccountsById(state), shallowEqual);
  const scheduledTransactionsById = useSelector((state) => scheduledTransactionsSelectors.getUserVerifiedScheduledTransactions(state), shallowEqual);

  const coaParm = useMemo(() => ({ coa: coa || { id: 0, type: 'UNCATEGORIZED' } }), [coa]);
  const coastouse = useSelector((state) => chartOfAccountsSelectors.getChartOfAccountNodesByCoa(state, coaParm), shallowEqual);
  const ids = useMemo(() => coastouse.map((val) => val.coa.id), [coastouse]);
  const coasToIds = coaIds || ids;
  const typeId = coaIds ? coaIds[0] : coa.id;
  const catType = categoriesSelectors.isIncomeCat(null, typeId) ? 'INCOME' : 'EXPENSE';

  const selProps = useMemo(() => ({
    filterCriteria: { type: 'categories', items: coasToIds, typeOfCategories: catType },
    dateInterval: getPredefinedRange({ value: monthsToView }),
    localAccountIds,
  }), [localAccountIds, monthsToView, catType, coasToIds]);

  const transactionSelector = catType === 'INCOME' ? getIncomeForPeriods : getSpendingForPeriods;
  const transactions = useSelector((state) => transactionSelector(state, selProps), shallowEqual);

  // EFFECTS ============================================================================

  /*
   * Hack to reuse the spending graph, invert amounts
   */
  useEffect(() => {
    if (catType === 'INCOME') {
      const newPeriods = transactions.periods.map((period) =>
        ({ ...period, amount: -Number(period.amount) }));

      setGraphPeriods(newPeriods);
    } else {
      setGraphPeriods(transactions.periods);
    }
  }, [transactions, catType]);
  /*
   * Create the transactions display list
   */
  useEffect(() => {
    const sortedTxns = transactions.txns.sort(transactionsSortFunction);
    let usedIds = new Set();
    let deDupedTxns = List();
    sortedTxns.forEach((txn) => {
      if (!usedIds.includes(txn.id)) deDupedTxns = deDupedTxns.push(txn);
      usedIds = usedIds.add(txn.id);
    });
    setDisplayTransactions(deDupedTxns.reverse());
  }, [accountIds, numMonths, transactions]);
  /*
   * create the scheduled transactions
   */

  useEffect(() => {
    const txns = scheduledTransactionsById.filter((txn) =>
      coasToIds.includes(txn?.transaction?.coa?.id)).map((x, index) =>
      x.transaction.set('amount', calcMontlyAverageForScheduledTxn(x)).set('id', index));
    setScheduledTxns(txns);
  }, [scheduledTransactionsById, coasToIds]);
  /*
   * Set up accountIds with default of all accounts if not specified
   */
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {

    let [...idsToUse] = accountsById.keys();
    if (accountIds) {
      idsToUse = accountIds;
    }
    // only supported accounts
    idsToUse = idsToUse.filter((acct) => !accountsUtils.isInvestmentAccount(acct) && !accountsUtils.isLoanAccount(acct));

    setLocalAccountIds(List(idsToUse));
  }, [accountIds, accountsById]);
  /*
   * Calculate monthly average for the given coa
   */
  useEffect(() => {
    if (onAverageCalc) {
      const txnsByMonth = displayTransactions.groupBy((txn) => moment(txn.postedOn).month());
      const totals = txnsByMonth.map((txnList) =>
        txnList.reduce((sum, txn) => sum + Number(txn.amount), 0.0));
      const total = totals.reduce((sum, val) => sum + val, 0);
      const average = totals.size === 0 ? 0 : total / totals.size;
      onAverageCalc(average);
      // save totals for the month
      // setTransactionTotalsByMonth(totals);
    }
  }, [displayTransactions, onAverageCalc]);

  // STYLES ============================================================================
  const classes = useStyles(props);

  // INTERNAL FUNCTIONS
  const onBarGraphClick = (barNum) => {
    if (onSetValueFromGraph) {
      onSetValueFromGraph(transactions.periods.get(barNum).amount);
    }
  };

  // RENDER ============================================================================

  const showTransactions = format.includes('transactions');
  const showGraph = format.includes('history');
  const showBills = format.includes('recurring');
  const coaTitleString = coaTitle || (`${coa ? chartOfAccountsSelectors.getCoaStringSelector(undefined, coa, true) : 'Multiple Categories'}`);

  return (
    <div className={classes.root} onClick={(e) => { e.stopPropagation(); e.preventDefault(); }} role="presentation">
      {(showGraph || showTransactions) &&
        <TimeIntervalSelector
          onChange={(e) => { e.stopPropagation(); e.preventDefault(); setMonthsToView(e.target.value); }}
          monthsToView={monthsToView}
          classes={classes}
          coaTitleString={coaTitleString}
        />}
      {showBills &&
      <Typography
        variant="subtitle2"
        style={{ marginBottom: 4 }}
      >
        `Per Month Total`
      </Typography>}

      <div className={classes.contentArea}>

        {showTransactions &&
          <div className={classes.transactionList}>
            <MiniTxnList
              name="txns"
              transactions={displayTransactions}
              currency={currency}
            />
          </div>}

        {showGraph && graphPeriods &&
        <div className={classes.chartPanel}>
          <MonthlyData
            {...selProps}
            overrideMonthlyData={graphPeriods}
            toolTipLabel={coaTitleString}
            onBarGraphClick={onBarGraphClick}
            excludeCategoryInTooltip={excludeCategoryInTooltip}
          />
        </div>}
        {showBills && scheduledTxns &&
        <div className={classes.transactionList}>
          <MiniTxnList
            name="bills"
            transactions={scheduledTxns}
            currency={currency}
            disableDate
          />
        </div>}
      </div>
    </div>
  );
};

CategoryReviewCard.propTypes = propTypes;
CategoryReviewCard.defaultProps = propDefaults;

export default (CategoryReviewCard);

/*
 *************** SUB COMPONENTS ******************
 */
const TimeIntervalSelector = (props) => {
  const { coaTitleString, monthsToView, onChange, classes } = props;
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minHeight: 30 }}>
      <Typography
        variant="subtitle2"
        style={{ marginBottom: 4 }}
      >
        {coaTitleString}
      </Typography>
      <div style={{ marginLeft: 7 }}>
        <Select
          value={monthsToView}
          onChange={onChange}
          name="cat-review-months"
          disableUnderline
          classes={{
            select: classes.monthPickerSelect,
            icon: classes.iconSelect,
          }}
        >
          <MenuItem value="LAST_MONTH"> 1 </MenuItem>
          <MenuItem value="3_MONTHS_PRIOR"> 3 </MenuItem>
          <MenuItem value="6_MONTHS_PRIOR"> 6 </MenuItem>
          <MenuItem value="12_MONTHS_PRIOR"> 12 </MenuItem>
        </Select>
      </div>
      <Typography
        variant="subtitle2"
        style={{ marginBottom: 4 }}
      >
        Months
      </Typography>
    </div>
  );
};
TimeIntervalSelector.propTypes = {
  classes: PropTypes.object,
  coaTitleString: PropTypes.string,
  monthsToView: PropTypes.string,
  onChange: PropTypes.func,
};


