
import React from 'react';
import classNames from 'classnames';
import compose from 'utils/compose';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import TaskWidget from './taskWidget';

import { styles } from './styles';

class TaskListProgress extends React.PureComponent {

  render() {

    const { classes, setupItems, open, onOpenToggle, onClick, titleOnTop, name } = this.props;
    const rootClassname = classNames(classes.root, { closed: !open }, { [classes.rootDashboardHeight]: !titleOnTop }, { [classes.modalRootOverwrite]: titleOnTop });

    return (

      <div style={{ textAlign: 'center', marginLeft: 16, marginRight: 16 }}>

        {onOpenToggle &&
          <ButtonBase
            onClick={(e) => onOpenToggle(e, !open)}
            className={classes.toggleButton}
          >
            <div className={classes.toggleSwitch} />
          </ButtonBase>}
        <div
          id={`${name || 'default'}-setup-task-bar`}
          className={rootClassname}
        >
          {titleOnTop &&
          <div className={classNames(classes.title, 'ontop')}>
            <Typography className={classes.titleText}>
              SET UP YOUR QUICKEN
            </Typography>

          </div>}
          <div
            className={classNames(classes.container)}
          >
            <>
              {!titleOnTop &&
                <div className={classes.title}>
                  <Typography className={classes.titleText}>
                    SET UP YOUR QUICKEN
                  </Typography>
                </div>}

              <div className={classes.widgetHolder}>
                {setupItems.map((x) =>
                  <TaskWidget
                    key={`task-widget-${x.name}`}
                    title={x.title}
                    completed={Boolean(x.completed)}
                    selected={this.props.selected === x.name}
                    onClick={onClick}
                    name={x.name}
                    classes={{ container: classes.taskWidget }}
                  />)}
              </div>
            </>
          </div>
        </div>
      </div>
    );
  }
}

TaskListProgress.propTypes = {
  classes: PropTypes.object,
  setupItems: PropTypes.array,
  open: PropTypes.bool,
  onOpenToggle: PropTypes.func,
  onClick: PropTypes.func,
  titleOnTop: PropTypes.bool,
  name: PropTypes.string,
  selected: PropTypes.string,
};

export default compose(
  withStyles(styles),
)(TaskListProgress);

