
import { getTxnDifferences } from 'data/transactions/utils';
import { isAcme } from 'isAcme';
import { getLogger } from '@quicken-com/react.utils.core';

const log = getLogger('data/transactionList/optimalCacheFunctions');


/*
  getTransactionListForAccountIds
*/

// Attempt to process newTxn into the cache
// returns the new cache, or NULL if a recalc is needed (can't resolve the cache)
export const optimalCacheProcessFn = (cachedValues, newTxn, filterFn) => {

  let txIndex = -1;
  let sectionKey = null;
  cachedValues.forEach((sec, key) => {
    const index = sec.findIndex((txn) => txn.id === newTxn.id);
    if (index !== -1) {
      sectionKey = key;
      txIndex = index;
    }
  });
  // console.log("PROCESSING looking for ", newTxn);
  if (txIndex !== -1 && sectionKey) {
    // console.log("FOUND TXN AT ", sectionKey, txIndex, cachedValues.get(sectionKey));
    // check for deleted or whether this txn was filtered out of the list

    // console.log("New Txn Filter Test ", newTxn, filterFn && filterFn([newTxn]));
    if (newTxn.isDeleted || (filterFn && filterFn([newTxn]).length === 0)) {
      return cachedValues.set(sectionKey, cachedValues.get(sectionKey).remove(txIndex));
    }

    const newTxnMerge = newTxn.set('balance', cachedValues.getIn([sectionKey, txIndex]).balance);
    return cachedValues.setIn([sectionKey, txIndex], newTxnMerge);
  }
  return newTxn.isDeleted ? cachedValues : null;  // if new txn was deleted, and not found, that is ok
};

// Returns TRUE if the changes do NOT cause a recalc of the selector and allow
// for cache replacement
export const optimalCacheCompareFn = (oldTxn, newTxn, sortBy, filterFn) => {
  // determine which fields changed
  const diffs = getTxnDifferences(oldTxn.toJS(), newTxn.toJS());
  log.debug('TXNlist DIFFS ARE ', diffs, sortBy);
  if (filterFn && filterFn([newTxn]).length === 0) {
    return true; // we will delete this transaction in the process function
  }
  // if (filter) console.log("FILTER WSAS APPLIED TO ", newTxn, filter([newTxn]));
  if (isAcme) {
    return (!diffs.includes('postedOn') &&  // always resort on date or amount change
      !(sortBy === 'status' && (diffs.includes('state'))) &&
        !(sortBy === 'amount' && diffs.includes('amount')) &&
        !(sortBy === 'reviewed' && diffs.includes('isReviewed')) &&
        !(sortBy === 'category' && diffs.includes('coa')) &&
        !(sortBy === 'payee' && diffs.includes('payee')));
  }
  const ret = (!diffs.includes('postedOn') && !diffs.includes('amount') &&  // always resort on date or amount change
    !(sortBy === 'status' && (diffs.includes('state'))) &&
      !(sortBy === 'amount' && diffs.includes('amount')) &&
      !(sortBy === 'category' && diffs.includes('coa')) &&
      !(sortBy === 'reviewed' && diffs.includes('isReviewed')) &&
      !(sortBy === 'payee' && diffs.includes('payee')));
  return ret;
};


/*
  Recent Transactions
*/

// this is for when the value is wrapped in a JS object (so the object pointer does not change)
export const optimalCacheProcessFnRecentAcctTxns = (cachedObject, newTxn) => {
  assert(cachedObject?.immutableValue, 'optimalCacheProcessFnRecent was called without an immutable value');
  // eslint-disable-next-line no-param-reassign
  cachedObject.immutableValue = optimalCacheProcessFnTxnGrouped(cachedObject.immutableValue, newTxn);
  return cachedObject;
};


/*
  General Process Grouped Txns
*/
export const optimalCacheProcessFnTxnGrouped = (cachedValues, newTxn) => {
  let txIndex = -1;
  let sectionKey = null;

  cachedValues.forEach((sec, key) => {
    const foundTxn = sec.find((txn) => txn.id === newTxn.id);
    if (foundTxn) {
      sectionKey = key;
      txIndex = foundTxn.id;
    }
  });
  if (txIndex !== -1 && sectionKey) {
    log.debug('FOUND TXN AT ', sectionKey, txIndex, cachedValues.get(sectionKey));
    // check for deleted
    if (newTxn.isDeleted) return cachedValues.set(sectionKey, cachedValues.get(sectionKey).delete(txIndex));
    return cachedValues.setIn([sectionKey, txIndex], newTxn);
  }
  return cachedValues;
};

/*
  General Compare PostedOn
*/
export const optimalCacheCompareFnPostedOn = (oldTxn, newTxn) => {
  // determine which fields changed
  const diffs = getTxnDifferences(oldTxn.toJS(), newTxn.toJS());
  log.debug('TXN DIFFS ARE (PostedOn)', diffs, !diffs.includes('postedOn'));
  return !diffs.includes('postedOn'); // always resort on date change
};
/*
  General Compare PostedOn and Amount
*/
export const optimalCacheCompareFnPostedOnAndAmount = (oldTxn, newTxn) => {
  // determine which fields changed
  const diffs = getTxnDifferences(oldTxn.toJS(), newTxn.toJS());
  log.debug('TXN DIFFS ARE (PostedOnAndAmount)', diffs, !diffs.includes('postedOn'));
  return !diffs.includes('postedOn') && !diffs.includes('amount'); // always resort on date or amount change
};

export const optimalCacheCompareFnBubble = (oldTxn, newTxn) => {
  // determine which fields changed
  const diffs = getTxnDifferences(oldTxn.toJS(), newTxn.toJS());
  log.debug('TXN DIFFS ARE (PostedOnAndAmount)', diffs, !diffs.includes('postedOn'));
  return !diffs.includes('postedOn') && !diffs.includes('amount') && !diffs.includes('coa') && !diffs.includes('payee') && !diffs.includes('isExcludedFromReports') && !diffs.includes('isExcludedFromF2S'); // always resort on date or amount change
};

/*
  General Process Txn List
*/
export const optimalCacheProcessFnTxnList = (cachedValues, newTxn) => {

  const txIndex = cachedValues.findIndex((txn) => txn.id === newTxn.id);

  if (txIndex !== -1) {
    // check for deleted
    if (newTxn.isDeleted) return cachedValues.delete(txIndex);
    return cachedValues.set(txIndex, newTxn);
  }
  return cachedValues;  // if new txn was deleted, and not found, that is ok
};
