// @flow
import React from 'react';
import compose from 'utils/compose';
import { connect } from 'react-redux';

import withStyles from '@mui/styles/withStyles';

import { getStdForm } from 'data/StdForm/selectors';
import type { StdForm } from 'data/StdForm/types';

import type { DIALOG_CLOSE_EVENT } from '../StdDialogDeprecated/types';
import withStdDialog from '../StdDialogDeprecated';

import { styles } from './styles';

type Props = {
  children: Node | Array<Node>;
  closeWhenSubmitFailed: boolean,
  onClosed: (DIALOG_CLOSE_EVENT, any) => void,

  // from connect
  stdFormData: StdForm,

  // from StdDialog
  closeDialog: () => void,
  onRef: (any) => void,
}

class StdFormDialog extends React.PureComponent<Props> {

  componentDidMount() {
    this.props.onRef(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps, _nextContext) {
    const { stdFormData } = nextProps;
    if (stdFormData !== this.props.stdFormData) {
      if (stdFormData.submitSucceeded) {
        this.handleClose('SUBMIT_SUCCESS', stdFormData.submitResponseData);
      } else if (this.props.closeWhenSubmitFailed && stdFormData.submitFailed) {
        this.handleClose('SUBMIT_FAILED', stdFormData.submitResponseData);
      } else if (stdFormData.cancelled) {
        this.handleClose('CANCELLED');
      }
    }
  }

  // This is called both locally and by the StdDialog HOC when onClose is called.
  //
  handleClose = (dEvent: any, data: any = null) => {
    this.props.closeDialog();
    if (this.props.onClosed) {
      this.props.onClosed(dEvent, data);
    }
  };

  render() {
    return (
      <>
        {this.props.children}
      </>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    stdFormData: getStdForm(state, props.formId),
  };
}

export default compose(
  withStdDialog(),
  withStyles(styles, { name: 'StdFormDialog' }),
  connect(mapStateToProps, null),
)(StdFormDialog);
