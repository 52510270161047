import { List as ImmutableList, Record } from 'immutable';
import { DateTime } from 'luxon';

import { transactionsUtils } from '@quicken-com/react.flux.transactions';

import { getCurrencyForAccount, getCombinedCurrency } from 'components/QCurrency/utils';
import { isUnAcceptedTxnType } from 'data/transactions/utils';

// ================================================================================================
// Txn Filters
// ================================================================================================

// Filter to determine whether a txn is a candidate for reportable income. To be a candidate, txn must fall within the
// date interval and either have a regular CATEGORY or be a split-transaction.
//

export const isPostedOnDateInterval = (txn, dateInterval) => {
  const postedOn = DateTime.fromISO(txn.postedOn);
  return !(!txn || !dateInterval.contains(postedOn));
};

function isTxnCandidateForReportableIncome(txn, dateInterval) {
  const postedOn = DateTime.fromISO(txn.postedOn);
  if (!dateInterval.contains(postedOn)) {
    return false;
  }
  return ((txn.coa && txn.coa.type === 'CATEGORY') || transactionsUtils.isSplitTxn(txn));
}

export const isTxnItemReportableIncome = (item, categoriesById) => {
  if (!item.coa) {
    return false;
  }
  const category = categoriesById.get(item.coa.id);
  return category && category.type === 'INCOME';
};

export const incomeFilters = {
  isTxnCandidateForReporting: isTxnCandidateForReportableIncome,
  isTxnItemReportable: isTxnItemReportableIncome,
};

function isTxnItemReportableWatchlist(item, categoriesById) {
  if (!item.coa) {
    return false;
  }
  return isTxnItemReportableIncome(item, categoriesById) || isTxnItemReportableSpending(item, categoriesById) ||
    item?.coa?.type === 'BALANCE_ADJUSTMENT';
}

export const watchlistFilters = {
  isTxnCandidateForReporting: (txn, dateInterval) => isTxnCandidateForReportableIncome(txn, dateInterval) || isTxnCandidateForReportableSpending(txn, dateInterval),
  isTxnItemReportable: isTxnItemReportableWatchlist,
};

// Filter to determine whether a txn is a candidate for reportable expense. To be a candidate, txn must fall within the
// date interval and either have a regular CATEGORY, be UNCATEGORIZED or be a split-transaction.
//
function isTxnCandidateForReportableSpending(txn, dateInterval) {
  const postedOn = DateTime.fromISO(txn.postedOn);
  if (!dateInterval.contains(postedOn)) {
    return false;
  }
  return ((txn.coa && (txn.coa.type === 'CATEGORY' || txn.coa.type === 'UNCATEGORIZED')) || transactionsUtils.isSplitTxn(txn));
}

export const isTxnItemReportableSpending = (item, categoriesById) => {
  if (!item.coa) {
    return false;
  }
  if (item.coa.type && item.coa.type === 'UNCATEGORIZED') {
    return true;
  }
  const category = categoriesById.get(item.coa && item.coa.id);
  return category && category.type === 'EXPENSE';
};

function payeesMatch(payee1, payee2) {
  if (payee1 && payee2) {
    const normalized1 = payee1.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '');
    const normalized2 = payee2.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '');

    return normalized1 === normalized2;
  }
  return false;
}

function isTxnHasCategory(txn, category) {
  return txn.coa && txn.coa.id === category;
}

function isTxnHasTag(txn, tag) {
  if (txn.tags) {
    return !txn.tags.every((theTag) => theTag.id !== tag.id);
  }
  return false;
}

// parameter "txn" might not be a real txn, it might be a split item (which has no payee field), so caller has to pass in the txnPayee
function isTxnExcludedByWatchlistFilter(txn, filterCriteria, txnPayee = null) {
  let exclude = false;
  if (filterCriteria) {
    switch (filterCriteria.type) {
      case 'payees': exclude = filterCriteria.items.every((payee) => !payeesMatch(txn.payee || txnPayee, payee)); break;
      case 'categories': exclude = filterCriteria.items.every((category) => !isTxnHasCategory(txn, category)); break;
      case 'tags': exclude = filterCriteria.items.every((tag) => !isTxnHasTag(txn, tag)); break;
      default: break;
    }
  }
  return exclude;
}

function isTxnExcludedByFilterCriteriaTopLevelCategoryConstraint(txn, filterCriteria) {
  let exclude = false;
  if (filterCriteria && filterCriteria.type === 'categories' && filterCriteria.topLevelOnly) {
    exclude = !isTxnHasCategory(txn, filterCriteria.parentCoaNodeId);
  }
  return exclude;
}

function isTxnExcludedBySource(txn, filterBySource) {
  let exclude = false;
  if (filterBySource) {
    // console.log(`(AAA) ${txn.payee}: ${txn.source} === ${filterBySource} | ${txn.source === filterBySource}`);
    exclude = (txn.source === filterBySource);
  }
  return exclude;
}

export const spendingFilters = {
  isTxnCandidateForReporting: isTxnCandidateForReportableSpending,
  isTxnItemReportable: isTxnItemReportableSpending,
};

// ================================================================================================
// Frequency Data
// ================================================================================================

export const monthlyFrequencyData = {
  frequency: 'month',
  duration: 'months',
  keyFromPeriod: (period) => period.toFormat('yyyy.MM'),
  shortLabelFromPeriod: (period) => period.toFormat('MMM'),
  labelFromPeriod: (period) => period.toFormat('MMMM'),
  yearFromPeriod: (period) => period.toFormat('yyyy'),
};

// ================================================================================================
// Calculators
// ================================================================================================
const isTxnReportable = (txn, filter, filterCriteria, filterBySource, categoriesById, payee = null) => {
  const test1 = filter.isTxnItemReportable(txn, categoriesById);
  const test2 = !isTxnExcludedByWatchlistFilter(txn, filterCriteria, payee);
  const test3 = !isTxnExcludedByFilterCriteriaTopLevelCategoryConstraint(txn, filterCriteria);
  const test4 = !isTxnExcludedBySource(txn, filterBySource);
  // console.log(`(AAA) Is Reportable: ${test1 && test2 && test3 && test4}`);
  return test1 && test2 && test3 && test4;
};

const isTxnNotExcluded = (txn) => !txn.isExcludedFromReports;

export const getReportableTxn = (
  txn,
  categoriesById,
  filterCriteria,
  filter,
  filterBySource,
  isReportableTxn = isTxnReportable,
  isTxnNotExcludedFromReports = isTxnNotExcluded // eslint-disable-line no-unused-vars
) => {

  let amount = 0;
  let isReportable = false;

  if (!transactionsUtils.isSplitTxn(txn)) {
    if (isReportableTxn(txn, filter, filterCriteria, filterBySource, categoriesById, txn.payee)) {
      // We don't want to add scheduled transaction pending to the totals
      const txnDate = DateTime.fromISO(txn.postedOn);
      const isUnAcceptedTxnTypeTxn = isUnAcceptedTxnType(txn);
      if (!isUnAcceptedTxnTypeTxn
        && !(txnDate?.diff(DateTime.local(), 'days').toObject().days >= 0)) {
        amount = Number(txn.amount);
      }
      isReportable = Boolean(!isUnAcceptedTxnTypeTxn);
    }
  } else {
    const isUnAcceptedTxnTypeTxn = isUnAcceptedTxnType(txn);
    if (!isUnAcceptedTxnTypeTxn) {
      let splitHadReportable = false;

      const txnDate = DateTime.fromISO(txn.postedOn);
      txn.split.items.forEach((item) => {
        if (isReportableTxn(item, filter, filterCriteria, filterBySource, categoriesById, txn.payee)) {
          if (!(txnDate?.diff(DateTime.local(), 'days').toObject().days >= 0)) {
            amount += Number(item.amount);
          }
          splitHadReportable = true;
        }
      });
      if (splitHadReportable) {
        isReportable = true;
      }
    }
  }

  return { amount, isReportable };
};

export const OTReturn = Record({
  periods: null,
  txns: null,
  currency: null,
});

export const calculateValuesForPeriods = (accountIds, accountsByIds, categoriesById, transactionsByAccountId, dateInterval, frequencyData, filter, filterCriteria, filterBySource) => {

  const periods = new Map();
  accountIds.forEach((accountId) => {
    const account = accountsByIds.get(accountId);
    const currency = account ? getCurrencyForAccount(account) : '';

    if (!['BANK', 'CREDIT', 'CASH'].includes(account.type)) return;  // loan and asset account txns are to be filtered out

    const txns = transactionsByAccountId.get(accountId);
    (txns || []).forEach((txn) => {
      const period = DateTime.fromISO(txn.postedOn).startOf(frequencyData.frequency);
      const key = frequencyData.keyFromPeriod(period);

      let data = periods.get(key);
      if (!data) {
        periods.set(key, data = {
          period,
          amount: 0,
          txns: [],
          currency,
        });
      }

      const { amount, isReportable } = getReportableTxn(
        txn,
        categoriesById,
        filterCriteria,
        filter,
        filterBySource,
      );

      data.amount += amount;

      if (isReportable) {
        data.txns.push(txn);
      }
    });
  });

  const byPeriods = [];
  let txns = [];
  let currency = '';

  // Create byPeriods array in date-order (start->end) and all txns array. Will add values for periods with no income,
  // to insure we have keys for every period in date interval.
  //
  for (
    let period = dateInterval.start.startOf(frequencyData.frequency);
    dateInterval.contains(period);
    period = period.plus({ [frequencyData.duration]: 1 })
  ) {
    const key = frequencyData.keyFromPeriod(period);
    const dataForPeriod = periods.get(key);

    const byPeriod = {
      period,

      label: frequencyData.labelFromPeriod(period),
      shortLabel: frequencyData.shortLabelFromPeriod(period),
      year: frequencyData.yearFromPeriod(period),
      amount: dataForPeriod ? dataForPeriod.amount : 0,
      currency: dataForPeriod ? dataForPeriod.currency : '',
      txns: ImmutableList(dataForPeriod ? dataForPeriod.txns : []),
    };
    byPeriods.push(byPeriod);

    txns = txns.concat(dataForPeriod ? dataForPeriod.txns : []);
    currency = getCombinedCurrency(currency, dataForPeriod ? dataForPeriod.currency : '');
  }

  return new OTReturn({
    periods: ImmutableList(byPeriods),
    txns: ImmutableList(txns),
    currency,
  });
};

// NOTE: if an injectedKey is an object each period will have the same object in it
export function createPastMonthPeriods(injectedKeys, numMonths) {
  let periods = ImmutableList();
  for (let i = 0; i < numMonths; i++) {
    const month = DateTime.local().endOf('month').minus({ months: i });
    periods = periods.set(i, {
      monthLong: month.monthLong,
      monthShort: month.monthShort,
      year: month.year,
      date: month.toISO(),
      txns: ImmutableList(),
      mutableTxns: [],
      ...injectedKeys,
    });
  }
  return periods.reverse();
}



