import React from 'react';
import withStyles from '@mui/styles/withStyles';
import compose from 'utils/compose';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import { transactionsTypes } from '@quicken-com/react.flux.transactions';

// MUI
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import AssignmentReturnedOutlinedIcon from '@mui/icons-material/AssignmentReturnedOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import MatchIcon from '@mui/icons-material/CompareArrows';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CircleIcon from '@mui/icons-material/Circle';
import FileDownload from '@mui/icons-material/GetApp';

// Components
import QData from 'components/QData';
import QDialogs from 'components/QDialogs';
import QCurrency from 'components/QCurrency';
import QIconButton from 'components/QIconButton';
import QButton from 'components/QButton';

// Data
import * as clipboardActions from 'data/clipboard/actions';
import { mkClipboardItem } from 'data/clipboard/types';
import { getClipboardForKey } from 'data/clipboard/selectors';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';
import { showReviewed } from 'data/preferences/selectors';

import { areMatchable, isUnacceptedScheduledTxn } from 'data/transactions/utils';

// import { isMobile } from 'react-device-detect';
import { isAcme } from 'isAcme';

import { styles } from './styles';


function canBeCopied(txn) {
  return (!isUnacceptedScheduledTxn(txn));
}

function fixTxnToPaste(accountId, txn) {
  return new transactionsTypes.CashFlowTransaction({
    accountId,
    clientId: uuidv4(),
    postedOn: txn.postedOn,
    payee: txn.payee,
    memo: txn.memo,
    coa: txn.coa,
    amount: txn.amount,
    split: transactionsTypes.mkSplitCopy(txn.split),
    tags: txn.tags,
  });
}

// ==============================================================
//
// The control bar at the top of the Transaction list (register)
//
// props:
//
//

export class RegMEditBar extends React.Component {

  getTxnInfo = () => {

    let total = 0;
    let hasRecurring = false;
    this.props.selectedTxns.forEach((item) => {
      total += Number(item.amount);
      hasRecurring = hasRecurring || isUnacceptedScheduledTxn(item);
    });
    return { total, hasRecurring };
  };

  matchAvailable = () => {
    if (this.props.selectedTxns.size !== 2) {
      return false;
    }
    const txns = this.props.selectedTxns.toArray();

    return areMatchable(txns[0], txns[1]);
  };

  onCopy = () => {
    const { selectedTxns } = this.props;
    this.props.copyToClipboard(selectedTxns);
  };
  cancelCopy = () => {
    this.props.clearClipboard(); // TODO: should this be 'bankTransactions'
  };
  onPaste = () => {
    const { clipboardTxns, isMultiAccount, accountId } = this.props;
    if (!isMultiAccount && clipboardTxns?.size > 0) {
      let newTxnList = clipboardTxns.filter((txn) => canBeCopied(txn));
      newTxnList = clipboardTxns.map((txn) => fixTxnToPaste(accountId, txn)).toList();

      this.props.dialogAlert(
        'Paste transactions?',
        `Are you sure you want to paste ${newTxnList?.size} transaction(s) into this account?`,
        (btnObj) => {
          if (btnObj.btnPressed === 'Yes') {
            this.props.qDataSaveTransactions(newTxnList);
            this.props.clearClipboard();
          }
        },
        ['No', 'Yes'],
      );
    }
  };

  render() {

    const {
      classes, onEdit, onDelete, onMatch, onDownload, selectedTxns, copyPasteEnabled,
      onReviewed, onNotReviewed, clipboardTxns, isMultiAccount, showReview,
    } = this.props;

    const txnsCopied = clipboardTxns?.size > 0;
    const numSelected = selectedTxns.size;
    const showMatch = !txnsCopied && this.matchAvailable();
    const txnInfo = this.getTxnInfo();

    const currSymbol = this.props.getSharedCurrency([selectedTxns]);
    const totalText = this.props.formatAmount(txnInfo.total, currSymbol);
    const selText = txnsCopied ?
      ` You copied ${clipboardTxns.size} Transaction${clipboardTxns.size > 1 ? 's' : ''}  to the clipboard (${totalText})` :
      ` ${numSelected} Transaction${numSelected > 1 ? 's' : ''} Selected  (${totalText})`;

    return (

      <div className={classes.container}>
        <div className={classes.iconContainer}>
          {showMatch &&
          <QIconButton
            aria-label="Match transactions"
            id="bulk-edit-ui-match"
            IconComponent={MatchIcon}
            onClick={onMatch}
            tooltip={`${isAcme ? 'Link' : 'Match'} the downloaded transaction to the manual transaction`}
            size="small-target"
          />}

          {!txnInfo.hasRecurring &&
            <>
              {(!txnsCopied || !isMultiAccount) && copyPasteEnabled &&
                <QIconButton
                  aria-label={`${txnsCopied ? 'Paste copied' : 'Copy selected'} transactions`}
                  id="bulk-edit-ui-copy"
                  IconComponent={txnsCopied ? AssignmentReturnedOutlinedIcon : FileCopyOutlinedIcon}
                  onClick={txnsCopied ? this.onPaste : this.onCopy}
                  tooltip={`${txnsCopied ? 'Paste selected transactions from clipboard' : 'Copy selected transactions to clipboard'}`}
                  size="small-target"
                />}

              {!txnsCopied &&
                <QIconButton
                  aria-label="Edit selected transactions"
                  id="bulk-edit-ui-edit"
                  IconComponent={EditIcon}
                  onClick={onEdit}
                  tooltip="Bulk edit all selected transactions at once"
                  size="small-target"
                />}
            </>}

          {!txnsCopied &&
            <QIconButton
              aria-label="Delete selected transactions"
              id="bulk-edit-ui-delete"
              IconComponent={DeleteIcon}
              onClick={onDelete}
              tooltip="Delete all selected transactions"
              size="small-target"
            />}

          {!txnsCopied && !txnInfo.hasRecurring && showReview &&
          <>
            <QIconButton
              aria-label="Mark selected transactions as reviewed"
              id="bulk-edit-ui-mark-as-reviewed"
              IconComponent={RadioButtonUncheckedIcon}
              IconProps={{ className: classNames(classes.reviewed, 'reviewed') }}
              onClick={onReviewed}
              tooltip="Mark all selected transactions reviewed"
              size="small-target"
            />
            <QIconButton
              aria-label="Mark all selected transactions as not reviewed"
              id="bulk-edit-ui-mark-as-not-reviewed"
              IconComponent={CircleIcon}
              IconProps={{ className: classNames(classes.reviewed, 'notReviewed') }}
              onClick={onNotReviewed}
              tooltip="Mark all selected transactions as not reviewed"
              size="small-target"
            />
          </>}

          {!txnsCopied &&
          <QIconButton
            aria-label="Download a CSV file with all selected transactions"
            id="bulk-edit-ui-mark-as-reviewed"
            IconComponent={FileDownload}
            onClick={onDownload}
            tooltip="Download a CSV file with all selected transactions"
            size="small-target"
          />}
        </div>
        <Typography
          type="subheading"
          className={classes.text}
          id="reg-header-selected-total"
        >
          {selText}
        </Typography>

        {txnsCopied &&
        <QButton
          id="copy-reg-cancel"
          variant="outlined"
          onClick={this.cancelCopy}
          className={classes.cancelBtn}
        >
          Cancel
        </QButton>}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    clipboardTxns: getClipboardForKey(state, 'bankTransactions')?.first()?.data,
    isMultiAccount: ownProps.accountIds?.size > 1,
    accountId: ownProps.accountIds.first(),
    copyPasteEnabled: featureFlagsSelectors.getFeatureFlags(state).get('copyPasteEnabled'),
    showReview: showReviewed(state),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    copyToClipboard: (txnList) => dispatch(clipboardActions.copyToClipboard(mkClipboardItem({
      data: txnList,
      key: 'bankTransactions',
    }))),
    clearClipboard: () => dispatch(clipboardActions.clearClipboardKey('bankTransactions')),
  };
}


RegMEditBar.propTypes = {
  selectedTxns: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onMatch: PropTypes.func,
  onReviewed: PropTypes.func,
  onNotReviewed: PropTypes.func,
  copyToClipboard: PropTypes.func,
  clipboardTxns: PropTypes.object,
  isMultiAccount: PropTypes.bool,
  accountId: PropTypes.string,
  clearClipboard: PropTypes.func,
  copyPasteEnabled: PropTypes.bool,
  showReview: PropTypes.bool,

  classes: PropTypes.object,

  // QCurrency
  getSharedCurrency: PropTypes.func,
  formatAmount: PropTypes.func,

  // QData & QDialog
  qDataSaveTransactions: PropTypes.func,
  dialogAlert: PropTypes.func,

};

export default compose(
  withStyles(styles),
  QCurrency(),
  QDialogs(),
  QData(),
  connect(mapStateToProps, mapDispatchToProps),
)(RegMEditBar);
