
import React from 'react';
import compose from 'utils/compose';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';

import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';

const outlineColor = '#ccc';
const taskHeight = 42;

const styles = () => ({
  container: {
    width: 194,
    height: taskHeight,
    display: 'flex',
  },
  taskFrame: {
    width: 155,
    height: taskHeight,
    background: 'white',
    borderRadius: 20,
    borderColor: outlineColor,
    borderWidth: 1,
    borderStyle: 'solid',
    flex: 1,
    '&.selected': {
      background: 'rgba(17, 102, 192, 0.2)',
    },
  },
  iconHolder: {
    flex: 0,
    minWidth: 24,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  checkIcon: {
    width: 24,
    height: 24,
    color: outlineColor,
    '&.completed': {
      color: 'green',
    },
  },
  connector: {
    flex: 0,
    minWidth: 20,
    height: 1,
    background: outlineColor,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  btnText: {
    lineHeight: `${taskHeight - 3}px`,
    fontSize: '16px',
    fontWeight: 500,
    color: '#1166b0',
    '&.completed': {
      color: '#555',
    },
  },
});

const TaskWidget = ({ classes, onClick, completed, selected, title, name }) => {

  const CheckIcon = completed ? CheckCircleIcon : CheckCircleOutline;
  const colorClass = (completed ? ' completed ' : '') + (selected ? ' selected' : '');

  return (
    <>
      <div className={classes.container}>
        <div className={classes.iconHolder}>
          <CheckIcon className={classNames(classes.checkIcon, colorClass)} />
        </div>
        <div className={classes.connector} />
        <ButtonBase onClick={(e) => onClick(e, name)}>
          <div className={classNames(classes.taskFrame, colorClass)}>
            <Typography className={classNames(classes.btnText, colorClass)}>
              {title}
            </Typography>
          </div>
        </ButtonBase>
      </div>
    </>
  );
};

TaskWidget.propTypes = {
  classes: PropTypes.object,
  completed: PropTypes.bool,
  selected: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func,
  name: PropTypes.string,
};

export default compose(
  withStyles(styles),
)(TaskWidget);

