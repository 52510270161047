import Downshift from 'downshift';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import withStyles from '@mui/styles/withStyles';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import compose from 'utils/compose';
import { noNaN } from 'utils/utils';
import TextField from '@mui/material/TextField';
import { isAcme } from 'isAcme';

/* eslint-disable no-underscore-dangle */

const isIe = require('is-iexplorer');

const styles = (theme) => ({
  icon: {
    paddingRight: 12,
  },
  frame: {
    flexGrow: 2,
    margin: 0,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  listContainer: {
    maxHeight: 300,
    backgroundColor: isAcme ? theme.palette.background.default : theme.palette.common.white,
    boxShadow: `0px 0px 2px 0px ${theme.components.payeeField.shadowColor}`,
    width: 'auto',
    marginRight: theme.spacing(1),
    position: 'fixed',
    fontSize: '13px',
    overflowY: 'auto',
    '& floating': {
      position: 'inherit',
    },
  },
  textField: {
    '& label.Mui-focused': {
      color: theme.palette.greyScaleDeprecated[0],
    },
    '& label': {
      color: theme.palette.greyScaleDeprecated[0],
    },

    '& input': {
      paddingBottom: 4,
      '&::placeholder': {
        color: theme.palette.greyScaleDeprecated[0],
        opacity: '100%',
      },
    },
  },
  active: {
    backgroundColor: theme.palette.action.active,
  },
  secondaryActionFrame: {
    overflow: 'visible',
    width: 2,
    height: 2,
    position: 'absolute',
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: 1,
  },
  secondaryAction: {
    marginLeft: -10,
    height: 'auto',
  },
  inputFrame: {
    position: 'relative',
    display: 'flex',
  },
  inputField: {
    '& input': {
      paddingTop: 0,
      paddingBottom: 0,
      height: isIe ? 27 : 24, // ie11 needs 27
      '&::-ms-clear ': {
        display: 'none',
      },
      '&::placeholder': {
        color: isAcme ? theme.components.txns.payeePlaceholder : 'black',
        opacity: '100%',
      },
    },
  },
  menuFrame: { position: 'relative' },
  menuAnchor: {
    zIndex: 2,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'visible',
    height: 0,
  },
});

export class MaterialCombobox extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      initialValue: props.value,
    };

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ initialValue: nextProps.value });
    }
  }

  updateInputValue = (event) => {
    const { onChangeInput } = this.props;
    if (onChangeInput) {
      onChangeInput(event);
      this.setState({ initialValue: null });
    }
  };

  stateReducer = (state, changes) => {

    switch (changes.type) {

      case '__autocomplete_click_item__':
      case '__autocomplete_blur_input__':
        if (state.selectedItem) { // } && state.selectedItem.id === 'viewAll') {
          return {
            ...changes,
            inputValue: null,
          };
        }
        break;

      default:
        return changes;
    }
    return changes;
  };

  itemChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
    if (this.target) {
      this.target.select();
    }
  };

  handleFocus = (e) => {
    e.target.select();
    this.target = e.target;
  };

  keyDown = (e, items) => {
    if (e.key === 'Escape') {
      this.state.initialValue = this.props.value;
    }
    if (e.key === 'Tab') {
      if (items && Math.max(noNaN(items.size), noNaN(items.length)) > 0) {
      /*
        const event = new KeyboardEvent('keydown', {
          key: 'Enter',
          keyCode: 13,
          bubbles: true,
          cancelable: true,
        });

        this.target.dispatchEvent(event);

      */
        // this.itemChange(items.get('0'));
        // console.log("payee: KEYDOWN TAB ");
      }
    } else if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }
  };

  render() {
    const {
      items,
      displayTemplate,
      placeholder,
      searchTemplate,
      inputDisplayTemplate,
      classes,
      disableUnderline,
      onBlur,
      // bulkEdit,
      label,
      textFieldVariant,
      marginProp,
      // value,
      textFieldProps,
    } = this.props;

    function autoCompleteContents(options) {
      const {
        // clearSelection,
        getInputProps,
        getItemProps,
        highlightedIndex,
        inputValue,
        isOpen,
        selectHighlightedItem,
        selectedItem,
        // toggleMenu,
      } = options;

      function autoCompleteItemToHtml(item, index) {
        let selected = false;

        if (selectedItem) {
          if (item.id && selectedItem.id) {
            selected = item.id === selectedItem.id;
          }
        }

        const _props = getItemProps({
          item,
          index,
          dataHighlighted: highlightedIndex === index,
          dataSelected: selected,
          onClick: () => {
            selectHighlightedItem();
          },
        });

        let stateColor = 'rgba(0,0,0,0)';

        if (_props.dataHighlighted) {
          stateColor = 'rgba(0,0,0,0.12)';
        }

        if (_props.dataSelected) {
          stateColor = 'rgba(0,0,0,0.0)';
        }

        return displayTemplate
          ? displayTemplate(item, _props, inputValue)
          :
          <ListItem
            {..._props}
            style={{
              backgroundColor: stateColor,
            }}
          >
            <ListItemText primary={`${item}`} />
          </ListItem>;
      }

      function valuesBySearchTerm(item) {
        const _val = inputValue || '';

        if (!_val) {
          return true;
        }

        const searchThis = searchTemplate
          ? searchTemplate(item).toLowerCase()
          : item.toString();

        return searchThis.indexOf(_val.toLowerCase()) === 0;
      }


      let filteredItems = [];
      let autoCompleteMenuItems = [];
      if (isOpen) {
        filteredItems = items.filter(valuesBySearchTerm.bind(this));
        autoCompleteMenuItems = filteredItems.map(autoCompleteItemToHtml.bind(this));
        if (this.props.onListChange) {
          this.props.onListChange(filteredItems);
        }
      }

      const autoCompleteMenu = (
        <div className={classes.menuAnchor}>
          <List
            className={classes.listContainer}
            style={this.props.menuPosition ? { position: this.props.menuPosition } : null}
          >
            {autoCompleteMenuItems}
          </List>
        </div>
      );

      const _value = inputValue || this.state.initialValue || '';

      const _inputProps = getInputProps({
        placeholder: placeholder || '',
        value: _value,
        className: textFieldVariant === 'outlined' ? '' : classes.inputField,
        // label: null,
        label,
        onChange: this.updateInputValue,
        autoFocus: true,
        onFocus: this.handleFocus,
        onKeyDown: (e) => this.keyDown(e, filteredItems),
        name: this.props.name,
        // padding added directly to the right input to accommodate the menu icon
        InputProps: {
          inputProps: {
            ref: this.props.inputRef,
            style: { fontSize: this.props.fontSize || '13px', paddingRight: 6, minHeight: 23 },
            'aria-label': 'Payee Field',
          },
          onBlur,
          ...(textFieldVariant !== 'outlined' && { disableUnderline }),
        },
      });

      const retItems = [];
      retItems.push(
        <div className={classes.frame}>
          <div className={classes.inputFrame} style={{ width: this.props.width }}>
            <TextField
              variant={textFieldVariant}
              margin={marginProp}
              {...textFieldProps}
              {..._inputProps}
              fullWidth
            />
          </div>
          <div className={classes.menuFrame}>
            {isOpen ? autoCompleteMenu : null}
          </div>
        </div>
      );
      return retItems;
    }

    return (
      <Downshift
        {...this.props}
        stateReducer={this.stateReducer}
        onChange={this.itemChange}
        itemToString={inputDisplayTemplate}
      >
        {autoCompleteContents.bind(this)}
      </Downshift>
    );
  }
}

MaterialCombobox.propTypes = {
  onChangeInput: PropTypes.func,
  onKeyDown: PropTypes.func,
  items: PropTypes.object,
  displayTemplate: PropTypes.func,
  placeholder: PropTypes.string,
  searchTemplate: PropTypes.func,
  inputDisplayTemplate: PropTypes.func,
  classes: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onListChange: PropTypes.func,
  name: PropTypes.string,
  disableUnderline: PropTypes.bool,
  menuPosition: PropTypes.string,
  inputRef: PropTypes.func,
  fontSize: PropTypes.string,
  label: PropTypes.string,
  textFieldProps: PropTypes.object,
  width: PropTypes.string,
  textFieldVariant: PropTypes.string,
  marginProp: PropTypes.string,
};

MaterialCombobox.defaultProps = {
  width: 'auto',
  marginProp: 'none',
};

export default compose(
  withStyles(styles, { withTheme: true }),
)(MaterialCombobox);
