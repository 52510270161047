import { createAction } from 'redux-actions';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import { DIALOG_TYPE as DIALOG_HELP_SUPPORT } from './types';

export const doHelpSupportModal = createAction(
  createDialog,
  () => mkRootUiData({
    id: 'DIALOG_HELP_SUPPORT',
    type: DIALOG_HELP_SUPPORT,
    allowNesting: false,
  })
);
