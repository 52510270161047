import { Record } from 'immutable';

import { resourceStoreTypes } from '@quicken-com/react.flux.core';

export const TransactionStore = Record({
  ...resourceStoreTypes.mkQcsSyncResourceStore().toObject(),
  lastReductions: null,
});

export const TxnPendingStates = {
  QCS_REFRESH_BANK_PENDING: 'QCS_REFRESH_BANK_PENDING',
  QCS_BATCH_REFRESH_BANK_PENDING: 'QCS_BATCH_REFRESH_BANK_PENDING',
  USER_OWNED_BANK_PENDING: 'USER_OWNED_BANK_PENDING',
  REAL_TIME: 'REAL_TIME',
  USER_OWNED_REAL_TIME_BANK_PENDING: 'USER_OWNED_REAL_TIME_BANK_PENDING',
};
