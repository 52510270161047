// @flow
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { fromJS } from 'immutable';

import { getLogger } from '@quicken-com/react.utils.core';
import { authSelectors } from '@quicken-com/react.flux.auth';

import * as actions from './actions';

const log = getLogger('data/persist/sagas');

export const load = (key: string) => {
  try {
    const serializedData = localStorage?.getItem(key);
    log.log('SerializedData is', serializedData);
    if (!serializedData) {
      return undefined;
    }
    return JSON.parse(serializedData);
  } catch (err) {
    return undefined;
  }
};

export const save = (key: string, data: string) => {
  try {
    const serializedData = JSON.stringify(data);
    localStorage?.setItem(key, serializedData);
  } catch (err) {
    log.error('Failed to save perisistent data', key, data);
  }
};

export function* loadPersistedDatasetData(): Generator<*, *, *> {
  const datasetId = yield select(authSelectors.getDatasetId);
  log.debug('Loading Persisted Dataset Data', datasetId);

  let institutionLoginsBlobs = yield call(load, `${datasetId}_institutionLoginsBlobs`);
  if (institutionLoginsBlobs) {
    institutionLoginsBlobs = fromJS(institutionLoginsBlobs);
    yield put(actions.loadedInstitutionLoginsCredentialBlobs(institutionLoginsBlobs));
  }
}

export function* saveInstitutionLoginsCredentialBlobs({ payload: blobs } : any): Generator<*, *, *> {
  const datasetId = yield select(authSelectors.getDatasetId);
  log.debug('Saving Persisted Dataset Data, institutionLoginsBlobs', datasetId);

  yield call(save, `${datasetId}_institutionLoginsBlobs`, blobs);
}

export function* loadPersistedDatasetDataWatcher(): Generator<*, *, *> {
  yield takeLatest(actions.loadPersistedDatasetData, loadPersistedDatasetData);
}

export function* saveInstitutionLoginsCredentialBlobsWatcher(): Generator<*, *, *> {
  yield takeLatest(actions.saveInstitutionLoginsCredentialBlobs, saveInstitutionLoginsCredentialBlobs);
}

// for loading watcher sagas
export default [
  loadPersistedDatasetDataWatcher,
  saveInstitutionLoginsCredentialBlobsWatcher,
];
