// CORE
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';
import { transactionsActions } from '@quicken-com/react.flux.transactions';

// MUI

// DATA
import * as transactionSelectors from 'data/transactions/selectors';

// CUSTOM
import useQDialogs from 'components/QDialogs/useQDialogs';

import * as qdata from './core';


function useQData(wrapperId, robotMode) {

  const txnsByAccountId = useSelector(transactionSelectors.getTransactionsByAccountId);
  const featureFlags = useSelector(featureFlagsSelectors.getFeatureFlags);

  const dispatch = useDispatch();
  const createTransactions = (payload, meta) => dispatch(transactionsActions.createTransactions(payload, meta));
  const updateTransactions = (payload, meta) => dispatch(transactionsActions.updateTransactions(payload, meta));
  const deleteTransactions = (payload, meta) => dispatch(transactionsActions.deleteTransactions(payload, meta));
  const performTransactionAction = (payload, meta) => dispatch(transactionsActions.performTransactionAction(payload, meta));
  const genericDispatch = (action) => dispatch(action);

  const { dialogAlert } = useQDialogs();

  const forwardProps = {
    wrapperId,
    robotMode,
    createTransactions,
    deleteTransactions,
    updateTransactions,
    performTransactionAction,
    genericDispatch,
    featureFlags,
    txnsByAccountId,
    dialogAlert,
  };

  const QDperformTransactionAction = (action) => qdata.performTransactionAction(action, forwardProps);
  const QDunMatchTransaction = (txn) => qdata.unMatchTransaction(txn, forwardProps);
  const QDmatchTransactions = (txns) => qdata.matchTransactions(txns, forwardProps);
  const QDdeleteTransactions = (txns) => qdata.deleteTransactions(txns, forwardProps);
  const QDsaveTransactions = (txns, cb) => qdata.saveTransactions(txns, cb, forwardProps);
  const QDsetBalanceAdjust = (accountId, date, amount) => qdata.setBalanceAdjust(accountId, date, amount, forwardProps);

  return {
    qDataSaveTransactions: QDsaveTransactions,
    qDataDeleteTransactions: QDdeleteTransactions,
    qDataMatchTransactions: QDmatchTransactions,
    qDataUnMatchTransaction: QDunMatchTransaction,
    qDataPerformTransactionAction: QDperformTransactionAction,
    qDataSetBalanceAdjust: QDsetBalanceAdjust,
  };
}

useQData.propTypes = {
  wrapperId: PropTypes.string,
  robotMode: PropTypes.bool,
};
useQData.defaultProps = {
  robotMode: false,
};

export default useQData;
