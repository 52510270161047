// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import compose from 'utils/compose';
import { Map as ImmutableMap } from 'immutable';
import { DateTime } from 'luxon';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';

import { authActions, authSelectors } from '@quicken-com/react.flux.auth';
import { profileSelectors } from '@quicken-com/react.flux.profile';

import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

import { DIALOG_TYPE as DIALOG_CONFIRMATION, mkConfirmationDialogProps } from 'components/Dialogs/ConfirmationDialog';
import QHelp from 'components/QHelp';

import { createDialog as createDialogAction, updateDialogProps as updateDialogPropsAction } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';

import QPreferences from 'components/QPreferences';

import { getBuildConfig, getLogger, localPreferences } from '@quicken-com/react.utils.core';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';
import { safeRefIn } from 'utils/utils';

import { isAcme } from 'isAcme';
import VersionDisplay from './versionDisplay';

const log = getLogger('components/Main/index.js');

export const InactivityCountdown = styled.div`
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
`;

type Props = {
  children: React.Node,

  // from QHelp
  showQHelp: any,

  // from QPreferences
  setDatasetPreference: (Object) => void,
  datasetPreferences: Object,

  // from connect
  //
  isLoggedIn: boolean,
  profile: Object,

  checkDesktopMinVersion: boolean,
  minWindowsBuildNumber: string,
  minMacReleaseNumber: string,

  authLogout: (Object, Object) => void,
  createDialog: (Object) => void,
  updateDialogProps: (Object) => void,
};

let versionWarningGiven = isAcme;

export function resetVersionWarning() {
  versionWarningGiven = isAcme;
}

function meetsMinimumVersionReqs(v1, minVer) {

  // prevent a crash, tell user to fix their profile :-)
  if (!v1 || !minVer) {
    return false;
  }

  const v1Parts = v1.split('.');
  const v2Parts = minVer.split('.');
  let retVal;
  let index = 0;

  while (index < v1Parts.length && retVal === undefined) {
    if (Number(v1Parts[index]) !== Number(v2Parts[index])) {
      retVal = Number(v1Parts[index]) > Number(v2Parts[index]);
    }
    index += 1;
  }
  // equality (still undefined) means strings are equal, and requirements are met
  return retVal === undefined ? true : retVal;
}

class Main extends React.PureComponent<Props> {

  // These are instance variables so changing does not directly cause rendering invalidation.

  inactivityTimeoutInSeconds = getBuildConfig().inactivity_timeout_in_seconds || 900;
  inactivityExpirationTime = null;
  inactivityExpirationChecker = null;

  inactivityCountdownTimeoutInSeconds = getBuildConfig().inactivity_countdown_timeout_in_seconds || 30;
  inactivityCountdownUpdater = null;
  inactivityCountdownValue = -1;

  inactivityCountdownDialogId = null;

  constructor(props) {
    super(props);

    //
    // this is a kiosk hack, if you add ?kioskmode to the URL, it will disable the
    // inactivity timeout, but only for that app session (module existence).  If this module
    // is reloaded (i.e. refresh page) and kioskmode is not in the URL at that point, it will
    // re-enable the timeout.  Currently the app does not preserve arbitrary url parms across pages
    //
    const queryObj = queryString.parse(window.location.search);
    if ('kioskmode' in queryObj) {
      props.setDatasetPreference({ kioskmode: Boolean(queryObj.kioskmode === 'enabled') });
      log.log(`Setting Kiosk Mode to ${String(queryObj.kioskmode === 'enabled')}`);
    }
    log.log(`Kiosk Mode is ${String(queryObj.kisokmode === 'enabled')}`);
  }

  UNSAFE_componentWillReceiveProps() {
    //
    // Just once check to see if the version of the desktop version being used (for companion app) is
    // at the minumum version number supported for Mac and Windows
    //
    if (this.props.profile && !versionWarningGiven && this.props.checkDesktopMinVersion) {
      if (this.props.profile.product) {

        const clientType = safeRefIn(this.props.profile, ['product', 'clientType']);

        let yourBuildNumber;
        let minBuildNumber;
        let numberField;
        let skipWarning = false;

        if (clientType === 'MAC') {
          numberField = 'releaseNumber';
          yourBuildNumber = safeRefIn(this.props.profile, ['product', numberField]);
          minBuildNumber = this.props.minMacReleaseNumber;

          const fields = yourBuildNumber.split('.');
          if (Number(fields[0]) === 0) {
            skipWarning = true;
          }
        } else {
          numberField = 'buildNumber';
          yourBuildNumber = safeRefIn(this.props.profile, ['product', numberField]);
          minBuildNumber = this.props.minWindowsBuildNumber;
          // this sucks, windows build numbers are nonsensical, and I just have to hack through them
          // if 2nd or 3rd field is zero, this is either a beta or dev build
          //
          const fields = yourBuildNumber.split('.');
          if (Number(fields[1]) === 0 || Number(fields[2]) === 0) {
            skipWarning = true;
          }
        }

        const giveWarning = !skipWarning &&
          !meetsMinimumVersionReqs(yourBuildNumber, minBuildNumber);

        log.log(`CHECKING DESKTOP VERSION .${clientType}. `, minBuildNumber, yourBuildNumber);

        if (giveWarning) {
          this.props.showQHelp('upgradeVersion', { ...safeRefIn(this.props.profile, ['product']), minBuildNumber });
          versionWarningGiven = true;
        }
      }
    }
  }

  // Handle situation where user has already expired but this wasn't detected during our normal checks. This
  // is mostly likely to occur when user puts their system into hibernate mode and then comes back to it.
  //
  checkIfAlreadyExpired = (now) => {
    if (this.inactivityExpirationTime
      && now > this.inactivityExpirationTime
      && now > this.inactivityExpirationTime.plus({ seconds: this.inactivityCountdownTimeoutInSeconds })
    ) {
      log.log('Already past expiration and countdown time, so logging out...');
      this.logout('AUTH_LOGOUT_INACTIVE', 'checkIfAlreadyExpired');
      return true;
    }
    return false;
  };

  startOrResetInactivityChecker = () => {
    // log.debug('Starting or Resetting Inactivity Checks');
    const now = DateTime.local();

    if (this.checkIfAlreadyExpired(now)) {
      return;
    }

    this.inactivityExpirationTime = now.plus({ seconds: this.inactivityTimeoutInSeconds });
    if (this.inactivityExpirationChecker === null) {
      // log.debug('Starting Inactivity Checker');
      this.inactivityExpirationChecker = setInterval(() => {
        // log.debug('Inside Inactivity Checker');
        if (!this.inactivityCountdownUpdater) {
          const nowInInactivityTimeoutChecker = DateTime.local();
          if (this.inactivityExpirationTime && nowInInactivityTimeoutChecker >= this.inactivityExpirationTime) {
            this.startInactivityCountdown();
          }
        }
      }, 1000);
    }
  };

  startInactivityCountdown = () => {
    this.inactivityCountdownValue = this.inactivityCountdownTimeoutInSeconds;
    // log.debug('Starting Inactivity Countdown Updater');
    this.inactivityCountdownUpdater = setInterval(() => {
      // log.debug('Inside Inactivity Countdown Updater');
      if (this.checkIfAlreadyExpired(DateTime.local())) {
        return;
      }
      if (this.inactivityCountdownValue <= 0) {
        this.logout('AUTH_LOGOUT_INACTIVE', 'inactivityCountdownValue');
      } else {
        this.inactivityCountdownValue -= 1;
        this.props.updateDialogProps({
          id: this.inactivityCountdownDialogId,
          props: ImmutableMap({ content: this.getContentForInactivityCountdownDialog() }),
        });
      }
    }, 1000);

    this.inactivityCountdownDialogId = uuidv4();
    this.props.createDialog(mkRootUiData({
      id: this.inactivityCountdownDialogId || '0',
      type: DIALOG_CONFIRMATION,
      allowNesting: true,
      props: ImmutableMap(mkConfirmationDialogProps({
        title: 'Session Inactivity',
        content: this.getContentForInactivityCountdownDialog(),
        confirmLabel: 'Continue',
        confirmAction: this.inactivityCountdownContinueAction,
        denyLabel: 'Logout',
        denyAction: this.inactivityCountdownLogoutAction,
        dialogCloseAliasAction: 'CONFIRM',
      })),
    }));
  };

  stopInactivityChecker = () => {
    if (this.inactivityExpirationChecker !== null) {
      clearInterval(this.inactivityExpirationChecker);
      this.inactivityExpirationChecker = null;

      this.inactivityExpirationTime = null;
    }
  };

  stopInactivityCountdown = () => {
    if (this.inactivityCountdownUpdater) {
      clearInterval(this.inactivityCountdownUpdater);
      this.inactivityCountdownUpdater = null;

      this.inactivityCountdownValue = -1;
      this.inactivityCountdownDialogId = null;
    }
  };

  inactivityCountdownContinueAction = () => {
    this.stopInactivityCountdown();
    this.startOrResetInactivityChecker();
  };

  inactivityCountdownLogoutAction = () => {
    this.logout('AUTH_LOGOUT_USER_INITIATED', 'inactivityCountdownLogoutAction');
  };

  getContentForInactivityCountdownDialog() {
    return (
      <DialogContent>
        <Typography variant="subtitle1">
          To ensure security of your session please confirm that you want to continue or you will be logged out automatically.
        </Typography>
        <InactivityCountdown>
          {this.inactivityCountdownValue}
        </InactivityCountdown>
      </DialogContent>
    );
  }

  logout = (reason, context) => {
    this.props.authLogout({ reason }, { context });
  };

  handleEvent = () => {
    if (this.inactivityExpirationChecker !== null && this.inactivityCountdownUpdater === null) {
      this.startOrResetInactivityChecker();
    }
  };

  render() {

    const inKioskmode = this.props.datasetPreferences.kioskmode;

    // Note: This is not a typical way of doing things in React. This works because we are using instance variables
    // instead of state and not directly relying on these variavbles during the actual render.
    //
    if (!inKioskmode && this.props.isLoggedIn && this.inactivityExpirationChecker === null && !localPreferences.getKeepLoggedIn()) {
      this.startOrResetInactivityChecker();
    } else if ((inKioskmode || !this.props.isLoggedIn) && this.inactivityExpirationChecker !== null) {
      this.stopInactivityCountdown();
      this.stopInactivityChecker();
    }

    return (
      <div id="main" onMouseOver={this.handleEvent} onFocus={this.handleEvent}>
        {this.props.children}
        <VersionDisplay />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: authSelectors.getAuthSession(state).accessToken,
    profile: profileSelectors.getProfile(state),
    checkDesktopMinVersion: featureFlagsSelectors.getFeatureFlags(state).get('checkDesktopMinVersion'),
    minWindowsBuildNumber: featureFlagsSelectors.getFeatureFlags(state).get('minWindowsBuildNumber'),
    minMacBuildNumber: featureFlagsSelectors.getFeatureFlags(state).get('minMacBuildNumber'),
    minMacReleaseNumber: featureFlagsSelectors.getFeatureFlags(state).get('minMacReleaseNumber'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authLogout: (data, meta) => dispatch(authActions.authLogout(data, meta)),
    createDialog: (rootUiData) => dispatch(createDialogAction(rootUiData)),
    updateDialogProps: (props) => dispatch(updateDialogPropsAction(props)),
  };
}

export default compose(
  QPreferences(),
  QHelp(),
)(connect(mapStateToProps, mapDispatchToProps)(Main));
