import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'utils/compose';
import classNames from 'classnames';

import { authSelectors } from '@quicken-com/react.flux.auth';

import QTip from 'components/QuickenControls/QTip';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import RefreshIcon from '@mui/icons-material/RefreshRounded';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';

import ErrorLinearProgress from 'components/ErrorLinearProgress';
import { appRefreshAll as refreshAllAction } from 'data/app/actions';
import * as selectors from 'data/app/selectors';

const styles = (theme) => ({
  buttonRoot: {
    outline: 'none !important',
    fontSize: 'unset',
  },
  refreshButtonDisabled: {
    opacity: 0.6,
  },
  disabledIcon: {
    opacity: 0.75,
  },
  refreshDivButton: {
  },
  navText: {
    color: theme.components.navigation.textColor,
  },
  disabledRefreshDivButton: {
    pointerEvents: 'none',
  },
});

const RefreshButton = (props) => {
  const {
    classes, disableRefreshButton, isRefreshing, refreshAll, datasetId,
    updateAllFailed, size, theme, isInAccountBar, updateAllMessages, closeHeader,
  } = props;

  const refreshAction = (e) => {
    closeHeader?.();
    e.stopPropagation();
    refreshAll();
  };

  // Main icon button that acme and quicken use
  const refreshIconRoot = (
    <IconButton
      tabIndex={isInAccountBar ? -1 : 0}
      aria-label="Refresh All"
      disabled={disableRefreshButton}
      classes={{
        root: classes.buttonRoot,
        disabled: classes.refreshButtonDisabled,
      }}
      style={{ color: !isInAccountBar && theme.components.navigation.iconColor, padding: !isInAccountBar && 0 }}
      onClick={refreshAction}
      size="large"
    >
      {updateAllFailed ? <SyncProblemIcon style={{ fontSize: size }} /> : <RefreshIcon style={{ fontSize: size }} />}
    </IconButton>
  );


  if (!datasetId) {
    return null;
  }

  return isInAccountBar ?
    <>
      {isRefreshing && (
        <IconButton
          aria-label="Refresh All Busy"
          disabled
          classes={{ root: classes.buttonRoot }}
          size="large"
        >
          <CircularProgress
            size={size || 22}
            style={{ color: theme.components.accountDrawer.refreshIcon }}
          />
        </IconButton>
      )}
      {!isRefreshing && (
        <QTip
          html={updateAllMessages && updateAllMessages.size ?
            updateAllMessages.map((message) => <div key={message}>{message}</div>) :
            <div>Update All</div>}
          placement="right"
        >
          {refreshIconRoot}
        </QTip>
      )}
    </> :
    <div
      role="button"
      tabIndex={0}
      onClick={refreshAction}
      onKeyPress={refreshAction}
      className={classNames(classes.refreshDivButton, disableRefreshButton && classes.disabledRefreshDivButton)}
    >
      {isRefreshing ? (
        <IconButton
          aria-label="Refresh All Busy"
          disabled
          classes={{ root: classes.buttonRoot }}
          style={{ padding: 0 }}
          size="large"
        >
          <CircularProgress
            size={size || 22}
            style={{ color: theme.components.navigation.iconColor }}
          />
        </IconButton>
      ) : (
        <QTip
          html={updateAllMessages?.map?.((message) => <div key={message}>{message}</div>)}
          placement="right"
        >
          {refreshIconRoot}
        </QTip>
      )}

      <Typography style={{ marginLeft: 24, textAlign: 'left' }} className={classes.navText}>
        Refresh
      </Typography>

      {updateAllFailed && <ErrorLinearProgress />}
    </div>;
};

RefreshButton.propTypes = {
  disableRefreshButton: PropTypes.bool,
  isRefreshing: PropTypes.bool,
  updateAllFailed: PropTypes.bool,
  refreshAll: PropTypes.func,
  classes: PropTypes.object,
  theme: PropTypes.object,
  updateAllMessages: PropTypes.object,
  size: PropTypes.number,
  datasetId: PropTypes.string,
  isInAccountBar: PropTypes.bool,
  closeHeader: PropTypes.func,
};

const mapStateToProps = (state) => ({
  disableRefreshButton: selectors.disableRefreshButton(state),
  isRefreshing: selectors.isRefreshing(state),
  updateAllFailed: selectors.getUpdateAllFailed(state),
  updateAllMessages: selectors.getUpdateAllMessages(state),
  datasetId: authSelectors.getDatasetId(state),
});

const mapDispatchToProps = (dispatch) => ({
  refreshAll: () => dispatch(refreshAllAction(undefined, { context: 'refreshAll' })),
});

export default compose(
  withStyles(styles, { name: 'RefreshAllButton', withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps)
)(RefreshButton);
