import { put, call, take, takeEvery, takeLatest, select } from 'redux-saga/effects';
import * as categoryGroupsActions from 'data/categoryGroups/categoryGroupsActions';
import { getLastSyncDate, getCategoryGroupsByIdRaw } from 'data/categoryGroups/categoryGroupsSelectors';

import { resourceStoreTypes, resourceSagaUtils } from '@quicken-com/react.flux.core';
import { categoriesActions } from '@quicken-com/react.flux.categories';

export function* getCategoryGroupsActionWatcher() {
  while (true) {
    const action = yield take(categoryGroupsActions.getCategoryGroupsAction);

    const config = resourceStoreTypes.mkQcsSyncResourceConfig({
      resourceName: 'categoryGroups',
      resourceBaseUrl: '/category-groups',
      getLastSyncDate,
      // transformResponseToResources: ,
      // transformResourceToRequestData:,
      successAction: categoryGroupsActions.getCategoryGroupsSuccessAction,
      failureAction: categoryGroupsActions.getCategoryGroupsFailureAction,
    });
    yield call(resourceSagaUtils.qcsSyncGetResources, config, action);
  }
}

export function* updateCategoryGroups(action) {

  const config = resourceStoreTypes.mkQcsSyncResourceConfig({
    resourceName: 'categoryGroups',
    resourceBaseUrl: '/category-groups',
    getLastSyncDate,
    // transformResponseToResources: ,
    // transformResourceToRequestData:,
    successAction: categoryGroupsActions.updateCategoryGroupSuccessAction,
    failureAction: categoryGroupsActions.updateCategoryGroupFailureAction,
  });
  yield call(resourceSagaUtils.qcsSyncUpdateResource, config, action);
}

export function* triggerUpdateCategoryGroups(action) {
  const catGroups = yield select(getCategoryGroupsByIdRaw);

  if (catGroups.size > 0) {
    const category = action.payload;

    /*
     CREATE CATEGORY
     */
    switch (action.type) {

      case 'UPDATE_CATEGORY_SUCCESS': {
        const catGroup = catGroups.find((x) => x?.coas?.find((y) => y.id === category.id));
        // console.log("UPDATE CATEGORY ", category.id, catGroup && catGroup.type, category.type);
        if (catGroup && catGroup.type !== category.type) {
          // console.log("UPDATING CATEGORY GROUPS");
          yield call(addToCategoryGroup, catGroups, category);
          yield call(deleteFromCategoryGroup, catGroups, category);
        }
        break;
      }

      case 'CREATE_CATEGORY_SUCCESS': {
        // console.log("CREATE CATEGORY SUCCESS");
        yield call(addToCategoryGroup, catGroups, category);
        break;
      }
      case 'DELETE_CATEGORY_SUCCESS': {
        // console.log("DELETE CATEGORY SUCCESS");
        yield call(deleteFromCategoryGroup, catGroups, category);
        break;
      }

      default:
        break;
    }
  }
}

export function* addToCategoryGroup(catGroups, category) {
  // first try and put it into the proper type for PERSONAL
  let catGroup = catGroups.find((x) => x.get('type') === category.type && x.get('usageType') === 'PERSONAL');
  if (catGroup) {
    catGroup = catGroups.find((x) => x.get('type') === category.type);
    if (catGroup) {
      // console.log("CAT GROUP FOUND IS ", catGroup, catGroup.coas.length);
      const { coas = [] } = catGroup;
      coas.push({ type: 'CATEGORY', id: category.id });
      catGroup = catGroup.set('coas', coas);
      // console.log("NEW CAT GROUP IS ", catGroup, catGroup.coas.length);
      yield put(categoryGroupsActions.updateCategoryGroupAction(catGroup));
    }
  }
}

export function* deleteFromCategoryGroup(catGroups, category) {

  let catGroup = catGroups.find((x) => x?.coas?.find((y) => y.id === category.id));
  if (catGroup) {
    // console.log("CAT GROUP MYABE FOUND IS ", catGroup, catGroup.coas.length);
    catGroup = catGroup.set('coas', catGroup.coas.filter((x) => x.id !== category.id));
    // console.log("NEW CAT GROUP IS ", catGroup.toJS());
    yield put(categoryGroupsActions.updateCategoryGroupAction(catGroup));
  }
}

export function* categoryChangesActionWatcher() {
  yield takeLatest([
    categoriesActions.deleteCategorySuccess,
    categoriesActions.createCategorySuccess,
    categoriesActions.updateCategorySuccess,
  ], triggerUpdateCategoryGroups);
}

export function* updateCategoryGroupsActionWatcher() {
  yield takeEvery(categoryGroupsActions.updateCategoryGroupAction, updateCategoryGroups);
}


export default [getCategoryGroupsActionWatcher, updateCategoryGroupsActionWatcher, categoryChangesActionWatcher];
