//
// SELECTORS, these are functions designed to work directly on and with the
// redux data store
//
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';

import { accountsSelectors } from '@quicken-com/react.flux.accounts';

import { getGlobalCache, setGlobalCache } from 'utils/auth/caches';

import { INVESTMENT_HOLDINGS_REDUCER_KEY } from './reducer';

// const log = getLogger('data/investmentHoldings/selectors.js');

export const getIsLoading = (state) => state[INVESTMENT_HOLDINGS_REDUCER_KEY].isLoading;

export const getIsQuotesLoading = (state) => state[INVESTMENT_HOLDINGS_REDUCER_KEY].isQuotesLoading;

export const getIHoldsByAccountId = (state) => state[INVESTMENT_HOLDINGS_REDUCER_KEY].iHoldsByAccountId;

export const getLastSyncDate = (state) => state[INVESTMENT_HOLDINGS_REDUCER_KEY].lastSyncDate;

export const getIHoldByAccountIdAndIHoldId = (state, props) =>
  state[INVESTMENT_HOLDINGS_REDUCER_KEY].iHoldsByAccountId.get(props.accountId).get(props.iHoldId);

// getIHoldsByAccountIdsSelectors
//
// We are using a selector based on accounts to return a map of selectors that
// can then be used to get investment holdings for a specific account. This map of
// selectors are stored in a global cache based on the current login.
//
// We are using this level of indirection and a global cache so that each account
// will have it's own selector for the retrieval of transactions which will not
// be invalidated by the adding or removing of accounts, the updating of the account
// or changing transaction data within the account.
//
// The selectors returned by this method are not typical selectors. Instead of
// a state and props, the expected arguments are a map of holdings by
// account ID and options map. Currently the only supported option are
// sortBy and sortOrder.
//
export const getIHoldsByAccountIdsSelectors = createSelector(
  accountsSelectors.getAccountsById,
  (accounts) => {
    let iHoldsByAccountIdsSelector = getGlobalCache('INVESTMENT_HOLDING_SELECTORS');
    if (!iHoldsByAccountIdsSelector) {
      setGlobalCache('INVESTMENT_HOLDING_SELECTORS', iHoldsByAccountIdsSelector = new Map());
    }

    // log.debug('accounts:', accounts.toJS());

    const activeAccountIds = [];
    accounts.forEach((account) => {

      if (account.type === 'INVESTMENT') {
        activeAccountIds.push(account.id);

        // log.debug('account:', account.toJS());

        // if selector doesn't exist, add it
        if (!iHoldsByAccountIdsSelector.get(account.id)) {
          const selector = createSelector(
            (iHoldsByAccountIds) => iHoldsByAccountIds.get(account.id),
            (iHoldsByAccountIds, options) => options,
            (iHolds) => {   // (iHolds, options) if you need the options
              // handle special case of no transactions in account

              if (iHolds === undefined || iHolds.size === 0) {
                return ImmutableList([]);
              }
              return ImmutableList(iHolds.toList());
            }
          );

          iHoldsByAccountIdsSelector.set(account.id, selector);
        }
      }
    });
    return iHoldsByAccountIdsSelector;
  }
);
