// CORE
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// MUI
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/AddRounded';

// DATA
import { removeDialog } from 'data/rootUi/actions';

// COMPONENTS
import StdDialog from 'components/Dialogs/StdDialog';
import RenameRuleDialogForm from 'components/Forms/RenameRuleDialogForm';
import LoadingView from 'components/LoadingView';
import QPanelButton from 'components/QPanelButton';
import QButton from 'components/QButton';
import ChecklistIcon from 'assets/checklist-rules.svg';
import makeStyles from '@mui/styles/makeStyles';

export const DIALOG_TYPE_RENAME_RULE_EDIT = 'DIALOG_TYPE_RENAME_RULE_EDIT';

const useStyles = makeStyles((theme) => ({
  addButton: {
    marginTop: theme.spacing(2),
  },
  panelButton: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
}));

const SelectRule = ({ rules, renamePayeeTo, onSelectRule }) => {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="space-around" padding={3} minHeight={500}>
      <img style={{ height: 100 }} src={ChecklistIcon} alt="checklist" />
      <Box dsplay="flex" alignItems="flex-start" flexDirection="column" paddingTop={4}>
        <Typography variant="body2">Future transactions matching keywords from these rules will be renamed to&nbsp;<b>{renamePayeeTo}</b></Typography>
        <br />
        <Typography variant="body2">Keywords are not case-sensitive and match when they appear in the exact order shown</Typography>
        {rules.toIndexedSeq().map((rule, index) => (
          <QPanelButton
            key={`rename-rule-select-${rule.id}`}
            onClick={() => onSelectRule(rule?.toJS?.())}
            className={classes.panelButton}
            title={`Rule ${index + 1}`}
            subtitle={`Keywords: ${rule.renamePayeeFrom?.replaceAll(' ', ', ') || 'ERROR'}`}
          />
        ))}
        <QButton
          startIcon={<AddIcon />}
          className={classes.addButton}
          onClick={() => onSelectRule({ renamePayeeTo })} // create new rule using renamePayeeTo
        >
          new rename rule
        </QButton>
      </Box>
    </Box>
  );
};
SelectRule.propTypes = {
  rules: PropTypes.object,
  renamePayeeTo: PropTypes.string,
  onSelectRule: PropTypes.func,
};

const RenameRuleEditDialog = React.memo((props) => {
  const { renameRule, transaction, onClose, dialogId, open, applyRule, selectRuleFrom, ...otherProps } = props;
  const dispatch = useDispatch();

  const [viewExistingTransactions, setViewExistingTransactions] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [selectedRule, setSelectedRule] = useState(false);

  const focusedRule = selectedRule || renameRule;

  const onCloseProxy = useCallback((_event, reason) => {
    if (reason === 'submit' && !focusedRule?.id) { // creating a new rule, intercept close and instead show loading
      setShowLoading(true);
      return;
    }
    if (reason === 'create-complete' || reason === 'create-error') {  // handle done creating rule
      onClose?.('submit');  // pass onClose upwards finishing submission
      if (open === undefined && dialogId) { // uncontrolled version of dialog
        dispatch(removeDialog(dialogId));
      }
      return;
    }
    onClose?.(reason);
    if (open === undefined && dialogId) { // uncontrolled version of dialog
      dispatch(removeDialog(dialogId));
    }
  }, [dispatch, dialogId, onClose, open, focusedRule]);

  const handleBack = () => {
    if (viewExistingTransactions) {
      setViewExistingTransactions(false);
    } else if (selectRuleFrom && selectedRule) {
      setSelectedRule(false);
    }
  };

  let dialogTitle = focusedRule?.id ? 'Edit payee rule' : 'New rename rule';
  if (viewExistingTransactions) dialogTitle = 'Existing transactions';
  if (showLoading) dialogTitle = null;

  let dialogContent;
  if (selectRuleFrom && !selectedRule) {
    dialogTitle = 'Payee rename rules';
    dialogContent = (
      <SelectRule
        rules={selectRuleFrom}
        renamePayeeTo={selectRuleFrom?.first()?.renamePayeeTo}
        onSelectRule={setSelectedRule}
      />
    );
  } else {
    dialogContent = (
      <RenameRuleDialogForm
        renameRule={focusedRule}
        transaction={transaction}
        onClose={onCloseProxy}
        applyRule={applyRule}
        setViewExistingTransactions={setViewExistingTransactions}
        viewExistingTransactions={viewExistingTransactions}
      />
    );
  }

  return (
    <StdDialog
      title={dialogTitle}
      dialogId={dialogId}
      open={open}
      onClose={onCloseProxy}
      disableBackdropClick
      disableEscapeKeyDown
      showBackButton={(viewExistingTransactions && !showLoading) || (selectRuleFrom && selectedRule)}
      showCloseButton={!showLoading}
      onBack={handleBack}
      sharedcomponentid="DIALOG_TYPE_RENAME_RULE_EDIT"
      fullWidth
      maxWidth="md"
      {...otherProps}
    >
      {
        showLoading ?
          <Box padding={5} display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h5">
              Creating rename rule
            </Typography>
            <Box marginTop={3} marginBottom={5}>
              <LoadingView size={100} />
            </Box>
          </Box>
          :
          dialogContent
      }
    </StdDialog>
  );
});

RenameRuleEditDialog.propTypes = {
  renameRule: PropTypes.object,
  transaction: PropTypes.object,
  onClose: PropTypes.func,
  applyRule: PropTypes.bool,
  open: PropTypes.bool, // set manually for controlled dialog
  dialogId: PropTypes.string, // set automatically for uncontrolled dialog
  selectRuleFrom: PropTypes.arrayOf(PropTypes.object),  // given a list of rename rules to select editing from
};

export default RenameRuleEditDialog;
