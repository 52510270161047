import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { authSelectors } from '@quicken-com/react.flux.auth';

import { bugsnagIdentify } from 'utils/core/bugsnag';

const Bugsnag = () => {
  const authSession = useSelector(authSelectors.getAuthSession);

  useEffect(() => {
    if (authSession.qcsId) {
      bugsnagIdentify(authSession.qcsId, authSession.datasetId);
    }
  }, [authSession.qcsId, authSession.datasetId]);

  return null;
};

export default Bugsnag;


