// flow
import { createSelector } from 'reselect';

import { datasetsSelectors } from '@quicken-com/react.flux.datasets';
import { authSelectors } from '@quicken-com/react.flux.auth';

import { SUBSCRIPTIONS_REDUCER_KEY } from './subscriptionsReducer';

export const getStore = (state) => state[SUBSCRIPTIONS_REDUCER_KEY];

export const getIsLoading = (state) => getStore(state).isLoading;
export const getLoadPending = (state) => getStore(state).loadPending;
export const getError = (state) => getStore(state).error;
export const getSubscriptions = (state) => getStore(state).resourcesById;
export const getResourcesById = (state) => getStore(state).resourcesById;

export const getActiveSubscription = createSelector(
  getSubscriptions,
  datasetsSelectors.getDatasetsById,
  authSelectors.getDatasetId,
  (subscriptionsById, datasetsById, datasetId) => {

    if (subscriptionsById.size > 0 && datasetsById.size > 0 && datasetId) {
      const dataset = datasetsById.get(datasetId);
      if (dataset) {
        return subscriptionsById
          .filter((subscription) => subscription.supportedDatasetPlatforms.contains(dataset.platform))
          .sort((subscription1, subscription2) => subscription1.expireOn.localeCompare(subscription2.expireOn))
          .last();
      }
    }
    return null;
  }
);
