import { List as ImmutableList } from 'immutable';

import { transactionsUtils } from '@quicken-com/react.flux.transactions';

import { noNaN } from 'utils/utils';
import { getTxnStateInfo, RefundState, getRefundState, isBankOwnedPending } from 'data/transactions/utils';
import { DateTime } from 'luxon';
import { memoizeWithKey, memoizeWithKeyRetrieveCache } from 'utils/memoizeWithKey';
import { getOrCreateStringFromObject } from 'utils/objectToKeyUtils';

/*
 CALC RUNNING BALANCES
 */
export function calcRunningBalances(txns, sortBy, sortOrder, endingBalance, isExcludePendingTxn) {

  if (sortBy !== 'date') {
    return txns;
  }

  let txnsWithBalances = [];

  let balance = endingBalance;

  const reverse = (sortOrder === 'ascending');
  const parseList = reverse ? txns.reverse() : txns;

  parseList.forEach((txn) => {
    if (isExcludePendingTxn && isBankOwnedPending(txn)) {
      txnsWithBalances.push(txn);
    } else {
      txnsWithBalances.push(txn.set('balance', balance));
      balance -= noNaN(txn.amount);
    }
  });

  if (reverse) {
    txnsWithBalances = txnsWithBalances.reverse();
  }

  return ImmutableList(txnsWithBalances);
}

export function padBounds(balances) {
  const firstMin = Math.min(...balances) || 0;
  const firstMax = Math.max(...balances) || 100;
  const deviation = (firstMax - firstMin) || Math.abs(firstMax) / 4 || 100;

  let minBalance = firstMin;
  let maxBalance = firstMax;

  if (minBalance > 0) {
    minBalance -= (minBalance * 0.4);
  } else {
    minBalance -= (deviation * 0.2);
  }

  if (maxBalance > 0) {
    maxBalance += deviation * 0.3;
  } else {
    maxBalance -= (maxBalance * 0.4);
  }

  const minLimit = firstMin - deviation;
  if (minBalance < minLimit) {
    minBalance = minLimit;
  }
  const maxLimit = firstMax + deviation;
  if (maxBalance > maxLimit) {
    maxBalance = maxLimit;
  }

  return {
    min: minBalance,
    max: maxBalance,
    high: firstMax,
    low: firstMin,
  };
}

/*
  Get Future Transactions helper function
 */
export const getFutureTxnsForAccountIds = (props) => {

  const {
    accountIdsList,
    accountsById,
    transactionsByAccountId,
    transactionsByAccountIdsSelectors,
    featureFlags,
  } = props;


  const key = getOrCreateStringFromObject(
    'futureTxnsForAccountIds',
    {
      accountIdsList,
      accountsById,
      featureFlags,
      transactionsByAccountId,
      transactionsByAccountIdsSelectors,
    },
    5,
    memoizeWithKeyRetrieveCache,   // fn used for garbage collection for unusued object strings
  );

  return memoizeWithKey(
    'futureTxnsForAccountIds',
    () => getFutureTxnsForAccountIdsHelper(props),
    key,
    5
  );
};

export const getFutureTxnsForAccountIdsHelper = (props) => {

  const {
    accountIdsList,
    accountsById,
    transactionsByAccountId,
    transactionsByAccountIdsSelectors,
    featureFlags,
  } = props;

  let txnList = ImmutableList();
  let filteredTransactions;
  const filter = '';
  const sortBy = 'date';
  const sortOrder = 'ascending';

  accountIdsList.forEach((accountId) => {

    const acct = accountsById.get(accountId);

    if (acct && !(featureFlags.get('hideLoanTransactions') && acct.type === 'LOAN')) {

      const transactionsForAccountIdSelector = transactionsByAccountIdsSelectors.get(accountId);

      const transactionsForAccountId =
        transactionsForAccountIdSelector(transactionsByAccountId, {
          sortBy,
          sortOrder,
          filter,
        });
      filteredTransactions = transactionsForAccountId.filter((txn) => filterFutureOrDueOverdue(txn));
      txnList = txnList.concat(filteredTransactions);
    }
  });
  return txnList;
};


/*
 FILTER FUTURE OR DUE OVERDUE
 */
export function filterFutureOrDueOverdue(txn) {
  const info = getTxnStateInfo(txn);
  if (info.status === 'DUE' || info.status === 'OVERDUE') {
    return true;
  }
  const refundState = getRefundState(txn);
  if (refundState === RefundState.EXPECTED || refundState === RefundState.OVERDUE) {
    return true;
  }
  return DateTime.fromISO(txn.postedOn).startOf('day') > DateTime.local().startOf('day');
}


export const isRecentSpend = (txnList, categoriesById) =>
  txnList.filter((txn) => {
    if (!transactionsUtils.isSplitTxn(txn) && txn.coa) {  // ensure there is a coa regardless
      // returns if is matched scheduled txn or expense
      let cat = categoriesById.get(txn.coa.id);
      const coaType = txn.coa.type;
      if (!cat) cat = 'UNCATEGORIZED';
      if (txn.isExcludedFromReports) {
        return false;
      }
      if ((cat.type === 'EXPENSE' || 'UNCATEGORIZED') && (cat.type !== 'INCOME') && coaType !== 'ACCOUNT' && coaType !== 'BALANCE_ADJUSTMENT') {
        return !(txn.source && txn.source === 'SCHEDULED_TRANSACTION_PENDING');
      }
    } else {
      // Check twice in case is split and scheduled, who knows weirder things have happened
      return !(txn.source && txn.source === 'SCHEDULED_TRANSACTION_PENDING');
    }
    return false;
  });
