import { Map, fromJS, isImmutable } from 'immutable';
import lodash from 'lodash';

/*
 * PREFERENCE REDUCERS -
 *
 * These routines are used by the reducer
 * It is best to access these via ACTIONS (store.dispatch(action)), and route through the reducer
 * in order to enjoy full time-auditing of activities.
 *
 * You can access these via the preference model in preferenceModel.js which will fire off actions for you
 *
 */

import { getLogger } from '@quicken-com/react.utils.core';
const log = getLogger('data/preferences/reducer.js');

/*
 * createReplacePreferenceList
 *
 * state: the current preference list datastore
 * preferences: the preference object from QCS
 * path: the section/group of the preference object
 *
 * SECTION: one of 'shared', 'client', 'qcs'
 * GROUP: shared, one of 'user', 'dataset;
 *        client, one of 'user', 'dataset', 'global'
 *        qcs: only top level
 *
 */
export function createReplacePreferenceList(state, data) {

  const { resources, path } = data;

  let newState = state;

  log.debug('data for create/replace is ', resources, path);

  if (resources && path) {

    let subState = state.get('preferences');
    subState = subState[path.section];
    subState = path.group ? subState[path.group] : subState;
    newState = mergeDeepReplaceArrays(new Map(subState), resources);
    newState = state.mergeIn(['preferences', path.section, path.group], newState);

    newState = newState.setIn(['loadPending', path.section, path.group], false);
    log.debug('PREFS END AS ', newState.toJS());
  }
  return newState;  // no change
}

/*
 * setPreference
 *
 * state: the current preference list datastore
 * preference: the single preference objects to add to the preference list
 *
 */
export function setPreference(state, successObject) {
  return createReplacePreferenceList(state, { path: successObject.data, resources: successObject.response.data });
}

export function removePreference(state) {
  return state;
}

// mergeDeep that replaces arrays with instead of merging
// https://github.com/facebook/immutable-js/issues/762
/* eslint-disable no-param-reassign */
function mergeDeepReplaceArrays(state, updatedData) {

  const replaceArrays = (data, basePath) => {
    basePath = basePath || [];

    lodash.forIn(data, (_v, k) => {

      // convert an immutable list into an array
      const v = (isImmutable(_v)) ? _v.toJS() : _v;

      const path = basePath.concat([k]);

      if (Array.isArray(v)) {
        if (newState.hasIn(path)) {
          newState = newState.setIn(path, fromJS(v));
          // delete data[k];  no longer do this, we need to preserve the original data and not alter it
        }
      } else if (lodash.isObject(v)) {
        replaceArrays(v, path);
      }
    });
  };

  // this gets hoisted, and is in scope for function above
  // newState will represent the merged state object
  let newState = state.mergeDeep(updatedData);
  // state now is in error as arrays were merged, so now we fix that by replacing
  // any arrays directly in newState
  replaceArrays(updatedData);
  // return newState to be used to replace the actual redux state
  return newState; // state.mergeDeep(updatedData);

}
