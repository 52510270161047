import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import compose from 'utils/compose';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';

import store from '@quicken-com/react.utils.redux-store';
import { getLogger } from '@quicken-com/react.utils.core';
import { datasetsActions, datasetsSelectors } from '@quicken-com/react.flux.datasets';
import { authActions, authTypes, authSelectors } from '@quicken-com/react.flux.auth';
import { profileSelectors } from '@quicken-com/react.flux.profile';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';
import { accountsSelectors } from '@quicken-com/react.flux.accounts';

import withStyles from '@mui/styles/withStyles';
import GettingStartedWebFirst from 'containers/SetupPage/GettingStartedWebFirst';
import { hasWebFirstEntitlements } from 'data/entitlements/entitlementsSelectors';

import SetupPageCompanion from './SetupPageCompanion';
import SetupPageWebFirst from './SetupPageWebFirst';

const log = getLogger('containers/SetupPage');


const styles = () => ({
  base: {
    fontWeight: 200,
    padding: 3,
  },
  title: {
    extend: 'base',
    fontSize: '25px',
    fontWeight: 400,
  },
  subTitle: {
    fontSize: '20px',
    extend: 'base',
  },
  bodyText: {
    fontWeight: 200,
    fontSize: '14px',
  },
  link: {
    cursor: 'pointer',
  },
});

const desktopClients = 'quicken_windows, quicken_mac';
const cloudClients = 'quicken_webapp';

class SetupPage extends PureComponent { // eslint-disable-line react/prefer-stateless-function

  static propTypes = {
    profile: PropTypes.object,
    classes: PropTypes.object,
    authSession: PropTypes.object,
    datasetId: PropTypes.string,
    datasetsById: PropTypes.object,
    isLoadPending: PropTypes.bool,
    dispatchCreateDatasetAction: PropTypes.func,
    dispatchAuthSelectDatasetAction: PropTypes.func,
    redirect: PropTypes.func,
    webFirstEnabled: PropTypes.bool,
    accountsLoadPending: PropTypes.bool,
    accountsById: PropTypes.object,
    disableNewUserWebFirst: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      // showIntro: true,
      newDatasetClientId: null,
    };
  }

  passIntro = () => {
    // this.setState({ showIntro: false })
  }

  createNewDataset = (type) => {

    if (!type || desktopClients.indexOf(type) === -1) {
      log.log('Create Dataset Failed, you must pass a type of "quicken_windows" or "quicken_mac"');
    }
    let newDataset = datasetsSelectors.makeNewDataset(store.getState(), this.props.profile);
    newDataset = newDataset.set('platform', type === 'quicken_windows' ? 'QWIN_MWF' : 'QMAC_MWF');

    this.props.dispatchCreateDatasetAction(newDataset);
    this.setState({ newDatasetClientId: newDataset.clientId });
  };

  render() {

    const { datasetId, datasetsById, isLoadPending, webFirstEnabled, accountsLoadPending, accountsById } = this.props;
    const { newDatasetClientId } = this.state;

    // 1. Still loading, return nothing
    if (isLoadPending) {
      return <div />;
    }

    // 2. new dataset was created? then open it and navigate to getting started
    //
    if (newDatasetClientId) {
      const newDataSet = datasetsById.find((dataset) => newDatasetClientId === dataset.clientId);
      if (newDataSet && newDataSet.id) {
        this.setState({ newDatasetClientId: undefined });
        this.props.dispatchAuthSelectDatasetAction(authTypes.AuthSelectDatasetPayload({ dataset: newDataSet, reload: false, location: '/getting-started-webfirst' }));
      }
      return <div />;
    }

    const datasetObject = datasetsById && datasetsById.get(datasetId);
    const createdByClientId = (datasetObject && datasetObject.createdByClientId) || 'unknown';
    // This indicates the dataset has been created by Windows or Mac, we cannot ascertain if it is unused,
    // or just unsynced.  In this case they get the "companion" setup page to either instruct them how to
    // sync their desktop accounts, or they can start from scratch with a web-created (web first) dataset
    //

    const useCompanion = (desktopClients.indexOf(createdByClientId) !== -1) 
    || !webFirstEnabled || datasetObject?.disableRemoteAccess || this.props.disableNewUserWebFirst;

    if (!datasetObject?.disableRemoteAccess) {
      // 3. if dataset and it is created by cloud, then go to webfirst setup
      if (cloudClients.indexOf(createdByClientId) !== -1) {
        // window.location.replace('/getting-started-webfirst');
        return <GettingStartedWebFirst />;
      }
      if (!isLoadPending && !useCompanion && datasetsById.size === 0 && webFirstEnabled) {
        this.props.redirect('platformSelector');
      } else if (!accountsLoadPending && accountsById?.size >= 1) {
        this.props.redirect('');
      }
    }

    return (
      <>
        {useCompanion &&  // dataset is from the desktop
          <SetupPageCompanion
            {...this.props}
            defaultTabIsWindows={createdByClientId === 'quicken_windows'}
            onCreateDataset={this.createNewDataset}
          />}
        {!useCompanion &&  // no dataset
        <SetupPageWebFirst
          {...this.props}
          defaultTabIsWindows={createdByClientId === 'quicken_windows'}
          onCreateDataset={this.createNewDataset}
        />}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: profileSelectors.getProfile(state),
    authSession: authSelectors.getAuthSession(state),
    datasetId: authSelectors.getDatasetId(state),
    datasetsById: datasetsSelectors.getDatasetsById(state),
    isLoadPending: datasetsSelectors.getLoadPending(state) || featureFlagsSelectors.getLoadPending(state),
    webFirstEnabled: hasWebFirstEntitlements(state),
    accountsById: accountsSelectors.getAccountsById(state),
    accountsLoadPending: accountsSelectors.getLoadPending(state),
    disableNewUserWebFirst: featureFlagsSelectors.getFeatureFlags(state).get('disableNewUserWebFirst'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchCreateDatasetAction: (dataset) => dispatch(datasetsActions.createDataset(dataset)),
    dispatchAuthSelectDatasetAction: (data) => dispatch(authActions.authSelectDataset(data)),
    redirect: (location) => dispatch(replace(location)),
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(SetupPage));
