
// BASE
import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

// CUSTOM COMPONENTS

// ACTIONS, SELECTORS, UTILS

// MUI COMPONENTS
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Popover from '@mui/material/Popover';

// PATH RELATIVE IMPORTS

// STYLES DEF AND HOOK
const styles = (theme) => ({
  root: {
    boxShadow: theme.shadows[5],
  },
  rootPaper: {
    borderRadius: theme.shape.padding[2],
  },
  group: {
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.shape.padding[2],
    marginBottom: 8,
  },
  contentRow: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    marginLeft: 0,
  },
});

const useStyles = makeStyles(styles);

/*
 * IgnoreSelectionMenu Component ***********************************
 */

// PROP TYPES
const propTypes = {
  isExcludedFromReports: PropTypes.bool,
  isExcludedFromF2S: PropTypes.bool,
  onChange: PropTypes.func,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
};

// HELPER FUNCTIONS

const IgnoreSelectionMenu = (props) => {

  // PROPS
  const { isExcludedFromReports, isExcludedFromF2S, onChange, anchorEl, onClose } = props;

  // STATE
  const [excludeReports, setExcludeReports] = useState(false);
  const [excludeF2S, setExcludeF2S] = useState(false);

  // EFFECTS
  useEffect(() => {
    setExcludeReports(isExcludedFromReports);
    setExcludeF2S(isExcludedFromF2S);
  }, [isExcludedFromReports, isExcludedFromF2S]);

  // STYLES
  const classes = useStyles(props);

  // INTERNAL FUNCTIONS

  const onReportsClick = () => {
    setTimeout(() =>
      onChange?.({ target: { value: { isExcludedFromReports: !excludeReports, isExcludedFromF2S: excludeF2S } } }), 10);
    setExcludeReports(!excludeReports);
  };
  const onF2SClick = () => {
    setTimeout(() =>
      onChange?.({ target: { value: { isExcludedFromReports: excludeReports, isExcludedFromF2S: !excludeF2S } } }), 10);
    setExcludeF2S(!excludeF2S);
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={onClose}
      classes={{ paper: classes.rootPaper }}
      className={classes.root}
      open
    >
      <div className={classes.group}>
        <FormControlLabel
          className={classes.contentRow}
          control={<Switch />}
          label="Ignore from Reports"
          labelPlacement="start"
          onChange={onReportsClick}
          checked={excludeReports}
        />

        <FormControlLabel
          className={classes.contentRow}
          control={<Switch />}
          label="Ignore from Spending Plan"
          labelPlacement="start"
          onChange={onF2SClick}
          checked={excludeF2S}
        />
      </div>
    </Popover>
  );
};

IgnoreSelectionMenu.propTypes = propTypes;
export default IgnoreSelectionMenu;


