import React, { useEffect, useRef } from 'react';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { useTheme } from '@mui/material/styles';

import compose from 'utils/compose';

import styles from './styles';

const CHART_ID = 'progressChart';
const ProgressChart = ({ chartId, data, classes }) => {

  const chartRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {

    if (chartRef.current) {
      const chartIdentifier = chartId || CHART_ID;
      chartRef.current = am4core.create(chartIdentifier, am4charts.XYChart);
      chartRef.current.data = data;
      chartRef.current.responsive.enabled = true;
      chartRef.current.paddingLeft = 0;
      chartRef.current.paddingRight = 0;

      const yAxis = chartRef.current.yAxes.push(new am4charts.CategoryAxis());
      yAxis.dataFields.category = 'label';
      yAxis.renderer.grid.template.disabled = true;
      yAxis.renderer.labels.template.disabled = true;

      const xAxis = chartRef.current.xAxes.push(new am4charts.ValueAxis());
      xAxis.renderer.grid.template.disabled = true;
      xAxis.renderer.labels.template.disabled = true;
      xAxis.min = 0;
      xAxis.max = data[data.length - 1].to;
      xAxis.strictMinMax = true; 

      const series = chartRef.current.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = 'to';
      series.dataFields.openValueX = 'from';
      series.dataFields.categoryY = 'label';
      series.columns.template.propertyFields.fill = 'fill';
      series.columns.template.strokeOpacity = 0;
      series.columns.template.height = am4core.percent(100);

      const valueLabel = series.bullets.push(new am4charts.LabelBullet());
      valueLabel.label.text = '{graphValue}';
      valueLabel.label.fontSize = 12;
      valueLabel.label.fill = theme.components.progressChart.textLabel;
      valueLabel.label.truncate = false;
      valueLabel.label.hideOversized = false;
      valueLabel.locationX = 0.5;
    }

    return () => {
      chartRef.current && chartRef.current.dispose();
    };

  }, [chartId, data, theme]);

  return (
    <div className={classes.progressChartWrapper}>
      <div 
        id={chartId || CHART_ID} 
        ref={chartRef}
        className={classes.progressChart}
      />
    </div>
  );
};

ProgressChart.propTypes = {
  chartId: PropTypes.string,
  data: PropTypes.array,
  classes: PropTypes.object,
};

export default compose( 
  withStyles(styles, { withTheme: true })
)(ProgressChart);
