/* eslint react/prop-types: 0 */

import React from 'react';
import compose from 'utils/compose';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { List } from 'immutable';

import { getLogger } from '@quicken-com/react.utils.core';
import { datasetsUtils } from '@quicken-com/react.flux.datasets';
import { accountsSelectors } from '@quicken-com/react.flux.accounts';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import QTip from 'components/QuickenControls/QTip';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';
import { getClipboardForKey } from 'data/clipboard/selectors';

import * as transactionListSelectors from 'data/transactionList/selectors';

import QCheckbox from 'components/QCheckbox';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import OutlinedFlag from '@mui/icons-material/OutlinedFlag';
import VisibilityOnIcon from '@mui/icons-material/Visibility';
import TxPrefMenu from 'components/TransactionRegister/TxPrefMenu';
import RegPrefsModal from 'containers/Transactions/RegPrefsModal';
import QPreferences from 'components/QPreferences';
import ReconcilePanel from 'components/ReconcilePanel';
import TransactionCalendar from 'components/TransactionCalendar';

import { isAcme } from 'isAcme';

import { fieldIsMoveable } from '../helpers';

import RegControlBar from '../RegControlBar';
import RegMEditBar from '../RegMEditBar';

import * as txConfig from '../transactionsConfig';


import {
  RegHeader, HdrListItem, ColumnField, styles,
} from './styles';

const log = getLogger('components/RegisterHeader/index.js');

// ==============================================================
//
// The control bar at the top of the Transaction list (register)
//
// props:
//
//
export class RegisterHeader extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showRegPrefs: false,
      showReconcile: false,
      showCalendar: false,
      draggingOver: false,
      headerFields: props.headerFields,
      // showHeadings: true,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.sizeChanged !== this.props.sizeChanged) {
      this.forceUpdate();
    }
    if (nextProps.headerFields !== this.state.headerFields) {
      this.setState({ headerFields: nextProps.headerFields });
    }
  }


  /*
    DRAG AND DROP FUNCTIONS
   */
  onDragStart = (e, field) => {
    e.dataTransfer.setData('text', field);
    this.fieldToDrop = field;
    this.origList = this.state.headerFields;
  };
  onDragOver = (e, field) => {
    this.draggingOver = field;
    if (this.fieldToDrop && (this.fieldToDrop !== field)) {
      this.alterHeader(this.fieldToDrop, field);
    }
    e.preventDefault();  // not fully sure what this prevents, but without this, the onDrop is never invoked
  };
  onDrop = (e) => {
    e.preventDefault();
    if (this.fieldToDrop) {
      this.saveCurrentHeader();
    }
    this.fieldToDrop = null;
  };
  onDragEnd = () => {
    if (this.fieldToDrop) {
      this.fieldToDrop = null;
      this.draggingOver = null;
      this.setState({ headerFields: this.origList });
    }
  };

  alterHeader = (fromField, toField) => {

    const workArray = this.state.headerFields.toArray();

    const fromIndex = workArray.indexOf(fromField);
    const initialToIndex = workArray.indexOf(toField);
    const adjust = fromIndex <= initialToIndex ? 1 : 0;

    const temp = workArray[fromIndex];
    workArray.splice(fromIndex, 1); // delete fromField from list
    const toIndex = workArray.indexOf(toField);
    workArray.splice(toIndex + adjust, 0, temp);  // insert fromField back to list either before or after the to item

    if (JSON.stringify(this.state.headerFields.toArray()) !== JSON.stringify(workArray)) {
      this.setState({ headerFields: List(workArray) });
    }
  };

  saveCurrentHeader = () => {
    this.props.callbacks.alterHeader(this.state.headerFields);
  };

  getItemStyle = (item, isDragging, draggableStyle) => ({
    ...item.style,
    opacity: isDragging ? 0.5 : 1.0,
    overflow: 'visible',
    ...draggableStyle,
  });


  getListStyle = () => ({
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
  });

  maybeCancel = () => {
    this.props.callbacks.maybeSaveTxnBeingEdited(() => {
      this.props.callbacks.cancelEdit();
    });
  };

  handleSettingsChange = (value) => {

    switch (value) {
      case 'compact':
      case 'comfortable':
      case 'normal':
        this.props.setDatasetPreference({ transactionRegister: { registerComfort: value } });
        break;
      case 'preferences':
        this.setState({ showRegPrefs: true });
        break;
      case 'reconcile':
        if (this.props.reconcileAccount) {
          this.setState({ showReconcile: true });
        }
        break;
      case 'calendar':
        if (this.props.transactionCalendar) {
          this.setState({ showCalendar: true });
        }
        break;
      default:
        break;
    }
  };

  sumTxns = () => {

    let total = 0;
    this.props.acctTxns.forEach((item) => {
      total += item.amount;
    });
    return total;
  };

  closeRegPrefs = () => {
    this.setState({ showRegPrefs: false });
  };

  defaultHeaderItem = (field, sortClass, props) => (
    <HdrListItem
      id={`${field}-header`}
      privateKey={`regHdr:${field}`}
      fieldName={field}
      onClick={(e) => this.props.callbacks?.headerClick?.(e, field)}
      {...props}
    >
      <ColumnField>
        {txConfig.fieldData[field].label}
      </ColumnField>
      {this.props.sortBy === field &&
      <ArrowUpwardIcon
        className={this.props.classes[sortClass]}
        viewBox="-1 -1 27 27"
      />}
    </HdrListItem>
  );

  renderFields = (options) => {

    const { callbacks, theme } = this.props;
    const { sortClass, antiSortClass, showBalance } = options;

    const retRow = [];

    this.state.headerFields.forEach((field) => {
      let privateClassName;

      switch (field) {
        case 'action':
          privateClassName = classNames(this.props.classes.regItem, 'click', 'protectWidth');
          retRow.push(this.defaultHeaderItem(field, sortClass, { privateClassName }));
          break;
        case 'security':
          privateClassName = classNames(this.props.classes.regItem, 'click', 'protectWidth');
          retRow.push(this.defaultHeaderItem(field, sortClass, { privateClassName }));
          break;
        case 'quantity':
          privateClassName = classNames(this.props.classes.regItem, 'click', 'quantity', 'amount');
          retRow.push(this.defaultHeaderItem(field, sortClass, { privateClassName }));
          break;
        case 'price':
          privateClassName = classNames(this.props.classes.regItem, 'click', 'protectWidth', 'amount');
          retRow.push(this.defaultHeaderItem(field, sortClass, { privateClassName }));
          break;
        //--------------------------------------------
        // MATCH FIELD
        //--------------------------------------------
        case 'match':
          retRow.push(
            <HdrListItem
              id="match-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={this.props.classes.iconColumn}
            >
            </HdrListItem>
          );
          break;

        //--------------------------------------------
        // REVIEWED FIELD
        //--------------------------------------------
        case 'reviewed': {
          // const filterState = this.props.uiState.reviewedFilterState;
          // const CheckCircleComponent = filterState !== 'none' ? CheckCircleIcon : CheckCircleOutline;
          retRow.push(
            <HdrListItem
              id="reviewed-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classNames(this.props.classes.iconButtonColumn)}
              onClick={(e) => callbacks.headerClick(e, 'reviewed')}
            >
            </HdrListItem>
          );
          break;
        }
        //--------------------------------------------
        // FLAG FIELD
        //--------------------------------------------
        case 'userFlag':
          retRow.push(
            <HdrListItem
              id="flag-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={this.props.classes.iconButtonColumn}
            >
              <OutlinedFlag
                style={{ fontSize: 20 }}
              />
            </HdrListItem>
          );
          break;

        //--------------------------------------------
        // IGNORED FIELD
        //--------------------------------------------
        case 'ignored':
          retRow.push(
            <HdrListItem
              id="ignored-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={this.props.classes.iconButtonColumn}
            >
              <VisibilityOnIcon
                style={{ fontSize: 20 }}
              />
            </HdrListItem>
          );
          break;
        //--------------------------------------------
        // SELECT FIELD
        //--------------------------------------------
        case 'select':
          retRow.push(
            <HdrListItem
              id="regHdrSelectAll"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={this.props.classes.iconButtonColumn}
            >
              <QTip
                title="Select / Unselect all visible transactions"
              >
                <QCheckbox
                  size={theme.components.register.checkboxSize}
                  color="primary"
                  inputProps={{ 'aria-label': 'Search Transactions' }}
                  className={this.props.classes.checkBox}
                  onChange={(e, v) => {
                    callbacks.onCheckAll(v);
                  }}
                  checked={this.props.uiState.allSelected}
                />
              </QTip>
            </HdrListItem>
          );
          break;
        //--------------------------------------------
        // ACCOUNT NAME FIELD
        //--------------------------------------------
        case 'account': {
          const hide = this.props.showAccountColors || this.props.accountIds.size === 1;
          const hideClass = hide ? this.props.classes.nop : '';
          const sizeClass = this.props.showAccountColors ? '' : 'protectWidth';

          retRow.push(
            <HdrListItem
              id="account-name-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={
                classNames(
                  this.props.classes.regItem,
                  'click',
                  this.props.classes.accountFieldName,
                  sizeClass,
                  hideClass
                )
              }
              style={{ cursor: 'default' }}
            >
              <ColumnField>
                {txConfig.fieldData[field].label}
              </ColumnField>
            </HdrListItem>
          );
          break;
        }

        //--------------------------------------------
        // ACCOUNT COLOR FIELD
        //--------------------------------------------
        case 'accountColor': {

          const hideClass = !this.props.showAccountColors ? this.props.classes.nop : '';
          retRow.push(
            <HdrListItem
              id="account-color-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classNames(this.props.classes.accountFieldColor, hideClass)}
            >
              <div
                className={this.props.classes.accountFieldColor}
              >
              </div>
            </HdrListItem>
          );
          break;
        }

        //--------------------------------------------
        // POSTED_ON FIELD
        //--------------------------------------------
        case 'postedOn':
          retRow.push(
            <HdrListItem
              id="posted-on-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classNames(this.props.classes.regItem, 'click', 'protectWidthDate')}
              onClick={(e) => callbacks.headerClick(e, 'date')}
            >
              <ColumnField>
                {txConfig.fieldData[field].label}
              </ColumnField>
              {this.props.sortBy === 'date' &&
                <ArrowUpwardIcon
                  className={this.props.classes[sortClass]}
                  viewBox="0 0 29 29"
                />}
            </HdrListItem>
          );
          break;
        //--------------------------------------------
        // CHECKNUM FIELD
        //--------------------------------------------
        case 'check':
          retRow.push(
            <HdrListItem
              id="checknum-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classNames(this.props.classes.regItem)}
              onClick={(e) => callbacks.headerClick(e, 'check')}
            >
              <ColumnField>
                {txConfig.fieldData[field].label}
              </ColumnField>
              {this.props.sortBy === 'check' &&
                <ArrowUpwardIcon
                  className={this.props.classes[sortClass]}
                  viewBox="0 0 29 29"
                />}
            </HdrListItem>
          );
          break;
        //--------------------------------------------
        // TAGS FIELD
        //--------------------------------------------
        case 'tags':
          retRow.push(
            <HdrListItem
              id="tags-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classNames(this.props.classes.regItem, 'flex2')}
            >
              <ColumnField>
                {txConfig.fieldData[field].label}
              </ColumnField>
            </HdrListItem>
          );
          break;
        //--------------------------------------------
        // PAYEE FIELD
        //--------------------------------------------
        case 'payee':
          retRow.push(
            <HdrListItem
              id="payee-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classNames(this.props.classes.regItem, 'flex2', 'click')}
              onClick={(e) => callbacks.headerClick(e, 'payee')}
            >
              <ColumnField>
                {txConfig.fieldData[field].label}
              </ColumnField>
              {this.props.sortBy === 'payee' &&
                <ArrowUpwardIcon
                  className={this.props.classes[sortClass]}
                  viewBox="-1 -1 27 27"
                />}
            </HdrListItem>
          );
          break;
        //--------------------------------------------
        // NOTES FIELD
        //--------------------------------------------
        case 'notes':
          retRow.push(
            <HdrListItem
              id="notes-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classNames(this.props.classes.regItem, 'flex2', 'click')}
            >
              <ColumnField>
                {txConfig.fieldData[field].label}
              </ColumnField>
            </HdrListItem>
          );
          break;
        //--------------------------------------------
        // CATEGORY FIELD
        //--------------------------------------------
        case 'category':
          retRow.push(
            <HdrListItem
              id="category-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classNames(this.props.classes.regItem, 'flex2 click')}
              onClick={(e) => callbacks.headerClick(e, 'category')}
            >
              <ColumnField>
                {txConfig.fieldData[field].label}
              </ColumnField>
              {this.props.sortBy === 'category' &&
                <ArrowUpwardIcon
                  className={this.props.classes[sortClass]}
                  viewBox="-1 -1 27 27"
                />}
            </HdrListItem>
          );
          break;
        //--------------------------------------------
        // SPLIT FIELD
        //--------------------------------------------
        case 'split':
          retRow.push(
            <HdrListItem
              id="split-header"
              privateKey="regHdr:split"
              privateClassName={this.props.classes.iconColumn}
            >
              { /*
              <MUISplitIcon
                className={this.props.classes.regIcon}
                viewBox="-2 -0 27 27"
              />
              */}
            </HdrListItem>
          );
          break;

        //--------------------------------------------
        // STATE FIELD
        //--------------------------------------------
        case 'state':
          retRow.push(
            <HdrListItem
              id="state-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classNames(this.props.classes.statusColumn, 'click')}
              onClick={(e) => callbacks.headerClick(e, 'status')}
            >
              <ColumnField>
                {txConfig.fieldData[field].label}
              </ColumnField>
              {this.props.sortBy === 'status' &&
                <ArrowUpwardIcon
                  className={this.props.classes[sortClass]}
                  viewBox="-1 -1 27 27"
                />}
            </HdrListItem>
          );
          break;
        //--------------------------------------------
        // ATTACHMENT FIELD
        //--------------------------------------------
        case 'attachment':
          if (this.props.uiState.showAttachmentColumnInHeader) {
            retRow.push(
              <HdrListItem
                id="attachment-header"
                privateKey={`regHdr:${field}`}
                fieldName={`${field}`}
                privateClassName={this.props.classes.iconColumn}
              >
                {
                  <AttachFileIcon
                    className={this.props.classes.regIcon}
                    viewBox="-2 -4 30 30"
                  />
                }
              </HdrListItem>
            );
          } else {
            retRow.push(
              <HdrListItem
                privateKey={`regHdr:${field}`}
                fieldName={`${field}`}
                privateClassName={this.props.classes.iconButtonColumn}
              />
            );
          }
          break;
        //--------------------------------------------
        // EXPENSE FIELD
        //--------------------------------------------
        case 'expense':
          if (this.props.doubleAmounts) {
            retRow.push(
              <HdrListItem
                id="expense-header"
                privateKey={`regHdr:${field}`}
                fieldName={`${field}`}
                privateClassName={classNames(this.props.classes.regItem, 'amount', 'click', 'protectWidth')}
                onClick={(e) => callbacks.headerClick(e, 'expense')}
              >
                <ColumnField>
                  {txConfig.fieldData[field].label}
                </ColumnField>
                {this.props.sortBy === 'amount' &&
                  <ArrowUpwardIcon
                    className={this.props.classes[sortClass]}
                    viewBox="-1 -1 27 27"
                  />}
              </HdrListItem>
            );
          } else {
            retRow.push(
              <HdrListItem
                privateClassName={this.props.classes.nop}
                fieldName={`${field}`}
                privateKey={`regHdr:${field}`}
              />
            );
          }
          break;
        //--------------------------------------------
        // INCOME FIELD
        //--------------------------------------------
        case 'income':
          if (this.props.doubleAmounts) {
            retRow.push(
              <HdrListItem
                id="income-header"
                privateKey={`regHdr:${field}`}
                fieldName={`${field}`}
                privateClassName={classNames(this.props.classes.regItem, 'amount', 'click', 'protectWidth')}
                onClick={(e) => callbacks.headerClick(e, 'income')}
              >
                <ColumnField>
                  {txConfig.fieldData[field].label}
                </ColumnField>
                {this.props.sortBy === 'amount' &&
                  <ArrowUpwardIcon
                    className={this.props.classes[antiSortClass]}
                    viewBox="-1 -1 27 27"
                  />}
              </HdrListItem>
            );
          } else {
            retRow.push(
              <HdrListItem
                privateKey={`regHdr:${field}`}
                fieldName={`${field}`}
                privateClassName={this.props.classes.nop}
              />
            );
          }
          break;
        //--------------------------------------------
        // AMOUNT FIELD
        //--------------------------------------------
        case 'amount':
          if (!this.props.doubleAmounts) {
            retRow.push(
              <HdrListItem
                id="amount-header"
                privateKey={`regHdr:${field}`}
                fieldName={`${field}`}
                privateClassName={classNames(this.props.classes.regItem, 'amount', 'click', 'protectWidth')}
                onClick={(e) => callbacks.headerClick(e, 'amount')}
              >
                <ColumnField>
                  {txConfig.fieldData[field].label}
                </ColumnField>
                {this.props.sortBy === 'amount' &&
                  <ArrowUpwardIcon
                    className={this.props.classes[sortClass]}
                    viewBox="-1 -1 27 27"
                  />}
              </HdrListItem>
            );
          } else {
            retRow.push(
              <HdrListItem
                privateKey={`regHdr:${field}`}
                fieldName={`${field}`}
                privateClassName={this.props.classes.nop}
              />
            );
          }
          break;
        //--------------------------------------------
        // BALANCE
        //--------------------------------------------
        case 'balance':
          if (showBalance) {
            retRow.push(
              <HdrListItem
                id="balance-header"
                privateKey={`regHdr:${field}`}
                fieldName={`${field}`}
                privateClassName={classNames('amount', this.props.classes.regItem)}
              >
                <ColumnField>
                  {txConfig.fieldData[field].label}
                </ColumnField>
              </HdrListItem>
            );
          } else {
            retRow.push(
              <HdrListItem
                privateKey={`regHdr:${field}`}
                fieldName={`${field}`}
                privateClassName={this.props.classes.nop}
              />
            );
          }
          break;
        //--------------------------------------------
        // MENU FIELD
        //--------------------------------------------
        case 'menu': {

          const customItems = [];

          if (this.props.transactionCalendar) {
            customItems.push({
              label: 'Calendar...',
              value: 'calendar',
            });
          }

          if (this.props.accountIds.size === 1 && this.props.reconcileAccount) {
            customItems.push({
              label: 'Reconcile Account...',
              value: 'reconcile',
            });
          }

          // This actually should be getting the accountNode id for superRegisters, but we don't have access
          // to that. Since right now only CREDIT registers have their own regFields sort order, and since
          // the first account in this group will be of type credit, this actually works, but we need to do
          // a more siginficant refactoring
          //
          const firstAccount = this.props.accountsById?.get(this.props.accountIds?.first()) || this.props.accountsById?.first();
          const accountType = firstAccount?.type || 'bank';

          retRow.push(
            <HdrListItem
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={this.props.classes.iconButtonColumn}
            >
              <ColumnField>
                {!this.props.noNew &&
                  <TxPrefMenu
                    colorBlack
                    selected={this.props.datasetPreferences.transactionRegister.registerComfort}
                    onChange={(v) => this.handleSettingsChange(v)}
                    customItems={customItems}
                    accountType={accountType}
                  />}
              </ColumnField>
            </HdrListItem>
          );
          break;
        }

        //--------------------------------------------
        // SAVE FIELD
        //--------------------------------------------
        case 'save':
          retRow.push(
            <HdrListItem
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classNames(this.props.classes.iconButtonColumn, 'skinny')}
            />
          );
          break;

        //--------------------------------------------
        // CLOSE FIELD
        //--------------------------------------------
        case 'close':
          if (this.props.txnCancelExtraColumn) {
            retRow.push(
              <HdrListItem
                id="regHdrSelectAll"
                privateKey={`regHdr:${field}`}
                fieldName={`${field}`}
                privateClassName={classNames(this.props.classes.iconButtonColumn, 'skinny')}
              >
              </HdrListItem>
            );
          }
          break;


        default:
          log.error(`field type ${field} not implemented`);
          break;
      }
    });
    return retRow;
  };

  //-------------------------------------------------------
  // Render TransactionsHeader
  //
  renderTransactionsHeader = (callbacks, showBalance) => {

    const sortClass = this.props.sortOrder === 'ascending' ? 'turnUp' : 'turnDown';
    const antiSortClass = this.props.sortOrder === 'descending' ? 'turnUp' : 'turnDown';

    const multiAccount = this.props.accountIds.size !== 1;  // TODO what if there is only 1 account?

    const fieldItems = this.renderFields({ multiAccount, sortClass, antiSortClass, showBalance });

    const txnsAreSelected = this.props.uiState.selectedTxns?.size > 0 || (!multiAccount && this.props.txnsCopied);

    const doFastCat = this.props.transactionsWithCategorySuggestions &&
      this.props.transactionsWithCategorySuggestions.size > 0 &&
      this.props.suggestCategoryForUncategorized;

    return (
      <div>
        {this.state.showRegPrefs &&
          <RegPrefsModal
            onClose={this.closeRegPrefs}
          >
          </RegPrefsModal>}

        {this.state.showReconcile &&
          <ReconcilePanel
            onClose={() => this.setState({ showReconcile: false })}
            accountId={this.props.accountIds.first()}
          >
          </ReconcilePanel>}

        {this.state.showCalendar &&
          <TransactionCalendar
            onClose={() => this.setState({ showCalendar: false })}
            navFn={(txn) => this.props.callbacks.scrollToTransaction(txn.id)}
            inDialog
            year={this.props.calendarDate.get('calendarYear')}
            month={this.props.calendarDate.get('calendarMonth')}
            setDate={this.props.callbacks.setCalendarDate}
            accountIds={this.props.accountIds}
          >
          </TransactionCalendar>}

        <RegHeader
          style={{}}
          onClick={this.maybeCancel}
          ref={
            (e) => {
              this.hdrRef = e;
              if (this.props.innerRegRef) {
                return this.props.innerRegRef(e);
              }
              return null;
            }
          }
        >
          {this.props.showControls && !txnsAreSelected &&
            <div style={{}}>
              {!this.props.hideSearch &&
              <RegControlBar
                onFastCategorize={doFastCat ? (e) => {
                  e.stopPropagation();
                  callbacks.fastCategorize();
                } : null}
                onRefresh={callbacks.refreshTxnList}
                onSearch={callbacks.onSearchFilter}
                filter={this.props.uiState.searchBoxFilter}
                onApplyFilter={callbacks.onApplyFilter}
                filterObject={this.props.uiState.filterObject}
                downloadFile={callbacks.downloadFile}
                acctTxns={this.props.acctTxns}
                transactionListMetrics={this.props.transactionListMetrics}
                scheduledTransactionsInRegister={this.props.scheduledTransactionsInRegister}
                reminderSetting={this.props.reminderSetting}
                onReminderChange={this.props.accountIds.size === 1 && !isAcme && callbacks.reminderChange ? callbacks.reminderChange : undefined}
                showNewTxn={callbacks.showNewTxn}
                noNew={this.props.noNew}
                isWindowsDataset={this.props.dataset && datasetsUtils.isWindowsDataset(this.props.dataset)}
                showDebtErrorMessage={this.props.showDebtErrorMessage}
                accountsFilter={this.props.accountsFilter}
                defaultAccountId={this.props.accountIds.first()}
              />}
            </div>}


          {txnsAreSelected &&
            <RegMEditBar
              selectedTxns={this.props.uiState.selectedTxns}
              onEdit={callbacks.editSelectedTxns}
              onDelete={callbacks.deleteSelectedTxns}
              onReviewed={() => callbacks.reviewSelectedTxns(true)}
              onNotReviewed={() => callbacks.reviewSelectedTxns(false)}
              onMatch={callbacks.matchSelectedTxns}
              onDownload={callbacks.downloadFile}
              accountIds={this.props.accountIds}
            />}

          {!this.props.hideColumnsHeader &&
            <div className={this.props.classes.regColumnsHeader}>
              {
                fieldItems.map((item) => {

                  const draggableDroppable = this.props.draggableFields && fieldIsMoveable(item.props.fieldName);

                  return (
                    <div
                      onDragStart={draggableDroppable ? (e) => this.onDragStart(e, item.props.fieldName) : null}
                      onDragOver={draggableDroppable ? (e) => this.onDragOver(e, item.props.fieldName) : null}
                      onDrop={draggableDroppable ? this.onDrop : null}
                      onDragEnd={draggableDroppable ? this.onDragEnd : null}
                      key={`hdrColumn-${item.props.privateKey}`}
                      draggable={draggableDroppable}
                      className={
                        classNames(
                          item.props.privateClassName,
                          this.state.draggingOver === item.props.fieldName ? this.props.classes.inserting : ''
                        )
                      }
                    >
                      {item}
                    </div>
                  );
                })
              }
            </div>}
        </RegHeader>
      </div>
    );
  };

  render() {
    return this.renderTransactionsHeader(this.props.callbacks, this.props.showBalance);
  }
}

RegisterHeader.propTypes = {
  callbacks: PropTypes.object,
  showBalance: PropTypes.bool,
  uiState: PropTypes.object,
  accountIds: PropTypes.object,
  showControls: PropTypes.bool,
  headerFields: PropTypes.object.isRequired,
  hideColumnsHeader: PropTypes.bool,
  classes: PropTypes.object,
  innerRegRef: PropTypes.func,
  doubleAmounts: PropTypes.bool,
  showAccountColors: PropTypes.bool,
  acctTxns: PropTypes.object,
  noNew: PropTypes.bool,
  sizeChanged: PropTypes.bool,
  hideSearch: PropTypes.bool,
  transactionListMetrics: PropTypes.object,
  transactionsWithCategorySuggestions: PropTypes.object,
  accountsById: PropTypes.object,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  draggableFields: PropTypes.bool,
  txnsCopied: PropTypes.bool,
  calendarDate: PropTypes.object,

  setDatasetPreference: PropTypes.func,
  datasetPreferences: PropTypes.object,

  txnCancelExtraColumn: PropTypes.bool,
  suggestCategoryForUncategorized: PropTypes.bool,
  scheduledTransactionsInRegister: PropTypes.bool,

  // DEBT NOT SUPPORTED IN QUICKEN WEB
  showDebtErrorMessage: PropTypes.bool,
};
function mapStateToProps(state, ownProps) {

  const suggestCats = featureFlagsSelectors.getFeatureFlags(state).get('suggestCategoryForUncategorized');
  return {
    transactionsWithCategorySuggestions: suggestCats ?  // skip selector cost if not needed
      transactionListSelectors.getTransactionsWithCategorySuggestions(
        state,
        {
          accountIds: ownProps.accountIds,
        }
      ) : List(),
    accountsById: accountsSelectors.getAccountsById(state),
    accountsFilter: accountsSelectors.getAccountsByIds(state, ownProps.accountIds),
    txnCancelExtraColumn: featureFlagsSelectors.getFeatureFlags(state).get('txnCancelExtraColumn'),
    reconcileAccount: featureFlagsSelectors.getFeatureFlags(state).get('reconcileAccount'),
    transactionCalendar: featureFlagsSelectors.getFeatureFlags(state).get('transactionCalendar'),
    suggestCategoryForUncategorized: suggestCats,
    scheduledTransactionsInRegister: featureFlagsSelectors.getFeatureFlags(state).get('scheduledTransactionsInRegister'),
    txnsCopied: getClipboardForKey(state, 'bankTransactions')?.size > 0,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  QPreferences(),
  connect(mapStateToProps)
)(RegisterHeader);
