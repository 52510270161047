// @flow
import { List, Map as ImmutableMap } from 'immutable';

import { getLogger } from '@quicken-com/react.utils.core';
import { accountsTransformers } from '@quicken-com/react.flux.accounts';
import type { Account } from '@quicken-com/react.flux.accounts';

import type { InstitutionLogin } from 'data/institutionLogins/types';
import { mkInstitutionLoginAggregator } from 'data/institutionLogins/types';
import { replaceRawASCII } from 'utils/utils';

import * as types from './types';

const log = getLogger('components/Dialogs/AccountDiscovery/transformers.js');

const transformAccountsToRequestData = (accounts: List<Account>) =>
  accounts.reduce((list, account) => { // eslint-disable-line
    list.push(accountsTransformers.transformAccountToRequestData(account));
    return list;
  }, []);

export const transformInstitutionLoginToReqestData = (institutionLogin: InstitutionLogin, accounts = null) => ({
  cpSetupMode: institutionLogin.cpSetupMode ? institutionLogin.cpSetupMode : 'DISCOVER_ACCOUNTS_ONLY',
  channel: institutionLogin.channel,
  id: institutionLogin.id !== '0' ? institutionLogin.id : null,
  name: institutionLogin.name,

  cpInstitutionId: institutionLogin.cpInstitutionId,
  institutionId: institutionLogin.institutionId,

  credentials: !institutionLogin.credentials ? null : institutionLogin.credentials.map((credential) => ({
    key: credential.key,
    value: credential.value,
  })),
  aggregators: !institutionLogin.aggregators || institutionLogin.aggregators.size === 0 ? null :
    [{
      cpChannel: institutionLogin.aggregators.get(0).channel,
      cpId: institutionLogin.aggregators.get(0).cpId,
    }],

  accounts: accounts != null ? transformAccountsToRequestData(accounts) : null,
});

export const mergeDiscoveryAggregatorsWithInstitutionLogin = (disoveryAggregators, institutionLogin : any) => (
  institutionLogin.set('aggregators', List(
    disoveryAggregators.map((aggregator) => mkInstitutionLoginAggregator({
      channel: aggregator.channel,
      cpId: aggregator.cpId,
    }))
  ))
);

export const transformResponseToAccountDiscoveryData = (data: any, allAccountsById: ImmutableMap<string, Account>) => {
  const activeAccounts = [];
  const deadAccounts = [];
  const newAccounts = [];
  const unconnectedAccounts = [];

  data.accounts?.forEach((account) => {

    let existingAccount = null;
    if (account.id) {
      existingAccount = allAccountsById.get(account.id);
      // todo: should we fail if we cannot find existing account
    }

    // todo: need to handle multiple aggregators of different types, for now
    // todo: will will assume just a single aggregator and fix later
    //
    const aggregator = account.aggregators && account.aggregators.length > 0 ? account.aggregators[0] : null;
    log.debug('Account and Aggregator', account, aggregator, aggregator && aggregator.cpDetails);

    // case a: no id - new discovered account (or one QCS does not know about)
    // case b: id but no aggregator - unconnected account
    // case c: id, cpId, cpDetails - active aggregated account
    // case d: id, cpId, but no cpDetails - dead aggregated account

    let discoveredAccount = null;
    if (aggregator && aggregator.cpDetails) {
      let { type, subType } = aggregator.cpDetails;
      if (type === 'OTHER' || type === 'UNCLASSIFIED' || type === 'UNKNOWN') {
        type = 'UNKNOWN';
        subType = 'UNKNOWN';
      }
      discoveredAccount = types.mkDiscoveredAccount({
        shadowAccountId: account.shadowId,

        id: aggregator.id,
        cpId: aggregator.cpId,
        cpLoginId: aggregator.cpLoginId,

        channel: aggregator.channel,

        type,
        subType,

        name: replaceRawASCII(aggregator.cpDetails.name),
        number: aggregator.cpDetails.maskedNumber,
        maskedNumber: aggregator.cpDetails.maskedNumber,

        balance: aggregator.cpDetails.balance,
        balanceAt: aggregator.cpDetails.balanceAt,

        currency: aggregator.cpDetails.currency,
      });
    }
    log.log('Discovered Account is', discoveredAccount);

    if (existingAccount) {
      if (discoveredAccount) {
        activeAccounts.push(
          types.mkActiveDiscoveredAccount({
            account: existingAccount,
            discoveredAccount,
          })
        );
      } else if (aggregator) {
        deadAccounts.push(existingAccount);
      } else {
        unconnectedAccounts.push(existingAccount);
      }
    } else {
      if (!discoveredAccount) {
        log.error("We didn't discover an existing account yet were also missing new account information", account);
        return;
      }
      const { aggStatus } = aggregator.cpDetails;

      if (aggStatus === 'ACCOUNT_NOT_FOUND') {
        log.info("Skipping account because FDS has marked it as not found and we don't know about it anyways", account);
        return;
      }

      const { type } = discoveredAccount;
      if (type !== 'BANK' && type !== 'CREDIT' && type !== 'INVESTMENT' && type !== 'LOAN' && type !== 'UNKNOWN') {
        log.info(`Skipping unsupported account type, type=${type}`);
        return;
      }

      newAccounts.push(discoveredAccount);
    }
  });

  const accountDiscoveryData = types.mkAccountDiscoveryData({
    institutionLoginId: data.institutionLoginId,
    credentialsBlob: data.credentialsBlob,

    status: data.status,

    activeAccounts: List(activeAccounts),
    deadAccounts: List(deadAccounts),
    newAccounts: List(newAccounts),
    unconnectedAccounts: List(unconnectedAccounts),
  });

  log.debug('AccountDiscoveryData = ', accountDiscoveryData);
  return accountDiscoveryData;
};

export function transformResponseToPartnerAuthUris(response: any) {
  const { data } = response;
  return List(data.uris);
}

// ================================================================================================
// Transformers for AccountDiscovery Status
// ================================================================================================

export function transformResponseToAccountDiscoveryStatus(response: any) {
  const { data } = response;

  return types.mkAccountDiscoveryStatus({
    institutionLoginId: data.institutionLoginId,

    isProcessing: data.isProcessing,
    status: data.status,

    aggregators: !data.aggregators ? List() : List(
      data.aggregators.map((aggregator) => types.mkAccountDiscoveryAggregatorStatus({
        isProcessing: aggregator.isProcessing,

        aggStatus: aggregator.aggStatus,
        cpAggStatusCode: aggregator.cpAggStatusCode,
        cpAggStatusDetail: aggregator.cpAggStatusDetail,
        userInstructions: aggregator.userInstructions,
      }))
    ),
  });
}

export function transformAccountsToDiscoverAccount(institutionLogin, accounts) {

  // const activeAccounts = [];
  // const deadAccounts = [];
  const newAccounts = [];
  // const unconnectedAccounts = [];

  accounts.forEach((account) => {

    const { type } = account;
    if (type !== 'BANK' && type !== 'CREDIT' && type !== 'INVESTMENT' && type !== 'LOAN') {
      log.info(`Skipping unsupported account type, type=${type}`);
      return;
    }

    const aggregator = account.aggregators.get(0);
    const discoveredAccount = types.mkDiscoveredAccount({
      id: aggregator.id,
      cpId: aggregator.cpId,
      cpLoginId: aggregator.cpLoginId,

      channel: aggregator.channel,

      type,
      subType: account.subType,

      name: account.name,
      maskedNumber: aggregator.accountNumberMasked,
    });

    newAccounts.push(discoveredAccount);
  });

  const accountDiscoveryData = types.mkAccountDiscoveryData({
    institutionLoginId: institutionLogin.id,
    status: 'OK',

    // activeAccounts: List(activeAccounts),
    // deadAccounts: List(deadAccounts),
    newAccounts: List(newAccounts),
    // unconnectedAccounts: List(unconnectedAccounts),
  });

  return accountDiscoveryData;
}

export const transformPlaidItemActivityToReqestData = (institutionLogin: InstitutionLogin, event) => ({
  event: event.name,
  sessionId: event.sessionId,
  requestId: event.requestId,

  institutionLoginId: institutionLogin ? institutionLogin.id : null,

  institutionId: event.institutionId,
  institutionName: event.institutionName,

  errorCode: event.errorCode,
  errorMessage: event.errorMessage,
  exitedAt: event.exitedAt,
});

export const transformResponseToAggregationInfo = (response: any) => {
  const { data } = response;
  switch (data.aggregationPlatform) {
    case 'FINICITY':
      return types.mkFinicityAggregationInfo({
        connectUrl: data.finicity.connectUrl,
        pollingRef: data.pollingReference,
      });
    case 'PLAID':
      return types.mkPlaidAggregationInfo({
        linkToken: data.plaid.linkToken,
      });
    default:
      log.error('Unsupported aggregation platform', data.aggregationPlatform);
      return undefined;
  }
};

