// @flow
import { createSelector } from 'reselect';
import { List, Map as ImmutableMap } from 'immutable';

import { accountsUtils, accountsSelectors } from '@quicken-com/react.flux.accounts';

import { getInstitutionLoginsById, getPureManualAccounts } from 'data/institutionLogins/selectors';
import { getTopTierEntitlement } from 'data/entitlements/entitlementsSelectors';
import { getAggregatorFromChannel } from 'data/institutions/utils';

import buildUpsertableAccountData from './buildUpsertableAccountData';
import { REDUCER_KEY } from './reducer';
import type { InstitutionLoginUpserterStore } from './types';
import * as transformers from './transformers';

export const getInstitutionLoginUpserterStore = (state: any): InstitutionLoginUpserterStore =>
  state[REDUCER_KEY];

export const getInstitutionLoginUpserterDataForScope = (state: any, { scope }): InstitutionLoginUpserterStore =>
  getInstitutionLoginUpserterStore(state).upserterData.get(scope);

export const getInstitution = (state: any, props) => getInstitutionLoginUpserterDataForScope(state, props)?.institution;
export const getChannel = (state: any, props) => getInstitutionLoginUpserterDataForScope(state, props)?.channel;

export const getInstitutionLogin = (state: any, props) => getInstitutionLoginUpserterDataForScope(state, props)?.institutionLogin;
export const getMfaChallenge = (state: any, props) => getInstitutionLoginUpserterDataForScope(state, props)?.mfaChallenge;
export const getInstitutionLoginIdBeingMigrated = (state: any, props) => getInstitutionLoginUpserterDataForScope(state, props)?.institutionLoginIdBeingMigrated;

export const getPartnerAuthUris = (state: any, props) => getInstitutionLoginUpserterDataForScope(state, props)?.partnerAuthUris;
export const getPublicToken = (state: any, props) => getInstitutionLoginUpserterDataForScope(state, props)?.publicToken;

export const getUpdateStrategy = (state: any, props) => getInstitutionLoginUpserterDataForScope(state, props)?.updateStrategy;
export const getAccountDiscoveryData = (state: any, props) => getInstitutionLoginUpserterDataForScope(state, props)?.accountDiscoveryData;

export const getIsAuthenticationDone = (state: any, props) => getInstitutionLoginUpserterDataForScope(state, props)?.isAuthenticationDone;
export const getIsAccountUpsertingDone = (state: any, props) => getInstitutionLoginUpserterDataForScope(state, props)?.isAccountUpsertingDone;
export const getIsAccountRefreshingDone = (state: any, props) => getInstitutionLoginUpserterDataForScope(state, props)?.isAccountRefreshingDone;

export const getIsPolling = (state: any, props) => getInstitutionLoginUpserterDataForScope(state, props)?.isPolling;
export const getIsSubmitting = (state: any, props) => getInstitutionLoginUpserterDataForScope(state, props)?.isSubmitting;

export const getError = (state: any, props) => getInstitutionLoginUpserterDataForScope(state, props).error;

export const getInstitutionChannelData = createSelector(
  getInstitution,
  getChannel,
  (institution, channel) => {
    if (!institution || !channel) {
      return undefined;
    }
    const aggregatorKey = getAggregatorFromChannel(channel).toLowerCase();
    const aggregatorInfo = institution[aggregatorKey];
    return aggregatorInfo.channelDataByType ? aggregatorInfo.channelDataByType.get(channel) : aggregatorInfo.channelData;
  }
);

export const getAccountsBeingMigrated = createSelector(
  getInstitutionLoginIdBeingMigrated,
  accountsSelectors.getAccountsByInstitutionLoginId,
  (institutionLoginId, accountsByInstitutionLoginId) =>
    institutionLoginId ? accountsByInstitutionLoginId.get(institutionLoginId) : null
);

export const getUpsertableNewAccountsData = createSelector(
  getAccountDiscoveryData,
  getAccountsBeingMigrated,
  getPureManualAccounts,
  getTopTierEntitlement,
  (accountDiscoveryData, accountsBeingMigrated, pureManualAccounts, tier) => {
    const manualLinkableAccounts = pureManualAccounts.filter((acct) => acct.type !== 'GOAL');
    return buildUpsertableAccountData(accountDiscoveryData, accountsBeingMigrated, manualLinkableAccounts, tier);
  }
);

export const getActiveAccountsData = createSelector(
  getAccountDiscoveryData,
  (accountDiscoveryData) => accountDiscoveryData.activeAccounts
);

export const getDeadAccountsData = createSelector(
  getAccountDiscoveryData,
  (accountDiscoveryData) => accountDiscoveryData.deadAccounts
);

export const getOtherAccountAtSameInstitution = createSelector(
  getInstitution,
  getInstitutionLogin,
  getInstitutionLoginIdBeingMigrated,
  getInstitutionLoginsById,
  accountsSelectors.getAccountsByInstitutionLoginId,
  (institution, login, loginIdBeingMigrated, loginsById, accountsByLoginId) => {
    const otherLoginsForInstitution = loginsById.reduce((list, iLogin) => {
      if (iLogin.id !== login.id
        && (loginIdBeingMigrated && iLogin.id !== loginIdBeingMigrated)
        && login.institutionId === institution.id
      ) {
        list.push(login);
      }
      return list;
    }, List());
    return otherLoginsForInstitution.flatMap((loginForInstitution) => {
      const accountsforLoginId = accountsByLoginId.get(loginForInstitution.id);
      return accountsforLoginId
        ? accountsforLoginId.filter((account) => accountsUtils.isActiveConnectedAccount(account))
        : List();
    });
  }
);

export const getAccountDiscoveryDataForTesting = createSelector(
  () => {
    const data = {
      institutionLoginId: '126817258656712194',
      mode: ' DISCOVER_AND_ADD_ACCOUNTS',
      status: 'OK',
      aggregators: [
        {
          aggStatus: 'DISCOVERED_ACCOUNTS_READY_FOR_USER',
          isProcessing: false,
        },
      ],
      accounts: [
        {
          aggregators: [
            {
              channel: 'FDS_SCREEN_SCRAPED',
              cpId: '2906315190',
              cpDetails: {
                type: 'INVESTMENT',
                subType: 'INVESTMENT_TAXABLE',
                name: 'My Brokerage',
                maskedNumber: '0000000000',
                balance: 805.09,
                balanceAt: '2018-08-05T07:00:00Z',
                currency: 'INR',
                aggStatus: 'OK',
                cpAggStatusCode: 'success',
              },
            },
          ],
        },
        {
          aggregators: [
            {
              channel: 'FDS_SCREEN_SCRAPED',
              cpId: '2906315191',
              cpDetails: {
                type: 'LOAN',
                subType: 'MORTGAGE',
                name: 'My Mortgage',
                maskedNumber: '1000000001',
                balance: 805.09,
                balanceAt: '2018-08-05T07:00:00Z',
                currency: 'BND',
                aggStatus: 'OK',
                cpAggStatusCode: 'success',
              },
            },
          ],
        },
        {
          aggregators: [
            {
              channel: 'FDS_SCREEN_SCRAPED',
              cpId: '2906315193',
              cpDetails: {
                type: 'CREDIT',
                subType: 'CREDIT_CARD',
                name: 'My Visa',
                maskedNumber: '4100007777',
                balance: -805.09,
                balanceAt: '2018-08-05T07:00:00Z',
                currency: 'USD',
                aggStatus: 'OK',
                cpAggStatusCode: 'success',
              },
            },
          ],
        },
        {
          aggregators: [
            {
              channel: 'FDS_SCREEN_SCRAPED',
              cpId: '2906315185',
              cpDetails: {
                type: 'BANK',
                subType: 'CD',
                name: 'My CD',
                maskedNumber: '2000005555',
                balance: 805.09,
                balanceAt: '2018-08-05T07:00:00Z',
                currency: 'INR',
                aggStatus: 'OK',
                cpAggStatusCode: 'success',
              },
            },
          ],
        },
        {
          aggregators: [
            {
              channel: 'FDS_SCREEN_SCRAPED',
              cpId: '2906315186',
              cpDetails: {
                type: 'INVESTMENT',
                subType: 'INVESTMENT_TAXABLE',
                name: 'My Roth IRA',
                maskedNumber: '2000004444',
                balance: 805.09,
                balanceAt: '2018-08-05T07:00:00Z',
                currency: 'USD',
                aggStatus: 'OK',
                cpAggStatusCode: 'success',
              },
            },
          ],
        },
        {
          aggregators: [
            {
              channel: 'FDS_SCREEN_SCRAPED',
              cpId: '2906315189',
              cpDetails: {
                type: 'BANK',
                subType: 'SAVINGS',
                name: 'My Savings',
                maskedNumber: '1000002222',
                balance: 805.09,
                balanceAt: '2018-08-05T07:00:00Z',
                currency: 'INR',
                aggStatus: 'OK',
                cpAggStatusCode: 'success',
              },
            },
          ],
        },
        {
          aggregators: [
            {
              channel: 'FDS_SCREEN_SCRAPED',
              cpId: '2906315192',
              cpDetails: {
                type: 'INVESTMENT',
                subType: 'INVESTMENT_TAX_DEFERRED',
                name: 'My Retirement',
                maskedNumber: '0000000001',
                balance: 805.09,
                balanceAt: '2018-08-05T07:00:00Z',
                currency: 'INR',
                aggStatus: 'OK',
                cpAggStatusCode: 'success',
              },
            },
          ],
        },
        {
          aggregators: [
            {
              channel: 'FDS_SCREEN_SCRAPED',
              cpId: '2906315194',
              cpDetails: {
                type: 'BANK',
                subType: 'CHECKING',
                name: 'My Checking',
                maskedNumber: '1000001111',
                balance: 805.09,
                balanceAt: '2018-08-05T07:00:00Z',
                currency: 'BND',
                aggStatus: 'OK',
                cpAggStatusCode: 'success',
              },
            },
          ],
        },
        {
          aggregators: [
            {
              channel: 'FDS_SCREEN_SCRAPED',
              cpId: '2906315187',
              cpDetails: {
                type: 'LOAN',
                subType: 'LOAN',
                name: 'My Auto Loan',
                maskedNumber: '8000008888',
                balance: 805.09,
                balanceAt: '2018-08-05T07:00:00Z',
                currency: 'USD',
                aggStatus: 'OK',
                cpAggStatusCode: 'success',
              },
            },
          ],
        },
        {
          aggregators: [
            {
              channel: 'FDS_SCREEN_SCRAPED',
              cpId: '2906315188',
              cpDetails: {
                type: 'CREDIT',
                subType: 'LINE_OF_CREDIT',
                name: 'My Line of Credit',
                maskedNumber: '8000006666',
                balance: -805.09,
                balanceAt: '2018-08-05T07:00:00Z',
                currency: 'INR',
                aggStatus: 'OK',
                cpAggStatusCode: 'success',
              },
            },
          ],
        },
      ],
      isProcessing: false,
    };
    return transformers.transformResponseToAccountDiscoveryData(data, ImmutableMap());
  }
);
