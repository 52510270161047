
// CORE
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';

// CUSTOM COMPONENTS
import greenThumb from 'assets/green-thumb.svg';
import bankIcon from 'assets/nav-menu/accounts.svg';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
  },
  icon: {
    width: 168,
    height: 168,
  },
  greyBox: {
    margin: '24px 12px 32px',
    backgroundColor: theme.palette.greyScaleDeprecated[5],
    width: '100%',
    maxWidth: 502,
    height: 192,
    borderRadius: 8,
    border: `1px solid ${theme.palette.greyScaleDeprecated[4]}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 24,
  },
  brandingIcon: {
    height: 66,
    width: 104,
    border: `1px solid ${theme.palette.greyScaleDeprecated[4]}`,
    borderRadius: 6,
    backgroundColor: theme.palette.greyScaleDeprecated[6],
    marginBottom: 16,
  },
}));


// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
// ### - - -                  Main Component                     - - - ###
// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
function CompletePage(props) {

  const classes = useStyles();
  const { biller } = props;

  useEffect(() => {
    setTimeout(() => {
      // close dialog;
    }, 10000);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.root}>
      <img
        src={greenThumb}
        className={classes.icon}
        alt="Simplifi by Quicken, click to go home"
      />
      <Typography variant="h5">
        Woohoo!
      </Typography>
      <Typography variant="h5">
        Your bill is now connected.
      </Typography>

      <div className={classes.greyBox}>
        <img
          alt="Bank Icon"
          className={classes.brandingIcon}
          src={biller?.logo || bankIcon}
        />

        <Typography variant="h5">
          {biller?.name}
        </Typography>
      </div>
    </div>
  );
}

CompletePage.defaultProps = {
  biller: {},
};

CompletePage.propTypes = {
  biller: PropTypes.object,
};

export default CompletePage;
