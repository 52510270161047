import { createAction } from 'redux-actions';

export const getMemorizedRules = createAction('GET_MEMORIZED_RULES');
export const getMemorizedRulesSuccess = createAction('GET_MEMORIZED_RULES_SUCCESS');
export const getMemorizedRulesFailure = createAction('GET_MEMORIZED_RULES_FAILURE');

export const createMemorizedRule = createAction('CREATE_MEMORIZED_RULE', (payload) => payload, (payload, meta) => meta);
export const createMemorizedRuleSuccess = createAction('CREATE_MEMORIZED_RULE_SUCCESS', (payload) => payload, (payload, meta) => meta);
export const createMemorizedRuleFailure = createAction('CREATE_MEMORIZED_RULE_FAILURE');

export const updateMemorizedRule = createAction('UPDATE_MEMORIZED_RULE', (payload) => payload, (payload, meta) => meta);
export const updateMemorizedRuleSuccess = createAction('UPDATE_MEMORIZED_RULE_SUCCESS');
export const updateMemorizedRuleFailure = createAction('UPDATE_MEMORIZED_RULE_FAILURE');

export const combineMemorizedRules = createAction('COMBINE_MEMORIZED_RULES', (payload) => payload, (payload, meta) => meta);
export const combineMemorizedRulesSuccess = createAction('COMBINE_MEMORIZED_RULES_SUCCESS', (payload) => payload, (payload, meta) => meta);
export const combineMemorizedRulesFailure = createAction('COMBINE_MEMORIZED_RULES_FAILURE');

export const deleteMemorizedRule = createAction('DELETE_MEMORIZED_RULE', (payload) => payload, (payload, meta) => meta);
export const deleteMemorizedRuleSuccess = createAction('DELETE_MEMORIZED_RULE_SUCCESS');
export const deleteMemorizedRuleFailure = createAction('DELETE_MEMORIZED_RULE_FAILURE');

export const makeMemorizedRuleFromTransactionAction = createAction('MAKE_MEMORIZED_RULE_FROM_TRANSACTION');
