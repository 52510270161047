import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated'; // eslint-disable-line
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import compose from 'utils/compose';
import _ from 'lodash';
import { isAcme } from 'isAcme';

import withStyles from '@mui/styles/withStyles';

const styles = () => ({
  chartRoot: {
    width: '100%',
    height: '100%',
    '& > div:first-child > svg:first-child': {
      transform: 'translate3d(0,0,0) !important', // fixes an issue with Safari
    },
  },
});

class MonthlyBarChart extends PureComponent { // eslint-disable-line react/prefer-stateless-function

  static propTypes = {
    classes: PropTypes.object,
    idPrefix: PropTypes.string,
    monthlyData: PropTypes.any,
    onBarGraphClick: PropTypes.func,
    selectedMonthIndex: PropTypes.number,
    sequencedInterpolation: PropTypes.bool,
    getBarAmount: PropTypes.func,
    tooltipLabel: PropTypes.string,
    colors: PropTypes.object,
    theme: PropTypes.object,
    expandableGrid: PropTypes.bool,
    swlData: PropTypes.object,
    negator: PropTypes.number,
    active: PropTypes.bool,
    isDashboard: PropTypes.bool,
    navigateToReports: PropTypes.func,
  };
  static defaultProps = {
    getBarAmount: (amount) => amount,
    tooltipLabel: '',
    expandableGrid: false,
    idPrefix: '',
    negator: 1,
    sequencedInterpolation: true,
    active: true,
    isDashboard: false,
  };

  componentDidMount() {
    this.setupChart(this.props.monthlyData, this.props.selectedMonthIndex);
  }

  componentDidUpdate(prevProps) {
    const { monthlyData, selectedMonthIndex, swlData, expandableGrid, active } = this.props;
    if (prevProps.monthlyData !== monthlyData) {
      // for some reason monthlyData shallow compare says monthlyData did change,
      // but in fact the values are all equal, so we have to use deep compare
      if (!_.isEqual(monthlyData, prevProps.monthlyData)) {
        setTimeout(() => { this.setChartData(this.chart, monthlyData, selectedMonthIndex, swlData); }, 0);
      }
    }
    if (prevProps.selectedMonthIndex !== selectedMonthIndex) {
      this.prevSelectedMonthIndex = prevProps.selectedMonthIndex;
      this.setChartData(this.chart, monthlyData, selectedMonthIndex, swlData);
    }
    if (expandableGrid && prevProps.swlData !== swlData) {
      this.setChartData(this.chart, monthlyData, selectedMonthIndex, swlData);
    }
    if (prevProps.active !== active) {
      if (active) {
        // this delay allows tab switch animation to complete before the chart is then shown
        setTimeout(() => this.chart.appear(), 500);
      } else {
        this.chart.hide(0);
      }
    }
    if (prevProps.theme !== this.props.theme) {
      const labelColor = am4core.color(this.props.theme.applyOpacityToHex(this.props.theme.palette.greyScaleDeprecated[0], 0.6));
      this.valueAxis.renderer.labels.template.fill = labelColor;
      this.categoryAxis.renderer.labels.template.fill = labelColor;
      this.setTooltipHtml(this.props.theme, this.props);
    }
    if (prevProps.tooltipLabel !== this.props.tooltipLabel) {
      this.setTooltipHtml(this.props.theme, this.props);
    }
    if (expandableGrid && prevProps.swlData !== this.props.swlData) {
      this.chart.invalidateData();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeThrottler);
    if (this.chart) {
      this.chart?.dispose?.();
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
  }

  setupChart = (monthlyData, selectedMonthIndex) => {
    const { idPrefix, theme, expandableGrid, sequencedInterpolation } = this.props;
    if (this.resizeThrottler) {
      clearTimeout(this.resizeThrottler);
    }
    window.addEventListener('resize', this.resizeThrottler);
    am4core.options.autoSetClassName = true;
    am4core.options.autoDispose = true;
    am4core.useTheme(am4themes_animated);
    const chartDivID = idPrefix
      ? `${idPrefix}-monthly-bar-chart-div`
      : 'monthly-bar-chart-div';
    const chart = am4core.create(chartDivID, am4charts.XYChart);
    this.chart = chart;
    this.labelColor = am4core.color(theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.6));
    this.labelColorSelected = am4core.color(theme.palette.greyScaleDeprecated[0]);

    chart.numberFormatter.numberFormat = "'$'#,###.## | '-$'#,###.##s";

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    this.categoryAxis = categoryAxis;
    categoryAxis.dataFields.category = 'month';
    categoryAxis.tooltip.disabled = true;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.grid.template.location = 0.5;
    categoryAxis.renderer.grid.template.strokeOpacity = 0;
    categoryAxis.renderer.ticks.template.disabled = false;
    categoryAxis.renderer.line.opacity = 1;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.labels.template.fontSize = 12;
    categoryAxis.renderer.labels.template.fill = this.labelColor;
    categoryAxis.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    this.valueAxis = valueAxis;
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.line.opacity = 0;
    valueAxis.renderer.line.zIndex = -1;
    valueAxis.renderer.ticks.template.disabled = true;
    valueAxis.renderer.labels.template.fontSize = 12;
    valueAxis.renderer.labels.template.fill = this.labelColor;
    valueAxis.renderer.baseGrid.stroke = am4core.color(theme.palette.greyScaleDeprecated[2]);
    valueAxis.renderer.baseGrid.zIndex = 1;
    valueAxis.renderer.minGridDistance = 25;
    valueAxis.extraMax = 0.5;

    // Create series
    const series = chart.series.push(new am4charts.ColumnSeries());
    this.series = series;
    series.showOnInit = true;
    series.dataFields.valueY = 'amount';
    series.dataFields.categoryX = 'month';
    series.sequencedInterpolation = sequencedInterpolation;

    series.tooltip.label.textAlign = 'middle';
    series.tooltip.dy = -5;
    series.tooltip.getFillFromObject = false;
    series.tooltip.pointerOrientation = 'down';
    series.tooltip.background.fill = am4core.color(theme.palette.background.default);
    series.tooltip.background.fillOpacity = 1;
    series.tooltip.background.strokeWidth = 1;
    series.tooltip.background.cornerRadius = 4;
    series.tooltip.background.pointerLength = 12;
    series.tooltip.background.valign = 'middle';
    series.tooltip.animationDuration = 400;

    const tooltipRect = new am4core.Rectangle();
    this.tooltipRect = tooltipRect;
    tooltipRect.opacity = 0;
    tooltipRect.x = -100;
    tooltipRect.y = -100;
    tooltipRect.height = 100;
    tooltipRect.width = 200;
    tooltipRect.parent = series.tooltip;
    tooltipRect.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    series.columns.template.column.cornerRadius(4, 4, 0, 0);
    series.columns.template.width = expandableGrid
      ? am4core.percent(60)
      : am4core.percent(40);
    series.columns.template.column.zIndex = 2;
    series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    series.columns.template.propertyFields.alwaysShowTooltip = 'always';
    series.columns.template.tooltipY = 0;
    series.columns.template.propertyFields.fill = 'defaultStateFill';
    series.columns.template.propertyFields.stroke = 'defaultStateFill';

    const selectedState = series.columns.template.states.create('selected');
    selectedState.propertyFields.fill = 'selectedStateFill';
    selectedState.propertyFields.stroke = 'selectedStateFill';

    const defaultState = series.columns.template.states.create('default');
    defaultState.propertyFields.fill = 'defaultStateFill';
    defaultState.propertyFields.stroke = 'defaultStateFill';

    // event handlers
    categoryAxis.renderer.labels.template.events.on('over', this.onLabelOver, this);
    categoryAxis.renderer.labels.template.events.on('out', this.onLabelOut, this);
    categoryAxis.renderer.labels.template.events.on('hit', this.onBarOrLabelClick, this);
    series.tooltip.events.on('hit', this.onTooltipClick, this);
    series.columns.template.events.on('hit', this.onBarOrLabelClick, this);
    series.columns.template.events.on('over', this.onBarOver, this);
    series.columns.template.events.on('out', this.onBarOut, this);

    this.setTooltipHtml(theme);

    // adapters
    series.columns.template.column.adapter.add('cornerRadiusBottomLeft', this.cornerRadiusBottomAdapter);
    series.columns.template.column.adapter.add('cornerRadiusBottomRight', this.cornerRadiusBottomAdapter);
    series.columns.template.column.adapter.add('cornerRadiusTopLeft', this.cornerRadiusTopAdapter);
    series.columns.template.column.adapter.add('cornerRadiusTopRight', this.cornerRadiusTopAdapter);
    series.columns.template.adapter.add('strokeWidth', this.strokeWidthAdapter);
    series.columns.template.adapter.add('dy', this.dyChange);
    categoryAxis.renderer.labels.template.adapter.add('fill', this.labelFillAdapter);

    // needed for tooltips
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;
    chart.cursor.lineX.disabled = true;
    chart.cursor.lineY.disabled = true;
    chart.cursor.behavior = 'none';

    chart.maxZoomLevel = 1;
    chart.zoomOutButton.disabled = true;

    chart.events.on('appeared', () => {
      if (!this.props.active) {
        this.chart.hide(0); // so that it will animate nicely when state changes from hidden to visible
      }
      this.handleResize();
    });

    this.setChartData(chart, monthlyData, selectedMonthIndex, this.props.swlData);
    if (expandableGrid) {
      const topContainer = chart.chartContainer.createChild(am4core.Container);
      topContainer.layout = 'absolute';
      topContainer.toBack();
      topContainer.paddingBottom = 15;
      topContainer.width = am4core.percent(100);
    }
  };

  // https://developer.mozilla.org/en-US/docs/Web/Events/resize
  resizeThrottler = () => {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(this.handleResize, 66);
  };

  handleResize = () => {
    this.chart?.svgContainer?.measure();
    this.hideTooltips();
    this.showTooltips();
  };

  hideTooltips = () => {
    this.series.tooltip.animationDuration = 0;

    this.chart.data.forEach((datum) => {
      datum.always = false; // eslint-disable-line
    });
    const point = {
      x: -500,
      y: 0,
      angle: 0,
    };
    setTimeout(() => {
      this.chart.cursor.triggerMove(point, 'none'); // hides tooltip
      this.series.tooltip.animationDuration = 400;
    }, 0);
  };

  showTooltips = () => {
    this.chart.data.forEach((datum, i) => {
      datum.always = i === this.props.selectedMonthIndex;  // eslint-disable-line
    });
  };

  onTooltipClick = (e) => {
    e.event.stopPropagation();
    const { index } = this.series.tooltipDataItem;
    this.processClick(index);
  };

  onBarOrLabelClick = (e) => {
    e.event.stopPropagation();
    const { index } = e.target.dataItem;
    this.processClick(index);
  };

  processClick = (index) => {
    const { selectedMonthIndex, onBarGraphClick } = this.props;
    const isUnselecting = index === selectedMonthIndex;
    setTimeout(() => {
      this.hideTooltips();
      onBarGraphClick(isUnselecting ? null : index);
    }, 100);   // for bar clicks, 0 works here, but for label clicks, need the 100.
  };

  onBarOver = (e) => {
    const { index } = e.target.dataItem;
    const dataItem = this.categoryAxis.dataItems.getIndex(index);
    if (dataItem) {
      const { label } = dataItem;
      label.fill = this.labelColorSelected;
      label.fontWeight = 500;
    }
  };

  onBarOut = (e) => {
    const { index } = e.target.dataItem;
    const dataItem = this.categoryAxis.dataItems.getIndex(index);
    if (dataItem) {
      const { label } = dataItem;
      label.fill = this.props.selectedMonthIndex === index ? this.labelColorSelected : this.labelColor;
      label.fontWeight = this.props.selectedMonthIndex === index ? 500 : 'normal';
    }
  };

  // prevents zero bars from shifting down (or 'disappearing') when clicked on.
  dyChange = (dy, target) => {
    if (!target.dataItem) {
      return dy;
    }
    if (this.chart.data[target.dataItem.index].amount > 0) {
      return -2;
    }
    return 2;
  };

  onLabelOver = (e) => {
    // this code triggers onBarOver() by moving the cursor to the bar
    const point = this.categoryAxis.categoryToPoint(e.target.dataItem.category);
    this.chart.cursor.triggerMove(point, 'soft'); // shows tooltip
  };

  onLabelOut = (e) => {
    // this code triggers onBarOut()
    const point = this.categoryAxis.categoryToPoint(e.target.dataItem.category);
    this.chart.cursor.triggerMove(point, 'none'); // hides tooltip
  };

  // only used on Watchlist page
  setMaxValueAxisWatchlist = (monthlyData, swlData) => {
    const { targetAmount } = swlData;
    const arrayAmount = monthlyData && monthlyData.size && monthlyData.map((a) => this.props.negator * a.amount);
    let monthlyMax = arrayAmount ? Math.max(...arrayAmount) : 0;
    if (monthlyMax === 0 && !targetAmount) {
      monthlyMax = arrayAmount ? Math.min(...arrayAmount) : 0;
      this.valueAxis.max = (-1 * monthlyMax) / 4;
    } else {
      this.valueAxis.max = monthlyMax > targetAmount ? monthlyMax : targetAmount;
    }
  };

  setChartData = (chart, data, selectedMonthIndex, swlData) => {
    const { getBarAmount, expandableGrid } = this.props;
    if (expandableGrid) {
      this.setMaxValueAxisWatchlist(data, swlData);
      if (this.range) {
        this.valueAxis.axisRanges.removeValue(this.range);
      }
    }

    const amountList = data?.map(({ amount }) => getBarAmount(amount));
    const monthlyMinAmount = amountList ? Math.min(...amountList) : 0;
    this.valueAxis.min = monthlyMinAmount < 0 ? monthlyMinAmount : 0;

    const verifiedData = data || [];
    const dataSet = Array.isArray(verifiedData) ? verifiedData : verifiedData.toArray();

    if (expandableGrid && swlData && swlData.targetAmount && this.valueAxis && this.valueAxis.axisRanges) {
      // Create value axis range
      const range = this.valueAxis.axisRanges.create();
      range.value = swlData.targetAmount;
      range.grid.fill = am4core.color('#000');
      range.grid.strokeWidth = 1;
      range.grid.strokeOpacity = 0.7;
      range.label.text = `$${swlData.targetAmount}`;
      range.label.background.fill = am4core.color('#000');
      range.label.fill = am4core.color(this.props.theme.palette.background.paper);
      range.grid.above = true;
      this.range = range;
    }

    if (this.prevSelectedMonthIndex !== 0
      && this.prevSelectedMonthIndex
      && selectedMonthIndex !== null
      && this.prevSelectedMonthIndex !== selectedMonthIndex) {
      this.makeNewDefault = true; // signals that we should prevent any changes to bars other than the selected and previously selected ones
    }

    if (chart?.events?.once) {
      chart.events.once?.('beforedatavalidated', () => {
        setTimeout(() => {  // this deferral is required because columns are not created until next tick
          if (this.series.columns.values.length !== dataSet.length) return;
          this.series.columns.values.forEach((column, i) => {
            column.setState(selectedMonthIndex !== null ? 'selected' : 'default');
            column.tooltip.zIndex = i === selectedMonthIndex ? 0 : Infinity;  // eslint-disable-line
          });
          this.makeNewDefault = false;
          this.prevSelectedMonthIndex = null;
        }, 0);
      });
    }

    chart.data = dataSet.map((period, i) => ({  // eslint-disable-line
      month: period.shortLabel.toUpperCase(),
      amount: expandableGrid ? -1 * getBarAmount(period.amount.toFixed(0)) : getBarAmount(period.amount.toFixed(0)),
      monthTitleCase: _.startCase(_.toLower(period.shortLabel)),
      year: String(period.year),
      always: i === selectedMonthIndex,
      target: expandableGrid ? swlData.targetAmount : null,
      // we have these two different properties bound to different states ("default" and "selected"),
      // so that amCharts does a color transition when we switch states
      defaultStateFill: this.makeNewDefault // eslint-disable-line
        ? i === this.prevSelectedMonthIndex
          ? this.getDefaultFillForAmount(getBarAmount(period.amount.toFixed(0)))
          : this.getDimmedFillForAmount(getBarAmount(period.amount.toFixed(0)))
        : this.getDefaultFillForAmount(getBarAmount(period.amount.toFixed(0))),
      selectedStateFill: i === selectedMonthIndex
        ? this.getDefaultFillForAmount(getBarAmount(period.amount.toFixed(0)))
        : this.getDimmedFillForAmount(getBarAmount(period.amount.toFixed(0))),
    }));
  };

  setTooltipHtml = (theme, props = this.props) => {
    const { tooltipLabel, colors, excludeCategoryInTooltip, idPrefix } = props;
    this.series.tooltip.background.fill = am4core.color(theme.palette.background.default);
    if (!excludeCategoryInTooltip) {
      // AmCharts does not support variable substitution within the attributes of the HTML,
      // it only supports variables when they appear as the child of an element.  What we would have
      // liked to do was alter the color of the colored dot based on the associated value, but since
      // we cannot, and since this is only needed for Quicken's NetIncome graph, thus for Quicken only,
      // we omit the colored dot.
      if (isAcme) {
        this.series.columns.template.tooltipHTML = `<div id=${idPrefix}-graph-tooltip xmlns="http://www.w3.org/1999/xhtml" style="
        display: flex;
        justify-content: space-between;
        width: 143px;
        height: 44px;
        font-size: 12px;
        background-color: ${theme.palette.background.default};
        position: static;"
      >
        <div style="display: flex; flex-direction: column; justify-content: center; line-height: 17px; position: static; top: 0; left: 0;">
          <div id=${idPrefix}-graph-tooltip-amount style="font-weight: 500; color: ${theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.87)}; letter-spacing: 2px;">{valueY.value}</div>
          <div id=${idPrefix}-graph-tooltip-date" style="font-weight: normal; color: ${theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.87)};">{monthTitleCase} {year}</div>
        </div>
        <div style="display: flex; flex-direction: column; justify-content: center; line-height: 17px;">
          <div style="visibility: hidden;">Hidden</div>
          <div style="color: ${theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.6)};">
            <div style="display: inline-flex; width: 5px; height: 5px; line-height: 5px; border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%; background-color: ${colors.tooltipBullet}; margin-right: 2px; position: static; top: -1px;"></div>
            <div style="display: inline-flex; color: ${theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.6)}">${tooltipLabel}</div>
          </div>
        </div>
      </div>`;
        // same as above except omit the colored dot and its associated text
      } else {
        this.series.columns.template.tooltipHTML = `<div id="graph-tooltip" xmlns="http://www.w3.org/1999/xhtml" style="
        display: flex;
        justify-content: space-between;
        width: 90px;
        height: 44px;
        font-size: 12px;
        background-color: ${theme.palette.background.default};
        cursor: pointer !important;
        position: static;"
      >
        <div style="display: flex; flex-direction: column; justify-content: center; line-height: 17px; position: static; top: 0; left: 0;">
          <div id=${idPrefix}-graph-tooltip-amount" style="font-weight: 500; color: ${theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.87)}; letter-spacing: 2px;">{valueY.value}</div>
          <div id=${idPrefix}-graph-tooltip-date" style="font-weight: normal; color: ${theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.87)};">{monthTitleCase} {year}</div>
        </div>
      </div>`;
      }
    } else {
      // different HTML for the Watchlist graph's tooltips
      this.series.columns.template.tooltipHTML = `<div id="graph-tooltip" xmlns="http://www.w3.org/1999/xhtml" style="
      display: flex;
      justify-content: space-between;
      width: 60px;
      height: 44px;
      font-size: 12px;
      background-color: ${theme.palette.background.default};
      position: static;"
    >
      <div style="display: flex; flex-direction: column; justify-content: center; line-height: 17px; position: static; top: 0; left: 0;">
        <div id=${idPrefix}-graph-tooltip-amount" style="font-weight: 500; color: ${theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.87)}; letter-spacing: 2px;">{valueY.value}</div>
        <div id=${idPrefix}-graph-tooltip-date" style="font-weight: normal; color: ${theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.87)};">{monthTitleCase} {year}</div>
      </div>
    </div>`;
    }
  };

  cornerRadiusBottomAdapter = (radius, target) => {
    if (!target.dataItem) {
      return radius;
    }
    const isNegative = target.dataItem.dataContext.amount < 0;
    return isNegative ? 2 : 0;
  };

  cornerRadiusTopAdapter = (radius, target) => {
    if (!target.dataItem) {
      return radius;
    }
    const isNegative = target.dataItem.dataContext.amount < 0;
    return isNegative ? 0 : 2;
  };

  getDefaultFillForAmount = (amount) => {
    const { colors } = this.props;
    return amount < 0
      ? am4core.color(colors.negativeDefault)
      : am4core.color(colors.positiveDefault);
  };

  getDimmedFillForAmount = (amount) => {
    const { colors } = this.props;
    return amount < 0
      ? am4core.color(colors.negativeDimmed)
      : am4core.color(colors.positiveDimmed);
  };

  strokeWidthAdapter = (strokeWidth, target) => {
    if (!target.dataItem) {
      return strokeWidth;
    }
    if (this.chart.data[target.dataItem.index].amount === '0' || this.chart.data[target.dataItem.index].amount === 0) {
      return 0;
    }
    return 4;
  };

  labelFillAdapter = (fill, target) => {
    if (!target.dataItem) {
      return fill;
    }
    const { selectedMonthIndex } = this.props;
    const { index } = target.dataItem;
    target.fill = selectedMonthIndex === index  // eslint-disable-line
      ? this.labelColorSelected
      : this.labelColor;
    target.fontWeight = selectedMonthIndex === index ? 500 : 'normal'; // eslint-disable-line
    return fill;
  };

  onCardClick = (e) => {
    const isTooltipClick = (ev) => {
      let ret = false;
      document.getElementsByClassName('amcharts-Tooltip').forEach((el) => {
        if (el?.contains(ev.target)) {
          ret = true;
        }
      });
      return ret;
    };
    if (
      this.props.isDashboard
      // is it a click on whitespace or tooltip or non-clickable elements?
      && (e.target.parentElement.parentElement.parentElement.tagName === 'svg'
      || (e.target.tagName === 'path' && e.target.classList?.toLowerCase?.contains('rounded'))
      || (e.target.tagName === 'tspan' && e.target.parentElement.tagName !== 'text')
      || (e.target.tagName === 'tspan' && e.target.parentElement.textContent.startsWith('$'))
      || (e.target.tagName === 'rect' && !isTooltipClick(e))
      || e.target.tagName === 'svg'
      )
    ) {  // yes, it is, so handle it ourselves
      if (this.props.navigateToReports) {
        this.props.navigateToReports(e);
      }
    }
  };

  render() {
    const { idPrefix, classes } = this.props;
    const chartDivID = idPrefix ? `${idPrefix}-monthly-bar-chart-div` : 'monthly-bar-chart-div';
    return (
      <div
        role="presentation"
        onClick={(e) => e.stopPropagation()}  // prevent events from escaping the chart container so that clicks on whitespace and header can be detected and processed correctly
        onKeyPress={(e) => e.stopPropagation()}
        style={{ height: '100%', transform: 'translate3d(0,0,0) !important' }}
      >
        <div
          role="presentation"
          onKeyPress={this.onCardClick}
          id={chartDivID}
          className={classes.chartRoot}
        />
      </div>
    );
  }
}

MonthlyBarChart.whyDidYouRender = true;

export default compose(
  withStyles(styles, { withTheme: true }),
)(MonthlyBarChart);
