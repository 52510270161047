
// CORE

// MUI

// DATA
import { useDispatch } from 'react-redux';
import { createDialog as createDialogAction } from 'data/rootUi/actions';

// CUSTOM
import { getLogger } from '@quicken-com/react.utils.core';

import * as ddata from './core';

const log = getLogger('components/QDialogs/index.js');


function useQDialogs() {

  const iconsOnDialogAlerts = true; // useSelector(featureFlagsSelectors.getFeatureFlags).get('iconsOnDialogAlerts');
  const dispatch = useDispatch();
  const createDialog = (data) => dispatch(createDialogAction(data));

  const dialogAlertCustom = (props) => {

    // only this one takes on an object (props), all others take individual args   (argggh!)
    const dialogData = ddata.dialogAlertCustom({ ...props, iconsOnDialogAlerts });
    log.log('Creating Dialog...', dialogData.toJS());
    createDialog(dialogData);
  };

  const dialogAlert = (...args) => {
    const dialogData = ddata.dialogAlert(...args, iconsOnDialogAlerts);
    log.log('Creating Dialog...', dialogData.toJS());
    createDialog(dialogData);
  };

  const dialogPassword = (...args) => {
    const dialogData = ddata.dialogPassword(...args, iconsOnDialogAlerts);
    createDialog(dialogData);
  };

  const dialogForm = (...args) => {
    const dialogData = ddata.dialogForm(...args, iconsOnDialogAlerts);
    log.log('Creating Dialog...', dialogData.toJS());
    createDialog(dialogData);
  };

  return {
    dialogAlert,
    dialogForm,
    dialogPassword,
    dialogAlertCustom,
  };
}

export default useQDialogs;
