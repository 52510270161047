import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'utils/compose';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { List as ImmutableList, Map as ImmutableMap } from 'immutable';

import { getBuildConfig, getEnvironmentConfig, tracker } from '@quicken-com/react.utils.core';
import { datasetsSelectors } from '@quicken-com/react.flux.datasets';
import { authActions, authTypes, authSelectors } from '@quicken-com/react.flux.auth';
import { profileSelectors } from '@quicken-com/react.flux.profile';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';

import { dispatchSimpleNotification } from 'data/notifications/notificationsUtils';
import { createDialog as createDialogAction, removeDialog } from 'data/rootUi/actions';
import {
  DIALOG_TYPE as DIALOG_NOTIFICATION,
  mkNotificationDialogProps,
} from 'components/Dialogs/NotificationDialog/types';
import { doAdditionalDesktopFeatures as doAdditionalDesktopFeaturesAction } from 'components/Dialogs/WFDesktopFeatures/actions';
import { mkRootUiData } from 'data/rootUi/types';
import { getSSOToken } from 'data/sso/ssoActions';
import { DIALOG_TYPE_EMPTY_DIALOG } from 'components/Dialogs/EmptyDialog';
import { DIALOG_TYPE_REPORT_ERROR } from 'components/Dialogs/ReportErrorDialog';

import * as subscriptionsSelectors from 'data/subscriptions/subscriptionsSelectors';

import withTheme from '@mui/styles/withTheme';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import CircularProgress from '@mui/material/CircularProgress';
import MiniDatasetPicker from 'components/MiniDatasetPicker';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import IconButton from '@mui/material/IconButton';

import QPreferences from 'components/QPreferences';
import { isQuicken, isAcme } from 'isAcme';

import { overviewLayout, overviewLayoutVersion } from 'containers/OverviewPage/config';
import ProfileOutlineSimplfiIcon from 'assets/nav-menu/profile.simplifi.inline.svg';
import Dump from 'components/Dump';
import ZeroStateView from 'components/ZeroStateView';
import QButton from 'components/QButton';
import ConfusedStarImage from 'assets/confused-star.svg';

class ProfileMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profileButtonAnchorEl: null,
      showHiddenMenu: false,
      // Profile pop-up theme change
      // changeText: `Switch to ${this.props.theme.palette.mode==='dark' ? 'Light' : 'Dark'} Mode`,
    };

    this.popoverActions = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.datasets !== this.props.datasets && Boolean(this.state.profileButtonAnchorEl)) {
      this.popoverActions.updatePosition();
    }
  }

  myAccountClick = () => {
    tracker.track(tracker.events.myAccount, { location: 'nav menu' });
    this.closeProfile();
    if (isAcme) window.open(`${getEnvironmentConfig().myaccount_url}?hide-nav&bearer=${this.props.authSession.accessToken}`);
    else {
      window.open(`${getEnvironmentConfig().myaccount_url}?bearer=${this.props.authSession.accessToken}`);
    }
  };

  logoutClick = () => this.props.authLogout({ reason: 'AUTH_LOGOUT_USER_INITIATED' }, { context: 'profile' });

  openDesktopAdditionalFeatures = () => {
    this.closeProfile();
    this.props.doWFDesktopFeatures();
  };

  helpClick = () => {
    this.closeProfile();
    window.open('https://www.quicken.com/support');
  };

  handleDatasetClick = (dataset, classes) => {
    if (this.props.datasetId !== dataset.id) {
      tracker.track(tracker.events.datasetChange, { location: 'nav menu' });
      setTimeout(() => this.props.authSelectDataset(authTypes.AuthSelectDatasetPayload({ dataset })), 100);
      this.closeProfile();
      this.props.createDialog(mkRootUiData({
        type: DIALOG_NOTIFICATION,
        allowNesting: false,
        props: ImmutableMap(mkNotificationDialogProps({
          buttonLabel: null,
          content: (
            <div className={classes.datasetSwitchingContent}>
              <Typography variant="body1">
                Switching to {dataset.name}
              </Typography>
              <CircularProgress classes={{ root: classes.datasetSwitchingProgress }} size={50} />
            </div>
          ),
          dialogProps: {
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
          },
          title: '',
        })),
      }));
    } else {
      setTimeout(this.closeProfile, 100);  // the delay is only for feel to the user
    }
  };

  profileClick = (e) => {
    this.setState({ profileButtonAnchorEl: e.currentTarget });
  };
  closeProfile = () => {
    this.setState({
      profileButtonAnchorEl: null,
      showHiddenMenu: false,
    });
  };
  handleProfileClose = () => {
    this.closeProfile();
  };
  // Profile pop-up theme change
  // changeTheme = () => {
  //   this.props.setDatasetPreference({
  //     theme: this.props.theme.palette.mode==='dark' ? 'muiDefault' : 'ACME_DARK',
  //   }),
  //   this.setState({
  //     changeText: `Switch to ${this.props.theme.palette.mode==='dark' ? 'Dark' : 'Light'} Mode`
  //   })
  // };
  getFirstLetterOfUserName = (profile) => {
    if (!profile) {
      return '';
    }
    const firstName = profile.firstName || (profile.primaryAddress ? profile.primaryAddress.fullName : '') || profile.username;
    return firstName[0].toUpperCase();
  };

  getFullName = (profile) => {
    if (!profile) {
      return '';
    }
    let fullName = '';
    if (profile.firstName && profile.lastName) {
      fullName = `${profile.firstName} ${profile.lastName}`;
    } else if (profile.primaryAddress && profile.primaryAddress.fullName) {
      fullName = profile.primaryAddress.fullName;
    }
    return fullName;
  };

  goToCommunity = () => {
    this.props.dispatchRemoveDialog('community-verify-email');
    this.closeProfile();
    new Promise((resolve, reject) => this.props.dispatchGetSSOTokenAction(undefined, {
      resolve,
      reject,
      axiosConfig: {
        params: {
          clientId: getBuildConfig().sso_client_id,
          redirect_uri: getBuildConfig().community_url,
        },
      },
    }))
      .then((response) => {
        if (response?.data?.errors?.[0].code === 'QCS-0401-7') {
          this.props.createDialog(mkRootUiData({
            id: 'community-verify-email',
            type: DIALOG_TYPE_EMPTY_DIALOG,
            allowNesting: true,
            props: ImmutableMap({
              colorBar: this.props.theme.palette.background.default,
              content:
  <ZeroStateView
    icon={ConfusedStarImage}
    primary="Verify your email address"
    secondary="We sent you an email to confirm your identity. Once verified, click here to try again."
  >
    <QButton
      variant="contained"
      onClick={this.goToCommunity}
    >
      Go to community
    </QButton>
  </ZeroStateView>,
            }),
          }));
        } else if (assert(response?.data?.redirectUri, 'no sso redirectUri')) {
          setTimeout(() => window.open(response.data.redirectUri, '_blank'), 1000); // setTimeout protects from popup blocker
        }
      })
      .catch((error) => assert(false, error));
  };

  render() {
    const { classes, profile, subscription, financesEnabled, datasetId, datasets } = this.props;
    const { profileButtonAnchorEl } = this.state;
    const dataset = datasets.get(datasetId);
    const fullName = this.getFullName(profile);
    const overviewLayoutAcme = ImmutableList(overviewLayout);
    return (
      <>
        <div
          id={'profile-menu-bar'}
          role={'button'}
          tabIndex={0}
          onKeyPress={() => null}
          onClick={this.profileClick}
          className={classNames(classes.navMenuItem)}
        >
          {isAcme ?
            <ProfileOutlineSimplfiIcon />
            :
            <Avatar className={classes.circleButton}>
              <div
                id={'profile-letter'}
                className={classes.firstNameLetter}
              >
                {this.getFirstLetterOfUserName(profile)}
              </div>
            </Avatar>}
          <Typography style={{ marginLeft: 24, textAlign: 'left' }} className={classes.navText}>
            Profile
          </Typography>
        </div>
        <Popover
          action={(actions) => {
            this.popoverActions = actions;
          }}
          id="profile-menu"
          open={!!(profileButtonAnchorEl)}
          anchorEl={profileButtonAnchorEl}
          onClose={this.handleProfileClose}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          PaperProps={{
            style: {
              transform: 'translate3d(0, 0, 0)',  // fixes a scroll issue: https://github.com/mui-org/@mui/material/issues/10601
            },
          }}
          onKeyDown={(e) => {
            if (e.altKey) { // can't use 'control' key because of MAC's control + click
              this.setState({ showHiddenMenu: true });
            }
            if (e.key === 'Escape') {
              this.handleProfileClose();
            }
          }}
          onKeyUp={() => {
            this.setState({ showHiddenMenu: false });
          }}
          disableEnforceFocus
        >
          <div className={classes.profilePopover}>
            {profile &&
            <div className={classes.profileHeader}>
              <Dump obj={{ dataset, profile, subscription }} />
              <h3>{fullName}</h3>
              <p id="my-account-quicken-id">{profile.username}</p>
              {subscription && subscription.tierName && !isAcme &&
              <p id="my-account-product-tier">{subscription.tierName}</p>}

              {this.state.showHiddenMenu && dataset &&
              <p
                id="my-account-dataset-id"
                style={{ position: 'absolute', right: 2, top: 2 }}
              >
                {profile.userId}:{dataset.id}
                <IconButton
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `user id: ${profile?.userId}\n`
                      + `dataset id: ${dataset?.id}\n`
                      + `email: ${profile?.username}`,
                    );
                    dispatchSimpleNotification('User copied to clipboard');
                  }}
                >
                  <FileCopyOutlinedIcon fontSize="inherit" />
                </IconButton>
              </p>}

              <Button
                variant="contained"
                onClick={this.myAccountClick}
                color="primary"
                id="my-account-button"
              >
                My Account
              </Button>
            </div>}
            {(datasets && datasets.size > 1) && (datasets || financesEnabled) &&
            <div className={classes.profileDataset}>
              <Typography color="inherit" variant="subtitle1" className={classes.profileFinancesTitle}>
                Finances
              </Typography>
              <List disablePadding>
                {datasets && datasets.valueSeq().map((theDataset, index) => {
                  if (index < 3) {
                    return (
                      <ListItem
                        button
                        key={theDataset.id}
                        onClick={() => this.handleDatasetClick(theDataset, classes)}
                        classes={{ root: classes.datasetListItemDefault }}
                      >
                        <Dump obj={theDataset} />
                        <ListItemIcon
                          style={{
                            visibility: `${dataset === theDataset
                              ? 'visible'
                              : 'hidden'}`,
                            marginRight: 0,
                          }}
                        >
                          <CheckIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={theDataset.name}
                          id={`data-set-${index + 1}`}
                        />
                      </ListItem>
                    );
                  }
                  return null;
                })}

                {datasets && datasets.size > 3 &&
                <MiniDatasetPicker
                  datasets={datasets}
                  dataset={dataset}
                  onChange={(d) => this.handleDatasetClick(d, classes)}
                  label="See all finances"
                  labelStyle={{ label: classes.financesButtonStyle }}
                  id="mini-dataset-picker"
                />}
              </List>
            </div>}
            <MenuList>
              {isQuicken && dataset && dataset.createdByClientId === 'quicken_webapp' &&
              <MenuItem id="my-account-do-more-quicken" onClick={this.openDesktopAdditionalFeatures}>
                Do more with Quicken Desktop
              </MenuItem>}
              {/* Profile pop-up theme change */}
              {/* {isAcme &&
              <MenuItem

                id={'theme-profile-menu'}
                onClick={this.changeTheme}
              >
                {this.state.changeText}
              </MenuItem>
              } */}
              {this.props.profileHelpSupport &&
              <MenuItem
                onClick={this.helpClick}
                id="my-account-help-and-support"
              >
                Help &amp; Support
              </MenuItem>}
              {this.props.intercomEnabled &&
              <MenuItem
                id="intercom-launcher"
                onClick={this.closeProfile}
              >
                Help
              </MenuItem>}

              <MenuItem
                id="feedback-launcher"
                onClick={this.goToCommunity}
              >
                Community
              </MenuItem>

              {this.props.resetDashEnabled &&
              <MenuItem
                onClick={() => {
                  this.closeProfile();
                  this.props.setDatasetPreference({ overviewLayoutVersion, overviewPageLayout: overviewLayoutAcme });
                }}
                id="reset-default-dashboard"
              >
                Reset Dashboard Layout
              </MenuItem>}

              {this.props.feedbackEnabled && this.state.showHiddenMenu &&
              <MenuItem
                onClick={() => {
                  this.closeProfile();
                  this.props.createDialog(mkRootUiData({
                    id: 'report-error',
                    type: DIALOG_TYPE_REPORT_ERROR,
                    allowNesting: true,
                    props: ImmutableMap({
                      feedbackSubject: 'send feedback',
                    }),
                  }));
                }}
                id="send-feedback"
              >
                Send Feedback
              </MenuItem>}

              <MenuItem onClick={this.logoutClick} id="my-account-sign-out">Sign Out</MenuItem>
            </MenuList>
          </div>
        </Popover>
      </>
    );

  }
}

ProfileMenu.propTypes = {
  classes: PropTypes.object,
  datasetId: PropTypes.string,
  datasets: PropTypes.object,
  profile: PropTypes.object,
  feedbackEnabled: PropTypes.bool,
  financesEnabled: PropTypes.bool,
  intercomEnabled: PropTypes.bool,
  resetDashEnabled: PropTypes.bool,
  subscription: PropTypes.object,
  profileHelpSupport: PropTypes.bool,
  authSelectDataset: PropTypes.func,
  createDialog: PropTypes.func,
  authSession: PropTypes.object,
  authLogout: PropTypes.func,
  setDatasetPreference: PropTypes.func,
  doWFDesktopFeatures: PropTypes.func,
  dispatchGetSSOTokenAction: PropTypes.func,
  dispatchRemoveDialog: PropTypes.func,
  theme: PropTypes.object,
};

const mapStateToProps = (state) => ({
  feedbackEnabled: featureFlagsSelectors.getFeatureFlags(state).get('feedback'),
  financesEnabled: featureFlagsSelectors.getFeatureFlags(state).get('finances'),
  intercomEnabled: featureFlagsSelectors.getFeatureFlags(state).get('intercom'),
  resetDashEnabled: featureFlagsSelectors.getFeatureFlags(state).get('resetDefaultDashboard'),
  profileHelpSupport: featureFlagsSelectors.getFeatureFlags(state).get('profileHelpSupport'),
  authSession: authSelectors.getAuthSession(state),
  datasets: datasetsSelectors.getSortedDatasets(state),
  profile: profileSelectors.getProfile(state),
  datasetId: authSelectors.getDatasetId(state),
  subscription: subscriptionsSelectors.getActiveSubscription(state),
});

const mapDispatchToProps = (dispatch) => ({
  createDialog: (uiRootData) => dispatch(createDialogAction(uiRootData)),
  dispatchRemoveDialog: (dialogId) => dispatch(removeDialog(dialogId)),
  authSelectDataset: (dataset) => dispatch(authActions.authSelectDataset(dataset)),
  authLogout: (payload, meta) => dispatch(authActions.authLogout(payload, meta)),
  doWFDesktopFeatures: () => dispatch(doAdditionalDesktopFeaturesAction()),
  dispatchGetSSOTokenAction: (data, meta) => dispatch(getSSOToken(data, meta)),
});

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  QPreferences(),
)(withTheme(ProfileMenu)));
