import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getBuildConfig, getLogger, tracker } from '@quicken-com/react.utils.core';
import { profileActions, profileSelectors } from '@quicken-com/react.flux.profile';
import { authSelectors } from '@quicken-com/react.flux.auth';

import useFetchOnce from 'hooks/useFetchOnce';

const log = getLogger('utils/intercom.js');
const APP_ID = getBuildConfig().intercom_app_id;

const Intercom = React.memo((_props) => {
  const profile = useSelector(profileSelectors.getProfile);
  const authSession = useSelector(authSelectors.getAuthSession);

  useFetchOnce(profileActions.getProfile, profileSelectors, false, true);

  useEffect(() => {
    /* eslint-disable */
    if (APP_ID) {
      //Set your APP_ID
      try {
        (function () {
          var w = window;
          var ic = w.Intercom;
          if (typeof ic === 'function') {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
          } else {
            var d = document;
            var i = function () {
              i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
              i.q.push(args);
            };
            w.Intercom = i;
            var l = function () {
              var s = d.createElement('script');
              s.type = 'text/javascript';
              s.async = true;
              s.src = 'https://widget.intercom.io/widget/' + APP_ID;
              var x = d.getElementsByTagName('script')[0];
              x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === 'complete') {
              l();
            } else if (w.attachEvent) {
              w.attachEvent('onload', l);
            } else {
              w.addEventListener('load', l, false);
            }
          }
        })();

        window.intercomSettings = {
          app_id: APP_ID,
          custom_launcher_selector: '#intercom-launcher',
          hide_default_launcher: true,
        }
        updateIntercom();
      } catch (error) {
        log.error(error);
      }
    }
  }, []);

  const updateIntercom = useCallback(() => {
    try {
      if (window.Intercom) {
        const name = [...(profile?.firstName ? [profile.firstName] : []), ...(profile?.lastName ? [profile.lastName] : [])].join(' ');
        window.Intercom('boot');
        window.Intercom('onShow', () => {
          tracker.track(tracker.events.showIntercom);
        });
        window.Intercom('update', {
          user_id: authSession?.qcsId,
          qcs_id: authSession?.qcsId,
          dataset_id: authSession?.datasetId,
          scope: authSession?.scope,
          groups: authSession?.groups,
          email: profile?.username,
          name,
        });
      }
    } catch (e) {
      assert(false, e);
    }

    return () => window.Intercom?.('shutdown');
  }, [authSession, profile]);

  useEffect(() => {
    updateIntercom();
  }, [profile, authSession, updateIntercom]);

  return null;
});

export default Intercom;
