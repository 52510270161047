// @flow
import { Record } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';

export const ENTITLEMENTS_REDUCER_KEY = 'entitlementsStore';

type EntitlementType = {
  id: string,
  expiresAt: string,
  partnerExpireAt: string,
  expiresOn: string,
  checkAlertsUntil: ?string,
  active: bool,
};
export type Entitlement = RecordOf<EntitlementType>;
export const mkEntitlement: RecordFactory<EntitlementType> = Record({
  id: undefined,
  expiresAt: undefined,
  partnerExpireAt: undefined,
  expiresOn: undefined,
  checkAlertsUntil: undefined,
  active: undefined,
});
