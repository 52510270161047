import React, { useState, useMemo, useCallback } from 'react';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import compose from 'utils/compose';

import QTip from 'components/QuickenControls/QTip';
import Typography from '@mui/material/Typography';

import ClearIcon from '@mui/icons-material/Clear';
import { isAcme } from 'isAcme';


const styles = (theme) => ({
  root: {
    backgroundColor: isAcme ? theme.palette.divider : theme.palette.grey[300],
    borderRadius: 20,
    padding: '2px 8px 2px 8px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'default',
    height: 23,
    margin: 2,
    overflow: 'hidden',
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.components.tagsField.hoverColor,
    },
  },
  label: {
    fontSize: 13,
    maxWidth: 100,
    minWidth: 5,
    '&.hovered': {
      width: 'calc(100% - 10px)',
      minWidth: 20,
    },
    //  Used for tags that have 2 characters in them. Added due to ellipsis not appearing in Firefox
    '&.hoveredSmallWidth': {
      width: 'calc(100% - 10px)',
      minWidth: 30,
    },
  },
  clearIcon: {
    fontSize: 14,
    marginLeft: 4,
    position: 'absolute',
    right: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: 20,
    padding: 1,
    backgroundColor: theme.components.tagsField.clearIconColor,
    color: theme.palette.greyScaleDeprecated[7],
    transition: 'background-color 0.2s',
  },
});

function InputChip(props) {
  const { classes, label, hovered, id } = props;

  const [mouseHover, setMouseHover] = useState(false);

  const hoverClass = hovered ? 'hover' : '';

  const typographyStyle = useMemo(() => ({ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }), []);

  const eatClick = useCallback((event) => event.stopPropagation(), []);

  return (
    <div
      className={classNames(classes.root, hoverClass)}
      onMouseEnter={() => setMouseHover(true)}
      onMouseLeave={() => setMouseHover(false)}
      id={id}
      onClick={eatClick}
      role={'presentation'}
    >
      <QTip
        title={label}
        wrapOnly
      >
        <Typography
          style={typographyStyle}
          className={classNames(classes.label, mouseHover ? `hovered${label.length === 2 ? 'SmallWidth' : ''}` : '')}
        >
          {label}
        </Typography>
      </QTip>
      {props.onDelete && (mouseHover || hovered) &&
        <ClearIcon onClick={(event) => props.onDelete(label, event)} className={classes.clearIcon} />}
    </div>
  );
}

InputChip.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  hovered: PropTypes.bool,
  id: PropTypes.string,
};

export default compose(
  withStyles(styles),
)(InputChip);

