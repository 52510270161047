
// BASE
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { List as ImmutableList } from 'immutable';

// CUSTOM COMPONENTS / ASSETS
import QAmountField from 'components/QAmountField';
import QIconButton from 'components/QIconButton';
import CategoryReviewPopupCard from 'components/CategoryReviewPopupCard';
import QTip from 'components/QuickenControls/QTip';
import { setAmountSign } from 'components/Budgets/EditBudgetsDialog/editBudgetHelpers';

// SELECTORS / ACTIONS / UTILITIES
import { getCategoryGroupsById } from 'data/categoryGroups/categoryGroupsSelectors';

// MUI COMPONENTS / ASSETS
import Typography from '@mui/material/Typography';
import BarChartIcon from '@mui/icons-material/BarChart';
import DeleteIcon from '@mui/icons-material/Delete';

// PATH RELATIVE
import { styles } from './styles';

// STYLES HOOK
const useStyles = makeStyles(styles);


/*
 * BudgetGroupItem Component ***************************************
 */

// PROP TYPES
const budgetGroupItemPropTypes = {
  item: PropTypes.object,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  accountIds: PropTypes.object,
  currency: PropTypes.string,
  isRollup: PropTypes.bool,
  depth: PropTypes.number,
  isEverythingElse: PropTypes.bool,
  overrideLabel: PropTypes.string,
  chartIds: PropTypes.object,
};

const BudgetGroupItem = (props) => {

  // PROPS
  const { item, autoFocus, onChange, onDelete, currency, accountIds, isRollup,
    chartIds, isEverythingElse, overrideLabel, depth = 0 } = props;

  const chartIdsToUse = useMemo(() => chartIds || (item.coa ? [item.coa.id] : []), [chartIds, item.coa]);

  // STATE
  const [amountFieldValue, setAmountFieldValue] = useState(Number(item.amount));
  const [isIncomeItem, setIsIncomeItem] = useState(item.isIncome);
  const [catReviewAnchorEl, setCatReviewAnchorEl] = useState(null);
  const [showIcons, setShowIcons] = useState(false);
  const [alternativeChartIds, setAlternativeChartIds] = useState(chartIdsToUse.map((x) => x.id));

  // SELECTORS
  const categoryGroupsById = useSelector((state) => getCategoryGroupsById(state), shallowEqual);

  // EFFECTS
  useEffect(() => {
    // by keeping internal state of the 'value', this prevents from sending multiple change events
    // after changes and before the item is actually changed by the caller
    setAmountFieldValue(Number(item.amount));
    setIsIncomeItem(item.isIncome);
  }, [item]);

  // Determine the id's to use for the chart
  useEffect(() => {
    if (!chartIds && item.type === 'GROUP' && item.groupId && categoryGroupsById) {
      const group = categoryGroupsById.get(item.groupId);
      if (group && group.coas) {
        const idList = ImmutableList(group.coas.map((coa) => coa.id));
        setAlternativeChartIds(idList);
      }
    } else {
      setAlternativeChartIds(chartIdsToUse.map((x) => x.id));
    }
  }, [item, categoryGroupsById, chartIds, chartIdsToUse]);


  // STYLES
  const classes = useStyles(props);

  // INTERNAL FUNCTIONS
  const internalOnChange = (e) => {
    // flip the sign for expense cats as appropriate
    const newVal = isIncomeItem ? Number(Math.abs(e.target.value)) : -Number(Math.abs(e.target.value));

    // only indicate change if it, well... changed.
    if (newVal !== Number(amountFieldValue)) {
      const newItem = item.set('amount', newVal);
      if (onChange) onChange(newItem);
    }
    setAmountFieldValue(newVal);
  };

  const constructName = () => {
    let str = item.name;
    const catParts = str.split(':');
    let parentStr = '';
    if (!isRollup && catParts.length > (depth + 1)) {
      parentStr = `(${catParts[depth]})`;
    }
    str = catParts[catParts.length - 1];

    if (item.budgetedWithChild) str = `Total of ${str}`;
    if (item.type === 'CATEGORY_OTHER') str = `${str} (Other)`;
    if (isEverythingElse) str = `All others in ${str}`;

    return [str, parentStr];
  };

  const nameArray = overrideLabel ? [overrideLabel, ''] : constructName();

  return (
    <div
      className={classNames(classes.categoryRow, isRollup ? 'rollup' : '')}
      onMouseEnter={() => setShowIcons(true)}
      onMouseLeave={() => setShowIcons(false)}
    >
      <div className={classes.categoryNameWrapper}>
        <Typography
          key={`editBudgetCats:str1 ${item.id}`}
          variant="body2"
          className={classes.categoryName}
        >
          {nameArray[0]}
        </Typography>
        <Typography
          key={`editBudgetCats:str2 ${item.id}`}
          variant="body2"
          className={classes.categoryNameParent}
        >
          {nameArray[1]}
        </Typography>
      </div>
      <div
        className={!isRollup ? classes.categoryAmount : classes.rollUpAmount}
      >
        <QAmountField
          id={`budgetAmt:${nameArray[0]}`}
          editable={!isRollup}
          expectedSign={item.isIncome ? 'positive' : 'negative'}
          value={item.isIncome ? Math.abs(amountFieldValue) : -Math.abs(amountFieldValue)}
          currency={currency}
          autoFocus={autoFocus}
          onSubmit={(e) => internalOnChange(e)}
          submitOnBlur
          decimals={0}
          disableUnderline
          InputProps={{
            classes: {
              input: classes.amountInputStyle,
              adornedStart: classes.dollarAdornment,
            },
          }}
        />
      </div>
      {!isRollup &&
        <div className={classNames(classes.iconHolder, showIcons ? '' : classes.hide)}>
          <QTip
            title="Category Details Card..."
          >
            <QIconButton
              aria-label="Show Budget Item Info"
              onClick={(e) => setCatReviewAnchorEl(e.currentTarget)}
              id={`row-${item.id}-delete-item`}
              size="small"
            >
              <BarChartIcon
                className={classes.barChartIcon}
              />
            </QIconButton>
          </QTip>
          {onDelete &&
            <QIconButton
              aria-label="Remove Budget Item"
              onClick={() => onDelete(item)}
              id={`row-${item.id}-delete-item`}
              size="small"
            >
              <DeleteIcon
                className={classes.deleteIcon}
              />
            </QIconButton>}
          {catReviewAnchorEl &&
          <CategoryReviewPopupCard
            anchorEl={catReviewAnchorEl}
            open
            onClose={() => setCatReviewAnchorEl(null)}
            coa={item.coa}
            coaIds={alternativeChartIds.toArray()}
            classes={{
              root: classes.categoryReviewCardRoot,
            }}
            currency={currency}
            accountIds={accountIds}
            onSetValueFromGraph={(amt) => internalOnChange({ target: { value: setAmountSign(amt, item.coa) } })}
            showCents
          />}
        </div>}
    </div>
  );
};
BudgetGroupItem.propTypes = budgetGroupItemPropTypes;

/* *** BUDGET GROUP ITEM export *** */
export default BudgetGroupItem;
