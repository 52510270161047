import { createSelector } from 'reselect';
import { ALERT_RULES_REDUCER_KEY } from './reducer';

export const getAlertRulesStore = (state) => state[ALERT_RULES_REDUCER_KEY];
export const getLastSyncDate = (state: any) => getAlertRulesStore(state).lastSyncDate;
export const getAllAlertRules = (state) => getAlertRulesStore(state).resourcesById;

export const getAllAlertRulesSorted = createSelector(
  getAllAlertRules,
  (alertRules) => alertRules.sortBy(
    (alertRule) => alertRule.title,
    (a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }),
  ),
);

