import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';

import compose from 'utils/compose';
import { formatNumber } from 'components/QuickenControls/AmountField';
import { getAnnualSpendings, getBudgetById } from 'data/budgets/budgetsSelectors';

import ProgressChart from './progressChart';
import styles from './styles';
import { generateChartData } from './utils';

const AnnualSpendingChart = ({ classes, budgetId }) => {
  const theme = useTheme();
  const data = useSelector((state) => getAnnualSpendings(state, budgetId));
  const budget = useSelector((state) => getBudgetById(state, budgetId));
  const { startDate, endDate, totalSpendings, annualBudget, totalSavings, currency } = data;
  const amountFormat = budget.showCents ? '0,00.00' : '0,0';
  const dataForChart = useMemo(() => generateChartData(totalSpendings, annualBudget, totalSavings, currency, theme, amountFormat), [totalSpendings, annualBudget, totalSavings, currency, theme, amountFormat]);

  if (totalSpendings > 0 || annualBudget > 0 || totalSavings > 0) {
    return (
      <Paper className={classes.progressWrapper}>
        <Typography className={classes.rangeHeader}>
          {DateTime.fromISO(startDate).toFormat('MMM yyyy')} - {DateTime.fromISO(endDate).toFormat('MMM yyyy')}
        </Typography>
        <div className={classes.budgetAmountWrapper}>
          <Typography>
            Spending {formatNumber(totalSpendings, currency, amountFormat)} of {formatNumber(annualBudget, currency, amountFormat)}
          </Typography>
          {totalSavings > 0 && (
            <Typography>
              Savings {formatNumber(totalSavings, currency, amountFormat)}
            </Typography>
          )}
        </div>
        <ProgressChart data={dataForChart} chartId={budgetId} />
      </Paper>
    );
  }
  return null;
};

AnnualSpendingChart.propTypes = {
  classes: PropTypes.object,
  budgetId: PropTypes.string,
};

export default compose( 
  withStyles(styles, { withTheme: true }), 
)(AnnualSpendingChart);
