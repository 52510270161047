import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useKeyPressEvent } from 'react-use';
import { useSelector } from 'react-redux';

import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';

import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';

import QPopper from 'components/QPopper';

const useStyles = makeStyles((theme) => ({
  dumpButton: {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0.5,
    zIndex: theme.zIndex.mobileStepper,
  },
  content: {
    maxWidth: '60vw',
    maxHeight: '60vh',
    overflow: 'scroll',
  },
}));

const Dump = (props) => {
  const { id, children, obj, ...otherProps } = props;
  const classes = useStyles();
  const buttonRef = useRef();
  const contentRef = useRef();
  const [show, setShow] = useState();
  const [showDetails, setShowDetails] = useState();
  const [pinWindow, setPinWindow] = useState();
  useKeyPressEvent('Alt', () => setShow(true), () => setShow(false));
  const debug = useSelector((state) => featureFlagsSelectors.getFeatureFlag(state, 'debug'));
  if (!debug) {
    return null;
  }
  return <>
    <IconButton
      {...otherProps}
      className={classes.dumpButton}
      style={{ visibility: (show || showDetails) ? 'visible' : 'hidden', ...otherProps.style }}
      onClick={(event) => {
        buttonRef.current = event.currentTarget;
        setShowDetails(true);
        event.stopPropagation();
      }}
      size="large"
    >
      <BuildOutlinedIcon fontSize="small" />
      <Typography variant="caption" noWrap>
        {id ?? obj?.id}
      </Typography>
    </IconButton>

    {(showDetails || pinWindow) &&
    <QPopper
      anchorEl={buttonRef.current}
      open={showDetails}
      placement="auto"
      onClickAway={() => !pinWindow && setShowDetails(false)}
    >
      <Box display="flex" justifyContent="space-between">
        <Button
          variant="outlined"
          onClick={(event) => {
            navigator.clipboard.writeText(contentRef.current.innerText);
            event.stopPropagation();
          }}
        >
          copy to clipboard
        </Button>

        <IconButton
          onClick={(event) => {
            setPinWindow(!pinWindow);
            event.stopPropagation();
          }}
          size="large"
        >
          {pinWindow ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />}
        </IconButton>

        <IconButton
          onClick={(event) => {
            setShowDetails(false);
            event.stopPropagation();
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <div
        ref={contentRef}
        className={classes.content}
      >
        {children || null}
        {obj &&
        <pre>
          <Typography variant="body2">
            {JSON.stringify(obj, null, 2)}
          </Typography>
        </pre>}
      </div>
    </QPopper>}
  </>;
};

Dump.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  obj: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  id: PropTypes.any,
};

export default Dump;
