import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';

import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';
import { categoriesSelectors } from '@quicken-com/react.flux.categories';
import { transactionsUtils } from '@quicken-com/react.flux.transactions';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import ExitIcon from '@mui/icons-material/Clear';
import LinkIcon from '@mui/icons-material/Link';

// DATA
import { useDispatch, useSelector } from 'react-redux';
import { getBillerAccountForId } from 'data/billerAccounts/selectors';
import { getBillerLoginForId } from 'data/billerLogins/selectors';
import { inError } from 'data/billerLogins/utils';

// CUSTOM COMPONENTS
import QButton from 'components/QButton';
import ButtonBase from '@mui/material/ButtonBase';
import { doBillPresentment } from 'components/Dialogs/BillPresentment/actions';
import { tracker } from '@quicken-com/react.utils.core';


const useStyles = makeStyles((theme) => ({
  connectBox: {
    height: 76,
    width: 'calc(100% - 48px)',
    padding: '12px 28px 12px 20px',
    flexShrink: 0,
    borderRadius: 12,
    border: `1px solid ${theme.palette.color7.opacity100}`,
    backgroundColor: theme.palette.color7.opacity50,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '12px 24px 4px',
    position: 'relative',
  },
  errorBox: {
    extend: 'connectBox',
    border: `1px solid ${theme.palette.color5.opacity100}`,
    backgroundColor: theme.palette.color5.opacity30,
  },
  stack: {
    display: 'flex',
    flexDirection: 'column',
  },
  dismiss: {
    position: 'absolute',
    borderRadius: 10,
    top: 6,
    right: 6,
    height: 20,
    width: 20,
    '&:hover': {
      backgroundColor: theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.1),
    },
  },
  exitSize: {
    height: 16,
    width: 16,
    '&:hover': {
      color: theme.palette.greyScaleDeprecated[0],
    },
  },
}));

export const showConnectOption = createSelector(
  (state, model) => model,
  categoriesSelectors.getIncomeCOAIds,
  (model, incomeCOAs) => {
    if (model?.billPresentmentAccountId === '0') {
      return false;
    }
    const txn = model?.transaction;
    if (!txn) return false;
    if (model.billPresentmentAccountId) return false;
    if (txn.amount < 0) return true;
    if (txn.coa) {
      return !(incomeCOAs.has(txn.coa.id));
    }
    if (transactionsUtils.isSplitTxn(txn)) { // split
      return txn.split.items && Boolean(txn.split.items.find((item) => item?.coa?.id && !(incomeCOAs.has(item.coa.id))));
    }
    return false;
  }
);


// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
// ### - - -                  Main Component                     - - - ###
// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
function BillPresentmentConnectBox(props) {

  const classes = useStyles();
  const { model, onConnect, location } = props;
  const dispatch = useDispatch();

  const presentBills = useSelector(featureFlagsSelectors.getFeatureFlags).get('billPresentment');
  const connectOption = useSelector((state) => presentBills && showConnectOption(state, model));

  const billerAccount = useSelector((state) => presentBills && getBillerAccountForId(state, model?.billPresentmentAccountId));
  const billerLogin = useSelector((state) => presentBills && getBillerLoginForId(state, billerAccount?.billerLoginID));

  const [dismissed, setDismissed] = useState(false);

  if (!presentBills) {
    return null;
  }

  const reconnect = model?.billPresentmentAccountId && billerLogin && inError(billerLogin);

  function startConnect() {
    dispatch(doBillPresentment(model, location));
    tracker.track(reconnect ? tracker.events.fixBillerStart : tracker.events.addBillerStart, { initiator: location });
    onConnect?.();
  }

  function dismiss() {
    setDismissed(true);
  }

  return (((connectOption || reconnect) && !dismissed) ?
    <div className={reconnect ? classes.errorBox : classes.connectBox}>
      <div className={classes.stack}>
        <Typography variant="h6">
          {reconnect ? 'Unable to connect to your bill' : 'Connect biller'}
        </Typography>
        <Typography variant="caption">
          {reconnect ?
            "We're having trouble connecting to your biller. Try reconnecting."
            :
            "We'll keep Simplifi updated with your most recent bill information."}
        </Typography>
      </div>

      <QButton
        variant="contained"
        onClick={startConnect}
        id={'series-connect'}
      >
        <LinkIcon style={{ marginRight: 4 }} />
        {reconnect ? 'RECONNECT' : 'CONNECT'}
      </QButton>

      <ButtonBase id="dismiss_connect" className={classes.dismiss} onClick={dismiss} focusRipple>
        <ExitIcon className={classes.exitSize} />
      </ButtonBase>
    </div>
    :
    null
  );
}

BillPresentmentConnectBox.propTypes = {
  model: PropTypes.object,
  onConnect: PropTypes.func,
  location: PropTypes.string, // mixpanel tracking
};

export default BillPresentmentConnectBox;
