export const WFDesktopFeatures = (theme) => {
  const { spacing, palette, typography } = theme;
  const OSSelectorWidth = spacing(15.5);

  return ({
    root: {
      padding: `0 ${spacing(3.25)} ${spacing(2)}`,
    },
    headlineTitle: {
      ...typography.h5,
    },
    headlineText: {
    },
    OSSelectorRoot: {
      position: 'relative',
      width: '100%',
      marginBottom: spacing(2),
      '&:before, &:after': {
        content: '\' \'',
        borderTop: `1px solid ${palette.divider}`,
        position: 'absolute',
        width: `calc(50% - ${(OSSelectorWidth / 2) + spacing(2)}px)`,
        top: 'calc(50% - 1px)',
      },
      '&:before': {
        left: 0,
      },
      '&:after': {
        right: 0,
      },
    },
    OSSelector: {
      width: OSSelectorWidth,
      margin: '0 auto',
      display: 'block',
      '& span': {
        cursor: 'pointer',
        userSelect: 'none',
      },
    },
    OSSelectorFadeLabel: {
      opacity: 0.5,
    },
    switchThumb: {
      background: palette.greyScaleDeprecated[6],
    },
    switchTrack: {
      background: `${palette.primary.main} !important`,
      opacity: '1 !important',
    },
    featuresRoot: {
      width: '100%',
    },
    featuresHolder: {
      height: '100%',
    },
    featuresTable: {
      flex: 1,
      height: '100%',
      overflowY: 'auto',
      maxHeight: 310,
    },
    table: {
      height: '100%',
    },
    tableHead: {
      '& $tableCell': {
        ...typography.subtitle2,
        paddingTop: spacing(2.5),
        paddingBottom: spacing(2.5),
        '&:nth-child(3)': {
          width: 200,
        },
      },
    },
    cellFeature: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    tableRow: {
      '&:nth-child(odd) $tableCell': {
        backgroundColor: palette.greyScaleDeprecated[6],
      },
    },
    tableCell: {
      border: 'none',
      borderRight: `1px solid ${palette.grey['300']}`,
      padding: `${spacing(1.5)} ${spacing(2)}`,
    },
    rightContent: {
      width: 300,
      display: 'flex',
      alignItems: 'center',
      paddingTop: spacing(4),
      flexDirection: 'column',
    },
    tooltipTitle: {
      marginBottom: theme.spacing(1.5),
      fontWeight: theme.typography.fontWeightBold,
    },
    tooltipContent: {},
    helpIcon: {
      color: palette.primary.light,
      cursor: 'pointer',
      backgroundColor: palette.greyScaleDeprecated[7],
      borderRadius: '50%',
    },
    checkAvailable: {
      color: palette.green1,
    },
    checkPartial: {
      color: palette.status.mediumMain,
    },
    checkNone: {
      color: palette.red1,
    },
    legendRoot: {
      marginTop: spacing(2),
    },
    legendText: {
      ...typography.caption,
      display: 'inline-block',
      marginRight: spacing(2),
    },
    linkGroup: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: spacing(2),
    },
    link: {
      marginTop: spacing(1),
      cursor: 'pointer',
    },
  });
};
