// @flow
import React from 'react';
import { connect } from 'react-redux';
import compose from 'utils/compose';
import classNames from 'classnames';

import { tracker } from '@quicken-com/react.utils.core';
import { accountsActions, accountsSelectors } from '@quicken-com/react.flux.accounts';
import { featureFlagsSelectors } from '@quicken-com/react.flux.feature-flags';

import { List as ImmutableList, Map as ImmutableMap, OrderedMap } from 'immutable';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import withStyles from '@mui/styles/withStyles';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Slide from '@mui/material/Slide';
import Skeleton from '@mui/material/Skeleton';

import zeroStateIcon from 'assets/zero-state-images/bank.svg';
import navIconInvestments from 'assets/nav-menu/investments.svg';

import { getInvestmentAccounts } from 'data/accounts/actions';
import { getAccountNodesTree, getAccountNodesById } from 'data/accountNodes/selectors';

import { isOldEdge } from 'utils/utils';
import { isQuicken, isAcme } from 'isAcme';

import QTextButton from 'components/QTextButton';
import Dump from 'components/Dump';

import AccountRow from './AccountRow';
import { makeDisplayOptions } from './types';
import type { DisplayOptions } from './types';


const styles = (theme) => ({
  base: {
    minWidth: theme.components.accountDrawer.width,
    paddingRight: theme.spacing(1),
    '@media (max-width:960px)': {
      minWidth: ({ prefix }) => prefix === 'dashboard' ? `calc(100vw - ${theme.components.navigation.barWidth}px - ${theme.spacing(7)} + 1px)` : 0,
    },
  },
  root: {
    width: '100%',
    background: (props) => ((isAcme && props.prefix === 'dashboard') ? theme.palette.background.paper : theme.components.accountDrawer.background),
    padding: 0,
    borderBottom: 'none',
  },
  rootWithBorderBottom: {
    width: '100%',
    background: (props) => ((isAcme && props.prefix === 'dashboard') ? theme.palette.background.paper : theme.components.accountDrawer.background),
    borderBottom: `1px solid ${isAcme ? theme.palette.background.default : theme.components.accountDrawer.divider}`,
    padding: 0,
    '&.dim': {
      opacity: 0.3,
    },
  },
  dividerRoot: {
    backgroundColor: theme.components.accountDrawer.divider,
  },
  loadingStateContainer: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  zeroStateContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 271,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 18,
    color: theme.palette.greyScaleDeprecated[2],
  },
  zeroStateTextHeadline: {
    marginBottom: 10,
    fontWeight: 500,
    color: theme.palette.greyScaleDeprecated[7],
  },
  zeroStateTextBody: {
    maxWidth: '80%',
    padding: 16,
    textAlign: 'center',
    color: theme.palette.greyScaleDeprecated[7],
  },
  skeletonWrapper: {
    height: '100%',
    width: '100%',
  },
  skeletonBlock: {
    backgroundColor: theme.palette.greyScaleDeprecated[5],
    borderRadius: 8,
    marginBottom: 10,
    marginLeft: '24px',
    width: '75%',
    height: 30,
    '&.large': {
      marginLeft: '24px',
      width: '85%',
      height: 40,
    },
    '&.small': {
      marginLeft: '24px',
      width: '70%',
      height: 20,
    },
    '&.netWorth': {
      marginLeft: '24px',
      width: '85%',
      height: 60,
    },
  },
  skeletonGroupWrapper: {
    marginBottom: '24px',
  },
  emptyAccountTypeHeader: {
    width: '100%',
    textAlign: 'left',
    padding: theme.spacing(1),
    background: theme.components.accountDrawer.l1Color,
    borderRadius: theme.shape.borderRadius * 3,
    paddingLeft: theme.spacing(3),
  },
  emptyAccountTypeAddButton: {
    textAlign: 'left',
    margin: `${theme.spacing(1)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    color: theme.palette.link.main,
    '&:hover': {
      textDecorationLine: 'underline',
    },
  },
});

const isIe = require('is-iexplorer') || isOldEdge();  // eslint-disable-line

type Props = {
  dimAccountList: bool,
  accountNode: Object,
  accountNodeName: string,
  accountNodesTree: any,
  borderBottom: any,
  classes: any,
  theme: any,
  balanceType: string,
  isLoading: bool,
  onSelect: Function,
  selectedNodeId: string,
  showColors: bool,
  omitCents: bool,
  handleAddInstitutionLogin: Function,
  accountsToShowAddOptionUnder: Array,
  prefix: string,
};

type State = {
  displayOptionsMap: ImmutableMap<string, DisplayOptions>,
};

class AccountList extends React.PureComponent<Props, State> {

  UNSAFE_componentWillMount() {
    this.setState({
      displayOptionsMap: this.mkDisplayOptionsMap(this.props),
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.accountNodesTree !== this.props.accountNodesTree) {
      const displayOptionsMap = this.mkDisplayOptionsMap(nextProps);
      this.setState({ displayOptionsMap });
    }
  }

  mkDisplayOptionsMapEntry = (path, node, props) => {
    let children = null;
    let expandParent = true;

    if (node.children) {
      children = OrderedMap(node.children.map((child) => {
        const pathToChild = path.concat(['children', child.id]);
        const displayNodeEntry = this.mkDisplayOptionsMapEntry(pathToChild, child, props);
        if (isQuicken && (path.includes('SEPARATE') || path.includes('HIDDEN'))) { // start separate and hidden sections as collapsed
          expandParent = false;
        }
        if (displayNodeEntry[1].selected) {
          expandParent = true;
        }
        return displayNodeEntry;
      }));
    }

    const displayOptions = makeDisplayOptions({
      selected: false,
      expand: expandParent,

      path: ImmutableList(path),
      children,
    });

    return [node.id, displayOptions];
  };

  mkDisplayOptionsMap = (props): ImmutableMap<string, DisplayOptions> => (
    ImmutableMap(props.accountNodesTree.map((node) =>
      this.mkDisplayOptionsMapEntry([node.id], node, props)))
  );

  handleExpandCollapse = (displayOptions) => {
    if (!displayOptions.children) {
      // cannot expand/collapse non-parent nodes
      return;
    }

    this.setState({
      displayOptionsMap: this.state.displayOptionsMap.setIn(
        displayOptions.path,
        displayOptions.set('expand', !displayOptions.expand)
      ),
    });

    const trackObj = {
      state: displayOptions.expand ? 'collapsed' : 'expanded',
      level: displayOptions.path.size === 3 ? 2 : 1,
    };
    tracker.track(tracker.events.accountListArrow, trackObj);
  };

  renderDrawerSkeleton = () => {
    const { classes } = this.props;
    const skeletonDrawer = [];
    for (let i = 0; i < 5; i += 1) {
      skeletonDrawer.push(
        <div key={`skeleton-${i}`} className={classes.skeletonGroupWrapper}>
          <Skeleton variant="rectangular" className={classNames(classes.skeletonBlock, 'large')} />
          <Skeleton variant="rectangular" className={classes.skeletonBlock} />
          <Skeleton variant="rectangular" className={classNames(classes.skeletonBlock, 'small')} />
        </div>,
      );
    }

    return (
      <>
        <div className={classes.skeletonWrapper}>
          <Skeleton variant="rectangular" className={classNames(classes.skeletonBlock, 'netWorth')} />
          {skeletonDrawer}
        </div>
      </>
    );
  };

  // ==============================================================================
  // RENDER
  // ==============================================================================

  render() {
    const {
      dimAccountList,
      accountNode,
      accountNodeName,
      accountNodesTree,
      handleAddInstitutionLogin,
      accountsToShowAddOptionUnder,
      borderBottom,
      classes,
      isLoading,
      onSelect,
      selectedNodeId,
      showColors,
      balanceType,
      theme,
      omitCents,
    } = this.props;

    const numAccounts = isLoading ? 0 : accountNodesTree.size;

    return (
      <div className={classes.base} id="qCard-account-list">
        <Dump obj={{ accountNodesTree }} style={{ left: 'unset', right: 0 }} />
        {isLoading &&
        <div className={classes.loadingStateContainer}>
          <Fade
            in={isLoading}
            unmountOnExit
          >
            <CircularProgress style={{ color: theme.palette.primaryBlue }} />
          </Fade>
        </div>}
        {/* This skeleton for the accountDrawer on the Transactions page for acme is currently not in use. It may be added in the future */}
        {(isLoading && false) &&
          this.renderDrawerSkeleton()}
        {!isLoading && numAccounts > 0 &&
        <List
          dense
          classes={{
            root: borderBottom ? classes.rootWithBorderBottom : classes.root,
          }}
          className={dimAccountList ? 'dim' : ''}
        >
          {accountNodesTree.map((node, i) => {
            // log.debug('NODE: ', node.toJS());
            if (node.hide) {
              return (<div key={node.id} />);
            }
            return (
              <AccountRow
                key={node.id}
                id={`${node.displayLabel}-${i}`}
                node={node}
                dense
                displayOptions={this.state.displayOptionsMap.get(node.id)}
                onClick={onSelect}
                onExpandCollapse={this.handleExpandCollapse}
                selectedNodeId={selectedNodeId}
                showColors={showColors}
                balanceType={balanceType}
                omitCents={omitCents}
              />
            );
          })}

          <Box marginTop={2} paddingLeft={2} paddingRight={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
            {accountsToShowAddOptionUnder.map((accountType, i) => (
              <Slide
                key={`transition-${accountType}-empty`}
                direction={'up'}
                in
                style={{ width: '100%' }}
                timeout={400 + (accountNodesTree?.size + i) * 50}
              >
                <Box>
                  <Fade in timeout={2500 + ((accountNodesTree.size + i) * 50)}>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                      <div className={classes.emptyAccountTypeHeader}>
                        <Typography variant={'subtitle1'}>{accountType}</Typography>
                      </div>
                      <QTextButton
                        className={classes.emptyAccountTypeAddButton}
                        onClick={handleAddInstitutionLogin(`account list${isAcme ? ` ${accountType.toLowerCase()}` : ''}${this.props.prefix === 'dashboard' ? ' dashboard' : ' txns'}`, accountType === 'Property')}
                        variant={'body2'}
                        title={`+ ${accountType?.replace(/s$/, '')} account`}
                      />
                    </Box>
                  </Fade>
                </Box>
              </Slide>
            ))}
          </Box>

          {borderBottom &&
          <Divider
            classes={{
              root: classes.dividerRoot,
            }}
          />}
        </List>}
        {!isLoading && numAccounts === 0 && !accountNodeName &&
        <div className={classes.zeroStateContainer}>
          <img alt="no transactions" src={zeroStateIcon} />
          <br />
          <div className={classes.zeroStateTextHeadline}>No synced financial accounts</div>
          <div className={classes.zeroStateTextBody}>All your connected financial accounts will be displayed here.</div>
        </div>}
        {accountNodeName === 'INVESTMENT' && !isLoading && !accountNode &&
        <div className={classes.zeroStateContainer}>
          <img alt="no investments" src={navIconInvestments} />
          <br />
          <div className={classes.zeroStateTextHeadline}>No synced investment accounts</div>
          <div className={classes.zeroStateTextBody}>All your connected investment accounts will be displayed here.</div>
        </div>}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  // Somewhat temporary to AB test the account list havving "Add" buttons, see SIMPL-9339 for more
  const showOptionToAddUnderAccounts = featureFlagsSelectors.getFeatureFlags(state).get('accountListSubLevelAdd') && isAcme;
  let accountsToShowAddOptionUnder = [];
  if (showOptionToAddUnderAccounts) {
    const accountNodesById = getAccountNodesById(state, props);
    const accountIdToDisplayLabel = { BANKING: 'Banking', LOAN: 'Loans', ASSET: 'Property', INVESTMENT: 'Investments' };
    accountsToShowAddOptionUnder = ['BANKING', 'INVESTMENT', 'ASSET', 'LOAN']
      .filter((id) => !accountNodesById.has(id))
      .map((id) => accountIdToDisplayLabel[id]);
  }

  return {
    isLoading: accountsSelectors.getLoadPending(state),
    accountNodesTree: getAccountNodesTree(state, props),
    accountsToShowAddOptionUnder,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAccounts: () => dispatch(accountsActions.getAccounts()),
    getInvestmentAccounts: () => dispatch(getInvestmentAccounts()),
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(AccountList);
