
// CORE
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field } from 'formik';
import _ from 'lodash';

import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

// CUSTOM COMPONENTS
import FormikSelect from 'components/Formik/Select';

const useStyles = makeStyles((theme) => ({
  billField: {
    width: '100%',
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.15),
  },
}));

export const autoAdjustAmountEnum = {
  BALANCE: 'EBILL_LAST_STATEMENT_BALANCE',
  MINIMUM: 'EBILL_MINIMUM_DUE',
  NONE: 'NONE',
};

const name = 'autoAdjustAmount';


// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
// ### - - -                   Menu Component                    - - - ###
// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
function BillAmountField(props) {

  const { isCredit, formikBag, className } = props;
  const { touched, errors } = formikBag;

  const helperText = _.get(touched, name) && _.get(errors, name);
  const inError = Boolean(helperText);
  const classes = useStyles();

  // render return
  return (
    <FormControl variant="outlined" margin="normal" className={classNames(classes.billField, className)} error={inError}>
      <InputLabel id={'Bill-Amount-Label'}>Amount</InputLabel>
      <Field
        component={FormikSelect}
        name={name}
        validate={(val) => !val && val !== '' && 'required'}
        variant="outlined"
        fullWidth
        label="Amount"
        id="Bill-Amount-Select"
      >
        <MenuItem value={autoAdjustAmountEnum.BALANCE}>
          {isCredit ? 'Statement Balance' : 'Bill Amount'}
        </MenuItem>
        {isCredit &&
        <MenuItem value={autoAdjustAmountEnum.MINIMUM}>
          Min. Payment Due
        </MenuItem>}
        {isCredit && <div className={classes.divider} />}
        <MenuItem value={autoAdjustAmountEnum.NONE}>
          {"Don't auto-update"}
        </MenuItem>
      </Field>

      <FormHelperText id="Bill-Amount-HelpText">{helperText}</FormHelperText>
    </FormControl>
  );
}

BillAmountField.defaultProps = {
};

BillAmountField.propTypes = {
  formikBag: PropTypes.object,
  isCredit: PropTypes.bool,
  className: PropTypes.string,
};

export default BillAmountField;

