
import { combineActions, handleActions } from 'redux-actions';

import { resourceUpserters } from '@quicken-com/react.flux.core';

import * as actions from './actions';

import { BillerAccountStore, mkBillerAccountStore } from './types';

const { upsertResources, completeWithError } = resourceUpserters;

const reducer = handleActions({

  [combineActions(
    actions.getBillerAccounts,
    actions.getBillerAccountsForLoginID,
  )]:
    (state: BillerAccountStore) => state.set('isLoading', true),

  [actions.getBillerAccountsSuccess]:
    (state: BillerAccountStore, { payload }) => upsertResources(state, payload),

  [actions.getBillerAccountsFailure]: (state: BillerAccountStore, { payload: error }) => completeWithError(state, error),

}, mkBillerAccountStore());

export const REDUCER_KEY = 'billerAccountStore';
export default reducer;
