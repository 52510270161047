import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Set } from 'immutable';
import { useLocation } from 'react-router-dom';
import useQPreferences from 'components/QPreferences/useQPreferences';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog/Dialog';
import MonthlyCalendar from './MonthlyCalendar';
import { transactionCalendarStyles } from './styles';

const useStyles = makeStyles(transactionCalendarStyles);

const TransactionCalendar = (props) => {
  const classes = useStyles();

  const { inDialog, year, month, setDate, navFn, onClose } = props;

  const { datasetPreferences } = useQPreferences();

  const { accountsToShow } = datasetPreferences.calendarTransaction;

  const location = useLocation();

  const accountIds = location.search?.includes('displayNode=all') ? (accountsToShow || Set()) : props.accountIds;

  const setCalendarDate = useCallback((yearValue, monthValue) => {
    setDate && setDate(yearValue, monthValue);
  }, [setDate]);

  const closeAndExit = useCallback(() => onClose && onClose(), [onClose]);

  const cachedNavFn = useCallback((txn) => { navFn(txn); closeAndExit(); }, [navFn, closeAndExit]);

  const MonthCalendar = (
    <MonthlyCalendar
      onClose={closeAndExit}
      accountIds={accountIds}
      navFn={cachedNavFn}
      month={month}
      year={year}
      setDate={setCalendarDate}
    />
  );

  if (inDialog) {
    return (
      <Dialog
        classes={{ paper: classes.container }}
        open
        maxWidth="xlg"
        fullWidth
        show="true"
        onClose={closeAndExit}
      >
        {MonthCalendar}
      </Dialog>
    );
  }

  return (
    <div className={classes.fullScreen}>
      {MonthCalendar}
    </div>
  );
};

TransactionCalendar.propTypes = {
  onClose: PropTypes.func,
  navFn: PropTypes.func,
  inDialog: PropTypes.bool,
  year: PropTypes.string,
  month: PropTypes.string,
  setDate: PropTypes.func,
  accountIds: PropTypes.object,
};

TransactionCalendar.defaultProps = {
  accountIds: Set(),
};

TransactionCalendar.whyDidYouRender = true;

export default TransactionCalendar;
