import { Record, Map, RecordFactory, RecordOf } from 'immutable';

// Notes: IHolds are stored first by accountId and then by txnId. So,
//        there is a map of transactionsByAccountId where each value in the
//        map is the IHoldsForAccount record which then contains a map of
//        the transactions by txnId.
//
export const InvestmentHoldingsStore = Record({
  isLoading: true,
  isQuotesLoading: false,
  iHoldsByAccountId: new Map(),
  lastSyncDate: 0,
});

type InvestmentHoldingProps = {
  id: string,
  clientId: ?string,
  createdAt: ?string,
  modifiedAt: ?string,
  numberOfShares: ?number,
  accountID: ?string,
  securityID: ?string,
  isDeleted: ?boolean,
  deleted: ?boolean,

  coa: ?any,

  // fields specific to web app (unknown to QCS) many from the associated security
  //

  amount: ?number,
  amountDelta: ?number,
  averageVolume: ?number,
  assetClassResourceType: ?string,
  balance: ?number,
  closePrice: ?number,
  companyName: ?string,
  divYield: ?number,
  highestPrice: ?number,
  latestPrice: ?number,
  lowestPrice: ?number,
  openPrice: ?number,
  peRatio: ?number,
  valueChange: ?number,
  quoteChange: ?number,
  quoteChangePercentage: ?number,
  security: ?any,
  securityType: ?string,
  showInInvestmentPanel: boolean,
  tickerSymbol: ?string,
  volume: ?number,
  yearHighPrice: ?number,
  yearLowPrice: ?number,
}
export type InvestmentHolding = RecordOf<InvestmentHoldingProps>;

export const mkInvestmentHolding: RecordFactory<InvestmentHoldingProps> =
  Record({
    id: '0',
    clientId: null,
    createdAt: null,
    modifiedAt: null,
    numberOfShares: null,
    accountID: null,
    securityID: null,
    isDeleted: false,
    deleted: false,

    coa: null,

    // fields specific to web app (unknown to QCS)
    //

    amount: 0.00,
    amountDelta: 0.00,
    averageVolume: null,
    assetClassResourceType: null,
    balance: 0.00,
    closePrice: null,
    companyName: null,
    divYield: null,
    highestPrice: null,
    latestPrice: null,
    lowestPrice: null,
    openPrice: null,
    peRatio: null,
    valueChange: null,
    quoteChange: null,
    quoteChangePercentage: null,
    security: null,
    securityType: null,
    showInInvestmentPanel: true,
    tickerSymbol: null,
    volume: null,
    yearHighPrice: null,
    yearLowPrice: null,
  });

export const statusOrder = ['RECONCILED', 'CLEARED', 'PENDING'];
