import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  loadingLinearProgress: {
    height: 1,
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100vw',
    zIndex: theme.zIndex.tooltip,
  },
  errorLinearProgress: {
    backgroundColor: theme.palette.warning.main,
  },
}));

const LoadingLinearProgress = () => {
  const classes = useStyles();
  return (
    <LinearProgress
      variant="determinate"
      value={100}
      classes={{
        root: classes.loadingLinearProgress,
        bar: classes.errorLinearProgress,
      }}
    />
  );
};

export default LoadingLinearProgress;
