// @flow
import { Record, Map as ImmutableMap } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';

import type { QcsSyncResourceStoreProps } from '@quicken-com/react.flux.core/resourceStoreTypes';


// ================================================================================================
// DocumentsStore
// ================================================================================================

type DocumentsStoreProps = QcsSyncResourceStoreProps & {

};
export type DocumentsStore = RecordOf<DocumentsStoreProps>;
export const mkDocumentsStore: RecordFactory<DocumentsStoreProps> =
  Record({
    resourcesById: ImmutableMap(),

    lastSyncDate: null,
    loadPending: true,

    isLoading: false,
  });

type DocumentProps = {
  id: ?string,
  isDeleted: ?boolean,
  name: string,
  description: ?string,
  documentType: string,
  documentSize: number,
  contentType: string,
  clientId: ?string,
  url: ?string,
};
export type Document = RecordOf<DocumentProps>;

export const mkDocument: RecordFactory<DocumentProps> =
  Record({
    id: undefined,
    isDeleted: false,
    name: undefined,
    description: undefined,
    documentType: 'OTHER',
    contentType: undefined,
    documentSize: 0,
    clientId: undefined,
    url: undefined,
  });

