
// BASE
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { categoriesSelectors } from '@quicken-com/react.flux.categories';

// CUSTOM COMPONENTS

// ACTIONS, SELECTORS, UTILS

// MUI COMPONENTS
import Typography from '@mui/material/Typography';

// PATH RELATIVE IMPORTS
import BudgetSummaryItem from './budgetSummaryItem';


/*
 * BudgetSummary Component ***********************************
 */

// PROP TYPES
const propTypes = {
  classes: PropTypes.object,
  budgetItems: PropTypes.object,
  currency: PropTypes.string,
};

// HELPER FUNCTIONS
function calculateSummaryTotals(budgetItems) {
  const newSummary = { income: 0, expenses: 0, balance: 0 };

  budgetItems.forEach((item) => {
    switch (item.type) {

      case 'GROUP':
        if (item.amount > 0) {
          newSummary.income += Number(item.amount);
        } else {
          newSummary.expenses += Number(item.amount);
        }
        break;

      default:
        if (item.coa && item.coa.type === 'CATEGORY' && categoriesSelectors.isIncomeCat(null, item.coa.id)) {
          newSummary.income += Number(item.amount);
        } else {
          newSummary.expenses += Number(item.amount);
        }
        break;
    }
  });
  newSummary.balance = newSummary.income + newSummary.expenses;
  return newSummary;
}

const BudgetSummary = (props) => {

  // PROPS
  const { budgetItems, classes, currency = 'USD' } = props;
  // STATE
  const [summaryTotals, setSummaryTotals] = useState({ income: 0, expenses: 0, balance: 0 });
  // EFFECTS
  useEffect(() => {
    setSummaryTotals(calculateSummaryTotals(budgetItems));

  }, [budgetItems]);

  // STYLES
  // INTERNAL FUNCTIONS

  return (
    <div
      className={classes.budgetSummary}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Typography
          variant="subtitle1"
          className={classes.summaryHeader}
        >
          BUDGET SUMMARY
        </Typography>
        <BudgetSummaryItem
          classes={classes}
          title="INCOME"
          currency={currency}
          amount={summaryTotals.income}
          disableColor
        />
        <BudgetSummaryItem
          classes={classes}
          title="EXPENSES"
          currency={currency}
          amount={summaryTotals.expenses}
        />
        <BudgetSummaryItem
          classes={classes}
          title="SAVINGS"
          currency={currency}
          amount={summaryTotals.balance}
        />
      </div>
    </div>
  );
};
BudgetSummary.propTypes = propTypes;
export default BudgetSummary;
