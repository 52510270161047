import { createSelector } from 'reselect';
import { Record, Map as ImmutableMap, OrderedMap } from 'immutable';

import { transactionsUtils } from '@quicken-com/react.flux.transactions';
import { accountsSelectors } from '@quicken-com/react.flux.accounts';
import { categoriesSelectors } from '@quicken-com/react.flux.categories';
import { chartOfAccountsUtils, chartOfAccountsSelectors } from '@quicken-com/react.flux.chart-of-accounts';

import { getRulesById } from 'data/renameRules/selectors';

import { coasAreDifferent } from 'data/transactions/utils';
import { getTransactionsOfTypes, TxnTypes } from 'data/transactions/selectors';
import { createValueCache } from 'utils/selectorsHelpers';

import { REDUCER_KEY } from './reducer';

export const getStore = (state) => state[REDUCER_KEY];
export const getLoadPending = (state) => getStore(state).loadPending;
export const getIsLoading = (state) => getStore(state).isLoading;
export const getError = (state) => getStore(state).error;
export const getResourcesById = (state) => getStore(state).resourcesById;
export const getLastSyncDate = (state) => getStore(state).lastSyncDate;

export const getMemorizedRulesById = getResourcesById;
export const getMemorizedRuleById = (state, id) => getResourcesById(state).get(id);

const getMemorizedRuleCoaStrings = createSelector(
  getMemorizedRulesById,
  categoriesSelectors.getCategoriesById,
  accountsSelectors.getAccountsById,
  (memorizedRules, _categoriesById, _accountsById) =>
    ImmutableMap().withMutations((coas) => memorizedRules.forEach((memorizedRule) =>
      coas.set(chartOfAccountsUtils.getCoaId(memorizedRule.coa), chartOfAccountsSelectors.getCoaStringSelector(undefined, memorizedRule.coa, true)))),
);

const RuleNode = Record({
  payee: undefined,
  coaString: undefined,
  memorizedRule: undefined,
  renameRules: undefined,
});

export const getRuleNodes = createSelector(
  getMemorizedRulesById,
  getRulesById,
  getMemorizedRuleCoaStrings,
  (memorizedRules, renameRules, coaStrings) => OrderedMap()
    .withMutations((ruleNodes) => {
      memorizedRules.forEach((memorizedRule) => {
        const coaString = coaStrings.get(chartOfAccountsUtils.getCoaId(memorizedRule.coa));
        ruleNodes.set(memorizedRule.payee.trim().toLowerCase(), RuleNode({
          payee: memorizedRule.payee,
          coaString,
          memorizedRule,
        }));
      });

      renameRules.forEach((renameRule) => {
        let ruleNode = ruleNodes.get(renameRule.renamePayeeTo.trim().toLowerCase());
        const newRenameRules = ImmutableMap({ [renameRule.id]: renameRule });
        if (ruleNode) {
          ruleNode = ruleNode.mergeDeep({ renameRules: newRenameRules });
        } else {
          ruleNode = RuleNode({
            payee: renameRule.renamePayeeTo,
            renameRules: newRenameRules,
          });
        }
        ruleNodes.set(ruleNode.payee.trim().toLowerCase(), ruleNode);
      });
    })
    .sortBy((ruleNode) => ruleNode.payee),
);

export const getMemorizedRuleApplicableTransactions = createSelector(
  (state) => getTransactionsOfTypes(state, TxnTypes.REGULAR_TXNS),
  createValueCache((state, memorizedRule) => memorizedRule),
  createValueCache((state, memorizedRule, memorizedRuleOld = {}) => memorizedRule?.id ? getMemorizedRuleById(state, memorizedRule?.id) : memorizedRuleOld),
  (transactions, memorizedRule, memorizedRuleOld) => {
    const payeeNormalized = memorizedRule.payee?.toLowerCase();
    const isPayeeChanged = memorizedRuleOld?.payee?.toLowerCase() !== payeeNormalized;
    const isCoaChanged = !chartOfAccountsUtils.coasAreEqual(memorizedRuleOld.coa, memorizedRule.coa);
    if (isPayeeChanged || isCoaChanged) {
      return transactions.filter((transaction) =>
        !transactionsUtils.isSplitTxn(transaction)
        && transaction.payee?.toLowerCase() === payeeNormalized
        && coasAreDifferent(transaction.coa, memorizedRule.coa)
        && (isPayeeChanged || !isCoaChanged || chartOfAccountsUtils.coasAreEqual(transaction.coa, memorizedRuleOld.coa)));
    }
    return ImmutableMap();
  },
);
