
/*
 * Account Retrievers - DATA ACCESS WITHOUT ANY STATE CHANGE
 *
 * This provides global access to the accounts.
 *
 * WARNING: These should NEVER be used for properties (MapToProps).  These are to retrieve information from
 * the REDUX Store and perform operations/searches on it, but this will not create any linkage for state change
 *
 */

import store from '@quicken-com/react.utils.redux-store';

import * as selectors from './selectors';

export function getBalanceForAccountAtDate(props) {
  return selectors.getBalanceForAccountAtDate(store.getState(), props);
}
