import { handleActions } from 'redux-actions';

import { coreActions } from '@quicken-com/react.flux.core';
import {
  appSetOnlineStatus,
  appSetServiceWorkerIsRegistered,
  appSetServiceWorkerIsRegistering,
  appSetServiceWorkerUpdateIsAvailable,
  setAwaitingToAutoRefresh,
  setAppProps,
} from 'data/app/actions';
import { importDataSuccess, importDataFailure } from 'data/import/importActions';
import { createJobStatus, deleteJobStatus } from 'data/jobStatus/actions';

import { mkAppStore } from './types';

const reducer = handleActions({

  [appSetOnlineStatus]: (state, { payload: value }) => state.merge({ isOffline: !value }),

  [appSetServiceWorkerIsRegistered]: (state, { payload: value }) =>
    state.merge({ serviceWorkerIsRegistered: value, serviceWorkerIsRegistering: false }),
  [appSetServiceWorkerIsRegistering]: (state, { payload: value }) =>
    state.merge({ serviceWorkerIsRegistering: value, serviceWorkerIsRegistered: value ? false : state.serviceWorkerIsRegistered }),

  [appSetServiceWorkerUpdateIsAvailable]: (state, { payload: value }) => state.merge({ serviceWorkerUpdateIsAvailable: value }),

  [coreActions.sanitize]: (state) => state.merge({ isSanitized: true }),
  [coreActions.persistDataLoadComplete]: (state) => state.merge({
    awaitingToAutoRefresh: false,
    fetchInProgress: false,
  }),

  [setAwaitingToAutoRefresh]: (state, { payload: value }) => state.set('awaitingToAutoRefresh', value),

  [createJobStatus]: (state) => state.merge({ fetchInProgress: true }),
  [deleteJobStatus]: (state) => state.merge({ fetchInProgress: false }),

  [setAppProps]: (state, { payload }) => state.mergeDeep(payload),

  [coreActions.setUpdateAllState]: (state, { payload }) => state.mergeDeep(payload),

  [importDataSuccess]:
    (state, { payload }) => state.mergeDeep({ csvImportCompleteByClientId: state.csvImportCompleteByClientId.set(payload.clientId, payload) }),

  [importDataFailure]:
    (state, { payload }) => state.mergeDeep({ csvImportErrorByClientId: state.csvImportErrorByClientId.set(payload.clientId, payload) }),

}, mkAppStore());

export const REDUCER_KEY = 'appStore';
export default reducer;
