import { initialize, localPreferences } from '@quicken-com/react.utils.core';

import './configInit';
import platform from './platform';

const history = require('history').createBrowserHistory();

const storageBackend = localPreferences.getLocalStorageInterface();

initialize({
  history,
  platform,
  storageBackend,
});


