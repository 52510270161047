
import React from 'react';
import PropTypes from 'prop-types';

import QMenu from 'components/QMenu';
import AlarmOn from '@mui/icons-material/AlarmOn';
import AlarmOff from '@mui/icons-material/AlarmOff';

import { schedTxViewsWindows, schedTxViewsMac } from 'data/accountBalances/types';
const ScheduleViewMenu = (
  {
    reminderSetting,
    onChange,
    isWindows,
    ..._other
  }
) =>
  (
    <QMenu
      menuIcon={!reminderSetting || reminderSetting === 'off' ? AlarmOff : AlarmOn}
      name="schedTxnMenu"
      title="Show reminders in register"
      menuIconButtonSize="mediumNoPadding"
      options={[
        { label: 'Show reminders for',
          groupId: 'reminders',
          isGroupList: true,
          selected: reminderSetting,
          groupList: isWindows ? schedTxViewsWindows : schedTxViewsMac,
        },
      ]}
      onChange={onChange}
    />
  );

ScheduleViewMenu.propTypes = {
  reminderSetting: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  isWindows: PropTypes.bool,
};

export default ScheduleViewMenu;



