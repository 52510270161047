import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import Zoom from '@mui/material/Zoom';
import simplifiLogoText from 'assets/simplifi-logo-text-dark.svg';
// import Badge from '@mui/material/Badge';
import quickenLogo from 'assets/quicken-logo-red.svg';
import { isAcme } from 'isAcme';

const useStyles = makeStyles((_theme: Object) => ({
  logoText: {
    height: 48,
    marginRight: 24,
  },
  betaBadge: {
    backgroundColor: '#F5A623',
    padding: '1px 2px',
    height: 'auto',
    borderRadius: 2,
    textTransform: 'none',
    lineHeight: 1,
    fontSize: 12,
  },
  logoButton: {
    padding: 8,
    minWidth: 0,
  },
}));

const LogoText = () => {
  const classes = useStyles();
  return (
    <>
      <Zoom in style={{ transitionDelay: '100ms' }}>
        <Button
          id="logo-nav"
          component={React.forwardRef((propers, ref) => <NavLink {...propers} innerRef={ref} />)}
          to="/"
          className={classes.logoButton}
        >
          <img
            src={isAcme ? simplifiLogoText : quickenLogo}
            className={classes.logoText}
            alt="Simplifi by Quicken, click to go home"
          />
        </Button>
      </Zoom>
    </>
  );
};

export default LogoText;
