
// BASE
import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

// CUSTOM COMPONENTS

// ACTIONS, SELECTORS, UTILS

// MUI COMPONENTS
import Button from '@mui/material/Button';
import VisibilityOnIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffRounded';

// PATH RELATIVE IMPORTS
import IgnoreSelectionMenu from './ignoreSelectionMenu';

// STYLES DEF AND HOOK
const styles = () => ({
  buttonRoot: {
    padding: 0,
    minWidth: 'unset',
  },
  iconRoot: {
    fontSize: 20,
  },
});

const useStyles = makeStyles(styles);

/*
 * IgnoredField Component ***********************************
 */

// PROP TYPES
const propTypes = {
  value: PropTypes.object,
  autoFocus: PropTypes.bool,
  inputRef: PropTypes.func,
  onChange: PropTypes.func,
  editable: PropTypes.bool,
  show: PropTypes.bool,
};

// HELPER FUNCTIONS

const IgnoredField = (props) => {

  // PROPS
  const { autoFocus, inputRef, onChange, editable, show, value, ...other } = props;

  // STATE
  const [anchorEl, setAnchorEl] = useState(null);
  const beingIgnored = !value || value.isExcludedFromReports || value.isExcludedFromF2S;

  // EFFECTS

  // STYLES
  const classes = useStyles(props);

  // INTERNAL FUNCTIONS

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  };

  const onMenuClose = () => setAnchorEl(null);

  return (
    <div
      {...other}
    >
      {show &&
        <>
          {anchorEl &&
            <IgnoreSelectionMenu
              anchorEl={anchorEl}
              onChange={onChange}
              onClose={onMenuClose}
              isExcludedFromReports={value && value.isExcludedFromReports}
              isExcludedFromF2S={value && value.isExcludedFromF2S}
            />}

          <Button
            {...other}
            classes={{ root: classes.buttonRoot }}
            autoFocus={autoFocus}
            aria-owns={anchorEl ? 'IgnoredField-menu' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            action={(act) => {
              if (autoFocus && act) {
                act.focusVisible();
              }
            }}
          >
            {beingIgnored ?
              <VisibilityOffIcon
                className={classes.iconRoot}
              />
              :
              <VisibilityOnIcon
                className={classes.iconRoot}
              />}
          </Button>
        </>}
    </div>
  );
};
IgnoredField.propTypes = propTypes;
export default IgnoredField;


