
// BASE
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Set } from 'immutable';
import { BudgetNodeTypes } from 'data/budgets/budgetsTypes';

// CUSTOM COMPONENTS

// SELECTORS / ACTIONS / UTILITIES

// MUI COMPONENTS
import ButtonBase from '@mui/material/ButtonBase';

// PATH RELATIVE
import BudgetGroupItem from './BudgetGroupItem';

// STYLES
import { styles } from './styles';
const useStyles = makeStyles((_theme) => (styles));

/*
 * EditBudgetCategoryGroups Component ***********************************
 */

// PROP TYPES
const editBudgetCategoryGroupsPropTypes = {
  // passed in
  currency: PropTypes.string,
  budgetTreeNodes: PropTypes.object,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  accountIds: PropTypes.object,

  // map state to props - dispatch to props
};

const EditBudgetCategoryGroups = (props) => {

  // PROPS
  const { currency, budgetTreeNodes, onChange, onDelete, accountIds } = props;

  // STATE
  const [hiddenGroups, setHiddenGroups] = useState(new Set());

  // EFFECTS

  // STYLES
  const classes = useStyles(props);

  // INTERNAL FUNCTIONS
  const showHideGroup = (node) => {
    setHiddenGroups(hiddenGroups.has(node.id) ? hiddenGroups.delete(node.id) : hiddenGroups.add(node.id));
  };

  const getInnerNodes = (nodes, depth = 0) => {

    let localNodeList = [];
    nodes.forEach((node) => {

      let overrideLabel = null;
      if (node.type === BudgetNodeTypes.GROUP_EE) {
        overrideLabel = node.displayLabel;
      }
      localNodeList.push(
        <React.Fragment key={`budgetNode:${node.id}${node.displayLabel}${node.type}`}>
          {((node.depth === 0) || node.type === 'crup') &&
            <BudgetGroupHeader
              classes={classes}
              onClick={() => showHideGroup(node)}
              node={node}
              currency={currency}
              accountIds={accountIds}
              isRootNode={node.depth === 0}
            />}
          {(node.depth) > 0 && !hiddenGroups.has(node.id) && node.budgetItems.size > 0 &&
          <BudgetGroupItem
            item={node.budgetItems.first()}
            chartIds={node.coas}
            currency={currency}
            onChange={onChange}
            onDelete={node.type === BudgetNodeTypes.GROUP_EE ? null : onDelete}
            accountIds={accountIds}
            overrideLabel={overrideLabel}
            depth={depth - 1 - (node.type === BudgetNodeTypes.GROUP_EE ? 1 : 0)}
            isEverythingElse={node.isEverythingElse && node.type !== BudgetNodeTypes.GROUP_EE}
          />}
        </React.Fragment>
      );
      if ((node.children.size > 0) && !hiddenGroups.has(node.id)) {
        localNodeList = localNodeList.concat(getInnerNodes(node.children, depth + 1));
      }
    });
    return localNodeList;
  };

  // RENDER
  // Build inner nodes using a sequencer to avoid React errors (does not support Map)

  // if (budgetTreeNodes) console.log ("NODE LIST ", budgetTreeNodes.toJS());
  const innerNodes = getInnerNodes(budgetTreeNodes);
  return innerNodes && innerNodes.length ? (
    <div style={{ overflowY: 'scroll' }}>
      {innerNodes}
    </div>
  ) : null;
};

EditBudgetCategoryGroups.propTypes = editBudgetCategoryGroupsPropTypes;
export default EditBudgetCategoryGroups;

/*
 *************** SUB COMPONENTS ******************
 */

/*
 * Budget Group Header
 */
const BudgetGroupHeader = (props) => {

  const { classes, onClick, node, currency, accountIds } = props;

  const isRollupRow = (node.depth > 0);
  return (
    <ButtonBase
      style={{ width: '100%' }}
      onClick={onClick}
      href=""
      focusRipple
    >
      <BudgetGroupItem
        item={{ amount: node.targetAmount,
          name: !isRollupRow ? node.displayLabel.toUpperCase() : node.displayLabel,
          isIncome: node.targetAmount > 0,
          id: node.id,
          budgetedWithChild: true,
        }}
        currency={currency}
        accountIds={accountIds}
        isRollup
        classes={{ categoryRow: isRollupRow ? '' : classes.rootNode, categoryName: classes.categoryName }}
        depth={isRollupRow ? 0 : -1}
      />
    </ButtonBase>
  );
};
BudgetGroupHeader.propTypes = {
  classes: PropTypes.object,
  onClick: PropTypes.func,
  node: PropTypes.object,
  currency: PropTypes.string,
  accountIds: PropTypes.object,
};

